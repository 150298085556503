import { axiosData, axiosNullableData, SourceGetter } from "@imas/api";
import { ProposalServiceFee, ProposalServiceFeeForm, ProposalServiceFeeReferences, vProposalServiceFee, vProposalServiceFeeRequest } from "./types";

//proposal services base url
const SERVICE_FEES_URL = "/api/proposals/services/fees";

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/get_api_proposals_services_fees */
export const GetAllProposalServiceFees = (getSource: SourceGetter) => {
    return async (): Promise<ProposalServiceFee[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_FEES_URL}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetvProposalServiceFees = (getSource: SourceGetter) => {
    return async (request: vProposalServiceFeeRequest): Promise<vProposalServiceFee[]> => {
        return await axiosData({
            method: 'put',
            url: `${SERVICE_FEES_URL}/views`,
            data: request,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/get_api_proposals_services_fees__feeId_ */
export const GetProposalServiceFee = (getSource: SourceGetter) => {
    return async (feeId: int): Promise<ProposalServiceFee> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_FEES_URL}/${feeId}`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/get_api_proposals_services_fees__feeId_ */
export const GetvProposalServiceFeesForService = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<vProposalServiceFee[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_FEES_URL}/views/${serviceId}`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/get_api_proposals_services_fees__feeId__references */
export const GetProposalServiceFeeReferences = (getSource: SourceGetter) => {
    return async (feeId: int): Promise<ProposalServiceFeeReferences | null> => {
        return await axiosNullableData({
            method: 'get',
            url: `${SERVICE_FEES_URL}/${feeId}/references`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/post_api_proposals_services_fees */
export const CreateProposalServiceFee = (getSource: SourceGetter) => {
    return async (data: ProposalServiceFeeForm): Promise<ProposalServiceFee> => {
        return await axiosData({
            method: 'post',
            url: `${SERVICE_FEES_URL}`,
            data,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/put_api_proposals_services_fees__feeId_ */
export const UpdateProposalServiceFee = (getSource: SourceGetter) => {
    return async (feeId: int, data: ProposalServiceFeeForm): Promise<ProposalServiceFee> => {
        return await axiosData({
            method: 'put',
            url: `${SERVICE_FEES_URL}/${feeId}`,
            data,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServiceFees/delete_api_proposals_services_fees__feeId_ */
export const DeleteProposalServiceFee = (getSource: SourceGetter) => {
    return async (feeId: int): Promise<ProposalServiceFee> => {
        return await axiosData({
            method: 'delete',
            url: `${SERVICE_FEES_URL}/${feeId}`,
            cancelToken: getSource().token,
        });
    };
};