import { useEffect, useRef } from 'react';
import { InputBase, IconButton } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TypedGridColTypeDef, TypedGridRenderEditCellParams } from '../typed-grid';
import { experimental_sx as sx } from '@mui/system';
import { Alignment } from '@imas/components/layout';
import { FileSystemLink } from '@imas/utils/files';
import { useFileExplorerDialog, FileExplorerDialogProps } from '@imas/utils/dialog';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { EditCellContainer } from './utils';

export const InputEditCell = styled(InputBase)(
    sx({
        typography: 'body2',
    }),
);


interface GetFileColProps<T extends boolean | undefined> extends Pick<FileExplorerDialogProps, "table" | "folder" | "maxLevelsUp" | "maxLevelsDown" | "filter"> {
	required?: T;
	folderRequired?: boolean;
	folderRequiredMessage?: string;
};

export type FileEditCellProps<T extends boolean | undefined> = T extends true ? 
	GetFileColProps<T> & TypedGridRenderEditCellParams<{ id: string | number; value: HierarchyId; }, { value: HierarchyId; }, never, "value", "cell">
	: GetFileColProps<T> & TypedGridRenderEditCellParams<{ id: string | number; value: HierarchyId | null; }, { value: HierarchyId | null; }, never, "value", "cell">;

export const FileEditCell = <T extends boolean | undefined>(props: FileEditCellProps<T>) => {
	//use automatic snackbar 
	const showSnackbar = useAutomaticSnackbar();

	//use file explorer dialog
	const [open] = useFileExplorerDialog();

    //input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (props?.hasFocus) ref.current?.focus(); }, [props?.hasFocus]);

	//get FileColProps
	const { required, table, folder, maxLevelsUp, maxLevelsDown, filter, folderRequired, folderRequiredMessage } = props;

    //get GridRenderEditCellParams
    const { id, value, field, api } = props;

    return (
		<EditCellContainer required={required && !value}>
			<Alignment row>
				{/* Link File Button */}
				<IconButton
					size={"small"}
					onClick={async () => {
						if (folderRequired && !folder) return showSnackbar(folderRequiredMessage ?? "No folder available to link from.", { variant: "error" });

						// open picker
						const item = await open("pick-file", { table, folder, maxLevelsUp, maxLevelsDown, filter, allowUpload: true });

						//update value
						if (item) api.setEditCellValue({ id, field, value: item.pathLocator } as any);
					}}
					sx={{ padding: '4px', margin: '0 4px' }}
				><LinkIcon sx={{ height: '16px', width: '16px' }}/></IconButton>

				{/* File Link/Name */}
				{value ? <FileSystemLink table={table} destination={value} type={"link"}/> : null}
			</Alignment>
		</EditCellContainer>
    );
};

export const getFileColDef = <T extends boolean | undefined>({ required, ...props }: GetFileColProps<T>): TypedGridColTypeDef<T extends true ? HierarchyId : HierarchyId | null> => ({
	renderCell: ({ value: pathLocator }) => pathLocator ? <FileSystemLink table={props.table} destination={pathLocator} type={"link"}/> : <></>,
	renderEditCell: (renderProps) => <FileEditCell required={required} {...props} {...renderProps as any}/>,
});