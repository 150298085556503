import { useApi } from '@imas/api';
import { 
	CreateClientLocationDocument, UpdateClientLocationDocument, DeleteClientLocationDocument, CreateClientFolder,
	ClientLocation, ClientLocationDocument, ClientLocationDocumentForm
} from '@imas/api/client';
import { GetFileInfo } from '@imas/api/files';
import { useClientLocationDocuments, useClientLocationClient } from '@imas/api/data-hooks';
import { Alignment } from '@imas/components/layout';
import { FileExplorerLink, FileSystemLink, FileUploader } from '@imas/utils/files';
import { FileTables } from '@imas/api/files';
import { EditDataGrid, useEditGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs } from '@imas/data-grid';
import { Typography, ButtonGroup, Button, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add as AddIcon, Link as LinkIcon, FileUpload as FileUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { memo, useMemo, useCallback } from 'react';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { useFileExplorerDialog, useConfirmationDialog } from '@imas/utils/dialog';
import { FormPaper } from '@imas/mui-form';

export interface ClientLocationDocumentRM extends Pick<ClientLocationDocument, "type" | "pathLocator"> {}; 

//define the column order
export type LOCATION_DOCUMENTS_GRID_COLUMNS = ["type", "pathLocator"];
export type LOCATION_DOCUMENTS_EDITABLE_COLUMNS = "type";

//define the grid api ref type
export type LOCATION_DOCUMENTS_GRID_API_REF = TypedGridApiRef<ClientLocationDocument, ClientLocationDocumentRM, LOCATION_DOCUMENTS_EDITABLE_COLUMNS, "row">;

//define grid props type
export type LOCATION_DOCUMENTS_GRID_PROPS = TypedEditDataGridProps<ClientLocationDocument, ClientLocationDocumentRM, LOCATION_DOCUMENTS_EDITABLE_COLUMNS, LOCATION_DOCUMENTS_GRID_COLUMNS, "row">

export interface LocationDocumentsProps {
	cLocation?: ClientLocation | null;
};

export const LocationDocuments = memo(({ cLocation }: LocationDocumentsProps) => {
	//use automatic snackbar
	const showSnackbar = useAutomaticSnackbar();

	//use file explorer dialog
	const [openExplorer] = useFileExplorerDialog();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

	//get documents for specified location
	const [documents] = useClientLocationDocuments(cLocation?.id ?? -1, { disabled: !cLocation });

	//get location's client
	const [client] = useClientLocationClient(cLocation?.id ?? -1, { disabled: !cLocation });

	//APIs
	const createDocumentApi = useApi(CreateClientLocationDocument);
	const updateDocumentApi = useApi(UpdateClientLocationDocument);
	const deleteDocumentApi = useApi(DeleteClientLocationDocument);
	const createClientFolder = useApi(CreateClientFolder);
	const getFileInfo = useApi(GetFileInfo);

	//grid apiRef
	const apiRef: LOCATION_DOCUMENTS_GRID_API_REF = useEditGridApiRef();

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<ClientLocationDocument, ClientLocationDocumentRM, LOCATION_DOCUMENTS_EDITABLE_COLUMNS, LOCATION_DOCUMENTS_GRID_COLUMNS, "row"> => {
        return [
            { field: 'type', headerName: 'Type', width: 130, editable: true },
            { 
				field: 'pathLocator', 
				headerName: 'File Name',
				flex: 1,
				renderCell: ({ value: pathLocator }) => <FileSystemLink table={FileTables.ClientFiles} destination={pathLocator} type={"link"}/>,
			},
        ];
    }, []);

	const createDocument = useCallback(async (fields: Pick<ClientLocationDocumentForm, "locationId" | "document" | "pathLocator">) => {
		//progress snackbar 
		const close = showSnackbar("Creating document...", { variant: "info", persist: true });

		try {
			//create new location document
			await createDocumentApi({ ...fields, type: "Billing" });

			//close progress
			close();
		} catch (e) {
			throw close(e);
		}
	}, [showSnackbar, createDocumentApi]);

	//validator
	const validator = useCallback<LOCATION_DOCUMENTS_GRID_PROPS["rowValidator"]>(async (row, document) => {
		if (row.type === undefined || row.type === "") throw new Error("Type is required.");
		if (document === null) throw new Error("Create Not Implimented.");
		
		return {
			type: row.type,
			pathLocator: document.pathLocator,
		};
	}, []);
	
	//row create handler
	const onCreate = useCallback<LOCATION_DOCUMENTS_GRID_PROPS["onRowCreate"]>(async () => {
		throw Error("Create Not Implimented.");
	}, []);

	//row update handler
	const onUpdate = useCallback<LOCATION_DOCUMENTS_GRID_PROPS["onRowUpdate"]>(async (id, row) => {
		//get the row record
		const document = apiRef.current.getRow(id);
		if (document === null) throw new Error("Unable to update row which does not exist.");

		//update the record
		return await updateDocumentApi(document.id, { locationId: document.locationId, type: row.type });
	}, [apiRef, updateDocumentApi]);
	
	//row delete handler
	const onDelete = useCallback<Exclude<LOCATION_DOCUMENTS_GRID_PROPS["onRowDelete"], undefined>>(async (id, document) => {
		//get document name
		const info = await getFileInfo(FileTables.ClientFiles, document.pathLocator); 

		//confirm the user wants to delete this document
		const deleteConfirmed  = await confirm({
			title: "Delete Confirmation",
			prompt: (
				<>
					<Typography variant={"body1"}>{"Are you sure you would like to delete the following location document?"}</Typography><br/>
					<Typography variant={"body1"} sx={{ textAlign: 'center' }}>{`${info.name}.${info.extension}`}</Typography>
				</>
			),
			confirmText: "Delete",
			confirmIcon: <DeleteIcon/>
		});
		if (!deleteConfirmed) throw new Error();

		//delete the record
		await deleteDocumentApi(id);
	}, [deleteDocumentApi, getFileInfo, confirm]);

	return (
		<FormPaper sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
			<Alignment row sx={{ marginLeft: '10px', marginTop: '3px', marginRight: '3px' }}>
				<Typography variant={"h6"} sx={{ fontWeight: 'bold', color: 'text.primary' }}>{"Documents"}</Typography>

				{/* Link & Upload Buttons */}
				<ButtonGroup variant={"contained"} color={"primary"} size={"small"} sx={{ marginLeft: '10px' }}>
					<Tooltip title={"Link Existing Document"} arrow>
						<Button
							disabled={!client?.pathLocator}
							onClick={async () => {
								if (!client?.pathLocator) return;
								if (!cLocation) return;

								//show file picker prompt
								const file = await openExplorer("pick-file", { 
									table: FileTables.ClientFiles,
									folder: client.pathLocator,
									filter: (rows) => rows.filter(row => !(documents ?? []).some(x => x.pathLocator === row.pathLocator)),
									maxLevelsDown: 0,
									maxLevelsUp: 0,
								});
								if (file === null) return;

								//create the new document record
								await createDocument({ locationId: cLocation.id, pathLocator: file.pathLocator });
							}}
						><LinkIcon/></Button>
					</Tooltip>

					<Tooltip title={"Upload New Document"} arrow>
                        <FileUploader
							disabled={!cLocation}
                            onUpload={async (document) => {
								if (!cLocation) return;

								//create the new document record
								await createDocument({ locationId: cLocation.id, document });
                            }}
                        ><FileUploadIcon/></FileUploader>
					</Tooltip>
				</ButtonGroup>

				{/* Create Folder / Open Folder Button */}
				{!client || !client.pathLocator ? (
					<Tooltip title={cLocation !== null && client === undefined ? "Open Client Folder" : `Create Folder for '${client?.name ?? "Client"}'`} arrow>
						<LoadingButton
							variant={"contained"}
							color={"primary"}
							size={"small"}
							startIcon={<AddIcon/>}
							loading={cLocation !== null && client === undefined}
							loadingPosition={"start"}
							disabled={!client}
							onClick={async () => {
								if (!client) return;

								//show progress snackbar
								const close = showSnackbar("Creating folder...", { variant: "info", persist: true });

								try {
									//create the folder
									await createClientFolder(client.id);

									//close snackbar
									close();
								} catch (e) {
									throw close(e);
								}
							}}
							sx={{ marginLeft: 'auto' }}
						>{cLocation !== null && client === undefined ? "Client" : "Folder"}</LoadingButton>
					</Tooltip>
				) : (
					<FileExplorerLink
						table={FileTables.ClientFiles}
						folder={client.pathLocator}
						color={"primary"}
						variant={"contained"}
						size={"small"}
						sx={{ marginLeft: 'auto' }}
					>{"Client"}</FileExplorerLink>
				)}
			</Alignment>

			{cLocation === null ? (
				<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until location is saved."}</Typography>
			) : cLocation ? ( 
				<Alignment column flex>
					<EditDataGrid<ClientLocationDocument, ClientLocationDocumentRM, LOCATION_DOCUMENTS_EDITABLE_COLUMNS, LOCATION_DOCUMENTS_GRID_COLUMNS, "row">
						apiRef={apiRef}
						editMode={"row"}
						disablePersistentEditRow
						loading={!documents}
						rows={documents ?? []}
						columns={columns}
						rowValidator={validator}
						onRowCreate={onCreate}
						onRowUpdate={onUpdate}
						onRowDelete={onDelete}
						sx={{ border: 'none' }}
					/>
				</Alignment>
			) : null}
		</FormPaper>
	);
});