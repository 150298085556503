import { axiosData, axiosNullableData, SourceGetter } from "@imas/api";
import { vProposalServiceVariant } from './types';

//proposal service variants base url
const PROPOSAL_SERVICE_VARIANTS_URL = "/api/proposals/proposal-service-variants";

//gets all vProposalServiceVariants
export const GetvProposalServiceVariants = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<vProposalServiceVariant[]> => {
        return await axiosData({
            method: 'get',
            url: `${PROPOSAL_SERVICE_VARIANTS_URL}/views/${serviceId.toString()}`,
            cancelToken: getSource().token,
        });
    };
};

//delete a proposal service variant
export const DeleteProposalServiceVariant = (getSource: SourceGetter) => {
    return async (serviceVariantDetailId: int): Promise<boolean> => {
        return await axiosData({
            method: 'delete',
            url: `${PROPOSAL_SERVICE_VARIANTS_URL}/${serviceVariantDetailId.toString()}`,
            cancelToken: getSource().token,
        });
    };
};

//quick add proposal service variants
export const QuickAddProposalServiceVariants = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<boolean> => {
        return await axiosData({
            method: 'post',
            url: `${PROPOSAL_SERVICE_VARIANTS_URL}/quick-add/${serviceId.toString()}`,
            cancelToken: getSource().token,
        });
    };
};

//create ProposalServiceVariants
export const CreateProposalServiceVariants = (getSource: SourceGetter) => {
    return async (serviceId: int, serviceVariantIds: int[]): Promise<void> => {
        return await axiosData({
            method: 'post',
            url: `${PROPOSAL_SERVICE_VARIANTS_URL}`,
			data: {
				serviceId,
				serviceVariantIds
			},
            cancelToken: getSource().token,
        });
    };
};