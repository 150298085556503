import { GetFileInfo } from '@imas/api/files';
import { getApi } from '@imas/api';
import axios from 'axios';
import { store } from '@imas/redux';
import { FileTables } from '@imas/api/files/types';
import { openPopup } from '@imas/utils/misc';
import { encodeSpecialChars } from '.';
interface OpenFileExplorerOptions {

};

export async function openFile(table: FileTables, file: HierarchyId, options?: OpenFileExplorerOptions): Promise<string | null> {
    //check if office mode is enabled
    const officeModeEnabled = store.getState().appSettings.value.general.officeMode;

    //get api
    const { api: getFileInfo } = getApi(GetFileInfo);

    if (officeModeEnabled) {
        try {
            //get info for this file
            const fileInfo = await getFileInfo(table, file ?? "/");
            
            //use native interop if available
            if (window?.native) {
                return window.native.openLocal(fileInfo.fullPath);
            }

            //fallback to localexplorer
            window.open("localexplorer:" + encodeSpecialChars(fileInfo.fullPath), "_blank");
            return null;
        } catch(e) {
            //return error message
            if (typeof e === "string") return e;
            else return "An unknown error has ocurred trying to open file.";
        }
    } else {
        //open web file viewer
        const popup = openPopup(
            axios.getUri({ url: '/files/view', params: { 
                table, 
                file: file ?? undefined,
            }}), 
            {
				name: `IMAS File Explorer: ${file ? `(File) ${file}` : `(Table)${table}`}`, 
            	width: 1000,
				height: Math.floor(window.screen.height - (window.screen.height * 0.1)),
			},
        );

		//check if viewer popup was opened
		if (popup === null) return "Failed to open file viewer popup.";
        return null;
    };
};