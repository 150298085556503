import { axiosData, SourceGetter } from  '@imas/api';
import { Employee, vEmployee } from "@imas/api/employee/types";
import { UserProfileForm } from './types';
import { vEmployeeHRDate } from 'src/api/types/api/employee/employee';

//user profile base url
const USER_PROFILE_URL = "/api/user/profile";

/** https://localhost:44363/swagger/index.html#/UserProfile/get_api_user_profile */
export const GetUserProfile = (getSource: SourceGetter) => {
    return async (): Promise<vEmployee> => {
        return await axiosData({
            method: 'get',
            url: `${USER_PROFILE_URL}`,
            cancelToken: getSource().token,
        });
    };
};

/** https://localhost:44363/swagger/index.html#/UserProfile/put_api_user_profile */
export const UpdateUserProfile = (getSource: SourceGetter) => {
    return async (data: UserProfileForm): Promise<Employee> => {
        return await axiosData({
            method: 'put',
            url: `${USER_PROFILE_URL}`,
            data: data,
            cancelToken: getSource().token,
        });
    };
};

export const GetUserHRDates = (getSource: SourceGetter) => {
    return async (): Promise<vEmployeeHRDate> => {
        return await axiosData({
            method: 'get',
            url: `${USER_PROFILE_URL}/hr-dates`,
            cancelToken: getSource().token,
        });
    };
};