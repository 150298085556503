import { TVehicleInspectionForm, VehicleInspection, vVehicleInspection } from 'src/api/types/api/misc/misc';
import axios from 'axios';
import { Moment } from 'moment';
import { SourceGetter } from '../types/api/api';
import { GetAvailableVehsResult, vVehicle } from '../types/api/misc/misc';
import { objectToFormData } from '@imas/api';

/**
 * Get available Vehicles
 */
 export const GetAvailableVehs = (getSource: SourceGetter) => {
    return (date: Moment, site: int): Promise<GetAvailableVehsResult[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<GetAvailableVehsResult[]>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/vehicles/get-vehicles',
               data: {
                   date: date.format(),
				   site: site
               },
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Get vVehicles
 */
 export const GetVVehicles = (getSource: SourceGetter) => {
    return (): Promise<vVehicle[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vVehicle[]>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/vehicles/vVehicles',
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Get a vVehicle
 */
 export const GetVVehicle = (getSource: SourceGetter) => {
    return (calItemId: int): Promise<vVehicle> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vVehicle>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/vehicles/vVehicles/' + calItemId.toString(),
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Get a Vehicle Inspection
 */
 export const GetVehicleInspection = (getSource: SourceGetter) => {
    return (calVehInspectionId: int): Promise<VehicleInspection> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<VehicleInspection>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/vehicles/inspections/' + calVehInspectionId.toString(),
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

//get all vehicle inspections with outstanding issues
export const GetvOpenVehicleInspections = (getSource: SourceGetter) => {
    return (): Promise<vVehicleInspection[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vVehicleInspection[]>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/vehicles/v-inspections',
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

export const GetvInspectionsForVehicle = (getSource: SourceGetter) => {
    return (calItemId: int, lastYear: boolean): Promise<vVehicleInspection[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vVehicleInspection[]>((resolve, reject) => {
           axios({
               method: 'get',
               url: `/api/vehicles/v-inspections/vehicle/${calItemId.toString()}/${lastYear ? '1' : '0'}`,
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Create a Vehicle Inspection
 */
 export const CreateVehicleInspection = (getSource: SourceGetter) => {
    return (calItemId: int, formData: TVehicleInspectionForm): Promise<VehicleInspection> => {
       //get a source for the request 
       const source = getSource();

	   formData.inspectionDate = new Date(formData.inspectionDate.toISOString().split('T')[0]);

       //make API call and return a promise which resolves into the result
       return new Promise<VehicleInspection>((resolve, reject) => {
           axios({
               method: 'post',
               url: '/api/vehicles/inspections/' + calItemId.toString(),
			   data: objectToFormData(formData),
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Update a Vehicle Inspection
 */
 export const UpdateVehicleInspection = (getSource: SourceGetter) => {
    return (calVehInspectionId: int, formData: TVehicleInspectionForm): Promise<VehicleInspection> => {
       //get a source for the request 
       const source = getSource();

	   formData.inspectionDate = new Date(formData.inspectionDate.toISOString().split('T')[0]);

       //make API call and return a promise which resolves into the result
       return new Promise<VehicleInspection>((resolve, reject) => {
           axios({
               method: 'put',
               url: '/api/vehicles/inspections/' + calVehInspectionId.toString(),
			   data: objectToFormData(formData),
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Upload a Vehicle File
 */
 export const UploadVehicleFile = (getSource: SourceGetter) => {
    return (calVehInspectionId: int, file: File): Promise<boolean> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<boolean>((resolve, reject) => {
           axios({
               method: 'post',
               url: '/api/vehicles/inspections/upload-file/' + calVehInspectionId.toString(),
			   data: objectToFormData(file),
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Upload a Vehicle File
 */
 export const DeleteVehicleFile = (getSource: SourceGetter) => {
    return (calVehInspectionId: int, fileNum: int, hierarchyId: HierarchyId): Promise<boolean> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<boolean>((resolve, reject) => {
           axios({
               method: 'delete',
               url: '/api/vehicles/inspections/files',
			   data: {
					calVehInspectionId: calVehInspectionId,
					fileNum: fileNum,
					hierarchyId: hierarchyId,
			   },
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};