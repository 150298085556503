import { memo, forwardRef } from 'react';
import { MuiForm, MuiFormPartial, MuiFormRef, useMuiForm , Editable, FormTextField } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm, ServiceOrderWorkPlaceReview } from '@imas/api/service-order';
import { WorkPlaceReviewHeader } from './WorkPlaceReviewHeader';
import { Alignment } from '@imas/components/layout';
import { WorkPlaceReviewHazardMaterials } from './WorkPlaceReviewHazardMaterials';
import { WorkPlaceReviewFallHazards } from './WorkPlaceReviewFallHazards';
import { WorkPlaceReviewConfinedSpace } from './WorkPlaceReviewConfinedSpace';
import { WorkPlaceReviewTrenches } from './WorkPlaceReviewTrenches';
import { WorkPlaceReviewLockOut } from './WorkPlaceReviewLockOut';
import { WorkPlaceReviewOther } from './WorkPlaceReviewOther';
import { WorkPlaceReviewOtherProtectiveActions } from './WorkPlaceReviewOtherProtectiveActions';
import { WorkPlaceReviewFooter } from './WorkPlaceReviewFooter';
import { WorkPlaceReviewEmployees, WorkPlaceReviewEmployeesRef } from './WorkPlaceReviewEmployees';
import { useCallbackRef } from '@imas/utils/hooks';

export const defaultValues: Editable<TServiceOrderWorkPlaceReviewForm> = {
    serviceOrderId: null,    
	date: null,
	workPlaceDesc: null,
	matNone: false,
	matDust: false,
	matSilica: false,
	matAsbestos: false,
	matSmoke: false,
	matCorrosive: false,
	matInhalant: false,
	matFlammableLiquid: false, 
	matExplosive: false,
	matReactive: false,
	matMold: false,
	matRadioactive: false,
	reqHandArmPPE: false,
	reqFootLegPPE: false,
	reqKneepad: false,
	reqEyeFacePPE: false,
	reqBodyPPE: false,
	matOther: false,
	matOtherDesc: null,
	fallNone: false,
	fallStairwell: false,
	fallPerimeter: false,
	fallRollingScaffold: false,
	fallScaffoldGreen: false,
	fallScaffoldYellow: false,
	fallScaffoldRed: false,
	fallRoof: false,
	fallLadder: false,
	fallElevatorShaft: false,
	fallLift: false,
	fallOpenArea: false,
	fallOther: false,
	fallOtherDesc: null,
	reqFullBodyHarness: false,
	spaceNone: false,
	spaceTank: false,
	spaceVessel: false,
	spaceStorage: false,
	spaceVault: false,
	spacePit: false,
	spacePipe: false,
	spaceControlled: false,
	spaceNPV: false,
	spaceNPNV: false,
	spaceOther: false,
	spaceOtherDesc: null,
	reqRespirator: false,
	reqFireExtinguisher: false,
	reqHarness: false,
	reqWheelChock: false,
	reqRetrievalSystem: false,
	reqAirSystem: false,
	trenchNone: false,
	trenchDeepTrench: false,
	trenchShallowTrench: false,
	lockoutNone: false,
	lockoutInspector: false,
	lockoutClient: false,
	otherAerialDebris: false,
	otherNight: false,
	otherElectrical: false,
	otherCrane: false,
	otherMachinery: false,
	otherNoise: false,
	otherAboveWork: false,
	otherBelowWork: false,
	otherForklift: false,
	otherHeat: false,
	otherWind: false,
	otherRain: false,
	otherSnow: false,
	otherTripping: false,
	reqHeadPPE: false,
	reqHearingPPE: false,
	reqLight: false,
	other: false,
	otherDesc: null,
	reqSignage: false,
	reqTempBarricade: false,
	reqPermBarricade: false,
	reqOther: false,
	reqOtherDesc: null,
	reqMask: false,
};

export const ServiceOrderWorkPlaceReviewForm = memo(forwardRef<MuiFormRef<TServiceOrderWorkPlaceReviewForm>, MuiFormPartial & { fullJobName?: string; serviceOrderId?: int | null; review?: ServiceOrderWorkPlaceReview | null; }>(({ fullJobName, serviceOrderId, review, ...props }, ref) => {
    //ref for WorkPlaceReviewEmployees
	const [employeesRef, onEmployeesRef] = useCallbackRef<WorkPlaceReviewEmployeesRef | null>(null);

	console.log(employeesRef);
	
	//form hook
    const { muiFormProps } = useMuiForm({ 
        defaultValues: { ...defaultValues, serviceOrderId: serviceOrderId ?? null } as Editable<TServiceOrderWorkPlaceReviewForm>,
        validator: ({ serviceOrderId, date, ...fields }, error) => {
			//conditional errors
			if (fields.matOther && fields.matOtherDesc === null) error("matOtherDesc", "required");
			if (fields.fallOther && fields.fallOtherDesc === null) error("fallOtherDesc", "required");
			if (fields.spaceOther && fields.spaceOtherDesc === null) error("spaceOtherDesc", "required");
			if (fields.other && fields.otherDesc === null) error("otherDesc", "required");

            //ensure required fields are required
            if (serviceOrderId === null) error("serviceOrderId", "required");
            if (date === null) error("date", "required");
            if (fields.workPlaceDesc === null) error("workPlaceDesc", "required");

            //ensure type safety
            if (serviceOrderId === null) return;
            if (date === null) return;

            return { serviceOrderId, date, ...fields };
        },
    });

    //separate loading from other props
    const { loading, viewOnly, ...otherProps } = props;

    //form data
    return (
        <MuiForm
            loading={loading || employeesRef?.loading}
            viewOnly={viewOnly}
            {...otherProps}
            {...muiFormProps}
            ref={ref}
        >
			<Alignment column flex sx={{ color: "text.primary" }}>
				{/* Header & General Description */}
				<WorkPlaceReviewHeader fullJobName={fullJobName} reviewedByEmployeeId={review?.reviewedByEmployeeId ?? null}/>

				{/* Hazard Materials */}
				<WorkPlaceReviewHazardMaterials/>

				{/* Fall Hazards */}
				<WorkPlaceReviewFallHazards/>

				{/* Confined Space */}
				<WorkPlaceReviewConfinedSpace/>

				{/* Trenches */}
				<WorkPlaceReviewTrenches/>

				{/* Lock Out / Tag Out */}
				<WorkPlaceReviewLockOut/>

				{/* Other */}
				<WorkPlaceReviewOther/>

				{/* Other Protective Actions */}
				<WorkPlaceReviewOtherProtectiveActions/>

				{/* Footer */}
				<WorkPlaceReviewFooter/>

				{/* List of Employees */}
				{ review ? <WorkPlaceReviewEmployees review={review} ref={onEmployeesRef}/> : review}
			</Alignment>
        </MuiForm>
    );
}));