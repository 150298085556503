import { GetProjectProposals, GetProposalProcedures, GetProposalTests, GetServiceBundleDetails, GetvProposalServices, GetvProposalServiceVariants } from '@imas/api/proposal';
import { GetMinifiedProposals, GetProposalVAdditionalScopes, GetProposalVPurchaseOrders, GetProposalVServiceOrderWorkPlaceReviews } from './../proposal/proposals';
import { DataHookResult, createUpdateHandler } from './_utils';
import { useHub, useOnHub } from '../_hubs';
import { useApi, useLoadApi, UseLoadApiOptions, UseLoadApiOptionsFromApi } from '../_internals';
import { GetProposal, GetProposals, GetProposalServices, GetProposalServiceFees, GetProposalPreLiens, GetProposalVQualiyControls, GetProposalVReports, GetProposalVReport, GetvProposalContacts, GetvProposalSiteContacts, GetvProposalBillingContacts, GetProposalDocuments, Proposal, MinifiedProposal }  from '../proposal';
import { useMemo } from 'react';
import { GetProposalvCertificationDetails } from 'src/api/certification/api';
import { GetProposalProjectRequirements } from '../proposal/project-requirements';
import { GetProposalDetails } from 'src/api/service-order/api';
import { Complaint, Feedback } from '../quality-control';

export const useProposal = (proposalId: int, options?: UseLoadApiOptionsFromApi<typeof GetProposal>) => {
	//load data
    const api = useLoadApi(GetProposal, [proposalId], [proposalId], options);

	//load signalR hub
	const hub = useHub("/proposals");

	//listen for updates
	useOnHub(hub, "Update", (type, record) => {
		//update result if id matches
		if (record.id === proposalId && type === "UPDATE") api.update(record);
	}, [proposalId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposals = (options?: UseLoadApiOptionsFromApi<typeof GetProposals>) => {
	//load data
    const api = useLoadApi(GetProposals, [], [], options);

	//use signalR hub
	const hub = useHub("/proposals");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", createUpdateHandler(api.update), []);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useMinifiedProposals = (options?: UseLoadApiOptionsFromApi<typeof GetMinifiedProposals>) => {
	//load data
    const api = useLoadApi(GetMinifiedProposals, [], [], options);

	//use signalR hub
	const hub = useHub("/proposals");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", createUpdateHandler(api.update), []);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalServices = (options?: UseLoadApiOptionsFromApi<typeof GetProposalServices>) => {
	//load data
    const api = useLoadApi(GetProposalServices, [], [], options);

	//use signalR hub
	const hub = useHub("/proposals/services");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", createUpdateHandler(api.update), []);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalServiceFees = (serviceId: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalServiceFees>) => {
	//load data
    const api = useLoadApi(GetProposalServiceFees, [serviceId], [serviceId], options);

	//use signalR hub
	const hub = useHub("/proposals/services/fees");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, fee) => {
		//ignore fees which don't belong to the specified service
		if (fee.serviceId !== serviceId) return;

		//update the list
		updateData(type, fee);
	}, [serviceId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalVAdditionalScopes = (proposalId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetProposalVAdditionalScopes>) => {
	//load data
    const api = useLoadApi(GetProposalVAdditionalScopes, [proposalId], [proposalId], options);

	//use signalR hub
	const hub = useHub("/proposals/additional-scopes");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateView", (type, record) => {
		//ignore records which dont belong to the proposal
		if (proposalId && record.proposalId !== proposalId) return;

		//update the list
		update(type, record);
	}, [proposalId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalVPurchaseOrders = (proposalId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetProposalVPurchaseOrders>) => {
	//load data
    const api = useLoadApi(GetProposalVPurchaseOrders, [proposalId], [proposalId], options);

	//use signalR hub
	const hub = useHub("/proposals/purchase-orders");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateView", (type, record) => {
		//ignore records which dont belong to the proposal
		if (proposalId && record.proposalId !== proposalId) return;

		//update the list
		update(type, record);
	}, [proposalId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalPreLiens = (proposalId: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalPreLiens>) => {
	//load data
    const api = useLoadApi(GetProposalPreLiens, [proposalId], [proposalId], options);

	//use signalR hub
	const hub = useHub("/proposals/pre-liens");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.proposalId !== proposalId) return;

		//update the list
		update(type, record);
	}, [proposalId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalVServiceOrderWorkPlaceReviews = (proposalId: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalVServiceOrderWorkPlaceReviews>) => {
	//load data
    const api = useLoadApi(GetProposalVServiceOrderWorkPlaceReviews, [proposalId], [proposalId], options);

	//use signalR hub
	const hub = useHub("/service-orders/work-place-reviews");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateView", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.proposalId !== proposalId) return;

		//update the list
		update(type, record);
	}, [proposalId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalVQualityControls = (proposalId: int, complaint: Complaint | null, feedback: Feedback | null, options?: UseLoadApiOptionsFromApi<typeof GetProposalVQualiyControls>) => {
	//load data
    const api = useLoadApi(GetProposalVQualiyControls, [proposalId], [proposalId, complaint, feedback], options);

	//use signalR hub
	const hub = useHub("/qc");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateVQualityControl", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.proposalId !== proposalId) return;

		//update the list
		update(type, record);
	}, [proposalId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalProjectRequirements = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalProjectRequirements>) => {
	//load data
    const api = useLoadApi(GetProposalProjectRequirements, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/project-requirements");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalvCertificationDetails =(proposalId: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalvCertificationDetails>) => {
	//load data
	const api = useLoadApi(GetProposalvCertificationDetails, [], [proposalId], options);	

	//use signalR hub
	//const hub = useHub("/qc");

	//update handler
	//const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	//useOnHub(hub, "UpdateVQualityControl", (type, record) => {
	//	//ignore records which dont belong to the proposal
	//	if (record.proposalId !== proposalId) return;
	//
	//	//update the list
	//	update(type, record);
	//}, [proposalId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalvReports = (proposalId: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalVReports>) => {
	//load data
    const api = useLoadApi(GetProposalVReports, [proposalId], [proposalId], options);

	//use signalR hub
	const hub = useHub("/proposals/reports");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.proposalId !== proposalId) return;

		//update the list
		update(type, record);
	}, [proposalId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalDetails = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalDetails>) => {
	//load data
    const api = useLoadApi(GetProposalDetails, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/details");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalSiteContacts = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetvProposalSiteContacts>) => {
	//load data
    const api = useLoadApi(GetvProposalSiteContacts, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/site-contacts");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalContacts = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetvProposalContacts>) => {
	//load data
    const api = useLoadApi(GetvProposalContacts, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/contacts");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalBillingContacts = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetvProposalBillingContacts>) => {
	//load data
    const api = useLoadApi(GetvProposalBillingContacts, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/billing-contacts");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalDocuments = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalDocuments>) => {
	//load data
    const api = useLoadApi(GetProposalDocuments, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/documents");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalServiceBundleDetails = (bundleId: int, options?: UseLoadApiOptionsFromApi<typeof GetServiceBundleDetails>) => {
	//load data
    const api = useLoadApi(GetServiceBundleDetails, [bundleId], [bundleId], options);

	//use signalR hub
	const hub = useHub("/proposals/bundle-details");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.bundleId !== bundleId) return;

		//update the list
		update(type, record);
	}, [bundleId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalServiceVariants = (serviceId: int, options?: UseLoadApiOptionsFromApi<typeof GetvProposalServiceVariants>) => {
	//load data
    const api = useLoadApi(GetvProposalServiceVariants, [serviceId], [serviceId], options);

	//use signalR hub
	const hub = useHub("/proposals/services/variants");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.serviceId !== serviceId) return;

		//update the list
		update(type, record);
	}, [serviceId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalProcedures = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalProcedures>) => {
	//load data
    const api = useLoadApi(GetProposalProcedures, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/procedures");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;

		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProposalTests = (pronum: int, options?: UseLoadApiOptionsFromApi<typeof GetProposalTests>) => {
	//load data
    const api = useLoadApi(GetProposalTests, [pronum], [pronum], options);

	//use signalR hub
	const hub = useHub("/proposals/tests");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the proposal
		if (record.pronum !== pronum) return;
		
		//update the list
		update(type, record);
	}, [pronum]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useProjectProposals = (options?: UseLoadApiOptionsFromApi<typeof GetProjectProposals>) => {
	//load data
    const api = useLoadApi(GetProjectProposals, [], [], options);

	//use signalR hub
	const hub = useHub("/proposals/project-proposals");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		if (type === 'DELETE') return;
		
		//update the list
		update(type, record);
	}, []);
	
	return [api.data, api] as DataHookResult<typeof api>;
};