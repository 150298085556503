import { makeDeviceStyles } from '@imas/styles';

const topNavigationStyles = makeDeviceStyles()((theme) => ({
    topNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 950,
        flex: '0 1 auto',
        backgroundColor: theme.palette.mode === 'light' ? '#F1F1F1' : theme.palette.grey[900]
    },
    desktopToolbar: {
        minHeight: '48px',
    },
    employeeInfoContainer: {
        display: 'flex',
        margin: 'auto 10px auto auto',
        flexDirection: 'row',
    },
    employeeName: {
        margin: 'auto',
        paddingRight: '5px',
        color: theme.palette.text.navigation,
    },
    navBarIcon: {
        color: theme.palette.text.navigation,
    },
    dropdownContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    dropdownButton: {
        justifyContent: "left",
        borderRadius: 0,
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        }
    },
    drawer: {
        height: '100%',
    },
    drawerColumn: {
        height: '100%',
    },
    drawerFooter: {
        margin: 'auto auto 15px auto',
    },
    version: { 
        margin: '10px auto',
    },
    subNav: {
        margin: '4px 0',
        minHeight: '32px',
    }
}));

export default topNavigationStyles;