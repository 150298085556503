import React, { forwardRef } from 'react';
import { useMergeRefs } from 'use-callback-ref';
import { FieldValues, FieldPath } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { FormInputProps } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';

export interface FormTextFieldProps<TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<string | null, TFieldValues, TName>,
    Omit<TextFieldProps, "name" | "defaultValue" | "error" | "value" | "onChange" | "onBlur" | "ref" | "select" | "SelectProps"> 
{
    disableErrorText?: boolean;
};

const _FormTextField = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
        ...textFieldProps
    }: FormTextFieldProps<TFieldValues, TName>,
	extRef: React.ForwardedRef<HTMLInputElement>
) => {
    //use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
		context: { size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

	//merge refs
	const mergeRef = useMergeRefs([ref, extRef]);

    //render loading
    if (isLoading) return <FormFieldSkeleton {...textFieldProps} size={textFieldProps.size ?? size}/>;

    //render TextField 
    return (
        <TextField
            {...textFieldProps}
			size={textFieldProps.size ?? size}
            disabled={isViewOnly || textFieldProps.disabled}
            name={name}
            required={isRequired}
            error={hasError}
            helperText={helperText || textFieldProps.helperText}
            value={value ?? ""}
            onChange={e => onChange(e.target.value === "" ? null : e.target.value)}
            onBlur={handleBlur}
            inputRef={mergeRef}
        />
    );
};

const FormTextField = forwardRef(_FormTextField);

export { FormTextField };