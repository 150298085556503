import { ListItemButton, ListItem, Typography, alpha, lighten } from '@mui/material';
import { memo, useContext, useMemo } from 'react';
import { Alignment, Spacer, LabeledItem, XSkeleton } from '@imas/components/layout';
import { formatTimeStr } from '@imas/utils/formatting';
import { ScheduleEventEntry, ScheduleType } from 'src/api/types/api/schedule/schedule';
import { DailyScheduleViewerContext } from '../DailyScheduleViewerContext';
import { makeDeviceStyles } from '@imas/styles';
import moment from 'moment';

const useStyles = makeDeviceStyles()((theme) => ({
    listItem: {
		overflow: 'hidden',
        borderTop: theme.palette.mode === 'light' ? "solid rgba(0, 0, 0, 0.15) 1px" : "solid rgba(255, 255, 255, 0.12) 1px",
    },
    currentEvent: {
        "&.Mui-selected": {
            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.secondary.main, 0.75) : alpha(theme.palette.secondary.main, 0.1),
            "&:hover": {
                backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.secondary.main, 0.7) : alpha(theme.palette.secondary.main, 0.15),
            }
        }
    },
    hasBeenWorked: {
        "&.Mui-selected": {
            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.75) : alpha(theme.palette.success.main, 0.1),
            "&:hover": {
                backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.7) : alpha(theme.palette.success.main, 0.15),
            }
        }
    },
    name: {
        fontWeight: 500,
        textDecoration: 'underline'
    }
}));

export const DailyScheduleEntrySkeleton = ({ type }: { type: ScheduleType; }) => {
	const { classes, deviceInfo } = useStyles();

	return (
		<ListItemButton className={classes.listItem}>
			<Alignment column flex>
                <Alignment row>
				    {type === "ME" ? null : <XSkeleton variant={"text"} height={30} width={75} sx={{ marginRight: '40px' }}/>}
                    <XSkeleton variant={"text"} height={30} width={90} />
                </Alignment>
				<Alignment row>
					<XSkeleton variant={"text"} height={30} width={90}/>
					<XSkeleton variant={"text"} height={30} width={90} sx={{ marginLeft: '40px' }}/>
				</Alignment>
				<XSkeleton variant={"text"} height={30} width={180}/>
				<XSkeleton variant={"text"} height={30} fillAvailable sx={{ marginTop: '10px' }}/>
				{!deviceInfo.isMd ? <XSkeleton variant={"text"} height={30} fillAvailable/> : null}
			</Alignment>
		</ListItemButton>
	);
};

export interface DailyScheduleEntry {
	entry: ScheduleEventEntry | null;
    type: ScheduleType;
    checkedInId?: int;
    onClick: (entry: ScheduleEventEntry) => void;
};

export const DailyScheduleEntry = memo(({ entry, type, checkedInId, onClick }: DailyScheduleEntry) => {
    const { classes, cx } = useStyles();

    //consume context
    const { getHours } = useContext(DailyScheduleViewerContext);

    //get hours worked for this entry
    const getHoursWorked = () => {
        if (entry === null) return 0;

        return getHours(entry);
    };

	//if entry data is null show a skeleton
    if (entry === null) return <DailyScheduleEntrySkeleton type={type}/>;

    //get value
    const hoursWorked = getHoursWorked();

    //if this is the event the user is currently clocked in for
    const clockedInEvent = checkedInId === entry.uniqueId || checkedInId === entry.dailyAssignmentId;
    
    //if this event has worked time
    const hasBeenWorked = hoursWorked !== 0;

    //Make date range
    const dateRange = () => {
        if (!entry.startDate) return "";
        if (!entry.endDate) return "";

        if (entry.startDate === entry.endDate) return moment(entry.startDate).format('M/D');
        else {
            return `${moment(entry.startDate).format('M/D')}-${moment(entry.endDate).format('M/D')}`;
        }
    };

    return (
        <ListItem
            onClick={() => { onClick(entry); }}
            className={cx(classes.listItem, { [classes.currentEvent]: clockedInEvent, [classes.hasBeenWorked]: !clockedInEvent })}
            button
            selected={type === ScheduleType.ME && (clockedInEvent || hasBeenWorked)}
			sx={{ 

			}}
        >
            <Alignment column>
                {/* show the employee's name if the recordType is anything but 'me' */}
                <Alignment row flex>
                    {type === ScheduleType.ME ? null : <Typography variant={"subtitle1"} className={classes.name} sx={{ marginRight: '40px' }}>{entry.employeeNameShort}</Typography>}

                    {/* SO Date Range */}
                    <LabeledItem label={"Date(s):"} item={dateRange()} row />
                </Alignment>

                {/* show entry lab and on-site time */}
                <Alignment row>
                    <LabeledItem label={"Lab:"} row item={formatTimeStr(entry.labTime)}/>
                    <Spacer horizontal size={"40px"}/>
                    
                    <LabeledItem label={"On-Site:"} row item={formatTimeStr(entry.onSiteTime)}/>
                    
                </Alignment> 

                {/* show entry client name */}
                <LabeledItem label={"Client: "} item={entry.client} row inline/>

                {/* Show Job Name and Number */}
                <Typography variant={"body1"} title={entry.fullJobName} sx={{ marginTop: '10px' }}>{entry.fullJobName}</Typography>

                {/* Show Hours Worked for this Entry */}
                {type === ScheduleType.ME && hoursWorked !== 0 ? ( 
					<LabeledItem label={"Hours Worked:"} item={hoursWorked.toFixed(2)} row sx={{ marginTop: '10px' }}/>
                ) : null}
            </Alignment>
        </ListItem>
    );
});