import { ClientLocation, ClientLocationForm as TClientLocationForm } from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { Typography } from '@mui/material';
import { FormNumberSelect, FormCheckbox, useMuiFormControl } from '@imas/mui-form';
import { memo } from 'react';

interface LocationPreferencesProps {
	cLocation?: ClientLocation | null;
};

const billingAndReportingMethods = [{ label: "Print & Mail", value: 0 }, { label: "PDF & Email", value: 1 }, { label: "Both", value: 2 }];

export const LocationPreferences = memo(({ cLocation }: LocationPreferencesProps) => {
	//use form control
	const control = useMuiFormControl<TClientLocationForm>();

	return (
		<Alignment column sx={{ marginTop: '10px', color: 'text.primary', width: '-webkit-fill-available' }}>
			{/* Location Preferences */}
			<Typography variant={"h6"} sx={{ fontWeight: 'bold', marginBottom: '5px' }}>{"Location Preferences"}</Typography>

			<Alignment column>
				{/* Reporting */}
				<FormNumberSelect
					control={control}
					name={"reportMethod"}
					label={"Reporting Method"}
					required
					options={billingAndReportingMethods}
					sx={{ marginBottom: '10px' }}
				/>

				{/* Billing */}
				<FormNumberSelect
					control={control}
					name={"billingMethod"}
					label={"Billing Method"}
					required
					options={billingAndReportingMethods}
					sx={{ marginBottom: '10px' }}
				/>

				{/* Attach Reports */}
				<FormCheckbox
					control={control}
					name={"billingReport"}
					label={"Attach reports when billing client"}
					//sx={{ "& .MuiFormControlLabel-label": { maxWidth: '130px' }}}
				/>
			</Alignment>
		</Alignment>
	);
});
