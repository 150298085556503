import { Moment } from 'moment';
import axios from 'axios';
import { JsonFile } from 'src/api/types/api/JsonFile';
import { SOReportVersionResult, ServiceOrderReport, ServiceOrderContact } from 'src/api/types/api/service-orders/types';
import Email from 'src/api/types/api/Email';
import ReportTypeNote from '../types/api/service-orders/service-order/ReportTypeNote';
import { ReportUpdateForm as TReportUpdateForm } from '@imas/api/service-order';
import { objectToFormData, SourceGetter, WrappedApiFunction } from  '@imas/api';

/**
 * Get a list of service order reports given a uniqueId.
 */
 export const _GetReports = (getSource: SourceGetter) => {
    return  (uniqueId: int): Promise<SOReportVersionResult[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<SOReportVersionResult[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId.toString() + "/reports",
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get a service order entry given the serviceOrder uniqueId.
 * @param {number} uniqueId Unique ID of the ServiceOrder to get.
 */
 export const _CreateReports = (getSource: SourceGetter) => {
    return (uniqueId: int, sourceIds: string[], date: Moment, isTemplate: boolean = true): Promise<ServiceOrderReport[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderReport[]>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/' + uniqueId.toString() + '/reports',
                data: {
                    sourceIds: sourceIds,
                    date: date.format(),
                    isTemplate: isTemplate
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Duplicate a list of service order reports given a list of reportIds
 */
 export const _DuplicateReports = (getSource: SourceGetter) => {
    return (uniqueId: int, reportIds: int[]): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/duplicate',
                data: reportIds,
                cancelToken: source.token
            }).then(() => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};

/** 
 *  Download a auto-filled report template given the SO uniqueId and pathLocator of the template. 
*/
export const _DownloadReportTemplate = (getSource: SourceGetter) => {
    return (uniqueId: int, templatePathLocator: HierarchyId) => {
        //create request source 
        const source = getSource();

        //return a promise which resolves into the result of the API call
        return new Promise<JsonFile>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/download-template',
                params: {
                    path: templatePathLocator,
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/** 
 *  Download a auto-filled report template given the SO uniqueId and pathLocator of the template. 
*/
export const _UploadReports = (getSource: SourceGetter) => {
    return (uniqueId: int, date: Moment, files: File[]): Promise<string[]> => {
        //create request source 
        const source = getSource();

        //create FormData object
        const formData = new FormData();

        //set the date
        formData.set("date", date.format());
        
        //append all the files
        for (var file of files) formData.append("files", file);

        //return a promise which resolves into the result of the API call
        return new Promise<string[]>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/upload',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

//updateOptions interface
interface UpdateSOReportFields {
    date?: Moment,
    index?: string,
    tag?: string,
    fileName?: string
    viewed?: boolean
    corrected?: boolean,
    revisionId?: int | null
};

/**
 * Get a service order entry given the serviceOrder uniqueId.
 */
export const _UpdateReport = (getSource: SourceGetter) => {
    return (uniqueId: number, reportId: number, data: UpdateSOReportFields): Promise<SOReportVersionResult> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<SOReportVersionResult>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/' + reportId.toString() + '/edit',
                data: {
                    date: data.date === undefined ? null : data.date.format('YYYY-MM-DD'),
                    index: data.index ?? null,
                    tag: data.tag ?? null,
                    fileName: data.fileName ?? null,
                    viewed: data.viewed ?? null,
                    corrected: data.corrected ?? null,
                    revisionId: data.revisionId === undefined ? -1 : data.revisionId    //-1 means no change (null changes the value)
                },
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Set an array of reports as viewed
 */
export const _SetReportsAsViewed = (getSource: SourceGetter) => {
    return (uniqueId: number, reportIds: number[]): Promise<boolean> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/viewed',
                data: reportIds,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  PDF a list of service order reports given a list of reportIds
 */
export const _PdfReports = (getSource: SourceGetter) => {
    return (uniqueId: int, reportIds: int[]): Promise<{ reports: SOReportVersionResult[], errorMessage: string | null }> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<{ reports: SOReportVersionResult[], errorMessage: string | null }>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/pdf',
                data: reportIds,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Send an email to a list of contents of Service Order Reports
 */
 export const _EmailReports = (getSource: SourceGetter) => {
    return (uniqueId: int, email: Email, reportIds: int[], selectedDays: int[]): Promise<ServiceOrderContact[]>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<ServiceOrderContact[]>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/' + uniqueId + '/reports/email',
                data: {
                    email,
                    reportIds,
                    selectedDays
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Delete one or many service order reports and return the updated list of reports for the ServiceOrder
 */
export const _DeleteReports = (getSource: SourceGetter) => {
    return (uniqueId: int, reportIds: int[], deleteFile: boolean): Promise<ServiceOrderReport[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderReport[]>((resolve, reject) => {
            axios({
                method: 'delete',
                url: '/api/service-order/' + uniqueId.toString() + '/reports',
                data: {
                    reportIds: reportIds,
                    deleteFile: deleteFile 
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get a list of types of reports
 */
 export const _GetReportTypes = (getSource: SourceGetter) => {
    return  (): Promise<ReportTypeNote[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ReportTypeNote[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/reports/gettypes',
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

interface reportScore {
	scores: number[],
	errorString: string,
}

/**
 * Score a Report
 */
 export const ScoreReport = (getSource: SourceGetter) => {
    return (reportid: int): Promise<reportScore> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<reportScore>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/reports/score/' + reportid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

interface reportResult {
    note: string,
    type: string,
}

export const ReportUpdate = (getSource: SourceGetter) => {
    return (SOReportID: number, EditorId: number, data: TReportUpdateForm): Promise<string> => {
             //create request source 
             console.log(data.correctionType);
             const source = getSource();

             //make API call and return a promise which resolves into the result
                return new Promise<string>((resolve, reject) => {
                    axios({
                        method: 'post',
                        url: '/api/service-orders/update/' + SOReportID.toString() + '/report/' + EditorId.toString(),
                        data: objectToFormData(data),
                        headers: { "Content-Type": "multipart/form-data"},
                        cancelToken: source.token
                    }).then((response) => {
                        //resolve promise
                        resolve(response.data);
                    }).catch(e => reject(e));
                });
    };
};