import { mapApi, ApiConfig } from '@imas/api';
import { ServiceOrderWorkPlaceReviewEmployee, ServiceOrderWorkPlaceReviewEmployeeForm } from './types';

//proposal pre liens base url
const SERVICE_ORDER_WORK_PLACE_REVIEW_EMPLOYEES = "/api/service-orders/work-place-reviews/employees";

/*  */
export const GetAllServiceOrderWorkPlaceReviewEmployees = mapApi((): ApiConfig<ServiceOrderWorkPlaceReviewEmployee[]> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEW_EMPLOYEES}`,
}));

/*  */
export const GetServiceOrderWorkPlaceReviewEmployee = mapApi((workPlaceReviewId: int): ApiConfig<ServiceOrderWorkPlaceReviewEmployee> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEW_EMPLOYEES}/${workPlaceReviewId}`,
}));

/*  */
export const CreateServiceOrderWorkPlaceReviewEmployee = mapApi((data: ServiceOrderWorkPlaceReviewEmployeeForm): ApiConfig<ServiceOrderWorkPlaceReviewEmployee> => ({
	method: 'post',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEW_EMPLOYEES}`,
	data,
}));

/*  */
export const UpdateServiceOrderWorkPlaceReviewEmployee = mapApi((workPlaceReviewId: int, data: ServiceOrderWorkPlaceReviewEmployeeForm): ApiConfig<ServiceOrderWorkPlaceReviewEmployee> => ({
	method: 'put',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEW_EMPLOYEES}/${workPlaceReviewId}`,
	data,
}));

/*  */
export const DeleteServiceOrderWorkPlaceReviewEmployee = mapApi((workPlaceReviewId: int): ApiConfig<ServiceOrderWorkPlaceReviewEmployee> => ({
	method: 'delete',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEW_EMPLOYEES}/${workPlaceReviewId}`,
}));