import { LightPoleNum, vServiceOrderLightPole } from './../../pages/LightPole/LightPoleMeasurements/LightPoleMeasurementUtils';
import { JsonFile } from 'src/api/types/api/JsonFile';
import { SourceGetter } from  '@imas/api';
import { LightPoleMeasurement } from 'src/pages/LightPole/LightPoleMeasurements/LightPoleMeasurementUtils';
import axios from 'axios';
import { Moment } from 'moment';

/**
 *  
 */
export const GetLightPoles = (getSource: SourceGetter) => {
    return (uniqueId: int, fromDate: Moment | null, toDate: Moment | null, hideFlagged: boolean): Promise<LightPoleMeasurement[]> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<LightPoleMeasurement[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: `/api/light-poles/${uniqueId}/measurements`,
                params: {
                    fromDate: fromDate ? fromDate.format() : null,
                    toDate: toDate ? toDate.format() : null,
					hideFlagged: hideFlagged,
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

//gets vLightPoles
export const GetvLightPoles = (getSource: SourceGetter) => {
    return (uniqueId: int, fromDate: Moment | null, toDate: Moment | null, hideFlagged: boolean): Promise<vServiceOrderLightPole[]> => {
        //get a source for the request 
        const source = getSource();

        console.log(uniqueId);

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderLightPole[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: `/api/vlight-poles/${uniqueId}`,
                params: {
                    fromDate: fromDate ? fromDate.format() : null,
                    toDate: toDate ? toDate.format() : null,
					hideFlagged: hideFlagged,
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export const GetLightPoleNumPronum = (getSource: SourceGetter) => {
    return (pronum: int): Promise<LightPoleNum[]> => {
        //get a source for the request 
        const source = getSource();


        //make API call and return a promise which resolves into the result
        return new Promise<LightPoleNum[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: `/api/vlight-poles/pronum/${pronum}`,
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

interface LightPoleData extends Omit<LightPoleMeasurement, "id" | "dateAdded" | "employeeId" | "uniqueId" | "pathLocator" | "date"> {
    date: Moment,
    file: JsonFile | null,
}

interface LightPoleWallThickness {
	wallThickness: number | null,
}

/**
 * 
 */
export const CreateLightPole = (getSource: SourceGetter) => {
    return (uniqueId: int, data: LightPoleData): Promise<LightPoleMeasurement> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<LightPoleMeasurement>((resolve, reject) => {
            axios({
                method: 'post',
                url: `/api/light-poles/${uniqueId}/create`,
                data: {
                    ...data,
                    date: data.date.format(),
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export const UpdateLightPole = (getSource: SourceGetter) => {
    return (lightPoleId: int, data: LightPoleData): Promise<LightPoleMeasurement> => {
        //get a source for the request 
        const source = getSource();
		console.log(data.angle);

        //make API call and return a promise which resolves into the result
        return new Promise<LightPoleMeasurement>((resolve, reject) => {
            axios({
                method: 'put',
                url: `/api/light-poles/${lightPoleId}/update`,
                data: {
                    ...data,
                    date: data.date.format(),
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export const GetLightPoleRemainingWallThickness = (getSource: SourceGetter) => {
    return (data: LightPoleData): Promise<LightPoleWallThickness> => {
        //get a source for the request 
        const source = getSource();
		console.log(data.angle);

        //make API call and return a promise which resolves into the result
        return new Promise<LightPoleWallThickness>((resolve, reject) => {
            axios({
                method: 'put',
                url: `/api/light-poles/wall-thickness`,
                data: {
                    ...data,
                    date: data.date.format(),
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};