import React from 'react';
import { Button, ButtonProps } from "@mui/material";
import { FileTables } from "@imas/api/files/types";
import { Folder as FolderIcon } from '@mui/icons-material';
import { useAutomaticSnackbar } from "@imas/utils/snackbar";
import { openFileExplorer } from "../openFileExplorer";

export interface FileExplorerLinkProps extends ButtonProps {
    table: FileTables;
    folder?: HierarchyId | null;

    //options
    name?: string;
};

const FileExplorerLink = (props: FileExplorerLinkProps) => {
    //get table & folder from button
    const { table, folder, name, ...buttonProps } = props;

    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //render button
    return (
        <Button 
            {...buttonProps}
            startIcon={buttonProps.startIcon ?? <FolderIcon/>}
            onClick={() => {
                openFileExplorer(table, folder ?? null, { name })
                .then(errMsg => {
                    if (errMsg) showSnackbar(errMsg, { variant: 'error' });
                });;
            }}
        />
    );
};

export { FileExplorerLink };