import { UpdateType } from '@imas/api';
import useEventListener from '@use-it/event-listener';
import useInterval from '@use-it/interval';
import { useCallback, useState, useRef, useEffect } from 'react';
import { EditorDataTypeMap, EditorUrls, EditorUpdate } from './types';

export function isEditorUpdate<E extends EditorUrls>(x: any): x is EditorUpdate<E> {
	if (x instanceof Object) {
		if (x?.isEditorUpdate) {
			return true;
		}

		return false;
	}

	return false;
};

export type EditorOpenerFn = (...args: any[]) => Window | null;

export interface UseEditorProps<E extends EditorUrls, F extends EditorOpenerFn> {
	url: E;
	opener: F;
	onUpdate?: (type: UpdateType, data: EditorDataTypeMap[E]) => void;
};

//hook into updates from an open editor window
export function useEditor<E extends EditorUrls, F extends EditorOpenerFn>(props: UseEditorProps<E, F>): [isOpen: boolean, openEditor: (...args: Parameters<F>) => Window | null] {
	const { url, opener, onUpdate } = props;
	
	//window opener ref
	const openerRef = useRef<F>(opener);
	useEffect(() => { openerRef.current = opener; }, [opener]);
	
	//the currently open editor object
	const [openEditor, setOpenEditor] = useState<Window | null>(null);

	//check if the editor has been closed, if it has then remove it
	useInterval(() => {
		if (openEditor && openEditor.closed) setOpenEditor(null);
	}, 200);

	//listen for message events to this window
	useEventListener("message", (event: MessageEvent) => {
		const message = event.data;

		//ensure this message is an EditorUpdate object intended for this specific useEditor instance
		if (isEditorUpdate<E>(message)) {

			//ensure this message is from the window opened by this hook
			if (openEditor !== null && message.url === url && message.windowId === openEditor.windowId) {

				//call on update callback
				if (onUpdate) onUpdate(message.type, message.data);
			}
		}
	});

	//open editor function
	const open = useCallback((...args: Parameters<F>): Window | null => {
		const editorWindow = openerRef.current(...args);
		
		//update the open editor
		setOpenEditor(editorWindow);

		return editorWindow;
	}, [setOpenEditor]);

	return [openEditor !== null, open];
};