import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { local } from "@imas/utils/storage";
import { RootState } from '../store';
import merge from 'deepmerge';

//key of the localStorage where the AppSettings is cached
const SETTINGS_KEY = "settings";

export interface GeneralAppSettings {
    theme?: "light" | "dark";
    denseTables: boolean;
    officeMode: boolean;
};

export interface CacheSettings {
	
};

export interface AdvancedSettings {
	cache: CacheSettings | null;
};

export interface AppSettings {
    general: GeneralAppSettings;
	avanced: AdvancedSettings;
};

//initial state type
interface AppSettingsState {
	value: AppSettings;
	status: string;
};

/**
 *  Functions for saving & loading settings from local storage.
 */
const saveSettings = (settings: AppSettings) => {
    //save settings to localStorage
    local.set(SETTINGS_KEY, JSON.stringify(settings));
};

const loadSettings = (): AppSettings => {
	//get settings from local storage
	const cachedSettings = local.get(SETTINGS_KEY);

	//default settings
	const defaultSettings: AppSettings = {
		general: {
			theme: undefined,
			denseTables: true,
			officeMode: false,
		},
		avanced: {
			cache: null,
		}
	};

	//if cached settings is not null overwrite default settings
	return merge(defaultSettings, JSON.parse(cachedSettings ?? "{}") as AppSettings);
};

/**
 * Slice for storing information related to authentication such as the loggedIn status of the employee or their data. 
 */
const initialState: AppSettingsState = {
	value: loadSettings(),
	status: 'idle'
};

export const appSettingsSlice = createSlice({
	name: 'appSettings',
	initialState,
	reducers: {
		updateAppSettings: (state, action: PayloadAction<AppSettings>) => {
			state.value = action.payload;
			saveSettings(state.value);
		},
		updateGeneralAppSettings: (state, action: PayloadAction<GeneralAppSettings>) => {
			state.value.general = action.payload;
			saveSettings(state.value);
		}
	},
});

export const { updateAppSettings, updateGeneralAppSettings } = appSettingsSlice.actions;

//selector functions
export const getAppSettings = (state: RootState) => state.appSettings.value;
export const getGeneralAppSettings = (state: RootState) => state.appSettings.value.general;
export const getAdvancedAppSettings = (state: RootState) => state.appSettings.value.avanced;

const appSettingsReducer = appSettingsSlice.reducer;
export { appSettingsReducer };