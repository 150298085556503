const usdFormatterNoDecimal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
	maximumFractionDigits: 0,
});

const usdFormatter2Digits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const usdFormatter3Digits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
});

//format a string/number to be USD currency
export function formatUSD(value: any, precision: 0 | 2 | 3 = 2): string {
    const number = isNaN(Number(value)) ? 0 : Number(value);

    if (precision === 2) return usdFormatter2Digits.format(number);
    if (precision === 3) return usdFormatter3Digits.format(number);
    return usdFormatterNoDecimal.format(number);
};