import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";

export interface GridFilterInputValueProps {
	item: GridFilterItem;
	applyValue: (value: GridFilterItem) => void;
	// Is any because if typed as GridApiRef a dep cycle occurs. Same happens if ApiContext is used.
	apiRef: any;
	focusElementRef?: React.Ref<any>;
}

//toNullBool 
const toNullBool = (x: any) => typeof x === "boolean" ? x : x === "string" ? x === "true" ? true : x === "false" ? false : null : null;

export function FixedGridFilterInputBoolean(props: GridFilterInputValueProps & TextFieldProps) {
	const { item, applyValue, apiRef, focusElementRef, ...others } = props;
	const [filterValueState, setFilterValueState] = React.useState(toNullBool(item.value));

	const onFilterChange = React.useCallback(
		(event) => {
			const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
			setFilterValueState(value);
			applyValue({ ...item, value });
		},
		[applyValue, item]
	);

	React.useEffect(() => {
		setFilterValueState(toNullBool(item.value));
	}, [item.value]);

	return (
		<TextField
			label={apiRef.current.getLocaleText("filterPanelInputLabel")}
			value={typeof filterValueState === "boolean" ? filterValueState ? "true" : "false" : ""}
			onChange={onFilterChange}
			variant="standard"
			select
			SelectProps={{
				native: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
			inputRef={focusElementRef}
			{...others}
		>
			<option value="">{apiRef.current.getLocaleText("filterValueAny")}</option>
			<option value="true">
				{apiRef.current.getLocaleText("filterValueTrue")}
			</option>
			<option value="false">
				{apiRef.current.getLocaleText("filterValueFalse")}
			</option>
		</TextField>
	);
};

export const getFixedGridBooleanOperators = (): GridFilterOperator[] => [
	{
		value: 'is',
		getApplyFilterFn: (filterItem: GridFilterItem) => {
			if (typeof filterItem.value !== "boolean") {
				return null;
			}

			return ({ value }): boolean => {
				return Boolean(value) === filterItem.value;
			};
		},
		InputComponent: FixedGridFilterInputBoolean,
	},
];
