import { Alignment } from '@imas/components/layout';
import React from 'react';
import { FileExplorerItemContainer } from './FileExplorerItemContainer/FileExplorerItemContainer';
import { FileExplorerMode, FileExplorerAction} from './types';
import { FileExplorerNavigation } from './FileExplorerNavigation/FileExplorerNavigation';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { useFileExplorerProps } from './useFileExplorerHandlers';
import FileExplorerContextProvider from './FileExplorerContextProvider';
import { FileExplorerFooter } from './FileExplorerFooter/FileExplorerFooter';
import { FileExplorerContextMenuBuilder } from './FileExplorerContextMenu/types';

export interface FileExplorerProps extends useFileExplorerProps {
    /** File explorer mode. */
    mode?: FileExplorerMode;
	/** Will override the upload behavior of the current mode to allow or disallow file uploading. */
	allowUpload?: boolean;
    /** File explorer footer actions. */
    actions?: FileExplorerAction[];
    /** File explorer context menu builder. */
    contextMenu?: FileExplorerContextMenuBuilder;
    /** Component sx styling. */
    sx?: SxProps<Theme>;
};

const FileExplorer = React.memo((props: FileExplorerProps) => {
    return (
        <FileExplorerContextProvider {...props}>
            <Alignment column flex overflowHidden sx={props.sx}>
                {/* Explorer Navigation */}
                <FileExplorerNavigation/>
                
                {/* Explorer Item Container */}
                <FileExplorerItemContainer/>

                {/* Explorer Footer */}
                <FileExplorerFooter actions={props.actions}/>
            </Alignment>
        </FileExplorerContextProvider>
    );
});

export { FileExplorer };