import { GetvOperationalProcedureRevisions, GetvOperationalProcedures, GetvRevisionsForProcedure } from '@imas/api/certs-and-qc';
import { useMemo } from 'react';
import { useHub, useOnHub } from '../_hubs';
import { UseLoadApiOptionsFromApi, useLoadApi } from '../_internals';
import { createUpdateHandler, DataHookResult } from './_utils';


export const useVOperationalProcedures = (options?: UseLoadApiOptionsFromApi<typeof GetvOperationalProcedures>) => {
	//load data
    const api = useLoadApi(GetvOperationalProcedures, [], [], options);

	//use signalR hub
	const hub = useHub("/qc/operational-procedures");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {

		//update the list
		update(type, record);
	}, []);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useVRevisionsForProcedure = (procId: int, options?: UseLoadApiOptionsFromApi<typeof GetvRevisionsForProcedure>) => {
	//load data
    const api = useLoadApi(GetvRevisionsForProcedure, [procId], [procId], options);

	//use signalR hub
	const hub = useHub("/qc/operational-procedure-revisions");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		if (record.procId !== procId) return;

		//update the list
		update(type, record);
	}, [procId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};