import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext, useMuiWatch, FormCheckbox, FormTextField } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo } from 'react';
import { useResetSection } from './WorkPlaceReviewFormUtils';

export const WorkPlaceReviewConfinedSpace = memo(() => {
    const { control, isLoading, setValue } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//get the value of spaceNone and spaceOther
	const [spaceNone, spaceOther] = useMuiWatch({ control, name: ["spaceNone", "spaceOther"] });

	//use reset section
	const reset = useResetSection("space");
	
	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"CONFINED SPACE: "}</b>
						{!spaceNone ? "The following confined space(s) is to be entered at this work area:" : "Confined space is to be entered at this work area."}
					</Typography>
				}
				control={<Checkbox disabled={isLoading || !spaceNone} checked={!spaceNone} onChange={() => setValue("spaceNone", false)}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={!spaceNone} sx={{ marginLeft: '45px' }}>
				<FormCheckbox
					control={control}
					name={"spaceNone"}
					label={"None"}
					onBlur={({ currentValue }) => {
						//reset values of the section if None is set to true
						if (currentValue) reset();
					}}
				/>

				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"spaceTank"}
						label={"Tank"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spaceVessel"}
						label={"Vessel"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spaceStorage"}
						label={"Storage Bin or Hopper"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spaceVault"}
						label={"Vault"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spacePit"}
						label={"Pit"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spacePipe"}
						label={"Pipe"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spaceControlled"}
						label={"Space is controlled and has been permitted by the owner of the space"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spaceNPV"}
						label={"Space is not permitted but is well ventilated with no atmospheric hazards"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"spaceNPNV"}
						label={"Space is not permitted and is not ventilated - DO NOT ENTER"}
						sx={{ marginRight: '20px'}}
					/>
				</Alignment>

				{/* Other & Description */}
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"spaceOther"}
						label={"Other"}
					/>

					{spaceOther ? (
						<FormTextField
							required
							control={control}
							name={"spaceOtherDesc"}
							multiline
							rows={3}
						/>
					) : null}
				</Alignment>
			</Collapse>

			{/* Protective Equipment */}
			<Alignment column sx={{ marginLeft: '45px' }}>
				<Typography variant={"body1"} sx={{ margin: '5px', fontWeight: 'bold' }}>{"Protective Equipment Required:"}</Typography>
				
				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"reqRespirator"}
						label={"Respirator"}
					/>

					<FormCheckbox
						control={control}
						name={"reqFireExtinguisher"}
						label={"Fire extinguisher"}
					/>

					<FormCheckbox
						control={control}
						name={"reqHarness"}
						label={"Harness"}
					/>

					<FormCheckbox
						control={control}
						name={"reqRetrievalSystem"}
						label={"Retrieval system"}
					/>

					<FormCheckbox
						control={control}
						name={"reqAirSystem"}
						label={"Air monitoring system"}
					/>
				</Alignment>
			</Alignment>
		</Alignment>
	);
});