import React, { useState, useCallback } from 'react';
import { DialogContext } from './DialogContext';
import { v4 as uuidv4 } from 'uuid';

export type DialogRecord = {
	key: UUID;
	dialog: React.ReactNode;
};

const DialogProvider: React.FC = ({ children }) => {
	//list of open dialogs
	const [dialogs, setDialogs] = useState<DialogRecord[]>([]);

	//open dialog function
	const openDialog = useCallback((dialog: React.ReactNode, options?: { key?: UUID; }): UUID => {
		//add the dialog to the list of dialogs
		const newDialog: DialogRecord = {
			key: options?.key ?? uuidv4(),
			dialog,
		};

		//add dialog to 
		setDialogs(x => [...x, newDialog]);

		return newDialog.key;
	}, []);
	
	//close dialog function
	const closeDialog = useCallback((key: UUID): void => {
		//remove the dialog record so it is closed
		setDialogs(x => x.filter(y => y.key !== key));
	}, []);

	return (
		<DialogContext.Provider 
			value={{
				open: openDialog,
				close: closeDialog,
			}}
		>
			{children}
			<React.Fragment key={"dialog-provider-container"}>
				{dialogs[0] ? dialogs[0].dialog : null}
			</React.Fragment>
		</DialogContext.Provider>
	);
};

export { DialogProvider };