import { Moment } from 'moment';
import { SourceGetter, axiosData, objectToFormData } from '@imas/api';
import { SampleCouponCode, SampleService, ServiceOrderSample, TServiceOrderSampleForm } from './types';

//service order samples base url
const SERVICE_ORDER_SAMPLES_URL = "/api/service-orders/samples";

/* Get a service order sample */
export const GetServiceOrderSample = (getSource: SourceGetter) => {
    return async (labNum: int): Promise<ServiceOrderSample> => {
		return axiosData({
			method: 'get',
			url: `${SERVICE_ORDER_SAMPLES_URL}/${labNum}`,
			cancelToken: getSource().token,
		});
    };
};

/* Get a sample coupon codes */
export const GetSampleCouponCodes = (getSource: SourceGetter) => {
    return async (): Promise<SampleCouponCode[]> => {
		return axiosData({
			method: 'get',
			url: `${SERVICE_ORDER_SAMPLES_URL}/codes`,
			cancelToken: getSource().token,
		});
    };
};

/* Get a sample services */
export const GetSampleServices = (getSource: SourceGetter) => {
    return async (): Promise<SampleService[]> => {
		return axiosData({
			method: 'get',
			url: `${SERVICE_ORDER_SAMPLES_URL}/services`,
			cancelToken: getSource().token,
		});
    };
};

/* Update a service order sample */
export const UpdateServiceOrderSample = (getSource: SourceGetter) => {
    return async (labNum: int, data: TServiceOrderSampleForm): Promise<ServiceOrderSample> => {
		return axiosData({
			method: 'put',
			url: `${SERVICE_ORDER_SAMPLES_URL}/${labNum}`,
            data: objectToFormData(data),
			cancelToken: getSource().token,
		});
    };
};

/* Duplicate a service order sample */
export const DuplicateServiceOrderSample = (getSource: SourceGetter) => {
    return async (labNum: int): Promise<int> => {
		return axiosData({
			method: 'post',
			url: `${SERVICE_ORDER_SAMPLES_URL}/${labNum}/duplicate`,
			cancelToken: getSource().token,
		});
    };
};

/* Delete a service order sample */
export const DeleteServiceOrderSample = (getSource: SourceGetter) => {
    return async (labNum: int): Promise<boolean> => {
		return axiosData({
			method: 'delete',
			url: `${SERVICE_ORDER_SAMPLES_URL}/${labNum}`,
			cancelToken: getSource().token,
		});
    };
};

/* Create a sample coupon code */
export const CreateSampleCouponCode = (getSource: SourceGetter) => {
    return async (code: string): Promise<SampleCouponCode> => {
		return axiosData({
			method: 'post',
			url: `${SERVICE_ORDER_SAMPLES_URL}/codes`,
            data: {
				item: code,
			},
			cancelToken: getSource().token,
		});
    };
};

/* Create a sample service */
export const CreateSampleService = (getSource: SourceGetter) => {
    return async (service: string): Promise<SampleService> => {
		return axiosData({
			method: 'post',
			url: `${SERVICE_ORDER_SAMPLES_URL}/services`,
            data: {
				item: service,
			},
			cancelToken: getSource().token,
		});
    };
};