import { mapApi, ApiConfig } from '@imas/api';
import { ClientLocationDocument, ClientLocationDocumentForm } from './types';

//client locstion documents base url
const CLIENT_LOCATION_DOCUMENTS_URL = "/api/clients/locations/documents";

/*  */
export const GetAllClientLocationDocuments = mapApi((): ApiConfig<ClientLocationDocument[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_DOCUMENTS_URL}`,
}));

/*  */
export const GetClientLocationDocument = mapApi((documentId: int): ApiConfig<ClientLocationDocument> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_DOCUMENTS_URL}/${documentId}`,
}));

/*  */
export const CreateClientLocationDocument = mapApi((data: ClientLocationDocumentForm): ApiConfig<ClientLocationDocument> => ({
	method: 'post',
	url: `${CLIENT_LOCATION_DOCUMENTS_URL}`,
	data: data,
	encodeAs: "FormData",
}));

/*  */
export const UpdateClientLocationDocument = mapApi((documentId: int, data: ClientLocationDocumentForm): ApiConfig<ClientLocationDocument> => ({
	method: 'put',
	url: `${CLIENT_LOCATION_DOCUMENTS_URL}/${documentId}`,
	data: data,
	encodeAs: "FormData",
}));

/*  */
export const DeleteClientLocationDocument = mapApi((documentId: int): ApiConfig<ClientLocationDocument> => ({
	method: 'delete',
	url: `${CLIENT_LOCATION_DOCUMENTS_URL}/${documentId}`,
}));