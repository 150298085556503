import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext, useMuiWatch, FormCheckbox, FormTextField } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo, useState, useEffect, useCallback } from 'react';
import { useWorkPlaceReviewSection } from './WorkPlaceReviewFormUtils';

export const WorkPlaceReviewOtherProtectiveActions = memo(() => {
    const { control, isLoading } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//if the section form area is open
	const [sectionOpen, setSectionOpen] = useState(true);

	//get section values
	const [anyTrue, getAnyTrue] = useWorkPlaceReviewSection("reqOther");

	//open
	const open = sectionOpen || anyTrue;

	//handles toggling sectionOpen
	const handleSectionToggle = useCallback((e: any, checked: boolean) => {
		if (getAnyTrue() && !checked) {
			return;
		}

		//toggle section open
		setSectionOpen(checked);
	}, [getAnyTrue, setSectionOpen]);

	//value of other
	const [other] = useMuiWatch({ control, name: ["reqOther"] });

	//when the form is finished loading get the values for this section, if any are true open the collpased area
	useEffect(() => {
		if (getAnyTrue()) setSectionOpen(true);
	}, [isLoading, getAnyTrue, setSectionOpen]);
	
	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"OTHER PROTECTIVE ACTIONS: "}</b>
						{sectionOpen ? "Check if any of the following are required:" : "Safety equipment is required."}
					</Typography>
				}
				control={<Checkbox disabled={anyTrue} checked={open} onChange={handleSectionToggle}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={open} sx={{ marginLeft: '45px' }}>
				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"reqSignage"}
						label={"Signage (Caution or danger tape, cones, etc.)"}
						sx={{ marginRight: '20px'}}
					/>
					
					<FormCheckbox
						control={control}
						name={"reqTempBarricade"}
						label={"Temporary barricades"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqPermBarricade"}
						label={"Permanent barricades"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqWheelChock"}
						label={"Wheel chocks"}
					/>
				</Alignment>

				{/* Other & Description */}
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"reqOther"}
						label={"Other measures"}
					/>

					{other ? (
						<FormTextField
							required
							control={control}
							name={"reqOtherDesc"}
							multiline
							rows={3}
						/>
					) : null}
				</Alignment>
			</Collapse>
		</Alignment>
	);
});