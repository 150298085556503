import { useApi } from '@imas/api';
import { ProposalPreLien, CreateProposalPreLien, UpdateProposalPreLien, DeleteProposalPreLien } from '@imas/api/proposal';
import { useProposal, useProposalPreLiens } from '@imas/api/data-hooks';
import { EditDataGrid, TypedEditDataGridProps, useTGridApiRef, TypedGridApiRef, TypedGridColumnDefs, getFileColDef, NULLABLE_DATE_COL_V6 } from '@imas/data-grid';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { memo, useMemo, useCallback, useRef } from 'react';
import { FileTables } from '@imas/api/files';
import Enumerable from 'linq';

export interface ProposalPreLienRM extends Pick<ProposalPreLien, "amount" | "number" | "pathLocator"> {
	submitDate: Date;
	filedDate: Date | null;
}; 

export interface ProposalPreLiensFormProps {
	/** ID of the Proposal to show PreLiens for.  */
	proposalId: int;
	/** If Grid column headers should be hidden. */
	hideHeaders?: boolean;
};

//define the column order for the phone numbers grid & which columns are editable
export type PROPOSAL_PRE_LIENS_EDIT_GRID_COLUMNS = ["submitDate", "amount", "filedDate", "number", "pathLocator"];
export type PROPOSAL_PRE_LIENS_EDIT_GRID_EDITABLE = "submitDate" | "amount" | "filedDate" | "number" | "pathLocator";

//define the grid api ref type for the phone numbers grid
export type PROPOSAL_PRE_LIENS_EDIT_GRID_API_REF = TypedGridApiRef<ProposalPreLien, ProposalPreLienRM, PROPOSAL_PRE_LIENS_EDIT_GRID_EDITABLE, "row">;

//grid props
export type PROPOSAL_PRE_LIENS_EDIT_GRID_PROPS = TypedEditDataGridProps<ProposalPreLien, ProposalPreLienRM, PROPOSAL_PRE_LIENS_EDIT_GRID_EDITABLE, PROPOSAL_PRE_LIENS_EDIT_GRID_COLUMNS, "row">

export const ProposalPreLiensForm = memo(({ proposalId, hideHeaders }: ProposalPreLiensFormProps) => {
	//propsRef
	const proposalIdRef = useRef(proposalId);
	proposalIdRef.current = proposalId;

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();
	
	//get proposal record
	const [proposal] = useProposal(proposalId);

	//get pre liens for specified proposal
	const [preLiens] = useProposalPreLiens(proposalId);

	//APIs
	const createPreLien = useApi(CreateProposalPreLien);
	const updatePreLien = useApi(UpdateProposalPreLien);
	const deletePreLien = useApi(DeleteProposalPreLien);

	//grid apiRef
	const apiRef: PROPOSAL_PRE_LIENS_EDIT_GRID_API_REF = useTGridApiRef();

	//memoized grid column types
	const columnTypes = useMemo(() => ({
		"job-file": getFileColDef({ 
			table: FileTables.JobFiles, 
			folder: proposal?.pathLocator, 
			maxLevelsUp: 0, 
			filter: (items) => Enumerable.from(items).where(x => x.type === "wpd" || x.type === "doc" || x.type === "docx" || x.type === "pdf").toArray(),
			folderRequired: true, 
			folderRequiredMessage: "Job Folder does not exist and must be created before adding a pre lien file." 
		}),
	}), [proposal?.pathLocator]);

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<ProposalPreLien, ProposalPreLienRM, PROPOSAL_PRE_LIENS_EDIT_GRID_EDITABLE, PROPOSAL_PRE_LIENS_EDIT_GRID_COLUMNS, "row"> => {
        return [
			{ 
				editable: true, 
				field: 'submitDate', 
				headerName: 'Submit On', 
				width: 90,
				type: 'required-date',
				
			},
            { editable: true, field: 'amount', headerName: 'Amount', width: 90, type: 'required-usd' },
			{ 
				editable: true, 
				field: 'filedDate', 
				headerName: 'Filed On', 
				width: 90, 
				...NULLABLE_DATE_COL_V6
			},
			{ editable: true, field: 'number', headerName: 'Lien No.', flex:1, minWidth: 90 },
			{ 
				editable: true, 
				field: 'pathLocator', 
				headerName: 'File', 
				width: 90,
				type: 'job-file',
			},
        ];
    }, []);

	//validator
	const validator = useCallback<PROPOSAL_PRE_LIENS_EDIT_GRID_PROPS["rowValidator"]>(async (row) => {
		if (row.submitDate === undefined) throw new Error("Submit On is required.");
		if (row.amount === undefined) throw new Error("Amount is required.");
		
		return {
			submitDate: row.submitDate,
			amount: row.amount,
			filedDate: row.filedDate ?? null,
			number: row.number || null,
			pathLocator: row.pathLocator || null,
		};
	}, []);
	
	//row create handler
	const onCreate = useCallback<PROPOSAL_PRE_LIENS_EDIT_GRID_PROPS["onRowCreate"]>(async (row) => {
		//get current proposalId
		const proposalId = proposalIdRef.current;

		//create the record
		return await createPreLien({ proposalId, ...row });
	}, [createPreLien]);

	//row update handler
	const onUpdate = useCallback<PROPOSAL_PRE_LIENS_EDIT_GRID_PROPS["onRowUpdate"]>(async (id, row, { proposalId }) => {
		//update the record
		return await updatePreLien(id, { proposalId, ...row });
	}, [updatePreLien]);
	
	//row delete handler
	const onDelete = useCallback<Exclude<PROPOSAL_PRE_LIENS_EDIT_GRID_PROPS["onRowDelete"], undefined>>(async (id, phoneNumber) => {
		//confirm the user wants to delete the phone number
		const deleteConfirmed  = await confirm({
			title: "Delete Confirmation",
			prompt: (
				<>
					<Typography variant={"body1"}>{`Are you sure you would like to delete the following ?`}</Typography><br/>
					<Typography variant={"body1"} sx={{ textAlign: 'center' }}>{phoneNumber.number}</Typography>
				</>
			),
			confirmText: "Delete",
			confirmIcon: <DeleteIcon/>
		});
		if (!deleteConfirmed) throw new Error();
		
		//delete the record
		await deletePreLien(id);
	}, [deletePreLien, confirm]);

	return (
		<EditDataGrid<ProposalPreLien, ProposalPreLienRM, PROPOSAL_PRE_LIENS_EDIT_GRID_EDITABLE, PROPOSAL_PRE_LIENS_EDIT_GRID_COLUMNS, "row">
			apiRef={apiRef}
			editMode={"row"}
			columnHeaderHeight={hideHeaders ? 0 : undefined}
			loading={!preLiens}
			rows={preLiens ?? []}
			columnTypes={columnTypes}
			columns={columns}
			rowValidator={validator}
			onRowCreate={onCreate}
			onRowUpdate={onUpdate}
			onRowDelete={onDelete}
			sx={{ border: 'none' }}
		/>
	);
});