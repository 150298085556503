import { mapApi, ApiConfig } from '@imas/api';
import { PhoneNumber, PhoneNumberForm, PhoneNumberGroupTypes } from './types';

//phone numbers base url
const PHONE_NUMBERS_URL = "/api/phone-numbers";

/*  */
export const GetPhoneNumbers = mapApi((groupType?: PhoneNumberGroupTypes, groupId?: int): ApiConfig<PhoneNumber[]> => ({
	method: 'get',
	url: `${PHONE_NUMBERS_URL}`,
	params: {
		groupTypeId: groupType,
		groupId,
	},
}));

/*  */
export const GetPhoneNumber = mapApi((phoneNumberId: int): ApiConfig<PhoneNumber[]> => ({
	method: 'get',
	url: `${PHONE_NUMBERS_URL}/${phoneNumberId}`,
}));

/*  */
export const CreatePhoneNumber = mapApi((data: PhoneNumberForm): ApiConfig<PhoneNumber> => ({
	method: 'post',
	url: `${PHONE_NUMBERS_URL}`,
	data,
}));

/*  */
export const UpdatePhoneNumber = mapApi((phoneNumberId: int, data: PhoneNumberForm): ApiConfig<PhoneNumber> => ({
	method: 'put',
	url: `${PHONE_NUMBERS_URL}/${phoneNumberId}`,
	data,
}));		

/*  */
export const DeletePhoneNumber = mapApi((phoneNumberId: int): ApiConfig<PhoneNumber> => ({
	method: 'delete',
	url: `${PHONE_NUMBERS_URL}/${phoneNumberId}`,
}));		