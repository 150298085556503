import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { useMuiFormContext, useMuiWatch } from '@imas/mui-form';
import { useMemo, useCallback } from 'react';

interface WorkPlaceReviewSectionMap {
	"mat": ["matDust", "matSilica", "matAsbestos", "matSmoke", "matCorrosive", "matInhalant", "matFlammableLiquid", "matExplosive",
		"matReactive", "matMold", "matRadioactive", "matOther"];
	"fall": ["fallStairwell", "fallPerimeter", "fallRollingScaffold", "fallScaffoldGreen", "fallScaffoldYellow", "fallScaffoldRed",
		"fallRoof", "fallLadder", "fallElevatorShaft", "fallLift", "fallOpenArea", "fallOther"];
	"space": ["spaceTank", "spaceVessel", "spaceStorage", "spaceVault", "spacePit", "spacePipe", "spaceControlled", "spaceNPV", "spaceNPNV", "spaceOther"];
	"trench": ["trenchDeepTrench", "trenchShallowTrench"];
	"lock-out": ["lockoutInspector", "lockoutClient"];
	"other": ["otherAerialDebris", "otherNight", "otherElectrical", "otherCrane", "otherMachinery", "otherAboveWork", "otherBelowWork", "otherForklift", 
		"otherHeat", "otherWind", "otherRain", "otherSnow", "otherTripping", "otherNoise", "other"];
	"reqOther": ["reqSignage", "reqTempBarricade", "reqPermBarricade"];
};

const sectionMap: WorkPlaceReviewSectionMap = {
	"mat": ["matDust", "matSilica", "matAsbestos", "matSmoke", "matCorrosive", "matInhalant", "matFlammableLiquid", "matExplosive",
		"matReactive", "matMold", "matRadioactive", "matOther"],
	"fall": ["fallStairwell", "fallPerimeter", "fallRollingScaffold", "fallScaffoldGreen", "fallScaffoldYellow", "fallScaffoldRed",
		"fallRoof", "fallLadder", "fallElevatorShaft", "fallLift", "fallOpenArea", "fallOther"],
	"space": ["spaceTank", "spaceVessel", "spaceStorage", "spaceVault", "spacePit", "spacePipe", "spaceControlled", "spaceNPV", "spaceNPNV", "spaceOther"],
	"trench": ["trenchDeepTrench", "trenchShallowTrench"],
	"lock-out": ["lockoutInspector", "lockoutClient"],
	"other": ["otherAerialDebris", "otherNight", "otherElectrical", "otherCrane", "otherMachinery", "otherAboveWork", "otherBelowWork", "otherForklift", 
		"otherHeat", "otherWind", "otherRain", "otherSnow", "otherTripping", "otherNoise", "other"],
	"reqOther": ["reqSignage", "reqTempBarricade", "reqPermBarricade"],
};

export const useWorkPlaceReviewSection = <S extends keyof WorkPlaceReviewSectionMap>(section: S): [boolean, () => boolean] => {
    const { control, getValues } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();
	
	//get section values
	const sectionValues = useMuiWatch<TServiceOrderWorkPlaceReviewForm, WorkPlaceReviewSectionMap[S]>({ 
		control: (control as unknown as any), 
		name: sectionMap[section]
	});

	//is true if any of sectionValues are true
	const anyTrue = useMemo((): boolean => {
		return (sectionValues as any[]).some(x => x);
	}, [sectionValues]);

	//checks if any values in this section of the form are set to true when called
	const getAnyTrue = useCallback((): boolean => {
		//get values for this part of the form
		const values = getValues<WorkPlaceReviewSectionMap[S]>(sectionMap[section]);

		//if any of the values are true set sectionOpen to true
		return (values as any[]).some(x => x); 
	}, [section, getValues]);

	return [anyTrue, getAnyTrue];
};

export const useResetSection = <S extends keyof WorkPlaceReviewSectionMap>(section: S): (() => void) => {
    const { setValue } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	return useCallback(() => {
		//get fields for the specified section of the form
		const fields = sectionMap[section];

		for (const field of fields) {
			setValue(field as any, false);
		}

		//based on the section reset the description field
		switch (section) {
			case "materials":
				return setValue("matOtherDesc", null);
			case "fall":
				return setValue("fallOtherDesc", null);
			case "space":
				return setValue("spaceOtherDesc", null);
			default:
				return;
		}

	}, [section, setValue]);
};