import { DataHookResult, createUpdateHandler } from './_utils';
import { useHub, useOnHub } from '../_hubs';
import { useLoadApi, UseLoadApiOptionsFromApi } from '../_internals';
import { GetPhoneNumbers, PhoneNumberGroupTypes }  from '../phone-numbers';
import { useMemo } from 'react';

export const usePhoneNumbers = (groupType?: PhoneNumberGroupTypes, groupId?: int, options?: UseLoadApiOptionsFromApi<typeof GetPhoneNumbers>) => {
	//load data
    const api = useLoadApi(GetPhoneNumbers, [groupType, groupId], [groupType, groupId], options);

	//load signalR hub
	const hub = useHub("/phone-numbers");

	//update handler
	const upateFees = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, phoneNumber) => {
		//ignore fees which don't belong to the specified service
		if (groupType !== undefined && groupId !== undefined) {
			if (phoneNumber.groupTypeId !== groupType || phoneNumber.groupId !== groupId) return;
		}

		//update the list of fees
		upateFees(type, phoneNumber);
	}, [groupType, groupId]);

	return [api.data, api] as DataHookResult<typeof api>;
};
