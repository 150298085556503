import { Alignment } from '@imas/components/layout';
import { memo, useMemo } from 'react';
import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs } from '@imas/data-grid';
import { useLoadApi } from '@imas/api';
import { Typography } from '@mui/material';
import { GetPoReferences, PoReference } from '@imas/api/client';
import { Launch } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

export interface PurchaseOrderReferencesGridProps {
    purchaseOrderId: int | undefined,
};

//define the column order
export type PURCHASE_ORDER_REFERENCES_GRID_COLUMNS = ["actions", "name"];

//define the grid api ref type
export type PURCHASE_ORDER_REFERENCES_GRID_API_REF = TypedGridApiRef<PoReference, PoReference, never, "row">;

//define grid props type
export type PURCHASE_ORDER_REFERENCES_GRID_PROPS = TypedEditDataGridProps<PoReference, PoReference, never, PURCHASE_ORDER_REFERENCES_GRID_COLUMNS, "row">

export const PurchaseOrderReferencesGrid = memo(({ purchaseOrderId } : PurchaseOrderReferencesGridProps) => {

    //load records
    const {data: references} = useLoadApi(GetPoReferences, [purchaseOrderId ?? -1], [purchaseOrderId], {disabled: !purchaseOrderId});

	//grid apiRef
	const apiRef: PURCHASE_ORDER_REFERENCES_GRID_API_REF = useTGridApiRef();

	//memoized grid column defs
	const columns = useMemo((): TypedGridColumnDefs<PoReference, PoReference, never, PURCHASE_ORDER_REFERENCES_GRID_COLUMNS, "row"> => {
		return [
			{ field: 'actions', headerName: '', type: 'actions', width: 50, 
                getActions: ({ id }) => ([
                    <GridActionsCellItem icon={<Launch/>} disabled={true} label={"Open Proposal"} color={"inherit"}/>
                ]),
            },
			{ field: 'name', headerName: 'Name', flex: 1},
		];
	}, []);

    if (!purchaseOrderId) return (
		<Alignment column sx={{height: '100%', width: '400px', marginLeft: '20px'}}>
            <Typography color={'textPrimary'} variant={'body1'}>{'The referenced jobs grid is not available until the purchase order has been created.'}</Typography>
        </Alignment>
    );

	return (

		<Alignment column sx={{height: '100%', width: '400px', marginLeft: '20px'}}>
            <Typography color={'textPrimary'} variant={'h6'} fontWeight={'bold'} sx={{marginBottom: '10px'}}>{'Reference Job No(s)'}</Typography>
			<Alignment column flex>
				<TDataGrid<PoReference, PoReference, never, PURCHASE_ORDER_REFERENCES_GRID_COLUMNS, "row">
					apiRef={apiRef}
					editMode={"row"}
					disableColumnMenu
					loading={!references}
					rows={references ?? []}
					columns={columns}
            	    columnHeaderHeight={0}
					sx={{height: '100%'}}
				/>
			</Alignment>
		</Alignment>
	);
});