import { axiosData, SourceGetter } from '@imas/api';
import axios from 'axios';
import { Moment } from 'moment';
import { ReportEmailTemplate, ServiceOrderReport } from './types';
import GetReportsEmailItem from './types/reports/GetReportsEmailItem';
import GetReportsEmailRequest from './types/reports/GetReportsEmailRequest';

//service order reports base url
const SERVICE_ORDER_REPORTS_URL = "/api/service-orders/reports";

/* Gets the email for sending out service order reports */
/*
export const GetServiceOrderReportsEmail = (getSource: SourceGetter) => {
    return async (uniqueId: int, selectedDays: int[], selectedLists: int[], selectedSingleContacts: int[]): Promise<ReportEmailTemplate> => {
		const request: GetReportsEmailRequest = {selectedDays: [0], selectedLists: [], selectedSingleContacts: [16075]};
		console.log('emailrequest: ' + request.selectedDays, + request.selectedLists, + request.selectedSingleContacts);
		console.log(request.selectedDays);
		console.log(request.selectedLists);
		console.log(request.selectedSingleContacts);
        //make API call
        const result = await axios({
            method: 'get',
            url: `/api/service-orders/reports/email/${uniqueId}`,
            data: {
                selectedDays: [0],
				selectedLists: [-45],
				selectedSingleContacts: [16075],
            },
            cancelToken: getSource().token,
        });
        
        return result.data;
    };
};
*/
const encode = (iarray: int[], code: string) => {
	let sarray: string[] = [];
	iarray.forEach(x => {
		const encoded = code + x.toString();
		sarray.push(encoded);
	});
	return sarray;
};

export const GetServiceOrderReportsEmail = (getSource: SourceGetter) => {
    return (uniqueId: int, selectedDays: int[], selectedLists: int[], selectedSingleContacts: int[], isFinal: boolean, isCorrected: boolean): Promise<ReportEmailTemplate> => {
		const source = getSource();
		const dayStrings = encode(selectedDays, 'd');
		const listStrings = encode(selectedLists, 'l');
		const contactStrings = encode(selectedSingleContacts, 'c');
		const items = dayStrings.concat(listStrings, contactStrings);

        return new Promise<ReportEmailTemplate>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: `/api/service-orders/reports/email/${uniqueId}/${isFinal ? 1 : 0}/${isCorrected ? 1 : 0}`,
	            params: {
	                querystring:items.join(","),
	            },
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};


/*  */
export const LinkServiceOrderReport = (getSource: SourceGetter) => {
    return async ({ serviceOrderId, pathLocator, reportDate }: { serviceOrderId: int; pathLocator: HierarchyId; reportDate?: Moment | Date | null; }): Promise<ServiceOrderReport> => {
		return axiosData({
			method: 'post',
			url: `${SERVICE_ORDER_REPORTS_URL}/link`,
			data: {
				serviceOrderId,
				pathLocator,
				reportDate: reportDate ? reportDate instanceof Date ? reportDate : reportDate.toDate() : undefined,
			},
			cancelToken: getSource().token,
		});
	};
};