import { FolderInfoBreadcrumb, FolderInfoItem, FileTables } from '@imas/api/files/types';
import { createContext } from 'react';
import { 
    FileExplorerMode, Directory, FileExplorerCanNavigateFunction, FileExplorerNavigateFunction, FileExplorerRefreshFunction, FileExplorerUploadFunction, 
    FileExplorerRenameFunction, FileExplorerMoveFunction, FileExplorerDeleteFunction 
} from './types';
import { FileExplorerContextMenuRef } from './FileExplorerContextMenu/FileExplorerContextMenu';

export interface FileExplorerPreferences {
    /** If select checkboxes should be shown. */
    selectCheckboxes: boolean;
    /** If the file extension should be included in the shown filename. */
    includeExtInFilename: boolean;
};

export interface TFileExplorerContext {
    /** Preferences for FileExplorer Instance */
    preferences: FileExplorerPreferences;
    /** Currently Displayed Directory */
    currentDir: Directory;
    /** The current operation mode of this FileExplorer Instance */
    mode: FileExplorerMode;
    /** The FileTable currently being accessed. */
    table: FileTables;
    /** Items for the currently loaded folder. */
    items?: FolderInfoItem[];
    /** Breadcrumbs for the currently loaded folder. */
    breadcrumbs?: FolderInfoBreadcrumb[];
    /** List of currently selected items. */
    selected: FolderInfoItem[];
	/** If uploading is currently enabled for this FileExplorer instance. */
	canUpload: boolean;
    /** Navigation history. */
    navHistory: Directory[];
    /** Go back history. */
    goBackHistory: Directory[];
    /** Context Menu Interface */
    contextMenu: React.MutableRefObject<FileExplorerContextMenuRef>;
	/** Sorting method */
	sorting: int;
    /** Dispatch to modify selected items. */
    setSelected: React.Dispatch<React.SetStateAction<FolderInfoItem[]>>;
	/** Dispatch to modify selected items. */
    setSorting: React.Dispatch<React.SetStateAction<int>>;
    /** If the given folder can be navigated to. */
    canNavigateTo: FileExplorerCanNavigateFunction;
    /** Navigate to the specified folder. */
    navigate: FileExplorerNavigateFunction;
    /** Navigate to the previous folder. */
    goBack: () => void;
    /** Undo go back action. */
    undoGoBack: () => void;
    /** Refresh the FileExplorer's current data so it is up to date. */
    refresh: FileExplorerRefreshFunction;
    /** Upload a new file. */
    upload: FileExplorerUploadFunction;
    /** Re-name an existing file. */
    rename: FileExplorerRenameFunction;
    /** Move an existing file to a new folder. */
    move: FileExplorerMoveFunction;
    /** Delete an existing file. */
    delete: FileExplorerDeleteFunction;
};

export const FileExplorerContext = createContext<TFileExplorerContext>({
    preferences: {
        selectCheckboxes: true,
        includeExtInFilename: true,
    },
    currentDir: { name: "Loading...", pathLocator: "/" },
    mode: "explorer",
    table: FileTables.JobFiles,
    items: undefined,
    breadcrumbs: undefined,
    selected: [],
	canUpload: false,
    navHistory: [],
    goBackHistory: [],
    contextMenu: { 
		current: {
        	open: () => {},
        	close: () => {},
		},
    },
	sorting: 0,
    setSelected: () => {},
    setSorting: () => {},
    canNavigateTo: () => false,
    navigate: () => {},
    goBack: () => {},
    undoGoBack: () => {},
    refresh: () => {},
    upload: async () => {},
    rename: async () => {},
    move: async () => {},
    delete: async () => {},
});