import { ClientLocationContact, ClientLocationContactForm as TClientLocationContactForm } from '@imas/api/client';
import { useClientLocationContacts } from '@imas/api/data-hooks';
import { FormTextField, FormAutocomplete, useMuiFormControl, FormPaper } from '@imas/mui-form';
import { Alignment } from '@imas/components/layout';
import { Typography, TextField } from '@mui/material';
import { memo, useMemo } from 'react';
import Enumerable from 'linq';
import { STRING_AUTOCOMPLETE_VIRTUALIZER } from '@imas/utils/virtualization';
import { PhoneNumbersForm } from '@imas/components/forms';
import { PhoneNumberGroupTypes } from '@imas/api/phone-numbers';
import { ClientPreferences } from '../../ClientEditor/ClientForm/ClientPreferences';

export const CONTACT_SUFFIXES = [
    "I",
    "II",
    "III",
    "Jr.",
    "Sr."
];

export const CONTACT_TITLES = [
    "Mr.",
    "Mrs.", 
    "Ms.",
    "Miss.",
    "Dr.",
    "Prof."
];

interface ClientLocationContactDetailsProps {
	contact?: ClientLocationContact | null;
};

export const ClientLocationContactDetails = memo(({ contact }: ClientLocationContactDetailsProps) => {
	//get form control
	const control = useMuiFormControl<TClientLocationContactForm>();

	//get all contacts
	const [contacts] = useClientLocationContacts();

	//get a distinct list of job titles
	const jobTitles = useMemo(() => contacts ? Enumerable.from(contacts).select(x => x.jobTitle ?? "").where(x => x !== "").distinct().orderBy(x => x).toArray() : undefined, [contacts]);

	//get a distinct list of departments
	const departments = useMemo(() => contacts ? Enumerable.from(contacts).select(x => x.department ?? "").where(x => x !== "").distinct().orderBy(x => x).toArray() : undefined, [contacts]);

	return (
		<Alignment column sx={{ marginTop: '15px', color: 'text.primary', width: '-webkit-fill-available' }}>
			<Alignment row sx={{ marginBottom: '10px' }}>
				<Alignment column flex sx={{ marginRight: '10px' }}>
					<Typography variant={"h6"} sx={{ marginBottom: '5px', fontWeight: 'bold' }}>{"Contact Details"}</Typography>

					<Alignment row sx={{ marginBottom: '10px' }}>
						{/* Title Field */}
						<FormAutocomplete
							name={"title"}
							control={control}
							freeSolo
							onInputChange={(option) => option}

							autoHighlight
							options={CONTACT_TITLES ?? []}
							getValue={(value) => value}
							onChange={option => option}
							renderInput={props => <TextField label={"Title"} {...props}/>}
							sx={{ maxWidth: '150px' }}
							fullWidth
						/>
							
						{/* Suffix Field */}
						<FormAutocomplete
							name={"suffix"}
							control={control}
							freeSolo
							onInputChange={(option) => option}
							autoHighlight
							options={CONTACT_SUFFIXES ?? []}
							getValue={(value) => value}
							onChange={option => option}
							renderInput={props => <TextField label={"Suffix"} {...props}/>}
							sx={{ maxWidth: '150px', marginLeft: 'auto' }}
							fullWidth
						/>
					</Alignment>

					<Alignment row sx={{ marginBottom: '10px' }}>
						{/* First Name Field */}
						<FormTextField
							control={control}
							name={"first"}
							label={"First"}
							required
							fullWidth
						/>

						{/* Middle Name Field */}
						<FormTextField
							control={control}
							name={"middle"}
							label={"Middle"}
							sx={{ margin: '0 10px', width: '100px', minWidth: '120px' }}
						/>

						{/* Last Name Field */}
						<FormTextField
							control={control}
							name={"last"}
							label={"Last"}
							fullWidth
						/>
					</Alignment>

					{/* Preferred First Name Field */}
					<FormTextField
						control={control}
						name={"preferredFirst"}
						label={"Preferred First"}
						fullWidth
						sx={{ maxWidth: '50%', marginBottom: '10px' }}
					/>

					{/* Job Title Field */}
					<FormAutocomplete
						name={"jobTitle"}
						control={control}
						freeSolo
						onInputChange={(option) => option}
						autoHighlight
						optionsLoading={!jobTitles}
						options={jobTitles ?? []}
						getValue={(value) => value}
						onChange={option => option}
						renderInput={props => <TextField label={"Job Title"} {...props}/>}
						sx={{ marginBottom: '10px' }}
						fullWidth
						
						//add virtualization
						{...STRING_AUTOCOMPLETE_VIRTUALIZER}
					/>

					{/* Department Field */}
					<FormAutocomplete
						name={"department"}
						control={control}
						freeSolo
						onInputChange={(option) => option}
						autoHighlight
						optionsLoading={!departments}
						options={departments ?? []}
						getValue={(value) => value}
						onChange={option => option}
						renderInput={props => <TextField label={"Department"} {...props}/>}
						fullWidth
						
						//add virtualization
						{...STRING_AUTOCOMPLETE_VIRTUALIZER}
					/>
				</Alignment>
				<Alignment column sx={{ width: '430px', minWidth: '430px' }}>
					{/* Phone Number Editor */}
					<FormPaper sx={{ display: 'flex', flexDirection: 'column', flex: '1', color: 'text.primary', marginBottom: '10px' }}>
						<Typography variant={"h6"} sx={{ marginLeft: '10px', marginTop: '3px', fontWeight: 'bold' }}>{"Phone Numbers"}</Typography>
					
						{contact === null ? (
							<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until contact is saved."}</Typography>
						) : contact ? (
							<Alignment column flex>
								<PhoneNumbersForm groupType={PhoneNumberGroupTypes.Contact} groupId={contact.id}/>
							</Alignment>
						) : null}
					</FormPaper>
					
					{/* Email Field */}
					<FormTextField
						control={control}
						name={"email"}
						label={"Email"}
						fullWidth
						sx={{ marginBottom: '10px' }}
					/>

					{/* CC Email Field */}
					<FormTextField
						control={control}
						name={"ccEmail"}
						label={"CC Email"}
						fullWidth
					/>
				</Alignment>
			</Alignment>

			<Alignment row>
				{/* Contact Notes */}
				<FormTextField
					control={control}
					name={"notes"}
					label={"Contact Notes"}
					multiline
					rows={3}
					fullWidth
					sx={{ marginRight: '10px' }}
				/>
				
				{/* Contact History */}
				<FormTextField
					control={control}
					name={"history"}
					label={"Contact History"}
					multiline
					rows={3}
					fullWidth
				/>
				
			</Alignment>
			
		</Alignment>
	);
});