import { Button, SvgIconTypeMap, ButtonPropsColorOverrides, TextField } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { OverridableStringUnion } from '@mui/types';
import { fileListToArray } from '@imas/utils/files';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { FileImportCallback, FileImporterRef } from './types';

//FileImporterProps interface
interface FileImporterInputProps {
    disabled?: boolean,
    fileTypeWhitelist?: string[],
    multiple?: boolean,
    capture?: boolean | "environment" | "user",
    onImport: FileImportCallback,
}

const FileImporterInput= forwardRef<FileImporterRef, FileImporterInputProps>((props, ref) => {
    //component props
    const { disabled, multiple, capture, onImport } = props;
    const fileTypeWhitelist = props.fileTypeWhitelist ?? [];

    //use automatic hiding snackbar
    const showSnackbar = useAutomaticSnackbar();

    const inputRef = useRef<HTMLInputElement>(null);

    //fileImportHandler - takes a FileList and converts it into a File[]
    const fileImportHandler = useCallback<(files: FileList | File[]) => void>((files) => {
        try {
        //if the object provided is a FileList
        if (files instanceof FileList) {
            let fileArray = fileListToArray(files);

            //check file types if there is a whitelist
            if (fileTypeWhitelist.length !== 0) {
                fileArray.forEach((file) => {
                    let fileType = file.name.substring(file.name.lastIndexOf("."));
                    if (!fileTypeWhitelist.includes(fileType)) {
                        showSnackbar(`File type \"${fileType}\" not allowed.`, { variant: 'error'}, true);
                        throw new TypeError("File Type is wrong.");
                    }
                });
            }
            onImport(fileArray);
        } else {
            //check file types if there is a whitelist
            if (fileTypeWhitelist.length !== 0) {
                files.forEach((file) => {
                    let fileType = file.name.substring(file.name.lastIndexOf("."));
                    if (!fileTypeWhitelist.includes(fileType)) {
                        showSnackbar(`File type \"${fileType}\" not allowed.`, { variant: 'error'}, true);
                        throw new TypeError("File Type is wrong.");
                    }
                });
            }
            onImport(files);
        };
        } catch {}
    }, [onImport, fileTypeWhitelist]);

    const openImportDialog = useCallback(() => {
        inputRef.current?.click();
    }, []);

    //set ref on load
    useImperativeHandle(ref, () => ({
        import: fileImportHandler,
        open: openImportDialog,
    }), [fileImportHandler, openImportDialog]);

    //Rendered Button
    return (
        <input
            disabled={disabled}
            accept={fileTypeWhitelist.length === 0 ? undefined : fileTypeWhitelist.join(",")}
            type={"file"}
            hidden
            onChange={(event) => {
                //ensure files is not null
                if (event.target.files === null) return;
                
                //handle uploading the files
                fileImportHandler(event.target.files);
            }}
            multiple={multiple}
            capture={capture}
            ref={inputRef}
        />
    );
});


export { FileImporterInput };