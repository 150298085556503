import { useSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';

/**
 * 	Components for configuring notistack to work outside React components.
 */

interface IProps {
    setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

//inner config component
const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
};

//reference to useSnackbar
export const useSnackbarRef: { current?: WithSnackbarProps } = {};

//function to set useSnackbarRef
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
    useSnackbarRef.current = useSnackbarRefProp;
};

//external config component
export const SnackbarUtilsConfigurator = () => {
    return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};