export interface FormatAddressProps {
	address1?: string | null;
	address2?: string | null;
	city?: string | null;
	state?: string | null;
	zip?: string | null;
	country?: string | null;
};

export function formatAddress<T = string>({ address1, address2, city, state, zip, country } : FormatAddressProps, defaultValue?: T): string | T {
	if (`${address1 ?? ""}${address2 ?? ""}${city ?? ""}${state ?? ""}${zip ?? ""}` === "") return defaultValue === undefined ? "None" : defaultValue;
	
	if(`${address2 ?? ""}${city ?? ""}` === "") return `${address1 ?? ""}, ${state ?? ""} ${zip ?? ""} ${country ?? ""}`;


	if(`${address2 ?? ""}` === "") return `${address1 ?? ""}, ${city ?? ""}, ${state ?? ""} ${zip ?? ""} ${country ?? ""}`;

	if(`${city ?? ""}` === "") return `${address1 ?? ""}, ${address2 ?? ""} ${state ?? ""} ${zip ?? ""} ${country ?? ""}`;

	return `${address1 ?? ""}, ${address2 ?? ""} ${city ?? ""}, ${state ?? ""} ${zip ?? ""} ${country ?? ""}`;
};