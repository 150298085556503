import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, List, ListItem, Button } from '@mui/material';
import useInterval from '@use-it/interval';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Alignment, Spacer } from '@imas/components/layout';
import { registerPushNotifications } from '@imas/service-worker';

export enum RequiredPermission {
    Geolocation = "Location",
    Notification = "Notification"
}

export enum PermissionState {
    Allowed = "Allowed",
    Denied = "Denied",
    Prompt = "Missing",
    Unavailable = "Not Available on Device",
    Unknown = "Loading..."
}

export interface PermissionEntry {
    permission: RequiredPermission,
    state: PermissionState
}

const PermissionLockout = React.memo(() => {
    //list of all permissions and their state
    const [permissionStates, setPermissionStates] = useState<PermissionEntry[]>([
        { permission: RequiredPermission.Geolocation, state: PermissionState.Unknown },
    ]);

    //function for checking Location permission
    const checkGeolocation = (): Promise<PermissionState> => {
        return new Promise<PermissionState>((resolve) => {
            if (!("geolocation" in navigator)) return resolve(PermissionState.Unavailable);

            //query the geolocation permission
            navigator.permissions.query({name: 'geolocation'})
            .then(result => {
                if (result.state === "granted") return resolve(PermissionState.Allowed);
                else if (result.state === "prompt") return resolve(PermissionState.Prompt);
                else if (result.state === "denied") return resolve(PermissionState.Denied);
                else return resolve(PermissionState.Unavailable);
            }).catch(() => resolve(PermissionState.Unavailable));
        });
    };

    //function for checking notification permission
    const checkNotification = (): Promise<PermissionState> => {
        return new Promise<PermissionState>((resolve) => {
            if (!("Notification" in window)) return resolve(PermissionState.Unavailable);

            //query the navigator permission
            navigator.permissions.query({name: 'notifications'})
            .then(result => {
                if (result.state === "granted") return resolve(PermissionState.Allowed);
                else if (result.state === "prompt") return resolve(PermissionState.Prompt);
                else if (result.state === "denied") return resolve(PermissionState.Denied);
                else return resolve(PermissionState.Unavailable);
            }).catch(() => resolve(PermissionState.Unavailable));
        });
    };

    //check for all permissions
    const checkPermissions = () => {
        //check for permissions
        (async () => {
            //get location permission
            const geolocation = await checkGeolocation();

            //get notification permission
            const notification = await checkNotification();

            //update permissions
            setPermissionStates([
                { permission: RequiredPermission.Geolocation, state: geolocation },
            ]);
        })();
    };

    //check if required permissions have been provided on load & every 5 minutes
    useInterval(() => checkPermissions(), 1000 * 60 * 5);
    useEffect(() => checkPermissions(), []);

    //modify the state of a permission
    const updatePermission = (permission: RequiredPermission, newState: PermissionState) => {
        //remove permission from permissions list
        const newList = permissionStates.filter(x => x.permission !== permission);

        //add new permission & update list
        setPermissionStates([...newList, { permission, state: newState }]);
    };

    //check if all permissions are currently allowed
    const allAllowed = () => {
        return true;

        for (var permission of permissionStates) {
            if (permission.permission === RequiredPermission.Notification && permission.state === PermissionState.Unavailable) continue;

            if (permission.state !== PermissionState.Allowed && permission.state !== PermissionState.Unknown) {
                return false;
            }
        }

        return true;
    };

    //check if any permissions were denied
    const anyDenied = () => {
        for (var permission of permissionStates) {
            if (permission.state === PermissionState.Denied) {
                return true;
            }
        }

        return false;
    };

    //asks user for permissions which have not yet been provided
    const askForPermissions = () => {
        //get position to ask for permission
        navigator.geolocation.getCurrentPosition(() => {
            checkPermissions();
        }, error => {
            if (error.code === GeolocationPositionError.PERMISSION_DENIED) checkPermissions();
        });
        
        //get permission for push notification and register device
        registerPushNotifications(() => {
            checkPermissions();
        }, () => {
            checkPermissions();
        }, () => {
            checkPermissions();
        });
    };
    
    return (
        <Dialog open={!allAllowed()}>
            <DialogTitle
                title={"App Permissions"}
            />
            <DialogContent>
                {/* Required Permissions Label */}
                <Typography variant={"body1"}>{"The following permissions must be allowed before you can continue to use this application."}</Typography>

                {/* List of Permissions & statuses */}
                <List>
                    {permissionStates.map((permission, index) => (
                        <ListItem key={index}>
                            <Alignment row>
                                <Typography variant={"body1"} style={{ display: 'list-item', listStyleType: 'disc' }}>{permission.permission + ":"}</Typography>
                                <Spacer size={"5px"} horizontal/>
                                <Typography variant={'body1'} color={permission.state === PermissionState.Denied ? "error" : undefined}>{permission.state}</Typography>
                            </Alignment>
                        </ListItem>
                    ))}
                </List>

                {/* If any permissions are denied show error text */}
                { anyDenied() ? ( 
                    <Typography variant={"body1"} color={"error"}>{"If you have denied any permissions the prompt will not longer be able to show, you must allow them by going into your browser's settings."}</Typography>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button
                    color={"primary"}
                    variant={"text"}
                    onClick={() => askForPermissions()}
                >{"Ask For Permissions"}</Button>
            </DialogActions>
        </Dialog>
    );
});

export { PermissionLockout };