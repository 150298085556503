import { Alignment, Spacer } from '@imas/components/layout';
import {FormTextField, useMuiFormContext } from '@imas/mui-form';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../VehicleInspectionStyles';
import { VehicleInspectionHeader } from './VehicleInspectionRow/VehicleInspectionHeader';
import { VehicleInspectionRow } from './VehicleInspectionRow/VehicleInspectionRow';


export const VehicleInspectionFormMaintenance = memo(() => {

	const { classes } = VehicleInspectionStyles();

	const { control } = useMuiFormContext<TVehicleInspectionForm>();

	return(
		
		<Alignment column>

			<Spacer vertical/>

			<Alignment row>
				{/* Maintenance Title */}
				<Typography className={classes.title} align={'center'} fontWeight={"bold"} variant={"h5"}>{"Maintenance"}</Typography>
			</Alignment>

			<VehicleInspectionHeader/>

			<VehicleInspectionRow label={'Oil Fluid*:'} variable={'oilFluid'} />
			<VehicleInspectionRow label={'Air Filter:'} variable={'airFilter'} />
			<VehicleInspectionRow label={'Wiper Fluid:'} variable={'wiperFluid'} />
			<VehicleInspectionRow label={'Coolant Fluid*:'} variable={'radFluid'} />
			<VehicleInspectionRow label={'Brake Fluid*:'} variable={'brakeFluid'} />
			<VehicleInspectionRow label={'Power Steering Fluid*:'} variable={'powerFluid'} />

			{/* Notes Field */}
			<FormTextField
				control={control}
				name={'maintenanceNotes'}
				label={'Maintenance Notes'}
				fullWidth
				inputProps={{maxLength: 255}}
				multiline
				minRows={2}
			/>

		</Alignment>

	);
});
