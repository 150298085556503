import React from 'react';
import { FieldValues, FieldPath, UseFormSetValue, PathValue, UnpackNestedValue } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, AutocompleteValue, AutocompleteChangeReason, AutocompleteChangeDetails, TextFieldProps, AutocompleteInputChangeReason } from '@mui/material';
import { FormInputProps } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';

export interface FormAutocompleteProps<TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>, T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined> 
    extends FormInputProps<number | string | null, TFieldValues, TName>, 
    Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "name" | "defaultValue" | "error" | "helperText" | "value" | "onChange" | "onInputChange" | "onBlur" | "ref" | "select" | "SelectProps" | "loading"> 
{
	optionsLoading?: boolean;
    renderInput: (props: TextFieldProps) => React.ReactNode;
    getValue: (value: UnpackNestedValue<PathValue<TFieldValues, TName>> | null, options: readonly T[]) => AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | null | undefined;
    onChange: (
        value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
        setValue: UseFormSetValue<TFieldValues>,
        reason: AutocompleteChangeReason, 
        details?: AutocompleteChangeDetails<T>
    ) => UnpackNestedValue<PathValue<TFieldValues, TName>> | null;
    onInputChange?: (
        value: string,
        setValue: UseFormSetValue<TFieldValues>,
        reason: AutocompleteInputChangeReason
    ) => UnpackNestedValue<PathValue<TFieldValues, TName>> | null;
};

const FormAutocomplete = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>, T, Multiple extends boolean | undefined = false, DisableClearable extends boolean | undefined = false, FreeSolo extends boolean | undefined = false>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
        optionsLoading, renderInput, getValue, onChange: onAutocompleteChange, onInputChange: onAutocompleteInputChange,
        ...autocompleteProps
    }: FormAutocompleteProps<TFieldValues, TName, T, Multiple, DisableClearable, FreeSolo>
) => {
    //use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
        context: { setValue, size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

    //render loading
    if (isLoading) return <FormFieldSkeleton {...autocompleteProps} size={autocompleteProps.size ?? size}/>;

    if (!!autocompleteProps.freeSolo && !onAutocompleteInputChange) throw Error("onInputChange must be defined if 'freeSolo' is set on a FormAutocomplete.");

    //render TextField 
    return (
        <Autocomplete
            {...autocompleteProps}
			loading={optionsLoading}
            value={(getValue(value, autocompleteProps.options) ?? null) as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>}
            onChange={(_, value, reason, details) => {
                onChange(onAutocompleteChange(value, setValue, reason, details));
            }}
            onInputChange={(_, value, reason) => {
                if (!!autocompleteProps.freeSolo && onAutocompleteInputChange) onChange(onAutocompleteInputChange(value, setValue, reason));
            }}
            size={autocompleteProps.size ?? size}
			disabled={isViewOnly || autocompleteProps.disabled}
            renderInput={(params) => renderInput({ 
                ...params,
                name: name,
				size: autocompleteProps.size ?? size,
                error: hasError,
                helperText: helperText,  
                ref: ref, 
                onBlur: handleBlur,
                required: isRequired,
            })}
        />
    );
};

export { FormAutocomplete };