

export function getFileTypeIconURL(str: string | null, options?: { resolution?: 256 | 64, isDirectory?: boolean }): string {
    //the file name of given the file type
    let name = "blank";

    if (str === null && options?.isDirectory) {
        name = "folder";
    } else {
        //lowercase input str
        str = (str ?? "").toLowerCase();

        if (str.endsWith("pdf")) name = "pdf";
        else if (str.endsWith("doc") || str.endsWith("docm") || str.endsWith("docx") || str.endsWith("dot") || str.endsWith("dotx")) name = "word";
        else if (str.endsWith("xls") || str.endsWith("xlsm") || str.endsWith("xlsx") || str.endsWith("xlt") || str.endsWith("xltx")) name = "excel";
        else if (str.endsWith("ppt") || str.endsWith("pptx") || str.endsWith("pot") || str.endsWith("potx")) name = "power-point";
        else if (str.endsWith("wpd") || str.endsWith("wpd5")) name = "word-perfect";
        else if (str.endsWith("png") || str.endsWith("jpg") || str.endsWith("jpeg") || str.endsWith("bmp") || str.endsWith("gif") || str.endsWith("webp") || str.endsWith("heif") || str.endsWith("heic")) name = "image";
        else if (str.endsWith("mp3") || str.endsWith("m3u") || str.endsWith("midi") || str.endsWith("mid") || str.endsWith("wav")) name = "music";
        else if (str.endsWith("mp4") || str.endsWith("wmv") || str.endsWith("asf")) name = "video";
        else if (str.endsWith("zip")) name = "zip";
        else if (str.endsWith("dll")) name = "dll";
        else if (str.endsWith("exe")) name = "exe";
        else if (str.endsWith("txt") || str.endsWith("csv")) name = "txt";
        else if (str.endsWith("accdb") || str.endsWith("mdb")) name = "access";
        else if (str.endsWith("pst") || str.endsWith("ost")) name = "outlook";
    }

    //return constructed windows path
    return `/images/file-icons/${(options?.resolution ?? 64)}/${name}.webp`;
};