import { ProposalDocument } from './types/project-data/ProposalDocument';
import { vProposalContact } from './types/project-data/vProposalContact';
import { vProposalSiteContact } from './types/project-data/vProposalSiteContact';
import { ContactListWithNumber, GetSOListResult, vProposalBillingContact } from '@imas/api/proposal';
import { axiosData, SourceGetter } from '@imas/api';
import { ServiceLocation } from './types/project-data/ServiceLocation';
import { ProjectTech } from './types/project-data/ProjectTech';

//proposal purchase orders base url
const PROPOSAL_PROJECT_DATA_URL = "/api/proposals/project-data";

/* Get GetSOListResults */
export const GetSOListResults = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<GetSOListResult[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/SO-list/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get vProposalSiteContacts */
export const GetvProposalSiteContacts = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<vProposalSiteContact[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/site-contacts/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create ProposalSiteContacts */
export const CreateProposalSiteContacts = (getSource: SourceGetter) => {
    return async (pronum: int, contactIDs: int[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_PROJECT_DATA_URL}/site-contacts/${pronum.toString()}`,
			data: contactIDs,
            cancelToken: getSource().token,
		});
    };
};

/* Update ProposalSiteContact */
export const UpdateProposalSiteContact = (getSource: SourceGetter) => {
    return async (sitecontactid: int, type: string): Promise<vProposalSiteContact> => {
        return axiosData({
			method: 'put',
            url: `${PROPOSAL_PROJECT_DATA_URL}/site-contacts/${sitecontactid.toString()}`,
			data: {
				id: sitecontactid,
				type: type,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Delete ProposalSiteContact */
export const DeleteProposalSiteContact = (getSource: SourceGetter) => {
    return async (sitecontactid: int): Promise<void> => {
        return axiosData({
			method: 'delete',
            url: `${PROPOSAL_PROJECT_DATA_URL}/site-contacts/${sitecontactid.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get vProposalContacts */
export const GetvProposalContacts = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<vProposalContact[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/contacts/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create ProposalContacts */
export const CreateProposalContacts = (getSource: SourceGetter) => {
    return async (pronum: int, contactIDs: int[], contactListids: int[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_PROJECT_DATA_URL}/contacts/${pronum.toString()}`,
			data: {
				contactids: contactIDs,
				contactListids: contactListids,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Update ProposalContact */
export const UpdateProposalContact = (getSource: SourceGetter) => {
    return async (proContactid: int, correspondence: int, type: string): Promise<vProposalContact> => {
        return axiosData({
			method: 'put',
            url: `${PROPOSAL_PROJECT_DATA_URL}/contacts/${proContactid.toString()}`,
			data: {
				id: proContactid,
				type: type,
				correspondence: correspondence
			},
            cancelToken: getSource().token,
		});
    };
};

/* Delete ProposalContact */
export const DeleteProposalContact = (getSource: SourceGetter) => {
    return async (contactid: int): Promise<void> => {
        return axiosData({
			method: 'delete',
            url: `${PROPOSAL_PROJECT_DATA_URL}/contacts/${contactid.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get vProposalBillingContacts */
export const GetvProposalBillingContacts = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<vProposalBillingContact[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/billing-contacts/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create ProposalBillingContacts */
export const CreateProposalBillingContacts = (getSource: SourceGetter) => {
    return async (pronum: int, contactIDs: int[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_PROJECT_DATA_URL}/billing-contacts/${pronum.toString()}`,
			data: contactIDs,
            cancelToken: getSource().token,
		});
    };
};

/* Update ProposalBillingContact */
export const UpdateProposalBillingContact = (getSource: SourceGetter) => {
    return async (billContactid: int, type: string): Promise<boolean> => {
        return axiosData({
			method: 'put',
            url: `${PROPOSAL_PROJECT_DATA_URL}/billing-contacts/${billContactid.toString()}`,
			data: {
				id: billContactid,
				type: type
			},
            cancelToken: getSource().token,
		});
    };
};

/* Delete ProposalBillingContact */
export const DeleteProposalBillingContact = (getSource: SourceGetter) => {
    return async (contactid: int): Promise<boolean> => {
        return axiosData({
			method: 'delete',
            url: `${PROPOSAL_PROJECT_DATA_URL}/billing-contacts/${contactid.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get ProposalDocuments */
export const GetProposalDocuments = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<ProposalDocument[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/documents/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create a ProposalDocument */
export const CreateProposalDocument = (getSource: SourceGetter) => {
    return async (pronum: int, doctype: string, sourceID: HierarchyId, fileTableName: string): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_PROJECT_DATA_URL}/documents/${pronum.toString()}`,
			data: {
				pronum: pronum,
				doctype: doctype,
				sourceID: sourceID,
				fileTableName: fileTableName,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Update a ProposalDocument */
export const UpdateProposalDocument = (getSource: SourceGetter) => {
    return async (prodocid: int, prodoctype: string): Promise<boolean> => {
        return axiosData({
			method: 'put',
            url: `${PROPOSAL_PROJECT_DATA_URL}/documents/${prodocid.toString()}`,
			data: {
				prodocid: prodocid,
				prodoctype: prodoctype,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Delete a ProposalDocument */
export const DeleteProposalDocument = (getSource: SourceGetter) => {
    return async (prodocid: int, deleteFile: boolean): Promise<boolean> => {
        return axiosData({
			method: 'delete',
            url: `${PROPOSAL_PROJECT_DATA_URL}/documents/${prodocid.toString()}`,
			data: {
				prodocid: prodocid,
				deleteFile: deleteFile,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Get ServiceLocations */
export const GetServiceLocations = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<ServiceLocation[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/service-locations/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get ProjectTechs */
export const GetProjectTechs = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<ProjectTech[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/project-techs/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/** Get Contact lists with number of contacts */
export const GetContactListsWithNumbers = (getSource: SourceGetter) => {
    return async (): Promise<ContactListWithNumber[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROJECT_DATA_URL}/contact-lists`,
            cancelToken: getSource().token,
		});
    };
};
