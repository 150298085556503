import { ForgotPassword } from "@imas/api/auth/types/ForgotPassword";
import React, { memo, useMemo, useContext, useCallback } from 'react';
import { Editable, FormTextField, MuiForm, MuiFormPartial, MuiFormRef, useMuiForm } from "@imas/mui-form";

interface ForgotPasswordProps extends MuiFormPartial {

}

const ForgotPasswordForm = React.memo(React.forwardRef<MuiFormRef<ForgotPassword>, ForgotPasswordProps> (({...props}, ref) => {
    const { loading, viewOnly, ...otherProps} = props;

    const defaultValues: Editable<ForgotPassword> = {
        userEmail: null,

    };

    const { control, muiFormProps} = useMuiForm({
        defaultValues,
        validator: ({ userEmail}, error) => {
            if(userEmail === null) error("userEmail", "required");
            if(userEmail === null) return;

            return {userEmail};
        }
    });

    return (
        <MuiForm
        loading={loading}
            viewOnly={viewOnly}
            {...muiFormProps}
            {...otherProps}
            ref={ref}>
                <FormTextField
                control={control}
                name={'userEmail'}
                label={"Enter Work Email"}
                sx={{margin: '10px 10px 10px 10px',}}
                />


            </MuiForm>
    );


}));

export default ForgotPasswordForm;