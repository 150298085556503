import React, { useCallback, useState } from 'react';
import { MuiFormRef } from './MuiForm';
import { FieldValues } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { FormRefSubmitHandler } from './types';
import { usePrompt } from 'react-router-dom';

export interface MuiFormSubmitProps<TFieldValues extends FieldValues, R extends any = any> extends Omit<ButtonProps, "component" | "onClick" | "onSubmit"> {
    //form logic properties
    formRef: MuiFormRef<TFieldValues, R> | null;
    onSubmit: FormRefSubmitHandler<TFieldValues, R>;
    onSuccessfulSubmit: (result: R) => void;
    onError?: (error: any) => void;

    /** Disables the behavior which prevents the user navigating away from the form if there are unsaved changes. */
    disablePreventNavigation?: boolean; 
};

const MuiFormSubmit = <TFieldValues extends FieldValues, R extends any = any>(props: MuiFormSubmitProps<TFieldValues, R>) => {
    const { 
        formRef, onSubmit, onSuccessfulSubmit, onError,
        disablePreventNavigation,
		...buttonProps
    } = props;

    //internal wasSavedSuccessfully state
    const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(false);

    //prevent navigation away from page if the form is dirty and has not been successfully submitted and preventNavigation is not set to false
    usePrompt("Are you sure you want to discard the changes?", !disablePreventNavigation && (formRef?.hasUnsavedChanges ?? false) && !wasSavedSuccessfully);

    //submit callback
    const submit = useCallback(() => {
        if (!formRef) return;

        //set wasSavedSuccessfully to false
        setWasSavedSuccessfully(false);
        
        //call .submit on formRef
        formRef.submit(onSubmit)
        .then((result) => {
            setWasSavedSuccessfully(true); 
            onSuccessfulSubmit(result); 
        })
        .catch((e) => { if(onError) onError(e); });
    }, [formRef, onSubmit, onSuccessfulSubmit]);

    return (
        <LoadingButton
            color={"primary"}
            variant={"contained"}
			{...buttonProps}
            disabled={!(formRef?.isValid ?? false) || buttonProps.disabled}
            loading={formRef?.isSaving || formRef?.isLoading}
            loadingPosition={"start"}
            onClick={submit}
        >{buttonProps.children ?? "Save"}</LoadingButton>
    );
};

export { MuiFormSubmit };