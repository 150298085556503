import { mapApi, ApiConfig } from '@imas/api';
import { ServiceOrderWorkPlaceReview, ServiceOrderWorkPlaceReviewForm, vServiceOrderWorkPlaceReview, ServiceOrderWorkPlaceReviewEmployee } from './types';

//proposal pre liens base url
const SERVICE_ORDER_WORK_PLACE_REVIEWS = "/api/service-orders/work-place-reviews";

/*  */
export const GetAllServiceOrderWorkPlaceReviews = mapApi((): ApiConfig<ServiceOrderWorkPlaceReview[]> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}`,
}));

/*  */
export const GetAllServiceOrderVWorkPlaceReviews = mapApi((): ApiConfig<vServiceOrderWorkPlaceReview[]> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}/v-work-place-reviews`,
}));

export const getWorkPlaceReviewFile = mapApi((id: int): ApiConfig<HierarchyId> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}/file/${id}`,
}));

/*  */
export const GetServiceOrderWorkPlaceReview = mapApi((workPlaceReviewId: int): ApiConfig<ServiceOrderWorkPlaceReview> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}/${workPlaceReviewId}`,
}));

/*  */
export const GetServiceOrderWorkPlaceReviewEmployees = mapApi((workPlaceReviewId: int): ApiConfig<ServiceOrderWorkPlaceReviewEmployee[]> => ({
	method: 'get',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}/${workPlaceReviewId}/employees`,
}));

/*  */
export const CreateServiceOrderWorkPlaceReview = mapApi((data: ServiceOrderWorkPlaceReviewForm): ApiConfig<ServiceOrderWorkPlaceReview> => ({
	method: 'post',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}`,
	data,
}));

/*  */
export const UpdateServiceOrderWorkPlaceReview = mapApi((workPlaceReviewId: int, data: ServiceOrderWorkPlaceReviewForm): ApiConfig<ServiceOrderWorkPlaceReview> => ({
	method: 'put',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}/${workPlaceReviewId}`,
	data,
}));

/*  */
export const DeleteServiceOrderWorkPlaceReview = mapApi((workPlaceReviewId: int): ApiConfig<ServiceOrderWorkPlaceReview> => ({
	method: 'delete',
	url: `${SERVICE_ORDER_WORK_PLACE_REVIEWS}/${workPlaceReviewId}`,
}));