import { Button, Dialog, DialogActions, DialogContent, Typography, FormControlLabel, Radio, RadioGroup, TextField, Divider, Box } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';

export interface ChoiceTextDialogProps {
    open: boolean;
    title: string;
    choices: string[];
    inputLabel?: string;
    confirmText?: string; 
    closeText?: string;
    onConfirm: (value: string) => any; 
    onClose: () => void;
	onCancel: () => void;
};

export const ChoiceTextDialog = ({ open, title, choices, inputLabel, confirmText, closeText, onConfirm, onClose, onCancel }: ChoiceTextDialogProps) => {
    //choice state
    const [choice, setChoice] = useState<number | null>(null);
    const [text, setText] = useState<string>("");

    //detect change in choice
    useEffect(() => {
        setText(choice === null ? "" : choices[choice]);
    }, [choice]);

    const close = () => {
        //clear choice value
        setChoice(null);

        //call onClose
        onClose();
    };

	const cancel = () => {
		//call onCancel
		onCancel();

		//call close
		close();
	};

    return (
        <Dialog open={open} onClose={close}>
            {/* Choice Selection */}
            <DialogContent>
                {/* Title */}
                <Typography variant={"h5"} style={{ marginBottom: "5px" }}><Box fontWeight={"bold"}>{title}</Box></Typography>

                {/* Radio Button Control group */}
                <RadioGroup 
                    name={"choice-dialogue"} 
                    value={choice} 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChoice(Number(e.target.value))}
                >
                    {/* Generate Choice Radio Buttons */}
                    {choices.map((choice, index) => (
                        <FormControlLabel 
                            key={index}
                            value={index}
                            control={<Radio/>}
                            label={choice}
                        />
                    ))}
                </RadioGroup>
                <Divider/>
                <TextField
                    label={inputLabel}
                    variant={"outlined"}
                    value={text}
                    multiline
                    rows={3}
                    style={{ marginTop: "15px", width: "-webkit-fill-available" }}
                    onChange={e => setText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                {/* Cancel/Close Button */}
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={cancel}
                >{closeText ?? "Cancel"}</Button>
                
                {/* Choice Confirmation Button */}
                <Button
                    disabled={text === ""}
                    color={"primary"}
                    variant={"contained"}
                    onClick={ () => { onConfirm(text); close(); }}
                >{confirmText ?? "Confirm"}</Button>
            </DialogActions>
        </Dialog>
    );
};