import { mapApi, ApiConfig } from '@imas/api';
import { TimeSheetDetail, vTimeSheetDetail, TimeSheetDetailForm, TimeSheetCertificationHour } from './types';
import { Moment } from 'moment';

const TIME_SHEET_DETAILS_BASE_URL = "/api/time-sheets/details";

/*  */
export const GetTimeSheetDetail = mapApi((detailId: int): ApiConfig<TimeSheetDetail> => ({
	method: 'get',
	url: `${TIME_SHEET_DETAILS_BASE_URL}/${detailId}`,
}));

/*  */
export const GetTimeSheetDetailCertificationHours = mapApi((detailId: int): ApiConfig<TimeSheetCertificationHour[]> => ({
	method: 'get',
	url: `${TIME_SHEET_DETAILS_BASE_URL}/${detailId}/certification-hours`,
}));

/*  */
export const CreateTimeSheetDetail = mapApi((data: TimeSheetDetailForm): ApiConfig<vTimeSheetDetail> => ({
	method: 'post',
	url: `${TIME_SHEET_DETAILS_BASE_URL}`,
	data,
}));

/*  */
export const UpdateTimeSheetDetail = mapApi((detailId: int, data: TimeSheetDetailForm): ApiConfig<vTimeSheetDetail> => ({
	method: 'put',
	url: `${TIME_SHEET_DETAILS_BASE_URL}/${detailId}`,
	data,
}));

/*  */
export const TimeSheetDetailNullifyZeros = mapApi((detailId: int): ApiConfig<vTimeSheetDetail> => ({
	method: 'put',
	url: `${TIME_SHEET_DETAILS_BASE_URL}/${detailId}/nullify-zeros`,
}));;

/*  */
export const DeleteTimeSheetDetail = mapApi((detailId: int): ApiConfig<vTimeSheetDetail> => ({
	method: 'delete',
	url: `${TIME_SHEET_DETAILS_BASE_URL}/${detailId}`,
}));