import { makeStyles } from '@imas/styles';
import clsx from 'clsx';

const useStyles = makeStyles()(() => ({
    hidden: {
        display: 'none'
    }
}));

export interface SpacerProps {
    vertical?: boolean;
    horizontal?: boolean;
    size?: string;
    hidden?: boolean;
};

/**
 *  Used to add spacing.
 */
const Spacer = ({ vertical, horizontal, size, hidden }: SpacerProps) => {
    //component styling
    const { classes } = useStyles();

    if (vertical) {
        return <div style={{minHeight: size ?? "10px", width: 'auto'}} className={clsx({[classes.hidden]: hidden})}/>;
    } else if (horizontal) {
        return <div style={{minWidth: size  ?? "10px", height: 'auto'}} className={clsx({[classes.hidden]: hidden})}/>;
    } else {
        return null;
    }
};

export { Spacer };