import React from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Popper, Typography, ListSubheader, useMediaQuery, autocompleteClasses, useTheme, styled, PopperProps } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current !== null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
};

export interface VirtualizedListboxRowProps<T extends any> {
	props: any;
	option: T;
	key: any;
	group: any;
};

export type VirtualizedListboxChildComponentProps<T extends any> = ListChildComponentProps<VirtualizedListboxRowProps<T>[]>;

export interface VirtualizedListboxAdpaterProps extends React.HTMLAttributes<HTMLElement> {
	listboxPadding?: int;
	itemSize?: int;
	renderer: (props: VirtualizedListboxChildComponentProps<any>) => React.ReactNode;
};

//react-window Listbox Adapter for Autocomplete
export const VirtualizedListboxAdpater = React.forwardRef<HTMLDivElement, VirtualizedListboxAdpaterProps>((props, ref) => {
	const { children, listboxPadding, renderer, ...other } = props;
	const padding: int = listboxPadding ?? 8;
	const itemData: React.ReactChild[] = [];
	(children as React.ReactChild[]).forEach(
		(item: React.ReactChild & { children?: React.ReactChild[] }) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	);

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = props.itemSize ? props.itemSize : smUp ? 36 : 48;

	const getChildSize = (child: React.ReactChild) => {
		if (child.hasOwnProperty("group")) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData as any}
					height={getHeight() + 2 * padding}
					width={"100%"}
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType={"ul"}
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
					style={{ paddingLeft: 0, paddingTop: padding, paddingBottom: padding }}
				>
					{renderer as any}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

export const VirtualizedListboxPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});