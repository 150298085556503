import { getDeviceUniqueId } from '@imas/utils/device';
import { axiosData, SourceGetter } from  '@imas/api';
import { EmployeeDeviceNotificationSubscription } from "@imas/api/employee/types";
import axios from 'axios';

/** https://localhost:44363/swagger/index.html#/UserDeviceNotificationSubscription/get_api_user_devices_notification_subscriptions */
export const GetUserNotificationSubscriptions = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeDeviceNotificationSubscription[]> => {
        return await axiosData({
            method: 'get',
            url: '/api/user/devices/notification-subscriptions',
            cancelToken: getSource().token,
        });
    };
};


/** https://localhost:44363/swagger/index.html#/UserDeviceNotificationSubscription/post_api_user_devices_notification_subscriptions */
export const CreateUserNotificationSubscription = (getSource: SourceGetter) => {
    return async (request: { endpoint: string, expirationDate: int | null, auth: string, p256dh: string }): Promise<EmployeeDeviceNotificationSubscription> => {
        return await axiosData({
            method: 'post',
            url: '/api/user/devices/notification-subscriptions',
            data: {
                deviceId: getDeviceUniqueId(),
                endpoint: request.endpoint,
                expirationDate: request.expirationDate,
                auth: request.auth,
                p256dh: request.p256dh
            },
            cancelToken: getSource().token,
        });
    };
};

/** https://localhost:44363/swagger/index.html#/UserDeviceNotificationSubscription/post_api_user_devices_notification_subscriptions__deviceId__send_test */
export const SendTestNotification = (getSource: SourceGetter) => {
    return async (deviceId: UUID): Promise<null> => {
        await axios({
            method: 'post',
            url: `/api/user/devices/notification-subscriptions/${deviceId}/send-test`,
            cancelToken: getSource().token,
        });

        return null;
    };
};

/** https://localhost:44363/swagger/index.html#/UserDeviceNotificationSubscription/put_api_user_devices_notification_subscriptions__deviceId__make_primary */
export const MakeUserNotificationSubscriptionPrimary = (getSource: SourceGetter) => {
    return async (deviceId: UUID): Promise<EmployeeDeviceNotificationSubscription> => {
        return await axiosData({
            method: 'put',
            url: `/api/user/devices/notification-subscriptions/${deviceId}/make-primary`,
            cancelToken: getSource().token,
        });
    };
};

/** https://localhost:44363/swagger/index.html#/UserDeviceNotificationSubscription/delete_api_user_devices_notification_subscriptions__deviceId_ */
export const DeleteUserNotificationSubscription = (getSource: SourceGetter) => {
    return async (deviceId: UUID): Promise<EmployeeDeviceNotificationSubscription> => {
        return await axiosData({
            method: 'delete',
            url: `/api/user/devices/notification-subscriptions/${deviceId}`,
            cancelToken: getSource().token,
        });
    };
};