import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as MuiGrid from "@mui/x-data-grid-pro";
import {
	useTGridApiRef,
	TypedGridComponentProps, TGEM, TGDM, TGRM, TGDM_Id, TGRM_Key, TypedGridColumns, TypedGridColumnDefs, TypedGridApiRef, TypedGridEventListener, TypedGridEditRow
} from '@imas/data-grid';
import { Save as SaveIcon, Close as CloseIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { NoEditFilterDataGrid } from '../NoEditFilterDataGrid';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { useEditDataGridStyles } from './EditDataGridStyles';
import { deletePersistentRow, isChildOfPopper, PERSISTENT_EDIT_ID, remakePersistentRow, resetPersistentRow } from './utilts';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { useEditDataGridDisabledStyles } from './EditDataGridDisabledStyles';
import AutoSizer from 'react-virtualized-auto-sizer';

//TODO impliment this
export interface EdiGridApiRef<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM = "cell"> extends TypedGridApiRef<DM, RM, E, Mode> {
	validate: (row: TypedGridEditRow<RM, E, Mode>) => Promise<RM>;
	create: (row: RM) => Promise<DM>;
	update: (id: TGDM_Id<DM>, row: RM) => Promise<DM>;
	delete: (id: TGDM_Id<DM>) => Promise<void>;
};

export function useEditGridApiRef<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM = "cell">(): EdiGridApiRef<DM, RM, E, Mode> {
	//get TGridApiRef
	return useTGridApiRef<DM, RM, E, Mode>() as EdiGridApiRef<DM, RM, E, Mode>;
};

export interface TypedEditDataGridProps<
	DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>,
	COLS extends TypedGridColumns<RM>,
	Mode extends TGEM = 'cell',
	COL_DEFS extends TypedGridColumnDefs<DM, RM, E, COLS, Mode> = TypedGridColumnDefs<DM, RM, E, COLS, Mode>
> extends Omit<TypedGridComponentProps<Mode, DM, RM, E, COLS, COL_DEFS>, 'onRowEditCommit'> {
	autoFocusEditRow?: boolean;
	disablePersistentEditRow?: boolean;
	disabled?: boolean;
	disableDeleteButton?: boolean;
	experimental_singleClickToEdit?: boolean;
	experimental_disableCreateReorder?: boolean;
	experimental_enableLogging?: boolean;
    rowValidator: (row: RM, record: DM | null) => Promise<RM>;
    onRowCreate: (row: RM, record: DM | null) => Promise<DM>;
    onRowUpdate: (id: TGDM_Id<DM>, row: RM, record: DM) => Promise<DM>;
    onRowDelete?: (id: TGDM_Id<DM>, record: DM) => Promise<void>;
};

export const EditDataGrid = <
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>,
    COLS extends TypedGridColumns<RM>,
    Mode extends TGEM = "cell",
    COL_DEFS extends TypedGridColumnDefs<DM, RM, E, COLS, Mode> = TypedGridColumnDefs<DM, RM, E, COLS, Mode>
>(props: Omit<TypedEditDataGridProps<DM, RM, E, COLS, Mode, COL_DEFS>, "apiRef"> & { apiRef:  TypedGridApiRef<DM, RM, E, Mode>; }): JSX.Element => {
    const { 
		rows, apiRef, columns: preActionsColumns, autoFocusEditRow, disablePersistentEditRow, disableDeleteButton,
		disabled: disabledProp, experimental_singleClickToEdit, experimental_disableCreateReorder, experimental_enableLogging,
        rowValidator, onRowCreate, onRowUpdate, onRowDelete,
		...otherProps
    } = props;

	const { classes, cx } = useEditDataGridStyles();
	const { classes: disabledClasses } = useEditDataGridDisabledStyles();

	const disabled = disabledProp ?? false;

	const options = useMemo(() => ({
		autoFocusEditRow: autoFocusEditRow ?? false,
		disablePersistentEditRow: !(disablePersistentEditRow ?? false),
		disabled,
		experimental_singleClickToEdit: experimental_singleClickToEdit ?? false,
		experimental_disableCreateReorder: experimental_disableCreateReorder ?? false,
		logging: experimental_enableLogging ? 'DEBUG' : 'ERROR'
	}), [autoFocusEditRow, disablePersistentEditRow, disabled, experimental_singleClickToEdit, experimental_disableCreateReorder, experimental_enableLogging]);

	//options ref
	const optionsRef= useRef(options);
	optionsRef.current = options;

	//use automatic snackbar
	const showSnackbar = useAutomaticSnackbar();

	//keep track of which rows failed to save to highlight them for the user
	const [failedSaveRowIds, setFailedSaveRowIds] = useState<TGDM_Id<DM>[]>([]);
	const addFailedSaveRow = useCallback((id: TGDM_Id<DM>) => setFailedSaveRowIds(ids => ids.includes(id) ? ids : [...ids, id]), [setFailedSaveRowIds]);
	const removeFailedSaveRow = useCallback((id: TGDM_Id<DM>) => setFailedSaveRowIds(ids => ids.filter(x => x !== id)), [setFailedSaveRowIds]);

	//show error message
	const showError = useCallback((error: any) => {
		let errMsg = 'Unknown Error';
		if (typeof error === 'string') errMsg = error;
		if (error instanceof Error && error.message !== undefined) errMsg = error.message;
		showSnackbar(`Unable to save row: ${errMsg}`, { variant: 'error' });
	}, [showSnackbar]);

	
	//handle row save error
	const handleRowSaveError = useCallback((id: TGDM_Id<DM>, error: any) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid handle row save error");
		
		//add row to list of failed row saves
		addFailedSaveRow(id);

		//show error message
		showError(error);
	}, [addFailedSaveRow, showError]);

    //change commit handler
    const onRowEditCommit = useCallback(async (newRow: RM, oldRow: RM) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid row edit commit");
		//get the editing state of the row 
        //const editState = apiRef.current.getEditRowsModel()[id];
        // const editState = apiRef.current.getRowWithUpdatedValues(id);

        //get row's value pre-edits 
        const preEditDM = apiRef.current.getRow(oldRow.id);

        //try to create/edit record
        try {
			if (optionsRef.current.disabled) throw Error('Editing has been disabled.'); 

            //validate the edit model and return the row model to be passed into the update/create methods
            const rowModel = await rowValidator(newRow, preEditDM);

			let result: DM;

            //if the row is the persistent record then call onRowCreate, otherwise call onRowSave
			//this is because only NEW records will have an id which matches the unique value of PERSISTENT_EDIT_ID
			//existing records will have an ID of whatever their database record primary key is
            if (oldRow.id === PERSISTENT_EDIT_ID) {
                result = await onRowCreate(rowModel, null);
            } else {
				if (preEditDM === null) throw new Error("YOU CANT EDIT A ROW THAT DOESNT EXIST IDIOT");
                result = await onRowUpdate(oldRow.id, rowModel, preEditDM);
            }

            //add the new row/update the existing row
            if (oldRow.id !== PERSISTENT_EDIT_ID) apiRef.current.updateRows([result]);

			//remove the row from the list of failed row saves if it is there
			removeFailedSaveRow(oldRow.id);

            //remake persistent edit row if it was used
            if (oldRow.id === PERSISTENT_EDIT_ID) {
				//The reordering is needed for most data grids, but when there is a datahook (signalR) sometimes the reordering is not needed and it will cause duplicate rows instead.
				if (options.experimental_disableCreateReorder) remakePersistentRow(apiRef as any, optionsRef.current.autoFocusEditRow);
				else {
					const rowMap = apiRef.current.getRowModels();
					const currentRows = Array.from(rowMap, (item) => {
						return item[1];
					});
					currentRows.splice(currentRows.length-1, 0, result);
					apiRef.current.setRows(currentRows);
					//throw new Error('test');
				}
			}

			return result;
        } catch (error) {
            if (!apiRef.current) return;

			if (!optionsRef.current.disabled) handleRowSaveError(oldRow.id, error);

			//re-enable editing mode if commit fails and restore the row to it's original value
            if (!optionsRef.current.disabled) apiRef.current.startRowEditMode({ id: oldRow.id });

			return oldRow;
        }
    }, [apiRef, showError, removeFailedSaveRow, rowValidator, onRowCreate, onRowUpdate]);

	//handle cell click to implement one click edit
	const handleCellClick = useCallback<NonNullable<TypedEditDataGridProps<DM, RM, E, COLS, Mode, COL_DEFS>['onCellClick']>>((cell, event, callback) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid Cell Click");
		const startEdit = !optionsRef.current.disabled 
			&& (optionsRef.current.experimental_singleClickToEdit || cell.row.id === PERSISTENT_EDIT_ID) 
			&& apiRef.current.getRowMode(cell.row.id) !== 'edit';

		if (startEdit) {
			apiRef.current.startRowEditMode({id: cell.row.id});
			apiRef.current.setCellFocus(cell.row.id, cell.field);
		}

		//handle higher level callback if it exists
		if (otherProps.onCellClick) otherProps.onCellClick(cell, event, callback);
	}, [otherProps.onCellClick]);

	//handle row edit stop
	const handleRowEditStop = useCallback<NonNullable<TypedEditDataGridProps<DM, RM, E, COLS, Mode, COL_DEFS>['onRowEditStop']>>((row, event, callback) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid Row Edit Stop");
		removeFailedSaveRow(row.id);

		event.defaultMuiPrevented = true;

		//handle higher level callback if it exists
		if (otherProps.onRowEditStop) otherProps.onRowEditStop(row, event, callback);
	}, [removeFailedSaveRow, otherProps.onRowEditStop]);

	//handle keydown to impliment save & cancel
	const handleRowKeyDown = useCallback((event: React.KeyboardEvent) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid Key Down");
		if ((event.key.toLowerCase() === 's' && event.ctrlKey)) {
			//cancel if disabled
			if (optionsRef.current.disabled) return;

			//get id of row
			const rowIdStr = event.currentTarget.getAttribute('data-id');
			const rowId = rowIdStr === PERSISTENT_EDIT_ID ? PERSISTENT_EDIT_ID : Number(rowIdStr);
			
			event.preventDefault();
			event.stopPropagation();

			if (apiRef.current.getRowMode(rowId) === 'edit') {
				//try and comit the row change & set the row back to view
				try {
					//apiRef.current.commitRowChange(rowId);
					apiRef.current.stopRowEditMode({id: rowId, ignoreModifications: false});
				} catch {}
			}
		} else if (event.key === "Escape") {
			//prevent default
			event.preventDefault();
			
			//get id of row
			const rowIdStr = event.currentTarget.getAttribute('data-id');
			const rowId = rowIdStr === PERSISTENT_EDIT_ID ? PERSISTENT_EDIT_ID : Number(rowIdStr);

			//dont commit row changes and set the row back to view mode (if the row is not the persistent edit row)
			if (rowId !== PERSISTENT_EDIT_ID) {
				apiRef.current.stopRowEditMode({id: rowId, ignoreModifications: true});
			}
		} else if (event.key === 'Enter') {
			if (experimental_singleClickToEdit) {
				event.stopPropagation();
			}
		}
	}, [apiRef]);

	//handle row unfocus
	const handleRowLeave = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid Row Leave");
		//get id of row
		const rowIdStr = event.currentTarget.getAttribute('data-id');
		const rowId: TGDM_Id<DM> = rowIdStr === PERSISTENT_EDIT_ID ? PERSISTENT_EDIT_ID : Number(rowIdStr);

		if (!(event.target instanceof HTMLElement)) return;
		if (!event.currentTarget) return;

		//do not try saving if the new focused element is still inside the row
		if (event.currentTarget.contains(event.relatedTarget as any)) return;

		//check if the element is a child of a modal, if so we *might* still be inside the datagrid row, so dont save.
		if(isChildOfPopper(event.target)) return;
		if(isChildOfPopper(event.relatedTarget)) return;
		
		if (apiRef.current.getRowMode(rowId) === 'edit') {
			(async () => {
				try {
					//validate the row
					//await rowValidator(
					//	apiRef.current.getRowWithUpdatedValues(rowId) as any, 
					//	apiRef.current.getRow(rowId)
					//);

					//try and commit the row change & set the row back to view
					try {
						apiRef.current.stopRowEditMode({id: rowId, ignoreModifications: false});
					} catch {}
				} catch (error) {
					//handle error
					handleRowSaveError(rowId, error);
				}
			})();
		}
	}, [apiRef, rowValidator]);

	//handle getting row style (used to apply failed row styling)
	const getRowClassName = useCallback<NonNullable<TypedEditDataGridProps<DM, RM, E, COLS, Mode, COL_DEFS>['getRowClassName']>>((params) => {
		if (optionsRef.current.logging === 'DEBUG')console.log("Data Grid get row class name");
		return cx(
			{ 
				[classes.failedRow]: failedSaveRowIds.includes(params.id) 
			}, 
			otherProps.getRowClassName ? otherProps.getRowClassName(params) : undefined
		);
	}, [failedSaveRowIds, classes, cx, otherProps.getRowClassName]);

    //when rows changes/is loaded add the persistent row
    useEffect(() => {
		if (rows === undefined) return;

        // handle creating/removing the persistent row as needed, not shown when the grid is disabled
        if (!disabled && optionsRef.current.disablePersistentEditRow) {
			remakePersistentRow(apiRef as any, false);
		}
		if (disabled) {
			deletePersistentRow(apiRef as any);
			try {
				// cancel all edits
				const editingIds = Object.keys(apiRef.current.getRowModels());
				editingIds.forEach(id => apiRef.current.stopRowEditMode({ id: id, ignoreModifications: true }) );

				// clear failed save rows
				setFailedSaveRowIds([]);
			} catch {}
		}
    }, [disabled, rows, apiRef]);

	//handle save action click
	const handleSaveClick = useCallback((id: TGDM_Id<DM>) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid handle save click");
		event.stopPropagation();

		if (id === PERSISTENT_EDIT_ID) {
			apiRef.current.stopRowEditMode({id, ignoreModifications: false});
		}
		else {
			//commit row changes & set the row to view
			apiRef.current.stopRowEditMode({id, ignoreModifications: false});
		}
	}, [apiRef]);

	//handle cancel cancel click
	const handleCancelClick = useCallback(
		(id: TGDM_Id<DM>) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		  if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid handle cancel click");
		  event.stopPropagation();
	  
		  // Remove row from failed row save list
		  removeFailedSaveRow(id);
	  
		  // Fetch the row data
		  const row = apiRef.current.getRow(id);
	  
		  // If the row is the persistent edit row, reset it
		  if (id === PERSISTENT_EDIT_ID) {
			console.log('Resetting persistent row');
			resetPersistentRow(apiRef as any, row, false);  // Pass the row or null (not false)
		  } else {
			// Don't commit row changes and set the row back to view mode
			apiRef.current.stopRowEditMode({ id, ignoreModifications: true });
		  }
		},
		[apiRef]
	  );
	  
	  

	//handle edit click
	const handleEditClick = useCallback((id: TGDM_Id<DM>) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid handle edit click");
		//stop event propigation
		event.stopPropagation();

		//set row to edit mode
		apiRef.current.startRowEditMode({id});

		//croll to & focus row now in edit mode
		const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(id);
		apiRef.current.scrollToIndexes({ rowIndex });
		const columns = apiRef.current.getAllColumns();
		apiRef.current.setCellFocus(id, columns[0].field);
	}, [apiRef]);

	//handle delete click
	const handleDeleteClick = useCallback((id: TGDM_Id<DM>) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (optionsRef.current.logging === 'DEBUG') console.log("Data Grid handle delete click");
		//stop event propigation
		event.stopPropagation();

		if (onRowDelete) {
			const record = apiRef.current.getRow(id);
			if (record === null) return;

			const rowMap = apiRef.current.getRowModels();
			const rows = Array.from(rowMap, (item) => {
				return item[1];
			});

			apiRef.current.updateRows([{ id: id, _action: 'delete' }] as any);

			//call delete handler
			onRowDelete(id, record)
			.catch(() => {
				apiRef.current.setRows(rows);
			});
			/* .then(() => {
				//delete row from datagrid
				//apiRef.current.updateRows([{ id: id, _action: 'delete' }] as any);
				//apiRef.current.setRows(rows.filter(x => x.id !== record.id));
				
			})
			 */
		}
	}, [apiRef, onRowDelete]);

	//get columns def with added Edit/Delete actions row
	const columns = useMemo(() => {
		//add cell class name "Not Editable" to all non editable columns so that the backgrounds can be styled.
		let modifiedColumns = preActionsColumns.map((column) => {
			return {cellClassName: 'baseGridColor',  ...column};
		});

		return [
			...modifiedColumns,
			{
				field: 'edit-actions',
				type: 'actions',
				headerName: '',
				width: 80,
				cellClassName: 'edit-actions',
				getActions: ({ id }: { id: TGDM_Id<DM>; }) => {
					//determine the actions based on the current mode
					const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

					if (isInEditMode) return [
						<MuiGrid.GridActionsCellItem disabled={disabled} onClick={handleSaveClick(id)} icon={<SaveIcon/>} label={"Save"} color={"primary"} size={"small"}/>,
						<MuiGrid.GridActionsCellItem disabled={disabled} onClick={handleCancelClick(id)} icon={<CloseIcon/>} label={"Cancel"} color={"inherit"} size={"small"}/>
					];

					return [
						<MuiGrid.GridActionsCellItem disabled={disabled} onClick={handleEditClick(id)} icon={<EditIcon/>} label={"Edit"} color={"inherit"} size={"small"}/>,
						...(onRowDelete ? [<MuiGrid.GridActionsCellItem disabled={disabled || id === PERSISTENT_EDIT_ID || disableDeleteButton} onClick={handleDeleteClick(id)} icon={<DeleteIcon/>} label={"Delete"} color={"inherit"} size={"small"}/>] : [])
					];
				}
			},
		];
	}, [disabled, apiRef, preActionsColumns, handleCancelClick, handleDeleteClick, handleEditClick, handleSaveClick, onRowDelete]);

	const gridClasses = useMemo<Partial<MuiGrid.GridClasses>>(() => disabled ? { ...disabledClasses, ...(props?.classes) } : props?.classes ?? {}, [disabledClasses, props?.classes]);

	//no autosizer
	if (otherProps.autoHeight || otherProps.disableAutosizer) {
		return <NoEditFilterDataGrid 
    		    {...({
    		        ...otherProps as any,
    		        rows,
					columns,
					processRowUpdate: async (newRow: RM, oldRow: RM) => {
						const returnedValue = onRowEditCommit(newRow, oldRow);
					
						return returnedValue;
					},
    		        apiRef,
					onCellClick: handleCellClick,
					onRowEditStop: handleRowEditStop,
					getRowClassName,
					onCellFocusOut: props?.onCellFocusOut ?? ((_, event) => { event.defaultMuiPrevented = true; }),
					componentsProps: {
						...props?.componentsProps,
						row: { ...props?.componentsProps?.row, onKeyDown: handleRowKeyDown, onBlur: handleRowLeave },
					},
					onRowEditStart: disabled ? ((_, event) => { event.defaultMuiPrevented = true; }) : undefined,
					classes: gridClasses,
    		    } as DataGridProProps as any)} 
    		// eslint-disable-next-line semi
    		/>
	}

    //render grid with props
    return <AutoSizer>
			{({ width, height }) => (
                <div style={{height: height, width: width}}>
					<NoEditFilterDataGrid 
    				    {...({
    				        ...otherProps as any,
    				        rows,
							columns,
							processRowUpdate: async (newRow: RM, oldRow: RM) => {
								const returnedValue = onRowEditCommit(newRow, oldRow);
							
								return returnedValue;
							},
    				        apiRef,
							onCellClick: handleCellClick,
							onRowEditStop: handleRowEditStop,
							getRowClassName,
							onCellFocusOut: props?.onCellFocusOut ?? ((_, event) => { event.defaultMuiPrevented = true; }),
							componentsProps: {
								...props?.componentsProps,
								row: { ...props?.componentsProps?.row, onKeyDown: handleRowKeyDown, onBlur: handleRowLeave },
							},
							onRowEditStart: disabled ? ((_, event) => { event.defaultMuiPrevented = true; }) : undefined,
							classes: gridClasses,
    				    } as DataGridProProps as any)} 
    				/>
				</div>
			)}
		</AutoSizer>;
};
