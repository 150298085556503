import React, { useState } from 'react';
import { MuiForm, MuiFormPartial, MuiFormRef, useMuiForm , Editable, FormTextField, FormDatePicker, FormNumberSelect, FormNumberTextField, FormCheckbox } from '@imas/mui-form';
import { Alignment, LabeledItem } from '@imas/components/layout';
import { ClientPurchaseOrder, GetPoAmounts, TClientPurchaseOrderForm, Client } from '@imas/api/client';
import { Button, TextField, Typography, Link } from '@mui/material';
import { useLoadApi } from '@imas/api';
import { PurchaseOrderRevisionsGrid } from './ClientPurchaseOrderFormComponents/PurchaseOrderRevisionsGrid';
import { Link as LinkIcon, LinkOff as LinkOffIcon, LockOpen as LockOpenIcon, Lock as LockIcon, Add as AddIcon } from '@mui/icons-material';
import { useConfirmationDialog, useFileExplorerDialog } from '@imas/utils/dialog';
import { FileTables, GetFileInfo } from '@imas/api/files';
import { downloadFile } from '@imas/utils/files';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';

const defaultValues: Editable<TClientPurchaseOrderForm> = {
    clientId: null,
	number: null,
    amount: 0,
    issuedDate: new Date(),
    expirationDate: null,
    type: null,
    notes: null,
    discontinued: false,
    pathLocator: null,
};


interface ClientPurchaseOrderFormProps { 
    clientId: int;
    client?: Client,
    clientPONumId: int | null,
    purchaseOrder?: ClientPurchaseOrder,
    clientPurchaseOrders?: ClientPurchaseOrder[],
    editable: boolean,
    onEditClick: () => void,
    onRevisionClick: () => void,
    form: MuiFormRef<TClientPurchaseOrderForm, ClientPurchaseOrder> | null,
    filePath?: HierarchyId
};

export const ClientPurchaseOrderForm = React.memo(React.forwardRef<MuiFormRef<TClientPurchaseOrderForm>, MuiFormPartial & ClientPurchaseOrderFormProps>(({ clientId, client, clientPONumId, purchaseOrder, clientPurchaseOrders, editable, onEditClick, onRevisionClick, form, filePath, ...props }, ref) => {
    
    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //form hook
    const { muiFormProps, control, setValue, watch } = useMuiForm<TClientPurchaseOrderForm>({ 
        defaultValues: { ...defaultValues, clientId },
        validator: ({ clientId, number, type, amount, issuedDate, ...otherFields }, error) => {

			//ensure required fields are required
            if (number === null) error("number", "required");
            if (issuedDate === null) error("issuedDate", "required");
            if (type === null) error("type", "required");

            //ensure that the PO does not have the ponum as another PO from the same client
            if (!clientPurchaseOrders) return;
            if (clientPurchaseOrders.findIndex(x => x.number === number && (x.id !== clientPONumId || clientPONumId === null)) !== -1) {
                error("number", "validate");
                return;
            }

            //ensure type safety
            if (clientId === null) return;
            if (number === null) return;
            if (issuedDate === null) return;
            if (type === null) return;
            if (amount === null) amount = 0;

            return { clientId, number, type, amount, issuedDate, ...otherFields };
        },
    });

    const pathLocator = watch('pathLocator');

    //use file dialog for linking files
    const [openExplorerDialog] = useFileExplorerDialog();
    const [openConfirmationDialog] = useConfirmationDialog();

    //get PO amounts (total chargeable amount)
    const {data: poAmounts} = useLoadApi(GetPoAmounts, [clientPONumId ?? -1], [clientPONumId, editable], {disabled: clientPONumId === null});

    const {data: attachedFile, clear: clearFile} = useLoadApi(GetFileInfo, [FileTables.JobFiles, pathLocator ?? ''], [pathLocator], {disabled: !pathLocator});


    //separate loading from other props
    const { loading, viewOnly, ...otherProps } = props;

    //form data
    return (
        <MuiForm
            loading={loading}
            viewOnly={viewOnly}
            {...otherProps}
            {...muiFormProps}
            ref={ref}
        >
            <Alignment row>
                {/* Client Name */}
                <LabeledItem label={'Client:'} row item={client?.name} labelProps={{variant: 'h5', fontWeight: 'bold'}} itemTextProps={{variant: 'h5'}} />

                {/* Discontinued */}
                <FormCheckbox
					name={"discontinued"}
					control={control}
                    disabled={!editable}
					label={"Discontinued"}
					sx={{ margin: '0 5px 0 auto'}}
				/>
            </Alignment>

            <Alignment row sx={{marginBottom: '15px' }}>
                {/* PO Number Field */}
                <LabeledItem label={'P.O. No.:'} row  labelProps={{variant: 'h5', fontWeight: 'bold'}} itemTextProps={{variant: 'h5'}} item={(
                    <FormTextField
                        name={'number'}
                        control={control}
                        disabled={!editable}
                        variant={editable ? 'outlined' : 'standard'}
                        //label={'P.O. Number'}
                        required
                        InputProps={{disableUnderline: !editable}}
                        sx={{width: '245px', "& .MuiInputBase-input": { fontSize: '1.5rem', padding: '0 0 0 5px' } }}
                    />
                )} />

                {/* Rev # */}
                {purchaseOrder?.revision ? purchaseOrder.revision !== 0 ? 
                    <Typography color={'textPrimary'} variant={'h6'} sx={{marginLeft: '10px', alignSelf: 'center'}} >{`(Rev. ${purchaseOrder.revision})`}</Typography>
                : null : null}
            </Alignment>

            {clientPONumId === null ? null : (
                <Alignment row  sx={{marginBottom: '15px'}}>
                    <Button
                        size={"medium"}
                        disabled={editable && !(form?.isValid ?? false)}
                        startIcon={editable ? <LockIcon/> : <LockOpenIcon/>}
                        onClick={() => onEditClick()}
                    >{editable ? 'Lock' : 'Edit'}</Button>

                    <Button
                        size={'medium'}
                        disabled={editable}
                        startIcon={<AddIcon/>}
                        onClick={() => onRevisionClick()}
                        sx={{marginLeft: '270px'}}
                    >{'New Revision'}</Button>
                
                </Alignment>
            )}

            <Alignment row flex sx={{ marginBottom: '10px' }}>
                <Alignment column flex>
                    
                    

                    <Alignment row sx={{ marginBottom: '15px' }}>
                        {/* Issued Date Field */}
                        <FormDatePicker
                            name={'issuedDate'}
                            control={control}
                            disabled={!editable}
                            required
                            label={"Issued On"}
                            renderInput={(props) => <TextField {...props} />}
                            sx={{width: '150px'}}                 
                        />

                        {/* Expiration Date Field */}
                        <FormDatePicker
                            name={'expirationDate'}
                            control={control}
                            disabled={!editable}
                            label={"Expires On"}
                            renderInput={(props) => <TextField {...props} />}
                            sx={{ marginLeft: '10px', width: '150px' }}
                        />

                         
                    </Alignment>         

                    {/* P.O. Type Field */}
                    <FormNumberSelect
                        name={'type'}
                        control={control}
                        disabled={!editable}
                        label={'P.O. Type'}
                        required
                        options={[{label: 'Signed', value: 1}, {label: 'Written', value: 2}, {label: 'Verbal', value: 3}]}
                        sx={{ marginBottom: '10px', width: '150px' }}
                    />

                </Alignment>

                <PurchaseOrderRevisionsGrid purchaseOrderId={clientPONumId ?? undefined} />

            </Alignment>

            {/* P.O. Issued Amount */}
            <Alignment row sx={{marginBottom: '10px'}}>
                <Typography color={'textPrimary'} variant={'body1'} fontWeight={'bold'} textAlign={'right'} width={'250px'} alignSelf={'center'}>
                    {'P.O. Issued Amount:'}
                </Typography>
 
                {/* PO issued amount */}
                <FormNumberTextField
                    name={'amount'}
                    control={control}
                    disabled={!editable}
                    decimalScale={2}
                    fixedDecimalScale={true} 
                    prefix={"$"}
                    inputProps={{ style: {textAlign: 'right'} }}
                    sx={{ width: '196px', marginLeft: '10px' }}
                />
            </Alignment>

            <Alignment row>
                <Typography color={'textPrimary'} variant={'body1'} fontWeight={'bold'} textAlign={'right'} width={'250px'}>
                    {'Total Chargeable Amount:'}
                </Typography>

                <Typography color={'textPrimary'} variant={'body1'} textAlign={'right'} width={'200px'} marginRight={'20px'}>
                    {poAmounts === undefined ? '' : poAmounts[5].formattedTotal}
                </Typography>

                <Typography color={'textPrimary'} variant={'body1'} >
                    {poAmounts === undefined ? '' : poAmounts[5].metricDescription}
                </Typography>
            </Alignment>

            <Alignment row sx={{marginBottom: '5px'}}>
                <Typography color={'textPrimary'} variant={'body1'} fontWeight={'bold'} textAlign={'right'} width={'250px'}>
                    {poAmounts === undefined ? 'Amount Remaining:' : 'Amount Remaining ' + poAmounts[7].metric.substring(14, poAmounts[7].metric.length -1) + ':'}
                </Typography>

                <Typography color={'textPrimary'} variant={'body1'}  textAlign={'right'} width={'190px'} paddingRight={'10px'} marginLeft={'10px'} marginRight={'10px'} borderTop={'ridge'}>
                   {poAmounts === undefined ? '' : poAmounts[7].formattedTotal}
                </Typography>

                <Typography color={'textPrimary'} variant={'body1'} >
                    {poAmounts === undefined ? '' : poAmounts[7].metricDescription}
                </Typography>
            </Alignment>      

            {/* Attach File */}
            <Alignment row sx={{marginBottom: '10px'}}>

                {/* File Link Button */}
                <Button
			    	size={"medium"}
			    	startIcon={<LinkIcon/>}
                    disabled={!filePath || !editable}
			    	onClick={async () => {
			    		//open link dialog
			    		const file = await openExplorerDialog("pick-file", {
			    			table: FileTables.JobFiles, 
			    			folder: filePath, 
			    			maxLevelsUp: 1,
			    			allowUpload: false,
			    		});

			    		//cancel if no file was picked
			    		if (file === null) return;

                        setValue('pathLocator', file.pathLocator);
			    	}}
			    	sx={{ marginLeft: '10px', marginRight: '10px' }}
			    >{"Link"}</Button>

                <Link 
                    component={'button'}
                    variant={'body1'}
                    onClick={() => downloadFile(pathLocator ?? '', FileTables.JobFiles)}
                >{attachedFile ? attachedFile.name + '.' + attachedFile.extension : ''} </Link>

                <Button
                    size={'medium'}
                    disabled={!filePath || !editable}
                    color={'secondary'}
                    onClick={async () => {
                        const result = await openConfirmationDialog({
                            title: 'Confirm Removing Link',
                            prompt: 'Do you want to remove the existing link?',
                            confirmText: 'Break Link',
                            confirmIcon: <LinkOffIcon/>
                        });

                        if (result) {
                            setValue('pathLocator', null);
                            clearFile();
                        }
                    }}
                    sx={{marginLeft: 'auto'}}
                ><LinkOffIcon/></Button>



            </Alignment>      

            {/* Note Field */}
            <FormTextField 
                name={'notes'}
                control={control}
                disabled={!editable}
                label={'Notes'}
                multiline
                rows={5}
            />
			
        </MuiForm>
    );
}));