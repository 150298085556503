import { UpdateType } from '@imas/api';
import { EditorDataTypeMap, EditorUrls, EditorUpdate } from './types';

const isWindow = (obj: any): obj is Window => {
	return typeof obj === "object" && obj === obj["self"];
};

//send an editor update to parrent window
export function sendEditorUpdate<E extends EditorUrls>(url: E, type: UpdateType, data: EditorDataTypeMap[E]) {
	//get window opener
	const opener = window.opener;

	if (window.isIMASPopup && window.windowId && isWindow(opener)) {
		//create update message
		const update: EditorUpdate<E> = {
			isEditorUpdate: true,
			windowId: window.windowId,
			url,
			type,
			data,
		};
		
		//post update message
		opener.postMessage(update);
	}
};