import React, { useContext } from 'react';
import { Alignment } from "@imas/components/layout";
import { FileExplorerAction } from "../types";
import { FileExplorerContext } from '../FileExplorerContext';
import moment from 'moment';
import { Button, ButtonProps, Typography } from '@mui/material';
import { getModeDisplayName } from '../FileExplorerUtils';
import { fileListToArray, formatFileSize } from '@imas/utils/files';

export interface FileExplorerFooterProps {
    actions?: FileExplorerAction[];
};

const FileExplorerFooter = React.memo(({ actions }: FileExplorerFooterProps) => {
    //use explorer context
    const { mode, currentDir, items, selected, upload } = useContext(FileExplorerContext);

    //create's action button props given the action & current state of this component
    const createActionProps = (action: FileExplorerAction): ButtonProps & { component?: string, children: React.ReactNode } => {
        //get action text
        const actionText: string = typeof (action.text) === "string" ? action.text : action.text(selected); 

        //similar props object, populate with similarities
        let similarProps: ButtonProps & { children: React.ReactNode } = {
            variant: "contained",
            color: action.color ?? "primary",
            startIcon: action.startIcon,
            children: actionText,
        };
        
        if (action.type === "generic") {
            return {
                ...similarProps,
                disabled: action.disabled && action.disabled(selected),
                onClick: action.onClick,
            };
        } else if (action.type === "select") {
            return {
                ...similarProps,
                disabled: action.disabled && action.disabled(selected),
                onClick: () => action.onClick(selected),
            };
        } else if (action.type === "upload") {
            //a list of selected files which includes the current directory if no other options are provided
            const selectedIncludingCurrent = selected.length === 0 ? [{ name: currentDir.name, pathLocator: currentDir.pathLocator, lastWrite: moment().format(), type: null, size: 0, isDir: true }] : selected;

            return {
                ...similarProps,
                disabled: !((selected.length === 1 && selected[0]?.isDir) || selected.length === 0) || (action.disabled && action.disabled(selectedIncludingCurrent)),
                component: "label",
                children: (
                    <>
                        {typeof (action.text) === "string" ? action.text : action.text(selectedIncludingCurrent)}
                        <input 
                            onChange={(event) => {
                                //get target folder
                                const target = selectedIncludingCurrent[0];

                                //do nothing if any of these conditions are met
                                if (event.target.files === null || event.target.files[0] === undefined || target === undefined) return;
                                
                                if (mode === "upload-file") {
                                    //only call upload on the first file
                                    upload(event.target.files[0], target);
                                } else {
                                    //upload all files
                                    upload(fileListToArray(event.target.files), target);
                                }
                            }}
                            type={"file"}
                            multiple={mode !== "upload-file"}
                            hidden
                            accept={action.accept?.join(",")}
                        />
                    </>
                ), 
            };
        }

        return similarProps;
    };

    return (
        <Alignment column>
            {/* Footer with number of total items & select items  */}
            <Alignment row sx={{ margin: '0 5px' }}>
                {/* Show Info Total # of Items */}
                <Typography variant={"caption"} sx={{ color: 'text.secondary' }}>{items ? `${items.length} item${items.length === 1 ? "" : "s"}` : "Loading...."}</Typography>
                        
                {/* Show Selected Information */}
                {selected.length > 0 ? (
                    <Typography 
                        variant={"caption"} 
                        sx={{ color: 'text.secondary', marginLeft: '15px' }}
                    >{`${selected.length} item${selected.length === 1 ? "" : "s"}  selected ${formatFileSize(selected.map(x => x.size).reduce((a, b) => a + b, 0))}`}</Typography>
                ) : null}

                {/* Display the Current Explorer Mode */}
                <Typography 
                    variant={"caption"} 
                    sx={{ color: 'text.secondary', marginLeft: 'auto' }}
                >{getModeDisplayName(mode)}</Typography>
            </Alignment>

            {/* Button Actions */}
            {actions && actions.length > 0 ? (
                <Alignment rowReverse sx={{ "& > *:not(:last-child)": { marginLeft: '10px' } }}>
                    {/* Render Action Buttons */}
                    {actions.map((action, index) => <Button key={index} {...createActionProps(action)}/>)}
                </Alignment>
            ) : null }
        </Alignment>
    );
});

export { FileExplorerFooter };