import { Proposal } from '@imas/api/proposal';
import { ClientLocationContact } from '@imas/api/client';
import { useClientLocationContactProposals } from '@imas/api/data-hooks';
import { Alignment } from '@imas/components/layout';
import { Typography, IconButton, ListItem, ListItemText } from '@mui/material';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import { memo } from 'react';
import { FormPaper } from '@imas/mui-form';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { openProposalEditor } from 'src/pages/editors';
import { getUserRoles } from '@imas/redux';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

export interface ClientLocationContactProposalsProps {
	contact?: ClientLocationContact | null;
};

const renderProposalRow = ({ style, index, data }: ListChildComponentProps<{ canEdit: boolean; proposals: Proposal[]; }>) => {
	const proposal = data.proposals[index];

	return (
		<ListItem style={style} key={index} component={"div"} disablePadding dense>
			<IconButton
				disabled={!data.canEdit}
				size={"small"}
				sx={{ margin: '0 10px' }}
				onClick={() => { openProposalEditor({ proposalId: proposal.id }); }}
			><EditIcon/></IconButton>
			<ListItemText primary={`${proposal.siteId ?? "X"}${proposal.departmentId ?? "X"}-${proposal.id} ${proposal.name}`}/>
		</ListItem>
	);
};

export const ClientLocationContactProposals = memo(({ contact }: ClientLocationContactProposalsProps) => {
	//get vContactsPrimaryPhoneNumber records
	const [proposals] = useClientLocationContactProposals(contact?.id ?? -1, { disabled: !contact });

	//get the user's role, if they are not at least a power user then they cannot edit/create a new proposal
	const { powerUser } = useSelector(getUserRoles);
	const canEditProposals =  powerUser && false; //powerUser;

	return (
		<FormPaper sx={{ display: 'flex', flexDirection: 'column', flex: '1', overflow: 'hidden' }}>
			<Alignment row sx={{ margin: '3px 3px 0 10px' }}>
				<Typography variant={"h6"} sx={{ fontWeight: 'bold', color: 'text.primary' }}>{"Proposals/Jobs"}</Typography>

				{/* New Proposal With Contact */}
				<LoadingButton
					disabled={!canEditProposals || contact === null}
					variant={"contained"}
					color={"primary"}
					size={"small"}
					startIcon={<AddIcon/>}
					loading={contact === undefined}
					loadingPosition={"start"}
					onClick={() => {}}
					sx={{ marginLeft: 'auto' }}
				>{"Proposal"}</LoadingButton>
			</Alignment>

			{contact === null ? (
				<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until contact is saved."}</Typography>
			) : contact ? (
				<Alignment column flex> 
					<AutoSizer>
						{({ height, width }) => (
							<FixedSizeList
								height={height}
								width={width}
								itemCount={(proposals ?? []).length}
								itemSize={32}
								itemData={{ canEdit: canEditProposals, proposals: proposals ?? [] }}
							>
								{renderProposalRow}
							</FixedSizeList>
						)}
					</AutoSizer>
				</Alignment>
			) : null}
		</FormPaper>	
	);
});