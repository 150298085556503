import { axiosData, SourceGetter } from "@imas/api";
import { EmployeeVacation, EmployeeSick} from './types';

//states base url
const TIME_OFF_POLICY_URL = "/api/system/time-off-policies";

/**  */
export const GetVacs = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeVacation[]> => {
        return await axiosData({
            method: 'get',
            url: `${TIME_OFF_POLICY_URL}/vacs`,
            cancelToken: getSource().token,
        });
    };
};

export const GetSicks = (getSource: SourceGetter) => {
    return async(): Promise<EmployeeSick[]> => {
        return await axiosData({
            method: 'get',
            url: `${TIME_OFF_POLICY_URL}/sicks`,
            cancelToken: getSource().token,
        });
    };
};
