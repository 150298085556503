import { useApi, useLoadApi } from "@imas/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RenameFile, MoveFile, DeleteFile, GetFolderInfo, UploadFile } from '@imas/api/files';
import { 
    FileExplorerUploadHandler, FileExplorerRenameHandler, FileExplorerMoveHandler, FileExplorerDeleteHandler, InternalHandlerResult, 
    Directory, FailedAction,  FileExplorerCanNavigateFunction, FileExplorerNavigateFunction, FileExplorerRefreshFunction, FileExplorerUploadFunction, 
    FileExplorerRenameFunction, FileExplorerMoveFunction, FileExplorerDeleteFunction 
} from './types';
import { FileTables, FolderInfoBreadcrumb, FolderInfoItem } from "@imas/api/files/types";
import { getFileError, getFileErrorMessage, getLevel, separateHandlerResults } from "./FileExplorerUtils";
import Enumerable from 'linq';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';

export interface useFileExplorerProps {
    table: FileTables;
    folder?: HierarchyId | null;

    /** The maximum number of levels the user can navigate up from the root folder. */
    maxLevelsUp?: int;
    /** The maximum number of levels the user can navigate down from the root folder. */
	maxLevelsDown?: int;
	

    //file filter, can be used to hide or show specific files
    filter?: (rows: FolderInfoItem[]) => FolderInfoItem[];

    //file processing handlers
    handleUpload?: FileExplorerUploadHandler;
    handleRename?: FileExplorerRenameHandler;
    handleMove?: FileExplorerMoveHandler;
    handleDelete?: FileExplorerDeleteHandler;
};

export interface useFileExplorerResult {
    currentDirectory: Directory;
    items?: FolderInfoItem[];
    breadcrumbs?: FolderInfoBreadcrumb[];
    navHistory: Directory[];
    goBackHistory: Directory[];
    canNavigateTo: FileExplorerCanNavigateFunction;
    navigate: FileExplorerNavigateFunction;
    goBack: () => void;
    undoGoBack: () => void;
    refresh: FileExplorerRefreshFunction;
    upload: FileExplorerUploadFunction; 
    rename: FileExplorerRenameFunction;
    move: FileExplorerMoveFunction;
    delete: FileExplorerDeleteFunction; 
};

export function useFileExplorer(props: useFileExplorerProps): useFileExplorerResult {
    const initDirectory = props.folder ?? "/";
	const { filter } = props;

	//propsRef
	const propsRef = useRef(props);

    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //use APIs
    const uploadFileApi = useApi(UploadFile);
    const renameFileApi = useApi(RenameFile);
    const moveFileApi = useApi(MoveFile);
    const deleteFileApi = useApi(DeleteFile);

    //navLevel of the initial folder
    const initNavLevel = useMemo(() => getLevel(initDirectory), [initDirectory]);

    //function used to check if a given folder can be navigated to
    const canNavigateTo = useCallback((pathLocator: HierarchyId): boolean => {
        //get maxLevelsUp & maxLevelsDown
		const maxLevelsUp = propsRef.current.maxLevelsUp ?? 1_000_000;
		const maxLevelsDown = propsRef.current.maxLevelsDown ?? 1_000_000;
		
		//current level
		const currentLevel = getLevel(pathLocator);
        
        //if the destination is more levels up or down than is allowed by maxLevelsUp/Down then retun false
        return !(currentLevel < (initNavLevel - maxLevelsUp) || currentLevel > (initNavLevel + maxLevelsDown));
    }, [initNavLevel]);

    //directory currently being viewed
    const [currentDir, setCurrentDir] = useState<Directory>({
        name: "",
        pathLocator: initDirectory,
    });

    //update currentDir when initDirectory changes
    useEffect(() => setCurrentDir({ name: "", pathLocator: initDirectory }), [initDirectory]);

    //navigation history stacks
    const [navHistory, setNavHistory] = useState<Directory[]>([]);
    const [goBackHistory, setGoBackHistory] = useState<Directory[]>([]);

    //list of failed actions
    const [failedActions, setFailedActions] = useState<FailedAction[]>([]);

    //show error messages as stopgap
    useEffect(() => {
        if (failedActions.length < 1) return;

        setFailedActions(actions => {
            for (const action of actions) {
                if (action.failed.length < 1) continue;

                //show error message
                showSnackbar(getFileErrorMessage(action.failed[0].error), { variant: 'error' });
            }

            return [];
        });
    }, [failedActions, showSnackbar]);

    //get the info for the current directory
    const { data: directoryInfo, update: setDirectoryInfo, call: refreshDirectory } = useLoadApi(GetFolderInfo, [props.table, currentDir.pathLocator], [props.table, currentDir.pathLocator]);

    //filter folder items if filter is provided
    const filteredItems = useMemo(() => {
        if (directoryInfo?.rows === undefined) return;

        //if filter is provided filter the rows, otherwise do nothing
        if (filter === undefined) return directoryInfo.rows;
        else return filter(directoryInfo.rows);
    }, [filter, directoryInfo?.rows]);

    //sort folder items
    const sortedItems = useMemo(() => {
        if (!filteredItems) return;

        //apply user sorting preference
        const sorted = Enumerable.from(filteredItems).orderBy(x => x.name).toArray();

        //sort so folders are above other files
        const folders = sorted.filter(x => x.isDir);
        const files = sorted.filter(x => !x.isDir);

        return [...folders, ...files];
    }, [filteredItems]);

    //useEffect to get original folder name when breadcrumbs are first loaded 
    useEffect(() => {
        if (currentDir.name === "" && directoryInfo) {
            for (const crumb of directoryInfo.breadcrumbs) {
                if (crumb.pathLocator === currentDir.pathLocator) setCurrentDir(x => ({ ...x, name: crumb.name }));
            }  
        };
    }, [directoryInfo, currentDir]);

    //navigate back to previous folder
    const goBack = useCallback(() => {
        setCurrentDir(x => {
            let current = x;

            setNavHistory(x => {
                //get last element in history list
                const dest = x.pop();

                if (dest === undefined) return x;

                //set current to the destination
                current = dest;

                //add current dir to goBackHistory
                setGoBackHistory(y => [...y, current]);

                //return history with the removed element
                return [...x];
            });

            //propagate changes
            return current;
        });
    }, []);

    //undo a navigation to a previous folder
    const undoGoBack = useCallback(() => {
        setCurrentDir(x => {
            let current = x;

            setGoBackHistory(history => {
                //get last element in history list
                const dest = history.pop();

                if (dest === undefined) return history;

                //set current to the destination
                current = dest;

                //add current dir to navHistory
                setNavHistory(y => [...y, current]);

                //return history with the removed element
                return [...history];
            });

            //propagate changes
            return current;
        });
    }, []);

    //navigate callback
    const navigate = useCallback((name: string, pathLocator: HierarchyId) => {
        //check if navigation is allowed, if not show an error message
        if (!canNavigateTo(pathLocator)) {
            showSnackbar(`Navigation to '${name}' is not allowed.`, { variant: "error" });
            return;
        };

        //set the value of current dir
        setCurrentDir(previousFolder => {
            //add previous folder to navHistory
            setNavHistory(x => {
                //do not update history if we did not change directories
                if (x.length > 0 && x[x.length - 1].pathLocator === previousFolder.pathLocator) return x;
                
                return [...x, previousFolder];
            });

            //delete any goBack history
            setGoBackHistory([]);

            return { name, pathLocator };
        });
    }, [canNavigateTo, showSnackbar]);

    //default upload handler
    const defaultUploadHandler = useCallback<FileExplorerUploadHandler>(async (file, folder, table) => uploadFileApi(table, folder, file), [uploadFileApi]);

    //upload callback function, uses the provided handleUpload function or defaultUploadHandler if handleUpload is not provided
    const uploadCallback = useCallback(async (file: File, folder: FolderInfoItem): Promise<InternalHandlerResult<File>> => {
        const handleUpload = propsRef.current.handleUpload ?? defaultUploadHandler;

        //call handler and get error message if one was returned
        const error = await handleUpload(file, folder.pathLocator, propsRef.current.table);
        
        return { file, error: getFileError(error) };
    }, [defaultUploadHandler]);
 
    //upload function
    const uploadFiles = useCallback(async (files: File[] | File, folder: FolderInfoItem) => {
        //files as an array
        const filesList = files instanceof Array ? files : [files];
            
        try {
            //create promises 
            const requests = filesList.map(x => uploadCallback(x, folder));

            //wait for all of them to settle
            const results = await Promise.all(requests);
            
            //separate results into failed and deleted
            const [failed, uploaded] = separateHandlerResults(results);

            //if failedFiles.length > 0 then add a record to the failedActions list
            if (failed.length > 0) setFailedActions(x => [...x, { type: "UPLOAD", failed }]);

            //refresh the directory to show new files
            if (uploaded.length > 0) refreshDirectory();

            //return uploaded files
            return;
        } catch (e) {
            //show an error message if e is not null
            if (e !== null) showSnackbar(`Failed to upload file${filesList.length > 1 ? "s" : ""} for an unknown reason.`, { variant: "error" });

            return;
        };
    }, [refreshDirectory, showSnackbar, uploadCallback]);

    //default rename handler
    const defaultRenameHandler = useCallback<FileExplorerRenameHandler>(async (file, name, table) => renameFileApi(table, file, name), [renameFileApi]);

    //rename callback function, uses the provided handleRename function or defaultRenameHandler if handleRename is not provided
    const renameCallback = useCallback(async (file: FolderInfoItem, name: string): Promise<InternalHandlerResult<FolderInfoItem>> => {
        const handleRename = propsRef.current.handleRename ?? defaultRenameHandler;

        //call handler and get error message if one was returned
        const error = await handleRename(file.pathLocator, name, propsRef.current.table);
        
        return { file, error: getFileError(error) };
    }, [defaultRenameHandler]);
 
    //rename function
    const renameFile = useCallback(async (file: FolderInfoItem, name: string) => {     
        try {
            //call api
            const result = await renameCallback(file, name);

            //if the request failed add it to the list of failed actions
            if (result.error) setFailedActions(x => [...x, { type: "RENAME", failed: [result] }]);
            else {
                //update directory info so the new file name is shown
                setDirectoryInfo(info => {
                    if (!info) return info;
                    
                    return {
                        breadcrumbs: info.breadcrumbs,
                        rows: info.rows.map(x => {
                            if (x.pathLocator === result.file.pathLocator) return { ...x, name };
                            return x;
                        }),
                    };
                });
            };
        } catch (e) {
            //show an error message if e is not null
            if (e !== null) showSnackbar(`Failed to rename file for an unknown reason.`, { variant: "error" });
        };
    }, [renameCallback, setDirectoryInfo, showSnackbar]);

    //default move handler
    const defaultMoveHandler = useCallback<FileExplorerMoveHandler>(async (file, destination, table) => moveFileApi(table, file, destination), [moveFileApi]);

    //move callback function, uses the provided handleMove function or defaultMoveHandler if handleMove is not provided
    const moveCallback = useCallback(async (file: FolderInfoItem, destination: FolderInfoItem): Promise<InternalHandlerResult<FolderInfoItem>> => {
        const handleMove = propsRef.current.handleMove ?? defaultMoveHandler;

        //call handler and get error message if one was returned
        const error = await handleMove(file.pathLocator, destination.pathLocator, propsRef.current.table);
        
        return { file, error: getFileError(error) };
    }, [defaultMoveHandler]);
 
    //move function
    const moveFiles = useCallback(async (files: FolderInfoItem[] | FolderInfoItem, destination: FolderInfoItem) => {
        //files as an array
        const filesList = files instanceof Array ? files : [files];
            
        try {
            //create promises 
            const requests = filesList.map(x => moveCallback(x, destination));

            //wait for all of them to settle
            const results = await Promise.all(requests);
            
            //separate results into failed and deleted
            const [failed, moved] = separateHandlerResults(results);

            //if failedFiles.length > 0 then add a record to the failedActions list
            if (failed.length > 0) setFailedActions(x => [...x, { type: "MOVE", failed }]);

            //update directory info to exclude deleted files (if any were deleted)
            if (moved.length > 0) {
                setDirectoryInfo(info => {
                    if (!info) return info;
                    
                    return {
                        breadcrumbs: info.breadcrumbs,
                        rows: info.rows.filter(x => !moved.some(y => y.pathLocator === x.pathLocator)),
                    };
                });
            };
        } catch (e) {
            //show an error message if e is not null
            if (e !== null) showSnackbar(`Failed to move selected file${filesList.length > 1 ? "s" : ""} for an unknown reason.`, { variant: "error" });
        };
    }, [moveCallback, setDirectoryInfo, showSnackbar]);

    //default delete handler
    const defaultDeleteHandler = useCallback<FileExplorerDeleteHandler>(async (file, table) => deleteFileApi(table, file), [deleteFileApi]);

    //delete callback function, uses the provided handleDelete function or defaultDeleteHandler if handleDelete is not provided
    const deleteCallback = useCallback(async (file: FolderInfoItem): Promise<InternalHandlerResult<FolderInfoItem>> => {
        const handleDelete = propsRef.current.handleDelete ?? defaultDeleteHandler;

        //call handler and get error message if one was returned
        const error = await handleDelete(file.pathLocator, propsRef.current.table);
        
        return { file, error: getFileError(error) };
    }, [defaultDeleteHandler]);

    //delete function
    const deleteFiles = useCallback(async (files: FolderInfoItem[] | FolderInfoItem) => {
        //files as an array
        const filesList = files instanceof Array ? files : [files];
        
        try {
            //create promises 
            const requests = filesList.map(x => deleteCallback(x));

            //wait for all of them to settle
            const results = await Promise.all(requests);
            
            //separate results into failed and deleted
            const [failed, deleted] = separateHandlerResults(results);

            //if failedFiles.length > 0 then add a record to the failedActions list
            if (failed.length > 0) setFailedActions(x => [...x, { type: "DELETE", failed }]);

            //update directory info to exclude deleted files (if any were deleted)
            if (deleted.length > 0) {
                setDirectoryInfo(info => {
                    if (!info) return info;
                    
                    return {
                        breadcrumbs: info.breadcrumbs,
                        rows: info.rows.filter(x => !deleted.some(y => y.pathLocator === x.pathLocator)),
                    };
                });
            };

            return;
        } catch (e) {
            //show an error message if e is not null
            if (e !== null) showSnackbar(`Failed to delete selected file${filesList.length > 1 ? "s" : ""} for an unknown reason.`, { variant: "error" });
        };
    }, [deleteCallback, setDirectoryInfo, showSnackbar]);

    //return values & methods for interacting with the file explorer
    return {
        currentDirectory: currentDir,
        items: sortedItems,
        breadcrumbs: directoryInfo?.breadcrumbs,
        navHistory,
        goBackHistory,
        canNavigateTo,
        navigate,
        goBack,
        undoGoBack,
        refresh: refreshDirectory,
        upload: uploadFiles,
        rename: renameFile,
        move: moveFiles,
        delete: deleteFiles,
    };
}