import { mapApi, ApiConfig, axiosData, SourceGetter } from '@imas/api';
import { PhoneNumber } from '../phone-numbers';
import { Client, ClientLocation, ClientLocationContact, ClientLocationDocument, vContact, vContactPrimaryPhoneNumber, ClientLocationForm } from './types';

//client locations base url
const CLIENT_LOCATIONS_URL = "/api/clients/locations";

/*  */
export const GetAllClientLocations = mapApi((): ApiConfig<ClientLocation[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}`,
}));

/*  */
export const GetClientLocation = mapApi((locationId: int): ApiConfig<ClientLocation> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId}`,
}));

/*  */
export const GetClientLocationClient = mapApi((locationId: int): ApiConfig<Client> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId}/client`,
}));

/*  */
export const GetClientLocationContacts = mapApi((locationId: int | null): ApiConfig<ClientLocationContact[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId ?? -1}/contacts`,
}));

/*  */
export const GetClientLocationVContacts = mapApi((locationId: int | null): ApiConfig<vContact[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId ?? -1}/v-contacts`,
}));

/*  */
export const GetClientLocationVContactPrimaryPhoneNumbers = mapApi((locationId: int | null): ApiConfig<vContactPrimaryPhoneNumber[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId ?? -1}/v-contact-primary-phone-numbers`,
}));

/*  */
export const GetClientLocationPhoneNumbers = mapApi((locationId: int | null): ApiConfig<PhoneNumber[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId ?? -1}/phone-numbers`,
}));

/*  */
export const GetClientLocationDocuments = mapApi((locationId: int | null): ApiConfig<ClientLocationDocument[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATIONS_URL}/${locationId ?? -1}/documents`,
}));

/*  */
export const CreateClientLocation = mapApi((data: ClientLocationForm): ApiConfig<ClientLocation> => ({
	method: 'post',
	url: `${CLIENT_LOCATIONS_URL}`,
	data,
}));

/*  */
export const UpdateClientLocation = mapApi((clientId: int, data: ClientLocationForm): ApiConfig<ClientLocation> => ({
	method: 'put',
	url: `${CLIENT_LOCATIONS_URL}/${clientId}`,
	data,
}));

/*  */
export const DeleteClientLocation = mapApi((clientId: int): ApiConfig<ClientLocation> => ({
	method: 'delete',
	url: `${CLIENT_LOCATIONS_URL}/${clientId}`,
}));