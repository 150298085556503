import { SourceGetter, axiosData } from '@imas/api';
import { VersionCode, VersionCodeItem } from "src/api/types/api/misc/misc";
import axios from "axios";
import { Source } from '@mui/icons-material';

/**
 * Get the current version of the app
 */
 export const _GetCurrent = (getSource: SourceGetter) => {
    return (): Promise<VersionCode> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<VersionCode>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/version/current',
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

/**
 * Get all versions of the app
 */
export const _GetHistory = (getSource: SourceGetter) => {
    return (): Promise<VersionCode[]> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<VersionCode[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/version/history',
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get all change items for a specific version of the app
 */
export const _GetChanges = (getSource: SourceGetter) => {
    return (versionId: int): Promise<VersionCodeItem[]> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<VersionCodeItem[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/version/changes',
                params: {
                    versionId
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export const _UpdateChanges = (getSource: SourceGetter) => {
    return async (id: int, description: string): Promise<VersionCodeItem> => {
        return axiosData({
            method: 'put',
            url: `/api/version/changes/` + id.toString() + '/' + description,
            cancelToken: getSource().token,
        });

        
    } ; 
};