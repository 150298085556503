import { ExtendButtonBase, ListItem, ListItemProps, ListItemTypeMap } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import React from 'react';
import { useLongPress } from 'use-long-press';


//custom list item
const LongPressListItem = (props: React.PropsWithChildren<{ onLongPress: () => void | undefined } & DefaultComponentProps<ListItemTypeMap<{ button?: true }, 'div'>>>) => {
    const { onLongPress, button, ...otherProps } = props;

    //binding for long press
    const bind = useLongPress((e) => {
        //prevent default
        e?.preventDefault();

        if (onLongPress) {
            //call the long press callback
            onLongPress();
        };
    }, {
        threshold: 600,
    });

    return <ListItem {...bind} button={true} {...otherProps}/>;
};

export { LongPressListItem };