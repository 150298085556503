import { HubConnectionBuilder } from '@microsoft/signalr';
import { createContext } from 'react';
import { HubRegistration } from './types';

export interface THubContext {
    register: (endpoint: string) => HubRegistration<any>;
    unregister: (registration: HubRegistration<any>) => void;
};

export const HubContext = createContext<THubContext>({
    register: () => new HubRegistration(new HubConnectionBuilder().build()),
    unregister: () => {},
});