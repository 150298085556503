import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useCallback } from "react";
import { useDialog } from "./useDialog";

export interface ShowInfoDialogProps {
	title?: React.ReactNode | string; 
	body?: React.ReactNode;
	container?: Element | null; 
};

export type ShowInfoDialogFn = (props: ShowInfoDialogProps) => Promise<void>;

export function useInfoDialog(): [ShowInfoDialogFn] {
	//useDialog hook
	const [openDialog] = useDialog();

	//showInfo function, displays a info dialog, returns a promise which resolves when the user closes the dialog
	const showInfo = useCallback<ShowInfoDialogFn>((props) => {
		const { title, body, container } = props;

		return openDialog((close) => (
			<Dialog 
				open
				onClose={() => close()}
				container={container}
				BackdropProps={container ? { style: { position: 'absolute' } } : undefined}
				style={container ? { position: 'absolute' } : undefined}
			>
				{/* Title */}
				{title ? <DialogTitle>{title}</DialogTitle> : null}

				{/* Body */}
				{body ? <DialogContent>{body}</DialogContent> : null}

				{/* Title */}
				<DialogActions>
					{/* Close Dialog Button */}
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => close()}
					>{"Ok"}</Button>
				</DialogActions>
			</Dialog>
		));
	}, [openDialog]);
	
	return [showInfo];
};