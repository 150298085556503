
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LightPoleMeasurements from './LightPoleMeasurements/LightPoleMeasurements';

const User = React.memo(() => {
    return (
        <Routes>
            <Route path={":uniqueId"}>
                <Route path={"measurements"}>
                    <Route path={""} element={<LightPoleMeasurements key={"light-pole-measurements"}/>}/>
                    <Route path={":editorState"} element={<LightPoleMeasurements key={"light-pole-measurements"}/>}/>
                </Route>
            </Route>
            
            {/* Invalid route redirection to /dashboard */}
            <Route path={"*"} element={<Navigate to="/dashboard"/>}/>
        </Routes>
    );
});

export default User;