import { makeStyles } from '@imas/styles';


const dynamicToolbarStyles = makeStyles()((theme) => ({
    dropdownContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    dropdownButton: {
        
        // "& .MuiButton-label": {
        //     color: theme.palette.text.navigation,
        // }
    },
    dropdownOptionButton: {
        justifyContent: "left",
        borderRadius: 0,
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2),
    },
}));

export default dynamicToolbarStyles;