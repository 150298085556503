
/**
 * Round a number to the specified precision. 
 * 
 * Ex.
 *  value = 4.2125
 *  precision = 0.25
 * 
 * The value would be rounded to 4.25.
 * 
 * @param value Value to round.
 * @param precision Level of precision to round to.
 * @returns Rounded number
 */
export const roundFloat = (value: number, precision: float): float => {
    const inversePrecision = 1 / precision;

    return Math.round(value * inversePrecision) / inversePrecision;
};