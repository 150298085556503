import { AdobeDC } from './AdobeDC';

//get client ID from domain
const getClientId = () => {
	switch (window.location.hostname) {
		case "pms.pnltest.net":
			return "fb5cabe85e2b4b1f891d2d9d1b086973";
		case "imas.pnltest.net":
			return "2368de60b7694a6ab76fc6443b324920";
		case "imasdev.pnltest.net":
			return "4cf5c8f9814d43acb39be28a9fafb00a";
		case "localhost":
		default:
			return "3bad1079c6ba4faa9203ee84467523cb";
	}
};

//import embed SDK script
const script = document.createElement('script');
script.src = 'https://documentcloud.adobe.com/view-sdk/main.js';
document.head.append(script);

export namespace PDFEmbedSDK {
	export class Client {
		private sdkReady: Promise<void>;
		private adobe: AdobeDC.WindowAdobeDC | null = null;
	
		constructor() {
			this.sdkReady = new Promise<void>((resolve) => {
				if (window.AdobeDC) {
					this.populate();
					resolve();
				} else {
					//Wait for Adobe Document Services PDF Embed API to be ready
					document.addEventListener("adobe_dc_view_sdk.ready", () => {
						this.populate();
						resolve();
					});
				}
			});
		}
	
		private populate() {
			//populate adobe interface
			if (window.AdobeDC) {
				this.adobe = window.AdobeDC;
			}
		}
	
		public ready(): Promise<Client> {
			return new Promise<Client>(resolve => {
				this.sdkReady.then(() => {
					resolve(this);
				});
			});
		}

		public getViewer(divId?: string): Viewer {
			if (this.adobe === null) throw new Error("AdobeDC SDK not yet loaded.");
			const view = new this.adobe.View({ clientId: getClientId(), divId });
			return new Viewer(this, view);
		}
	}

	export type OpenViewerSource = string | File | Blob | Promise<File | Blob | ArrayBuffer>;

	export interface OpenViewerOptions extends Omit<AdobeDC.ViewerPreviewFileOptions, "embedMode"> {
		fileName: string;
		headers?: { key: string; value: string; }[];
		mode?: AdobeDC.EmbedModes; 
	}

	export class Viewer {
		public client: Client;
		public dcView: AdobeDC.View;

		constructor(client: Client, dcView: AdobeDC.View) {
			this.client = client;
			this.dcView = dcView;
		}

		open(source: OpenViewerSource, { fileName, headers, mode, ...options }: OpenViewerOptions) {
			if (typeof source === "string") {
				//preview file with URL
				this.dcView.previewFile({
					content: { location: { url: source, headers } },
					metaData: { fileName },
				}, { 
					embedMode: mode,
					...options,
				});
			} else {
				//convert source to ArrayBuffer
				const promise = new Promise<ArrayBuffer>((resolve) => {
					(async () => {
						const object = source instanceof Promise ? await source : source;

						if (object instanceof File || object instanceof Blob) {
							//read file into arraybuffer
							const reader = new FileReader();
							reader.onloadend = (result) => {
								resolve(result.target?.result as ArrayBuffer);
							};
							reader.readAsArrayBuffer(object);
						} else {
							//resolve directly when arraybuffer
							resolve(object);
						}
					})();;
				});

				//preview file with File/ArrayBuffer
				this.dcView.previewFile({
					content: { promise },
					metaData: { fileName },
				}, { 
					embedMode: mode,
					...options,
				});
			}
		}
	}
};