import { mapApi, ApiConfig } from "@imas/api";
import { IMASSecrets } from './types';

//secrets base url
const SECRERTS_URL = "/api/system/secrets";

/*  */
export const GetSecrets = mapApi((): ApiConfig<IMASSecrets> => ({
	method: 'get',
	url: `${SECRERTS_URL}`,
}));