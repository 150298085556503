import { Alignment, Spacer } from '@imas/components/layout';
import { useMuiFormContext, useMuiWatch } from '@imas/mui-form';
import { Typography, RadioGroup, FormControlLabel, Checkbox, Radio } from '@mui/material';
import { memo, useContext, useEffect } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../../VehicleInspectionStyles';
import { VehicleInspectionFormContext } from '../../VehicleInspectionFormContext';


export interface VehicleInspectionRowProps {
	label: string,
	variable: keyof TVehicleInspectionForm,
}


export const VehicleInspectionRow = memo(({label, variable} : VehicleInspectionRowProps) => {
	
	//styling
	const { classes } = VehicleInspectionStyles();

	const { control } = useMuiFormContext<TVehicleInspectionForm>();
	const [variableValue] = useMuiWatch({ control, name: [variable] });

	if (typeof variableValue !== 'number') return null;

    const { getRadioValue, setRadioValue, radioDisabled, setCheckboxValue, hideCompletion, disableCompletion, hideIgnore, disableIgnore } = useContext(VehicleInspectionFormContext);

	/* useEffect(() => {
		calculateProblemsPresent();
	}, [variableValue]); */

	return (
		<Alignment row>
			<Typography variant={"body1"} align={'right'} className={classes.leftTypography}>{label}</Typography>

			{/* radio group */}
			<RadioGroup
				aria-labelledby={variable + 'radio group'}
				value={getRadioValue(variableValue)}
				sx={{flexDirection: 'row'}}
				onChange={(e) => {							
					//update variable based on the new value
					switch (e.target.value) {
						case 'not inspected':
							setRadioValue(variable, 'not inspected');
							break;
						case 'good':
							setRadioValue(variable, 'good');
							break;
						case 'bad':
							setRadioValue(variable, 'bad');
							break;
					};					
				}}
			>
				<Alignment row>
					<FormControlLabel sx={{width: '38px'}} disabled={radioDisabled} className={classes.label} value={'not inspected'} control={<Radio size={"small"} className={classes.radioButton}/>} label={""}/>
					<FormControlLabel sx={{width: '38px'}} disabled={radioDisabled} className={classes.label} value={'good'} control={<Radio size={"small"} className={classes.radioButton}/>} label={""}/>

					{/* sx to make the button red, even when disabled if selected */}
					<FormControlLabel sx={{width: '38px'}} disabled={radioDisabled} className={classes.label} value={'bad'} control={<Radio size={"small"} className={classes.radioButton} 
					sx={{
 					   '&, &.Mui-disabled': {
 					     color: variableValue === 8 ? 'red' : 'grey',
 					   },
 					}} color={variableValue === 8 ? 'error' : 'primary'} 
					/>} label={''}/>
				</Alignment>

			</RadioGroup>

			{/* Completion Checkbox */}
			{hideCompletion ? null :
				<>
					{/* <Spacer horizontal size={'10px'}/> */}
					<Alignment row /* sx={{width: '79px'}} */>
						<Checkbox 
							size={'small'} 
							className={classes.radioButton}
							checked={variableValue ? variableValue%2 !== 0 : false} 
							onChange={(event) => setCheckboxValue("fixed", variable, event.target.checked)} 
							disabled={disableCompletion}
							sx={{width: '40px'}}
						/>
					</Alignment>
				</>
			}

			{/* Ignore Checkbox */}
			{hideIgnore ? null :
				<>
					<Spacer horizontal size={'15px'}/>
					<Alignment row sx={{width: '43px'}}>
						<Checkbox 
							size={'small'} 
							className={classes.radioButton}
							checked={variableValue ? variableValue > 15 : false} 
							onChange={(event) => setCheckboxValue("ignore", variable, event.target.checked)} 
							disabled={disableIgnore}
						/>
					</Alignment>
					<Spacer horizontal size={'5px'}/>
				</>
			}

		</Alignment>
	);
});
