import { useState, useCallback } from 'react';

//a hook which provides a ref object and a callback function which is used to update the ref, will triger an update each time the reference changes
export const useCallbackRef = <T>(defaultValue?: T | null): [ref: T | null, onRef: (node: T | null) => void] => {
    const [ref, setRef] = useState<T | null>(defaultValue ?? null);

    const onRef = useCallback((node: T | null) => {
        if (!node) return;
        setRef(node);
    }, []);

    return [ref, onRef];
};