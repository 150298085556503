import { makeDeviceStyles } from '@imas/styles';

const lightPoleMeasurementsStyles = makeDeviceStyles()((theme) => ({
    itemList: {
        overflow: 'scroll',
        marginBottom: '10px',
    },
    jobNoText: {
        margin: '5px 10px',
    },
    fab: {
        margin: '5px 10px 10px 10px',
    },
}));

export default lightPoleMeasurementsStyles;