import { Alignment } from '@imas/components/layout';
import { memo, useMemo } from 'react';
import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs, DATE_COL_V6 } from '@imas/data-grid';
import { useLoadApi } from '@imas/api';
import { ClientPurchaseOrderRevision, GetClientPurchaseOrderRevisions } from '@imas/api/client';
import { Typography } from '@mui/material';
import { useClientPurchaseOrderRevisions } from '@imas/api/data-hooks';
import { OpenInNew } from '@mui/icons-material';
import { openClientPurchaseOrderRevisionReportViewer } from '../../PurchaseOrderRevisions/ClientPurchaseOrderRevisionReportViewer';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

export interface PurchaseOrderRevisionsGridProps {
    purchaseOrderId: int | undefined,
};

//define the column order
export type PURCHASE_ORDER_REVISIONS_GRID_COLUMNS = ["actions", "revision", "revisedDate", "revisedReason"];

//define the grid api ref type
export type PURCHASE_ORDER_REVISIONS_GRID_API_REF = TypedGridApiRef<ClientPurchaseOrderRevision, ClientPurchaseOrderRevision, never, "row">;

//define grid props type
export type PURCHASE_ORDER_REVISIONS_GRID_PROPS = TypedEditDataGridProps<ClientPurchaseOrderRevision, ClientPurchaseOrderRevision, never, PURCHASE_ORDER_REVISIONS_GRID_COLUMNS, "row">

export const PurchaseOrderRevisionsGrid = memo(({ purchaseOrderId } : PurchaseOrderRevisionsGridProps) => {

    //load records
    const [unsortedRevisions] = useClientPurchaseOrderRevisions(purchaseOrderId ?? -1, {disabled: !purchaseOrderId});

	const revisions = useMemo(() => {
		if (!unsortedRevisions) return undefined;
		else return unsortedRevisions.sort((a,b) => b.revision - a.revision);
	}, [unsortedRevisions]);

	//grid apiRef
	const apiRef: PURCHASE_ORDER_REVISIONS_GRID_API_REF = useTGridApiRef();

	//memoized grid column defs
	const columns = useMemo((): TypedGridColumnDefs<ClientPurchaseOrderRevision, ClientPurchaseOrderRevision, never, PURCHASE_ORDER_REVISIONS_GRID_COLUMNS, "row"> => {
		return [
			{ field: 'actions', type: 'actions', width: 50,
                getActions: ({ id }) => ([
                    <GridActionsCellItem icon={<OpenInNew/>} onClick={() => openClientPurchaseOrderRevisionReportViewer(id)} label={"Preview Report"} color={"inherit"}/>
                ]),
			},
			{ field: 'revision', headerName: 'Revision', width: 80, valueFormatter: (param) => param.value === 0 ? "Original" : `Rev. ${param.value}`},
			{ field: 'revisedDate', headerName: 'Rev. Date', width: 100, ...DATE_COL_V6},
			{ field: 'revisedReason', headerName: 'Rev. Reason', flex: 1},
		];
	}, []);

    if (!purchaseOrderId) return (
		<Alignment column sx={{height: '100%', width: '-webkit-fill-available', marginLeft: '50px'}}>
            <Typography color={'textPrimary'} variant={'body1'}>{'The revisions grid is not available until the purchase order has been created.'}</Typography>
        </Alignment>
    );

	return (

		<Alignment column sx={{height: '100%', width: '-webkit-fill-available', marginLeft: '50px'}}>
			<TDataGrid<ClientPurchaseOrderRevision, ClientPurchaseOrderRevision, never, PURCHASE_ORDER_REVISIONS_GRID_COLUMNS, "row">
				apiRef={apiRef}
				editMode={"row"}
				disableColumnMenu
				loading={!revisions}
				rows={revisions ?? []}
				columns={columns}
				//sx={{height: '100%', marginBottom: '10px'}}
			/>
		</Alignment>
	);
});