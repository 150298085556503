//get device browser
export const getDeviceBrowser = () => {
    const userAgent = navigator?.userAgent ?? "Unknown";
    
    if (userAgent.indexOf("Firefox") > -1) {
        return "Mozilla Firefox";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
        return "Samsung Internet";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        return "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        return "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
        return "Microsoft Edge (Legacy)";
    } else if (userAgent.indexOf("Edg") > -1) {
        return "Microsoft Edge (Chromium)";
    } else if (userAgent.indexOf("Chrome") > -1) {
        return "Google Chrome/Chromium";
    } else if (userAgent.indexOf("Safari") > -1) {
        return "Apple Safari";
    } else {
        return "Unknown Browser";
    };
};

//get device platform
export const getDevicePlatform = () => {
    return navigator?.platform ?? "Unknown Platform";
};

//get device  as a string
const getDeviceStringFromUA = () => {
    return getDevicePlatform() + " - " + getDeviceBrowser();
};

//get browser from brands list
export const getSignificantBrandVersion = (brands: UABrandVersion[]): UABrandVersion | null => {
    if (brands.find(x => x.brand === "Google Chrome") !== undefined) {
        return brands.find(x => x.brand === "Google Chrome") as UABrandVersion;
    } else if (brands.find(x => x.brand === "Microsoft Edge") !== undefined) {
        return brands.find(x => x.brand === "Microsoft Edge") as UABrandVersion;
    }

    return null;
};

//get device data as an string
export const getDeviceString = () => {
    return new Promise<string>((resolve) => {
        (async () => {
            //check if userAgentData exists in navigator, if it does not then fallback to userAgent string
            if (navigator?.userAgentData === undefined) return resolve(getDeviceStringFromUA());

            //get user agent info
            const values = await navigator.userAgentData.getHighEntropyValues(["model", "platform", "platformVersion"]);

            //get browser significant brand version
            const brandVer = getSignificantBrandVersion(navigator.userAgentData.brands);

            //create brand string
            const brandStr = brandVer ? brandVer.brand + " " + brandVer.version : "Unknown Browser";

            //get platform + platform version
            const platformStr = (values?.platform ?? "Unknown") + " " + (values?.platformVersion ?? "unk");

            //if device model was provided
            if (values?.model !== undefined && values?.model !== "") {
                return resolve(`${values.model} (${platformStr}) [${brandStr}]`);
            }
            //if model was not provided
            else {
                return resolve(`${platformStr} [${brandStr}]`);
            }
        })().catch((e) => {
            //fallback to userAgent string
            resolve(getDeviceStringFromUA());
        });
    });
};