import { ApiConfig, axiosData, IdName, mapApi, SourceGetter, objectToFormData } from '@imas/api';
import { ProposalSummaryResult, Proposal, ProposalPreLien, vProposalPurchaseOrder, vProposalReport, vProposalAdditionalScope, MinifiedProposal } from './types';
import moment, { Moment } from 'moment';
import { vQualityControl } from '../quality-control';
import { vServiceOrderWorkPlaceReview } from '../service-order';
import { ServiceOrderEmailTemplate } from './types/reporting-details/ServiceOrderEmailTemplate';
import { Client } from '../client';
import { ProposalForm as TProposalForm} from '@imas/api/proposal';

//proposals base url
const PROPOSALS_URL = "/api/proposals";

/*  */
export const GetProposals = mapApi((): ApiConfig<Proposal[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}`,
}));

/*  */
export const GetMinifiedProposals = mapApi((): ApiConfig<MinifiedProposal[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/minified`,
}));

/*  */ 
export const GetProposalsSummary = mapApi((startDate?: Moment, endDate?: Moment): ApiConfig<ProposalSummaryResult[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/summary`,
	params: {
		fromDate: (startDate ?? moment().subtract(3, "years")).format(),
		toDate: (endDate ?? moment()).format(),
	},
}));

/*  */
export const GetProposalServiceLocations = mapApi((): ApiConfig<string[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/service-locations`,
}));

/*  */
export const GetProposalCodes = mapApi((): ApiConfig<string[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/codes`,
}));

/*  */
export const GetProposalSpecifications = mapApi((): ApiConfig<string[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/specifications`,
}));

/*  */
export const GetProposalAcceptanceCriteria = mapApi((): ApiConfig<string[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/acceptance-criteria`,
}));

/*  */
export const GetProposal = mapApi((proposalId: int): ApiConfig<Proposal> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId}`,
}));

/*  */
export const GetProposalClient = mapApi((proposalId: int): ApiConfig<Client> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId}/client`,
}));

/*  */
export const GetProposalFullJobName = mapApi((proposalId: int): ApiConfig<string> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId}/full-job-name`,
}));

/*  */
export const GetProposalWorkClassCode = mapApi((proposalId: int): ApiConfig<int | null> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId}/work-class-code`,
}));

/*  */
export const GetProposalServiceOrderIdNames = mapApi((proposalId: int | null): ApiConfig<IdName[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId ?? -1}/service-orders/id-names`,
}));

/*  */
export const GetProposalVAdditionalScopes = mapApi((proposalId?: int | null): ApiConfig<vProposalAdditionalScope[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId ?? -1}/v-additional-scopes`,
}));

/*  */
export const GetProposalPreLiens = mapApi((proposalId?: int | null): ApiConfig<ProposalPreLien[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId ?? -1}/pre-liens`,
}));

/*  */
export const GetProposalVPurchaseOrders = mapApi((proposalId?: int | null): ApiConfig<vProposalPurchaseOrder[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId ?? -1}/v-purchase-orders`,
}));

/*  */
export const GetProposalVServiceOrderWorkPlaceReviews = mapApi((proposalId?: int | null): ApiConfig<vServiceOrderWorkPlaceReview[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId ?? -1}/v-service-order-work-place-reviews`,
}));

/*  */
// export const GetProposalPreLiens = (getSource: SourceGetter) => {
//     return async (proposalId: int): Promise<vQualityControl[]> => {
//         return axiosData({
// 			method: 'get',
//             url: `${PROPOSALS_URL}/${proposalId}/pre-liens`,
//             cancelToken: getSource().token,
// 		});
//     };
// };

/*  */
export const GetProposalVQualiyControls = mapApi((proposalId: int): ApiConfig<vQualityControl[]> => ({
	method: 'get',
	url: `${PROPOSALS_URL}/${proposalId}/v-quality-controls`,
}));

/*  */
export const CreateProposal = mapApi((data: { siteId: int; departmentId: short; clientId: int | null; contactId: int | null; proname: string | null }): ApiConfig<Proposal> => ({
	method: 'post',
	url: `${PROPOSALS_URL}`,
	data,
}));

export const UpdateProposal = mapApi((pronum: int, data: TProposalForm): ApiConfig<Proposal> => ({
	method: 'put',
	url: `${PROPOSALS_URL}/${pronum.toString()}`,
	data
}));

export const GetEmailTemplates = mapApi((): ApiConfig<ServiceOrderEmailTemplate[]> => ({
	method: 'get',
	url: 'api/proposals/reporting-details/email-templates',
}));

export const ApproveProposal = mapApi((pronum: int): ApiConfig<boolean> => ({
	method: 'post',
	url: 'api/proposals/fee-details/approve/' + pronum.toString(),
}));