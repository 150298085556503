import { Theme, Button, IconButton, Link, LinkProps, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useLoadApi } from '@imas/api';
import { GetFileInfo, FileInfoResult, FileTables } from '@imas/api/files';
import { useNative } from '@imas/native';
import { getIsLocal } from '@imas/redux';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { openLocalExplorer } from '..';
import { XSkeleton } from '@imas/components/layout';

interface BaseFileSystemLinkProps {
    //component logic props
    destination: HierarchyId;
    table: FileTables;
    disabled?: boolean;
    onOpen?: () => void;
	renderer?: (info?: FileInfoResult) => React.ReactNode; 

    //props shared between variants
    sx?: SxProps<Theme>;
    style?: React.CSSProperties;
    className?: string;
    children?: React.ReactNode;
};

interface LinkVariantProps extends BaseFileSystemLinkProps, Pick<LinkProps, "variant" | "underline" | "color"> {
    type: "link";
};

interface ButtonVariantProps extends BaseFileSystemLinkProps {
    type: "button";
};

interface IconButtonVariantProps extends BaseFileSystemLinkProps {
    type: "iconButton";
};

export type FileSystemLinkProps = LinkVariantProps | ButtonVariantProps | IconButtonVariantProps;

const FileSystemLink = (props: FileSystemLinkProps) => {
    //extract base props needed for logic
    const { destination, table, disabled, onOpen, children, renderer: providedRenderer, ...renderProps } = props;
	const renderer = providedRenderer ?? ((info?: FileInfoResult) => info ? `${info.name}.${info.extension}` : <XSkeleton variant={"text"} width={150}/>);

    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //filePath state
    const { data: destinationInfo } = useLoadApi(GetFileInfo, [table, destination], [table, destination], { disabled: disabled });

    //state which is set to true if the destination should be opened, useful for allowing the user to interact with button before path info is loaded
    const [openDestination, setOpenDestination] = useState(false);

    //check if native interop is available
    const { isNative, native } = useNative();

    //check if the user is on the local network
    const isLocal = useSelector(getIsLocal);

    //hook which handles when destinationInfo or openDestination change
    useEffect(() => {
        //check if openDestination is true, if it is then check destinationInfo
        if (openDestination) {
            if (destinationInfo) {
                //try to open file with native interop if it is available
                if (isNative) {
                    native.openLocal(destinationInfo.fullPath)
                    .then(errMsg => {
                        if (errMsg) showSnackbar(errMsg, { variant: "error" });
                    });
                } else {
                    //fallback to opening with localexplorer
                    openLocalExplorer(destinationInfo);
                }
            }
            
            //call onOpen callback if it was set
            if (onOpen !== undefined) onOpen();

            //set openDestination since the open action was completed
            setOpenDestination(false);
        }
    }, [openDestination, destinationInfo]);

    // render component based on type 
    if (renderProps.type === "link") {
        if (!isLocal || disabled) return <Typography {...renderProps}>{children ?? renderer(destinationInfo)}</Typography>;
        return <Link {...renderProps} href={"#"} variant={renderProps.variant ?? "body1"} onClick={(e) => { e.preventDefault(); setOpenDestination(true); }}>{children ?? renderer(destinationInfo)}</Link>;
    } else if (renderProps.type === "button") {
        return <Button {...renderProps} disabled={!isLocal || disabled}>{children ?? renderer(destinationInfo)}</Button>;
    } else if (renderProps.type === "iconButton") {
        const { type, ...iconButtonRenderProps } = renderProps;
        return <IconButton {...iconButtonRenderProps} disabled={!isLocal || disabled}>{children ?? renderer(destinationInfo)}</IconButton>;
    }

    return null;
};

export { FileSystemLink };