import { Skeleton, Box, BoxProps } from '@mui/material';
import { useFilePreviewURL } from '@imas/utils/files'; 
import { makeStyles } from '@imas/styles';
import { FileTables } from '@imas/api/files/types';
import clsx from 'clsx';
import { useLongPress, LongPressEvent } from 'use-long-press';

const useStyles = makeStyles()(theme => ({
    previewImage: {
        objectFit: 'scale-down',
    },
	previewLoader: {
		minHeight: '250px',
	},
}));

export interface PreviewImageProps extends Omit<BoxProps<"img">, "src"> {
    file: File | HierarchyId;
	table: FileTables;
    onLongPress?: (e?: LongPressEvent<Element>) => void;
};

const PreviewImage = ({ file, table, onLongPress, className, sx, ...boxProps }: PreviewImageProps) => {
    //component styling
    const { classes } = useStyles();

    //get the preview URL for this file
    const previewURL = useFilePreviewURL(file, { table: table });

    //binding for long press
    const bind = useLongPress((e) => {
        //prevent default
        e?.preventDefault();

        if (onLongPress) {
            //call the long press callback
            onLongPress(e);
        };
    }, {
        threshold: 600,
    });

    //render loading stand in
    if (!previewURL) return <Skeleton className={clsx(className, classes.previewLoader)} sx={sx}/>;

    //render preview image
    return (
        <Box 
			component={"img"} 
			src={previewURL}
            {...bind}
			{...boxProps}
			className={clsx(className, classes.previewImage)}
			sx={sx}
        />
    );
};

export { PreviewImage };