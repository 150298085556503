import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext, useMuiWatch, FormCheckbox } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo } from 'react';

export const WorkPlaceReviewTrenches = memo(() => {
    const { control, isLoading, setValue } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//get the value of trenchNone, trenchDeepTrench, and trenchShallowTrench
	const [trenchNone, trenchDeepTrench, trenchShallowTrench] = useMuiWatch({ control, name: ["trenchNone", "trenchDeepTrench", "trenchShallowTrench"] });
	
	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"TRENCHES: "}</b>
						{"Work will be conducted in a trench."}
					</Typography>
				}
				control={<Checkbox disabled={isLoading || !trenchNone} checked={!trenchNone} onChange={() => setValue("trenchNone", false)}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={!trenchNone} sx={{ marginLeft: '45px' }}>
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"trenchNone"}
						label={"None"}
						disabled={trenchDeepTrench || trenchShallowTrench}
					/>

					<FormCheckbox
						control={control}
						name={"trenchDeepTrench"}
						label={"Five (5) feed deep or more - Protective shoring is in place and secure."}
						disabled={trenchNone || trenchShallowTrench}
					/>

					<FormCheckbox
						control={control}
						name={"trenchShallowTrench"}
						label={"Less than five (5) feed deep - Stairs, ladder, ramp, or other safe access has been provided."}
						disabled={trenchNone || trenchDeepTrench}
					/>
				</Alignment>
			</Collapse>
			
			{/* Protective Equipment */}
			<Alignment column sx={{ marginLeft: '45px' }}>
				<Typography variant={"body1"} sx={{ margin: '5px', fontWeight: 'bold' }}>{"Protective Equipment Required:"}</Typography>
				
				<FormCheckbox
					control={control}
					name={"reqFullBodyHarness"}
					label={"Full Body Harness"}
				/>
			</Alignment>
		</Alignment>
	);
});