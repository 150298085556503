import { local } from '@imas/utils/storage';
import { handleCheckInReminder } from './web-push-handlers/check-in-reminder-handler';
import { swShowNotification, swOpenPath } from './utils';
import { getApi } from '@imas/api';
import { CreateUserNotificationSubscription } from '@imas/api/user';
import { snackbar } from '@imas/utils/snackbar';
import { PeriodicSyncTasks, NotificationType, WebPushMessageType, ServiceWorkerMessage, ServiceWorkerMessageTypes } from './types';
import { handleNewVersion } from './web-push-handlers/new-version-handler';
import { handleAnnouncement } from './web-push-handlers/announcement-handler';
import { handleGeneralNotification } from './web-push-handlers/general-notification-handler';

//register push event listener
const registerPushListener = (sw: ServiceWorkerGlobalScope) => {
    if (!('pushManager' in sw.registration)) return;

    //listen for the 'push' event
    sw.addEventListener("push", (event) => {
        //log web push
        console.log("Received Web Push:");
        console.log(event);
        console.log(event.data?.text());

        if (event.data === null) return;

        //try and convert data to json
        const data = event.data.json();

        //check if the data has the correct properties, "type" and "payload"
        if ("type" in data && "payload" in data) {
            //based on the type of message, call the correct handler
            if (data.type === WebPushMessageType.TestNotification) event.waitUntil(swShowNotification(sw, "This is a test notification.", NotificationType.TestNotification, { renotify: true }, data.messageId, data.dev));
            else if (data.type === WebPushMessageType.NewVersion) event.waitUntil(handleNewVersion(sw, data));
            else if (data.type === WebPushMessageType.Announcement) event.waitUntil(handleAnnouncement(sw, data));
            else if (data.type === WebPushMessageType.CheckInReminder) event.waitUntil(handleCheckInReminder(sw, data));
            else if (data.type === WebPushMessageType.Schedule) event.waitUntil(handleGeneralNotification(sw, data));
            else if (data.type === WebPushMessageType.OnCall) event.waitUntil(handleGeneralNotification(sw, data));
            else if (data.type === WebPushMessageType.Job) event.waitUntil(handleGeneralNotification(sw, data));
            else if (data.type === WebPushMessageType.Timesheet) event.waitUntil(handleGeneralNotification(sw, data));
            else if (data.type === WebPushMessageType.Custom) event.waitUntil(handleGeneralNotification(sw, data));
        } else {
            console.error("Invalid push message:" + event.data.text());
        }
    });
};

//register periodic sync event listener
const registerPeriodicSyncListener = (sw: ServiceWorkerGlobalScope) => {
    if (!('periodicSync' in sw.registration)) return;

    //listen for the 'periodicsync' event
    sw.addEventListener("periodicsync", (event) => {
        //run the correct task based on the event's tag
        // if (event.tag === PeriodicSyncTasks.CheckCheckedIn) {
        //     event.waitUntil(checkCheckedInTask(sw));
        // }
    });
};

//register notificationclick event listener
const registerNotificationClickListener = (sw: ServiceWorkerGlobalScope) => {
    if (!('showNotification' in sw.registration)) return;

    //Possibily log if the notification was clicked on in the future.

    //listen for the 'notificationclick' event
    sw.addEventListener("notificationclick", (event) => {
        if (event.notification.tag === NotificationType.NewVersion) {
            //open the app to show the user the new version
            event.waitUntil((async () => {
                //open app at home page
                await swOpenPath(sw, "/");

                //close notification
                event.notification.close();
            })());
        } else if (event.notification.tag === NotificationType.CheckInReminder) {
            //navigate to home page where the schedule is located
            event.waitUntil((async () => {
                //open app at home page
                await swOpenPath(sw, "/");

                //close notification
                event.notification.close();
            })());
        } else if (event.notification.tag === NotificationType.Timesheet) {
            //navigate to home page where the schedule is located
            event.waitUntil((async () => {
                //open app at home page
                await swOpenPath(sw, "/user/time-sheets");

                //close notification
                event.notification.close();
            })());
        }
        
        
        //no custom event for this notification type
        else {
            //navigate to home page
            event.waitUntil((async () => {
                //open app at home page
                await swOpenPath(sw, "/");

                //close notification
                event.notification.close();
            })());
        }
    });
};

//register an install event listener
const registerInstallListener = (sw: ServiceWorkerGlobalScope) => {
    //listen for install event
    sw.addEventListener('install', (event) => {
        //wait for skip waiting
        event.waitUntil(sw.skipWaiting());
    });
};

//register activate event listener
const registerActivateListener = (sw: ServiceWorkerGlobalScope) => {
    //listen for activate event
    sw.addEventListener('activate', (event) => {
        console.log("New service worker activated.");

        //wait until complete
        event.waitUntil((async () => {
            //claim any leftover clients
            await sw.clients.claim();
        })());
    });
};

//register a service worker message listener
const registerMessageListener = (sw: ServiceWorkerGlobalScope) => {
    //listen for messages from the clients
    sw.addEventListener("message", (event) => {
        //get the message
        const message = event.data as ServiceWorkerMessageTypes;

        if (message.type === "install-update") {
            console.log("Install Update Command Received.");

            //wait for this to complete
            event.waitUntil((async () => {
                console.log("Calling skipWaiting.");
                //call skip waiting
                await sw.skipWaiting();

                console.log("Clearing Caches");
                //clear caches
                for (let cacheName of await caches.keys()) await caches.delete(cacheName);

                //send reload page message to all clients controlled by this service worker
                const clients = await sw.clients.matchAll({ type: "window" });
                console.log("Notifying Clients: ");
                console.log("Clients");


                for (let client of clients) {
                    client.postMessage({ 
                        type: "reload-page",
                        data: null,
                    } as ServiceWorkerMessageTypes);
                }
            })());
        }
    });
};

//register service worker event listeners
export const registerServiceWorkerEventListeners = (sw: ServiceWorkerGlobalScope) => {
    console.log("Registering Service Worker Event Listeners for Service Worker:");
    console.log(sw);

    try { registerPushListener(sw); } catch(e) { console.log("Problem registering Push Listener: " + e); }
    try { registerPeriodicSyncListener(sw); } catch(e) { console.log("Problem registering Periodic Listener: " + e); }
    try { registerNotificationClickListener(sw); } catch(e) { console.log("Problem registering Notification Click Listener: " + e); }
    try { registerInstallListener(sw); } catch(e) { console.log("Problem registering Install Listener: " + e); }
    try { registerActivateListener(sw); } catch(e) { console.log("Problem registering Activate Listener: " + e); }
    try { registerMessageListener(sw); } catch(e) { console.log("Problem registering Message Listener: " + e); }
};

//handles messages from the service worker
const handleServiceWorkerMessage = () => {
    navigator.serviceWorker.addEventListener("message", event => {
        console.log("Got message: ");
        console.log(event.data);

        //get the message
        const message = event.data as ServiceWorkerMessageTypes;

        //if the message is a reload command
        if (message.type === "reload-page") {
            //reload the page
            window.location.reload();
        }
    });
};

//register service worker
export const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        //register service worker
        navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
            console.log("SW Registration Successful: ");
            console.log(registration);

            //handle service worker messages
            handleServiceWorkerMessage();
        }).catch(error => {
            console.log("SW Registration Failed: ");
            console.log(error);
        });
    }
};

const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/')
    ;
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
};

interface Callback { (): void }

//register a periodicsync task to be active
export const registerPeriodicSyncTask = (task: PeriodicSyncTasks, intervalInMinutes: number) => {
    if (!('serviceWorker' in navigator)) return;

    //enter async context
    (async () => {
        //get service worker registration
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) return;

        if ('periodicSync' in registration) {
            //get list of registered tags
            const tags = await registration.periodicSync.getTags();

            //do nothing if the tag is already registered
            if (task in tags) return;

            //if it is not registered then add it
            await registration.periodicSync.register(task, { minInterval: intervalInMinutes * 1000 });
        }
    })().catch(() => null);
};

//unregister a periodicsync task given the task
export const unregisterPeriodicSyncTask = (task: PeriodicSyncTasks) => {
    if (!('serviceWorker' in navigator)) return;

    //enter async context
    (async () => {
        //get service worker registration
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) return;

        if ('periodicSync' in registration) {
            //get list of registered tags
            const tags = await registration.periodicSync.getTags();

            //do nothing if the tag is not currently registered
            if (!(task in tags)) return;

            //if it is registered then unregister it
            await registration.periodicSync.unregister(task);
        }
    })().catch(() => null);
};

//register the user for push notifications
export const registerPushNotifications = (onGrant?: Callback, onSuccess?: Callback, onError?: Callback) => {
    //check if notification api is supported, if not tell the user it is not supported
    if (!("Notification" in window)) {
        //check if this notification has already been shown
        if (local.get("notificationsNotSupportedShown") === "yes") {
            return;
        } else {
            //mark that this notification has been shown
            local.set("notificationsNotSupportedShown", "yes");
            snackbar.warning("This device does not support notifications.");
            return;
        }
    }

    try{
    //ask the user for permission to show notifications
    Notification.requestPermission((status) => {
        try {
            if (status === "granted") {
                //execute granted callback
                if (onGrant) onGrant();

                (async () => {
                    if (!('serviceWorker' in navigator)) throw "Service worker not available.";

                    //get service worker registration
                    const registration = await navigator.serviceWorker.getRegistration();

                    //do nothing if undefined
                    if (!registration) return;

                    //check for an existing subscription
                    const existingSubscription = await registration.pushManager.getSubscription();

                    //do nothing if there is an existing sub
                    if (existingSubscription) return;

                    //subscription options
                    const pushOptions: PushSubscriptionOptionsInit = {
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array("BLCKIIHAhY6fWSTRwg2pu6s6jPEko7eDeIEYGWCbNP7qop3XYKAQGFTtGU2JswogjLD5NYktrBxwH7nIZrsMGUQ")
                    };

                    //register with the push manager
                    const subscription = await registration.pushManager.subscribe(pushOptions);
        
                    //get subscription as json
                    const subscriptionJson = subscription.toJSON();

                    //get create subscription api
                    const { api: createNotificationSubscription } = getApi(CreateUserNotificationSubscription);

                    //show error if keys are not provided
                    if (subscriptionJson.endpoint === undefined || subscriptionJson.keys?.auth === undefined || subscriptionJson.keys?.p256dh === undefined) {
                        throw "Invalid subscription object.";
                    }

                    //make request to store subscription
                    await createNotificationSubscription({ 
                        endpoint: subscriptionJson.endpoint, 
                        expirationDate: subscriptionJson.expirationTime ?? null,  
                        auth: subscriptionJson.keys.auth, 
                        p256dh: subscriptionJson.keys.p256dh 
                    });

                    //show success notification
                    snackbar.success("Successfully enabled notifications for this device.");

                    //call success callback
                    if (onSuccess) onSuccess();
                })();
            }
            else if (status === "default") {
                throw "default";
            } else if (status === "denied") {
                throw "denied";
            }
        } catch (e) {
            console.log("Error enabling notifications: ");
            console.log(e);

            if (e === "default") snackbar.warning("Unable to enable notifications.");
            else if (e === "denied") snackbar.error("You have denied notification permissions in site or browser settings.");
            else snackbar.warning("Unable to enable notifications.");

            //call error callback
            if (onError) onError();
        }
    });
    } catch(e) {
        console.log(e);
    }
};