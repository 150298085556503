import { Autocomplete, AutocompleteProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { makeStyles } from '@imas/styles';

//styling
const useStyles = makeStyles()(() => ({
	option: {
		display: 'block!important',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export interface DebouncedAutocompleteProps extends Omit<AutocompleteProps<string, false, false, true>, "value" | "onChange" | "onInputChange" | "defaultValue" | "inputValue"> {
    value?: string | null;
    defaultValue?: string;
    debounce?: int;
    onChange: (value: string | null) => void; 
};

//text field where the onChange callback is debounced
const DebouncedAutocomplete = React.memo((props: DebouncedAutocompleteProps) => {
	//styling
	const { classes } = useStyles();

    //get props
    const { debounce, onChange, value: providedValue, defaultValue, ...otherProps } = props;

    //value state
    const [value, setValue] = useState<string>(defaultValue ?? providedValue ?? "");

    //if providedValue update value
    useEffect(() => setValue(providedValue ?? ""), [providedValue]);

    //onChange callback debounced
    const onChangeDebounced = useDebouncedCallback(onChange, debounce ?? 250);

    return <Autocomplete<string, false, false, true>
		freeSolo
		inputValue={value}
		onChange={(_, value) => {
			setValue(value ?? "");
			onChange(value);
		}}
		onInputChange={(_, value) => {
			setValue(value);
			onChangeDebounced(value === "" ? null : value);
		}}
		classes={{
			option: classes.option,
		}}
		{...otherProps}
	/>;
});

export { DebouncedAutocomplete };