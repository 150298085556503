import { axiosData, SourceGetter } from '@imas/api';
import { ProposalProcedure } from "./types";


//proposal procedures base url
const PROPOSAL_PROCEDURES_URL = "/api/proposals/procedures";

/* Get proposal procedures */
export const GetProposalProcedures = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<ProposalProcedure[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PROCEDURES_URL}/${pronum.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Delete a proposal procedure */
export const DeleteProposalProcedure = (getSource: SourceGetter) => {
    return async (proprocid: int): Promise<boolean> => {
        return axiosData({
			method: 'delete',
            url: `${PROPOSAL_PROCEDURES_URL}/${proprocid.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create a proposal procedure */
export const CreateProposalProcedure = (getSource: SourceGetter) => {
    return async (pronum: int, name: string): Promise<ProposalProcedure> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_PROCEDURES_URL}`,
            data: {
                pronum,
                name,
            },
            cancelToken: getSource().token,
		});
    };
};

/* Create proposal procedures from procIds */
export const CreateProposalProceduresFromProcIds = (getSource: SourceGetter) => {
    return async (pronum: int, procIds: int[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_PROCEDURES_URL}/procedure-ids`,
            data: {
                pronum,
                procIds,
            },
            cancelToken: getSource().token,
		});
    };
};

/* Update a proposal procedure */
export const UpdateProposalProcedure = (getSource: SourceGetter) => {
    return async (proProcId: int, proProcName: string): Promise<ProposalProcedure> => {
        return axiosData({
			method: 'put',
            url: `${PROPOSAL_PROCEDURES_URL}`,
            data: {
                proProcId,
                proProcName,
            },
            cancelToken: getSource().token,
		});
    };
};