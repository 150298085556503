import { Alignment, Spacer } from '@imas/components/layout';
import {FormTextField, useMuiFormContext } from '@imas/mui-form';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../VehicleInspectionStyles';
import { VehicleInspectionRow } from './VehicleInspectionRow/VehicleInspectionRow';
import { VehicleInspectionHeader } from './VehicleInspectionRow/VehicleInspectionHeader';


export const VehicleInspectionFormExterior = memo(() => {

	const { classes } = VehicleInspectionStyles();

	const { control } = useMuiFormContext<TVehicleInspectionForm>();

	return(
		
		<Alignment column>

			<Spacer vertical/>

			<Alignment row>
				{/* EXTERIOR Title */}
				<Typography className={classes.title} align={'center'} fontWeight={"bold"} variant={"h5"}>{"Exterior"}</Typography>

			</Alignment>

			<VehicleInspectionHeader/>

			<VehicleInspectionRow label={'Driver-Side Mirror:'} variable={'driverMirror'} />
			<VehicleInspectionRow label={'Passenger-Side Mirror:'} variable={'passMirror'} />
			<VehicleInspectionRow label={'Windshield Wipers:'} variable={'wiper'} />
			<VehicleInspectionRow label={'Front Windshield:'} variable={'windshield'} />
			<VehicleInspectionRow label={'Driver-Side Window:'} variable={'driverWindow'} />
			<VehicleInspectionRow label={'Passenger-Side Window:'} variable={'passWindow'} />
			<VehicleInspectionRow label={'Driver-Side Rear Window:'} variable={'driverBackWindow'} />
			<VehicleInspectionRow label={'Passenger-Side Rear Window:'} variable={'passBackWindow'} />
			<VehicleInspectionRow label={'Rear Windshield:'} variable={'rearWindow'} />
			<VehicleInspectionRow label={'Mine Flag*:'} variable={'mineFlag'} />
			<VehicleInspectionRow label={'Body Damage:'} variable={'bodyDamage'} />

			{/* Notes Field */}
			<FormTextField
				control={control}
				name={'exteriorNotes'}
				label={'Exterior Notes'}
				fullWidth
				inputProps={{maxLength: 255}}
				multiline
				minRows={2}
			/>

		</Alignment>

	);
});
