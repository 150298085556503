import { Divider, List, ListItem, Typography, Dialog, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Skeleton } from '@mui/lab';
import React, { useCallback } from 'react';
import { useApi, useLoadApi } from '@imas/api';
import { GetVersionChanges } from 'src/api/misc/misc';
import { Alignment, Spacer } from '@imas/components/layout';
import { VersionCode } from 'src/api/types/api/misc/misc';
import versionChangesStyles from './VersionChangesStyles';
import { IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { getUserRoles } from '@imas/redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { UpdateVersionChanges } from 'src/api/misc/misc';
import { useAutomaticSnackbar } from "@imas/utils/snackbar";

//change categories enum
enum VersionChangeCategory {
    NEW = "new",
    MODIFIED = "modified",
    BUGFIX = "bugfix"
}

//get label from category
const getCategoryLabel = (category: VersionChangeCategory) => {
    if (category === VersionChangeCategory.NEW) {
        return "New Features";
    } else if (category  === VersionChangeCategory.MODIFIED) {
        return "Modified Features";
    } else if (category  === VersionChangeCategory.BUGFIX) {
        return "Bug Fixes";
    }

    return "UNKNOWN";
};

const VersionChanges = ({
    version
}: {
    version?: VersionCode
}) => {
    //component styling & deviceInfo
    const { classes, deviceInfo } = versionChangesStyles();
	const userRoles = useSelector(getUserRoles);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(-1);
    const [description, setDescription] = useState('');
    
    const updateVersionChanges = useApi(UpdateVersionChanges);
    const showSnackbar = useAutomaticSnackbar();

    //Updates version change when form filled
    const updateverChange = useCallback( async (description: string, id: int) => {
        const close = showSnackbar("Updating version description...", {variant: 'info', persist: true});

        try{
            await updateVersionChanges(id, description);

            close();

            showSnackbar("Updated Version Patch Notes", {variant: 'success'}, true);

            refreshVersionChanges();




        } catch (e)
        {
            throw close(e);
        }

    },[]);

    const handleClose = () => {
        setOpen(false);
    };
    //get version changes
    const { data: versionChanges, call: refreshVersionChanges } = useLoadApi(GetVersionChanges, [version?.id ?? -1], [version], { disabled: version === undefined });

    // labeled category column given the category
    const LabeledCategory = ({ category, skeleton } : { category: VersionChangeCategory, skeleton?: boolean }) => {
        //get changes for the specified category
        const filteredChanges = (versionChanges ?? []).filter(x => x.group === category);

        if (skeleton) {
            //render skeleton
            return (
                <>
                    <Typography variant={"h6"} className={classes.categoryTitle}>{getCategoryLabel(category)}</Typography>
                    <List>
                        {[null, null, null].map((_, index) => (<ListItem key={index}><Typography variant={"body1"} className={classes.loadingText}><Skeleton/></Typography></ListItem>))}
                    </List>
                </>
            );
        } else {
            return (
                <>
                    <Typography variant={"h6"} className={classes.categoryTitle}>{getCategoryLabel(category)}</Typography>
                    <List>
                        {filteredChanges.map((change, index) => (
                            <ListItem key={index}>
                                <Alignment row>                                
                                    <Typography variant={"body1"}>{"- " + change.description}</Typography>
                                    {userRoles.dbManager ? <IconButton
										
										onClick={() => {
                                            setId(change.id);
                                            setDescription(change.description);
											setOpen(true);
										}}
										sx={{ marginLeft: '10px', fontSize: '5px' }}
									><EditIcon sx={{fontSize: '14px',}}/></IconButton> : null}
                                </Alignment>
                            </ListItem>
                        ))}
                        {filteredChanges.length === 0 ? (
                            <ListItem>
                                <Typography variant={"body1"}>{"None"}</Typography>
                            </ListItem>
                        ) : null}
                    </List>
                </>
            );
        }
    };

    //if no version show skeleton
    if (!version) return (
        <Alignment column flex overflowHidden className={deviceInfo.isMd ? classes.container : undefined}>
            {deviceInfo.isMd ? ( 
                <>
                    <Typography variant={"h4"}><Skeleton/></Typography>
                    <Divider/>
                    <Spacer vertical size={"20px"}/>
                </>
            ) : null}
            <Alignment column flex overflowScroll>
                <LabeledCategory category={VersionChangeCategory.NEW} skeleton/>
                <LabeledCategory category={VersionChangeCategory.MODIFIED} skeleton/>
                <LabeledCategory category={VersionChangeCategory.BUGFIX} skeleton/>
            </Alignment>
        </Alignment>
    );

    
    return (
        <><Alignment column flex overflowHidden className={deviceInfo.isMd ? classes.container : undefined}>
        {/* Show Title on Desktop */}
        {deviceInfo.isMd ? ( 
            <>
                {/* Show Version # */}
                <Typography variant={"h5"}>{"Version " + version.major + "." + version.minor + "." + version.revision}</Typography>
                <Divider/>
                <Spacer vertical size={"20px"}/>
            </>
        ) : null}
        
        {/* Changes Box */}
        <Alignment column flex overflowScroll>
            {/* New Features */}
            <LabeledCategory category={VersionChangeCategory.NEW}/>

            {/* Modified Features */}
            <LabeledCategory category={VersionChangeCategory.MODIFIED}/>

            {/* Bug Fixes */}
            <LabeledCategory category={VersionChangeCategory.BUGFIX}/>
        </Alignment>
    </Alignment>
    {/* Edit Version Changes Dialog */}
    <Dialog
    open={open}
    onClose={handleClose}>
        <DialogTitle>
				{'Edit Patch Notes'}
		</DialogTitle>
        <DialogContent>
            <TextField
            required
            error={!description}
            label='Description'
            value={description}
            multiline
            sx={{marginTop: '5px'}}
            rows={8}
            onChange={(e) => {
                setDescription(e.target.value);
            }}>

            </TextField>

        </DialogContent>
        <DialogActions>
            <Button 
            disabled={!description}
            onClick={() => {
                updateverChange(description, id);
                setOpen(false);
            }}>
                {"Save"}
            </Button>
            <Button onClick={() => {
                setOpen(false);
            }}>
               {"Cancel"} 
            </Button>

        </DialogActions>

    </Dialog>
    </>
        
    );
};

export default VersionChanges;