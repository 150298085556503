import { Typography, Divider } from '@mui/material';
import { Client } from '@imas/api/client';
import { FormPaper } from '@imas/mui-form';
import { memo } from 'react';
import { Alignment } from '@imas/components/layout';
import { formatUSD } from '@imas/utils/formatting';

interface ClientARDetailsProps {
	client?: Client | null;
};

export const ClientARDetails = memo(({ client }: ClientARDetailsProps) => {
	return (
		<FormPaper sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>			
			<Typography variant={"h6"} sx={{ marginLeft: '10px', marginTop: '3px', fontWeight: 'bold' }}>{"A/R Details"}</Typography>
			
			{client === null ? (
				<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until client is saved."}</Typography>
			) : client ? (
				<Alignment column flex sx={{ margin: '0 10px 10px 10px' }}>
					{/* <Alignment row>
						<Typography variant={"h6"} sx={{ fontWeight: 'bold' }}>{`Balance: 0.00`}</Typography>
					</Alignment> */}

					<Alignment row sx={{ marginTop: 'auto'}}>
						<Alignment column sx={{ flex: '0 1 0', minWidth: '170px' }}>
							<Typography variant={"body1"} sx={{ fontWeight: 'bold' }}>{"Last 12 Months"}</Typography>
							<Divider/>
							<Alignment row>
								<Typography variant={"body1"} sx={{ fontWeight: 'bold' }}>{"Sales:"}</Typography>
								<Typography variant={"body1"} sx={{ marginLeft: '5px' }}>{`${formatUSD(client.salesLast12M, 0)}`}</Typography>
							</Alignment>
							<Alignment row>
								<Typography variant={"body1"} sx={{ fontWeight: 'bold' }}>{"Rank:"}</Typography>
								<Typography variant={"body1"} sx={{ marginLeft: '5px' }}>{`${client.salesRankLast12M}`}</Typography>
							</Alignment>
						</Alignment>
						<Alignment column sx={{ flex: '0 1 0', minWidth: '170px', marginLeft: '20px' }}>
							<Typography variant={"body1"} sx={{ fontWeight: 'bold' }}>{"All-Time"}</Typography>
							<Divider/>
							<Alignment row>
								<Typography variant={"body1"} sx={{ fontWeight: 'bold' }}>{"Sales:"}</Typography>
								<Typography variant={"body1"} sx={{ marginLeft: '5px' }}>{`${formatUSD(client.sales, 0)}`}</Typography>
							</Alignment>
							<Alignment row>
								<Typography variant={"body1"} sx={{ fontWeight: 'bold' }}>{"Rank:"}</Typography>
								<Typography variant={"body1"} sx={{ marginLeft: '5px' }}>{`${client.salesRank}`}</Typography>
							</Alignment>
						</Alignment>
					</Alignment>
				</Alignment>
			) : null}
		</FormPaper>
	);
});