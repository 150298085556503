import { makeStyles } from '@imas/styles';

export const feedbackFormStyles = makeStyles()((theme) => ({
    attachmentChip: {
        margin: '5px',
        borderRadius: '16px'
    },
    attachments: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    submit: {
        width: '-webkit-fill-available'
    }
}));