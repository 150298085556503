import { formatNumberByFactor } from './formatNumberByFactor';

//format a number in large currency format (ex. $10 K, $10 M etc....) with the given precision
export function formatUSDLarge(value: any, precision: 0 | 1 | 2 | 3 = 0, mode: "short" | "long" = "short"): string {
    const number = isNaN(Number(value)) ? 0 : Number(value);
	const formatted = formatNumberByFactor(number, {
		factorOf: 1000,
		zeroResult: "0",
		factors: mode === "short" ? ["", "K", "M", "B", "T", "Qa", "Qi"] : ["", "Thousand", "Million", "Billion", "Quadrillion", "Quintillion"],
		fractionDigits: precision,
	});

	//return with USD symbol
    return `$${formatted}`;
};