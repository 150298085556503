import { Alignment, Spacer, XSkeleton } from '@imas/components/layout';
import { MuiFormPartial, MuiFormRef, useMuiForm, MuiForm, FormNumberTextField, FormDatePicker, useMuiWatch, FormTextField } from '@imas/mui-form';
import { Editable, FormRefSubmitHandler } from '@imas/mui-form/types';
import { getUserData } from '@imas/redux';
import { Box, Tab, Tabs, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { VehicleInspection, vVehicle } from 'src/api/types/api/misc/misc';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import VehicleInspectionAttachments from '../VehicleInspectionAttachments/VehicleInspectionAttachments';
import { VehicleInspectionStyles } from '../VehicleInspectionStyles';
import { TVehicleInspectionFormContext, VehicleInspectionFormContext } from './VehicleInspectionFormContext';
import { VehicleInspectionFormExterior } from './VehicleInspectionFormSections/VehicleInspectionFormExterior';
import { VehicleInspectionFormInterior } from './VehicleInspectionFormSections/VehicleInspectionFormInterior';
import { VehicleInspectionFormLights } from './VehicleInspectionFormSections/VehicleInspectionFormLights';
import { VehicleInspectionFormMaintenance } from './VehicleInspectionFormSections/VehicleInspectionFormMaintenance';
import { VehicleInspectionFormMechanical } from './VehicleInspectionFormSections/VehicleInspectionFormMechanical';
import { VehicleInspectionFormTires } from './VehicleInspectionFormSections/VehicleInspectionFormTires';




export const vehicleInspectionFormDefaultValues: Editable<TVehicleInspectionForm> = {

	mileage: null,
	inspectionDate: new Date(),

	//maintenance
	radFluid: 2,
	oilFluid: 2,
	brakeFluid: 2,
	powerFluid: 2,
	wiperFluid: 2,
	airFilter: 2,
	maintenanceNotes: '',

	//interior
	horn: 2,
	rearMirror: 2,
	driverSeat: 2,
	passSeat: 2,
	rearSeat: 2,
	instrumentation: 2,
	dashboard: 2,
	interiorLight: 2,
	fireExt: 2,
	firstaidKit: 2,
	interiorNotes: '',

	//exterior
	driverMirror: 2,
	passMirror: 2,
	wiper: 2,
	bodyDamage: 2,
	windshield: 2,
	driverWindow: 2,
	passWindow: 2,
	driverBackWindow: 2,
	passBackWindow: 2,
	rearWindow: 2,
	mineFlag: 2,
	exteriorNotes: '',

	//tires
	leftFrontTire: 2,
	rightFrontTire: 2,
	leftBackTire: 2,
	rightBackTire: 2,
	spareTire: 2,
	jackIron: 2,
	tireNotes: '',

	//lights
	headLight: 2,
	turnSignalLight: 2,
	reverseLight: 2,
	tailLight: 2,
	hazardLight: 2,
	licenseLight: 2,
	strobeLight: 2,
	backupAlarm: 2,
	lightNotes: '',

	//mechanical
	parkingBrake: 2,
	engine: 2,
	transmission: 2,
	suspension: 2,
	exhaust: 2,
	fuel: 2,
	electrical: 2,
	airConditioner: 2,
	heater: 2,
	battery: 2,
	mechanicalNotes: '',

	notes: '',
	file1: null,
	file2: null,
	file3: null,
	file4: null,
	file5: null,
	newFiles: []
};


interface VehicleInspectionFormProps extends MuiFormPartial { 
	vVehicle?: vVehicle;
	vehicleInspection?: VehicleInspection;
	onSubmit?: FormRefSubmitHandler<TVehicleInspectionForm, VehicleInspection>;
	//refreshProposal: {() : void};
};

export const VehicleInspectionForm = React.memo(React.forwardRef<MuiFormRef<TVehicleInspectionForm>, VehicleInspectionFormProps>(({ vVehicle, vehicleInspection, onSubmit, /* refreshProposal, */ ...props }, ref) => {

	const { classes, deviceInfo } = VehicleInspectionStyles();

	//get user data
	const userData = useSelector(getUserData);

	//state variables
	//const [problemsPresent, setProblemsPresent] = useState<boolean>(false);
	const [focusedTab, setFocusedTab] = useState<int>(0);

    //form hook
    const { control, muiFormProps, setValue, getValues } = useMuiForm({ 
        defaultValues: vehicleInspectionFormDefaultValues,
        validator: ({ mileage, inspectionDate, radFluid, oilFluid, brakeFluid, powerFluid, wiperFluid, airFilter, maintenanceNotes, horn, rearMirror, driverSeat, passSeat, rearSeat, instrumentation, dashboard, interiorLight, fireExt, firstaidKit, interiorNotes, driverMirror, passMirror, wiper, bodyDamage, windshield, driverWindow, passWindow, driverBackWindow, passBackWindow, rearWindow, mineFlag, exteriorNotes, leftFrontTire, rightFrontTire, leftBackTire, rightBackTire, spareTire, jackIron, tireNotes, headLight, turnSignalLight, reverseLight, tailLight, hazardLight, licenseLight, strobeLight, backupAlarm, lightNotes, parkingBrake, engine, transmission, suspension, exhaust, fuel, electrical, airConditioner, heater, battery, mechanicalNotes, notes, ...fields }, error) => {

			//if (problemsPresent && mileage === null) error('mileage', 'required');
			if (inspectionDate === null) return;
			if (radFluid === null) return;
			if (oilFluid === null) return;
			if (brakeFluid === null) return;
			if (powerFluid === null) return;
			if (wiperFluid === null) return;
			if (airFilter === null) return;
			if (maintenanceNotes === null) maintenanceNotes = '';
			if (horn === null) return;
			if (rearMirror === null) return;
			if (driverSeat === null) return;
			if (passSeat === null) return;
			if (rearSeat === null) return;
			if (instrumentation === null) return;
			if (dashboard === null) return;
			if (interiorLight === null) return;
			if (fireExt === null) return;
			if (firstaidKit === null) return;
			if (interiorNotes === null) interiorNotes = '';
			if (driverMirror === null) return;
			if (passMirror === null) return;
			if (wiper === null) return;
			if (bodyDamage === null) return;
			if (windshield === null) return;
			if (driverWindow === null) return;
			if (passWindow === null) return;
			if (driverBackWindow === null) return;
			if (passBackWindow === null) return;
			if (rearWindow === null) return;
			if (mineFlag === null) return;
			if (exteriorNotes === null) exteriorNotes = '';
			if (leftFrontTire === null) return;
			if (rightFrontTire === null) return;
			if (leftBackTire === null) return;
			if (rightBackTire === null) return;
			if (spareTire === null) return;
			if (jackIron === null) return;
			if (tireNotes === null) tireNotes = '';
			if (headLight === null) return;
			if (turnSignalLight === null) return;
			if (reverseLight === null) return;
			if (tailLight === null) return;
			if (hazardLight === null) return;
			if (licenseLight === null) return;
			if (strobeLight === null) return;
			if (backupAlarm === null) return;
			if (lightNotes === null) lightNotes = '';
			if (parkingBrake === null) return;
			if (engine === null) return;
			if (transmission === null) return;
			if (suspension === null) return;
			if (exhaust === null) return;
			if (fuel === null) return;
			if (electrical === null) return;
			if (airConditioner === null) return;
			if (heater === null) return;
			if (battery === null) return;
			if (mechanicalNotes === null) mechanicalNotes = '';
			if (notes === null) notes = '';

            return { mileage, inspectionDate, radFluid, oilFluid, brakeFluid, powerFluid, wiperFluid, airFilter, maintenanceNotes, horn, rearMirror, driverSeat, passSeat, rearSeat, instrumentation, dashboard, interiorLight, fireExt, firstaidKit, interiorNotes, driverMirror, passMirror, wiper, bodyDamage, windshield, driverWindow, passWindow, driverBackWindow, passBackWindow, rearWindow, mineFlag, exteriorNotes, leftFrontTire, rightFrontTire, leftBackTire, rightBackTire, spareTire, jackIron, tireNotes, headLight, turnSignalLight, reverseLight, tailLight, hazardLight, licenseLight, strobeLight, backupAlarm, lightNotes, parkingBrake, engine, transmission, suspension, exhaust, fuel, electrical, airConditioner, heater, battery, mechanicalNotes, notes, ...fields };
        },
		onSuccessfulSubmit: () => {
			
		}
    });

	//variables are packaged in 'binary' smallints, this will extract the radiobutton status from the int
	const getRadioValue = (value: int | null) => {
		if (value === null) return 'fail';
		else if (value === 2 || value === 3 || value === 18 ) return 'not inspected';
		else if (value === 4 || value === 5 || value === 20 ) return 'good';
		else if (value === 8 || value === 9 || value === 24 ) return 'bad';
		else return 'fail';
	};

	//this will package the radiobutton into the 'binary' smallint form
	const setRadioValue = (variable: keyof TVehicleInspectionForm, state: string) => {
		const value = getValues(variable);

		//this is only applicable to int fields, where this 'binary' form is used
		if (typeof value !== 'number') return;

		//the final value is the value of the radioButton (2, 4, or 8) + 1 is the fixed checkbox is checked. 
		//If the value was odd, it means the checkbox was checked, so add 1 to the final value
		//if the value is greater than 15, ignore has been checked. So add 16 from ignore
		const completionOffset = value%2 === 0 ? 0 : 1;
		const ignoreOffset = value > 15 ? 16 : 0;
		switch (state) {
			case 'not inspected': {
				setValue(variable, 2 + completionOffset + ignoreOffset);
				break;
			}
			case 'good': {
				setValue(variable, 4 + completionOffset + ignoreOffset);
				break;
			}
			case 'bad': {
				setValue(variable, 8 + completionOffset + ignoreOffset);
				break;
			}
		}

	};

	//if a checkbox is checked, add 1. If unchecked, subtract 1
	const setCheckboxValue = (box: "fixed" | "ignore", variable: keyof TVehicleInspectionForm, state: boolean) => {
		let value = getValues(variable);
		if (typeof value !== 'number') return;

		//fixed checkbox behavior
		if (box === "fixed") {
			if (value > 15) value = value - 16;
			const newValue = (state ? value + 1 : value - 1);
			setValue(variable, newValue);
		}
		
		//ignore checkbox behavior
		if (box === "ignore") {
			if (value%2 !== 0) value = value - 1;
			const newValue = (state ? value + 16 : value - 16);
			setValue(variable, newValue);
		}
	};

	/* const calculateProblemsPresent = useCallback(() => {
		const values = getValues();
		let problems = 0;
		const boolArray = Object.entries(values).forEach(([index, value]) => {
			if( typeof value !== 'number') return;
			else if( value === 8 || value === 9) problems++;
		});
		setProblemsPresent(problems !== 0);		
	}, []);

	//Are any fields 'bad'
	useEffect(() => {
		calculateProblemsPresent();
	}, [ref]); */

	//context values
	const contextValue = useMemo((): TVehicleInspectionFormContext => ({
		vehicleInspection: vehicleInspection,
		
		//radio buttons may only be edited by the person who made the inspection, or a fleet manager
		radioDisabled: !!vehicleInspection && (vehicleInspection.employeeId !== userData?.employee.employeeId || userData.employee.fleetManager),

		//the completion checkboxes can be hidden or disabled when/if they need to be. Not used at the moment
		hideCompletion: false,
		disableCompletion: false,
		hideIgnore: !vehicleInspection,
		//hideIgnore: true,
		disableIgnore: !vehicleInspection,
		//disableIgnore: true,
		getRadioValue: getRadioValue,
		setRadioValue: setRadioValue,
		setCheckboxValue: setCheckboxValue,
		//calculateProblemsPresent: calculateProblemsPresent,
	}), [vehicleInspection]);

	return(
		<MuiForm
			{...props}
			{...muiFormProps}
			ref={ref}
		>
			<VehicleInspectionFormContext.Provider value={contextValue}>

				{/* Tabs */}
				<Tabs
            	    value={focusedTab}
            	    onChange={(_, value: 0 | 1) => setFocusedTab(value)}
            	    indicatorColor={"primary"}
            	    textColor={"primary"}
            	    centered
            	>
            	    <Tab value={0} label={"Details"}/>
            	    <Tab value={1} label={"Attachments"}/>
           		</Tabs>

				<Alignment column overflowHidden hidden={focusedTab !== 0}>
					
					<Typography variant={'h5'} className={classes.title}>{vVehicle ? `Vehicle ${vVehicle.calItemVehicle} Inspection` : `Vehicle Inspection`}</Typography>

					<Spacer vertical/>

					<Alignment row>

						{/* Mileage */}
						<FormNumberTextField
							control={control}
							name={'mileage'}
							label={'Mileage'}
							//required={problemsPresent}
							fullWidth
						/>

						<Spacer horizontal/>

						{/* Date */}
						<FormDatePicker
							control={control}
							name={"inspectionDate"}
							label={"Date"}
							required
							//disabled
							renderInput={(params) => <TextField {...params}/>}
							sx={{ maxWidth: '150px' }}
						/>

					</Alignment>
					{/* Disclaimer */}
					<Typography className={classes.plainText} variant={"body1"}>{"Note: * MSHA Required"}</Typography>

					{/* Divider */}
					<Box sx={{ width: '-webkit-fill-available', bgcolor: "text.primary", height: '3px', marginBottom: '5px' }}/>

					{/* The bulk of the form itself */}
					<Alignment column sx={{overflowY: 'auto', paddingRight: '2px'}}>
						<VehicleInspectionFormMaintenance/>
						<Spacer vertical size={'20px'}/>
						<VehicleInspectionFormInterior/>
						<Spacer vertical size={'20px'}/>
						<VehicleInspectionFormExterior/>
						<Spacer vertical size={'20px'}/>
						<VehicleInspectionFormTires/>
						<Spacer vertical size={'20px'}/>
						<VehicleInspectionFormLights/>
						<Spacer vertical size={'20px'}/>
						<VehicleInspectionFormMechanical/>

						<Spacer vertical size={'20px'}/>

						{/* General Notes Field */}
						<FormTextField
							control={control}
							name={'notes'}
							label={'General Notes'}
							fullWidth
							inputProps={{maxLength: 255}}
							multiline
							minRows={3}
						/>
					</Alignment>

				</Alignment>

				{/* The attachments tab */}
				<Alignment overflowHidden column hidden={focusedTab !== 1} sx={{maxWidth: '500px'}}>
					<VehicleInspectionAttachments/>
				</Alignment>
				

			</VehicleInspectionFormContext.Provider>
		</MuiForm>
	);
}));