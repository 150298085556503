import { ProposalServiceFee, ProposalService } from '@imas/api/proposal/types';
import { HubConnection } from "@microsoft/signalr";
import { v4 as uuid4 } from 'uuid';
import { ResourcesHub } from '@imas/utils/resource';
import { ClientsHubMap, MiscHubMap, PhoneNumbersHubMap, ProposalsHubMap, QualityControlHubMap, ServiceOrdersHubMap, TimeSheetsHubMap } from './hub-definitions';

//UpdateType enum
export type UpdateType = "CREATE" | "UPDATE" | "DELETE" ;

//UpdateFunction type
export type UpdateFunction<T extends any> = (type: UpdateType, data: T) => void;

export type SignalRHubMethods = {
    [action: string]: (...args: any[]) => any;
};

export type SignalRHub<SERVER_METHODS extends SignalRHubMethods = {}, CLIENT_METHODS extends SignalRHubMethods = {}> = {
    server: SERVER_METHODS;
    client: CLIENT_METHODS;
};

export class HubRegistration<T extends SignalRHub<any, any> = SignalRHub<any, any>> {
    /** ID of the HubRegistration. */
    public id: string;
    /** The Raw HubConnection object. */
    public connection: HubConnection;
    /** If connection.start() has been called. */
    public connectionStarted: boolean = false;
    /** If an active connection is established. */
    public isConnected: boolean = false;
    /** A handler that will be called when connection is first or re-established, once it has been called it is removed. */
    public onConnect: (() => Promise<void>) | null = null;

    constructor(connection: HubConnection) {
        this.id = uuid4();
        this.connection = connection;

        //register handlers for detecting if the registration is currently connected 
        this.connection.onreconnecting(() => {
            this.isConnected = false;
        });

        this.connection.onreconnected(() => {
            this.isConnected = true;
            this.callOnConnect().finally();
        });
    }

    //wrapper for calling onConnect and removing it after it has been called
    async callOnConnect() {
        if (this.onConnect) {
            await this.onConnect();
            this.onConnect = null;
        }
    }

    //start the connection
    async start() {
        //call connection.start
        await this.connection.start();

        //set connectionStarted to true
        this.connectionStarted = true;

        //update isConnected & call onConnect if it was provided
        this.isConnected = true;
        await this.callOnConnect();
    }

    //invoke method with typings added
    async invoke<M extends Exclude<keyof T["server"], number | symbol>>(method: M, ...args: Parameters<T["server"][M]>): Promise<T["server"][M]> {
        //call connection invoke method 
        return await this.connection.invoke(method, ...(args as any[]));
    }
}

export type HubRegistrationType<T extends HubRegistration> = T extends HubRegistration<infer U> ? U : never;

export interface SingalRHubMap extends 
	ClientsHubMap,
	PhoneNumbersHubMap,
	ProposalsHubMap,
	QualityControlHubMap,
	ServiceOrdersHubMap,
	TimeSheetsHubMap,
	MiscHubMap
{
    "/resources": ResourcesHub;
};