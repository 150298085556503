import { SourceGetter, axiosData } from '../_internals';
import { ContactListWithNumber } from '../proposal';


//contact list details base url
const CONTACT_LISTS_URL = "/api/clients/locations/contacts/lists";

/* Update a ContactList's name or discontinued status */
export const CreateContactList = (getSource: SourceGetter) => {
    return async (name: string): Promise<ContactListWithNumber> => {
        return axiosData({
			method: 'post',
            url: `${CONTACT_LISTS_URL}/${name}`,
            cancelToken: getSource().token,
		});
    };
};

/* Update a ContactList's name or discontinued status */
export const UpdateContactList = (getSource: SourceGetter) => {
    return async (contactListId: int, contactListName?: string, discontinued?: boolean): Promise<void> => {
        return axiosData({
			method: 'put',
            url: `${CONTACT_LISTS_URL}`,
			data: {
				contactListId,
				contactListName,
				discontinued,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Delete a contact list */
export const DeleteContactList = (getSource: SourceGetter) => {
    return async (contactListId: int): Promise<boolean> => {
        return axiosData({
			method: 'delete',
            url: `${CONTACT_LISTS_URL}/${contactListId.toString()}`,
            cancelToken: getSource().token,
		});
    };
};
