import { ClientLocation } from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { useLoadApi } from '@imas/api';
import { GetClientLocations, ClientLocationForm as TClientLocationForm } from '@imas/api/client';
import { useClients } from '@imas/api/data-hooks';
import { CLIENTS_AUTOCOMPLETE_VIRTUALIZER } from '@imas/utils/virtualization';
import { useIsElementActive } from '@imas/utils/hooks';
import { Typography, TextField, Tooltip, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Help as HelpIcon, Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { FormTextField, FormAutocomplete, FormCheckbox, useMuiFormContext, useMuiWatch } from '@imas/mui-form';
import { memo, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { ClientLocationFormValidatorRef } from './ClientLocationForm';
import { formatAddress } from '@imas/utils/formatting';
import { useEditor } from '@imas/utils/editor/useEditor';
import { openClientEditor as clientEditorOpener } from 'src/pages/editors';

interface ClientAndLocationPickerProps {
	clientId: int | null;
	cLocation?: ClientLocation | null;
};

export const LocationClientAndName = memo(forwardRef<ClientLocationFormValidatorRef, ClientAndLocationPickerProps>(({ clientId: initClientId, cLocation }, ref) => {
	//use form control
	const { control, setValue, isLoading } = useMuiFormContext<TClientLocationForm>();

	//use client editor
	const [clientEditorOpen, openClientEditor] = useEditor({
		url: "/clients",
		opener: clientEditorOpener,
		onUpdate: (type, client) => {
			//if a new record was created from the opened editor then populate the clientId field
			if (type === "CREATE") setValue("clientId", client.id);
		},
	});

	//check if the location name input is the currently focused element
	const [locationNameFocused, locationNameRef] = useIsElementActive<HTMLInputElement>();

	//if the similar names tooltip should be open
	const [similarNamesTooltipOpen, setSimilarNamesTooltipOpen] = useState<boolean>(false);

	//subscribe to updates
	const [clientId, locationName] = useMuiWatch({ control, name: ["clientId", "name"] });

	//get all clients
	const [clients] = useClients();

	//data hook is not used here because if the hook receives the update of a new location before the form finishes submitting then the form bugs out and won't navigate to the edit version (somewhat of a race condition) avoided by only refreshing the list when the clientId changes
	//get all locations for the selected clients
	const { data: locations } = useLoadApi(GetClientLocations, [clientId], [clientId], { disabled: clientId === null });

	//filter locations to remove the location currently being edited (if in edit mode)
	const filteredLocations = useMemo(() => (locations ?? []).filter(x => x.id !== cLocation?.id), [locations, cLocation]);

	//update ref
    useImperativeHandle(ref, () => ({
        locations: filteredLocations,
    }), [filteredLocations]);

	//get locations with a similar name
	const similarLocations = useMemo(() => filteredLocations.filter(x => locationName !== null && x.name.toLowerCase().includes(locationName.toLowerCase())), [filteredLocations, locationName]);

	//get helper text for name input
	const similarNameHelperText: React.ReactNode = useMemo(() => {
		const plural = similarLocations.length > 1;

		//if there is more than 0 similar locations let the user know they exist and what they are
		if (similarLocations.length > 0) return (
			<>
				{`There ${plural ? "are" : "is"} ${similarLocations.length} location${plural ? "s" : ""} with a similar name.`}
				<Tooltip
					open={similarNamesTooltipOpen || locationNameFocused} 
					title={<List dense>{similarLocations.map(location => (<ListItem key={location.id} sx={{ padding: '0' }}><ListItemText primary={location.name}/></ListItem>))}</List>} 
					onOpen={() => setSimilarNamesTooltipOpen(true)}
					onClose={() => setSimilarNamesTooltipOpen(false)}
					arrow
				>
					<HelpIcon sx={{ margin: '0 0 -3px 5px', height: '15px', width: '15px' }}/>
				</Tooltip>
			</>
		);
		return undefined;
	}, [similarLocations, locationNameFocused, similarNamesTooltipOpen, setSimilarNamesTooltipOpen]);

	//selected client
	const client = useMemo(() => (clients ?? []).find(x => x.id === clientId) ?? null, [clientId, clients]);

	return (
		<Alignment column>
			<Alignment row sx={{ marginBottom: '10px' }}>
				{/* Client Field */}
				<FormAutocomplete
					control={control}
					name={"clientId"}
					loading={!clients}
					required
					autoComplete
					autoHighlight
					fullWidth
					disableClearable
					disabled={cLocation !== null || initClientId !== null}
					options={clients ?? []}
					getValue={(clientId, options) => options.find((x) => x.id === clientId)}
					onChange={(client) => client?.id ?? null}
					getOptionLabel={(client) => client.name}
					getOptionDisabled={(client) => !client.active}
					renderInput={(params) => <TextField {...params} label={"Client"}/>}
					sx={{ height: '37px', "& .MuiInputBase-input": { fontSize: '1.5em', padding: '0 8px' } }}

					//add virtualization
					{...CLIENTS_AUTOCOMPLETE_VIRTUALIZER}
				/>

				{/* Edit Selected Client Button */}
				<Tooltip title={`Edit '${client?.name ?? "Loading..."}'`} arrow>
					<IconButton
						disabled={isLoading || clientEditorOpen || client === null}
						onClick={() => {
							if (client === null) return;

							//open editor for selected client
							openClientEditor(client.id);
						}}
						sx={{ margin: 'auto 0 auto 10px' }}
					><EditIcon/></IconButton>
				</Tooltip>

				{/* New Client Button */}
				<Tooltip title={`New Client`} arrow>
					<IconButton
						disabled={isLoading || clientEditorOpen || cLocation !== null || initClientId !== null}
						onClick={() => {
							//open editor for selected client
							openClientEditor();
						}}
						sx={{ margin: 'auto 0 auto 10px' }}
					><AddIcon/></IconButton>
				</Tooltip>
			</Alignment>

			{/* Client Corprate Address */}
			<Alignment row sx={{ color: 'text.primary', marginLeft: '25px' }}>
				<Typography variant={"body1"} sx={{ fontWeight: 'bold', marginRight: '10px' }}>{"Corporate Address:"}</Typography>
				<Typography variant={"body1"}>{client ? formatAddress({ address1: client.address1, address2: client.address2, city: client.city, state: client.state, zip: client.zip }) : "--"}</Typography>
			</Alignment>

			{/* Location Name */}
			<Alignment row sx={{ marginTop: '15px' }}>
				<FormTextField
					control={control}
					name={"name"}
					label={"Location"}
					required
					fullWidth
					disabled={clientId === null}
					FormHelperTextProps={{ sx: similarNameHelperText ? { color: 'warning.main'} : undefined }}
					helperText={similarNameHelperText}
					sx={{ height: '54px', "& .MuiInputBase-input": { fontSize: '2em', padding: '5px 10px' } }}
					ref={locationNameRef}
				/>

				{/* Active Field */}
				{cLocation === null ? null : (
					<FormCheckbox
						name={"active"}
						control={control}
						label={"Active"}
						sx={{ margin: 'auto 5px auto 10px'}}
					/> 
				)}
			</Alignment>
		</Alignment>
	);
}));
