import React from 'react';
import { MuiForm, MuiFormPartial, MuiFormRef, useMuiForm , Editable } from '@imas/mui-form';
import { ClientLocationContact, ClientLocationContactForm as TClientLocationContactForm } from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { ContactLocationAndClientAndName } from './ContactLocationAndClientAndName';
import { ClientLocationContactDetails } from './ClientLocationContactDetails';
import { ClientLocationContactProposals } from './ClientLocationContactProposals';
import { isValidEmail, isValidEmailList } from '@imas/utils/validation';
import { store } from '@imas/redux';
import moment from 'moment';
import { ClientLocationContactPreferences } from './ClientLocationContactPreferences';

const defaultValues: Editable<TClientLocationContactForm> = {
	locationId: null,
	name: null,
	active: true,
	title: null,
	first: null,
	preferredFirst: null,
	middle: null,
	last: null,
	suffix: null,
	jobTitle: null,
	department: null,
	email: null,
	ccEmail: null,
	notes: null,
	history: null,
	correspondencetype: 0,
    signaturemethodtype: 0
};

const getFieldLabel = (field: keyof Editable<TClientLocationContactForm>) => {
	switch (field) {
		case "first":
			return "first name";
		case "preferredFirst":
			return "preferred first name";
		case "middle":
			return "middle name";
		case "last":
			return "last name";
		case "jobTitle":
			return "job tittle";
		case "ccEmail":
			return "CC email";
		default:
			return field;
	}
};

export interface ClientLocationContactFormValidatorRef {
	contacts: ClientLocationContact[]; 
};

interface ClientLocationContactFormProps { 
	clientId: int | null; 
	locationId: int | null; 
	contact?: ClientLocationContact | null; 
};

export const ClientLocationContactForm = React.memo(React.forwardRef<MuiFormRef<TClientLocationContactForm>, MuiFormPartial & ClientLocationContactFormProps>(({ clientId, locationId, contact, ...props }, ref) => {
    //form hook
    const { muiFormProps } = useMuiForm<TClientLocationContactForm>({ 
        defaultValues: { ...defaultValues, locationId },
        validator: ({ locationId, name, first, email, ccEmail,correspondencetype,signaturemethodtype, ...otherFields }, error) => {
			//ensure email is a valid email
			if (email) {
				if (!isValidEmail(email)) error("email", "pattern", "Invalid email.");
			}

			//ensure ccEmail is a valid email list
			if (ccEmail) {
				if (!isValidEmailList(ccEmail)) error("ccEmail", "pattern", "Invalid email/semicolon email list.");
			}

			//ensure required fields are required
            if (locationId === null) error("locationId", "required");
            if (name === null) error("name", "required");
            if (first === null) error("first", "required");
			if (correspondencetype === null) error("correspondencetype", "required");
			if (signaturemethodtype === null) error("signaturemethodtype", "required");

            //ensure type safety
            if (locationId === null) return;
            if (name === null) return;
            if (first === null) return;
			if (correspondencetype === null) return;
			if (signaturemethodtype === null) return;


            return { locationId, name, first, email, ccEmail,correspondencetype,signaturemethodtype, ...otherFields };
        },
		onFieldBlur: (field, { lastValue, currentValue, setValue, getValues }) => {
			if (lastValue === currentValue) return;
			//handle updating Contact Name when First, Last, or Preferred First name are updated
			if (field === "first" || field === "last" || field === "preferredFirst") {
				const [first, last, preferredFirst] = getValues(["first", "last", "preferredFirst"]);

				//update name using the above fields
				setValue("name", `${preferredFirst ?? first ?? ""}${last ? ` ${last}` : ""}`);
			}

			//dont add updates to history if contact is not brand new
			if (contact === null) return;

			//add a row to the history field
			const addHistoryRow = (message: string) => {
				const user = store.getState().auth.value.user;
				if (!user) return;

				setValue("history", `Revised on ${moment().format("MM/DD/YYYY at hh:mm A")} by ${user.employee.first} ${user.employee.last}: ${message}\n${getValues("history") ?? ""}`);
			};

			//handle client history updates
			if (field === "active") {
				addHistoryRow(`Updated active status to '${currentValue ? "Active" : "Not Active"}'`);
			} else if (
				field === "title" || field === "first" || field === "preferredFirst" || field === "middle" || field === "last" 
				|| field === "suffix" || field === "jobTitle" || field === "department" || field === "email" || field === "ccEmail" || field === "title"   
			) {
				if (currentValue === null) addHistoryRow(`Removed ${getFieldLabel(field)}`);
				else addHistoryRow(`Updated ${getFieldLabel(field)} to '${currentValue}'`);
			}
		}
    });



    //separate loading from other props
    const { loading, viewOnly, ...otherProps } = props;

    //form data
    return (
        <MuiForm
            loading={loading}
            viewOnly={viewOnly}
            {...otherProps}
            {...muiFormProps}
            ref={ref}
        >
			{/* Client & Location & Contact */}
			<ContactLocationAndClientAndName clientId={clientId} locationId={locationId} contact={contact}/>

			{/* Contact Details */}
			<ClientLocationContactDetails contact={contact}/>

			{/* Leads & Proposals */}
			<Alignment row flex sx={{ marginTop: '10px' }}>
				{/* Leads */}
				
				{/*client preferences */}
				<Alignment column flex>
					<ClientLocationContactPreferences/>
				</Alignment>

				{/* Proposals */}
				<Alignment column flex>
					<ClientLocationContactProposals contact={contact}/>
				</Alignment>
			</Alignment>
        </MuiForm>
    );
}));