import React, { useState, useCallback, useImperativeHandle, useContext } from 'react';
import { FileExplorerContextMenuAction, FileExplorerContextMenuBuilder, FileExplorerContextMenuEvent } from './types';
import { FileExplorerContext, TFileExplorerContext } from '../FileExplorerContext';
import { getDefaultActions } from './FileExplorerContextMenuUtils';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider, MenuProps } from '@mui/material';

export interface FileExplorerContextMenuProps {
    builder?: FileExplorerContextMenuBuilder;
};

export interface FileExplorerContextMenuRef {
    /** Open the context menu given an event. */
    open: (event: FileExplorerContextMenuEvent) => void;
    /** Close the context menu if it is open */
    close: () => void;
};

const FileExplorerContextMenu = React.forwardRef<FileExplorerContextMenuRef, FileExplorerContextMenuProps>((props, ref) => {
    const { builder } = props;

    //FileExplorer context
    const fileExplorerContext = useContext(FileExplorerContext);

    //current menu event
    const [currentEvent, setCurrentEvent] = useState<FileExplorerContextMenuEvent | null>(null);

    //open menu action
    const open = useCallback<FileExplorerContextMenuRef["open"]>((event) => {
        setCurrentEvent(event);
    }, []); 

    //open menu action
    const close = useCallback<FileExplorerContextMenuRef["close"]>(() => {
        setCurrentEvent(null);
    }, []);

    //use imperative handle, provide ref 
    useImperativeHandle(ref, () => ({
        open,
        close,
    }), [close, open]);

    //get menu actions
    const getMenuActions = (event: FileExplorerContextMenuEvent, context: TFileExplorerContext) => {
        //get default actions for this event
        const actions = getDefaultActions(event, context);

        //double-depth array type guard
        const isNestedArray = (arr: FileExplorerContextMenuAction[] | FileExplorerContextMenuAction[][]): arr is FileExplorerContextMenuAction[][] => Array.isArray(arr[0]);

        //get prepend/append actions & and ensure they are correctly formatted
        let prependActions = builder?.prepend ? builder.prepend({ event, context }) : [];
        prependActions = isNestedArray(prependActions) ? prependActions : [prependActions];
        let appendActions = builder?.append ? builder.append({ event, context }) : [];
        appendActions = isNestedArray(appendActions) ? appendActions : [appendActions];

        //combine prependActions, actions, and appendActions
        const combinedActions = [...prependActions, ...actions, ...appendActions].filter(x => x !== undefined).filter(x => x.length !== 0);

        //filter function
        const filter = builder?.filter;

        //apply builder filter if provided
        const filteredActions = filter ? combinedActions.map(x => filter(x)) : combinedActions;

        //ensure there are actions to display, if there are 0 actions close the menu
        if (filteredActions.map(x => x.length).reduce((a, b) => a + b, 0) === 0) close();

        //return the actions
        return filteredActions;
    };

    if (currentEvent === null) return null;

    //get menu render props given the current event
    const getMenuProps = (): MenuProps => ({
        open: true,
        anchorEl: "x" in currentEvent.origin ? undefined : currentEvent.origin,
        anchorReference: "x" in currentEvent.origin ? "anchorPosition" : "anchorEl",
        anchorPosition: "x" in currentEvent.origin ? { top: currentEvent.origin.y, left: currentEvent.origin.x } : undefined,
        onClose: close,
        onClick: close,
        onContextMenu: (e) => {
            e.preventDefault();
        },
        sx: { padding: 0, maxWidth: '450px' },
        MenuListProps: { dense: true },
    });
    
    //generate menu sections + actions
    const menuSections = getMenuActions(currentEvent, fileExplorerContext);

    return (
        <Menu {...getMenuProps()}>
            {/* Generate Menu */}
            {menuSections.map((menuSection, sectionIndex) => (
                <>
                    {/* Section Actions */}
                    {menuSection.map(action => {
                        // Dont Render the MenuItem if it is hidden.
                        return action.hidden ? null : (
                            <MenuItem onClick={action.click}>
                                {/* Render Icon if Provided */}
                                {action.icon ? <ListItemIcon>{action.icon}</ListItemIcon> : null}

                                {/* Render label, and inset if specified. */}
                                <ListItemText inset={action.inset} primaryTypographyProps={{ noWrap: true }}>{action.label}</ListItemText>
                            </MenuItem>
                        );
                    })}

                    {/* Add Divider if there is another section */}
                    {menuSections[sectionIndex + 1] !== undefined ? <Divider/> : null}
                </>
            ))}
        </Menu>
    );
});

export { FileExplorerContextMenu };