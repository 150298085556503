import { axiosData, SourceGetter } from "@imas/api";
import { ShippingMethod } from './types';

//states base url
const SHIPPING_METHODS_URL = "/api/system/shipping-methods";

/**  */
export const GetShippingMethods = (getSource: SourceGetter) => {
    return async (getOtherAndNone?: boolean): Promise<ShippingMethod[]> => {
        return await axiosData({
            method: 'get',
            url: `${SHIPPING_METHODS_URL}`,
			data: getOtherAndNone ? 1 : 0,
            cancelToken: getSource().token,
        });
    };
};

/**  */
export const UpdateShippingMethod = (getSource: SourceGetter) => {
    return async (shippingMethod: ShippingMethod): Promise<ShippingMethod> => {
        return await axiosData({
            method: 'put',
            url: `${SHIPPING_METHODS_URL}`,
			data: shippingMethod,
            cancelToken: getSource().token,
        });
    };
};

/**  */
export const CreateShippingMethod = (getSource: SourceGetter) => {
    return async (shippingMethod: ShippingMethod): Promise<ShippingMethod> => {
        return await axiosData({
            method: 'post',
            url: `${SHIPPING_METHODS_URL}`,
			data: shippingMethod,
            cancelToken: getSource().token,
        });
    };
};

/**  */
export const DeleteShippingMethod = (getSource: SourceGetter) => {
    return async (shippingId: int): Promise<boolean> => {
        return await axiosData({
            method: 'post',
            url: `${SHIPPING_METHODS_URL}/${shippingId}`,
            cancelToken: getSource().token,
        });
    };
};
