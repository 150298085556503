import axios, { CancelTokenSource } from 'axios';
import { useEffect, useCallback, useRef, useMemo } from 'react';
import { snackbar } from '@imas/utils/snackbar';
import { WrappedApiFunction, ApiFunctionResult, ApiFunction, ApiError } from './types';

/* handles injecting error handler into the api result */
function injectErrorHandlerIntoResult <T extends ApiFunctionResult<any>>(result: T, handleError: (error: any, dem?: boolean) => any, dem?: boolean): T {
    if (result instanceof Promise) {
        return new Promise<any>((resolve, reject) => result.then(x => resolve(x)).catch(e => reject(handleError(e, dem)))) as T;
    } else {
        return {
            api: new Promise<any>((resolve, reject) => result.api.then(x => resolve(x)).catch(e => reject(handleError(e, dem)))),
            cache: new Promise<any>((resolve, reject) => result.cache.then(x => resolve(x)).catch(e => reject(handleError(e, dem)))),
        } as T;
    };
}

//an object which acts like a Cache Result & Promise but will never resolve to any value, only reject
class CacheResultPromise extends Promise<any> {
    constructor(error: any) {
        super((_, reject) => reject(error));
        this.api = new Promise<any>((_, reject) => reject(error));
        this.cache = new Promise<any>((_, reject) => reject(error));
    };

    api: Promise<any>;
    cache: Promise<any>;
}

/* returns a object which acts like a promise & cache result and will never resolve but only reject */
function createErrorReturn <T>(error: any): T {
    return new CacheResultPromise(error) as unknown as T;
} 

/* checks if any is a ApiError object */
export function isApiError(error: any): error is ApiError {
    if (typeof error !== "object") return false;
    if (error === null) return false;
    if (error?.isError && "message" in error && "data" in error) return true;
    return false; 
};

/* attempts to handle provided errors, returns null if the error was handled */
function handleApiErrors (error: any, disableErrorMessages?: boolean): any {
    if (error === null) return error;

    //if exception is an axios error
    else if (axios.isAxiosError(error)) {
        //get response data
        const responseData = error.response?.data;

        //if the error.response is a string then show the error message
        if (isApiError(responseData)) {
            //show a snackbar with the error message
            if (disableErrorMessages !== true) snackbar.enqueue(responseData.message, { variant: "error"});

            //return the responseData
            return responseData;
        };
    };

    //return the exception
    return error;
};

//useApi options 
export interface UseApiOptions {
    disableErrorMessages?: boolean,
};

/**
 * useCancelableApi hook
 * 
 * Contains the implementation of the useApi hook with 1 extra return result, a function which can be used to cancel any ongoing requests. This
 * is used in implementation of other api hooks like useLoadApi but is not really needed in most other cases so this is it's own hook.
 */
export function useCancelableApi <A extends any[], R extends ApiFunctionResult<any>>(wrappedApiFunction: WrappedApiFunction<A, R>, options?: UseApiOptions) {
    //list of cancel tokens for requests
    const sourcesRef = useRef<CancelTokenSource[]>([]);

    //options ref
    const optionsRef = useRef<UseApiOptions>();
	optionsRef.current = options;

	//wrappedApiFunctionRef
	const wrappedApiFunctionRef = useRef(wrappedApiFunction);
	wrappedApiFunctionRef.current = wrappedApiFunction;
	
    //when called cancel all currently active requests
    const cancelRequests = useCallback(() => {
        //cancel each request
        sourcesRef.current.forEach(x => x.cancel());

        //clear the array
        sourcesRef.current = [];
    }, []);

    //useEffect for canceling ongoing calls automatically on un-mount
    useEffect(() => { return cancelRequests(); }, [cancelRequests]);

    //creates a source, adds it to the sourcesRef and returns the source token
    const getSource = useCallback(() => {
        //create source
        const source = axios.CancelToken.source();

        //add it to the sourcesRef
        sourcesRef.current  = [...sourcesRef.current, source];

        //return new source
        return source;
    }, []);

    //get an equivalent of the unwrapped API which has been injected with error handling middleware
    const api = useMemo((): ReturnType<WrappedApiFunction<A, R>> => {
        //get unwrapped api
        const unwrapped = wrappedApiFunctionRef.current(getSource);

        //return a function with the same signature as the unwrapped function which includes middleware
        return (...args) => {
            //try catch any errors thrown by the api call (mostly network errors)
            try {
                return injectErrorHandlerIntoResult(unwrapped(...args), handleApiErrors, optionsRef.current?.disableErrorMessages);
            } catch(error) {
                //returns a stand in object which acts like both a Promise and CacheResult and will always reject
                return createErrorReturn<R>(handleApiErrors(error, optionsRef.current?.disableErrorMessages));  
            };
        };
    }, [getSource]);

    //return the callable api function & a cancel function
    return {
        api: api,
        cancel: cancelRequests
    };
};

/**
 * useApi hook
 * 
 * Takes a WrappedApiFunction and UseAPiOptions as it two arguments and will return function which can be used to make API calls which are automatically
 * canceled when the component this hook is called in is unmounted.
 */
export function useApi<A extends any[], R extends ApiFunctionResult<any>>(wrappedApiFunction: WrappedApiFunction<A, R>, options?: UseApiOptions): ApiFunction<A, R> {
    const { api } = useCancelableApi(wrappedApiFunction, options);

    //return the actual API function
    return api;
};