import { makeStyles } from '@imas/styles';

const onCallScheduleStyles = makeStyles()((theme) => ({
    container: {
        padding: '10px',
        display: 'flex'
    },
    topContainer: {
        width: '100%'
    },
    scheduleContainer: {
        padding: '0 20px 0 10px'
    },
    onCallText: {
        margin: 'auto 10px',
    },
    onCallTextSkeleton: {
        textDecoration: 'none!important'
    },
    onCallScoresButton: {
        margin: 'auto 10px auto auto'
    }, 
    onCallEntry: {
        padding: '0 16px 0 16px'
    }
}));

export default onCallScheduleStyles;