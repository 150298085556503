import { useEffect, useState } from 'react';

//hook which takes a File/Blob and returns a Object URL while also handling cleanup
export const useFileAsObjectURL = (file: File | Blob | undefined) => {
    //object url
    const [objectURL, setObjectURL] = useState<string | undefined>();

    //hook which converts the File/Blob to an Object URL
    useEffect(() => {
        setObjectURL(undefined);
        if (file === undefined) return;

        //convert and update objectURL
        const objURL = URL.createObjectURL(file);
        setObjectURL(objURL);

        return () => URL.revokeObjectURL(objURL);
    }, [file]);

    return objectURL;
};