import { mapApi, ApiConfig } from '@imas/api';
import { ProposalPreLien, ProposalPreLienForm } from './types';

//proposal pre liens base url
const PROPOSAL_PRE_LIENS_URL = "/api/proposals/pre-liens";

/*  */
export const GetAllProposalPreLiens = mapApi((): ApiConfig<ProposalPreLien[]> => ({
	method: 'get',
	url: `${PROPOSAL_PRE_LIENS_URL}`,
}));

/*  */
export const GetProposalPreLien = mapApi((preLienId: int): ApiConfig<ProposalPreLien> => ({
	method: 'get',
	url: `${PROPOSAL_PRE_LIENS_URL}/${preLienId}`,
}));

/*  */
export const CreateProposalPreLien = mapApi((data: ProposalPreLienForm): ApiConfig<ProposalPreLien> => ({
	method: 'post',
	url: `${PROPOSAL_PRE_LIENS_URL}`,
	data,
}));

/*  */
export const UpdateProposalPreLien = mapApi((preLienId: int, data: ProposalPreLienForm): ApiConfig<ProposalPreLien> => ({
	method: 'put',
	url: `${PROPOSAL_PRE_LIENS_URL}/${preLienId}`,
	data,
}));

/*  */
export const DeleteProposalPreLien = mapApi((preLienId: int): ApiConfig<ProposalPreLien> => ({
	method: 'delete',
	url: `${PROPOSAL_PRE_LIENS_URL}/${preLienId}`,
}));