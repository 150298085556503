import { useLoadApi, usePromiseApi } from '@imas/api';
import { CreateFileAccessTicket, DownloadFile, DownloadFileURL, FileTableFile, FileTables, GetFile } from '@imas/api/files';
import { Alignment, XSkeleton } from '@imas/components/layout';
import { PDFViewer } from '@imas/utils/pdf';
import { Box, Typography } from '@mui/material';
import { forwardRef, memo, useImperativeHandle, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

export interface FileViewerLoaderProps {
	width: int;
	height: int;
};

const FileViewerLoaderSized = ({ width, height }: FileViewerLoaderProps) => { 
	return (
		<XSkeleton
			width={width}
			height={height}
			variant={"rectangular"}		
		/>
	);
};

export const FileViewerLoader = memo(() => { 
	return (
		<AutoSizer>
			{({ width, height }) => (
				<FileViewerLoaderSized width={width} height={height}/>
			)}
		</AutoSizer>
	);
});

//enum of suported file renderers 
export enum FileRenderer {
	/** Adobe PDF Embed Viewer */
	AdobePDF,
	/** Microsoft Office 365 iFrame Viewer */
	MicrosoftOffice,
	/** Google Docs iFrame Viewer */
	GoogleDocs,
	/** Native Browser File Viewer */
	NativeBrowser,
	/** No Compatable Viewer */
	NotSupported,
};


export interface FileViewerRendererRef {
	file?: FileTableFile;
	renderer?: FileRenderer;
};

export interface FileViewerRendererProps {
	table: FileTables;
	file: HierarchyId;
};

export const PdfViewerRenderer = ({ table, file, name }: FileViewerRendererProps & { name: string; }) => {
	//download the PDF file to view it
	const { data: download } = usePromiseApi(DownloadFile, [table, file], [table, file]);

	//render PDF Viewer with promise to file & file name
	return (
		<PDFViewer
			source={download}
			fileName={name}
		/>
	);
};

//if the file type is supported by the microsoft live embed viewer
const isMicrosoftViewerFile = (fileType: string | null): boolean => {
	if (fileType === null) return false;
	const type = fileType.toLowerCase();

	return (
		type === "doc" || type === "docx" || type === "xls" || type === "xlsx" || type === "ppt" || type === "pptx"
	);
};

export const MicrosoftFileViewer = ({ table, file, width, height }: FileViewerRendererProps & FileViewerLoaderProps) => {
	//get a file access ticket to the target file to be viewed
	const { data: ticket } = useLoadApi(CreateFileAccessTicket, [table, file], [table, file]);

	//render loader while access ticket is being made
	if (!ticket) return <FileViewerLoaderSized width={width} height={height}/>;

	//iframe to office 365 document viewer
	return (
		<iframe 
			src={`https://view.officeapps.live.com/op/embed.aspx?src=${ticket.url}`} 
			width={width} 
			height={height} 
			frameBorder={"0"}
		/>
	);
};

//if the file type is supported by the google docs embed viewer
const isGoogleViewerFile = (fileType: string | null): boolean => {
	if (fileType === null) return false;
	const type = fileType.toLowerCase();

	return (
		isMicrosoftViewerFile(fileType) ||
		type === "txt" || type === "csv" || type === "odt" || type === "odp"
	);
};

export const GoogleFileViewer = ({ table, file, width, height }: FileViewerRendererProps & FileViewerLoaderProps) => {
	//get a file access ticket to the target file to be viewed
	const { data: ticket } = useLoadApi(CreateFileAccessTicket, [table, file], [table, file]);

	//render loader while access ticket is being made
	if (!ticket) return <FileViewerLoaderSized width={width} height={height}/>;

	//iframe to google document viewer
	return (
		<iframe 
			src={`https://docs.google.com/viewer?embedded=true&url=${ticket.url}`} 
			width={width} 
			height={height} 
			frameBorder={"0"}
		/>
	);
};

//if the file type is supported by the native browser (images & video)
const isNativeViewerFile = (fileType: string | null): boolean => {
	if (fileType === null) return false;
	const type = fileType.toLowerCase();

	return (
		type === "bmp" || type === "jpg" || type === "jpeg" || type === "png" || type === "gif" || type === "webp" || type === "avif"
		|| type === "mp3" || type === "wav" || type === "weba" || type === "flac" || type === "oga"
		|| type === "mp4" || type === "mpeg" || type === "webm" || type === "ogv"
	);
};

export const NativeFileViewer = ({ table, file, contentType, width, height }: FileViewerRendererProps & FileViewerLoaderProps & { contentType: string; }) => {
	//get download URL
	const fileURL = useMemo(() => DownloadFileURL(table, file), [table, file]);

	//object element to display file
	return (
		<object 
			data={fileURL}
			type={contentType}
			width={width} 
			height={height}
			style={{ objectFit: 'contain' }}
		/>
	);
};

//get the suported file renderer based on the file type
const getFileRendererByType = (fileType: string | null): FileRenderer => {
	if (fileType === null) return FileRenderer.NotSupported;
	const type = fileType.toLowerCase();

	if (type === "pdf") return FileRenderer.AdobePDF;
	else if (isMicrosoftViewerFile(type)) return FileRenderer.MicrosoftOffice;
	else if (isGoogleViewerFile(type)) return FileRenderer.GoogleDocs;
	else if (isNativeViewerFile(type)) return FileRenderer.NativeBrowser;
	else return FileRenderer.NotSupported;
};

const _FileViewerRenderer = forwardRef<FileViewerRendererRef, FileViewerRendererProps & FileViewerLoaderProps>(({ table, file, width, height }, ref) => {
	//get info about the file
	const { data: fileInfo } = useLoadApi(GetFile, [table, file], [table, file], {});

	//get file renderer from type
	const fileRenderer = useMemo(() => fileInfo ? getFileRendererByType(fileInfo.fileType) : undefined, [fileInfo]);

	//imperative handle for ref
	useImperativeHandle(ref, () => ({
		file: fileInfo,
		renderer: fileRenderer,
	}), [fileInfo, fileRenderer]);

	//show loader while file information loads
	if (!fileInfo || fileRenderer === null) return <FileViewerLoaderSized width={width} height={height}/>;

	//based on the fileRenderer value show the correct renderer
	if (fileRenderer === FileRenderer.AdobePDF) return <Box sx={{ width, height, display: 'flex', flexDirection: 'column' }}><PdfViewerRenderer table={table} file={file} name={fileInfo.name}/></Box>;
	else if (fileRenderer === FileRenderer.MicrosoftOffice) return <MicrosoftFileViewer table={table} file={file} width={width} height={height}/>;
	else if (fileRenderer === FileRenderer.GoogleDocs) return <GoogleFileViewer table={table} file={file} width={width} height={height}/>;
	else if (fileRenderer === FileRenderer.NativeBrowser) return <NativeFileViewer table={table} file={file} contentType={fileInfo.contentType} width={width} height={height}/>;
	else {
		return (
			<Alignment column sx={{ width, height, color: 'text.primary' }}>
				<Typography variant={"h5"} sx={{ margin: '20px auto' }}>{`Preview Not Available.`}</Typography>
				<Typography variant={"body1"} sx={{ margin: '0 auto 10px auto' }}>{`There is no supported viewer for '${fileInfo.fileType}' files.`}</Typography>
			</Alignment>
		);
	}
});

export const FileViewerRenderer = memo(_FileViewerRenderer);

export interface FileViewerRef extends FileViewerRendererRef {};

export interface FileViewerProps extends FileViewerRendererProps {};

const _FileViewer = forwardRef<FileViewerRef, FileViewerProps>((props, ref) => {
	return (
		<AutoSizer>
			{({ width, height }) => (
				<FileViewerRenderer {...props} width={width} height={height} ref={ref}/>
			)}
		</AutoSizer>
	);
});

export const FileViewer = memo(_FileViewer);