import axios from "axios";
import { axiosData, axiosFile, objectToFormData, SourceGetter } from "@imas/api";
import { ProposalScope, ProposalScopeForm } from "./types";

//proposal scopes base url
const SCOPES_URL = "/api/proposals/scopes";

/* https://localhost:44363/swagger/index.html#/ProposalScopes/get_api_proposals_scopes */
export const GetProposalScopes = (getSource: SourceGetter) => {
    return async (): Promise<ProposalScope[]> => {
        return axiosData({
			method: 'get',
            url: `${SCOPES_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/* https://localhost:44363/swagger/index.html#/Scopes/get_api_proposals_scopes__scopeId_ */
export const GetProposalScope = (getSource: SourceGetter) => {
    return async (scopeId: int): Promise<ProposalScope | null> => {
        //make API call
        const result = await axios({
            method: 'get',
            url: `${SCOPES_URL}/${scopeId}`,
            cancelToken: getSource().token,
        });
        
        //if the status is 204 return null, otherwise return the result
        if (result.status === 204) return null;
        return result.data;
    };
};

export const GetScopePdf = (getSource: SourceGetter) => {
    return async (addScopeId: int): Promise<File> => {
        return axiosFile({
			method: 'get',
            url: `${SCOPES_URL}/${addScopeId}/pdf`,
            cancelToken: getSource().token,
		});
    };
};

/* https://localhost:44363/swagger/index.html#/Scopes/post_api_proposals_scopes */
export const CreateProposalScope = (getSource: SourceGetter) => {
    return async (data: ProposalScopeForm): Promise<ProposalScope> => {
        //make API call
        const result = await axios({
            method: 'post',
            url: SCOPES_URL,
            data: objectToFormData(data),
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};

/* https://localhost:44363/swagger/index.html#/Scopes/put_api_proposals_scopes__scopeId_ */
export const UpdateProposalScope = (getSource: SourceGetter) => {
    return async (scopeId: int, data: ProposalScopeForm): Promise<ProposalScope> => {
        //make API call
        const result = await axios({
            method: 'put',
            url: `${SCOPES_URL}/${scopeId}`,
            data: objectToFormData(data),
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};

/* https://localhost:44363/swagger/index.html#/Scopes/delete_api_proposals_scopes__scopeId_ */
export const DeleteProposalScope = (getSource: SourceGetter) => {
    return async (scopeId: int): Promise<ProposalScope> => {
        //make API call
        const result = await axios({
            method: 'delete',
            url: `${SCOPES_URL}/${scopeId}`,
            cancelToken: getSource().token,
        });

        //return result
        return result.data;
    };
};