import { Alignment, Spacer } from '@imas/components/layout';
import { useMuiFormContext, useMuiWatch } from '@imas/mui-form';
import { Check, Close } from '@mui/icons-material';
import { Typography, RadioGroup, FormControlLabel, Checkbox, Radio } from '@mui/material';
import { memo, useContext } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../../VehicleInspectionStyles';
import { VehicleInspectionFormContext } from '../../VehicleInspectionFormContext';


export interface VehicleInspectionHeaderProps {
}


export const VehicleInspectionHeader = memo(({} : VehicleInspectionHeaderProps) => {
	
	//styling
	const { classes } = VehicleInspectionStyles();

	//consume context
	const { hideCompletion, hideIgnore } = useContext(VehicleInspectionFormContext);

	return (
		<Alignment rowReverse>

			{hideIgnore ? null : 
				<>
					{/* <Spacer horizontal size={'10px'}/> */}
					<Typography className={classes.headerTypography} align={'center'} sx={{width: '48px'}}>{'Ignore'}</Typography>
					<Spacer horizontal size={'10px'}/>
				</>
			}

			{hideCompletion ? null : 
				<>
					{/* <Spacer horizontal size={'10px'}/> */}
					<Typography className={classes.headerTypography} align={'center'} sx={{width: '40px'}}>{'Fixed'}</Typography>
				</>
			}

			{/* <Typography className={classes.leftTypography}></Typography> */}
			<Typography className={classes.headerTypography} align={'center'} sx={{width:'38px'}}><Close/></Typography>
			<Typography className={classes.headerTypography} align={'center'} sx={{width:'38px'}}><Check/></Typography>
			<Typography className={classes.headerTypography} align={'center'} sx={{width:'38px'}}>{'N/A'}</Typography>

		</Alignment>
	);
});
