import { TimeSheetCertificationHour, TimeSheetDetail } from '@imas/api/time-sheet';
import { Alignment } from '@imas/components/layout';
import { TypedGridColumnHeaderParams } from '@imas/data-grid';
import { Box, Typography } from '@mui/material';
import { ArrowDownward as ArrowDownwardIcon, ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { useMemo } from 'react';
import { TimeSheetCertificationHourRM, TimeSheetWeekday, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE } from './types';
import Enumerable from 'linq';
import { getWeekRecordTotalHours, weekdayList, weekdayReverseMap } from '../../TimeSheetDetailsForm/TimeSheetDetailsFormUtils/utils';

interface HourColumnHeaderProps extends TypedGridColumnHeaderParams<TimeSheetCertificationHour, TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, any, 'row'> {
	detail?: TimeSheetDetail;
};

export const HourColumnHeader = () => {
	return null;
	// return (
	// 	<ArrowDownwardIcon sx={{ marginLeft: '3px'}}/>
	// );
};

const Hours = ({ val }: { val: string | number; }) => (
	<Box component={'span'} sx={{ display: 'inline-block', textAlign: 'center' }}>{typeof val === 'number' ? val.toFixed(2) : val}</Box>
);

const calcDayTotal = (details: TimeSheetCertificationHour[], field: TimeSheetWeekday): int => Enumerable.from(details).sum(x => x[field] ?? 0);

const TimeSheetCertificationHoursFooterWeekdayTotal = ({ weekday, total, certTotal }: { weekday: int; total: decimal | null; certTotal: int | null; }) => {
	return (
		<Alignment column sx={{ width: '50px', alignItems: 'center', '> p': { textAlign: 'center' } }}>
			<Typography 
				variant={'body2'}
				sx={{ color: (certTotal ?? 0) > (total ?? 0) ? 'error.main' : 'text.primary' }}
			>{
				(total === null || certTotal === null) ? <Hours val={'--'}/> : <Hours val={certTotal}/>}
			</Typography>
		</Alignment>
	);
};

export const TimeSheetCertificationHoursFooter = ({ detail, certificationHours }: { detail?: TimeSheetDetail, certificationHours?: TimeSheetCertificationHour[] }) => {
	//calculate footer totals
	const totals = useMemo(() => {
		if (!certificationHours) return weekdayList.map(() => null);

		//get totals for each weekday
		return weekdayList.map(x => calcDayTotal(certificationHours, x));
	}, [certificationHours]);

	const certHourTotal = useMemo(() => totals[0] === null ? null : Enumerable.from(totals).sum(), [totals]);
	const total = useMemo(() => detail ? getWeekRecordTotalHours(detail) : null, [detail]);

	return (
		<Alignment row sx={{ marginLeft: '35px', color: 'text.primary' }}>
			{/* Weekday Totals */}
			<Typography variant={'body2'} fontWeight={'bold'} sx={{ marginRight: '5px' }}>{'Total:'}</Typography>
			<Alignment row sx={{ marginTop: '1px' }}>
				{totals.map((certTotal, weekday) => (
					<TimeSheetCertificationHoursFooterWeekdayTotal weekday={weekday} total={detail ? (detail[weekdayReverseMap[weekday]] ?? 0) : null} certTotal={certTotal}/>
				))}
			</Alignment>
			
			<ArrowRightIcon sx={{ alignSelf: 'center', marginLeft: '-10px' }}/>

			{/* Total of Weekday Totals */}
			<Alignment row sx={{ marginTop: '1px' }}>
				<Typography 
					variant={'body2'} 
					sx={{ marginLeft: '5px', color: (certHourTotal ?? 0) > (total ?? 0) ? 'error.main' : 'text.primary' }}
				>{(total === null || certHourTotal === null) ? <Hours val={'--'}/> : <Hours val={certHourTotal}/>}</Typography>
			</Alignment>
		</Alignment>
	);
};