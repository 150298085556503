import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@imas/redux';
import { IMASSecrets } from '@imas/api/system';

//initial state type
interface SecretsState {
    value: IMASSecrets | null,
    status: string
};

/** Secrets Slice Initial State */
const initialState: SecretsState = {
    value: null,
    status: 'idle'
};

export const secretsSlice = createSlice({
    name: 'deviceInfo',
    initialState,
    reducers: {
        setSecrets: (state, action: PayloadAction<IMASSecrets | null>) => {
            state.value = action.payload;
        }
    }
});

export const { setSecrets } = secretsSlice.actions;

//selector functions
export const getReportingAuth = (state: RootState) => state.secrets.value?.reportingAuth ?? null;
export const getGoogleMapsKey = (state: RootState) => state.secrets.value?.googleMapsKey ?? null;

const secretsReducer = secretsSlice.reducer;
export { secretsReducer };