import { useMemo } from 'react';
import { useLoadApi } from '@imas/api';
import { GetFilePreview } from '@imas/api/files';
import { FileTables } from '@imas/api/files/types';
import { useFileAsObjectURL } from './useFileAsObjectURL';

interface UseFilePreviewURLOptions {
    table: FileTables, 
    quality?: number,
}

//takes either a File object or HierarchyId and returns an image which is a preview of the file
export const useFilePreviewURL = (file: File | HierarchyId, options: UseFilePreviewURLOptions): string | undefined => {
    //if the data is a HierarchyId then load the file from the server, if the data is not a HierarchyID this will never execute
    const { data: serverFilePreview } = useLoadApi(GetFilePreview, [typeof file === "string" ? file : "", options.table, 90, false], [file], { disabled: typeof file !== "string" });
    
    //if the file is not an image determine the preview file based on the file extension
    const nonImagePreviewURL = useMemo((): string | undefined => {
        if (typeof file === "string") return;
        
        //if the file mime type starts with image then return undefined
        if (file.type.toLowerCase().includes("image")) return;

        //get filename lower case & trimmed 
        const filename = file.name.trim().toLowerCase();
        let previewName = "not_found.webp";

        if (filename.endsWith("pdf")) previewName = "pdf.webp";
        else if (filename.endsWith("doc") || filename.endsWith("docx")) previewName = "word.webp";
        else if (filename.endsWith("xls") || filename.endsWith("xlsx")) previewName = "excel.webp";
        else if (filename.endsWith("ppt") || filename.endsWith("pptx")) previewName = "power-point.webp";
        else if (file.type.toLowerCase().startsWith("video")) previewName = "video.webp";
        else if (filename.endsWith("wpd")) previewName = "word-perfect.webp";
		else if (filename.endsWith("mp4")) previewName = "video.webp";

        return `/images/file-icons/${previewName}`;
    }, [file]);

    //get an objectURL for the preview file
    const filePreviewURL = useFileAsObjectURL(typeof file === "string" ? serverFilePreview : file);

	if (serverFilePreview?.type.endsWith('mp4')) return "/images/file-icons/video.webp";

    return nonImagePreviewURL ?? filePreviewURL;
};