import { useLoadApi } from '@imas/api';
import { Alignment, Spacer, XSkeleton } from '@imas/components/layout';
import { openPopup } from '@imas/utils/misc';
import { AppBar, Box, Divider, Paper, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { GetSignatureCharges, GetSignatureTrackingResult, GetSignedSignatureRequests, GetSOSignatureDetails, GetSOSignatureRequest } from 'src/api/service-order/client-signatures';
import MobileClientSignatureStyles from './MobileClientSignatureStyles';
import { ServiceOrderClientSignature } from './ServiceOrderClientSignature';
import { SuccessfullySigned } from './SuccessfullySigned';

export const openClientSignaturePage = (hashKey: string): Window | null => {
	return openPopup(`/sign-service-order/${hashKey}`, { name: 'Service Order Signature Page', height: 850, width: 1500,});
};

export const ClientSignaturePage = () => {
	//page title
    document.title = "IMAS";

	//component styling
	const { classes, deviceInfo } = MobileClientSignatureStyles();

	//get hashCode param from url
    const { hashKey } = useParams();

	//state variable
	const [signed, setSigned] = useState<boolean>(false);

	//get everything the signature page needs
	const { data: signatureDetails } = useLoadApi(GetSOSignatureDetails, [hashKey ?? ''], [hashKey], {disabled: !hashKey});
	const { data: signatureRequest, call: refreshSignatureRequest } = useLoadApi(GetSOSignatureRequest, [hashKey ?? ''], [hashKey], {disabled: !hashKey});
	const { data: signedRequests } = useLoadApi(GetSignedSignatureRequests, [hashKey ?? ''], [hashKey], {disabled: !hashKey});
	//const { data: serviceOrder } = useLoadApi(GetSignatureTrackingResult, [hashKey ?? ''], [hashKey], {disabled: !hashKey});
	//const { data: charges } = useLoadApi(GetSignatureCharges, [hashKey ?? ''], [hashKey], {disabled: !hashKey});

	//set the signed variable when signatureRequest loads
	useEffect(() => {
		if (!signatureRequest) return;
		setSigned(signatureRequest.signedDateTime !== null);
	}, [signatureDetails]);

	const loading = useMemo(() => {
		return signatureDetails === undefined || signatureRequest === undefined || signedRequests === undefined;
	}, [signatureDetails, signatureRequest, signedRequests]);

	const hashError = useMemo(() => {
		return signatureDetails === null || signatureRequest === null;
	}, [signatureDetails, signatureRequest]);

	const timeOutError = useMemo(() => {
		if (!signatureRequest) return true;
		const requestTime = moment(signatureRequest.requestDateTime);
		const currentTime = moment();
		return currentTime.isAfter(requestTime.add(5, 'days').endOf('day'));
	}, [signatureRequest]);

	//set the signed variable when signatureRequest loads
	/*
	useEffect(() => {
		if (!signatureRequest) return;
		setSigned(signatureRequest.signedDateTime !== null);
	}, [signatureRequest]);

	const loading = useMemo(() => {
		return signatureRequest === undefined || signedRequests === undefined || serviceOrder === undefined || charges === undefined;
	}, [signatureRequest, signedRequests, serviceOrder, charges]);

	const hashError = useMemo(() => {
		return signatureRequest === null || signedRequests === null || serviceOrder === null || charges === null;
	}, [signatureRequest, signedRequests, serviceOrder, charges]);

	const timeOutError = useMemo(() => {
		if (!signatureRequest) return true;
		const requestTime = moment(signatureRequest.requestDateTime);
		const currentTime = moment();
		return currentTime.isAfter(requestTime.add(5, 'days'));
	}, [signatureRequest]);
	*/

	return (
		<Alignment column flex>
				<AppBar position={"static"} color={"primary"} sx={{height: '56px'}}>
					<Alignment row sx={{margin: 'auto 0 auto 0'}}>

						{/*  PNL Image */}	
						<Box component={"img"} sx={{margin: '0 10px 0 10px', maxWidth: '75px', width: '-webkit-fill-available' }} src={"/images/Logo-Transparent.png"}/>
						<Typography className={classes.link} variant={'h5'} sx={{margin: 'auto 0 auto 10px'}}>{'Work Order'}</Typography>

					</Alignment>
					
				</AppBar>


				{/* Do Different things based on criteria */}
				{!hashKey ? (
					<Typography className={classes.pageText} color={'text-primary'} variant={'h5'}>{'404 - Page not found'}</Typography>
				) : loading ? (
					<div>
						<div>
							<XSkeleton variant={'text'} height={40} width={'80vw'} />
							<Divider />
						</div>
						<div>
							<Alignment row>
								<Skeleton variant={'text'} height={30} width={82} />
								<Spacer horizontal size={'5px'} />
								<Skeleton variant={'text'} height={30} width={81} />
								<Spacer horizontal size={'40px'} />
								<Skeleton variant={'text'} height={30} width={76} />
								<Spacer horizontal size={'5px'} />
								<Skeleton variant={'text'} height={30} width={81} />
								<Spacer horizontal size={'40px'} />
								<Skeleton variant={'text'} height={30} width={116} />
								<Spacer horizontal size={'5px'} />
								<Skeleton variant={'text'} height={30} width={81} />
							</Alignment>
							<Spacer vertical size={'15px'} />
							<Alignment row>
								<Skeleton variant={'text'} height={30} width={132} />
								<Spacer horizontal size={'5px'} />
								<Skeleton variant={'text'} height={30} width={93} />
								<Spacer horizontal size={'40px'} />
								<Skeleton variant={'text'} height={30} width={150} />
								<Spacer horizontal size={'5px'} />
								<Skeleton variant={'text'} height={30} width={20} />
								<Spacer horizontal size={'40px'} />
								<Skeleton variant={'text'} height={30} width={55} />
								<Spacer horizontal size={'5px'} />
								<Skeleton variant={'text'} height={30} width={175} />
							</Alignment>
							<Spacer vertical size={'10px'} />
						</div>
						<Spacer vertical size={'15px'} />
						<XSkeleton variant={'rectangular'} height={'100%'} fillFlex rounded />
					</div>
				) : hashError ? (
					<Typography className={classes.pageText} color={'text-primary'} variant={'h5'}>{'Invalid URL'}</Typography>
				) : signed ? (
					<SuccessfullySigned
						signatureRequest={signatureRequest!}
						//serviceOrder={serviceOrder!}
						signatureDetail={signatureDetails![0]}
					/>
				) : timeOutError ? (
					<Typography className={classes.pageText} color={'text-primary'} variant={'h5'}>{'Signature request has expired.'}</Typography>
				) : (
					<ServiceOrderClientSignature 
						signatureRequest={signatureRequest!}
						signedRequests={signedRequests!}
						//serviceOrder={serviceOrder!}
						//charges={charges!}
						signatureDetails={signatureDetails!}
						setSigned={(signed: boolean) => {
							refreshSignatureRequest();
							setSigned(signed);
						}}
					/>		
				)}

		</Alignment>
	);
};