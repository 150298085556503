import { axiosData, SourceGetter } from "@imas/api";
import { Country, State } from './types';

//states base url
const COUNTRY_URL = "/api/system/countries";

/**  */
export const GetCountries = (getSource: SourceGetter) => {
    return async (): Promise<Country[]> => {
        return await axiosData({
            method: 'get',
            url: `${COUNTRY_URL}`,
            cancelToken: getSource().token,
        });
    };
};
