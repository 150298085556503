import { alpha, IconButton, Menu } from '@mui/material';
import { ArrowBack, ArrowForward, ArrowUpward, DoubleArrow, Refresh } from '@mui/icons-material';
import { FolderInfoBreadcrumb } from '@imas/api/files/types';
import React, { useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react';
import { FileExplorerContext } from '../FileExplorerContext';
import { Alignment } from '@imas/components/layout';
import { NavigationBreadcrumb } from './NavigationBreadcrumb/NavigationBreadcrumb';
import { NavigationMenuItem } from './NavigationMenuItem/NavigationMenuItem';
import { makeStyles } from "@imas/styles";
import useSize from '@react-hook/size';

export const useStyles = makeStyles()((theme) => ({
    actionIcon: {
        padding: '6px 4px',
        borderRadius: 'unset',
        "& > svg": {
            height: '0.85em',
        },
    },
    navigationContainer: {
        border: `1px solid ${alpha(theme.palette.text.secondary, 0.3)}`, 
        width: '-webkit-fill-available',
        alignItems: 'center',
    },
}));

const FileExplorerNavigation = React.memo(() => {
    //component styling
    const { classes } = useStyles();

    //use explorer context
    const { breadcrumbs, navHistory, goBackHistory, navigate, goBack, undoGoBack, refresh } = useContext(FileExplorerContext);

    //orderedBreadcrumbs, a cached value, updated when navigating to a new folder
    const [orderedBreadcrumbs, setOrderedBreadcrumbs] = useState<FolderInfoBreadcrumb[]>([]);

    //get ordered breadcrumbs
    useEffect(() => {
        if (!breadcrumbs) return;

        setOrderedBreadcrumbs(
            breadcrumbs.sort((a, b) => {
                if (a.pathLocator === b.parentPathLocator) return -1;
                return 0;
            })
        );
    }, [breadcrumbs]);

    //if hiddenCrumbs menu should be open
    const [hiddenMenuOpen, setHiddenMenuOpen] = useState(false);

    //path container ref & width
    const pathContainerRef = useRef<HTMLDivElement>(null);
    const [pathContainerWidth] = useSize(pathContainerRef);

    //given the current pathContainerWidth calculate how many breadcrumbs can be shown
    const [hiddenCrumbs, pathCrumbs] = useMemo(() => {
        //crumb cost func
        const getCost = (name?: string) => ((name ?? "").length * 10) + 48;

        //get the starting width budget
        let widthBudget = pathContainerWidth - 34;

        //get a copy of orderedBreadcrumbs
        const crumbs = [...orderedBreadcrumbs];
        
        //list of visible crumbs
        let visibleCrumbs: FolderInfoBreadcrumb[] = [];
        
        //load first item into current & calculate it's width cost
        let current = crumbs.pop();
        let currentCost = getCost(current?.name);

        while (widthBudget > currentCost && current !== undefined) {
            //if the width budget has room for the current item then add it to visibleCrumbs and update the remaining budget
            visibleCrumbs = [current, ...visibleCrumbs];
            widthBudget = widthBudget - currentCost;

            //update the current item
            current = crumbs.pop();
            currentCost = getCost(current?.name);
        }

        //if the last current crumb was not allocated
        if (current !== undefined) {
            //check that visibleCrumbs has a least 1 item, if it does not add this to it
            if (visibleCrumbs.length === 0) visibleCrumbs = [current];
            //otherwise add it back to crumbs
            else crumbs.push(current);
        }

        return [crumbs, visibleCrumbs];
    }, [pathContainerWidth, orderedBreadcrumbs]);

    //go up to the parent directory
    const goUp = useCallback((crumbs: FolderInfoBreadcrumb[]) => {
        //get 2nd to last element in array
        const dest = crumbs[crumbs.length - 2];

        if (dest === undefined) return;
        else navigate(dest.name, dest.pathLocator);
    }, [navigate]);

    return (
        <Alignment row sx={{ padding: '5px 10px' }}>
            {/* Previous Folder */}
            <IconButton
                title={navHistory.length > 0 ? `Back to ${navHistory[navHistory.length - 1].name} (Alt + Left Arrow)` : undefined}
                disabled={navHistory.length < 1}
                size={"small"}
                className={classes.actionIcon}
                onClick={() => goBack()}
            ><ArrowBack/></IconButton>

            {/* Undo Previous Folder */}
            <IconButton
                title={goBackHistory.length > 0 ? `Forward to ${goBackHistory[goBackHistory.length - 1].name} (Alt + Right Arrow)` : undefined}
                disabled={goBackHistory.length < 1}
                size={"small"}
                className={classes.actionIcon}
                onClick={() => undoGoBack()}
            ><ArrowForward/></IconButton>

            {/* Go Up One Level */}
            <IconButton
                title={orderedBreadcrumbs.length > 1 ? `Up to "${orderedBreadcrumbs[orderedBreadcrumbs.length - 2].name}" (Alt + Up Arrow)` : undefined}
                disabled={orderedBreadcrumbs.length < 2}
                size={"small"}
                className={classes.actionIcon}
                onClick={() => goUp(orderedBreadcrumbs)}
            ><ArrowUpward/></IconButton>
            
            {/* Path Container */}
            <Alignment row className={classes.navigationContainer} ref={pathContainerRef}>
                {/* Hidden Crumbs Navigator */}
                {hiddenCrumbs.length > 0 ? (
                    <IconButton
                        size={"small"}
                        className={classes.actionIcon}
                        onClick={() => setHiddenMenuOpen(true)}
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                    ><DoubleArrow sx={{ color: 'text.secondary', height: '0.7em', transform: 'scaleX(-1)' }}/></IconButton>
                ) : null}

                {/* Popover Menu for Hidden Crumbs */}
                <Menu
                    anchorEl={pathContainerRef.current}
                    open={hiddenMenuOpen}
                    onClose={() => setHiddenMenuOpen(false)}
                    onClick={() => setHiddenMenuOpen(false)}
                    PaperProps={{ 
                        square: true,
                        sx: { borderRadius: 0, minWidth: '200px', "& ul": { padding: '4px'} }
                    }}
                    //sx={theme => ({ borderRadius: 0, backgroundColor: theme.palette.grey[400],  padding: '2px 0', "& ul": { padding: '2px 0'} })}
                >
                    {hiddenCrumbs.map(breadcrumb => (
                        <NavigationMenuItem crumb={breadcrumb}/>
                    ))}
                </Menu>

                {/* Path Breadcrumbs */}
                <Alignment row overflowHidden sx={{ marginRight: '30px', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {pathCrumbs.map((breadcrumb, i) => (
                        <NavigationBreadcrumb key={breadcrumb.pathLocator} data={breadcrumb} isLast={pathCrumbs[i + 1] === undefined}/>
                    ))}
                </Alignment>

                {/* Refresh Current Directory Button */}
                <IconButton
                    title={orderedBreadcrumbs.length > 0 ? `Refresh "${orderedBreadcrumbs[orderedBreadcrumbs.length - 1].name}" (F5)` : undefined}
                    disabled={orderedBreadcrumbs.length < 1}
                    size={"small"}
                    className={classes.actionIcon}
                    onClick={() => refresh(false)}
                    sx={{ marginLeft: 'auto' }}
                ><Refresh/></IconButton>
            </Alignment>
        </Alignment>
    );
});

export { FileExplorerNavigation };