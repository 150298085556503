import { ApiConfig, mapApi, SourceGetter } from '@imas/api';
import axios from 'axios';
import { vProposalReport } from './types';

export const GetProposalVReports = mapApi((proposalId?: int | null): ApiConfig<vProposalReport[]> => ({
	method: 'get',
	url: `api/proposals/${proposalId ?? -1}/v-reports`,
}));

export const GetProposalVReport = mapApi((reportId: int): ApiConfig<vProposalReport> => ({
	method: 'get',
	url: 'api/proposals/reporting-details/' + reportId.toString(),
}));

export const CreateProposalReport = (getSource: SourceGetter) => {
    return async (pronum: int, sourceID: HierarchyId, fileTableName: string): Promise<int | null> => {

        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'post',
            url: 'api/proposals/reporting-details/create/' + pronum.toString() + '/' + fileTableName,
            params: {
                sourceID: sourceID,
            },
            cancelToken: getSource().token,
        });

        //return result
        return result.data;
    };
};

/* Create Proposal Reports from Master Templates */
export const CreateTemplateProposalReports = (getSource: SourceGetter) => {
    return async (pronum: int, templateIds: int[]): Promise<boolean> => {

        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'post',
            url: 'api/proposals/reporting-details/create-reports',
            data: {
                pronum,
                templateIds,
            },
            cancelToken: getSource().token,
        });

        //return result
        return result.data;
    };
};

export const RenameProposalReport = (getSource: SourceGetter) => {
    return async (proreportid: int, sourceID: HierarchyId, newName: string): Promise<int | null> => {

        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'put',
            url: 'api/proposals/reporting-details/rename/' + proreportid.toString() + '/' + newName,
            params: {
                sourceID: sourceID,
            },
            cancelToken: getSource().token,
        });

		//return result
        return result.data;
    };
};

export const DuplicateProposalReport = mapApi((proreportid:int, name:string): ApiConfig<boolean> => ({
	method: 'post',
	url: 'api/proposals/reporting-details/duplicate/' + proreportid.toString() + '/' + name,
}));

export const DeleteProposalReport = mapApi((proreportid:int, deleteLink?:boolean): ApiConfig<void> => ({
	method: 'delete',
	url: 'api/proposals/reporting-details/delete/' + proreportid.toString() + '/' + (deleteLink ? 'true' : 'false'),
}));