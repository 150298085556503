import { AuthenticationDevice } from '@imas/api/auth';
import { getDeviceData } from './getDeviceData';
import { getDeviceUniqueId } from './getDeviceUniqueId';

//returns an instance of AuthenticationDevice for auth calls
export const getAuthDevice = async (): Promise<AuthenticationDevice> => {
    //get device data
    const deviceData = await getDeviceData();

    return {
        id: getDeviceUniqueId(),
        model: deviceData.model,
        platform: deviceData.platform,
        browser: deviceData.browser,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
    };
};