import { Moment } from "moment";

//shortcut type for () => string
type F<T> = () => T; 

export namespace IMAS_PAGES {
	export type INDEX = '/';
	export type LOGIN = '/login';
	export type LOGOUT = '/logout';



	export type DASHBOARD = '/dashboard';


	export type HOME = '/home';
	export type HOME_EMPLOYEE_DIRECTORY = `${HOME}/employee-directory`;
	export type HOME_EMPLOYEE_DIRECTORY_EMPLOYEE<ID extends int> = `${HOME_EMPLOYEE_DIRECTORY}/${ID}`;
	export type HOME_SYNC_EMPLOYEE_INFO = `${HOME}/sync-employee-info`;
	export type HOME_VEHICLES = `${HOME}/vehicles`;
	export type HOME_VEHICLES_VEHICLE<ID extends int> = `${HOME_VEHICLES}/${ID}`;
	export type HOME_VEHICLES_INSPECTION = `${HOME_VEHICLES}/inspection`;
	export type HOME_DOOR_ACCESS = `${HOME}/door-access`;
	export type HOME_NOTIFICATIONS = `${HOME}/notifications`;
	export type HOME_ORGCHART = `${HOME}/org-chart`

	export type CLIENTS = '/clients';
	export type CLIENT_REPORT_VIEWER<ID extends int> = `${CLIENTS}/${ID}/report-viewer`;
	export type CLIENT_LOCATIONS = '/clients/locations';
	export type CLIENT_LOCATION_REPORT_VIEWER<ID extends int> = `${CLIENT_LOCATIONS}/${ID}/report-viewer`;
	export type CLIENT_LOCATION_CONTACTS = '/clients/locations/contacts';
	export type CLIENT_LOCATION_CONTACT_REPORT_VIEWER<ID extends int> = `${CLIENT_LOCATION_CONTACTS}/${ID}/report-viewer`;
	export type CLIENT_PURCHASE_ORDERS = '/clients/purchase-orders';
	export type CLIENT_PURCHASE_ORDER_REPORT_VIEWER<ID extends int> = `${CLIENT_PURCHASE_ORDERS}/${ID}/report-viewer`;
	export type CLIENT_PURCHASE_ORDER_REVISION_REPORT_VIEWER<ID extends int> = `${CLIENT_PURCHASE_ORDERS}/revisions/${ID}/report-viewer`;

	export type CLIENTS_AND_CONTACTS = '/clients-and-contacts';
	export type CLIENTS_AND_CONTACTS_SEARCH = `${CLIENTS_AND_CONTACTS}/search`;
	export type CLIENTS_AND_CONTACTS_SEARCH_CLIENT<ID extends int> = `${CLIENTS_AND_CONTACTS_SEARCH}/${ID}`;
	export type CLIENTS_AND_CONTACTS_CONTACT_LIST = `${CLIENTS_AND_CONTACTS}/contact-lists`;

	export type PROPOSALS = '/proposals';
	export type PROPOSALS_TRACKING = `${PROPOSALS}/proposal-tracking`;
	export type PROPOSALS_FEE_SCHEDULE = `${PROPOSALS}/fee-schedule`;
	export type PROPOSALS_FEE_SCHEDULE_SERVICE<ID extends int> = `${PROPOSALS_FEE_SCHEDULE}/${ID}`;
	export type PROPOSALS_SERVICES = `${PROPOSALS}/services`;
	export type PROPOSALS_SCOPE_TEMPLATES = `${PROPOSALS}/scope-templates`;
	export type PROPOSALS_REPORT_TEMPLATES = `${PROPOSALS}/report-templates`;
	export type PROPOSALS_WELD_TESTS = `${PROPOSALS}/weld-tests`;
	export type PROPOSALS_PROJECTS = `${PROPOSALS}/project-tracking`;
	export type PROPOSAL_PROJECT_SUMMARY_REPORT_VIEWER<ID extends int> = `${PROPOSALS}/projects/${ID}/report-viewer`;
	
	export type SERVICE_ORDERS = '/service-orders';
	export type SERVICE_ORDER_TRACKING = `${SERVICE_ORDERS}/tracking`;
	export type SERVICE_ORDER_DETAILS<ID extends int> = `${SERVICE_ORDERS}/details/${ID}`;
	export type SERVICE_ORDER_WORK_PLACE_REVIEWS_LIST<ID extends int> = `${SERVICE_ORDERS}/${ID}/work-place-reviews`;
	export type SERVICE_ORDER_WORK_PLACE_REVIEWS = `${SERVICE_ORDERS}/work-place-reviews`;
	export type SERVICE_ORDER_WORK_PLACE_REVIEWS_REPORT_VIEW<ID extends int> = `${SERVICE_ORDERS}/work-place-reviews/${ID}/report-viewer`;
	export type SERVICE_ORDER_LAB_ITEMS = `${SERVICE_ORDERS}/lab-items`
	export type SERVICE_ORDER_LAB_ITEM_EDITOR<ID extends int> = `${SERVICE_ORDERS}/lab-items/${ID}`

	export type CERTS_AND_QC = '/certs-and-qc';
	export type OPERATIONAL_PROCEDURES = `${CERTS_AND_QC}/procedures`;

	export type MANAGE = '/manage';
	export type MANAGE_SCORE_CARD = `${MANAGE}/score-card`;
	export type MANAGE_KEY_MEASURE<ID extends int> = `${MANAGE}/key-measure/${ID}`;
	export type MANAGE_MESSAGING = `${MANAGE}/messaging`;
	export type MANAGE_TIME_OFF_SCHEDULE = `${MANAGE}/time-off-schedule`
	export type MANAGE_COACHING_SESSIONS = `${MANAGE}/coaching-sessions`;
	export type TIME_SHEET_APPROVAL = `${MANAGE}/time-sheet-approval`;
	export type TIME_TRACKER = `${MANAGE}/time-tracker`;
	export type APPLICANT_TRACKER = `${MANAGE}/applicant-tracker`;

	export type USER = '/user';
	export type USER_PROFILE = `${USER}/profile`;
	export type USER_TIME_OFF = `${USER}/time-off`;
	export type USER_TIME_SHEETS = `${USER}/time-sheets`;
	export type USER_TIME_SHEETS_WEEK = `${USER_TIME_SHEETS}/${string}`;
	export type USER_TIME_SHEETS_WEEK_DAY<DAY extends string> = `${USER_TIME_SHEETS}/${string}/${DAY}`;
	export type USER_EXPENSE_REPORTS = `${USER}/expense-reports`;
	export type USER_EXPENSE_REPORTS_REPORT<ID extends int> = `${USER_EXPENSE_REPORTS}/${ID}`;
	export type USER_SETTINGS = `${USER}/settings`;
	export type USER_FEEDBACK = `${USER}/feedback`;
	
	export type ADMIN = '/admin';
	export type ADMIN_PRODUCTS = `${ADMIN}/products`;
	export type ADMIN_INVENTORY = `${ADMIN}/inventory`;
	export type ADMIN_INVENTORY_DELETE= `${ADMIN_INVENTORY}/delete`


	//app page structure def
	export type PAGES_STRUCT = {
		INDEX: F<INDEX>;
		LOGIN: F<LOGIN>;
		LOGOUT: F<LOGOUT>;


		DASHBOARD: F<DASHBOARD>;
		HOME: {
			(): HOME;
			EMPLOYEE_DIRECTORY: {
				(): HOME_EMPLOYEE_DIRECTORY;
				EMPLOYEE: <ID extends int>(employeeId: ID) => HOME_EMPLOYEE_DIRECTORY_EMPLOYEE<ID>;
			};
			SYNC_EMPLOYEE_INFO: {
				(): HOME_SYNC_EMPLOYEE_INFO;
			},
			VEHICLES: {
				(): HOME_VEHICLES;
				VEHICLE: <ID extends int>(calItemId: ID) => HOME_VEHICLES_VEHICLE<ID>;
				INSPECTION: {(): HOME_VEHICLES_INSPECTION};
			};
			DOOR_ACCESS: {
				(): HOME_DOOR_ACCESS;
			};
			NOTIFICATIONS: {
				(): HOME_NOTIFICATIONS;
			};
			ORGCHART: {
				(): HOME_ORGCHART;
			};
		},
		CLIENTS: {
			(): CLIENTS;
			REPORT_VIEW: <ID extends int>(workPlaceReviewId: ID) => CLIENT_REPORT_VIEWER<ID>;
			LOCATIONS: {
				(): CLIENT_LOCATIONS;
				REPORT_VIEW: <ID extends int>(locationId: ID) => CLIENT_LOCATION_REPORT_VIEWER<ID>;
				CONTACTS: {
					(): CLIENT_LOCATION_CONTACTS;
					REPORT_VIEW: <ID extends int>(contactId: ID) => CLIENT_LOCATION_CONTACT_REPORT_VIEWER<ID>;
				}
			},
			PURCHASE_ORDERS: {
				() : CLIENT_PURCHASE_ORDERS;
				REPORT_VIEW: <ID extends int>(poNumId: ID) => CLIENT_PURCHASE_ORDER_REPORT_VIEWER<ID>;
				REVISIONS: {
					REPORT_VIEW: <ID extends int>(poNumRevId: ID) => CLIENT_PURCHASE_ORDER_REVISION_REPORT_VIEWER<ID>;
				}
			}
		},
		CLIENTS_AND_CONTACTS: {
			(): CLIENTS_AND_CONTACTS;
			SEARCH: {
				(): CLIENTS_AND_CONTACTS_SEARCH;
				<ID extends int>(clientId: ID): CLIENTS_AND_CONTACTS_SEARCH_CLIENT<ID>;
			};
			CONTACT_LISTS:{(): CLIENTS_AND_CONTACTS_CONTACT_LIST;}
		};
		PROPOSALS: {
			(): PROPOSALS;
			TRACKING: F<PROPOSALS_TRACKING>;
			FEE_SCHEDULE: {
				(): PROPOSALS_FEE_SCHEDULE;
				SERVICE: <ID extends int>(serviceId: ID) => PROPOSALS_FEE_SCHEDULE_SERVICE<ID>;
			};
			SERVICES: F<PROPOSALS_SERVICES>;
			SCOPE_TEMPLATES: F<PROPOSALS_SCOPE_TEMPLATES>;
			REPORT_TEMPLATES: F<PROPOSALS_REPORT_TEMPLATES>;
			WELD_TESTS: F<PROPOSALS_WELD_TESTS>;
			PROJECTS: {
				(): PROPOSALS_PROJECTS;
				REPORT_VIEW: <ID extends int>(projectId: ID) => PROPOSAL_PROJECT_SUMMARY_REPORT_VIEWER<ID>;
			}
		};
		SERVICE_ORDERS: {
			(): SERVICE_ORDERS;
			TRACKING: () => SERVICE_ORDER_TRACKING;
			DETAILS: <ID extends int>(uniqueId: ID) => SERVICE_ORDER_DETAILS<ID>;
			WORK_PLACE_REVIEWS_LIST: <ID extends int>(serviceOrderId: ID) => SERVICE_ORDER_WORK_PLACE_REVIEWS_LIST<ID>;
			WORK_PLACE_REVIEWS: {
				(): SERVICE_ORDER_WORK_PLACE_REVIEWS;
				REPORT_VIEW: <ID extends int>(workPlaceReviewId: ID) => SERVICE_ORDER_WORK_PLACE_REVIEWS_REPORT_VIEW<ID>;
			};
			LAB_ITEMS: {
				(): SERVICE_ORDER_LAB_ITEMS;
				EDITOR: <ID extends int>(labnum: ID) => SERVICE_ORDER_LAB_ITEM_EDITOR<ID>;
			}
		};
		CERTS_AND_QC: {
			(): CERTS_AND_QC;
			OPERATIONAL_PROCEDURES: F<OPERATIONAL_PROCEDURES>;
		}
		MANAGE: {
			(): MANAGE;
			SCORE_CARD: F<MANAGE_SCORE_CARD>
			KEY_MEASURE: <ID extends int>(keyMeasureId: ID) => MANAGE_KEY_MEASURE<ID>;
			MESSAGING: F<MANAGE_MESSAGING>;
			TIME_OFF_SCHEDULE: F<MANAGE_TIME_OFF_SCHEDULE>;
			COACHING_SESSIONS: F<MANAGE_COACHING_SESSIONS>;
			TIME_SHEET_APPROVAL: F<TIME_SHEET_APPROVAL>;
			TIME_TRACKER: F<TIME_TRACKER>;
			APPLICANT_TRACKER: F<APPLICANT_TRACKER>;

		};
		USER: {
			(): USER;
			PROFILE: F<USER_PROFILE>;
			TIME_OFF: F<USER_TIME_OFF>,
			TIME_SHEETS: {
				(): USER_TIME_SHEETS;
				WEEK: {
					(week: Moment): USER_TIME_SHEETS_WEEK;
					DAY: <D extends "monday" | "tuesday" | "wednesday" | "thursday" | "friday" |"saturday" | "sunday">(week: Moment, day: D) => USER_TIME_SHEETS_WEEK_DAY<D>;
				};
			};
			EXPENSE_REPORTS: {
				(): USER_EXPENSE_REPORTS;
				REPORT: <ID extends int>(reportId: ID) => USER_EXPENSE_REPORTS_REPORT<ID>;
			};
			SETTINGS: {
				(): USER_SETTINGS;
			};
			FEEDBACK: F<USER_FEEDBACK>;
		};
		ADMIN: {
			(): ADMIN;
			PRODUCTS: F<ADMIN_PRODUCTS>
			INVENTORY: {
				(): ADMIN_INVENTORY
				DELETE: {(): ADMIN_INVENTORY_DELETE};
			};
			
		}
	};
};

/** MAP A PAGE */
const MP = <U extends string, P extends {}>(url: (...args: any[]) => U, pages: P): { (): U; } & P => {
	return Object.assign(url, pages);
};

export const PAGES: IMAS_PAGES.PAGES_STRUCT = {
	INDEX: () => '/',
	LOGIN: () => '/login',
	LOGOUT: () => '/logout',
	DASHBOARD: () => '/dashboard',
	HOME: Object.assign(() => '/home', {
		EMPLOYEE_DIRECTORY: Object.assign(() => '/home/employee-directory', {
			EMPLOYEE: <ID extends int>(employeeId: ID): IMAS_PAGES.HOME_EMPLOYEE_DIRECTORY_EMPLOYEE<ID> => `/home/employee-directory/${employeeId}`,
		}),
		SYNC_EMPLOYEE_INFO: Object.assign(() =>'/home/sync-employee-info', {}),
		VEHICLES: Object.assign(() =>'/home/vehicles', {
			VEHICLE: <ID extends int>(calItemId: ID): IMAS_PAGES.HOME_VEHICLES_VEHICLE<ID> => `/home/vehicles/${calItemId}`,
			INSPECTION: MP(() => '/home/vehicles/inspection', {}),
		}),
		DOOR_ACCESS: Object.assign(() =>'/home/door-access', {}),
		NOTIFICATIONS: Object.assign(() =>'/home/notifications', {}),
		ORGCHART: Object.assign(() => '/home/org-chart', {}),
	}),
	CLIENTS: MP(() => '/clients', {
		REPORT_VIEW: <ID extends int>(clientId: ID): IMAS_PAGES.CLIENT_REPORT_VIEWER<ID> => `/clients/${clientId}/report-viewer`, 
		LOCATIONS: MP(() => '/clients/locations', {
			REPORT_VIEW: <ID extends int>(locationId: ID): IMAS_PAGES.CLIENT_LOCATION_REPORT_VIEWER<ID> => `/clients/locations/${locationId}/report-viewer`, 
			CONTACTS: MP(() => '/clients/locations/contacts', {
				REPORT_VIEW: <ID extends int>(contactId: ID): IMAS_PAGES.CLIENT_LOCATION_CONTACT_REPORT_VIEWER<ID> => `/clients/locations/contacts/${contactId}/report-viewer`, 
			}),
		}),
		PURCHASE_ORDERS: MP(() => '/clients/purchase-orders', {
			REPORT_VIEW: <ID extends int>(poNumId: ID): IMAS_PAGES.CLIENT_PURCHASE_ORDER_REPORT_VIEWER<ID> => `/clients/purchase-orders/${poNumId}/report-viewer`, 
			REVISIONS: MP(() => '/clients/purchase-orders/revisions', {
				REPORT_VIEW: <ID extends int>(poNumRevId: ID): IMAS_PAGES.CLIENT_PURCHASE_ORDER_REVISION_REPORT_VIEWER<ID> => `/clients/purchase-orders/revisions/${poNumRevId}/report-viewer`, 
			}),
		}),
	}),
	CLIENTS_AND_CONTACTS: MP(() => '/clients-and-contacts', {
		SEARCH: Object.assign(
			() => '/clients-and-contacts/search', 
			<ID extends int>(clientId: ID): IMAS_PAGES.CLIENTS_AND_CONTACTS_SEARCH_CLIENT<ID> => `/clients-and-contacts/search/${clientId}`,
			{}
		),
		CONTACT_LISTS: Object.assign(
			() => '/clients-and-contacts/contact-lists',
		)
	}),
	ADMIN: MP(() => '/admin', {
		PRODUCTS: () => '/admin/products',
		INVENTORY: MP(() => '/admin/inventory', {
			DELETE: MP(() => `/admin/inventory/delete`, {}) ,
		}), 
	}),
	PROPOSALS: MP(() => '/proposals', {
		TRACKING: () => '/proposals/proposal-tracking',
		SCOPE_TEMPLATES: () => '/proposals/scope-templates',
		REPORT_TEMPLATES: () => '/proposals/report-templates',
		WELD_TESTS: () => '/proposals/weld-tests',
		FEE_SCHEDULE: MP(() => '/proposals/fee-schedule', {
			SERVICE: <ID extends int>(serviceId: ID): IMAS_PAGES.PROPOSALS_FEE_SCHEDULE_SERVICE<ID> => `/proposals/fee-schedule/${serviceId}`,
		}),
		SERVICES: () => '/proposals/services',
		PROJECTS: MP(() => '/proposals/project-tracking', {
			REPORT_VIEW: <ID extends int>(projectId: ID): IMAS_PAGES.PROPOSAL_PROJECT_SUMMARY_REPORT_VIEWER<ID> => `/proposals/projects/${projectId}/report-viewer`,
		})
	}),
	SERVICE_ORDERS: MP(() => '/service-orders', {
		TRACKING: () => '/service-orders/tracking',
		DETAILS: <ID extends int>(uniqueId: ID): IMAS_PAGES.SERVICE_ORDER_DETAILS<ID> => `/service-orders/details/${uniqueId}`, 
		WORK_PLACE_REVIEWS_LIST: <ID extends int>(serviceOrderId: ID): IMAS_PAGES.SERVICE_ORDER_WORK_PLACE_REVIEWS_LIST<ID> => `/service-orders/${serviceOrderId}/work-place-reviews`, 
		WORK_PLACE_REVIEWS: MP(() => '/service-orders/work-place-reviews', {
			REPORT_VIEW: <ID extends int>(workPlaceReviewId: ID): IMAS_PAGES.SERVICE_ORDER_WORK_PLACE_REVIEWS_REPORT_VIEW<ID> => `/service-orders/work-place-reviews/${workPlaceReviewId}/report-viewer`, 
		}),
		LAB_ITEMS: MP(() => '/service-orders/lab-items', {
			EDITOR: <ID extends int>(labnum: ID): IMAS_PAGES.SERVICE_ORDER_LAB_ITEM_EDITOR<ID> => `/service-orders/lab-items/${labnum}`
		})
	}),
	CERTS_AND_QC: MP(() => '/certs-and-qc', {
		OPERATIONAL_PROCEDURES: () => '/certs-and-qc/procedures',
		},
	),
	MANAGE: MP(() => '/manage', {
		SCORE_CARD: () => '/manage/score-card',
		KEY_MEASURE: <ID extends int>(keyMeasureId: ID):IMAS_PAGES.MANAGE_KEY_MEASURE<ID> => `/manage/key-measure/${keyMeasureId}`,
		MESSAGING: () => '/manage/messaging',
		TIME_OFF_SCHEDULE: () => '/manage/time-off-schedule',
		COACHING_SESSIONS: () => '/manage/coaching-sessions',
		TIME_SHEET_APPROVAL: () => '/manage/time-sheet-approval',
		TIME_TRACKER: () => '/manage/time-tracker',
		APPLICANT_TRACKER: () => '/manage/applicant-tracker'
	}),
	USER: MP(() => '/user', {
		PROFILE: () => '/user/profile',
		TIME_OFF: () => '/user/time-off',
		TIME_SHEETS: MP(() => '/user/time-sheets', {
			WEEK: MP((week: Moment): IMAS_PAGES.USER_TIME_SHEETS_WEEK => `/user/time-sheets/${week.format("M-D-YYYY")}`, {
				DAY: <D extends "monday" | "tuesday" | "wednesday" | "thursday" | "friday" |"saturday" | "sunday">(week: Moment, day: D): IMAS_PAGES.USER_TIME_SHEETS_WEEK_DAY<D> => `/user/time-sheets/${week.format("M-D-YYYY")}/${day}`,
			}),
		}),
		EXPENSE_REPORTS: MP(() => '/user/expense-reports', {
			REPORT: <ID extends int>(reportId: ID): IMAS_PAGES.USER_EXPENSE_REPORTS_REPORT<ID> => `/user/expense-reports/${reportId}`,
		}),
		SETTINGS: MP(() => '/user/settings', {}),
		FEEDBACK: () => '/user/feedback',
	}),
	
};