/** Functions for opening an editor in a popup window. */

import { openEditorWindow } from '@imas/utils/editor';

export { openClientEditor } from './ClientsAndContacts/ClientEditor/ClientEditor';
export { openClientLocationEditor } from './ClientsAndContacts/ClientLocationEditor/ClientLocationEditor';
export { openClientLocationContactEditor } from './ClientsAndContacts/ClientLocationContactEditor/ClientLocationContactEditor';

export const openProposalEditor = ({ proposalId }: { proposalId: int; }): Window | null => {
	return openEditorWindow(
		IMAS.PAGES.PROPOSALS(), 
		{ id: proposalId }, 
		{ height: window.screen.height, width: window.screen.width, name: `Proposal #${proposalId} Edit Form` }
	);
};

export { openServiceOrderWorkPlaceReviewEditor } from './ServiceOrders/ServiceOrderWorkPlaceReviews/ServiceOrderWorkPlaceReviewEditor/ServiceOrderWorkPlaceReviewEditor';