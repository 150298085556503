import { TimeOffRequestFormData } from 'src/api/types/form/TimeOffRequestFormData';
import moment, { Moment } from 'moment';
import { SourceGetter, axiosData } from  '@imas/api';
import { EmployeeAbsence, EmployeeTimeOffResult, EmployeeTimeOffTrackingInfo, TimeOffCount, TimeOffStatusUpdateRequest, vEmployeeAbsense } from "src/api/types/api/employee/employee";
import axios from 'axios';

/**
 *  Get a list of TimeOffResults given some filters
 */
export const _GetTimeOffInfo = (getSource: SourceGetter) => {
    //return the wrapped API function
    return ({ startDate, endDate, employeeId, itemType, hourType, fullView }: { startDate?: Moment | null, endDate?: Moment | null, employeeId: int | null, itemType?: int | null, hourType?: int | null, fullView?: boolean }): Promise<EmployeeTimeOffResult[]> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeTimeOffResult[]>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'get',
                url: '/api/employee/time-off/results',
                params: {
                    startDate: startDate ? startDate.format() : moment().subtract(1, "year").format('YYYY-MM-DD'),
                    endDate: endDate ? endDate.format() : moment().add(1, "year").format('YYYY-MM-DD'),
                    employeeId: employeeId,
                    itemType: itemType ?? null,
                    hourType: hourType ?? null,
                    fullView: fullView ?? false
                },
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};


/*
    Get a a TimeOffTrackingInfo object given a userId 
*/
export const _GetTimeOffTrackingInfo = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (employeeId: int): Promise<EmployeeTimeOffTrackingInfo> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeTimeOffTrackingInfo>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'get',
                url: '/api/employee/time-off/tracking/' + employeeId,
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/** Gets all vEmployee absenses in a date range */
export const GetvEmployeeAbsenses = (getSource: SourceGetter) => {
    return async (startDate?: Moment | null, endDate?: Moment | null): Promise<vEmployeeAbsense[]> => {
        return await axiosData({
            method: 'get',
            url: `/api/employee/time-off/v-absence`,
            params: {
                startDate: startDate ? startDate.format() : moment().subtract(5, "years").format('YYYY-MM-DD'),
                endDate: endDate ? endDate.format() : moment().add(5, "years").format('YYYY-MM-DD'),
            },
            cancelToken: getSource().token,
        });
    };
};

/** Gets a vEmployee absense */
export const GetvEmployeeAbsense = (getSource: SourceGetter) => {
    return async (eventId: int): Promise<vEmployeeAbsense> => {
        return await axiosData({
            method: 'get',
            url: `/api/employee/time-off/v-absence/${eventId}`,
            cancelToken: getSource().token,
        });
    };
};

/**
 *  Get a specific Employee Absence entry  GetEmployeeAbsence
 */
export const _GetAbsence = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (entryId: int): Promise<EmployeeAbsence | null> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeAbsence | null>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'get',
                url: '/api/employee/time-off/absence/' + entryId,
                cancelToken: source.token
            }).then(response => {
                if (response.status === 204) return resolve(null);

                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Delete a specific time off request
 */
 export const _DeleteAbsence = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (entryId: int): Promise<boolean | null> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<boolean | null>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'delete',
                url: '/api/employee/time-off/absence/' + entryId,
                cancelToken: source.token
            }).then(response => {
                if (response.status === 204) return resolve(null);

                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Get time off counts
 */
export const _GetTimeOffCounts = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (startDate: Moment, endDate: Moment): Promise<TimeOffCount[]> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<TimeOffCount[]>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'get',
                url: '/api/employee/time-off/counts',
                params: {
                    startDate: startDate.format(),
                    endDate: endDate.format()
                },
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Create a new time off request
 */
export const _CreateTimeOffRequest = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (data: TimeOffRequestFormData, managerOverride?: boolean): Promise<EmployeeAbsence> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeAbsence>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'post',
                url: '/api/employee/time-off/request',
                data: {
                    employeeId: data.employeeId,
                    type: data.type,
                    fromDate: data.fromDate.format('YYYY-MM-DD'),
                    fromTime: data.fromTime === "" ? null : data.fromTime,
                    toDate: data.toDate.format('YYYY-MM-DD'),
                    toTime: data.toTime === "" ? null : data.toTime,
                    description: data.description,
                    notes: data.notes,
                    managerOverride: managerOverride ?? false
                },
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Edit a time off request
 */
export const _EditTimeOffRequest = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (requestId: int, data: TimeOffRequestFormData, managerOverride?: boolean): Promise<EmployeeAbsence> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeAbsence>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'post',
                url: '/api/employee/time-off/request/' + requestId,
                data: {
                    employeeId: data.employeeId,
                    type: data.type,
                    fromDate: data.fromDate.format(),
                    fromTime: data.fromTime === "" ? null : data.fromTime,
                    toDate: data.toDate.format(),
                    toTime: data.toTime === "" ? null : data.toTime,
                    description: data.description,
                    notes: data.notes,
                    managerOverride: managerOverride ?? false
                },
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  EditTimeOffNotes
 */
export const EditTimeOffNotes = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (requestId: int, data: TimeOffRequestFormData): Promise<EmployeeAbsence> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeAbsence>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'post',
                url: '/api/employee/time-off/request/' + requestId + '/notes',
                data: {
                    notes: data.notes
                },
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

interface StatusUpdateResponse {
    resultCode: int | null,
    resultNote: string,
}
/**
 *  Change the approval status of a time off request
 */
export const TimeOffRequestStatusUpdate = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (updateRequest: TimeOffStatusUpdateRequest): Promise<StatusUpdateResponse> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<StatusUpdateResponse>((resolve, reject) => {
            //make API request
            axios({ 
                method: 'put',
                url: '/api/employee/time-off/request/status',
                data: updateRequest,
                cancelToken: source.token
            }).then(response => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};