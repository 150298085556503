import { makeDeviceStyles } from '@imas/styles';

const onCallScoresStyles = makeDeviceStyles()((theme) => ({
    onCallIcon: {
        margin: 'auto 10px auto 10px',
        padding: '10px',
        display: 'flex'
    },
    onCallText: {
        margin: 'auto auto auto 10px',
        fontWeight: 'bold',
    },
    scoreText: {
        fontWeight: 'bold',
        marginRight: '15px'
    },
    scoreItem: {
        display: 'flex',
        flexDirection: 'row',
        padding: '2px 16px 2px 16px'
    }
}));

export default onCallScoresStyles;