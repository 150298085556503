import { useLoadApi } from '@imas/api';
import { Alignment } from '@imas/components/layout';
import { isPopup, openPopup } from '@imas/utils/misc';
import { SSRSPDFViewer } from '@imas/utils/pdf';
import { useParams, Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import { getEmployeeData, getUserRoles } from '@imas/redux';
import { FileViewerLoader } from '@imas/utils/files';
import { GetClientPurchaseOrder } from '@imas/api/client';

export const openClientPurchaseOrderRevisionReportViewer = (poNumRevId: int) => {
	openPopup(
		IMAS.PAGES.CLIENTS.PURCHASE_ORDERS.REVISIONS.REPORT_VIEW(poNumRevId), 
		{ name: "Contact Report", height: 1200, width: 900 }
	);
};

export const ClientPurchaseOrderRevisionReportViewer = () => {
	//page title
    document.title = "IMAS - Purchase Order Revision Report";
	
	//get serviceOrderId from router params
    const poNumRevId = Number(useParams().poNumRevId);

	//load the specified purchase order
    //const { data: purchaseOrderRevision, error: purchaseOrderRevisionError } = useLoadApi(GetClientPurchaseOrder, [poNumRevId], [poNumRevId], { disabled: isNaN(poNumRevId) });

	//get current employee info & roles
	//const currentEmployee = useSelector(getEmployeeData);
	//const { admin: isAdmin } = useSelector(getUserRoles);

	//if the current user can download/print the pdf
	//const canGetPdf = useMemo(() => {
	//	return (client && currentEmployee?.employeeId === client?.reviewedByEmployeeId) || isAdmin;
	//}, [client, currentEmployee, isAdmin]);

	//close form if there was an error loading the client
    if (isNaN(poNumRevId)) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

	//show PDF preview of report for the specified service order work place review
	return (
		<Alignment column flex>
			{/* SSRS PDF Report Viewer */}
			{poNumRevId ? (
				<SSRSPDFViewer
					template={"CLIENT_PURCHASE_ORDER"}
					/** Report Name Given client ID */
					reportName={`Purchase Order Report.${poNumRevId}`}
					parameters={[poNumRevId]}
					/** Only Shown Download & Print if Employee is the work place review creator or they are an admin. */
					showDownloadPDF={true}
					showPrintPDF={true}
				/>
			) : ( 
				<FileViewerLoader/>
			)}
		</Alignment>
	);
};