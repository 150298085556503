import { CacheClient, IMASCacheDbTables, IMASCacheDbTableMap } from '../_cache';
import { UpdateType, UpdateFunction } from '../_hubs';

type CacheInfo<UsesCache extends boolean> = UsesCache extends false ? {} : {
	stale?: boolean;
};

export type DataHookResult<T extends { data: any; }, UsesCache extends boolean = false> = [T["data"], T & CacheInfo<UsesCache>];

export interface DataHookOptions {
	disabled?: boolean;
};

export function createUpdateHandler<V extends { id: int | string; }>(update: React.Dispatch<React.SetStateAction<V[] | undefined>>): (type: UpdateType, data: V) => void;
export function createUpdateHandler<T extends IMASCacheDbTables, V extends { id: int | string; } = IMASCacheDbTableMap[T]>(
	update: React.Dispatch<React.SetStateAction<V[] | undefined>>,
	cache: CacheClient<T>
): (type: UpdateType, data: V) => void;

//produce an update handler function given a dispatch function for a given list of data
export function createUpdateHandler(update: React.Dispatch<React.SetStateAction<any[] | undefined>>, cache?: CacheClient<IMASCacheDbTables>): UpdateFunction<any> {
	return (type: UpdateType, data: any): void => {
		//update provided cache
		if (cache) cache.update(type, data);
		
		//update updater
		switch (type) {
			case "CREATE":
				return update(x => x ? [...x, data] : x);
			case "UPDATE":
				return update(x => x ? x.map(y => y.id === data.id ? data : y) : x);
			case "DELETE":
				return update(x => x ? x.filter(y => y.id !== data.id) : x);
		}
	};
};