import { Alignment } from "@imas/components/layout";
import { Typography } from "@mui/material";
import ConfettiExplosion from 'react-confetti-explosion';
import styled from 'styled-components';

export const ApplicantAccepted = () => {
    document.title = "IMAS";
    const WordleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  
  height: 100vh;  // Ensure the container takes full viewport height
  position: relative;  // For positioning the keyboard absolutely within this container
`;
    return (

      <Alignment sx={{backgroundColor: 'white'}}>
        
        
    <WordleContainer>

    <ConfettiExplosion
      particleCount={100}
      duration={10000}
      />
      <Typography sx={{marginTop: '50px'}} color={'black'} variant={"h4"}
      >Congratulations and welcome to the PNL team!</Typography>
        </WordleContainer>
        </Alignment>

         
    );

};