import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";

/*
 *  TextFieldMiddleware component used to get around the "size" prop conflict between the react-number-format NumberFormat props 
 *  and the Material-UI TextField props.
*/
type TextFieldMiddlewareProps = TextFieldProps & { textFieldSize?: TextFieldProps["size"] };

const TextFieldMiddleware = forwardRef<HTMLInputElement, TextFieldMiddlewareProps>((props, ref) => {
    //destructure params to take out textFieldSize
    const { textFieldSize, ...textFieldProps } = props;

    return <TextField {...textFieldProps} size={textFieldSize} inputRef={ref}/>;
});

/*
 *  NumberTextField component, renders like a Material-UI TextField and will accept almost all TextField props, but functions like a
 *  react-number-format NumberFormat,
 *  
 *  References:
 *      TextField props [https://mui.com/api/text-field/]
 *      NumberFormat props [https://www.npmjs.com/package/react-number-format]
*/ 
export type NumberTextFieldProps = Omit<NumberFormatPropsBase, "size"> & Omit<TextFieldProps, "value" | "defaultValue" | "type" | "ref">;

const NumberTextField = forwardRef<HTMLInputElement, NumberTextFieldProps>((props, ref) => {
    const { size, value, allowNegative, ...otherProps } = props;

    return (
        <NumberFormat
            allowNegative={allowNegative ?? false}
            customInput={TextFieldMiddleware}
            /* 
                NOTE: The 'value' prop is coalesced with the empty string "" to make the input render properly for react-number-format when null is passed.
                This custom NumberTextField component is designed to interpret null as "empty this input". react-number-format requires an empty string ("") for
                this to happen and passing null will cause unexpected behavior. This is mainly an issue when the 'value' prop of this component is cleared programmatically
                rather than by the user.
                
                Reason: https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797342449 
            */
            value={value ?? ""}
            //map the size param to textFieldSize so it can be passed correctly to the TextField component
            textFieldSize={size}
            getInputRef={ref as any}
            {...otherProps}
        />
    );
});

export { NumberTextField };