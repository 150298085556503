import { Alignment } from "@imas/components/layout";
import { AppBar, Box, Typography, Button } from '@mui/material';
import { isPopup } from '@imas/utils/misc';
import { Navigate, useNavigate, useParams } from 'react-router';

export const InvalidCredentails = () => {
	const isPopupWindow = isPopup();
	const navigate = useNavigate();

    return (
        <>
        <Alignment column>
        <AppBar position={"static"} color={"primary"} sx={{height: '56px'}}>
                     <Alignment row sx={{margin: 'auto 0 auto 0'}}>
 
                         {/*  PNL Image */}	
                         <Box component={"img"} sx={{margin: '0 10px 0 10px', maxWidth: '75px', width: '-webkit-fill-available' }} src={"/images/Logo-Transparent.png"}/>
                         <Typography variant={'h5'} sx={{margin: 'auto 0 auto 10px'}}>{'Invalid Credentials'}</Typography>
 
                     </Alignment>
                     
                 </AppBar>
                 <Alignment 
				column 
				flex 
				sx={{
					margin: ['2vh 8%', '5vh 8%', '10vh 8%'],
					width: '-webkit-fill-available',
					maxWidth: '400px',
					alignSelf: 'center',
				}}>
                {isPopupWindow? <Button onClick={() => {
                     window.close();
                 }}>Return to Login</Button>: <Button onClick={() => {
                    navigate("/login");
                 }}>Return to Login</Button>}
                </Alignment>
                 
        </Alignment>
        </>
    );
};