import { Alignment, LoadingPaper } from '@imas/components/layout';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { vContactPrimaryPhoneNumber } from '@imas/api/client';
import { useClientVContactPrimaryPhoneNumbers } from '@imas/api/data-hooks';
import { makeStyles } from '@imas/styles';
import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs, TypedGridFilterModel, TypedGridFilterItem } from '@imas/data-grid';
import { GridActionsCellItem, GridLogicOperator } from '@mui/x-data-grid-pro';
import { Typography, IconButton, Tooltip,FormControlLabel, Checkbox, lighten, alpha } from '@mui/material';
import { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { openClientLocationContactEditor } from 'src/pages/editors';

//define the column order
export type CLIENT_VCONTACT_PPN_GRID_COLUMNS = ["actions", "name", "jobTitle", "primaryPhoneNumber", "secondPhoneNumber", "email", "active"];

//define the filter model type
export type CLIENT_VCONTACT_PPN_FILTER_MODEL = TypedGridFilterModel<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumber>;

//define the grid api ref type
export type CLIENT_VCONTACT_PPN_GRID_API_REF = TypedGridApiRef<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumber, never, "row">;

//define grid props type
export type CLIENT_VCONTACT_PPN_GRID_PROPS = TypedEditDataGridProps<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumber, never, CLIENT_VCONTACT_PPN_GRID_COLUMNS, "row">

export const CLIENT_VCONTACT_PPN_FILTER: CLIENT_VCONTACT_PPN_FILTER_MODEL = { items: [{ id: 1, field: 'active', operator: 'is', value: true }], logicOperator: GridLogicOperator.And };

const useStyles = makeStyles()((theme) => ({
	inactive: {
        background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.7) : alpha(theme.palette.error.dark, 0.60),
        "&.MuiDataGrid-row:hover": {
            background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.6) : alpha(theme.palette.error.dark, 0.75),
        },
        "&.MuiDataGrid-row.Mui-selected, &.MuiDataGrid-row.Mui-selected:hover": {
            background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.35) : alpha(theme.palette.error.dark, 0.90),
        },
    },
}));

export interface ClientVContactPrimaryPhoneNumbersGridProps {
	/** ID of the Client */
	clientId?: int;
	/** locationId to filter contacts by */
	locationId?: int | null;
	/** Force loading state if true. */
	loading?: boolean;
	/** Controlled filter of including inactive. */
	includeInactive?: boolean;
	/** A component which is rendered in place of the grid. (Useful in forms.) */
	alternateBody?: React.ReactNode;
};

export const ClientVContactPrimaryPhoneNumbersGrid = memo((props: ClientVContactPrimaryPhoneNumbersGridProps) => {
	const { clientId, locationId, loading, includeInactive, alternateBody } = props;

	//component styling
	const { classes } = useStyles();

	//get vContactPrimaryPhoneNumber records
	const [unfilteredContacts] = useClientVContactPrimaryPhoneNumbers(clientId, { disabled: clientId === undefined || clientId === -1 });
	
	//filter contacts
	const contacts = useMemo(() => unfilteredContacts ? !locationId ? unfilteredContacts : unfilteredContacts.filter(x => x.locationId === locationId) : undefined, [unfilteredContacts, locationId]);

	//grid apiRef
	const apiRef: CLIENT_VCONTACT_PPN_GRID_API_REF = useTGridApiRef();

	//filter model state
    const [filterModel, setFilterModel] = useState<CLIENT_VCONTACT_PPN_FILTER_MODEL>(CLIENT_VCONTACT_PPN_FILTER);

	//get filter value for active
	const activeFilter = useMemo(() => {
		return filterModel.items.find((x): x is TypedGridFilterItem<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumber, 'active'> => x.field === 'active' && x.operator === 'is') ?? null;
	}, [filterModel]);
	const activeFilterRef = useRef(activeFilter);
	activeFilterRef.current = activeFilter;

	//update activeFilter as the controlled includeInactive is changed 
	useEffect(() => {
		if (includeInactive === undefined || !apiRef.current) return;
		
		// Log apiRef.current to check its structure
		console.log(apiRef.current);
	  
		if (includeInactive) {
		  if (activeFilterRef.current && typeof apiRef.current.deleteFilterItem === 'function') {
			apiRef.current.deleteFilterItem(activeFilterRef.current);
		  } else {
			console.error("deleteFilterItem is not a function on apiRef.current.");
		  }
		} else {
		  try {
			if (typeof apiRef.current.upsertFilterItem === 'function') {
			  apiRef.current.upsertFilterItem({
				id: 1,
				field: 'active',
				operator: 'is',
				value: true,
			  });
			} else {
			  console.error("upsertFilterItem is not a function on apiRef.current.");
			}
		  } catch (error) {
			console.error("Error in upsertFilterItem:", error);
		  }
		}
	  }, [includeInactive, apiRef]);

	//edit click callback
	const handleEditClick = useCallback((id: int) => () => {
		//open editor
		openClientLocationContactEditor({ contactId: id });
	}, []);

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumber, never, CLIENT_VCONTACT_PPN_GRID_COLUMNS, "row"> => {
        return [
			{
				field: 'actions',
				headerName: '',
				type: 'actions',
				width: 20,
				renderHeader: () => (
					<Tooltip title={"Create Contact"} arrow>
						<IconButton
							disabled={!clientId}
							size={"small"}
							onClick={() => { 
								if (!clientId) return;
		
								//open editor with locationId provided
								openClientLocationContactEditor(locationId ? { locationId } : { clientId });
							}}
						><AddIcon/></IconButton>
					</Tooltip>
				),
				getActions: ({ id }) => ([
					<GridActionsCellItem disabled={id === -1} onClick={handleEditClick(id)} icon={<EditIcon/>} label={"Edit"} color={"inherit"}/>
				]),
			},
            { field: 'name', headerName: 'Contact', flex: 1 },
            { field: 'jobTitle', headerName: 'Job Title', flex: 1.2 },
            { field: 'primaryPhoneNumber', headerName: 'Primary', flex: 1 },
            { field: 'secondPhoneNumber', headerName: 'Secondary', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1.8 },

			// Hidden Fields
			{ field: 'active', headerName: 'Active', width: 80, type: 'boolean'/* , hide: true */ },
        ];
    }, [clientId, locationId, handleEditClick]);

	return (
		<LoadingPaper loading={loading || clientId === undefined} sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
			<Alignment row sx={{ margin: '3px 3px 0 10px' }}>
				<Typography variant={"h6"} sx={{ fontWeight: 'bold', color: 'text.primary' }}>{"Contacts"}</Typography>

				{/* Include Inactive Filter */}
                <FormControlLabel
                    label={"Include Inactive"}
                    control={<Checkbox 
                        sx={{ padding: '4px' }} 
                        size={"small"} 
                        checked={activeFilter?.value === undefined}
                        onChange={() => {
							if (activeFilter) apiRef.current.deleteFilterItem(activeFilter);
							else {
								apiRef.current.upsertFilterItem({
									id: 1,
									field: 'active',
									operator: 'is',
									value: true,
								});
							}
						}}
                    />}
					sx={{ marginLeft: '10px' }}
                />

				{/* New Contact for Current Location */}
				<LoadingButton
					variant={"contained"}
					color={"primary"}
					size={"small"}
					startIcon={<AddIcon/>}
					loading={clientId === undefined}
					loadingPosition={"start"}
					onClick={() => { 
						if (!clientId) return;

						//open editor
						openClientLocationContactEditor(locationId ? { locationId } : { clientId });
					}}
					sx={{ marginLeft: 'auto' }}
				>{"Contact"}</LoadingButton>
			</Alignment>
				
			{/* vContactPrimaryPhoneNumber DataGrid */}
			{alternateBody ? alternateBody : (
				<Alignment column flex>
					<TDataGrid<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumber, never, CLIENT_VCONTACT_PPN_GRID_COLUMNS, "row">
						key={clientId ?? "NONE"}
						apiRef={apiRef}
						editMode={"row"}
						loading={!contacts}
						rows={contacts ?? []}
						columns={columns}
						columnVisibilityModel={{
							active: false
						}}
						filterModel={filterModel}
						onFilterModelChange={(model) => setFilterModel(model)}
						getRowClassName={({ row }) => row.active ? "" : classes.inactive}
						sx={{ border: 'none' }}
					/>
				</Alignment>
			)}
		</LoadingPaper>
	);
});