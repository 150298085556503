const delayMap = {
    ms: 1,
    s: 1000,
    m: 60000,
};

export function delay(amount: int, unit: 'ms' | 's' | 'm' = 'ms'): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), amount * delayMap[unit]);
    });
}