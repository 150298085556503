import { Checkbox } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { Alignment } from '@imas/components/layout';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { FileExplorerItemListColumn } from './FileExplorerItemListColumn';
import { FileExplorerItemListRow } from './FileExplorerItemListRow';
import { FolderInfoItem } from '@imas/api/files/types';
import { FileExplorerContext, TFileExplorerContext } from '../../FileExplorerContext';
import { FileExplorerDisplayItem } from '../FileExplorerItemContainer'; 

//list item data interface
export interface FileExplorerItemListData extends Pick<TFileExplorerContext, "upload" | "mode" | "canUpload"> {
    /** If checkboxes should be shown on the item. */
    showCheckboxes: boolean;
    /** Function to get the item for the given list item index. */
    getItem: (index: int) => FileExplorerDisplayItem
    /** Callback for when a select operation is executed. */
    onSelect: (row: FolderInfoItem, ctrlKey?: boolean, shiftKey?: boolean) => void;
    /** Callback for when a open operation is executed. */
    onOpen: (row: FolderInfoItem) => void;
	/** Callback for when a menu operation is executed. */
    onMenu: (row: FolderInfoItem, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    /** Callback for when a move operation is executed. */
    onMove: (destination: FolderInfoItem) => void;
};

//list row data interface
export interface FileExplorerItemListRowData extends FileExplorerItemListData {
    columnWidths: { name: int, date: int, type: int, size: int };
};

//FileExplorerItemListProps interface
export interface FileExplorerItemListProps {
    /** Number of items in this list. */
    totalItems: int;
    /** FileExplorerItemListData */
    data: FileExplorerItemListData;
    /** Main checkbox state. */
    checkboxState: "checked" | "indeterminate" | false;
    /** Callback for when check all checkbox is pressed. */
    onCheckAll: () => void;
};

//FileExplorerItemList - A virtualized list of items.
const FileExplorerItemList = React.memo((props: FileExplorerItemListProps) => {
    //list column widths
    const [columns, setColumns] = useState<{ name: int, date: int, type: int, size: int }>({ name: 400, date: 160, type: 75, size: 75 });

	//use explorer context
	const { sorting, setSorting } = useContext(FileExplorerContext);

	//handles when column headers are clicked on
	const handleClick = useCallback((origin: string) => {
		//headers have different sorting when clicked on two times compared to once
		switch (origin) {
			case 'name':
				if (sorting === 0) setSorting(1);
				else setSorting(0);
				break;
			case 'date':
				if (sorting === 2) setSorting(3);
				else setSorting(2);
				break;
			case 'type':
				if (sorting === 4) setSorting(5);
				else setSorting(4);
				break;
			case 'size':
				if (sorting === 6) setSorting(7);
				else setSorting(6);
				break;
		}
	}, [sorting, setSorting]);

    return (
        <>
            {/* List Columns */}
            <Alignment row sx={{ padding: '0 12px' }}>
                {/* Render Checkbox if showCheckboxes is true */}
                {props.data.showCheckboxes ? (
                    <Checkbox
                        size={"small"}
                        disabled={props.data.mode === "pick-file" || props.data.mode === "pick-folder" || props.data.mode === "upload-file" || props.data.mode === "upload-files"}
                        checked={props.checkboxState === "checked"}
                        indeterminate={props.checkboxState === "indeterminate"}
                        onChange={(e) => { props.onCheckAll(); e.stopPropagation(); }}
                        onClick={(e) => e.stopPropagation()}
                        sx={{ padding: '4px', marginRight: '12px' }}
                    />
                ) : null}

                <FileExplorerItemListColumn label={"Name"}          onClick={() => handleClick('name')} defaultWidth={columns.name} onWidthChange={(width) => setColumns(x => ({ ...x, name: width }))} offset={20}/>
                <FileExplorerItemListColumn label={"Date modified"} onClick={() => handleClick('date')} defaultWidth={columns.date} onWidthChange={(width) => setColumns(x => ({ ...x, date: width }))}/>
                <FileExplorerItemListColumn label={"Type"}          onClick={() => handleClick('type')} defaultWidth={columns.type} onWidthChange={(width) => setColumns(x => ({ ...x, type: width }))}/>
                <FileExplorerItemListColumn label={"Size"}          onClick={() => handleClick('size')} defaultWidth={columns.size} onWidthChange={(width) => setColumns(x => ({ ...x, size: width }))}/>
            </Alignment>

            {/* Virtualized List */}
			<Alignment column flex>
				<AutoSizer>
					{({ height, width }) => (
						<FixedSizeList
							width={width}
							height={height}
							itemCount={props.totalItems}
							itemData={{ columnWidths: columns, ...props.data }}
							itemSize={32}
						>
							{FileExplorerItemListRow}
						</FixedSizeList>
					)}
				</AutoSizer>
			</Alignment>
        </>
    );
});

export { FileExplorerItemList };