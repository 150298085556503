import { axiosData } from "@imas/api";
import { SourceGetter } from "../types/api/api";
import { WebPushMessageType, WebPushPayload } from "@imas/service-worker";
import { Message } from "../types/api/misc/misc";


export const SendMessages = (getSource: SourceGetter) => {
    return async (employeeIds: int[], title: string, sms: boolean, workEmail: boolean, otherEmail: boolean, body?: string): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `/api/manage/messaging`,
            data: employeeIds,
            params: {
                title,
                body,
                sms,
                workEmail,
                otherEmail
            },
            cancelToken: getSource().token,
		});
    };
};

/* Gets recent custom messages for the user */
export const GetRecentMessages = (getSource: SourceGetter) => {
    return async (): Promise<Message[]> => {
        return axiosData({
			method: 'get',
            url: `/api/manage/messaging/recent`,
            cancelToken: getSource().token,
		});
    };
};

/* Send push notifications */
export const PushNotifications = (getSource: SourceGetter) => {
    return async (senderId: int, notificationType: WebPushMessageType, employeeIds: int[], title: string, body?: string): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `/api/notifications`,
            data: employeeIds,
            params: {
                notificationType,
                senderId,
                title,
                body: !body ? 'doNotPostBody' : body,
            },
            cancelToken: getSource().token,
		});
    };
};

//Has to be a fetch because axios does not work with serviceworkers. The service workers do not have access to a lot, including a window to check if dev or prod.
export const MessageDeliveredFetch = (messageId: int, dev: boolean) => {
    let url;
    if (!dev) url = `https://imas.pnltest.net/api/notifications/${messageId}/delivered`;
    else url = `https://imasdev.pnltest.net/api/notifications/${messageId}/delivered`;

    fetch(url, {
        method: 'PUT',
        //mode: 'cors'
    });
};

//get the template defaults
export const GetNotificationPayload = (getSource: SourceGetter) => {
    return async (notificationType: WebPushMessageType): Promise<WebPushPayload> => {
        return axiosData({
			method: 'get',
            url: `/api/notifications`,
            params: {
                notificationType
            },
            cancelToken: getSource().token,
		});
    };
};
