import { Box, Typography, TypographyProps, SxProps, Theme } from '@mui/material';
import { makeStyles } from '@imas/styles';
import { ReactNode } from 'react';
import { Alignment } from '@imas/components/layout';

const useStyles = makeStyles()((theme) => ({
	spaced: {
		marginBottom: '10px',
	},
    boldedDescriptor: {
        fontWeight: 600,
        margin: 'auto 5px auto 0'
    },
    alignedText: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
	boldedDescriptorTop:{
		fontWeight: 600,
        margin: 'auto 5px auto 0'
	},
}));

export interface LabeledItemProps {
    label: string;
    labelProps?: TypographyProps;
    item: string | JSX.Element | ReactNode;
    itemTextProps?: TypographyProps;
    row?: boolean;
    spacer?: boolean;
    className?: string;
    inline?: boolean;
	labelTop?: boolean;
	sx?: SxProps<Theme>;
};

const LabeledItem = (props: LabeledItemProps) => {
    const { label, labelProps, item, itemTextProps, row, spacer, className, inline, labelTop, sx } = props;
	
	//styling
    const { classes, cx } = useStyles();

    return (
		<Alignment row={row} className={cx(className, { [classes.spaced]: spacer })} sx={sx}>
			{inline ? (
				<Typography variant={"body1"} color={"textPrimary"} {...labelProps}><Box fontWeight={"bold"} component={"span"}>{label}</Box>{item}</Typography>
			) : (
				<>
					<Typography className={labelTop ? classes.boldedDescriptor : classes.boldedDescriptorTop} variant={"body1"} color={"textPrimary"} {...labelProps}>{label}</Typography>
					{typeof(item) === "string" ? <Typography className={classes.alignedText} variant={"body1"} color={"textPrimary"} {...itemTextProps}>{item}</Typography> : item }
				</>
			)}
		</Alignment>
    );
};

export { LabeledItem };