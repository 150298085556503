import { Moment } from 'moment';
import { CancelToken } from "axios";
import Dexie, { IndexableType, Table } from "dexie";
import { ScheduleEventEntry, OnCallScheduleResult } from "src/api/types/api/schedule/schedule";
import EmployeeDirectoryResult from 'src/api/types/api/people/EmployeeDirectoryResult';
import { local } from './local';

/**
 * These are the legacy caching methods, to be replaced by the newer cache API in the imas api module
**/

//defined stores and their keys
const dbStores = ["keyvalue", "dailyScheduledEvents", "onCallSchedules"];

//CacheDB version
const CACHE_DB_VER = 2;

// define CacheDB class
class CacheDB extends Dexie {
    public employeeDirectory: Dexie.Table<EmployeeDirectoryResult, number>;
    public dailyScheduledEvents: Dexie.Table<{ date: Date, type: "all" | "field" | "lab" | "me", events: ScheduleEventEntry[] }, Date>;
    public onCallSchedules: Dexie.Table<{ date: Date, schedule: OnCallScheduleResult[] }, Date>;

    public constructor() {
        super("CacheDB");
        this.version(CACHE_DB_VER).stores({
            employeeDirectory: "employeeId",
            dailyScheduledEvents: "[date+type], date",
            onCallSchedules: "date"
        });

        this.employeeDirectory = this.table("employeeDirectory");
        this.dailyScheduledEvents = this.table("dailyScheduledEvents");
        this.onCallSchedules = this.table("onCallSchedules");
    }

    //save the employee directory to cache
    public setEmployeeDirectory(results: EmployeeDirectoryResult[]) {
        this.transaction('rw', this.employeeDirectory, async () => {
            //remove all existing entries
            await this.employeeDirectory.clear();
            
            //add the new entries
            await this.employeeDirectory.bulkPut(results);
        });
    }

    //get the employee directory from cache
    public getEmployeeDirectory() {
        return new Promise<EmployeeDirectoryResult[]>((resolve, _) => {
            this.transaction('r', this.employeeDirectory, async () => {
                const result = await this.employeeDirectory.toArray();
                resolve(result);
            });
        });
    };

    //add a dailyScheduleEvents entry
    public addDailyScheduledEvents(date: Moment, type: "all" | "field" | "lab" | "me", events: ScheduleEventEntry[]) {
        this.transaction('rw', this.dailyScheduledEvents, async () => {
            this.dailyScheduledEvents.put({ date: date.startOf("day").toDate(), type, events });
        });
    };

    //get a list of dailyScheduleEvents entries for a given day & type
    public getDailyScheduledEvents(date: Moment, type: "all" | "field" | "lab" | "me"): Promise<ScheduleEventEntry[]> {
        return new Promise<ScheduleEventEntry[]>((resolve, reject) => {
            this.transaction('r', this.dailyScheduledEvents, async () => { 
                const result = await this.dailyScheduledEvents.where("[date+type]").equals([date.startOf("day").toDate(), type]).first();
                if (result === undefined) return reject(null);
                resolve(result.events);
             }).catch(e => reject(e));
        });
    };

    //add a onCallSchedule entry
    public addOnCallSchedule(date: Moment, schedule: OnCallScheduleResult[]) {
        this.transaction('rw', this.onCallSchedules, async () => {
            this.onCallSchedules.put({ date: date.startOf("day").toDate(), schedule });
        });
    };

    //get onCallSchedule for given day
    public getOnCallSchedule(date: Moment): Promise<OnCallScheduleResult[]> {
        return new Promise<OnCallScheduleResult[]>((resolve, reject) => {
            this.transaction('r', this.onCallSchedules, async () => {
                const result = await this.onCallSchedules.where("date").equals(date.startOf("day").toDate()).first();
                if (result === undefined) return reject(null);
                resolve(result.schedule);
            });
        });
    };
    
    //generic add
    public add<T>(table: Table<any, IndexableType>, value: T) {
        this.transaction('rw', table, async () => {
            
        });
    }
}

//create CacheDB instance
export const cacheDb = new CacheDB();

//reference to db instnace
let idb: IDBDatabase | null = null; 

//our custom version ID of our DB. If the app detects that this has changed it will delete and re-create the db.
const DB_VER = "0.0.1";

//indexedDB interface
export const db = {
    /**
     * Initalize the IndexedDB instance
     * @param {string[]} stores A list of strings representing stores to generate.
     */
    init: () => {
        //check the stored version of the DB
        if (local.get("DB_VER") !== DB_VER) {
            console.log("DB_VER did not match regerating indexedDB.");

            //if it does not match delete the DB
            window.indexedDB.deleteDatabase("IMAS");
        }

        // create/open the IMAS indexedDB 
        const request = window.indexedDB.open("IMAS");

        //onupgradeneeded
        request.onupgradeneeded = (event) => {
            //log inital config
            console.log("Initalizing indexedDB config.");

            //save a reference to the db
            idb = request.result;

            //for each store definition in dbStores create the object store
            for (let store of dbStores) {
                if (!idb.objectStoreNames.contains(store)) idb.createObjectStore(store);
            }

            //if the indexedDB was generated successfully then set the localStorage DB_VER to the current value of DB_VER
            local.set("DB_VER", DB_VER);
        };

        //onsuccess 
        request.onsuccess = () => { 
            //save a reference to the db
            idb = request.result;
        };
    },

    /**
     * Set a valuu
     * @param {string} key The key to referece the data by.
     * @param {any} val The data to store.
     * @param {string} [store="keyvalue"] The DB Store to keep the data in.
     */
    set: (key: string, val: any, store: string = "keyvalue") => {
        if (idb === null) return;

        //make the request
        const request = idb.transaction([store], "readwrite").objectStore(store).add(val, key);

        //on error
        request.onerror = (e) => {
            if (idb === null) return;

            //attempt to update the value instead
            const putRequest = idb.transaction([store], "readwrite").objectStore(store).put(val, key);

            //log errors
            putRequest.onerror = (e) => console.log(e);
        };
    },

    /**
     * 
     */
    get: <T>(key: string, cancelToken: CancelToken, store: string = "keyvalue"): Promise<T> => {
        if (idb === null) return new Promise<T>(() => null);

        //make the request
        const request = idb.transaction([store], "readonly").objectStore(store).get(key);

        //result promise
        return new Promise<T>((resolve, reject) => {
            //keeps track of if the request has been canceled
            let canceled = false;

            //resolve cancelToken
            cancelToken.promise.then(() => { canceled = true; });

            request.onerror = (e) => reject(e);

            request.onsuccess = () => { 
                //reject if the request was canceled
                if (canceled) return reject(null);

                //dont do anything if the request found nothing
                if (request.result === undefined || request.result === null) return reject(null);

                //call the callback with the result
                resolve(request.result);
            };
        });
    }
};