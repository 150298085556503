import { memo, forwardRef } from 'react';
import { useLoadApi } from '@imas/api';
import { MuiForm, MuiFormPartial, MuiFormRef, useMuiForm, Editable, FormPaper, FormTextField } from '@imas/mui-form';
import { ClientLocation, ClientLocationForm as TClientLocationForm } from '@imas/api/client';
import { useCallbackRef } from '@imas/utils/hooks';
import { GetStates } from '@imas/api/system';
import { LocationClientAndName } from './LocationClientAndName';
import { LocationBusinessAndBilling } from './LocationBusinessAndBilling';
import { LocationDocuments } from './LocationDocuments';
import { LocationContacts } from './LocationContacts';
import { Alignment } from '@imas/components/layout';
import { PhoneNumbersForm } from '@imas/components/forms';
import { PhoneNumberGroupTypes } from '@imas/api/phone-numbers';
import { Typography } from '@mui/material';

const defaultValues: Editable<TClientLocationForm> = {
    clientId: null,
	name: null,
	billingAddress: null,
	billingAddress1: null,
	billingAddress2: null,
	billingCity: null,
	billingState: null,
	billingZip: null,
	billingCountry: null,
	businessAddress: null,
	businessAddress1: null,
	businessAddress2: null,
	businessCity: null,
	businessState: null,
	businessZip: null,
	businessCountry: null,
	billingTo: null,
	billingAttn: null,
	billingEmail: null,
	reportMethod: 1,
	billingMethod: 1,
	billingReport: false,
	notes: null,
	active: true,
};

export interface ClientLocationFormValidatorRef {
    locations: ClientLocation[];
};

export const ClientLocationForm = memo(forwardRef<MuiFormRef<TClientLocationForm>, MuiFormPartial & { clientId: int | null; cLocation?: ClientLocation | null; }>(({ clientId, cLocation, ...props }, ref) => {
	//get list of states
	const { data: states } = useLoadApi(GetStates, [], []);

    //get a callback ref for the ValidatorRef
    const [validatorRef, onValidatorRef] = useCallbackRef<ClientLocationFormValidatorRef>();

    //form hook
    const { control, muiFormProps } = useMuiForm<TClientLocationForm, ClientLocationFormValidatorRef>({ 
        defaultValues: { ...defaultValues, clientId }, context: validatorRef ?? undefined,
        validator: ({ clientId, name, reportMethod, billingMethod, ...otherFields }, error, context) => {
            //ensure no duplicate location names
            if (name && context) {
                //find an exact name match
                if (context.locations.map(x => x.name.toLowerCase()).includes(name.toLowerCase())) {
                    error("name", "conflict", `The selected client already has a location named '${name}'.`);
                } 
            }

            //ensure required fields are required
            if (clientId === null) error("clientId", "required");
            if (name === null) error("name", "required");
            if (reportMethod === null) error("reportMethod", "required");
            if (billingMethod === null) error("billingMethod", "required");

            //ensure type safety
            if (clientId === null) return;
            if (name === null) return;
            if (reportMethod === null) return;
            if (billingMethod === null) return;

            return { clientId, name, reportMethod, billingMethod, ...otherFields };
        },
		onSuccessfulSubmit: () => {
		}
    });

    //separate loading from other props
    const { loading, viewOnly, ...otherProps } = props;

    //form data
    return (
        <MuiForm
            loading={loading || !states}
            viewOnly={viewOnly}
            {...otherProps}
            {...muiFormProps}
            ref={ref}
        >
			{/* Client & Location */}
			<LocationClientAndName clientId={clientId} cLocation={cLocation} ref={onValidatorRef}/>

			<Alignment row sx={{ marginTop: '25px', width: '-webkit-fill-available' }}>
				<Alignment column flex>
					{/* Billing, Business, Location Preferences */}
					<LocationBusinessAndBilling cLocation={cLocation}/>

					{/* Location Notes */}
					<FormTextField
						control={control}
						name={"notes"}
						label={"Notes"}
						multiline
						rows={2}
						sx={{ marginTop: '10px' }}
					/>
				</Alignment>

				{/* Location Phone Numbers */}
				<Alignment column sx={{ marginLeft: '10px', minWidth: '435px' }}>
					<FormPaper sx={{ display: 'flex', flexDirection: 'column', flex: '1', color: 'text.primary' }}>
						<Typography variant={"h6"} sx={{ marginLeft: '10px', marginTop: '3px', fontWeight: 'bold' }}>{"Phone Numbers"}</Typography>
					
						{cLocation === null ? (
							<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until location is saved."}</Typography>
						) : cLocation ? (
							<Alignment column flex>
								<PhoneNumbersForm groupType={PhoneNumberGroupTypes.Location} groupId={cLocation.id}/>
							</Alignment>
						) : null}
					</FormPaper>

					<Alignment column flex sx={{ marginTop: '10px' }}>
						<LocationDocuments cLocation={cLocation}/>	
					</Alignment>
				</Alignment>
			</Alignment>
			

			{/* Location Contacts */}
			<Alignment column flex sx={{ marginTop: '10px' }}>
				<LocationContacts cLocation={cLocation}/>
			</Alignment>
        </MuiForm>
    );
}));