import { mapApi, ApiConfig } from '@imas/api';
import { PhoneNumberType } from './types';

//phone numbers types base url
const PHONE_NUMBERS_TYPES_URL = "/api/phone-numbers/types";

/*  */
export const GetPhoneNumberTypes = mapApi((): ApiConfig<PhoneNumberType[]> => ({
	method: 'get',
	url: `${PHONE_NUMBERS_TYPES_URL}`,
}));