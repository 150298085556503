import { Alignment, LoadingPaper } from '@imas/components/layout';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { openClientLocationEditor } from 'src/pages/editors';
import { vLocationPrimaryPhoneNumber } from '@imas/api/client';
import { useClientVLocationPrimaryPhoneNumbers } from '@imas/api/data-hooks';
import { makeStyles } from '@imas/styles';
import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs, TypedGridFilterModel, TypedGridFilterItem } from '@imas/data-grid';
import { GridActionsCellItem, GridLogicOperator } from '@mui/x-data-grid-pro';
import { Typography, IconButton, Tooltip,FormControlLabel, Checkbox, lighten, alpha } from '@mui/material';
import { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { LoadingButton } from '@mui/lab';

//define the column order
export type CLIENT_VLOCATIONS_PPN_GRID_COLUMNS = ["actions", "name", "primaryPhoneNumber", "secondPhoneNumber", "fullBusinessAddress", "fullBillingAddress", "active"];

//define the filter model type
export type CLIENT_VLOCATIONS_PPN_FILTER_MODEL = TypedGridFilterModel<vLocationPrimaryPhoneNumber, vLocationPrimaryPhoneNumber>;

//define the grid api ref type
export type CLIENT_VLOCATIONS_PPN_GRID_API_REF = TypedGridApiRef<vLocationPrimaryPhoneNumber, vLocationPrimaryPhoneNumber, never, "row">;

//define grid props type
export type CLIENT_VLOCATIONS_PPN_GRID_PROPS = TypedEditDataGridProps<vLocationPrimaryPhoneNumber, vLocationPrimaryPhoneNumber, never, CLIENT_VLOCATIONS_PPN_GRID_COLUMNS, "row">

export const CLIENT_VLOCATIONS_PPN_FILTER: CLIENT_VLOCATIONS_PPN_FILTER_MODEL = { items: [{ id: 1, field: 'active', operator: 'is', value: true }], logicOperator: GridLogicOperator.And };

//location which represents all locations of the client (used to reset location filter)
const getAllLocation = (): vLocationPrimaryPhoneNumber => ({
	id: -1,
    name: "All",
    active: true,
	clientId: -1,
    clientName: "",
    fullBusinessAddress: "",
    fullBillingAddress: "",
    workPhoneId: null,
    workPhoneNumber: null,
    faxPhoneId:null,
    faxPhoneNumber: null,
    primaryPhoneId: null,
    primaryPhoneNumber: null,
    primaryPhoneTypeId: null,
    primaryPhoneType: null,
    secondPhoneId: null,
    secondPhoneNumber: null,
    secondPhoneTypeId: null,
    secondPhoneType: null,
});

const useStyles = makeStyles()((theme) => ({
	inactive: {
        background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.7) : alpha(theme.palette.error.dark, 0.60),
        "&.MuiDataGrid-row:hover": {
            background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.6) : alpha(theme.palette.error.dark, 0.75),
        },
        "&.MuiDataGrid-row.Mui-selected, &.MuiDataGrid-row.Mui-selected:hover": {
            background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.35) : alpha(theme.palette.error.dark, 0.90),
        },
    },
}));

export interface ClientVLocationPrimaryPhoneNumbersGridProps {
	/** Id of the Client */
	clientId?: int | undefined;
	/** Force loading state if true. */
	loading?: boolean;
	/** If the "All" location should be injected. */
	addAllLocation?: boolean;
	/** Callback for when a location is selected. */
	onSelect?: (locationId: int | null) => void;
	/** Controlled Selected location. */
	selected?: int | null;
	/** Controlled filter of including inactive. */
	includeInactive?: boolean;
	/** A component which is rendered in place of the grid. (Useful in forms.) */
	alternateBody?: React.ReactNode;
};

export const ClientVLocationPrimaryPhoneNumbersGrid = memo((props: ClientVLocationPrimaryPhoneNumbersGridProps) => {
	const { clientId, loading, addAllLocation, onSelect, selected, includeInactive, alternateBody } = props;

	//component styling
	const { classes } = useStyles();

	//get vLocationPrimaryPhoneNumber records
	const [rawLcations] = useClientVLocationPrimaryPhoneNumbers(clientId, { disabled: clientId === undefined || clientId === -1 });
	
	//add in ALL location if specified
	const locations = useMemo(() => addAllLocation ? (rawLcations ? [getAllLocation(), ...rawLcations] : undefined) : rawLcations, [rawLcations, addAllLocation]);

	//grid apiRef
	const apiRef: CLIENT_VLOCATIONS_PPN_GRID_API_REF = useTGridApiRef();

	//filter model state
    const [selectedId, setSelectedId] = useState<int | null>(null);
	useEffect(() => setSelectedId(selected === undefined ? null : selected), [selected, setSelectedId]);

	//filter model state
    const [filterModel, setFilterModel] = useState<CLIENT_VLOCATIONS_PPN_FILTER_MODEL>(CLIENT_VLOCATIONS_PPN_FILTER);

	//get filter value for active
	const activeFilter = useMemo(() => {
		return filterModel.items.find((x): x is TypedGridFilterItem<vLocationPrimaryPhoneNumber, vLocationPrimaryPhoneNumber, 'active'> => x.field === 'active' && x.operator === 'is') ?? null;
	}, [filterModel]);
	const activeFilterRef = useRef(activeFilter);
	activeFilterRef.current = activeFilter;

	//update activeFilter as the controlled includeInactive is changed 
	useEffect(() => {
		if (includeInactive === undefined || !apiRef.current) return;
		
		// Log apiRef.current to check its structure
		console.log(apiRef.current);
	  
		if (includeInactive) {
		  if (activeFilterRef.current && typeof apiRef.current.deleteFilterItem === 'function') {
			apiRef.current.deleteFilterItem(activeFilterRef.current);
		  } else {
			console.error("deleteFilterItem is not a function on apiRef.current.");
		  }
		} else {
		  try {
			if (typeof apiRef.current.upsertFilterItem === 'function') {
			  apiRef.current.upsertFilterItem({
				id: 1,
				field: 'active',
				operator: 'is',
				value: true,
			  });
			} else {
			  console.error("upsertFilterItem is not a function on apiRef.current.");
			}
		  } catch (error) {
			console.error("Error in upsertFilterItem:", error);
		  }
		}
	  }, [includeInactive, apiRef]);
	  

	//edit click callback
	const handleEditClick = useCallback((id: int) => () => {
		//open editor
		openClientLocationEditor({ locationId: id });
	}, []);

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<vLocationPrimaryPhoneNumber, vLocationPrimaryPhoneNumber, never, CLIENT_VLOCATIONS_PPN_GRID_COLUMNS, "row"> => {
        return [
			{
				field: 'actions',
				headerName: '',
				type: 'actions',
				width: 20,
				renderHeader: () => (
					<Tooltip title={"Create Location"} arrow>
						<IconButton
							disabled={clientId === undefined}
							size={"small"}
							onClick={() => { 
								if (clientId === undefined) return;
		
								//open editor with locationId provided
								openClientLocationEditor({ clientId });
							}}
						><AddIcon/></IconButton>
					</Tooltip>
				),
				getActions: ({ id }) => ([
					<GridActionsCellItem disabled={id === -1} onClick={handleEditClick(id)} icon={<EditIcon/>} label={"Edit"} color={"inherit"}/>
				]),
			},
            { field: 'name', headerName: 'Location', flex: 1 },
            { field: 'primaryPhoneNumber', headerName: 'Primary', flex: 1 },
            { field: 'secondPhoneNumber', headerName: 'Secondary', flex: 1 },
            { field: 'fullBusinessAddress', headerName: 'Business Address', flex: 2.5 },
            { field: 'fullBillingAddress', headerName: 'Billing Address', flex: 2.5 },
            
			// Hidden Fields
			{ field: 'active', headerName: 'Active', width: 80, type: 'boolean'/* , hide: true */ },
        ];
    }, [clientId, handleEditClick]);

	return (
		<LoadingPaper loading={loading || clientId === undefined} sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
			<Alignment row sx={{ margin: '3px 3px 0 10px' }}>
				<Typography variant={"h6"} sx={{ fontWeight: 'bold', color: 'text.primary' }}>{"Locations"}</Typography>

				{/* Include Inactive Filter */}
                <FormControlLabel
                    label={"Include Inactive"}
                    control={<Checkbox 
                        sx={{ padding: '4px' }} 
                        size={"small"} 
                        checked={activeFilter?.value === undefined}
                        onChange={() => {
							if (activeFilter) apiRef.current.deleteFilterItem(activeFilter);
							else {
								apiRef.current.upsertFilterItem({
									id: 1,
									field: 'active',
									operator: 'is',
									value: true,
								});
							}
						}}
                    />}
					sx={{ marginLeft: '10px' }}
                />

				{/* New Contact for Current Location */}
				<LoadingButton
					variant={"contained"}
					color={"primary"}
					size={"small"}
					startIcon={<AddIcon/>}
					loading={clientId === undefined}
					loadingPosition={"start"}
					onClick={() => { 
						if (clientId === undefined) return;

						//open editor
						openClientLocationEditor({ clientId });
					}}
					sx={{ marginLeft: 'auto' }}
				>{"Location"}</LoadingButton>
			</Alignment>
				
			{/* VLocationPrimaryPhoneNumber DataGrid */}
			{alternateBody ? alternateBody : (
				<Alignment column flex>
					<TDataGrid<vLocationPrimaryPhoneNumber, vLocationPrimaryPhoneNumber, never, CLIENT_VLOCATIONS_PPN_GRID_COLUMNS, "row">
						key={clientId ?? "NONE"}
						apiRef={apiRef}
						editMode={"row"}
						loading={!locations}
						rows={locations ?? []}
						columns={columns}
						columnVisibilityModel={{
							active: false
						}}
						selectionModel={addAllLocation ? [selectedId === null ? -1 : selectedId] : (selectedId === null ? [] : [selectedId])}
						onRowSelectionModelChange={(model) => {
							let newSelectedId = typeof model === "number" ? model : model[0] === undefined ? null : model[0];
							newSelectedId = newSelectedId === -1 ? null : newSelectedId;
							setSelectedId(newSelectedId);
							if (onSelect) onSelect(newSelectedId);
						}}
						filterModel={filterModel}
						onFilterModelChange={(model) => setFilterModel(model)}
						getRowClassName={({ row }) => row.active ? "" : classes.inactive}
						sx={{ border: 'none' }}
					/>
				</Alignment>
			)}
		</LoadingPaper>
	);
});