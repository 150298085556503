import axios from 'axios';
import { Link, Typography } from '@mui/material';
import { openForgotPasswordPage } from './ForgotPassword';
import { openForgotUserPage } from './ForgotUser';
import { Alignment } from '@imas/components/layout';
import { Navigate, useNavigate, useParams } from 'react-router';

export const ForgotLogin = () => {
    return (
        <>  
        <Typography align="center"> {"Forgot "}
        <Link onClick={() => {
            window.open("/recover-username");
        }} sx={{marginTop: '10px', cursor: 'pointer'}}>{"username"}</Link>
         {" or"} <Link sx={{cursor: 'pointer'}} onClick={() => {
                window.open("/recover-password");
                }}>{"password?"}</Link>
        </Typography>
        </>
       
    );

};