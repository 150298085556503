import { useApi, useLoadApi } from '@imas/api';
import { CreatePhoneNumber, UpdatePhoneNumber, DeletePhoneNumber } from '@imas/api/phone-numbers';
import { usePhoneNumbers } from '@imas/api/data-hooks';
import { GetPhoneNumberTypes, PhoneNumber,  PhoneNumberGroupTypes } from '@imas/api/phone-numbers';
import { EditDataGrid, TypedEditDataGridProps, useTGridApiRef, TypedGridApiRef, TypedGridColumnDefs } from '@imas/data-grid';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { memo, useMemo, useCallback, useRef } from 'react';

export interface PhoneNumberRM extends Pick<PhoneNumber, "number" | "typeId" | "priority"> {}; 

export interface PhoneNumbersFormProps {
	/** Phone Number Group Type */
	groupType: PhoneNumberGroupTypes;
	/** Phone Number Group */
	groupId: int;
	/** If Grid column headers should be shown. */
	showHeaders?: boolean;
};

//define the column order for the phone numbers grid & which columns are editable
export type PHONE_NUMBERS_GRID_COLUMNS = ["number", "typeId", "priority"];
export type PHONE_NUMBERS_EDITABLE_COLUMNS = "number" | "typeId" | "priority";

//define the grid api ref type for the phone numbers grid
export type PHONE_NUMBERS_GRID_API_REF = TypedGridApiRef<PhoneNumber, PhoneNumberRM, PHONE_NUMBERS_EDITABLE_COLUMNS, "row">;

//grid props
export type PHONE_NUMBERS_GRID_PROPS = TypedEditDataGridProps<PhoneNumber, PhoneNumberRM, PHONE_NUMBERS_EDITABLE_COLUMNS, PHONE_NUMBERS_GRID_COLUMNS, "row">

//phone number priority options
export const phoneNumberPriorityOptions = [{ label: "Primary", value: 1 }, { label: "Second", value: 2 }, { label: "Third", value: 3 }, { label: "Other", value: 4 }];

export const PhoneNumbersForm = memo(({ groupType, groupId, showHeaders }: PhoneNumbersFormProps) => {
	//propsRef
	const groupRef = useRef({ groupTypeId: groupType, groupId });
	groupRef.current = { groupTypeId: groupType, groupId };

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();
	
	//get phone numbers for specified groupType and groupId
	const [phoneNumbers] = usePhoneNumbers(groupType, groupId);

	//APIs
	const createPhoneNumber = useApi(CreatePhoneNumber);
	const updatePhoneNumber = useApi(UpdatePhoneNumber);
	const deletePhoneNumber = useApi(DeletePhoneNumber);

	//get phone number types
	const { data: phoneNumberTypes } = useLoadApi(GetPhoneNumberTypes, [], []);

	//get phone number types -> phone number type label options
	const phoneNumberTypeOptions = useMemo(() => {
		return [...(phoneNumberTypes ?? []).map(x => ({ label: x.type, value: x.id }))];
	}, [phoneNumberTypes]);

	//grid apiRef
	const apiRef: PHONE_NUMBERS_GRID_API_REF = useTGridApiRef();

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<PhoneNumber, PhoneNumberRM, PHONE_NUMBERS_EDITABLE_COLUMNS, PHONE_NUMBERS_GRID_COLUMNS, "row"> => {
        return [
            { field: 'number', headerName: 'Number', width: 130, flex: 1, editable: true },
            { 
				field: 'typeId', 
				headerName: 'Type',
				type: 'singleSelect',
				width: 90, 
				editable: true,
                valueFormatter: ({ value }) => phoneNumberTypeOptions.find(x => x.value === value)?.label ?? "",
				valueOptions: phoneNumberTypeOptions,
			},
            { 
				field: 'priority', 
				headerName: 'Priority',
				type: 'singleSelect',
				width: 100, 
				editable: true,
                valueFormatter: ({ value }) => phoneNumberPriorityOptions.find(x => x.value === value)?.label ?? "",
				valueOptions: phoneNumberPriorityOptions,
			},
        ];
    }, [phoneNumberTypeOptions]);

	//validator
	const validator = useCallback<PHONE_NUMBERS_GRID_PROPS["rowValidator"]>(async (row) => {
		if (row.number === undefined || row.number === "") throw new Error("Number is required.");
		if (row.typeId === undefined) throw new Error("Type is required.");
		if (row.priority === undefined) throw new Error("Priority is required.");
		
		return {
			number: row.number,
			typeId: row.typeId,
			priority: row.priority,
		};
	}, []);
	
	//row create handler
	const onCreate = useCallback<PHONE_NUMBERS_GRID_PROPS["onRowCreate"]>(async (row) => {
		//get current group info
		const { groupTypeId, groupId } = groupRef.current;

		//create the record
		return await createPhoneNumber({ groupTypeId, groupId, ...row });
	}, [createPhoneNumber]);

	//row update handler
	const onUpdate = useCallback<PHONE_NUMBERS_GRID_PROPS["onRowUpdate"]>(async (id, row) => {
		//get the row record
		const phoneNumber = apiRef.current.getRow(id);
		if (phoneNumber === null) throw new Error("Unable to update row which does not exist.");

		//update the record
		return await updatePhoneNumber(phoneNumber.id, { ...phoneNumber, ...row });
	}, [apiRef, updatePhoneNumber]);
	
	//row delete handler
	const onDelete = useCallback<Exclude<PHONE_NUMBERS_GRID_PROPS["onRowDelete"], undefined>>(async (id, phoneNumber) => {
		//confirm the user wants to delete the phone number
		const deleteConfirmed  = await confirm({
			title: "Delete Confirmation",
			prompt: (
				<>
					<Typography variant={"body1"}>{`Are you sure you would like to delete the following ${(phoneNumberTypeOptions.find(x => x.value === phoneNumber.typeId)?.label ?? "phone").toLowerCase()} number?`}</Typography><br/>
					<Typography variant={"body1"} sx={{ textAlign: 'center' }}>{phoneNumber.number}</Typography>
				</>
			),
			confirmText: "Delete",
			confirmIcon: <DeleteIcon/>
		});
		if (!deleteConfirmed) throw new Error();
		
		//delete the record
		await deletePhoneNumber(id);
	}, [phoneNumberTypeOptions, deletePhoneNumber, confirm]);

	return (
		<EditDataGrid<PhoneNumber, PhoneNumberRM, PHONE_NUMBERS_EDITABLE_COLUMNS, PHONE_NUMBERS_GRID_COLUMNS, "row">
			apiRef={apiRef}
			editMode={"row"}
			experimental_disableCreateReorder
			columnHeaderHeight={showHeaders ? undefined : 0}
			loading={!phoneNumbers || !phoneNumberTypes}
			rows={phoneNumbers ?? []}
			columns={columns}
			rowValidator={validator}
			onRowCreate={onCreate}
			onRowUpdate={onUpdate}
			onRowDelete={onDelete}
			sx={{ border: 'none' }}
		/>
	);
});