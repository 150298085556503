import { Alignment } from '@imas/components/layout';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Client, ClientPurchaseOrder } from '@imas/api/client';
import { useClientPurchaseOrders } from '@imas/api/data-hooks';
import { TDataGrid, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs, TypedGridFilterModel, getColumnTypes, USD_COL_V6 } from '@imas/data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Typography, IconButton, Tooltip, lighten, alpha } from '@mui/material';
import { memo, useMemo, useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { FormPaper } from '@imas/mui-form';
import { makeStyles } from '@imas/styles';
import { openPurchaseOrderEditor } from '../../ClientPurchaseOrderEditor/ClientPurchaseOrderEditor';

export interface ClientPurchaseOrderRM extends Omit<ClientPurchaseOrder, "issuedDate" | "expirationDate"> {
	issuedDate: Date;
	expirationDate: Date | null;
};

//define the column order
export type CLIENT_PURCHASE_ORDERS_GRID_COLUMNS = ["actions", "number", "revision", "type", "amount", "issuedDate", "expirationDate", "notes", "discontinued"];

//define the filter model type
export type CLIENT_PURCHASE_ORDERS_FILTER_MODEL = TypedGridFilterModel<ClientPurchaseOrder, ClientPurchaseOrderRM>;

//define the grid api ref type
export type CLIENT_PURCHASE_ORDERS_GRID_API_REF = TypedGridApiRef<ClientPurchaseOrder, ClientPurchaseOrderRM, never, "row">;

//define grid props type
export type CLIENT_PURCHASE_ORDERS_GRID_PROPS = TypedEditDataGridProps<ClientPurchaseOrder, ClientPurchaseOrderRM, never, CLIENT_PURCHASE_ORDERS_GRID_COLUMNS, "row">

//export const CLIENT_PURCHASE_ORDERS_FILTER: CLIENT_PURCHASE_ORDERS_FILTER_MODEL = { items: [{ id: 1, field: 'active', operator: 'is', value: true }], logicOperator: GridLogicOperator.And };
//client/contact type options
export const CLIENT_PURCHASE_ORDERS_TYPE_OPTIONS = [{ label: "Signed", value: 1 }, { label: "Written", value: 2 }, { label: "Verbal", value: 3 }];

//get custom col types
const CLIENT_PURCHASE_ORDERS_COL_TYPES = getColumnTypes(["usd", "int"]);

const useStyles = makeStyles()((theme) => ({
	inactive: {
        background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.7) : alpha(theme.palette.error.dark, 0.60),
        "&.MuiDataGrid-row:hover": {
            background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.6) : alpha(theme.palette.error.dark, 0.75),
        },
        "&.MuiDataGrid-row.Mui-selected, &.MuiDataGrid-row.Mui-selected:hover": {
            background: theme.palette.mode === 'light' ? lighten(theme.palette.error.light, 0.35) : alpha(theme.palette.error.dark, 0.90),
        },
    },
}));

interface ClientPurchaseOrdersProps {
	client?: Client | null;
};

export const ClientPurchaseOrders = memo(({ client }: ClientPurchaseOrdersProps) => {
	//styling
	const { classes } = useStyles();

	//get client purchase orders
	const [purchaseOrders] = useClientPurchaseOrders(client?.id, { disabled: !client });

	//edit click callback
	const handleEditClick = useCallback((id: int | null) => () => {
		if (client === undefined || client === null) return;

		//open editor
		openPurchaseOrderEditor(id, client.id);
	}, [client]);

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<ClientPurchaseOrder, ClientPurchaseOrderRM, never, CLIENT_PURCHASE_ORDERS_GRID_COLUMNS, "row"> => {
        return [
			{
				field: 'actions',
				headerName: '',
				type: 'actions',
				width: 20,
				renderHeader: () => (
					<Tooltip title={"Create Purchase Order"} arrow>
						<IconButton
							size={"small"}
							disabled={client === undefined || client === null}
							onClick={() => { 
								openPurchaseOrderEditor(null, client?.id ?? -1);
							}}
						><AddIcon/></IconButton>
					</Tooltip>
				),
				getActions: ({ id }) => ([
					<GridActionsCellItem onClick={handleEditClick(id)} icon={<EditIcon/>} label={"Edit"} color={"inherit"}/>
				]),
			},
            { field: 'number', headerName: 'PO No.', width: 220 },
            { field: 'revision', headerName: 'Rev', width: 60, type: 'int', valueFormatter: ({ value }) => value > 0 ? `${value}` : "-" },
            { field: 'type', headerName: 'Type', width: 70, type: 'singleSelect', valueOptions: CLIENT_PURCHASE_ORDERS_TYPE_OPTIONS, valueFormatter: ({ value }) => CLIENT_PURCHASE_ORDERS_TYPE_OPTIONS.find(x => x.value === value)?.label ?? "" },
            { field: 'amount', headerName: 'Amount', width: 100, ...USD_COL_V6 },
            { 
				field: 'issuedDate', 
				headerName: 'Issued On', 
				width: 100,
				type: 'date',
				valueGetter: ({ row }) => moment(row.issuedDate).toDate(),
				valueFormatter: ({ value }) => moment(value).format("M/D/YYYY"),
			},
            { 
				field: 'expirationDate', 
				headerName: 'Expires On', 
				width: 110,
				type: 'date',
				valueGetter: ({ row }) =>  row.expirationDate ? moment(row.expirationDate).toDate() : null,
				valueFormatter: ({ value }) => value === null ? "--/--/----" : moment(value).format("M/D/YYYY"),
			},
            { 
				field: 'notes', 
				headerName: 'Notes', 
				flex: 1
			},

			//hidden fields
			{ field: 'discontinued', headerName: 'Disc', width: 70, type: 'boolean'/* , hide: true */ },
        ];
    }, [handleEditClick, client]);

	return (
		<FormPaper sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
			<Alignment row sx={{ margin: '3px 3px 0 10px' }}>
				<Typography variant={"h6"} sx={{ fontWeight: 'bold', color: 'text.primary' }}>{"Purchase Orders"}</Typography>

				{/* New Purchase Order for current Client */}
				<LoadingButton
					variant={"contained"}
					color={"primary"}
					size={"small"}
					startIcon={<AddIcon/>}
					disabled={client === null || client === undefined}
					loading={client !== null && client === undefined}
					loadingPosition={"start"}
					onClick={() => { 
						if (!client) return;

						//open editor
						openPurchaseOrderEditor(null, client?.id ?? -1);
					}}
					sx={{ marginLeft: 'auto' }}
				>{"Purchase Order"}</LoadingButton>
			</Alignment>

			{client === null ? (
				<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until client is saved."}</Typography>
			) : client ? (
				<Alignment column flex>
					<TDataGrid<ClientPurchaseOrder, ClientPurchaseOrderRM, never, CLIENT_PURCHASE_ORDERS_GRID_COLUMNS, "row">
						editMode={"row"}
						loading={!purchaseOrders}
						rows={purchaseOrders ?? []}
						columnTypes={CLIENT_PURCHASE_ORDERS_COL_TYPES}
						columns={columns}
						columnVisibilityModel={{
							discontinued: false
						}}
						getRowClassName={({ row }) => row.discontinued ? classes.inactive : ""}
						sx={{ border: 'none' }}
					/>
				</Alignment>
			) : null}
		</FormPaper>
	);
});