import { Button } from '@mui/material';
import { FolderInfoBreadcrumb, FolderInfoItem } from '@imas/api/files/types';
import { Alignment } from '@imas/components/layout';
import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import { FileExplorerContext } from '../../FileExplorerContext';
import moment from 'moment';
import clsx from 'clsx';
import { NativeTypes } from 'react-dnd-html5-backend';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useFileExplorerItemStyles } from '../../FileExplorerItemContainer/FileExplorerItemList/FileExplorerItemListRow';

export interface NavigationBreadcrumbProps {
    data: FolderInfoBreadcrumb;
    isLast: boolean;
};

const NavigationBreadcrumb = ({ data, isLast }: NavigationBreadcrumbProps) => {
    //get select checkboxes preference
    const { mode, canUpload, currentDir, canNavigateTo, navigate, upload } = useContext(FileExplorerContext);
    
    //component styling
    const { classes } = useFileExplorerItemStyles({ mode });

    //drop hook for moving file to new folder or uploading a new file
    const [{ isTarget }, drop] = useDrop(() => ({
        accept: ["MOVE_FILE", NativeTypes.FILE],
        drop: (item, monitor) => { 
            const destination: FolderInfoItem = { name: data.name, pathLocator: data.pathLocator, lastWrite: moment().format(), type: null, size: 0, isDir: true };

            //return the target folder if type is "MOVE_FILE"
            if (monitor.getItemType() === "MOVE_FILE") return destination;
            //call upload handler if the item type is native file and provide file list & folder info 
            else if (monitor.getItemType() === NativeTypes.FILE) upload((item as { files: File[] }).files, destination);
        },
        canDrop: (item, monitor) => {
            //ensure target is a folder & is not selected if type is "MOVE_FILE"
            if (monitor.getItemType() === "MOVE_FILE") return data.pathLocator !== currentDir.pathLocator && canNavigateTo(data.pathLocator);
            //ensure the target is a folder, canUpload is true, & the target folder is accessable
            else if (monitor.getItemType() === NativeTypes.FILE) return canUpload && canNavigateTo(data.pathLocator);
            return false;
        },
        collect: (monitor) => ({
            isTarget: monitor.canDrop() && monitor.isOver(),
        }),
    }), [data, currentDir, canUpload]);

    return (
        <Alignment row>
            <Button
                ref={drop}
                disabled={!canNavigateTo(data.pathLocator)}
                variant={"text"} 
                onClick={() => navigate(data.name, data.pathLocator)}
                className={clsx({ [classes.dropTarget]: isTarget })}
                sx={{ textTransform: 'none', padding: '3px 8px', borderRadius: '0', minWidth: '0', color: 'text.primary', whiteSpace: 'nowrap', flexShrink: 0 }}
            >{data.name}</Button>
            
            {isLast ? null : <KeyboardArrowRight sx={{ color: 'text.secondary', margin: 'auto 3px', height: '20px' }}/>}
        </Alignment>
    );
};

export { NavigationBreadcrumb };