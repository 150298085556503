import React, { useCallback, useMemo } from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { FormInputProps, SelectOption, OptionGenerator, OptionsGenerator } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';

export interface FormNumberSelectProps<TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<number | null, TFieldValues, TName>, 
    Omit<TextFieldProps, "name" | "defaultValue" | "error" | "helperText" | "value" | "onChange" | "onBlur" | "ref" | "SelectProps" | "children"> 
{
    noneSelectedText?: string;
    options: OptionsGenerator<number> | SelectOption<number>[];
};

const FormNumberSelect = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
        noneSelectedText, options,
        ...textFieldProps
    }: FormNumberSelectProps<TFieldValues, TName>
) => {
    //use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
		context: { size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

    //callback for generating an option
    const generateOption: OptionGenerator<number> = useCallback((value: number, label: React.ReactNode, disabled?: boolean) => ({ value, label, disabled }), []);

    //get the options as a memoized value
    const optionsList: SelectOption<number>[] = useMemo(() => {
        //if a OptionsGenerator was provided then use it to generate the options list
        if (typeof options === "function") {
            return options(generateOption);
        } else {
            return options ?? [];
        }
    }, [options, generateOption]);

    //render loading
    if (isLoading) return <FormFieldSkeleton {...textFieldProps} size={textFieldProps.size ?? size}/>;

    //render TextField Select 
    return (
        <TextField
            {...textFieldProps}
			size={textFieldProps.size ?? size}
            disabled={isViewOnly || textFieldProps.disabled}
            select={true}
            name={name}
            required={isRequired}
            error={hasError}
            helperText={helperText}
            ref={ref}
            value={value ?? ""}
            onChange={e => onChange(e.target.value === "no-value-selected-item" ? null : isNaN(Number(e.target.value)) ? null : Number(e.target.value))}
            onBlur={handleBlur}
        >
            {!isRequired && value !== null ? <MenuItem value={"no-value-selected-item"} disabled={isRequired}>{noneSelectedText ?? "None"}</MenuItem> : null}

            {/* Generate a MenuItem for each option in the list. */}
            {optionsList.map(option => (
                <MenuItem
                    key={option.value}
                    value={option.value}
					disabled={option.disabled}
                >{option.label}</MenuItem>
            ))}
        </TextField>
    );
};

export { FormNumberSelect };