import { EmployeeBasics } from '@imas/api/employee';
import { Typography, createFilterOptions, AutocompleteProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

const EmployeeListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<VirtualizedListboxAdpater 
			{...props} 
			ref={ref} 
			renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<EmployeeBasics>) => {
				const row = data[index];
			
				return (
					<Typography 
						component={"li"} 
						{...row.props} 
						noWrap 
						style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}
					>{`${row.option.name}`}</Typography>
				);
			}}
		/>
	);
});

const employeesFilter = createFilterOptions<EmployeeBasics>({ stringify: (employee) => `${employee.name}` });

export const EMPLOYEES_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<EmployeeBasics, false, false, false, "div">, "filterOptions" | "getOptionLabel" | "getOptionDisabled" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {
	filterOptions: employeesFilter,
	getOptionLabel: (employee) => `${employee.name}`,
	getOptionDisabled: (employee) => !employee.active,

	//virtualization props
	disableListWrap: true,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: EmployeeListboxAdapter,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};