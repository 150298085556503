import { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const PhoneNumberInput = forwardRef<HTMLElement, {}>((props, ref) => {
	const { ...other } = props;

	return (
        (<MaskedInput
			{...other}
			ref={ref as any}
			mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>)
    );
});

export { PhoneNumberInput };