import axios, { CancelTokenSource } from "axios";
import { ApiFunctionResult, WrappedApiFunction } from "./types";

//get an api outside of a react component
export const getApi = <A extends any[], R extends ApiFunctionResult<any>>(wrappedApiFunction: WrappedApiFunction<A, R>) => {
    //list of sources
    var sources: CancelTokenSource[] = [];

    //source getter
    const getSource = () => {
        //create source
        const source = axios.CancelToken.source();
        
        //add it to list of sources
        sources.push(source);

        //return new source
        return source;
    };

    //return the unwrapped api function and a cancel function
    return {
        api: wrappedApiFunction(getSource),
        cancel: (): void => {
            //cancel all sources
            sources.forEach(x => x.cancel());

            //reset array
            sources = [];
        },
    };
};