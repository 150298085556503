import axios from "axios";
import { SourceGetter } from "@imas/api";
import { MobileCarrier } from "./types";

//mobile carriers base url
const MOBILE_CARRIERS_URL = "/api/system/mobile-carriers";

/** Gets all mobile carrier records */
export const GetMobileCarriers = (getSource: SourceGetter) => {
    return async (): Promise<MobileCarrier[]> => {
        //make API call
        const result = await axios({
            method: 'get',
            url: `${MOBILE_CARRIERS_URL}`,
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};

/** Create a new record */
export const CreateMobileCarrier = (getSource: SourceGetter) => {
    return async (carrierInfo: MobileCarrier): Promise<MobileCarrier> => {
        //make API call
        const result = await axios({
            method: 'post',
            url: `${MOBILE_CARRIERS_URL}`,
            data: carrierInfo,
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};

/** Update a record */
export const UpdateMobileCarrier = (getSource: SourceGetter) => {
    return async (carrierInfo: MobileCarrier): Promise<MobileCarrier> => {
        //make API call
        const result = await axios({
            method: 'put',
            url: `${MOBILE_CARRIERS_URL}`,
            data: carrierInfo,
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};