import React, { useState, useCallback, useEffect } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Divider, Dialog, DialogContent, DialogActions, Box, Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserData, getUserRoles } from '@imas/redux';
import { useApi, useLoadApi } from '@imas/api';
import { Alignment, XSkeleton } from '@imas/components/layout';
import { Warning as WarningIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { employeeAlertsViewerStyles } from './EmployeeAlertsViewerStyles';
import { GetEmployeeAlerts, TimeOffAlertsResult, GetAllEmployeeViolations,vEmployeeViolation, SignViolation, EmployeeViolationForm } from '@imas/api/employee';
import { GetHolidays } from '@imas/api/misc/holidays';
import { Holiday } from '@imas/api/misc/types/Holiday';
import { Message, vVehicleInspection } from 'src/api/types/api/misc/misc';
import { openVehicleInspectionEditor } from 'src/pages/Home/Vehicles/VehicleInspection/VehicleInspectionEditor';
import { useOpenVehicleInspections } from '@imas/api/data-hooks/misc';
import { Image as ImageIcon } from '@mui/icons-material';
import { GetRecentMessages } from 'src/api/misc/messages';
import { Editable, FormNumberTextField, FormTextField, MuiForm, MuiFormPartial, MuiFormRef, MuiFormSubmit, useMuiForm, useMuiFormRef, MuiFormSubmitProps } from "@imas/mui-form";
import { EmployeeViolationForm as TEmployeeViolationForm } from '@imas/api/employee';
import { useDeviceInfo } from '@imas/styles';
import SignatureCanvas from 'react-signature-canvas';
import { width } from '@mui/system';
import { ConsoleLine } from 'mdi-material-ui';
import { set } from 'ol/transform';
export const EmployeeAlertsViewer = () => {
    const PENDING_SIGNATURE = 0;
    //component styling

    const { classes } = employeeAlertsViewerStyles();

    //get user roles and data
    //const userRoles = useSelector(getUserRoles);
	const userData = useSelector(getUserData);
    let sigCanvas: SignatureCanvas | null;
    // State to track if the canvas is empty
    const [text, setText] = useState<string>("");

    //get information
    const { data: alerts } = useLoadApi(GetEmployeeAlerts, [userData?.employee.employeeId ?? -1], [userData], {disabled: userData === null});
    const { data: violations, call: refreshViolations} = useLoadApi(GetAllEmployeeViolations, [userData?.employee.employeeId ?? -1], [userData], {disabled: userData === null});
	const { data: holidays } = useLoadApi(GetHolidays, [moment()]);


    //Violation constants
    const signViolation = useApi(SignViolation);
    const { data: notifications } = useLoadApi(GetRecentMessages, [], []);
	const [ openInspections ] = useOpenVehicleInspections({disabled: userData === null || !userData.employee.fleetManager});
    const [open, setOpen] = useState(false);
    const [violation, setViolation] = useState<vEmployeeViolation>();
    const deviceInfo = useDeviceInfo();
    const [showWarningText, setShowWarningText] = useState(false);
    const openViolation = (id: vEmployeeViolation) => {
        setViolation(id);
        setOpen(true);
    };

    const handleClearSignature = () => {
        sigCanvas?.clear();
    };
    
    const handleCloseDialog = () => {
        setViolation(undefined);
        setOpen(false);
        setShowWarningText(false);
        setText('');
        sigCanvas?.clear();
        refreshViolations();
    };
    
    //render nothing of the user is not a admin or manager
    //if (!(userRoles.admin || userRoles.manager)) return null;

    const EmployeeAlertsViewerCategory = ({ label, items }: { label: string, items?: TimeOffAlertsResult[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.alertItem}>
                            <ListItemText secondary={item.alertDescription}/>
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

	const HolidayViewerCategory = ({ label, items }: { label: string, items?: Holiday[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.listItem}>
                            <ListItemText primary={item.holidayName} secondary={moment(item.holidayDate).format("ddd, MMM Do")}/>
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

	//vehicle inspection notifications are for fleet managers only
	const VehicleInspectionCategory = ({ label, items }: { label: string, items?: vVehicleInspection[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;
		if (!items) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.listItem} button onClick={() => openVehicleInspectionEditor({calVehInspectionId: item.calVehInspectionId})}>
                            <ListItemText primary={`Vehicle ${item.calItemVehicle.substring(0,2)} (${item.inspectionIncompleteSummary})`} secondary={moment(item.inspectionDate).format("ddd, MMM Do, YYYY")}/>{item.inspectionWithFiles ? <ImageIcon/> : null}
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

    //show recent custom notification messages
	const RecentNotificationsCategory = ({ label, items }: { label: string, items?: Message[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;
		if (!items) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.listItem} >
                            <ListItemText primary={`${item.title}`} 
                                secondary={ item.senderName + (item.body !== null ? " - " : null) + item.body}
                            />
                        </ListItem> 
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

    //Submit for signature
    const onSubmit = async (id: int, url: string, statement: string) => {
     
        if( sigCanvas?.isEmpty() === true)
        {
            setShowWarningText(true);
            return;
        }
        
        const base64Content = url.replace('data:image/png;base64,', ''); // Remove the prefix
   
   
        let form : EmployeeViolationForm = {
            id: id,
            statement: statement,
            url: base64Content,
        };
   
        try {
            await  signViolation(form);
        } catch(e){
   
            return -1;
        }
   
        handleCloseDialog();
   
    };
    
  

    const EmployeeViolation = ({ label, items }: { label: string, items?: vEmployeeViolation[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;
        
        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.violationItem} onClick={() => openViolation(item)}>
                            <Alignment column>

                            <ListItemText primary={`${item.violationTypeDesc}` +  ` - ${item.violationActionTaken}`}
                            secondary={moment(item.violationdate).format("ddd, MMM Do")}/>
                            <Alignment row>
                            <WarningIcon sx={{fontSize: '18px', marginRight: '5px'}} color={'warning'}/>
                            <Typography  sx={{fontSize: '15px'}} color={'textPrimary'}><> Requires signature</></Typography>

                            </Alignment>
                            </Alignment>

                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
                
            </React.Fragment>
        );
    };


    return (
        <>
        <Paper className={classes.viewerContainer}>
            <Typography variant={"h5"} style={{ fontWeight: 'bold', margin: '10px auto' }}>{"Notifications"}</Typography>
            
            <Alignment overflowScroll style={{ margin: '0 15px', flex: '1 1 0' }}>
                {/* Time Off */}
                <EmployeeAlertsViewerCategory label={"Time Off"} items={alerts}/>

                {/* Recent Notifications */}
                <RecentNotificationsCategory label={"Recent Alert Notifications"} items={notifications?.sort((x, y) => moment(x.dateAdded).isBefore(y.dateAdded) ? 1 : -1)}/>

				{/* Open Vehicle Inspections, fleet managers only */}
				<VehicleInspectionCategory label={'Vehicle Issues'} items={openInspections?.sort((x, y) => moment(x.inspectionDate).isBefore(y.inspectionDate) ? 1 : -1)}/>

				{/* Holidays */}
				<HolidayViewerCategory label={"Upcoming Holidays"} items={holidays}/>

                {/* Violations */}
                <EmployeeViolation label={'Violations'} items={violations?.filter(x => x.violationStatusInd === PENDING_SIGNATURE)}/>

            </Alignment>
        </Paper>
        {/* Violation Form for when the user has a violation and clicks on said violation*/}
        <Dialog
        open={open}
        onClose={() => {
            setViolation(undefined);
            setOpen(false);
            setText("");
        }
    }
    fullScreen={!deviceInfo.isLg}
    
        >
            {/* Violation Dialog, where employee's sign. */}
            <DialogContent>
                <Alignment column>
                    <Alignment row sx={{justifyContent: 'space-between'}}>
                    <Alignment row>

                        
<Typography fontWeight={'bold'} sx={{marginRight: '5px',fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Notice Date: `}</Typography>
<Typography sx={{marginRight: '5px',fontSize: !deviceInfo.isLg ? '13px' : null}}>{moment(violation?.violationnoticedate).format('MM/DD/YYYY')}</Typography>
</Alignment>

<Alignment row>
<Typography fontWeight={'bold'} sx={{marginRight: '5px',fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Created on: `}</Typography>
<Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>{moment(violation?.violationdate).format('MM/DD/YYYY')}</Typography>
</Alignment>
                    </Alignment>
                       
                <Typography variant={'h6'} fontWeight={'bold'} sx={{marginTop: '5px', fontSize: !deviceInfo.isLg ? '15px' : null}} >Violation</Typography>
                <Divider/>
                <Alignment row>
                    <Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Occured on: `}</Typography>
                    <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>{moment(violation?.violationdate).format('MM/DD/YYYY')}</Typography>
                    </Alignment> 
                    <Alignment row>
                    <Typography fontWeight={'bold'} sx={{marginRight: '5px',fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Type:`}</Typography>
                    <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>{violation?.violationTypeDesc}</Typography>
                    </Alignment>         
                    <Alignment row>
                    <Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Details:`}</Typography>
                    <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>{violation?.violationdescription}</Typography>
                    </Alignment>  
                    <Typography variant={'h6'} fontWeight={'bold'} sx={{marginTop: '5px', fontSize: !deviceInfo.isLg ? '15px' : null}}>Action Taken</Typography>
                <Divider/>
                <Alignment row>                       
                <Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Type: `}</Typography>
                <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>{violation?.violationActionTaken}</Typography>
                </Alignment>
                <Alignment row>
                        {violation?.violationactionstartdate ? <> <Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Start: `}</Typography>
                        <Typography sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{violation?.violationactionstartdate ? moment(violation?.violationactionstartdate).format('MM/DD/YYYY') : null}</Typography></>: null}
                   
                    {violation?.violationactionenddate ?<><Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{`End: `}</Typography>
                    <Typography sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{violation?.violationactionenddate ? moment(violation?.violationactionenddate).format('MM/DD/YYYY') : null}</Typography></> : null}
                    
                    </Alignment>  
                    <Alignment row>                       
                <Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>{`Discussion: `}</Typography>
                <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}} >{violation?.violationdiscussion}</Typography>
                </Alignment>       
                {/* Statement */}      
                <TextField
                label={'Employee Statement:'}
                fullWidth
                multiline
                rows={4}
                value={text}
            style={{ marginTop: "15px", width: "-webkit-fill-available" }}
            onChange={e => setText(e.target.value)}

                />
                <Typography variant={'h6'} fontWeight={'bold'} sx={{marginTop: '5px', fontSize: !deviceInfo.isLg ? '15px' : null}} >Acknowledgement</Typography>
                <Divider/>
                <Typography fontWeight={'bold'} sx={{fontSize: !deviceInfo.isLg ? '12px' : null}}>By signing this notice, I am acknowledging that I have been counseled about my inapporopriate conduct and informed of consequences if improvements are not made.</Typography>
                <Alignment row sx={{justifyContent: 'space-between', marginTop: '5px'}}>
                <Alignment row>
                    <Typography fontWeight={'bold'} sx={{marginRight: '5px',fontSize: !deviceInfo.isLg ? '13px' : null}}>
                        Employee:
                    </Typography>
                    <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>
                        {`${violation?.employeeName}`}
                    </Typography>
                </Alignment>
                <Alignment row>
                    <Typography fontWeight={'bold'} sx={{marginRight: '5px', fontSize: !deviceInfo.isLg ? '13px' : null}}>
                        Supervisor:
                    </Typography>
                    <Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>
                        {`${violation?.managerName}`}
                    </Typography>
                </Alignment>
                
                </Alignment>
                </Alignment>

                <Alignment column>
                


                

                <Box sx={{justifyContent:'center', alignSelf:'center', border:2, borderColor: 'black'}}>
                    {/* Signature */}
            <SignatureCanvas
                 canvasProps={{width:350, height:150}}
                 ref={(ref) => {{sigCanvas = ref;}}}
                 backgroundColor={'#ffffff'}
                minWidth={2}
            
             >
            </SignatureCanvas>
            </Box>
            {showWarningText ? 
<Alignment row>
    {/* if the signature is empty display */}
    <WarningIcon sx={{fontSize: !deviceInfo.isLg ? '17px' : null, marginRight: '10px'}}/>
<Typography sx={{fontSize: !deviceInfo.isLg ? '13px' : null}}>
     {"Signature is Empty!"}

</Typography>                        </Alignment>
                
: null}
            
                </Alignment>
                
                
            </DialogContent>
            <DialogActions>
            <Button
                onClick={handleClearSignature}>
                Clear
            </Button>
            <Button 
                onClick={() => onSubmit(violation?.id ?? -1, sigCanvas?.toDataURL() ?? "", text)}
            >
                {"Submit"}
            </Button>
            <Button onClick={() => handleCloseDialog()}>
                {'Cancel'}
            </Button>

            </DialogActions>
            
        </Dialog>
        </>


    );
};