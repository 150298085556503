import { axiosData, SourceGetter } from '@imas/api';
import { FileAccessTicketResult, FileTables } from './types';

//proposal pre liens base url
const FILE_ACCESS_TICKETS_URL = "/api/files/access-tickets";

/*  */
export const CreateFileAccessTicket = (getSource: SourceGetter) => {
    return async (table: FileTables, pathLocator: HierarchyId): Promise<FileAccessTicketResult> => {
        return axiosData({
			method: 'post',
            url: `${FILE_ACCESS_TICKETS_URL}`,
			data: {
				table,
				pathLocator,
			},
            cancelToken: getSource().token,
		});
    };
};