import { HOME, CLIENTS_AND_CONTACTS, PROPOSALS, SERVICE_ORDERS, CERTS_AND_QC, MANAGER, USER, ADMIN} from './categories';
import { Menu, MenuCategory, MenuOption } from './types';

// Available Menus
const IMAS_MENU: Menu = [HOME, CLIENTS_AND_CONTACTS, PROPOSALS, SERVICE_ORDERS, CERTS_AND_QC, MANAGER, ADMIN]; //,  SCHEDULE, CHARGES, PO ];

const SUB_NAVIGATION_MENU: Menu = [...IMAS_MENU, USER];

export { IMAS_MENU, SUB_NAVIGATION_MENU };
export type { Menu, MenuCategory, MenuOption };
