import { ServiceOrderAttachment, UpdateServiceOrderAttachmentRequest } from './types';
import { SourceGetter, axiosData, objectToFormData } from '@imas/api';

//service order attachments base url
const SO_ATTACHMENTS_URL = "/api/service-orders/attachments";

/*  */
export const UploadServiceOrderAttachments = (getSource: SourceGetter) => {
    return async (uniqueId: int, attachments: File[]): Promise<{ attachments: ServiceOrderAttachment[], failedFiles: string[] }> => {
		return axiosData({
			method: 'post',
			url: `${SO_ATTACHMENTS_URL}`,
			data: objectToFormData({ uniqueId, files: attachments }),
			headers: { "Content-Type": "multipart/form-data" },
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const UpdateServiceOrderAttachment = (getSource: SourceGetter) => {
    return (attachmentId: int, data: UpdateServiceOrderAttachmentRequest): Promise<ServiceOrderAttachment> => {
		return axiosData({
			method: 'put',
			url: `${SO_ATTACHMENTS_URL}/${attachmentId}`,
			data,
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const DeleteServiceOrderAttachment = (getSource: SourceGetter) => {
    return (attachmentId: int): Promise<ServiceOrderAttachment> => {
		return axiosData({
			method: 'delete',
			url: `${SO_ATTACHMENTS_URL}/${attachmentId}`,
			cancelToken: getSource().token,
		});
    };
};