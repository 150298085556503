import { LabeledItem, Spacer, Alignment } from '@imas/components/layout';
import { Typography, Dialog, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { memo, useMemo } from 'react';
import { SOReportVersionResult, VServiceOrderReportRevision } from 'src/api/types/api/service-orders/types';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';

interface ReportScoreDialogProps {
	entry: SOReportVersionResult | VServiceOrderReportRevision;
	open: boolean;
	onClose: () => void;
	reportVersion?: boolean;
}


export const ReportScoreDialog = memo(({ entry, open, onClose, reportVersion } : ReportScoreDialogProps) => {

	//return <></>;
	//const integrityScore = reportVersion ? entry.version


	const scoreErrors = useMemo(() => {
		if (entry.scoreNotes === null) return '';

		return ( entry.scoreNotes.split('breakLine').map((line) => {
			if (!line) return null;
			return <Typography color={'textPrimary'}>{line}</Typography>;
		})
		);
	}, [entry.scoreNotes]);

	return (
		<Dialog
			maxWidth={'lg'}
			open={open}
		>
			<DialogTitle>
				{'Report Score'}
			</DialogTitle>

			<DialogContent>
				<LabeledItem row label={"File:"} item={`${entry.name}`}/>
				<LabeledItem row label={"Editor:"} item={`${entry.abbrev}`}/>
				<LabeledItem row label={"Completeness:"} item={entry.completenessScore !== null ? `${(entry.completenessScore * 100).toFixed(0)}%` : 'Score not found.'}/>
				<LabeledItem row label={"Data Integrity:"} item={entry.integrityScore !== null ? `${(entry.integrityScore * 100).toFixed(0)}%` : 'Score not found.'}/>
				<LabeledItem row label={"Grammar/Spelling:"} item={entry.spellingScore !== null ? `${(entry.spellingScore * 100).toFixed(0)}%` : 'Score not found.'}/>
				<LabeledItem row label={"File Naming:"} item={entry.filenamingScore !== null ? `${(entry.filenamingScore * 100).toFixed(0)}%` : 'Score not found.'}/>
				<Spacer vertical size={'20px'}/>

				<LabeledItem row label={"Weighted Score:"} item={entry.computedScore !== null ? `${(entry.computedScore * 100).toFixed(0)}%` : 'Score not Found.'}/>
				<Spacer vertical size={'20px'}/>

				<LabeledItem label={"Feedback:"} item={
					<Alignment column>
						{scoreErrors}
					</Alignment>
				}/>
			</DialogContent>
			<DialogActions>
				<Button
        	        color={"secondary"}
        	        variant={"contained"}
        	        startIcon={<CloseIcon/>}
        	        onClick={onClose}
        	    >{"Close"}</Button>
			</DialogActions>

	</Dialog>
	);
});