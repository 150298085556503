import { useLoadApi } from '@imas/api';
import { Alignment } from '@imas/components/layout';
import { isPopup, openPopup } from '@imas/utils/misc';
import { SSRSPDFViewer } from '@imas/utils/pdf';
import { useParams, Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import { getEmployeeData, getUserRoles } from '@imas/redux';
import { FileViewerLoader } from '@imas/utils/files';
import { GetClientLocationContact } from '@imas/api/client';

export const openClientLocationContactReportViewer = (contactId: int) => {
	openPopup(
		IMAS.PAGES.CLIENTS.LOCATIONS.CONTACTS.REPORT_VIEW(contactId), 
		{ name: "Contact Report", height: 1200, width: 900 }
	);
};

export const ClientLocationContactReportViewer = () => {
	//page title
    document.title = "IMAS - Contact Report";
	
	//get serviceOrderId from router params
    const contactId = Number(useParams().contactId);

	//load the specified client location contact
    const { data: clientLocationContact, error: clientLocationContactError } = useLoadApi(GetClientLocationContact, [contactId], [contactId], { disabled: isNaN(contactId) });

	//get current employee info & roles
	//const currentEmployee = useSelector(getEmployeeData);
	//const { admin: isAdmin } = useSelector(getUserRoles);

	//if the current user can download/print the pdf
	//const canGetPdf = useMemo(() => {
	//	return (client && currentEmployee?.employeeId === client?.reviewedByEmployeeId) || isAdmin;
	//}, [client, currentEmployee, isAdmin]);

	//close form if there was an error loading the client
    if (isNaN(contactId) || clientLocationContactError) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

	//show PDF preview of report for the specified service order work place review
	return (
		<Alignment column flex>
			{/* SSRS PDF Report Viewer */}
			{clientLocationContact ? (
				<SSRSPDFViewer
					template={"CLIENT_LOCATION_CONTACT_INFO"}
					/** Report Name Given client ID */
					reportName={`Contact Report.${clientLocationContact.id}`}
					parameters={[contactId]}
					/** Only Shown Download & Print if Employee is the work place review creator or they are an admin. */
					showDownloadPDF={true}
					showPrintPDF={true}
				/>
			) : ( 
				<FileViewerLoader/>
			)}
		</Alignment>
	);
};