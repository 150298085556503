import moment, { Moment } from "moment";

export type PatchDataType = PatchObject | Moment | Date | string | number | boolean | null | undefined;

export type PatchObject = { [key: string]: PatchDataType };

interface PatchReplaceAction { 
    op: "replace"; 
    path: string;
    value: string | number | boolean | null;
};

export type PatchAction = PatchReplaceAction

export type PatchRequest = PatchAction[];

//accept a object and convert it into a patch request
export function objectToPatchRequest(object: PatchObject): PatchRequest {
    return serializeToPatchRequest(object);
};

function serializeToPatchRequest(object: PatchObject, pathPrefix: string = ""): PatchRequest {
    //actions
    let  actions: PatchAction[] = [];

    for (const key in object) {
        //get the value from the object & construct path
        const value = object[key];
        const path = `${pathPrefix}/${key}`;

        //if the value is undefined do nothing
        if (value === undefined) continue;
        else if (value instanceof Date) {
            actions.push({ op: "replace", path, value: value.toISOString() });
        }
        else if (moment.isMoment(value)) {
            actions.push({ op: "replace", path, value: value.toISOString() });
        }
        else if (value !== null && value instanceof Object) {
            actions = [...actions, ...serializeToPatchRequest(value, path)];
        }
        else {
            actions.push({ op: "replace", path, value });
        }
    }

    return actions;
};