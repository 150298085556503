import { useApi } from '@imas/api';
import { EditDataGrid, useTGridApiRef } from '@imas/data-grid';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { Delete as DeleteIcon, Warning as WarningIcon } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import { memo, useMemo, useCallback, useRef } from 'react';
import { TimeSheetCertificationHour, CreateTimeSheetCertificationHour, UpdateTimeSheetCertificationHour, DeleteTimeSheetCertificationHour } from '@imas/api/time-sheet';
import { 
	useTimeSheetCertificationHoursFormColDefs, weekdayList, getCertificationHourDayTotal,
	TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_COLUMNS, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, 
	TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_API_REF, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_PROPS, TimeSheetCertificationHoursFooter, TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH, TIME_SHEET_CERTIFICATION_METHOD_COL_WIDTH, TimeSheetCertificationHoursContext, isNewHourValueOverDayTotal
} from './TimeSheetCertificationHoursFormUtils';
import { useTimeSheetDetail, useTimeSheetDetailCertificationHours } from '@imas/api/data-hooks';
import { makeStyles } from '@imas/styles';
import { Alignment } from '@imas/components/layout';
import { roundFloat } from '@imas/utils/math';
import { TimeSheetWeekdayWidthMap, weekdayFullNameMap } from '../TimeSheetDetailsForm/TimeSheetDetailsFormUtils';
import Enumerable from 'linq';
import { GridRowModes } from '@mui/x-data-grid-pro';

const useStyles = makeStyles()((theme) => ({
	hidden: {
		display: 'none!important',
	},
	gridContainer: {
		borderWidth: '0 1px 1px 1px', 
		borderStyle: 'solid', 
		borderColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 
		borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
	},
}));

export interface TimeSheetCertificationHoursFormProps {
	/** ID of the Time Sheet Detail to show CertificationHours for.  */
	timeSheetDetailId: int;
	/** Disables the grid */
	disabled?: boolean;
	/** If Grid column headers should be hidden. */
	hideHeaders?: boolean;
	/** If the grid should be shown in management mode. */
	managementMode?: boolean;
	/** Specify hour column widths (Used for control by the TimeSheetDetailEditor) */
	hourColWidths?: TimeSheetWeekdayWidthMap;
};

export const TimeSheetCertificationHoursForm = memo(({ timeSheetDetailId, disabled, hideHeaders, managementMode, hourColWidths }: TimeSheetCertificationHoursFormProps) => {
	const { classes } = useStyles();
	
	//propsRef
	const timeSheetDetailIdRef = useRef(timeSheetDetailId);
	timeSheetDetailIdRef.current = timeSheetDetailId;

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

	//get TimeSheetDetail record
	const [detail] = useTimeSheetDetail(timeSheetDetailId);
	const detailRef = useRef(detail);
	detailRef.current = detail;

	//get TimeSheetCertificationHours for specified TimeSheetDetail
	const [certificationHours] = useTimeSheetDetailCertificationHours(timeSheetDetailId);
	const certificationHoursRef = useRef(certificationHours);
	certificationHoursRef.current = certificationHours;

	//get list of certifications already in use
	const existingCertificationIds = useMemo(() => (certificationHours ?? []).map(x => x.certificationId), [certificationHours]);

	//APIs
	const createCertificationHour = useApi(CreateTimeSheetCertificationHour);
	const updateCertificationHour = useApi(UpdateTimeSheetCertificationHour);
	const deleteCertificationHour = useApi(DeleteTimeSheetCertificationHour);

	//grid apiRef
	const apiRef: TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_API_REF = useTGridApiRef();

	//grid column defs
    const { columns, loading } = useTimeSheetCertificationHoursFormColDefs({ detail, existingCertificationIds, managementMode: managementMode ?? false, hourColWidths });

	//validator
	const validator = useCallback<TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_PROPS['rowValidator']>(async (data, row) => {
		if (!detailRef.current) throw new Error('Form is still loading, please wait.');
		if (data.certificationId === undefined) throw new Error('Method is required.');

		//validate new column total does not exceed parrent detail record for each day
		for (const day of weekdayList) {
			if (isNewHourValueOverDayTotal(data[day], day, detailRef.current, certificationHoursRef.current ?? [], row)) {
				throw new Error(`Hours for ${weekdayFullNameMap[day]} would bring total certification hours to greater than ${detailRef.current[day]}.`);
			}
		}

		return {
			certificationId: data.certificationId,
			sat: typeof data.sat === 'number' ? roundFloat(data.sat, 0.25) : null,
			sun: typeof data.sun === 'number' ? roundFloat(data.sun, 0.25) : null,
			mon: typeof data.mon === 'number' ? roundFloat(data.mon, 0.25) : null,
			tue: typeof data.tue === 'number' ? roundFloat(data.tue, 0.25) : null,
			wed: typeof data.wed === 'number' ? roundFloat(data.wed, 0.25) : null,
			thu: typeof data.thu === 'number' ? roundFloat(data.thu, 0.25) : null,
			fri: typeof data.fri === 'number' ? roundFloat(data.fri, 0.25) : null,
		};
	}, []);
	
	//row create handler
	const onCreate = useCallback<TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_PROPS["onRowCreate"]>(async (row) => {
		//get current timeSheetId
		const timeSheetDetailId = timeSheetDetailIdRef.current;

		//create the record
		return await createCertificationHour({ timeSheetDetailId, ...row });
	}, [createCertificationHour]);

	//row update handler
	const onUpdate = useCallback<TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_PROPS["onRowUpdate"]>(async (id, row) => {
		//get current timeSheetId
		const timeSheetDetailId = timeSheetDetailIdRef.current;

		//update the record
		return await updateCertificationHour(id, { timeSheetDetailId, ...row });
	}, [updateCertificationHour]);

	//row delete handler
	const onDelete = useCallback<Exclude<TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_PROPS["onRowDelete"], undefined>>(async (id) => {
		//confirm the user wants to delete the phone number
		const deleteConfirmed  = await confirm({
			title: "Delete Confirmation",
			prompt: (
				<>
					<Typography variant={"body1"}>{`Are you sure you would like to delete these method?`}</Typography><br/>
				</>
			),
			confirmText: "Delete",
			confirmIcon: <DeleteIcon/>
		});
		if (!deleteConfirmed) throw new Error();
		
		//delete the record
		await deleteCertificationHour(id);
	}, [deleteCertificationHour, confirm]);

	// TODO: The resize here is not working correctly, apiRef seems to not exist when effect is called causing a crash. Resize of hour columns should be fine for now since it should not even be necessary
	//update hour col widths
	// useEffect(() => {
	// 	if (!hourColWidths || !apiRef.current) return;

	// 	apiRef.current?.setColumnWidth('sat', hourColWidths.sat);
	// 	apiRef.current?.setColumnWidth('sun', hourColWidths.sun);
	// 	apiRef.current?.setColumnWidth('mon', hourColWidths.mon);
	// 	apiRef.current?.setColumnWidth('tue', hourColWidths.tue);
	// 	apiRef.current?.setColumnWidth('wed', hourColWidths.wed);
	// 	apiRef.current?.setColumnWidth('thu', hourColWidths.thu);
	// 	apiRef.current?.setColumnWidth('fri', hourColWidths.fri);
	// }, [hourColWidths]);

	// useEffect(() => {
	// 	const unReg1 = apiRef.current.subscribeEvent('rowMode', (a, b, c) => setUnsavedRecordCount(x => x + 1));
	// 	const unReg2 = apiRef.current.subscribeEvent('rowEditStop', (a, b, c) => setUnsavedRecordCount(x => x - 1));

	// 	return () => {
	// 		unReg1();
	// 		unReg2();
	// 	};
	// }, [apiRef]);

	//calculate width
	const width = useMemo(() => {
		return (
			(hourColWidths?.sat ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			(hourColWidths?.sun ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			(hourColWidths?.mon ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			(hourColWidths?.tue ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			(hourColWidths?.wed ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			(hourColWidths?.thu ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			(hourColWidths?.fri ?? TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH) +
			TIME_SHEET_CERTIFICATION_METHOD_COL_WIDTH +
			90
		);
	}, [hourColWidths]);

	const context = useMemo(() => ({
		detail,
	}), [detail]);

	return (
		<TimeSheetCertificationHoursContext.Provider value={context}>
			<Alignment 
				column 
				flex 
				className={classes.gridContainer}
				width={width}
			>
				<Alert
					variant='filled'
					color='warning'
					icon={<WarningIcon sx={{ padding: '2px 0', fontSize: '18px', marginRight: '6px' }}/>}
					sx={{ 
						padding: '0 10px', 
						lineHeight: '1', 
						'> .MuiAlert-icon': { padding: '2px 0', fontSize: '18px', marginRight: '6px' }, 
						'> .MuiAlert-message': {  padding: '6px 0 2px 0' },
					}}
				>Make sure that all records are saved before closing.</Alert>
				<Alignment column flex>
					<EditDataGrid<TimeSheetCertificationHour, TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_COLUMNS, "row">
						apiRef={apiRef}
						editMode={"row"}
						columnHeaderHeight={hideHeaders ? 0 : undefined}
						loading={!certificationHours || loading}
						disabled={disabled ?? false}
						rows={certificationHours ?? []}
						columns={columns}
						rowValidator={validator}
						onRowCreate={onCreate}
						onRowUpdate={onUpdate}
						onRowDelete={onDelete}
						disableColumnResize
						sx={{ border: 'none' }}
						classes={{
							columnSeparator: classes.hidden,
						}}
						experimental_singleClickToEdit
						experimental_disableCreateReorder
					/>
				</Alignment>

				{/* Footer Info */}
				<Alignment column>
					<TimeSheetCertificationHoursFooter detail={detail} certificationHours={certificationHours}/>
				</Alignment>
			</Alignment>
		</TimeSheetCertificationHoursContext.Provider>
	);
});