import { getUserRoles } from '@imas/redux';
import { Grid } from '@mui/material';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import { DailyScheduleViewer } from './DailyScheduleViewer/DailyScheduleViewer';
import { dashboardStyles } from './DashboardStyles';
import { EmployeeAlertsViewer } from './EmployeeAlertsViewer/EmployeeAlertsViewer';
import { EmployeeMilestoneViewer } from './EmployeeMilestoneViewer/EmployeeMilestoneViewer';
import { useNotificationSubscription } from '@imas/utils/notification';
import { registerNotificationActionButton } from '@imas/utils/notification/registerNotificationActionButton';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { local } from '@imas/utils/storage';
import moment from 'moment';

const DashboardBody = () => {
    //page title
    document.title = "IMAS - Dashboard";

    //use automatic snackbar
    const showSnackBar = useAutomaticSnackbar();
    
    const { classes, deviceInfo } = dashboardStyles();

	const userRoles = useSelector(getUserRoles);

    //get device & user subscription information
    const { deviceSubscription } = useNotificationSubscription();


    const action = registerNotificationActionButton();

    //register the user for push notifications after login, no error messages.
    const requestPushNotifications = () => {
        //check if notification api is supported, if not do not show
        if (!("Notification" in window)) {
            return;
        }
        if (local.get("notificationsNotSupportedShown") === "yes") return;

        try{
            //if (Notification.permission === 'denied') return;
        } catch (e) {
            console.log(e);
            return;
        }

        if (local.get("notificationLastShown") !== moment().format("MM-DD-YYYY")) {
            local.set('notificationLastShown', moment().format("MM-DD-YYYY"));
            showSnackBar("Please enable notifications for IMAS.", {variant: 'info', action: action, autoHideDuration: 10000 /*, anchorOrigin: !deviceInfo.isMd ? {vertical: 'top', horizontal: 'right'} : undefined */});
        };
    };

    useEffect(() => {
        //if (deviceSubscription === undefined || window.location.hostname === "localhost") return;
        if (deviceSubscription === null) requestPushNotifications();
    }, [deviceSubscription]);

    //for mobile devices just show the schedule
    if (!deviceInfo.isMd) {
        return (
            <div className={classes.body}>
                <DailyScheduleViewer/>
            </div>
        );
    }

    //for devices md or larger
    return (
        <div className={classes.body}>
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridItem}>
                    <DailyScheduleViewer/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridItem}>
                    <EmployeeMilestoneViewer/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridItem}>
                    <EmployeeAlertsViewer/>
                </Grid>
            </Grid>
        </div>
    );
};


//handles subrouting for the /dashbord route
const Dashboard = memo(() => {
    return (
        <Routes>
            <Route path={""}>
                <Route key={"body"} path={"/"} element={<DashboardBody key={"dashboard-body"}/>}/>
                <Route key={"body"} path={"schedule/:state"} element={<DashboardBody key={"dashboard-body"}/>}/>
            </Route>

            {/* Invalid route redirection to /dashboard */}
            <Route path={"*"} element={<Navigate to="/dashboard"/>}/>
        </Routes>
    );
});

export default Dashboard;