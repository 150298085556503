import { makeStyles } from '@imas/styles';
import { Skeleton, SkeletonProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles()((theme) => ({
	rounded: {
		borderRadius: theme.shape.borderRadius,
	},
    fillAvailable: {
        width: '-webkit-fill-available!important'
    },
    fillFlex: {
        flex: '1'
    }
}));

export interface XSkeletonProps extends SkeletonProps {
    rounded?: boolean | string;
    fillAvailable?: boolean;
    fillFlex?: boolean;
};

const XSkeleton = ({ rounded, fillAvailable, fillFlex, className, ...skeletonProps }: XSkeletonProps) => {
    //component styling
    const { classes } = useStyles();

    return (
        <Skeleton 
			{...skeletonProps}
            className={
                clsx(className, {
					[classes.rounded]: rounded ?? false,
                    [classes.fillAvailable]: fillAvailable ?? false,
                    [classes.fillFlex]: fillFlex ?? false
                })
            }
			
        />
    );
};


export { XSkeleton };