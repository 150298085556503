/**
 * Generate a random number with a with a higher chance of the random number being closer to the value of min than max.
 * 
 * @param min The minimum value which the randomly generated number can be.
 * @param max The maximum value which the randomly generated number can be.
 * @param bias The level of bias towards smaller numbers.
 * @returns The randomly generated number between min and max.
 */
export const biasedRandom = (min: int, max: int, bias?: int): int => {
    return Math.floor(min + (max + 1 - min) * Math.pow(Math.random(), bias ?? 2));
};