import { Paper, PaperProps, ListSubheader, Box, Typography, AutocompleteProps, createFilterOptions } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps, createIdNameAutocompleteVirtualizer } from '@imas/utils/virtualization';
import { forwardRef } from 'react';
import { Proposal } from '@imas/api/proposal';

const LISTBOX_PADDING = 0; //px



const ProposalListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<>
			{/* List Header */}
			<ListSubheader sx={{ lineHeight: '24px', padding: '0px', display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ marginLeft: '8px', width: '62px', fontWeight: 'bold' }}>{"Job No"}</Box>
				<Box sx={{ marginLeft: '5px', width: '595px', fontWeight: 'bold' }}>{"Name"}</Box>
			</ListSubheader>

			{/* List */}
			<VirtualizedListboxAdpater 
				{...props} 
				ref={ref} 
				listboxPadding={0}
				itemSize={24}
				renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<Proposal>) => {
					const row = data[index];
				
					return (
						<li {...row.props} style={{ ...style, padding: '0 8px', top: (style.top as number) + LISTBOX_PADDING }}>
							<Typography noWrap sx={{ width: '70px', fontSize: '0.875rem' }}>{row.option.id}</Typography>
							<Typography noWrap sx={{ width: '600px', fontSize: '0.875rem' }}>{row.option.name}</Typography>
						</li>
					);
				}}
			/>
		</>
	);
});

const ProposalPaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
	return <Paper {...props} ref={ref} sx={{ minWidth: '670px'}}/>;
});

const proposalsFilter = createFilterOptions<Proposal>({ stringify: (proposal) => `${proposal.id} ${proposal.name}`.trim() });

export const PROPOSALS_AUTOCOMPLETE_VIRTUALIZER = createIdNameAutocompleteVirtualizer({ 
	showId: true,
	idWidth: 65, 
	nameWidth: 600, 
	labels: { id: 'Job No' },
	dense: true,
}) as NonNullable<Pick<AutocompleteProps<Proposal, false, false, false, "div">, "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup" | "filterOptions" | "getOptionLabel">>;

const x: NonNullable<Pick<AutocompleteProps<Proposal, false, false, false, "div">, "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup" | "filterOptions" | "getOptionLabel">> = {
	//virtualization props
	filterOptions: proposalsFilter,
	disableListWrap: true,
	PaperComponent: ProposalPaper,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: ProposalListboxAdapter,
	getOptionLabel: (option) => `${option.id}`,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};