import { mapApi, ApiConfig } from '@imas/api';
import { EmployeeBasics } from '../employee';
import { ServiceOrder, ServiceOrderAttachment, ServiceOrderCorrectionType, ServiceOrderCorrectionTypeForm } from "./types";
import { Moment } from 'moment';
import axios from "axios";
import { objectToFormData, SourceGetter, axiosData } from "@imas/api";
import { ServiceOrderReportRevision, SOReportVersionResult, VServiceOrderReportRevision } from 'src/api/types/api/service-orders/types';

//service orders base url
const SERVICE_ORDERS_URL = '/api/service-orders';

/*  */
export const GetServiceOrder = mapApi((serviceOrderId: int): ApiConfig<ServiceOrder> => ({
	method: 'get',
	url: `${SERVICE_ORDERS_URL}/${serviceOrderId}`,
}));

/*  */
export const GetServiceOrderFullJobName = mapApi((serviceOrderId: int): ApiConfig<string> => ({
	method: 'get',
	url: `${SERVICE_ORDERS_URL}/${serviceOrderId}/full-job-name`,
}));

/*  */
export const GetServiceOrderAttachments = mapApi((serviceOrderId: int): ApiConfig<ServiceOrderAttachment[]> => ({
	method: 'get',
	url: `${SERVICE_ORDERS_URL}/${serviceOrderId}/attachments`,
}));

/*  */
export const GetServiceOrderScheduledEmployees = mapApi((serviceOrderId: int, date?: Moment | Date | null): ApiConfig<EmployeeBasics[]> => ({
	method: 'get',
	url: `${SERVICE_ORDERS_URL}/${serviceOrderId}/scheduled-employees`,
	params: {
		date : (date ? date instanceof Date ? date : date.toDate() : date) ?? undefined,
	},
}));

export const GetServiceOrderCorrectionTypes = (getSource: SourceGetter) =>{
    return async (): Promise<ServiceOrderCorrectionType[]> => {
        const result = await axios({
            method: 'get',
            url: `${SERVICE_ORDERS_URL}/correctionType`,
            cancelToken: getSource().token,
        });
        return result.data;
    };
};

export const CreateCorrectionType = (getSource: SourceGetter) => {
	return async (reason: string): Promise<ServiceOrderCorrectionTypeForm> => {
		const result = await axios({
            method: 'post',
            url: `${SERVICE_ORDERS_URL}/correctionType/${reason}`,
            cancelToken: getSource().token,
        });
        return result.data;
	};
};

export const GetServiceOrderRevisionCorrectionTypes = (getSource: SourceGetter) => {
	return async (): Promise<ServiceOrderReportRevision[]> => {
		const result = await axios({
            method: 'get',
            url: `${SERVICE_ORDERS_URL}/revisions`,
            cancelToken: getSource().token,
        });
        return result.data;

	};
};

export const RemoveServiceOrderCorrectionType = (getSource: SourceGetter) => {
    return async (type: int): Promise<ServiceOrderCorrectionTypeForm> => {
        const result = await axios({
            method: 'delete',
            url: `${SERVICE_ORDERS_URL}/correctionType/${type}`,
            cancelToken: getSource().token,
        });
        return result.data;
    };
};

export const EditServiceOrderCorrectionTypeSort = (getSource: SourceGetter) => {
    return async (type: int, sortorder: int): Promise<ServiceOrderCorrectionType> => {
        const result = await axios({
            method: 'put',
            url: `${SERVICE_ORDERS_URL}/correctionType/${type}/sort/${sortorder}`,
            cancelToken: getSource().token,
        });
        return result.data;    
    };
};

export const EditServiceOrderCorrectionTypeReason = (getSource: SourceGetter) => {
    return async (type: int, reason: string): Promise<ServiceOrderCorrectionType> => {
        const result = await axios({
            method: 'put',
            url: `${SERVICE_ORDERS_URL}/correctionType/${type}/reason/${reason}`,
            cancelToken: getSource().token,
        });
        return result.data;    
    };
};

