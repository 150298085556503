import { InputBase, InputBaseProps } from '@mui/material';
import { forwardRef } from 'react';
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";

/*
 *  InputBaseMiddleware component used to get around the "size" prop conflict between the react-number-format NumberFormat props 
 *  and the Material-UI InputBase props.
*/
type InputBaseMiddlewareProps = InputBaseProps & { inputBaseSize?: InputBaseProps["size"] };

const InputBaseMiddleware = forwardRef<HTMLInputElement, InputBaseMiddlewareProps>((params, ref) => {
    //destructure params to take out inputBaseSize
    const { inputBaseSize, ...otherParams } = params;

    return <InputBase {...otherParams} size={inputBaseSize} inputRef={ref}/>;
});

/*
 *  NumberInputBase component, renders like a Material-UI InputBase and will accept almost all InputBase props, but functions like a
 *  react-number-format NumberFormat,
 *  
 *  References:
 *      InputBase props [https://mui.com/api/input-base/]
 *      NumberFormat props [https://www.npmjs.com/package/react-number-format]
*/ 
export type NumberInputBaseProps = Omit<NumberFormatPropsBase, "size"> & Omit<InputBaseProps, "value" | "defaultValue" | "type" | "ref">;

const NumberInputBase = forwardRef<HTMLInputElement, NumberInputBaseProps>((props, ref) => {
    const { size, value, allowNegative, ...otherProps } = props;

    return (
        <NumberFormat
            allowNegative={allowNegative ?? false}
            customInput={InputBaseMiddleware}
            /* 
                NOTE: The 'value' prop is coalesced with the empty string "" to make the input render properly for react-number-format when null is passed.
                This custom NumberInputBase component is designed to interpret null as "empty this input". react-number-format requires an empty string ("") for
                this to happen and passing null will cause unexpected behavior. This is mainly an issue when the 'value' prop of this component is cleared programmatically
                rather than by the user.
                
                Reason: https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797342449 
            */
            value={value ?? ""}
            //map the size param to inputBaseSize so it can be passed correctly to the NumberInput component
            inputBaseSize={size}
            getInputRef={ref as any}
            {...otherProps}
        />
    );
});

export { NumberInputBase };