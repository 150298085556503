import { useMediaQuery, useTheme } from '@mui/material';
import { memo, createContext, useContext, useMemo,  } from 'react';
import { DeviceInfo } from './types';

//deviceInfo context
export const DeviceInfoContext = createContext<DeviceInfo>({
    isXs:  true,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isTall: false
});

//deviceInfo provider
export const DeviceInfoProvider = memo((props: React.PropsWithChildren<{}>) => {
    const theme = useTheme();

    //get device information
    const isXs = useMediaQuery(theme.breakpoints.up('xs'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isTall = useMediaQuery('(min-height: 800px)');

    //construct deviceInfo
    const deviceInfo = useMemo(() => ({
        isXs, 
        isSm, 
        isMd, 
        isLg, 
        isXl, 
        isTall
    }), [isXs, isSm, isMd, isLg, isXl, isTall]);

    return (
        <DeviceInfoContext.Provider value={deviceInfo}>
            {props.children}
        </DeviceInfoContext.Provider>
    );
});

//hook for returning the value of DeviceInfoContext
export const useDeviceInfo = () => {
    return useContext(DeviceInfoContext);
};
