import { snackbar } from '@imas/utils/snackbar';
import { v4 as uuidv4 } from 'uuid';

//agument window object
declare global {
	interface Window {
		isIMASPopup?: boolean;
		windowId: UUID;
	}
};

//openPopup options
export interface OpenPopupOptions {
	name?: string;
	height?: int;
	width?: int;
	top?: int;
	left?: int;
	windowId?: UUID;
};

//open a popup and mark it as a popup
export function openPopup(url: string, options?: OpenPopupOptions): Window | null {
	//get options
	const { name, height, width, top, left } = options ?? {};

	//open popup window
	const popup = window.open(url, name ?? "IMAS Popup Window", `popup${height ? `,height=${height}` : ""}${width ? `,width=${width}` : ""}${top ? `,top=${top}` : ""}${left ? `,left=${left}` : ""}`);


	if (popup) {
		//add window ID
		popup.windowId = uuidv4();

		//mark new window as a popup
		popup.isIMASPopup = true;
	} else {
		//show error msg
		snackbar.error("Unable to open popup.");
	}

	//return the popup window
	return popup;
};