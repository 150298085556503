import { DataHookResult, createUpdateHandler } from './_utils';
import { useHub, useOnHub } from '../_hubs';
import { useLoadApi, UseLoadApiOptionsFromApi } from '../_internals';
import { GetTimeSheetDetail, GetTimeSheetDetails, GetTimeSheetDetailCertificationHours, GetTimeSheetCertificationHours, CreateTimeSheet, GetTimeSheet, GetTimeSheetDetailsView, GetTimeSheetSummary }  from '../time-sheet';
import { useMemo } from 'react';
import moment, { Moment } from 'moment';

type UseTimeSheetSummaryArgs = { fromDate: Moment | Date; toDate: Moment | Date; employeeId?: int | null; managerId?: int | null; includeDrOnly?: boolean; includeManager?: boolean; includeMissingOnly?: boolean; };

export const useTimeSheetSummary = ({ fromDate, toDate, employeeId, managerId, includeDrOnly, includeManager, includeMissingOnly }: UseTimeSheetSummaryArgs, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheetSummary>) => {
	//load data
    const api = useLoadApi(GetTimeSheetSummary, [fromDate, toDate, employeeId, managerId, includeDrOnly, includeManager, includeMissingOnly], [fromDate, toDate, employeeId, managerId, includeDrOnly, includeManager, includeMissingOnly], options);

	//load signalR hub
	const hub = useHub("/time-sheets");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data and filter out irellivent data
	useOnHub(hub, "UpdateSummary", (type, record) => {
		if (record.employeeId !== employeeId) return;
		update(type, record);
	}, [employeeId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useTimeSheet = (timeSheetId: number, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheet>) => {
	//load data
	const api = useLoadApi(GetTimeSheet, [timeSheetId], [timeSheetId], options);

	//use signalR hub
	const hub = useHub('/time-sheets');

	//listen for updates and update the list of data
	useOnHub(hub, 'Update', (type, timeSheet) => {
		if (api.data && api.data.id !== timeSheet.id) return;

		//update the record
		if (type === 'UPDATE') api.update(timeSheet);
	}, [api.data, api.update]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useCreateTimeSheet = ({ employeeId, date }: { employeeId: int; date: string | Moment; }, options?: UseLoadApiOptionsFromApi<typeof CreateTimeSheet>) => {
	//load data
	const api = useLoadApi(CreateTimeSheet, [{ employeeId, date: moment(date) }], [employeeId, date], options);

	//use signalR hub
	const hub = useHub('/time-sheets');

	//listen for updates and update the list of data
	useOnHub(hub, 'Update', (type, timeSheet) => {
		if (api.data && api.data.id !== timeSheet.id) return;

		//update the record
		if (type === 'UPDATE') api.update(timeSheet);
	}, [api.data, api.update]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useTimeSheetCertificationHours = (timeSheetId: int, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheetCertificationHours>) => {
	//load data
    const api = useLoadApi(GetTimeSheetCertificationHours, [timeSheetId], [timeSheetId], options);

	//use signalR hub
	const hub = useHub('/time-sheets/certification-hours');
	
	//get time sheet details as well
	const [details] = useTimeSheetDetails(timeSheetId);

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, 'Update', (type, certificationHour) => {
		//ignore certificationHours which don't belong to the specified time sheet
		if (details && !(details.some(detail => detail.id === certificationHour.timeSheetDetailId))) return;

		//update the list
		update(type, certificationHour);
	}, [timeSheetId, details]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useTimeSheetDetail = (timeSheetId: int, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheetDetail>) => {
	//load data
    const api = useLoadApi(GetTimeSheetDetail, [timeSheetId], [timeSheetId], options);

	//use signalR hub
	const hub = useHub("/time-sheets/details");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, detail) => {
		//update result if id matches
		if (detail.id === timeSheetId && type === "UPDATE") api.update(detail);
	}, [timeSheetId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useTimeSheetDetails = (timeSheetId: int, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheetDetails>) => {
	//load data
    const api = useLoadApi(GetTimeSheetDetails, [timeSheetId], [timeSheetId], options);

	//use signalR hub
	const hub = useHub("/time-sheets/details");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, detail) => {
		//ignore details which don't belong to the specified time sheet
		if (detail.timeSheetId !== timeSheetId) return;

		//update the list
		update(type, detail);
	}, [timeSheetId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useTimeSheetDetailsView = (timeSheetId: int, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheetDetailsView>) => {
	//load data
    const api = useLoadApi(GetTimeSheetDetailsView, [timeSheetId], [timeSheetId], options);

	//use signalR hub
	const hub = useHub("/time-sheets/details");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateView", (type, detail) => {
		//ignore details which don't belong to the specified time sheet
		if (detail.timeSheetId !== timeSheetId) return;

		//update the list
		update(type, detail);
	}, [timeSheetId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useTimeSheetDetailCertificationHours = (timeSheetDetailId: int, options?: UseLoadApiOptionsFromApi<typeof GetTimeSheetDetailCertificationHours>) => {
	//load data
    const api = useLoadApi(GetTimeSheetDetailCertificationHours, [timeSheetDetailId], [timeSheetDetailId], options);

	//use signalR hub
	const hub = useHub("/time-sheets/certification-hours");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, certificationHour) => {
		//ignore certificationHours which don't belong to the specified time sheet
		if (certificationHour.timeSheetDetailId !== timeSheetDetailId) return;

		//update the list
		update(type, certificationHour);
	}, [timeSheetDetailId]);

	return [api.data, api] as DataHookResult<typeof api>;
};