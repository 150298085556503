/** Takes in a string and attempts to format it to look like a time stamp. */
export const formatTimeStr = (timeString: string | null): string => {
	//if the value is null return --:--
    if (timeString === null || timeString === "--") {
        return "--:--";
    }

    //return the value if it is not a string or null for some reason
    if (typeof(timeString) !== "string") {
        return timeString;
    }

    //ensure string is correct length and can be converted to an number
    if (isNaN(Number(timeString)) || !(3 <= timeString.length && timeString.length <= 4)) {
        return timeString;
    }
    
    //format our string from something like '0700' or '700' to something like '07:00' or '7:00'
    if (timeString.length === 3) {
        return timeString.slice(0, 1) + ":" + timeString.slice(1);
    } else {
        return timeString.slice(0, 2) + ":" + timeString.slice(2);
    }
};