import React from 'react';
import { makeStyles } from "@imas/styles";
import { Typography, IconButton, TypographyVariant, AppBar, Toolbar } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';


const xDialogStyles = makeStyles()(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    fullScreenRoot: {
        padding: '0',
    },
    fullScreenCloseButton: {
        padding: theme.spacing(2),
        margin: `4px ${theme.spacing(1)} 4px 4px`,
        color: theme.palette.mode === "light" ? theme.palette.text.navigation : theme.palette.text.navigation,
    },
    toolBar: {
        padding: '0',
    },
    title: { 
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
    },
    appBar: {
        position: 'relative',
    },
}));

export interface DialogTitleProps {
    children: React.ReactNode,
    variant?: TypographyVariant,
    onClose?: () => void,
    fullScreen?: boolean,
}

const XDialogTitle = React.memo((props: DialogTitleProps) => {
    const { children, onClose, variant, fullScreen } = props;

    const { classes } = xDialogStyles();

    return (
        <>
            {/* if full screen then return an app bar */}
            {fullScreen ? (
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        {onClose ? (
                            <IconButton
                                aria-label={"close"}
                                className={classes.fullScreenCloseButton}
                                onClick={onClose}
                                size={"large"}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}

                        {typeof children === "string" ? <Typography variant={variant ?? "h6"} className={classes.title}>{children}</Typography> : children}
                    </Toolbar>
                </AppBar>
            ) : (
                <>
                    {typeof children === "string" ? <Typography variant={variant ?? "h6"} className={classes.title}>{children}</Typography> : children}
            
                    {onClose ? (
                        <IconButton
                            aria-label={"close"}
                            className={classes.closeButton}
                            onClick={onClose}
                            size={"large"}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </>
            )}
        </>
    );
});

export { XDialogTitle };