import { alpha } from '@mui/material';
import { makeDeviceStyles } from '@imas/styles';

export const pdfReportsViewerStyles = makeDeviceStyles()((theme) => ({
    dialog: {
        '& .MuiDialog-container .MuiDialog-paper': {
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            marginTop: '5vh',
            marginBottom: '5vh',
            display: 'flex',
            flexDirection: 'column',
        }
    },
    viewer: {
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
    },
    closeBtn: {
        marginLeft: 'auto',
    },
    acceptBtn: {
        marginLeft: '10px',
        background: theme.palette.success.main,
        "&:hover": {
            background: alpha(theme.palette.success.main, 0.75),
        },
    },
}));