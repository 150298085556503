import { mapApi, ApiConfig } from '@imas/api';
import { ClientNote, ClientNoteForm } from './types';

//client notes base url
const CLIENT_NOTES_URL = "/api/clients/notes";

/*  */
export const GetAllClientNotes = mapApi((): ApiConfig<ClientNote[]> => ({
	method: 'get',
	url: `${CLIENT_NOTES_URL}`,
}));

/*  */
export const GetClientNote = mapApi((noteId: int): ApiConfig<ClientNote> => ({
	method: 'get',
	url: `${CLIENT_NOTES_URL}/${noteId}`,
}));

/*  */
export const CreateClientNote = mapApi((data: ClientNoteForm): ApiConfig<ClientNote> => ({
	method: 'post',
	url: `${CLIENT_NOTES_URL}`,
	data,
}));

/*  */
export const UpdateClientNote = mapApi((noteId: int, data: ClientNoteForm): ApiConfig<ClientNote> => ({
	method: 'put',
	url: `${CLIENT_NOTES_URL}/${noteId}`,
	data,
}));

/*  */
export const DeleteClientNote = mapApi((noteId: int): ApiConfig<ClientNote[]> => ({
	method: 'delete',
	url: `${CLIENT_NOTES_URL}/${noteId}`,
}));