import { axiosData, SourceGetter, axiosFile } from '@imas/api';
import { ProposalReport, vProposalReport } from './types';
import { Moment } from 'moment';
import { ServiceOrderReport } from '../service-order';

//proposal reports base url
const PROPOSAL_REPORTS_URL = "/api/proposals/reports";

/*  */
export const GetAllProposalReports = (getSource: SourceGetter) => {
    return async (): Promise<ProposalReport[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_REPORTS_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */ 
export const GetAllProposalVReports = (getSource: SourceGetter) => {
    return async (): Promise<vProposalReport[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_REPORTS_URL}/v-reports`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetProposalReport = (getSource: SourceGetter) => {
    return async (proposalReportId: int): Promise<ProposalReport> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_REPORTS_URL}/${proposalReportId}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const DownloadProposalReport = (getSource: SourceGetter) => {
    return async (proposalReportId: int, options?: { serviceOrderId?: int | null; reportDate?: Date | Moment | null; }): Promise<File> => {
		const { serviceOrderId, reportDate: date } = options ?? {};

        return axiosFile({
			method: 'get',
            url: `${PROPOSAL_REPORTS_URL}/${proposalReportId}/download`,
			params: {
				serviceOrderId: serviceOrderId ?? undefined,
				reportDate: date ? date instanceof Date ? date : date.toDate() : undefined,
			},
            cancelToken: getSource().token,
		});
    };
};

export const ImportProposalReport = (getSource: SourceGetter) => {
    return async (proposalReportId: int, options: { serviceOrderId: int; reportDate?: Date | Moment | null; }): Promise<ServiceOrderReport> => {
		const { serviceOrderId, reportDate: date } = options ?? {};

        return axiosData({
			method: 'post',
            url: `${PROPOSAL_REPORTS_URL}/${proposalReportId}/import`,
			data: {
				serviceOrderId: serviceOrderId ?? undefined,
				reportDate: date ? date instanceof Date ? date : date.toDate() : undefined,
			},
            cancelToken: getSource().token,
		});
    };
};

/*  */
// export const CreateProposal = (getSource: SourceGetter) => {
//     return async (data: { siteId: int; departmentId: short; }): Promise<Proposal> => {
//         return axiosData({
// 			method: 'post',
//             url: `${PROPOSALS_URL}`,
// 			data,
//             cancelToken: getSource().token,
// 		});
//     };
// };