import { Client } from '@imas/api/client';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { useMuiFormContext } from '@imas/mui-form';
import { ClientVLocationPrimaryPhoneNumbersGrid } from '../../ClientContactSearch/ClientSearchView/ClientVLocationPrimaryPhoneNumbersGrid';

export interface ClientLocationsProps {
	client?: Client | null;
};

export const ClientLocations = memo(({ client }: ClientLocationsProps) => {
	//use form context
	const { isLoading } = useMuiFormContext();

	return (
		<ClientVLocationPrimaryPhoneNumbersGrid
			clientId={isLoading ? undefined : client === null ? -1 : client?.id}
			alternateBody={client === null ? (<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until client is saved."}</Typography>) : undefined}
		/>
	);
});