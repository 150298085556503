import { Dialog, IconButton, Typography } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { PDFViewer } from '@imas/utils/pdf';
import { pdfReportsViewerStyles } from 'src/pages/ServiceOrders/ServiceOrderTrackingView/ServiceOrderReportsEmailer/PdfReportsViewer/PdfReportsViewerStyles';
import { SlideUp } from '@imas/components/transitions';
import { XDialogTitle } from '@imas/components/mui';
import { download } from '@imas/utils/files';
import { useMemo, useState } from 'react';
import { useApi } from '@imas/api';
import { GetScopePdf } from '@imas/api/proposal';

export interface PdfReportsViewerProps {
	id?: int;
	allowDownload: boolean;
	onClose: () => void;
}

export const PdfDialog = ({ id, allowDownload, onClose }: PdfReportsViewerProps) => {
	const { classes, deviceInfo } = pdfReportsViewerStyles();

	//use automatic snackbar
	const showSnackbar = useAutomaticSnackbar();

	//current file name
	const [fileName, setFileName] = useState<string | null>(null);

	//api
	const getScopePdf = useApi(GetScopePdf);

	//returns a promise which resolves to the file
	const filePromise = useMemo(() => {
		if (!id) return null;

		return new Promise<File>((resolve) => {
			getScopePdf(id).then((file) => {
				setFileName(file.name);
				resolve(file);
			});
		});
	}, [id, setFileName]);

	if (!filePromise) return null;

	return (
		<Dialog
			open={true}
			maxWidth={"xl"}
			className={!deviceInfo.isSm ? undefined : classes.dialog}
			fullScreen={!deviceInfo.isSm}
            TransitionComponent={!deviceInfo.isSm ? SlideUp : undefined}
			onClose={() => onClose() }
		>
			<XDialogTitle
				fullScreen
				onClose={onClose}
			>
				<Typography variant={'h6'}>{"Scope PDF"}</Typography>
					{allowDownload ? 
						<IconButton
							color={'inherit'}
							sx={{margin: '0 10px 0 auto'}}
							disabled={!fileName}
							onClick={() => {
								download(filePromise);
							}}
						><DownloadIcon/></IconButton>
					: null }
			</XDialogTitle>

			{/* Render PDF Viewer */}
			<PDFViewer source={filePromise} fileName={fileName ?? "Loading..."} />
		</Dialog>
	);
};
