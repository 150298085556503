import axios from 'axios';

//add axios interceptors
function addAxiosInterceptors () {
    axios.interceptors.request.use((config) => {
        //dont add X-Requested-With for requests to google
        if (config.url?.includes("google")) {
            return config;
        }

        //add X-RequestedWith
        config.headers["X-Requested-With"] = "XMLHttpRequest";
        return config;
    }, (error) => {
        console.error(error);
    });
};

//make API configuration changes 
export function configureAPI() {
	//add axios interceptors
	addAxiosInterceptors();
};