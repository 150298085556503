//clear a notification given its tag if it exists
export const clearNotification = (tag: string) => {
    if (!('serviceWorker' in navigator)) return;

    //enter async context
    (async () => {
        //get service worker registration
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration || !('getNotifications' in registration)) return;

        //get list of notifications with the specified tag
        const notifications = await registration.getNotifications({ tag: tag });

        //clear all notifications found with the specified tag
        for (var notification of notifications) {
            //close notification
            notification.close();
        }
    })().catch(() => null);
};