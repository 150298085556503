import { makeStyles } from '@imas/styles';

export const dailyScheduleViewerStyles = makeStyles()((theme) => ({
    viewerContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '-webkit-fill-available',
    },
    title: {
        margin: 'auto',
        fontWeight: 'bold',
    },
    scheduleNavigationContainer: {
        padding: '10px',
        justifyContent: 'center',
    },
    titleDateContainer: {
        margin: 'auto',
        padding: '0 5px',
    },
    scheduleNavTitle: {
        fontWeight: 'bold',
    },
    recordTypeSelector: {
        minWidth: '85px',
    },
    navigationButton: {
        margin: 'auto 0 auto 0',
        color: theme.palette.text.primary,
    },
    entryList: {
        flex: '1 1 0',
        overflow: 'scroll',
    },
    hoursContainer: {
        padding: '10px 10px 0 10px',
    }
}));