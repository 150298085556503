import { DataGridEditTextField, DecimalEditCell, EditCellContainer, TypedGridRenderEditCellParams } from '@imas/data-grid';
import { createIdNameAutocompleteVirtualizer, createObjectAutocompleteVirtualizer, createStringAutocompleteVirtualizer } from '@imas/utils/virtualization';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TimeSheetDailyNotes, TimeSheetWeekday } from './types';
import { useClientByName } from '@imas/api/data-hooks';
import { IdName, useApi, useLoadApi } from '@imas/api';
import { GetProposalClient, GetProposalServiceOrderIdNames, GetProposalWorkClassCode, MinifiedProposal } from '@imas/api/proposal';
import { HourType, vWorkClass } from '@imas/api/system';
import { GetUserHourTypeEligible, GetUserWorkClass } from '@imas/api/user';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { HOUR_TYPES } from '@imas/constants';
import { Description as DescriptionIcon, Save as SaveIcon } from '@mui/icons-material';
import { getCertificationHourDayTotal, HOUR_CELL_DECIMAL_OPTIONS, useDailyNotesCount, useHourCellColoring, weekdayFullNameMap, weekdayList } from './utils';
import { useHourCellStyles } from './CellsAndHeaders';
import { NotesBadge, NotesTooltip } from './Components';
import { DialogRenderer, useDialog } from '@imas/utils/dialog';
import { delay } from '@imas/utils/misc';
import { TimeSheetDetailsFormContext } from './TimeSheetDetailsFormContext';
import Enumerable from 'linq';

const CLIENT_STRING_AUTOCOMPLETE_VIRTUALIZER = createStringAutocompleteVirtualizer({ width: 350, dense: true });

interface ClientEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'client', 'row'> {
    clients?: IdName[];
};

export const ClientEditCell = ({ id, value, field, api, hasFocus, row, clients }: ClientEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
	//get pronum current state
	const pronum = api.getRowWithUpdatedValues(row.id)?.pronum ?? null;
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={(value ?? '') === ''}>
			<Autocomplete
				value={value ?? ""}
				inputValue={value ?? ""}
				options={(clients ?? []).map(x => x.name)}
				loading={!clients}
				onChange={(_, value) => {
					api.setEditCellValue({ id, field, value  });
				}}
				onInputChange={(_, value) => {
					api.setEditCellValue({ id, field, value: value === '' ? null : value });
				}}
				freeSolo
				fullWidth
				disableClearable
				openOnFocus
				autoHighlight
				onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
				renderInput={(props) => (
					<DataGridEditTextField 
						{...props}
						inputRef={ref}
					/>
				)}
				disabled={pronum !== null}
				sx={{ typography: 'body2' }}
				{...CLIENT_STRING_AUTOCOMPLETE_VIRTUALIZER}
			/>
		</EditCellContainer>
    );
};

const MINIFIED_PROPOSALS_AUTOCOMPLETE_VIRTUALIZER = createObjectAutocompleteVirtualizer<MinifiedProposal>({
	columns: [
		{ field: 'id', label: 'Job No', width: 60, getValue: (x) => `${x.id}` },
		{ field: 'name', label: 'Name', width: 600, getValue: (x) => x.name ?? '' },
	],
	getLabel: (x) => `${x.id}`,
	dense: true,
});

interface ProposalEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'pronum', 'row'> {
	proposals?: MinifiedProposal[];
};

export const ProposalEditCell = ({ id, value, field, api, hasFocus, row, proposals }: ProposalEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
	//use apis
	const getProposalClient = useApi(GetProposalClient);
	const getProposalWorkClassCode = useApi(GetProposalWorkClassCode);
	const getUserWorkClass = useApi(GetUserWorkClass);

	//get client name current state
	const clientName = api.getRowWithUpdatedValues(row.id)?.client ?? '';

	//get client
	const [client, { update: updateClient }] = useClientByName(clientName, { disabled: clientName === '' });
	useEffect(() => { if (clientName === '') updateClient(undefined); }, [clientName, updateClient]);

	//get client's proposals
	const clientOptionsFilter = useCallback<typeof MINIFIED_PROPOSALS_AUTOCOMPLETE_VIRTUALIZER['filterOptions']>((options, state) => 
		MINIFIED_PROPOSALS_AUTOCOMPLETE_VIRTUALIZER.filterOptions(
			Enumerable.from(options)
			.where(x => !client || x.clientId === client.id)
			// .where(x => x.name !== null)
			.toArray()
		, state)
	, [client]);
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
        <Autocomplete
            value={((proposals ?? []).find(x => x.id === value) ?? null) as MinifiedProposal | null}
            options={(proposals ?? (typeof value !== 'number' ? [] : [{ id: value, name: '' }])) as MinifiedProposal[]}
			loading={!proposals}
			onChange={(e, value) => {
				if (value === null) {
					api.setEditCellValue({ id, field, value: null });
					api.setEditCellValue({ id, field: 'client', value: '' });
					api.setEditCellValue({ id, field: 'description', value: '' });
					api.setEditCellValue({ id, field: 'hourType', value: null });
					api.setEditCellValue({ id, field: 'serviceOrderId', value: null });
					api.setEditCellValue({ id, field: 'uniqueId', value: null });
					getUserWorkClass().then(x => api.setEditCellValue({ id, field: 'workClass', value: x.workClass })).catch();
				} else {
					api.setEditCellValue({ id, field, value: value?.id ?? null });
					getProposalClient(value.id).then(client => api.setEditCellValue({ id, field: 'client', value: client.name })).catch();
					api.setEditCellValue({ id, field: 'description', value: value?.name ?? '' });
					api.setEditCellValue({ id, field: 'hourType', value: HOUR_TYPES.REGULAR });
					getProposalWorkClassCode(value.id).then(value => api.setEditCellValue({ id, field: 'workClass', value })).catch();
					
					//focus serviceOrderId field (when focused w/ keyboard)
					if (e.type === 'keydown') api.setCellFocus(id, 'serviceOrderId');
				}
			}}
            fullWidth
			openOnFocus
			autoHighlight
			onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
			renderInput={(props) => (
				<DataGridEditTextField 
					{...props}
					InputProps={{
						...props.InputProps,
						endAdornment: undefined,
					}}
					inputRef={ref}
				/>
			)}
            sx={{ typography: 'body2' }}
			{...MINIFIED_PROPOSALS_AUTOCOMPLETE_VIRTUALIZER}
			filterOptions={clientOptionsFilter}
        />
    );
};


interface DescriptionEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'description', 'row'> {};

export const DescriptionEditCell = ({ id, value, field, api, hasFocus, row }: DescriptionEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
	//get client & pronum current state
	const client = api.getRowWithUpdatedValues(row.id)?.client ?? '';
	const pronum = api.getRowWithUpdatedValues(row.id)?.pronum ?? null;
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);
	
	const { workClass } = useContext(TimeSheetDetailsFormContext);

	//get user hour eligibility
	const { getAutoFillHourType } = useContext(TimeSheetDetailsFormContext);

    return (
		<EditCellContainer required={(value ?? '') === ''}>
			<DataGridEditTextField
				value={value ?? ''}
				onChange={(e) => {
					const value = e.target.value;
					api.setEditCellValue({ id, field, value });

					const autoFilledHourType = getAutoFillHourType(client, value, pronum);
					if (autoFilledHourType !== null) {
						api.setEditCellValue({ id, field: 'hourType', value: autoFilledHourType });
						api.setEditCellValue({ id, field: 'uniqueId', value: null });
						api.setEditCellValue({ id, field: 'serviceOrderId', value: '' });
						if (workClass) api.setEditCellValue({ id, field: 'workClass', value: workClass.workClass });
					};
				}}
				disabled={pronum !== null}
				inputRef={ref}
			/>
		</EditCellContainer>
    );
};


const HOUR_TYPE_AUTOCOMPLETE_VIRTUALIZER = createIdNameAutocompleteVirtualizer({ nameWidth: 70, dense: true });

interface HourTypeEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'hourType', 'row'> {
    hourTypes?: HourType[];
};

export const HourTypeEditCell = ({ id, value, field, api, hasFocus, hourTypes }: HourTypeEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

	//when hourType value changes, and it is not regular reset some other fields
	useEffect(() => { 
		if (value !== HOUR_TYPES.REGULAR) {
			api.setEditCellValue({ id, field: 'pronum', value: null });
			api.setEditCellValue({ id, field: 'uniqueId', value: null });
			api.setEditCellValue({ id, field: 'serviceOrderId', value: null });
			api.setEditCellValue({ id, field: 'shiftDiff', value: false });
		}
	}, [value]);

	//use snackbar
	const snackbar = useAutomaticSnackbar();

	//use APIs
	const getUserHourTypeEligible = useApi(GetUserHourTypeEligible);

    return (
		<EditCellContainer required={(value ?? null) === null}>
			<Autocomplete
				fullWidth
				openOnFocus
				autoHighlight
				value={(hourTypes ?? []).find(x => x.id === value) ?? null}
				options={hourTypes ?? []}
				loading={!hourTypes}
				onChange={(_, hourType) => {
					if (hourType) {
						(async () => {
							const eligible = await getUserHourTypeEligible(hourType.id);
							if (eligible) {
								api.setEditCellValue({ id, field, value: hourType.id  });
							} else snackbar(`The user is not eligible for ${(hourType as HourType).name} time.`, { variant: 'error' });
						})().catch();
					} else {
						api.setEditCellValue({ id, field, value: null  });
					}
				}}
				onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
				renderInput={(props) => (
					<DataGridEditTextField 
						{...props}
						InputProps={{
							...props.InputProps,
							endAdornment: undefined,
						}}
						inputRef={ref}
					/>
				)}
				sx={{ typography: 'body2' }}
				{...HOUR_TYPE_AUTOCOMPLETE_VIRTUALIZER}
			/>
		</EditCellContainer>
    );
};

const SERVICE_ORDER_AUTOCOMPLETE_VIRTUALIZER = createIdNameAutocompleteVirtualizer({ nameWidth: 40, dense: true });

interface ServiceOrderEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'serviceOrderId', 'row'> {};

export const ServiceOrderEditCell = ({ id, value, field, api, hasFocus, row }: ServiceOrderEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
	//get pronum current state
	const pronum = api.getRowWithUpdatedValues(row.id).pronum ?? null;

	//get proposal service orders
	const { data: serviceOrders } = useLoadApi(GetProposalServiceOrderIdNames, [pronum ?? 0], [pronum], { disabled: pronum === null });
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={typeof pronum === 'number' && (value ?? '') === ''}>
			<Autocomplete
				value={((serviceOrders ?? []).find(x => x.name === value)) ?? null}
				options={serviceOrders ?? []}
				loading={!serviceOrders}
				onChange={(e, value) => {
					api.setEditCellValue({ id, field, value: value?.name ?? null });
					api.setEditCellValue({ id, field: 'uniqueId', value: value?.id ?? null });

					//focus mon field
					if (value !== null && e.type === 'keydown') api.setCellFocus(id, 'mon');
				}}
				fullWidth
				openOnFocus
				autoHighlight
				noOptionsText={'None'}
				onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
				renderInput={(props) => (
					<DataGridEditTextField 
						{...props}
						InputProps={{
							...props.InputProps,
							endAdornment: undefined,
						}}
						inputRef={ref}
					/>
				)}
				disabled={pronum === null}
				sx={{ typography: 'body2' }}
				{...SERVICE_ORDER_AUTOCOMPLETE_VIRTUALIZER}
			/>
		</EditCellContainer>
    );
};

interface ShiftDiffEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'shiftDiff', 'row'> {};

export const ShiftDiffEditCell = ({ id, value, field, api, hasFocus, row }: ShiftDiffEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
	//get hourType current state
	const hourType = api.getRowWithUpdatedValues(row.id)?.hourType ?? '';

    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<Checkbox
			checked={value ?? false}
			onChange={(_, checked) => {
				api.setEditCellValue({ id, field, value: checked });
			}}
			disabled={hourType !== HOUR_TYPES.REGULAR}
		/>
    );
};

interface HourEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun', 'row'> {};

export const HourEditCell = (props: HourEditCellProps) => {
	const { classes, cx } = useHourCellStyles();
	
	const hours = props.value;
	const hourType = props.api.getRowWithUpdatedValues(props.row.id)?.hourType ?? null;

	// cell coloring
	const styles = useHourCellColoring(props.field, props.row, 'edit');

	//calculate certHour total
	const certHourTotal = useMemo(() => getCertificationHourDayTotal(props.row.certificationHours, props.field), [props.row.certificationHours]);

	return (
		<DecimalEditCell<false, HourEditCellProps>
			{...props}
			{...HOUR_CELL_DECIMAL_OPTIONS}
			{...(hourType === 8 ? { max: 9999 } : {})}
			className={cx({ [classes.invalidHours]: (certHourTotal > (hours ?? 0)) }, styles, classes.hourEdit)}
			sx={{ '& > .MuiInputBase-input.MuiInputBase-input.MuiInputBase-input': { padding: '0 6px' } }}
		/>
	);
};

const WORK_CLASSES_AUTOCOMPLETE_VIRTUALIZER = createObjectAutocompleteVirtualizer<vWorkClass>({
	columns: [
		{ field: 'id', label: 'Class', width: 60, getValue: (x) => `${x.id}` },
		{ field: 'depName', label: 'Department', width: 600, getValue: (x) => x.depName },
	],
	getLabel: (x) => `${x.id}`,
	isDisabled: (x) => x.depDiscontinued,
	hideDisabledOptions: true,
	dense: true,
});

interface WorkClassEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'workClass', 'row'> {
    workClasses?: vWorkClass[];
};

export const WorkClassEditCell = ({ id, value, field, api, hasFocus, workClasses }: WorkClassEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

	const { workClass } = useContext(TimeSheetDetailsFormContext);

    //populate this row with the default work class if none was selected
    useEffect(() => { if (typeof value !== 'number' && workClass) api.setEditCellValue({ id, field, value: workClass?.workClass }); }, [workClass]);

    return (
		<EditCellContainer required={(value ?? null) === null}>
			<Autocomplete
				value={(workClasses ?? []).find(x => x.workClass === value) ?? null}
				options={workClasses ?? []}
				loading={!workClasses}
				onChange={(_, value) => {
					api.setEditCellValue({ id, field, value: value?.id ?? null });
				}}
				fullWidth
				openOnFocus
				autoHighlight
				onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
				renderInput={(props) => (
					<DataGridEditTextField 
						{...props}
						InputProps={{
							...props.InputProps,
							endAdornment: undefined,
						}}
						inputRef={ref}
					/>
				)}
				sx={{ typography: 'body2' }}
				{...WORK_CLASSES_AUTOCOMPLETE_VIRTUALIZER}
			/>
		</EditCellContainer>
    );
};

interface NotesEditCellProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'notes', 'row'> {};

export const NotesEditCell = ({ id, value, field, api, row, hasFocus }: NotesEditCellProps) => {
	const [openDialog] = useDialog();
	
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
	const record = api.getRowWithUpdatedValues(row.id);
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

	const dailyNotes = useMemo(() => record.dailyNotes ?? { mon: null, tue: null, wed: null, thu: null, fri: null, sat: null, sun: null, open: false }, [record.dailyNotes]);
	const dailyNotesCount = useDailyNotesCount(dailyNotes);
	
	const openOnEdit = (record.dailyNotes ?? {})?.open ?? false;

	const editDailyNotes = useCallback(async () => {
		const notesResult = await openDialog(createDailyNotesEditDialog(dailyNotes));
		api.setEditCellValue({ id: row.id, field: 'dailyNotes', value: notesResult });
		
		if (openOnEdit) {
			await delay(100);
			await api.stopRowEditMode({id: row.id, ignoreModifications: false});
			//await api.commitRowChange(row.id);
			//api.setRowMode(row.id, 'view');
		}
	}, [row.id, dailyNotes, openOnEdit, api]);
	const editDailyNotesRef = useRef(editDailyNotes);
	editDailyNotesRef.current = editDailyNotes;

	useEffect(() => { 
		if (openOnEdit) {
			editDailyNotesRef.current();
			api.setCellFocus(row.id, 'notes');
		}
	}, [openOnEdit]);

    return (
		<EditCellContainer>
			<DataGridEditTextField
				value={value ?? ''}
				onChange={e => api.setEditCellValue({ id, field, value: e.target.value ? e.target.value : null })}
				sx={{ '& .MuiInputBase-input': { padding: '0 0 0 8px!important' } }}
				inputRef={ref}
			/>
			<Tooltip title='Edit Daily Notes'>
				<IconButton size='small' onClick={editDailyNotes} sx={{ margin: '0 5px -1px 4px' }}>
					<Tooltip title={<NotesTooltip notes={dailyNotes}/>} arrow>
						<NotesBadge badgeContent={dailyNotesCount} color="secondary">
							<DescriptionIcon sx={{ width: '18px', height: '18px' }}/>
						</NotesBadge>
					</Tooltip>
				</IconButton>
			</Tooltip>
		</EditCellContainer>
    );
};

const DailyNotesEditDialog = ({ dailyNotes, close }: { dailyNotes: TimeSheetDailyNotes; close: (notes: TimeSheetDailyNotes) => void; }) => {
	const [notes, setNotes] = useState(dailyNotes);

	const updateNote = useCallback((day: TimeSheetWeekday, value: string) => setNotes(x => ({ ...x, [day]: value })), []);

	return (
		<Dialog open maxWidth='lg' onClose={() => close({ ...dailyNotes, open: false })}>
			<DialogContent sx={{ display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', '> *:not(:first-child)': { marginLeft: '10px' } }}>
					{weekdayList.map(day => (
						<Box>
							<Typography variant='h6' sx={{ fontWeight: 'bold' }}>{weekdayFullNameMap[day]}</Typography>
							<TextField
								value={notes[day]}
								onChange={e => updateNote(day, e.target.value)}
								multiline
								rows={8}
							/>
						</Box>
					))} 
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<SaveIcon/>} color={'primary'} onClick={() => close({ ...notes, open: false })}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export const createDailyNotesEditDialog = (dailyNotes: TimeSheetDailyNotes): DialogRenderer<TimeSheetDailyNotes> => (close) => <DailyNotesEditDialog dailyNotes={dailyNotes} close={close}/>;

