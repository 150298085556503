import React from 'react';
import { v4 as uuid4v } from 'uuid';

export interface TDialogContext {
	/** Open a Dialog, returns a key which can be used to close the dialog. */
	open: (dialog: React.ReactNode, options?: { key?: UUID; }) => UUID;
	
	/** Use a key to close the Dialog. */
	close: (key: UUID) => void;
};

export const DialogContext = React.createContext<TDialogContext>({
	open: () => "provider-not-yet-loaded",
	close: () => {},
});