import { CreateFirstRevisionRequest } from './types/operational-procedures/CreateFirstRevisionRequest';
import { vOperationalProcedureRevision } from './types/operational-procedures/vOperationalProcedureRevision';
import { SourceGetter, axiosData, ApiConfig, mapApi } from '../_internals';

//api base url
const OPERATIONAL_PROCEDURE_REVISIONS_URL = "/api/certs-and-qc/operational-procedure-revisions";

/* Get vOperationalProcedureRevisions */
export const GetvOperationalProcedureRevisions = (getSource: SourceGetter) => {
    return async (): Promise<vOperationalProcedureRevision[]> => {
        return axiosData({
			method: 'get',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get vOperationalProcedureRevisions for a specified procedure */
export const GetvRevisionsForProcedure = (getSource: SourceGetter) => {
    return async (procId: int): Promise<vOperationalProcedureRevision[]> => {
        return axiosData({
			method: 'get',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/for-procedure/${procId.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get vOperationalProcedureRevision */
export const GetvOperationalProcedureRevision = (getSource: SourceGetter) => {
    return async (procRevId: int): Promise<vOperationalProcedureRevision> => {
        return axiosData({
			method: 'get',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/${procRevId.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

interface notesUpdate {
	procRevId: int,
	newNotes: string,
	procId: int,
}

/* Update an OperationalProcedureRevision */
export const UpdateOperationalProcedureRevision = (getSource: SourceGetter) => {
    return async (data: notesUpdate): Promise<boolean> => {

        return axiosData({
			method: 'put',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/notes`,
			data: data,
            cancelToken: getSource().token,
		});
    };
};

interface uploadProcedureRevisionFileRequest {
	procRevId: int,
	file: File
}

/* Upload a new file to an OperationalProcedureRevision */
export const UploadProcedureRevisionFile = mapApi((data: uploadProcedureRevisionFileRequest): ApiConfig<boolean> => ({
	method: 'post',
	url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/upload`,
	data: data,
	encodeAs: "FormData",
}));

/* Runs the stored procedure to create a new OperationalProcedureRevision */
export const CreateOperationalProcedureRevision = (getSource: SourceGetter) => {
    return async (procId: int): Promise<int | null> => {
        return axiosData({
			method: 'post',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/${procId}`,
            cancelToken: getSource().token,
		});
    };
};

/* Runs the stored procedure to create an OperationalProcedureRevision when one does not already exist */
export const CreateFirstProcedureRevision = mapApi((request: CreateFirstRevisionRequest): ApiConfig<int | null> => ({
	method: 'post',
	url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}`,
	data: request,
	encodeAs: "FormData",
}));

/* Prepares a draft revision, moves files around in the process */
export const PrepareOperationalProcedureRevision = (getSource: SourceGetter) => {
    return async (procRevId: int): Promise<string> => {
        return axiosData({
			method: 'put',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/prepare/${procRevId}`,
            cancelToken: getSource().token,
		});
    };
};

/* Prepares a draft revision, moves files around in the process */
export const ApproveOperationalProcedureRevision = (getSource: SourceGetter) => {
    return async (procRevId: int): Promise<string> => {
        return axiosData({
			method: 'put',
            url: `${OPERATIONAL_PROCEDURE_REVISIONS_URL}/approve/${procRevId}`,
            cancelToken: getSource().token,
		});
    };
};


/* Re-pdfs and protects all procedure revisions */
export const ProtectAllProcedures = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return axiosData({
			method: 'get',
            url: `api/files/protect-all-procedures`,
            cancelToken: getSource().token,
		});
    };
};