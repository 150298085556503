import { makeDeviceStyles } from '@imas/styles';

export const dashboardStyles = makeDeviceStyles()(theme => ({
    body: {
        display: 'flex',
        margin: '0',
        flex: '1 1 0',
        overflow: 'hidden',
        [theme.breakpoints.down('xl')]: {
            padding: '10px'
        }
    },
    gridContainer: {
        display: 'flex',
        flex: '1 1 0',
        overflow: 'hidden',
        padding: '10px',
    },
    gridItem: {
        display: 'flex',
        overflow: 'hidden',
        width: '-webkit-fill-available'
    }
}));