import { TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export interface DebouncedTextFieldProps extends Omit<TextFieldProps, "onChange" | "defaultValue" | "value"> {
    value?: string;
    defaultValue?: string;
    debounce?: int;
    onChange: (value: string) => void; 
};

//text field where the onChange callback is debounced
const DebouncedTextField = React.memo((props: DebouncedTextFieldProps) => {
    //get props
    const { debounce, onChange, value: providedValue, defaultValue, ...otherProps } = props;

    //value state
    const [value, setValue] = useState<string>(defaultValue ?? providedValue ?? "");

    //if providedValue update value
    useEffect(() => setValue(providedValue ?? ""), [providedValue]);

    //onChange callback debounced
    const onChangeDebounced = useDebouncedCallback(onChange, debounce ?? 250);

    //call onChangeDebounced when value changes
    useEffect(() => onChangeDebounced(value), [value]);

    return <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...otherProps}
    />;
});

export { DebouncedTextField };