import { Alignment } from '@imas/components/layout';
import { memo, useMemo } from 'react';
import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs, getColumnTypes, TypedGridInputSelectionModel, TypedGridCallbackDetails } from '@imas/data-grid';
import { GetContactListsWithNumbers, ContactListWithNumber } from '@imas/api/proposal';
import { useLoadApi } from '@imas/api';
import moment from 'moment';

export interface ContactListGridProps{
	apiRef: CONTACT_LIST_GRID_API_REF;
}

export interface ContactListWithNumberRM extends ContactListWithNumber {
};


//define the column order
export type CONTACT_LIST_GRID_COLUMNS = ["contacts", "contactListName"];

//define the grid api ref type
export type CONTACT_LIST_GRID_API_REF = TypedGridApiRef<ContactListWithNumber, ContactListWithNumberRM, never, "row">;

//define grid props type
export type CONTACT_LIST_GRID_PROPS = TypedEditDataGridProps<ContactListWithNumber, ContactListWithNumberRM, never, CONTACT_LIST_GRID_COLUMNS, "row">

export const ContactListGrid = memo(({apiRef} : ContactListGridProps) => {

	const {data: contactLists} = useLoadApi(GetContactListsWithNumbers, []);

	//memoized grid column defs
	const columns = useMemo((): TypedGridColumnDefs<ContactListWithNumber, ContactListWithNumberRM, never, CONTACT_LIST_GRID_COLUMNS, "row"> => {
		return [
			{ field: 'contacts', headerName: 'Contacts:', width: 90},
			{ field: 'contactListName', headerName: 'List Name:', width: 180},
		];
	}, []);


	return (

		<Alignment column flex>
			<TDataGrid<ContactListWithNumber, ContactListWithNumberRM, never, CONTACT_LIST_GRID_COLUMNS, "row">
				apiRef={apiRef}
				editMode={"row"}
				checkboxSelection
				//autoHeight
				disableColumnMenu
				//disableColumnReorder
				//disableColumnResize
				loading={!contactLists}
				rows={contactLists ?? []}
				columns={columns}
			/>
		</Alignment>
	);
});


