import { State } from '@imas/api/system';
import { Typography, Paper, PaperProps, createFilterOptions, AutocompleteProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

const StatesListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<VirtualizedListboxAdpater 
			{...props} 
			ref={ref} 
			renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<State>) => {
				const row = data[index];
				
				return (
					<li {...row.props}  style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}>
						<Typography noWrap sx={{ width: '50px' }}>{row.option.abbreviation}</Typography>
						<Typography noWrap sx={{ width: '250px' }}>{row.option.name}</Typography>
						<Typography noWrap sx={{ width: '250px' }}>{row.option.country}</Typography>
					</li>
				);
			}}
		/>
	);
});

const StatesPaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
	return <Paper {...props} ref={ref} sx={{ minWidth: '500px'}}/>;
});

const statesFilter = createFilterOptions<State>({ stringify: (state) => `${state.abbreviation} ${state.name}` });

export const STATES_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<State, false, false, false, "div">, "getOptionLabel" |"filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {
	filterOptions: statesFilter,

	//virtualization props
	disableListWrap: true,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: StatesListboxAdapter,
	PaperComponent: StatesPaper,
	getOptionLabel: (option) => `${option.abbreviation}`,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};