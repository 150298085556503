import { XSkeleton } from '@imas/components/layout';
import { Checkbox, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useCallback, useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

export type VirtualizedSelectListProps<T extends any, Multiple extends boolean | undefined = undefined, SelectModel extends int | int[] = Multiple extends true ? int[] : int> = {
	/** List items (undefined when loading). */
	items?: T[];
	/** Returns the text to be rendered in the row for a given list item. */
	getItemText: (item: T) => string | { primary: string; secondary?: string; };
	/** Returns a boolean indicating whether the item's row should be disabled. */
	getItemDisabled?: (item: T) => boolean;
	/** If true all items in the list are disabled. */
	disabled?: boolean;
	/** If multiple selection is enabled. */
	multiple?: Multiple;                 
	/** Model of selected row/rows. */                     
	selectedModel: SelectModel;
	/** Callback for when the selected row(s) model changes. */
	onSelectedModelChange: (model: SelectModel) => void;
	/** Can be used to specify when item property should be used in the selection model, defaults to the item's index. */
	getSelectionProperty?: (item: T) => int;
	/** How many loading rows should be rendered when in the loading state. */
	loadingRows?: int;
	/** If the list has rows with secondary text. */
	hasSecondaryText?: boolean;
	/** If a checkbox should be shown for eacch row. */
	checkbox?: boolean;
	/** The height of each item in the list. */
	size?: "medium" | "small" | "x-small";
};

interface VirtualizedSelectListItemData {
	items?: any[];
	getText: (item: any) => { primary: React.ReactNode; secondary?: React.ReactNode; };
	handleSelect: (item: any, index: int) => void;
	isSelected: (item: any, index: int) => boolean;
	isDisabled: (item: any) => boolean;
	checkbox: boolean;
	size: "medium" | "small" | "x-small";
};

export const VirtualizedSelectList = <T extends any, Multiple extends boolean | undefined = undefined>(props: VirtualizedSelectListProps<T, Multiple>) => {
	const { items, getItemText, getItemDisabled, disabled, multiple, selectedModel, onSelectedModelChange, loadingRows, hasSecondaryText, checkbox, size } = props;

	const propsRef = useRef(props);
	propsRef.current = props;

	const itemSize = useMemo(() => {
		switch (size) {
			case "x-small":
				return 24;
			case "medium":
				return 40;
			case "small":
			default:
				return 32;
		};
	}, [size]);

	//handles updating selection model
	const handleSelect = useCallback((item: T, index: int) => {
		if (item === undefined) return;
		const itemSelectId = propsRef.current.getSelectionProperty ? propsRef.current.getSelectionProperty(item) : index;

		if (multiple) {
			const selectedModel = propsRef.current.selectedModel as int[];
			const newSelectionModel = selectedModel.some(x => x === itemSelectId) ? selectedModel.filter(x => x !== itemSelectId) : [...selectedModel, itemSelectId];
			propsRef.current.onSelectedModelChange(newSelectionModel as any);
		} else {
			propsRef.current.onSelectedModelChange(itemSelectId as any);
		}
	}, [multiple]);

	//returns if the row is selected
	const isSelected = useCallback((item: T, index: int) => {
		if (item === undefined) return false;
		const itemSelectId = propsRef.current.getSelectionProperty ? propsRef.current.getSelectionProperty(item) : index;
		return Array.isArray(selectedModel) ? selectedModel.some(x => x === itemSelectId) : selectedModel === itemSelectId;
	}, [selectedModel]);

	//returns if the row is disabled
	const isDisabled = useCallback((item: T) => {
		if (item === undefined) return false;
		return disabled ?? (getItemDisabled ? getItemDisabled(item) : false);
	}, [disabled, getItemDisabled]);

	//gets the item's text
	const getText = useCallback((item?: T) => {
		if (item) {
			const text = getItemText(item);
			return typeof text === "string" ? { primary: text } : text;
		} else {
			//return skeletons
			return {
				primary: <XSkeleton variant={"text"}/>,
				secondary: hasSecondaryText ? <XSkeleton variant={"text"}/> : undefined,
			};
		}
	}, [hasSecondaryText, getItemText]);

	//item data for virtual list
	const itemData = useMemo(() => ({
		items,
		getText,
		handleSelect,
		isSelected,
		isDisabled,
		size: size ?? "small",
		checkbox: checkbox ?? false,
	}), [items, getText, handleSelect, isSelected, isDisabled, checkbox, size]);
	
	return (
		<AutoSizer>
			{({ width, height }) => (
				<FixedSizeList 
					height={height}
					width={width}
					innerElementType={"ul"}
					itemSize={itemSize}
					itemCount={items?.length ?? loadingRows ?? 5}
					itemData={itemData}
				>
					{VirtualizedCheckboxListRender as any}
				</FixedSizeList >
			)}
		</AutoSizer>
	);
};

export const VirtualizedCheckboxListRender = ({ index, data, style }: ListChildComponentProps<VirtualizedSelectListItemData>) => {
	const item = data.items ? data.items[index] : undefined;
	const selected = data.isSelected(item, index);
	const text = data.getText(item);
	
	return (
		<ListItemButton
			disabled={item === undefined || data.isDisabled(item)}
			selected={selected} 
			onClick={() => data.handleSelect(item, index)}
			style={style}
			title={typeof text.primary === "string" ? text.primary : undefined}
		>
			{data.checkbox ? (
				<ListItemIcon>
					<Checkbox
						edge={"start"}
						checked={selected}
						tabIndex={-1}
						disableRipple
					/>
				</ListItemIcon>
			) : null}
			<ListItemText {...text} primaryTypographyProps={{ sx: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}/>
		</ListItemButton>
	);
};