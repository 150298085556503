import { Box, Typography } from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import { PropsWithChildren } from 'react';

export interface UploadAreaProps extends PropsWithChildren<{}> {
    canDrop: boolean;
};

const UploadArea = ({ canDrop, children } : UploadAreaProps) => {
    return (
        <>
            {/* Upload Area Render */}
            <Box 
                sx={{ 
                    display: canDrop ? 'flex' : 'none',
                    margin: '10px',
                    borderWidth: '5px',
                    borderStyle: 'dashed',
                    borderColor: 'text.primary',
                    borderRadius: 1,
                    flex: '1'
                }}
            >
                <Box 
                    sx={{ 
                        alignSelf: 'center', 
                        alignItems: 'center',
                        color: 'text.primary',
                        margin: 'auto', 
                        display: 'flex', 
                        flexDirection: 'row' 
                    }}
                >
                    <UploadIcon style={{ fontSize: '3em', marginRight: '10px' }}/>
                    <Typography variant={"h5"}>{"Drop to Upload."}</Typography>
                </Box>
            </Box>

            {/* Children Render  */}
            <Box sx={{ display: canDrop ? 'none' : 'contents' }}>
                {children}
            </Box>
        </>
    );
};

export { UploadArea };