import { axiosData, axiosNullableData, SourceGetter } from "@imas/api";
import { ServiceRateModifier } from './types';

//proposal services base url
const SERVICE_VARIANTS_URL = "/api/proposals/service-rate-modifiers";

//gets all service variants
export const GetServiceRateModifiers = (getSource: SourceGetter) => {
    return async (): Promise<ServiceRateModifier[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_VARIANTS_URL}`,
            cancelToken: getSource().token,
        });
    };
};