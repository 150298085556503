import { memo, useMemo, useImperativeHandle, useState, forwardRef, useCallback, useEffect } from 'react';
import { Checkbox, FormControlLabel, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { GetAllClients, Client, ClientForm as TClientForm } from '@imas/api/client';
import { useLoadApi } from '@imas/api';
import { AllInbox, Help as HelpIcon } from '@mui/icons-material';
import { FormTextField, FormCheckbox, useMuiFormControl, useMuiWatch, useMuiFormContext } from '@imas/mui-form';
import { ClientFormValidatorRef } from './ClientForm';
import { useIsElementActive } from '@imas/utils/hooks';
import { Alignment, Spacer } from '@imas/components/layout';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { makeDeviceStyles } from '@imas/styles';

export const clientNameStyles = makeDeviceStyles()((theme) => ({
    cuiBox: {
        color: theme.palette.text.primary,
		width: '120px',
    },
}));

interface ClientNameProps {
	client?: Client | null;
};

export const ClientName = memo(forwardRef<ClientFormValidatorRef, ClientNameProps>(({ client }, ref) => {
    const control = useMuiFormControl<TClientForm>();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

	//styles
	const { classes } = clientNameStyles();

	//check if the client name input is the currently focused element
	const [clientNameFocused, clientNameRef] = useIsElementActive<HTMLInputElement>();

	//if the similar names tooltip should be open
	const [similarNamesTooltipOpen, setSimilarNamesTooltipOpen] = useState<boolean>(false);

	//use form context to set values
	const { setValue, isLoading } = useMuiFormContext();

	//get the current value of the name field
    const [clientName, clientCui] = useMuiWatch({ name: ["name", "clientCui"], control });

	//get the current value of the shortname field
	const [clientShort] = useMuiWatch({ name: ["clientShort"], control});
	//get all clients
	const { data: clients } = useLoadApi(GetAllClients, [], []);

	//filter clients to remove the client currently being edited (if in edit mode)
	const filteredClients = useMemo(() => (clients ?? []).filter(x => x.id !== client?.id), [clients, client]);

	//update ref
    useImperativeHandle(ref, () => ({
        clients: filteredClients,
    }), [filteredClients]);

	useEffect(() => {
		if(clientName !== null){
			let tempClientName = clientName.length > 30 ? clientName.slice(0, 30) : clientName;
			if(clientShort === null)
			{
				setValue('clientShort', tempClientName); 
			}
			
		}
		
	}, [clientNameFocused]);

	//get clients with a similar name
	const similarClients = useMemo(() => filteredClients.filter(x => clientName !== null && x.name.toLowerCase().includes(clientName.toLowerCase())), [filteredClients, clientName]);

	//get helper text for name input
	const similarNameHelperText: React.ReactNode = useMemo(() => {
		//if there is more than 0 similar locations let the user know they exist and what they are
		if (similarClients.length > 0) {
			//get a copy of similarClients
			const remainingClients = [...similarClients]; 

			//get the first 10 contacts
			const firstTenClients = remainingClients.splice(0, 10);

			//title
			const title = (
				<List dense>
					{firstTenClients.map(contact => (
						<ListItem key={contact.id} sx={{ padding: '0' }}><ListItemText primary={contact.name}/></ListItem>
					))}
					{remainingClients.length > 0 ? <ListItem sx={{ padding: '0' }}><ListItemText primary={`and ${remainingClients.length} more...`}/></ListItem> : null}
				</List>
			);
			
			//get plurality 
			const plural = similarClients.length > 1;
			
			return (
				<>
					{`There ${plural ? "are" : "is"} ${similarClients.length} client${plural ? "s" : ""} with a similar name.`}
					<Tooltip
						open={similarNamesTooltipOpen || clientNameFocused} 
						title={title} 
						onOpen={() => setSimilarNamesTooltipOpen(true)}
						onClose={() => setSimilarNamesTooltipOpen(false)}
						arrow
					>
						<HelpIcon sx={{ margin: '0 0 -3px 5px', height: '15px', width: '15px' }}/>
					</Tooltip>
				</>
			);
		}
		return undefined;
	}, [similarClients, clientNameFocused, similarNamesTooltipOpen, setSimilarNamesTooltipOpen]);

	const handleCuiClick = useCallback(async () => {
		if (clientCui === false) {
			//confirm that the user wants to mark this client as ITAR/DFARS
			const deleted = await confirm({
				prompt: `Do you want to flag this client as potentially having ITAR/DFARS jobs in the future?`,
				title: 'ITAR/DFARS Confirmation',
				confirmText: "Confirm",
				onConfirm: async () => {
					setValue('clientCui', true);
				}
			});
		}
		else {
			setValue('clientCui', false);
		};
		
	}, [clientCui]);
	
    return (
		<Alignment column sx={{ width: '-webkit-fill-available' }}>
			<Alignment row>
				<FormTextField
					name={"name"}
					control={control}
					label={"Client"}
					required
					FormHelperTextProps={{ sx: { color: 'warning.main' } }}
					helperText={similarNameHelperText}
					fullWidth
					sx={{ "& .MuiInputBase-input": { fontSize: '2em', padding: '5px 10px' } }}
					ref={clientNameRef}
				/>
				
				<Spacer horizontal/>

				{/* ITAR/DFARS field */}
				{isLoading ? null : 
					<FormControlLabel
						control={<Checkbox checked={clientCui} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCuiClick()} name={'clientCui'} />}
						label={'ITAR/DFARS Potential'}
						className={classes.cuiBox}
					/>
				}

				{/* Active Field */}
				{client === null ? null : (
					<FormCheckbox
						name={"active"}
						control={control}
						label={"Active"}
						
						sx={{ margin: 'auto 5px auto 10px'}}
					/> 
				)}
			</Alignment>

					
			<Spacer vertical/>
			<Alignment row>
				{/* ClientShortName*/}
				<FormTextField 
					name={"clientShort"}
					control={control}
					label={"Short Name"}
					required={true}
					sx={{width: '375px'}}
					
				/>
			</Alignment>
			<Spacer vertical/>
			<Alignment row>
				{/* Abbrev */}
				<FormTextField
					name={"abbrev"}
					control={control}
					label={"Abbrev"}
					sx={{width: '375px'}}
				/>

				
			</Alignment>
			
		</Alignment>
    );
}));