import { makeDeviceStyles } from '@imas/styles';

const loginStyles = makeDeviceStyles()((theme) => ({
	view: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignContent: 'center',
		overflow: 'scroll'
	},
	contentContainer: {
		display: 'flex', 
		flexDirection: 'column',
		margin: 'auto auto auto auto',
		[theme.breakpoints.up("sm")]: {
			margin: '10vh auto auto auto'
		},
		padding: '25px',
		width: '80%',
		maxWidth: '400px',
		minHeight: '350px',
	},
	loginContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '25px',
		width: '-webkit-fill-available'
	},
	loginForm: {
		display: 'flex',
		flexDirection: 'column',
	},
	spacer: {
		minHeight: '20px',
		width: '100%'
	},
	bigSpacer: {

	},
	logoImg: {
		width: '-webkit-fill-available'
	},
	dev: {
		margin: 'auto',
		padding: '10px'
	}
}));

export default loginStyles;