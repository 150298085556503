 import { lazy } from 'react';

//export page wrapper component
export * from './Page';

//pages to be loaded with main.js
export { default as Dashboard } from './Dashboard/Dashboard';
export { default as Login } from './Login/Login';

//code split pages
export const Home = lazy(() => import('./Home/Home'));
export const CertsAndQC = lazy(() => import('./CertsAndQC/CertsAndQC'));
export const Admin = lazy(() => import('./Admin/Admin'));
export const ClientsAndContacts = lazy(() => import('./ClientsAndContacts/ClientsAndContacts').then(module => ({ default: module.ClientsAndContacts })));
export const Clients = lazy(() => import('./ClientsAndContacts/ClientsAndContacts').then(module => ({ default: module.Clients })));
export const Managers = lazy(() => import('./Managers/Managers'));
export const Proposals = lazy(() => import('./Proposals/Proposals'));
export const ServiceOrders = lazy(() => import('./ServiceOrders/ServiceOrders'));
export const Feedback = lazy(() => import('./Feedback/Feedback'));
export const User = lazy(() => import('./User/User'));
export const Files = lazy(() => import('./Files/Files'));