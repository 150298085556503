import { createContext } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspection } from 'src/api/types/api/misc/vehicles/VehicleInspection';

export interface CalculateProblemsPresentCallback { (): void }

export interface TVehicleInspectionFormContext {
	vehicleInspection?: VehicleInspection;
	radioDisabled: boolean;
	hideCompletion: boolean;
	disableCompletion: boolean;
	hideIgnore: boolean;
	disableIgnore: boolean;
	getRadioValue: {(value: int | null) : string}
	setRadioValue: {(variable: keyof TVehicleInspectionForm, state: string) : void}
	setCheckboxValue: {(box: "fixed" | "ignore", variable: keyof TVehicleInspectionForm, state: boolean) : void}
	//calculateProblemsPresent: CalculateProblemsPresentCallback;
};

export const VehicleInspectionFormContext = createContext<TVehicleInspectionFormContext>({
	vehicleInspection: undefined,
	radioDisabled: true,
	hideCompletion: true,
	disableCompletion: true,
	hideIgnore: true,
	disableIgnore: true,
	getRadioValue: (value: int | null) => { return 'fail';},
	setRadioValue: (variable: keyof TVehicleInspectionForm, state: string) => {},
	setCheckboxValue: (box: "fixed" | "ignore", variable: keyof TVehicleInspectionForm, state: boolean) => {},
	//calculateProblemsPresent: () => {},
});