import { registerPushNotifications } from "@imas/service-worker";
import { Button } from "@mui/material";

export const registerNotificationActionButton = () => {

    return (
        <Button
            onClick={() => registerPushNotifications()}
            sx={{zIndex: 1500}}
        >{'Enable'}</Button>
    );
};