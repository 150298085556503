import { Alignment, XSkeleton } from '@imas/components/layout';
import { Box, Typography, TextField } from '@mui/material';
import { memo } from 'react';
import { useMuiFormControl, FormTextField, FormDatePicker } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { GetEmployee } from '@imas/api/employee';
import { useLoadApi } from '@imas/api';

export const WorkPlaceReviewHeader = memo(({ fullJobName, reviewedByEmployeeId }: { fullJobName?: string; reviewedByEmployeeId: int | null; }) => {
    const control = useMuiFormControl<TServiceOrderWorkPlaceReviewForm>();

	//get employee info
	const { data: reviewedBy } = useLoadApi(GetEmployee, [reviewedByEmployeeId ?? -1], [reviewedByEmployeeId], { disabled: reviewedByEmployeeId === null });

	return (
		<Alignment column>
			{/* Full Job Name & Title */}
			<Typography variant={"h5"} sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{"Work Place Safety Review"}</Typography>
			<Typography variant={"h6"} sx={{ fontWeight: 'bold' }}>{fullJobName ? fullJobName : <XSkeleton variant={"text"} width={250}/>}</Typography>

			{/* Reviewed By Name */}
			{reviewedBy ? (
				<TextField
					label={"Lead"}
					value={`${reviewedBy.first} ${reviewedBy.last}`}
					disabled
					sx={{ margin: '5px 0', maxWidth: '250px' }}
				/>
			) : null}

			{/* Date */}
			<FormDatePicker
				control={control}
				name={"date"}
				label={"Date"}
				required
				renderInput={(params) => <TextField {...params}/>}
				sx={{ margin: '5px 0', maxWidth: '150px' }}
			/>

			{/* Disclaimer */}
			<Typography variant={"body1"}>{"Prior to performing tests or inspections, perform a walk down of the area where work is to be peformed and indicate any safty hazards that are identified and the proptective actions taken. If any new safety issue arises or the working environment changes, a new safety review needs to be filled out."}</Typography>
		
			{/* Divider */}
			<Box sx={{ width: '-webkit-fill-available', bgcolor: "text.primary", height: '3px', marginBottom: '5px' }}/>

			{/* General Description */}
			<Typography variant={"body1"}><b>{"GENERAL DESCRIPTION OF WORK AREA:"}</b></Typography>
			<FormTextField
				control={control}
				name={"workPlaceDesc"}
				required
				multiline
				rows={3}
			/>
		</Alignment>
	);
});