import { GetSignatureRequests } from 'src/api/service-order/client-signatures';
import { GetServiceOrderContacts, GetServiceOrderSiteContacts } from './../../../api/service-order/contacts';
import { DataHookResult, createUpdateHandler } from './_utils';
import { useHub, useOnHub } from '../_hubs';
import { useLoadApi, UseLoadApiOptionsFromApi } from '../_internals';
import { GetLabSampleLogs, GetServiceOrderWorkPlaceReviewEmployees }  from '../service-order';
import { useMemo } from 'react';
import { SOReportVersionResult } from 'src/api/types/api/service-orders/types';
import { GetHoursForSO, GetPayrollEmpHours, GetServiceOrderReports, GetvServiceOrderCharges, QueryServiceOrderTrackingResults } from 'src/api/service-order/api';
import moment, { Moment } from 'moment';

// export const useServiceOrderWorkPlaceReviews = (serviceOrderId: int, options?: UseLoadApiOptions) => {
// 	//load data
//     const api = useLoadApi(GetServiceOrderWorkPlaceReviews, [serviceOrderId], [serviceOrderId], options);

// 	//use signalR hub
// 	const hub = useHub("/service-orders/work-place-reviews");

// 	//update handler
// 	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

// 	//listen for updates and update the list of data
// 	useOnHub(hub, "Update", (type, workPlaceReview) => {
// 		//ignore records which don't belong to the specified service order
// 		if (workPlaceReview.serviceOrderId !== serviceOrderId) return;

// 		//update the list
// 		updateData(type, workPlaceReview);
// 	}, [serviceOrderId]);
	
// 	return [api.data, api] as DataHookResult<typeof api>;
// };

export const useServiceOrderWorkPlaceReviewEmployees = (workReviewId: int, options?: UseLoadApiOptionsFromApi<typeof GetServiceOrderWorkPlaceReviewEmployees>) => {
	//load data
    const api = useLoadApi(GetServiceOrderWorkPlaceReviewEmployees, [workReviewId], [workReviewId], options);

	//use signalR hub
	const hub = useHub("/service-orders/work-place-reviews/employees");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, workPlaceReviewEmployee) => {
		//ignore records which don't belong to the specified service order
		if (workPlaceReviewEmployee.workPlaceReviewId !== workReviewId) return;

		//update the list
		updateData(type, workPlaceReviewEmployee);
	}, [workReviewId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};
export const useServiceOrderReports = (
	uniqueId: number, 
	options?: UseLoadApiOptionsFromApi<typeof GetServiceOrderReports>
  ) => {
	// load data
	const api = useLoadApi(GetServiceOrderReports, [uniqueId], [uniqueId], options);
  
	// use signalR hub
	const hub = useHub("/service-orders/details/reports");
  
	// update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);
  
	// listen for updates and update the list of data
	useOnHub(
	  hub,
	  "Update",
	  (type, SOReportVersionResult) => {
		// ignore records which don't belong to the specified service order
		if (SOReportVersionResult.uniqueId !== uniqueId) return;
  
		// update the list
		updateData(type, SOReportVersionResult);
	  },
	  [uniqueId]
	);
  
	// return data, api, and api.call as an object
	return {
	  data: api.data,
	  api,
	  call: api.call,
	};
  };
  
  

export const useServiceOrderTrackingResults = (rangeStart: Moment, rangeEnd: Moment, completionStatus: string | int, options?: UseLoadApiOptionsFromApi<typeof QueryServiceOrderTrackingResults>) => {
	//load data
    const api = useLoadApi(QueryServiceOrderTrackingResults, [rangeStart, rangeEnd, undefined,undefined, undefined, undefined, undefined,undefined,undefined,undefined,undefined, completionStatus], [rangeStart, rangeEnd, completionStatus]);

	//use signalR hub
	const hub = useHub("/service-orders/tracker/tracking-results");

	

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, SOTrackingResult) => {
		//ignore records which don't belong to the range and completion status
		if ( moment(SOTrackingResult.startDate, 'YYYY-MM-DD').isBefore(rangeStart) && moment(SOTrackingResult.startDate, 'YYYY-MM-DD').isAfter(rangeEnd) ) return;

		//update the list

		updateData(type, SOTrackingResult);
	}, [rangeStart, rangeEnd, completionStatus]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const usePayrollHours = (uniqueId: int, options?: UseLoadApiOptionsFromApi<typeof GetPayrollEmpHours>) => {
	//load data
    const api = useLoadApi(GetPayrollEmpHours, [uniqueId], [uniqueId], options);

	//use signalR hub
	const hub = useHub("/service-orders/details/payroll-hours");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, GetPayrollEmpHoursResults) => {
		//ignore records which don't belong to the specified service order
		let belong = GetPayrollEmpHoursResults.map(x => {
			if (x.uniqueid !== uniqueId) return false;
			else return true;
		});
		if (belong.includes(false)) return;

		//update the list
		api.update(GetPayrollEmpHoursResults);
	}, [uniqueId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

export const useServiceOrderContacts = (uniqueId: int, options?: UseLoadApiOptionsFromApi<typeof GetServiceOrderContacts>) => {
	//load data
    const api = useLoadApi(GetServiceOrderContacts, [uniqueId], [uniqueId], options);

	//use signalR hub
	const hub = useHub("/service-orders/contacts");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, siteContact) => {
		//ignore records which don't belong to the specified service order
		if (siteContact.uniqueId !== uniqueId) return;

		//update the list
		updateData(type, siteContact);
	}, [uniqueId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useServiceOrderSiteContacts = (uniqueId: int, options?: UseLoadApiOptionsFromApi<typeof GetServiceOrderSiteContacts>) => {
	//load data
    const api = useLoadApi(GetServiceOrderSiteContacts, [uniqueId], [uniqueId], options);

	//use signalR hub
	const hub = useHub("/service-orders/contacts/site-contacts");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, siteContact) => {
		//ignore records which don't belong to the specified service order
		if (siteContact.uniqueId !== uniqueId) return;

		//update the list
		updateData(type, siteContact);
	}, [uniqueId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const usevServiceOrderDetails = (uniqueId: int, options?: UseLoadApiOptionsFromApi<typeof GetvServiceOrderCharges>) => {
	//load data
    const api = useLoadApi(GetvServiceOrderCharges, [uniqueId], [uniqueId], options);

	//use signalR hub
	const hub = useHub("/service-orders/details/charges");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, detail) => {
		//ignore records which don't belong to the specified service order
		if (detail.uniqueid !== uniqueId) return;
		//if (type !== "CREATE") return;

		//update the list
		updateData(type, detail);
	}, [uniqueId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useServiceOrderHours = (uniqueId: int, options?: UseLoadApiOptionsFromApi<typeof GetHoursForSO>) => {
	//load data
    const api = useLoadApi(GetHoursForSO, [uniqueId], [uniqueId], options);

	//use signalR hub
	const hub = useHub("/service-orders/details/hours");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, detail) => {
		//ignore records which don't belong to the specified service order
		if (detail.uniqueid !== uniqueId) return;

		//update the list
		updateData(type, detail);
	}, [uniqueId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useServiceOrderSignatureRequests = (uniqueId: int, options?: UseLoadApiOptionsFromApi<typeof GetSignatureRequests>) => {
	//load data
    const api = useLoadApi(GetSignatureRequests, [uniqueId], [uniqueId], options);

	//use signalR hub
	const hub = useHub("/service-orders/signatures");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, signatureRequest) => {
		//ignore records which don't belong to the specified service order
		if (signatureRequest.uniqueId !== uniqueId) return;

		//update the list
		updateData(type, signatureRequest);
	}, [uniqueId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useLabSampleLogs = (fromDate: Moment, toDate: Moment, options?: UseLoadApiOptionsFromApi<typeof GetLabSampleLogs>) => {
	//load data
    const api = useLoadApi(GetLabSampleLogs, [fromDate, toDate], [fromDate, toDate], options);

	//use signalR hub
	const hub = useHub("/service-orders/lab-items");

	//update handler
	const updateData = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, sample) => {
		//ignore records which are not between the to and from date
		if (moment(sample.labDateTime).isAfter(toDate.endOf('day')) || moment(sample.labDateTime).isBefore(fromDate.startOf('day'))) return;

		//update the list
		updateData(type, sample);
	}, [fromDate, toDate]);

	return [api.data, api] as DataHookResult<typeof api>;
};