import { Typography, Paper, PaperProps, createFilterOptions, AutocompleteProps, PopperProps, ListSubheader, Box, BoxProps, FilterOptionsState } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';
import { IdName } from '@imas/api';
import { makeStyles } from '@imas/styles';

export interface CreateIdNameAutocompleteVirtualizerProps {
	showId?: boolean;
	idWidth?: int;
	nameWidth?: int;
	labels?: { id?: string; name?: string; } | true;
	dense?: boolean;
	forceIdLabel?: boolean;
};

const useIdNameStyles = makeStyles<{ dense?: boolean; }>()((_, { dense }) => ({
	item: {
		padding: dense ? '0 8px!important' : undefined,
		display: 'flex',
		flexDirection: 'row',
	},
	itemText: {
		fontSize: dense ? '0.875rem' : undefined,
	},
	disabled: {
		color: 'text.disabled',
	}
}));

export const createIdNameAutocompleteVirtualizer = (props: CreateIdNameAutocompleteVirtualizerProps): NonNullable<Pick<AutocompleteProps<IdName, false, false, false, "div">, "filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup" | "getOptionLabel">> => {
	const { showId, labels, dense, forceIdLabel } = props;
	let { idWidth, nameWidth } = props;
	const lisboxPadding = dense ? 0 : 8;
	const itemSize = dense ? 24 : undefined;
	idWidth = idWidth ?? 100;
	nameWidth = nameWidth ?? 250;
	const totalWidth =  showId ? idWidth + nameWidth : nameWidth;

	const IdNameListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
		const { classes, cx } = useIdNameStyles({ dense });

		return (
			<>
				{/* Column Labels */}
				{labels ? (
					<ListSubheader sx={{ lineHeight: '24px', padding: '0px', display: 'flex', flexDirection: 'row' }}>
						{showId ? <div style={{ marginLeft: '8px', width: idWidth, fontWeight: 'bold' }}>{(typeof labels === 'object' ? labels.id : null) ?? "ID"}</div> : null}
						<div style={{ marginLeft: showId ? undefined : '8px', width: nameWidth, fontWeight: 'bold' }}>{(typeof labels === 'object' ? labels.name : null) ?? "Name"}</div>
					</ListSubheader>
				) : null}

				<VirtualizedListboxAdpater 
					{...props} 
					ref={ref}
					listboxPadding={lisboxPadding}
					itemSize={itemSize}
					renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<IdName>) => {
						const row = data[index];
						
						return (
							<div 
								{...row.props}
								className={cx(row.props?.className, classes.item)}
								style={{ ...style, boxSizing: 'content-box', width: totalWidth, top: (style.top as number) + lisboxPadding }}
							>
								{showId ? (
									<Typography 
										noWrap
										className={cx(classes.itemText, { [classes.disabled]: row.option.disabled })}
										style={{ width: idWidth }}
									>{row.option.id}</Typography>
								) : null}

								<Typography 
									noWrap
									className={cx(classes.itemText, { [classes.disabled]: row.option.disabled })}
									style={{ width: nameWidth }}
								>{row.option.name}</Typography>
							</div>
						);
					}}
				/>
			</>
		);
	});

	const IdNamePaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
		return (
			<Paper 
				{...props} 
				ref={ref} 
				style={{ minWidth: totalWidth + 24 }}
			/>
		);
	});

	const idNameFilter = createFilterOptions<IdName>({ stringify: showId ? (x) => `${x.id} ${x.name}` : (x) => `${x.name}` });
	
	return {
		disableListWrap: true,
		filterOptions: idNameFilter,
		PopperComponent: VirtualizedListboxPopper, //(props: PopperProps) => <VirtualizedListboxPopper {...props} style={{ ...props.style, minWidth: props?.style?.width, width: undefined }}/>, //sx={{ minWidth: width }}/>,
		ListboxComponent: IdNameListboxAdapter,
		PaperComponent: IdNamePaper,
		renderOption: (props, option) => ({ props, option }),
		renderGroup: (params) => params,
		getOptionLabel: showId || forceIdLabel ? (option) => `${option.id}` : (option) => option.name
	};
};
