import axios from 'axios';
import { EditorItem, EditorItemFields } from "./types";

export interface getEditorURLOptions {
	readOnly?: boolean;
};

// export function getEditorURL<T extends EditorItemFields>(url: string): string;
// export function getEditorURL<T extends EditorItemFields>(url: string, item?: EditorItem<T>): string;
export function getEditorURL<T extends EditorItemFields = {}>(url: string, item: EditorItem<T>, options?: getEditorURLOptions): string {
	const { id, ...params } = item;

	//get edit/view url
	return axios.getUri({
		url: `${url}/${id === null ? "new" : id}`,
		params: {
			...params,
			mode: (options?.readOnly ? "view" : undefined),
		},
	});
};