import { Button, IconButton, ButtonPropsColorOverrides, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { useEffect, useState, useRef } from 'react';
import { FileTables } from '@imas/api/files/types';
import { Error as ErrorIcon } from '@mui/icons-material';
import { useLoadApi } from '@imas/api';
import { GetFileInfo } from '@imas/api/files';
import { openLocalExplorer } from  '@imas/utils/files';
import { OverridableStringUnion } from '@mui/types';
import { useNative } from '@imas/native';
import { snackbar } from '@imas/utils/snackbar';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { OpenInNew } from '@mui/icons-material';

export interface FileLinkProps {
	disabled?: boolean;
    fileTable: FileTables;
    pathLocator: string;
    text?: React.ReactNode;
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    iconLocation?: "start" | "end";
    size?: "small" | "medium" | undefined;
    color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>;
    variant?: "text" | "outlined" | "contained";
    className?: string;
    onOpen?: () => void;
	fileNameForRefresh?: string;
	gridActionsCellItem?: boolean;
	gridCellMenu?: boolean;
};

/** Takes a fileTable & pathLocator and will provide a clickable IconButton or Button which will open the file specified. */
const FileLink = ({ disabled, fileTable, pathLocator, text, icon, iconLocation, size, color, variant, className, onOpen, fileNameForRefresh, gridActionsCellItem, gridCellMenu }: FileLinkProps) => {
    //default value handling
    const Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = icon === undefined ? ErrorIcon : icon;
    if (disabled === undefined) disabled = false;
    if (iconLocation === undefined && icon !== undefined) iconLocation = "start";

    //filePath state
    const { data: fileInfo, call: refreshFileInfo } = useLoadApi(GetFileInfo, [fileTable, pathLocator], [fileTable, pathLocator, fileNameForRefresh], { disabled: disabled });

    //open, true if the link should be opened
    const [open, setOpen] = useState<boolean>(false);

	//onOpenRef
	const onOpenRef = useRef(onOpen);
	onOpenRef.current = onOpen;

    //use native hook & ref
    const { isNative, native } = useNative();
	const nativeRef = useRef({ isNative, native });
	nativeRef.current = { isNative, native };

    //detect changes in filePath and open
    useEffect(() => {
        //if (fileNameForRefresh !== undefined) refreshFileInfo();

        //if the file path has been loaded and the link was opened then open the file
        if (fileInfo && open) {
            //if native functionality is available use it
            if (nativeRef.current.isNative) {
                nativeRef.current.native.openLocal(fileInfo.fullPath)
                .then(errMsg => {
                    if (errMsg) snackbar.error(errMsg);
                });
            }
            //fallback to localexplorer
            else {
                //open the file using local explorer link
                openLocalExplorer(fileInfo);
            }

            //call onOpen callback if it was set
            if (onOpenRef.current !== undefined) onOpenRef.current();

            //set open to false since the file was loaded
            setOpen(false);
        }
    }, [fileInfo, open]);

    //open function
    const openFile = () => {
        refreshFileInfo();
        setOpen(true);
    };

    //show a Icon button if no text was provided, otherwise show a normal button
    if (text === undefined) {
        return (
            <IconButton
                disabled={disabled}
                size={size}
                color={color}
                className={className}
                onClick={openFile}
            ><Icon/></IconButton>
        );
    } 
	//show a grid cell action item for datagrids if the prop is given
	else if (gridActionsCellItem) {
		return (
			<GridActionsCellItem
				label={'Open File'}
				icon={<Icon/>}
				disabled={disabled}
				color={color}
				onClick={openFile}
				showInMenu
			></GridActionsCellItem>
		);
	}
	else {
        return (
            <Button
                disabled={disabled}
                size={size}
                color={color}
                variant={variant} 
                className={className}
                endIcon={iconLocation === "end" ? <Icon/> : undefined} 
                startIcon={iconLocation === "start" ? <Icon/> : undefined}
                onClick={openFile}
            >{text}</Button>
        );
    }
};

export { FileLink };