import { Country } from '@imas/api/system';
import { Typography, Paper, PaperProps, createFilterOptions, AutocompleteProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

const CountriesListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<VirtualizedListboxAdpater 
			{...props} 
			ref={ref} 
			renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<Country>) => {
				const row = data[index];
				
				return (
					<li {...row.props}  style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}>
						<Typography noWrap sx={{ width: '50px' }}>{row.option.countryAbbrev3}</Typography>
						<Typography noWrap sx={{ width: '450px' }}>{row.option.name}</Typography>
					</li>
				);
			}}
		/>
	);
});

const CountriesPaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
	return <Paper {...props} ref={ref} sx={{ minWidth: '500px'}}/>;
});

const statesFilter = createFilterOptions<Country>({ stringify: (country) => `${country.countryAbbrev3} ${country.name}` });

export const COUNTRY_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<Country, false, false, false, "div">, "getOptionLabel" |"filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {
	filterOptions: statesFilter,

	//virtualization props
	disableListWrap: true,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: CountriesListboxAdapter,
	PaperComponent: CountriesPaper,
	getOptionLabel: (option) => `${option.countryAbbrev3}`,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};