import { useApi } from '@imas/api';
import { TimeSheetCertificationHour, TimeSheetDetail } from '@imas/api/time-sheet';
import { GetUserHourTypeEligible } from '@imas/api/user';
import { HOUR_TYPES } from '@imas/constants';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { HourTypeEligibilityMap, HourTypes, TimeSheetWeekday, TimeSheetWeekdayWidthMap, TIME_SHEET_DETAILS_EDIT_GRID_API_REF, TimeSheetDetailRM, TimeSheetDetailDM, WeekHoursRecord, TIME_SHEET_DETAILS_EDIT_GRID_COLUMNS, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TimeSheetDailyNotes, GetAutoFillHourType } from './types';
import { TimeSheetDetailsFormContext } from './TimeSheetDetailsFormContext';
import { makeStyles } from '@imas/styles';
import { alpha } from '@mui/system';
import { TypedGridEditRowProps } from '@imas/data-grid';
import { Moment } from 'moment';
import Enumerable from 'linq';

export const TIME_SHEET_DETAIL_EDITOR_PANEL_CONTENT_HEIGHT = 200; 

export const TIME_SHEET_DETAILS_COL_WIDTHS = {
	CLIENT: 150,
	PRONUM: 90,
	DESCRIPTION: 250,
	HOUR_TYPE: 80,
	SERVICE_ORDER_ID: 50,
	SHIFT_DIFF: 50,
	SO_WEEKDAY: 160,
	HOURS: 50,
	HOUR_TOAL: 60,
	WORK_CLASS: 60,
	NOTES: 90,
	ACTIONS: 25
};

export const weekdayMap: Record<TimeSheetWeekday, int> = {
	'sat': 0,
	'sun': 1,
	'mon': 2,
	'tue': 3,
	'wed': 4,
	'thu': 5,
	'fri': 6,
};

export const weekdayReverseMap: Record<int, TimeSheetWeekday> = {
	0: 'sat',
	1: 'sun',
	2: 'mon',
	3: 'tue',
	4: 'wed',
	5: 'thu',
	6: 'fri',
};

export const weekdayFullNameMap: Record<TimeSheetWeekday, string> = {
	'mon': 'Monday',
	'tue': 'Tuesday',
	'wed': 'Wednesday',
	'thu': 'Thursday',
	'fri': 'Friday',
	'sat': 'Saturday',
	'sun': 'Sunday',
};

export const FILLER_CHR_VALUES = { startDate: null, endDate: null, dateRange: '', soIsShiftDiff: false, monChr: false, tueChr: false, wedChr: false, thuChr: false, friChr: false, satChr: true, sunChr: true }; 

export const weekdayList: TimeSheetWeekday[] = ['sat', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri'];

export const HOUR_CELL_DECIMAL_OPTIONS = { decimalScale: 2, fixedDecimalScale: true, roundToNearest: 0.25, min: 0, max: 24 };

export const getWeekRecordTotalHours = (detail: WeekHoursRecord) => {
	return (detail.mon ?? 0) + (detail.tue ?? 0) + (detail.wed ?? 0) + (detail.thu ?? 0) + (detail.fri ?? 0) + (detail.sat ?? 0) + (detail.sun ?? 0);
};

export const getCertificationHourTotalHours = (certificationHour: TimeSheetCertificationHour) => {
	return (certificationHour.mon ?? 0) + (certificationHour.tue ?? 0) + (certificationHour.wed ?? 0) + (certificationHour.thu ?? 0) + (certificationHour.fri ?? 0) + (certificationHour.sat ?? 0) + (certificationHour.sun ?? 0);
};

export const getCertificationHourDayTotal = (certificationHours: TimeSheetCertificationHour[] | undefined, day: TimeSheetWeekday) => {
	return Enumerable.from(certificationHours ?? []).select(x => x[day] ?? 0).sum();
};

export const useUserEligibleForHourTypes = () => {
	const getUserHourTypeEligible = useApi(GetUserHourTypeEligible);
	const [map, setMap] = useState<HourTypeEligibilityMap | null>(null);

	useEffect(() => { 
		(async () => {
			const requests = Object.keys(HOUR_TYPES).map(async (x) => ({ [x]: await getUserHourTypeEligible(HOUR_TYPES[x as HourTypes]) }));
			const results = await Promise.all(requests);
			setMap(results.reduce((a, b) => ({ ...a, ...b }), {}) as HourTypeEligibilityMap);
		})();
	}, []);

	return map;
};

export const useAutoFillHourType = (): GetAutoFillHourType => {
	const eligibilityMap = useUserEligibleForHourTypes();

	return useCallback<GetAutoFillHourType>((client, desc, pronum) => {
		client = client.toLowerCase().trim();
		desc = desc.toLowerCase();

		if (eligibilityMap === null) return null;
		if (eligibilityMap.VACATION) {
			if ((desc.includes('vacation') || desc.includes('pto')) && (client === 'pnl' || pronum === null)) return HOUR_TYPES.VACATION;
		}
		if (eligibilityMap.SICK) {
			if (desc.includes('sick') && (client === 'pnl' || pronum === null)) return HOUR_TYPES.SICK;
		}
		return null;
	}, [eligibilityMap]);
};

export const useCertHourMargin = (api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF, offset: int) => {
	const { managementMode } = useContext(TimeSheetDetailsFormContext);

	const get = useCallback((field: 'client' | 'pronum' | 'description' |'hourType' | 'serviceOrderId' | 'soWeekday') => api.current.getColumn(field).computedWidth, [api]);

	const [clientWidth, setClientWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.CLIENT);
	const [pronumWidth, setPronumWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.PRONUM);
	const [descriptionWidth, setDescriptionWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.DESCRIPTION);
	const [hourTypeWidth, setHourTypeWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.HOUR_TYPE);
	const [serviceOrderIdWidth, setServiceOrderIdWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.SERVICE_ORDER_ID);
	const [soWeekdayWidth, setSoWeekdayWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.SO_WEEKDAY);
	const [shiftDiff, setShiftDiff] = useState(TIME_SHEET_DETAILS_COL_WIDTHS.SHIFT_DIFF);

	useEffect(() => {
		if (!api.current) return;

		setClientWidth(get('client'));
		setPronumWidth(get('pronum'));
		setDescriptionWidth(get('description'));
		setHourTypeWidth(get('hourType'));
		setServiceOrderIdWidth(get('serviceOrderId'));
		setSoWeekdayWidth(get('soWeekday'));

		return api.current.subscribeEvent('columnResize', (col) => {
			switch (col.colDef.field) {
				case 'client':
					return setClientWidth(col.width);
				case 'pronum':
					return setPronumWidth(col.width);
				case 'description':
					return setDescriptionWidth(col.width);
				case 'hourType':
					return setHourTypeWidth(col.width);
				case 'serviceOrderId':
					return setServiceOrderIdWidth(col.width);
				case 'soWeekday':
					return setSoWeekdayWidth(col.width);
				case 'shiftDiff':
					return setShiftDiff(col.width);
			}
		});
	}, [api, setClientWidth, setPronumWidth, setDescriptionWidth, setHourTypeWidth, setServiceOrderIdWidth, setSoWeekdayWidth]);
	
	const calcShiftDiff = useMemo(() => managementMode ? shiftDiff : 0, [managementMode, shiftDiff]);
	return clientWidth + pronumWidth + descriptionWidth + hourTypeWidth + serviceOrderIdWidth + soWeekdayWidth + calcShiftDiff + offset;
};

export const useColWidth = (api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF, col: keyof TimeSheetDetailRM) => {
	const [width, setWidth] = useState(TIME_SHEET_DETAILS_COL_WIDTHS[col.toUpperCase() as keyof typeof TIME_SHEET_DETAILS_COL_WIDTHS] ?? TIME_SHEET_DETAILS_COL_WIDTHS.HOURS);

	useEffect(() => {
		return api.current.subscribeEvent('columnResize', (colProps) => {
			if (colProps.colDef.field === col) setWidth(colProps.width);
		});
	}, [col, setWidth]);

	const get = useCallback((col: keyof TimeSheetDetailRM) =>{
		let timeout: { current: NodeJS.Timeout | null; } = { current: null };
		
		const set = () => {
			return setTimeout(() => {
				if (!api.current) { 
					timeout.current = set();
				} else {
					timeout.current = null;
					setWidth(api.current.getColumn(col).computedWidth);
				}
			}, 25);
		};

		timeout.current = set();

		return () => { if (timeout.current) clearTimeout(timeout.current); };
	}, [setWidth]);

	useEffect(() => {
		return get(col);
	}, [col, get]);

	return width;
};

export const useCertHourColWidths = (api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF): TimeSheetWeekdayWidthMap => {
	const get = useCallback((field: TimeSheetWeekday) => api.current.getColumn(field).computedWidth, []);

	const [widths, setWidths] = useState({
		mon: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
		tue: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
		wed: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
		thu: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
		fri: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
		sat: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
		sun: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
	});

	useEffect(() => {
		setWidths({
			mon: get('mon'),
			tue: get('tue'),
			wed: get('wed'),
			thu: get('thu'),
			fri: get('fri'),
			sat: get('sat'),
			sun: get('sun'),
		});

		return api.current.subscribeEvent('columnResize', (col) => {
			if (weekdayList.includes(col.colDef.field)) setWidths(x => ({ ...x, [col.colDef.field]: col.width }));
		});
	}, [api, setWidths]);

	return widths;
};

export const useDailyNotesCount = (record?: TimeSheetDailyNotes) => {	
	return weekdayList.map(x => (record ?? {})[x] ?? '').map(x => Number(!!x)).reduce((a, b) => a + b, 0);
};

export const getWeekdayDate = (mondayDate: Moment, day: TimeSheetWeekday): Moment => {
	return mondayDate.clone().add(weekdayMap[day], 'days');
};

export const getDailyNotes = (row: TimeSheetDetailDM): TimeSheetDailyNotes => ({
	mon: row.monNotes ,
	tue: row.tueNotes,
	wed: row.wedNotes,
	thu: row.thuNotes,
	fri: row.friNotes,
	sat: row.satNotes,
	sun: row.sunNotes,
});

export const useHourCellColoring = (day: TimeSheetWeekday, row: TimeSheetDetailDM, mode: 'edit' | 'view' = 'edit') => {
	//
	const { classes } = useHourCellColoringStyles();
	const { soEmployeeHours, timeSheet, api } = useContext(TimeSheetDetailsFormContext);
	
	const uniqueId = mode === 'edit' ? api.current.getRowWithUpdatedValues(row.id)?.uniqueId ?? null : row.uniqueId;

	const soRecord = soEmployeeHours?.find(x => x.uniqueId === uniqueId);

	const dayHours = (mode === 'edit' ? api.current.getRowWithUpdatedValues(row.id)[day] ?? null : row[day]);
	const daySoHours = soRecord ? soRecord[day] ?? 0 : 0;
	const dayMethodHours = getCertificationHourDayTotal(row.certificationHours, day);
	const dayChr = row[`${day}Chr`];

	const colorGreen = soRecord && dayHours !== null && dayHours > 0 && dayHours === daySoHours;
	const colorRed = soRecord && dayHours !== null && dayHours > 0 && dayHours > daySoHours;
	const colorYellow = soRecord && dayHours !== null && dayHours > 0 && dayHours < daySoHours;
	const colorPurple = dayChr && dayMethodHours === 0;

	return useMemo(() => ({
		[classes.red]: colorRed,
		[classes.yellow]: colorYellow,
		[classes.green]: colorGreen,
		[classes.purple]: colorPurple,
	}), [colorPurple, colorGreen, colorRed, colorYellow, classes]);
};

export const useHourCellColoringStyles = makeStyles()(theme => {
	const grey = '#666666';
	const purple = '#800080';
	const green = '#00ff00';
	const red = '#ff0000';
	const yellow = '#ffff00';
	const pastelGreen = '#77dd77';
	const pastelRed = '#ff6961';
	const pastelYellow = '#fdfd96';

	return {
		grey: {
			background: grey,
			color: theme.palette.getContrastText(grey),
		},
		purple: {
			background: purple,
			color: theme.palette.getContrastText(purple),
		},
		green: {
			background: green,
			color: theme.palette.getContrastText(green),
		},
		red: {
			background: red,
			color: theme.palette.getContrastText(red),
		},
		yellow: {
			background: yellow,
			color: theme.palette.getContrastText(yellow),
		},
		pastelGreen: {
			background: pastelGreen,
			color: theme.palette.getContrastText(pastelGreen),
		},
		pastelRed: {
			background: pastelRed,
			color: theme.palette.getContrastText(pastelRed),
		},
		pastelYellow: {
			background: pastelYellow,
			color: theme.palette.getContrastText(pastelYellow),
		}
	};
});
