
import { memo } from 'react';
import { Typography } from '@mui/material';
import { ClientForm as TClientForm } from '@imas/api/client';
import { GetPaymentTerms } from '@imas/api/system';
import { useLoadApi } from '@imas/api';
import { FormNumberSelect, FormCheckbox, useMuiFormControl, useMuiWatch } from '@imas/mui-form';
import { Alignment } from '@imas/components/layout';
import { useSelector } from 'react-redux';
import { getUserRoles } from '@imas/redux';

export const ClientPaymentInfo = memo(() => {
	const control = useMuiFormControl<TClientForm>();

	//value of creditApproved field
	const [creditApproved] = useMuiWatch({ control, name: ["creditApproved"] });
	
	//get user's roles
    const roles = useSelector(getUserRoles);
	
	//get list of payment terms
	const { data: paymentTerms } = useLoadApi(GetPaymentTerms, [], []);

	return (
		<Alignment column flex>
			<Typography variant={"h6"} sx={{ marginBottom: '5px', fontWeight: 'bold' }}>{"Payment Info"}</Typography>

			{/* Credit Approved Field (Admin Only) */}
			<FormCheckbox
				disabled={!roles.admin}
				name={"creditApproved"}
				control={control}
				label={"Credit Approved"}
				sx={{ margin: '0 0 10px 0'}}
			/> 

			{/* Payment Term Field (Admin Only) */}
			<FormNumberSelect
				disabled={!roles.admin || !creditApproved}
				name={"paymentTerms"}
				control={control}
				label={"Payment Term"}
				loading={!paymentTerms}
				options={(option) => (paymentTerms ?? []).map(term => option(term.id, term.name))}
				sx={{ maxWidth: '200px', marginBottom: '10px' }}
			/>

			{/* Bad Credit History Field (Admin Only) */}
			<FormCheckbox
				disabled={!roles.admin}
				name={"badCredit"}
				control={control}
				label={"Bad Credit History"}
				sx={{ margin: '0 0 10px 0'}}
			/>
		</Alignment>
	);
});