import { isApiError, useApi } from '@imas/api';
import { MuiFormSubmit, useMuiFormRef } from '@imas/mui-form';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { useCallback, useState } from 'react';
import { WebLoginForm, TWebLoginForm } from './WebLoginForm';
import { AuthenticateUser } from '@imas/api/auth';
import { UserData } from '@imas/api/auth/types';
import { isGeolocationPermissionError } from '@imas/utils/geolocation';
import { setUserData } from '@imas/redux';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

interface WebLoginProps {};

export const WebLogin = ({}: WebLoginProps) => {
    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //redux dispatch
    const dispatch = useDispatch();

    //use APIs
    const makeLoginAttempt = useApi(AuthenticateUser);

    //login error message
    const [loginErrorMessage, setLoginErrorMessage] = useState<string | undefined>();

    //useFormRef for scope template
    const [formRef, onFormRef] = useMuiFormRef<TWebLoginForm, UserData>();

    //function for logging user in
    const submitLogin = useCallback(async (data: TWebLoginForm) => {
        //show logging in notification
        const close = showSnackbar("Logging in...", { variant: "info", persist: true });

        try {
            //attempt to login the user with the credentials provided
            const result = await makeLoginAttempt({
                ...data,
            });

            //close progress
            close();
            return result;
        } catch(e) {
            throw close(e);
        }
    }, [makeLoginAttempt, showSnackbar]);
    
    return (
        <>
            {/* Login Form */}
            <WebLoginForm ref={onFormRef} triggerSubmit={() => formRef?.submit(submitLogin).then((result) => dispatch(setUserData(result)))}/>
        
            {/* Login Button */}
            <MuiFormSubmit
            sx={{marginBottom: '10px'}}
                formRef={formRef}
                onSubmit={async (data) => {
                    return await submitLogin(data);
                }}
                onSuccessfulSubmit={(result) => {
                    console.log('Successful Submit');
                    dispatch(setUserData(result));
                }}
                onError={(error) => {
                    //if the error is a GeolocationPositionError
                    if (isGeolocationPermissionError(error))  {
                        //get error message based on error code
                        let message = "Unknown";
                        if (error.code === error.PERMISSION_DENIED) message = "Location Permission is Denied.";
                        else if (error.code === error.POSITION_UNAVAILABLE) message = "Location service is not currently enabled or does not exist for this browser.";
                        else if (error.code === error.TIMEOUT) message = "Timeout before location could be returned.";
                        message = `Location is required for login. Error: ${message}`;

                        //give error to the user
                        showSnackbar(message, { variant: "error" });

                        //set login error message
                        setLoginErrorMessage("Failed to get location.");
                    }

                    if (isApiError(error)) {
						console.log(error);
                        //set the login error message
                        setLoginErrorMessage(error.message);
                    }
                }}
                tabIndex={-1}
                disablePreventNavigation
            >{"Login"}</MuiFormSubmit>

			{/* Show login error message if one was provided. */}
			{loginErrorMessage ? <Typography variant={'caption'} sx={{ color: 'error.main', marginTop: '10px' }}>{loginErrorMessage}</Typography> : null}
        </>
    );
};