import { LabeledItem, Spacer, Alignment } from '@imas/components/layout';
import { Paper, Typography } from '@mui/material';
import moment from 'moment';
import { _SOTrackingResult } from 'src/api/types/api/service-orders/service-order/ServiceOrderTrackingResult';
import { SOSignatureDetail, ServiceOrderSignatureRequest } from 'src/api/types/api/service-orders/types';
import MobileClientSignatureStyles from './MobileClientSignatureStyles';

export interface SuccessfullySignedProps {
	signatureRequest: ServiceOrderSignatureRequest,
	//serviceOrder: _SOTrackingResult,
	signatureDetail: SOSignatureDetail,
}

export const SuccessfullySigned = (({signatureRequest, signatureDetail} : SuccessfullySignedProps) => {

	//component styling
	const { classes, deviceInfo } = MobileClientSignatureStyles();

	//get JobNo from FullJobName
	const getJobNo = (val: string) => val.split(" ")[0]; 

	return (
		<Alignment column sx={{alignItems: 'center'}}>
			<Paper sx={{margin: '10px', padding: '10px', backgroundColor: '#90ee9059'}}>
				<Typography fontWeight={'bold'} variant={'h6'}>{'The work order has been signed.'}</Typography>
			</Paper>

			<Typography className={classes.darkModeHelp} fontWeight={'bold'} variant={'h5'}>{signatureDetail.client}</Typography>

			{/* Full Job name  */}
			<Typography className={classes.darkModeHelp} variant={'h6'}  >{signatureDetail.proName}</Typography>
			<Spacer vertical/>

			
			<LabeledItem label={'Job No.'} item={getJobNo(signatureDetail.jobNo)} labelProps={{textAlign: 'center'}} itemTextProps={{textAlign: 'center'}} />
			<Spacer vertical/>
			<LabeledItem label={'Service Order'} item={signatureDetail.serviceOrderId} labelProps={{textAlign: 'center'}} itemTextProps={{textAlign: 'center'}} />
			<Spacer vertical/>
			<LabeledItem label={'Signed On'} item={moment(signatureRequest.signedDateTime).format('MMMM Do, YYYY')} labelProps={{textAlign: 'center'}} itemTextProps={{textAlign: 'center'}} />
			<Spacer vertical/>

			{signatureRequest.sendCopy ? 
				<Typography className={classes.darkModeHelp} variant={'body1'}>{`A copy has been sent to ${signatureRequest.email}`}</Typography>
			: null}

		</Alignment>
	);
});