import React, { useMemo } from 'react';
import * as MuiGrid from "@mui/x-data-grid-pro";
import { getFixedGridBooleanOperators } from '../utils/getFixedGridBooleanOperators';
import { getFixedGridSingleSelectOperators } from '../utils/getFixedGridSingleSelectOperators';
import { ALL_COL_TYPES } from '../column-types/ALL_COL_TYPES';
import { SelectProps } from '@mui/material';

//patchedRenderBooleanCell
const patchedRenderBooleanCell = (params: MuiGrid.GridRenderCellParams) => {
	if (params.value) {
		return (MuiGrid.GRID_BOOLEAN_COL_DEF.renderCell as (params: MuiGrid.GridRenderCellParams) => React.ReactNode)(params);
	} else {
		return <></>;
	}
};

//patches the cell renderer if needed
const getPatchedRenderer = (colTypes: MuiGrid.GridColumnTypesRecord | undefined, type?: string): MuiGrid.GridColDef["renderCell"] | undefined => {
    //if the colType specified has a custom filter then do not apply patch
    if (type && colTypes && type in colTypes) {        
        if (colTypes[type].renderCell !== undefined) return colTypes[type].renderCell;
    }

    switch (type) {
        case 'boolean':
			//this patch will make it where the filter value for boolean columns expects a actual boolean (desired behavior for TypedDataGrid)
            return patchedRenderBooleanCell;
    };

	return undefined;
};


//patches any filter opterators if needed
const patchedOperators = (colTypes: MuiGrid.GridColumnTypesRecord | undefined, type?: string): MuiGrid.GridFilterOperator[] | undefined => {
    //if the colType specified has a custom filter then do not apply patch
    if (type && colTypes && type in colTypes) {        
        if (colTypes[type].filterOperators !== undefined) return colTypes[type].filterOperators;
    }

    switch (type) {
        case "boolean":
			//this patch will make it where the filter value for boolean columns expects a actual boolean (desired behavior for TypedDataGrid)
            return getFixedGridBooleanOperators();
		case "singleSelect": 
			//this patch fixes a bug for singleSelect columns where if any of the values of the column are null the grid crashes when a filter is applied
			return getFixedGridSingleSelectOperators();
    };

	return undefined;
};

/** A Datagird with custom patches applied to make it work as desired. */
export function PatchedDataGrid (props: MuiGrid.DataGridProProps) {
    const { columns, apiRef, ...gridProps } = props;

	//add in custom column types
	const columnTypes = useMemo((): MuiGrid.GridColumnTypesRecord => ({ ...(ALL_COL_TYPES as any) }), []);

    //patch columns as needed
    const patchedColumns = useMemo(() => {
		return columns.map(col => {
			const patchedRenderer = col.renderCell ?? getPatchedRenderer(columnTypes, col.type);
			const patchedFilterOperators = col.filterOperators ?? patchedOperators(columnTypes, col.type);

			return ({
				...col,
				...(patchedRenderer ? { renderCell: patchedRenderer } : undefined),
				...(patchedFilterOperators ? { filterOperators: patchedFilterOperators } : undefined)
			});
		});
    }, [columns, columnTypes]);

    return <MuiGrid.DataGridPro 
        apiRef={apiRef} 
        columns={patchedColumns}
        {...gridProps}
		componentsProps={{
			...gridProps.componentsProps,
			baseButton: {
				variant: 'text',
				...gridProps.componentsProps?.baseButton,
			},
		}}
    />;
};