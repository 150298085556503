import { HubContext } from './HubContext';
import { useContext, useMemo, useEffect } from 'react';
import { HubRegistration, SingalRHubMap } from "./types";

export interface useHubOptions {
    /** If the hook should automatically initiate the hub registration's connection on mount by caling (registration.start()). Defaults to true. */
    autoConnect?: boolean;
};

export function useHub<T extends keyof SingalRHubMap>(endpoint: T, options?: useHubOptions): HubRegistration<SingalRHubMap[T]> {
    //use hub context
    const { register, unregister } = useContext(HubContext);
    
    //register the connection
    const registration = useMemo(() => {
        return register(`/hubs${endpoint}`);
    }, [endpoint]);

    //start the connection, and unregistr the registration when this hook is unmounted
    useEffect(() => {
		try {
        	//automatically start the connection (unless autoConnect is false)
        	if (options?.autoConnect ?? true) registration.start().then().catch(() => {});
		} finally {} 

        //unregister on unmount
        return () => {
            unregister(registration);
        };
    }, [registration]);
    
    return registration as HubRegistration<SingalRHubMap[T]>;
};
