import { forwardRef, ReactNode, CSSProperties, DOMAttributes } from 'react';
import { makeStyles } from '@imas/styles';
import clsx from 'clsx';
import { Theme, Box, BoxProps } from '@mui/material';
import { SxProps } from '@mui/system';

const useStyles = makeStyles()(() => ({
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	rowReverse: {
		display: 'flex',
		flexDirection: 'row-reverse'
	},
	column: {
		display: 'flex',
		flexDirection: 'column'
	},
	columnReverse: {
		display: 'flex',
		flexDirection: 'column-reverse'
	},
	flex: {
		flex: '1'
	},
	hidden: {
		display: 'none',
	},
	overflowHidden: {
		overflow: 'hidden'
	},
	overflowScroll: {
		overflow: 'scroll',
	},
}));

export interface AlignmentProps extends DOMAttributes<HTMLDivElement>, Pick<BoxProps, 'width' | 'height'> {
	row?: boolean;
	rowReverse?: boolean;
	column?: boolean;
	columnReverse?: boolean;
	flex?: boolean;
	/** When true the underlying div element is set to display: none */
	hidden?: boolean; 
	overflowHidden?: boolean;
	overflowScroll?: boolean;
	children?: ReactNode;
	className?: string | null;
	style?: CSSProperties;
	sx?: SxProps<Theme>;
};

/**
 * Component used to align children in a flexbox row or column. With some other common patterns added as a prop.
 */
const Alignment = forwardRef<HTMLDivElement, AlignmentProps>((props, ref): JSX.Element => {
	//props
	const { row, rowReverse, column, columnReverse, flex, hidden, overflowHidden, overflowScroll, children, className, ...divProps } = props;

	//component styling
	const { classes } = useStyles();

	return <Box 
		ref={ref} 
		className={clsx(className, {
			[classes.row]: row && !hidden,
			[classes.rowReverse]: rowReverse && !hidden,
			[classes.column]: column && !hidden,
			[classes.columnReverse]: columnReverse && !hidden,
			[classes.flex]: flex,
			[classes.overflowHidden]: overflowHidden,
			[classes.overflowScroll]: overflowScroll,
			[classes.hidden]: hidden
		})}
		{...divProps}
	>{children}</Box>;
});

export { Alignment };