import { FolderInfoItem } from '@imas/api/files';
import { Dialog, DialogProps } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import React, { useCallback } from "react";
import { FileExplorer, FileExplorerProps, FileExplorerAction } from '../files';
import { useDialog } from "./useDialog";

//partial dialog props
type PartialDialogProps = Omit<DialogProps, "open" | "onClose">;

//file explorer dialog props
export interface FileExplorerDialogProps extends Pick<FileExplorerProps, "table" | "folder" | "filter" | "maxLevelsUp" | "maxLevelsDown" | "actions" | "allowUpload" | "sx"> {};

//maps mode to return type of dialog function
interface FileExplorerModesMap {
	"pick-folder": FolderInfoItem;
	"pick-file": FolderInfoItem;
	"pick-files": FolderInfoItem[];
};

export type ShowFileExplorerFn = <T extends keyof FileExplorerModesMap>(mode: T, explorerProps: FileExplorerDialogProps, dialogProps?: PartialDialogProps) => Promise<FileExplorerModesMap[T] | null>;

export function useFileExplorerDialog(): [ShowFileExplorerFn] {
	//useDialog hook
	const [openDialog] = useDialog();

	//open function
	const open = useCallback<ShowFileExplorerFn>(<T extends keyof FileExplorerModesMap>(mode: T, explorerProps: FileExplorerDialogProps, dialogProps?: PartialDialogProps):  Promise<FileExplorerModesMap[T] | null> => {
		return openDialog<FileExplorerModesMap[T] | null>((close) => {
			//return action/actions based on mode
			const getExplorerActions = (): FileExplorerAction[] => {
				if (mode === "pick-folder") {
					return [
						{ 
							type: "select", 
							text: "Link Folder", 
							startIcon: <LinkIcon/>, 
							onClick: (selected) => { if(selected[0] !== undefined) close(selected[0] as any); },
							disabled: (selected) => selected.length !== 1,
						},
					];
				} else if (mode === "pick-file") {
					return [
						{ 
							type: "select", 
							text: "Link File", 
							startIcon: <LinkIcon/>, 
							onClick: (selected) => { if(selected[0] !== undefined) close(selected[0] as any); },
							disabled: (selected) => selected.length !== 1,
						},
					];
				} else if (mode === "pick-files") {
					return [
						{ 
							type: "select", 
							text: (selected) => selected.length > 0 ? `Link ${selected.length} file${selected.length > 1 ? "s" : ""}` : "Link", 
							startIcon: <LinkIcon/>, 
							onClick: (selected) => { if(selected[0] !== undefined) close(selected.filter(x => !x.isDir)  as any); },
							disabled: (selected) => selected.length < 1,
						},
					];
				}

				return [];
			};
			
			return (
				<Dialog maxWidth={"lg"} fullWidth {...dialogProps} open onClose={() => close(null)} sx={{ minHeight: '60vh', ...dialogProps?.sx }}>
					<FileExplorer
						{...explorerProps}
						mode={mode}
						actions={[
							...(explorerProps.actions ? explorerProps.actions : getExplorerActions()),
							{ type: "generic", text: "Cancel", color: "secondary", onClick: () => close(null) },
						]}
						sx={{ ...explorerProps?.sx, padding: '10px', minHeight: '60vh' }}
					/>
				</Dialog>
			);
		});
	}, [openDialog]);
	
	return [open];
};