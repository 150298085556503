import moment from 'moment';

export * from './pages';

//VERSION #
const VERSION: string = '0.14.1213';

//Google Maps API Key
const GOOGLE_MAPS_API_KEY: string = 'AIzaSyCZK37W936HQXLbB2uk74637yHJcGJpjTs';

//time sheet changeover cutoff date
const TIME_SHEET_CHANGEOVER_CUTOFF_DATE = moment('2023-09-30', 'YYYY-MM-DD');

export { VERSION, GOOGLE_MAPS_API_KEY, TIME_SHEET_CHANGEOVER_CUTOFF_DATE };

export { fileNameAllowedCharacterSet, fileNameDisallowedCharactersMatch } from './regex';

export * from './records';