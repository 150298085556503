import { axiosData } from '@imas/api';
import { GetProposalServiceResult, ProposalService, vProposalService, vProposalServiceFee } from '@imas/api/proposal';
import axios from 'axios';
import { SourceGetter } from '../types/api/api';
import ProposalDetail from '../types/api/proposals/ProposalDetail';
import ServiceOrderDetail from '../types/api/service-orders/ServiceOrderDetail';
import vServiceOrderDetail from '../types/api/service-orders/vServiceOrderDetails';
import vServiceOrderHour from '../types/api/service-orders/vServiceOrderHour';
import AddUpdateSOHourRequest from './ChargeTypes/AddUpdateSOHourRequest';
import UpdateChargeRequest from './ChargeTypes/UpdateChargeRequest';
import UpdateSOHourRequest from './ChargeTypes/UpdateSOHourRequest';
import CreateChargeRequest from './ChargeTypes/CreateChargeRequest';

/**
 * Get a list of service order charges given a uniqueId.
 */
 export const _GetCharges = (getSource: SourceGetter) => {
    return  (uniqueId: int): Promise<ServiceOrderDetail[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charges/getcharges/' + uniqueId.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

/**
 * Get a list of vserviceorderdetails given a uniqueId.
 */
 export const _GetvCharges = (getSource: SourceGetter) => {
    return  (uniqueId: int): Promise<vServiceOrderDetail[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charges/getvcharges/' + uniqueId.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

/**
 * Get a charge given a chargeid
 */
export const _GetCharge = (getSource: SourceGetter) => {
    return  (chargeid: int): Promise<vServiceOrderDetail> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderDetail>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charges/getvcharge/' + chargeid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// create a new charge given a uniqueid and serviceid
export const _CreateCharge = (getSource: SourceGetter) => {
    return  (uniqueId: int, serviceId:int, request:CreateChargeRequest): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/charges/createcharge/' + uniqueId.toString() + '/' + serviceId.toString(),
				data: request,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// create a new charge given a uniqueid and a proposalservice, used in the proposalserviceselector
export const _CreateSOCharges = (getSource: SourceGetter) => {
    return  (uniqueId: int, services: GetProposalServiceResult[]): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/charges/create-charges/' + uniqueId.toString(),
				data: services,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// create a new charge given a uniqueid and proposal detail
export const _CreateChargeFromProposal = (getSource: SourceGetter) => {
    return  (uniqueId: int, sortOrder:int, detail:ProposalDetail): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/charges/create-charge-from-proposal/' + uniqueId.toString() + '/' + sortOrder.toString(),
				data: detail,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// create a new field charge given a uniqueid
export const _CreateFieldCharge = (getSource: SourceGetter) => {
    return  (uniqueId: int, request:CreateChargeRequest): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/charges/createfieldcharge/' + uniqueId.toString(),
				data: request,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Get a list of proposal details given a pronum
export const _GetProposalDetails = (getSource: SourceGetter) => {
    return  (pronum: int): Promise<ProposalDetail[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ProposalDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charges/getproposaldetails/' + pronum.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

export const _GetChargeProposalServices = (getSource: SourceGetter) => {
    return async (): Promise<vProposalService[]> => {
        return await axiosData({
            method: 'get',
            url: '/api/service-orders/charges/getproposalservices',
            cancelToken: getSource().token,
        });
    };
};

export const _GetChargeServiceUnits = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return await axiosData({
            method: 'get',
            url: '/api/service-orders/charges/units',
            cancelToken: getSource().token,
        });
    };
};

export const _GetChargeServiceSubcategories = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return await axiosData({
            method: 'get',
            url: '/api/service-orders/charges/subcategories',
            cancelToken: getSource().token,
        });
    };
};

export const _GetServiceOrderLabnumbers = (getSource: SourceGetter) => {
    return async (uniqueid: int): Promise<string[]> => {
        return await axiosData({
            method: 'get',
            url: '/api/service-orders/charges/getlabnumbers/' + uniqueid.toString(),
            cancelToken: getSource().token,
        });
    };
};

export const GetvProposalServiceFeesFromSODetail = (getSource: SourceGetter) => {
    return async (chargeid: int): Promise<vProposalServiceFee[]> => {
        return await axiosData({
            method: 'get',
            url: '/api/service-orders/charges/v-proposal-service-fees/' + chargeid.toString(),
            cancelToken: getSource().token,
        });
    };
};

// delete a charge given a chargeid
export const _DeleteCharge = (getSource: SourceGetter) => {
    return  (chargeid: int): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'delete',
                url: '/api/service-orders/charges/deletecharge/' + chargeid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// update an existing charge given an array of updateChargeRequests
export const _UpdateCharges = (getSource: SourceGetter) => {
    return  (requests: UpdateChargeRequest[]): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/charges/updatecharges',
                cancelToken: source.token,
				data: requests
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// update an existing charge given an array of updateChargeRequests
export const _UpdateCharge = (getSource: SourceGetter) => {
    return  (request: UpdateChargeRequest): Promise<vServiceOrderDetail> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderDetail>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/charges/updatecharge',
                cancelToken: source.token,
				data: request
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Get a list of SO hours given a uniqueID
export const _GetSOHours = (getSource: SourceGetter) => {
    return  (uniqueid: int): Promise<vServiceOrderHour[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderHour[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charges/hours/' + uniqueid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Get a list of so hours given a chargeid
export const _GetHours = (getSource: SourceGetter) => {
    return  (chargeid: int): Promise<vServiceOrderHour[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderHour[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charges/getserviceorderhours/' + chargeid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Create a new SO hour given a chargeid and an employeeid
export const _CreateHour = (getSource: SourceGetter) => {
    return  (chargeid: int, employeeid: int): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/charges/createsohour/' + chargeid.toString() + '/' + employeeid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Create a new SO hour given a chargeid and an employeeid
export const _AddUpdateHours = (getSource: SourceGetter) => {
    return  (chargeid: int, requests: AddUpdateSOHourRequest[]): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/charges/addupdatesohours/' + chargeid.toString(),
				data: requests,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Delete an SOHour given its ID
export const _DeleteHour = (getSource: SourceGetter) => {
    return  (soHoursId: int): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'delete',
                url: '/api/service-orders/charges/deletesohour/' + soHoursId.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Update an so hour given an updatesohourrequest
export const _UpdateHour = (getSource: SourceGetter) => {
    return  (request: UpdateSOHourRequest): Promise<vServiceOrderHour> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderHour>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/charges/updatesohour',
				data: request,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};