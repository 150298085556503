import React, { forwardRef } from 'react';
import { useMergeRefs } from 'use-callback-ref';
import { FieldValues, FieldPath } from 'react-hook-form';
import { NumberTextField, NumberTextFieldProps } from '@imas/components/inputs';
import { FormInputProps, OnBlur } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';


export interface FormNumberTextFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<number | null, TFieldValues, TName>, 
    Omit<NumberTextFieldProps, "name" | "defaultValue" | "error" | "helperText" | "value" | "onChange" | "onBlur" | "ref" | "onValueChange"> 
{
};

const _FormNumberTextField = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
        ...numberTextFieldProps
    }: FormNumberTextFieldProps<TFieldValues, TName>,
	extRef: React.ForwardedRef<HTMLInputElement>
) => {
    //use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
		context: { size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

	//merge refs
	const mergeRef = useMergeRefs([ref, extRef]);

    //render loading
    if (isLoading) return <FormFieldSkeleton {...numberTextFieldProps} size={numberTextFieldProps.size ?? size}/>;

    //render NumberTextField 
    return (
        <NumberTextField
            {...numberTextFieldProps}
			size={numberTextFieldProps.size ?? size}
            disabled={isViewOnly || numberTextFieldProps.disabled}
            name={name}
            required={isRequired}
            error={hasError}
            helperText={helperText}
            value={value}
            onValueChange={values => onChange(values.floatValue ?? null)}
            onBlur={handleBlur}
            ref={mergeRef}
        />
    );
};

const FormNumberTextField = forwardRef(_FormNumberTextField);

export { FormNumberTextField };