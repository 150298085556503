import { Button, ButtonProps } from '@mui/material';
import { forwardRef, useCallback, useRef } from 'react';
import { fileListToArray } from '@imas/utils/files';

type FileUploaderImportProp = { multiple: true; onUpload: (files: File[]) => Promise<void> | void; } | { multiple?: false; onUpload: (file: File) => Promise<void> | void; };

export interface FileUploaderBaseProps extends Omit<ButtonProps, "onClick"> {
    fileTypeWhitelist?: string[];
    capture?: boolean | "environment" | "user";
};

export type FileUploaderProps = FileUploaderBaseProps & FileUploaderImportProp;

export interface FileUploaderRef {
    upload: (files: FileList | File[]) => void,
};

export const FileUploader = forwardRef<HTMLButtonElement, FileUploaderProps>((props, ref) => {
    //component props
    const { fileTypeWhitelist: fileTypeWhitelistProp, capture, multiple, onUpload, children, ...buttonProps } = props;
    const fileTypeWhitelist = fileTypeWhitelistProp ?? [];

	//input ref
	const inputRef = useRef<HTMLInputElement>(null);

	//upload handler which calls the onUpload callback
    const fileUploadHandler = useCallback((fileList: FileList | File[]): void => {
		const files = fileList instanceof FileList ? fileListToArray(fileList) : fileList;

        if (multiple) onUpload(files as any);
		else {
			const file = files[0];
			if (file !== undefined) onUpload(file as any);
		}
    }, [multiple, onUpload]);

    // //ref handle
    // useImperativeHandle(ref, () => ({
    //     upload: fileUploadHandler,
    // }), [fileUploadHandler]);

    //Rendered Button
    return (
        <Button
			{...buttonProps}
			onClick={() => {
				if (inputRef.current === null) return;
				inputRef.current.click();
			}}
			ref={ref}
        >
            {children}
            <input
                type={"file"}
                hidden
                disabled={buttonProps?.disabled}
                accept={fileTypeWhitelist.length === 0 ? undefined : fileTypeWhitelist.join(",")}
                onChange={(event) => {
                    //ensure files is not null
                    if (event.target.files === null) return;
                    
                    //handle uploading the files
                    fileUploadHandler(event.target.files);
                }}
                multiple={multiple}
                capture={capture}
				ref={inputRef}
            />
        </Button>
    );
});