import { axiosData, SourceGetter } from "@imas/api";
import { PaymentTerm } from './types';

//payment terms base url
const PAYMENT_TERMS_URL = "/api/system/payment-terms";

/*  */
export const GetPaymentTerms = (getSource: SourceGetter) => {
    return async (): Promise<PaymentTerm[]> => {
        return await axiosData({
            method: 'get',
            url: `${PAYMENT_TERMS_URL}`,
            cancelToken: getSource().token,
        });
    };
};