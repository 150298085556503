import { getEmployeeData } from '@imas/redux';
import { useSelector } from 'react-redux';

//get employee data for the currently logged in user
export const useUser = () => {
	const user = useSelector(getEmployeeData);

	//ensure data exits, this hook can only be used in a context where the user has been authenticated so if they are not an error should be thrown
	if (!user) throw Error("User has not been authenticated.");

	return user;
};

//get current users employeeId
export const useUserId = () => {
	return useUser().employeeId;
};