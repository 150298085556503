import { VersionCode } from 'src/api/types/api/misc/misc';

//generic template interface for a ServiceWorkMessage
export interface ServiceWorkerMessage<T, D> {
    type: T,
    data: D
}

//valid types of service worker message
export type ServiceWorkerMessageTypes = ServiceWorkerMessage<"install-update", null> | ServiceWorkerMessage<"reload-page", null>;

//enum for defined periodic sync task tags
export enum PeriodicSyncTasks {
    CheckCheckedIn = "check-checked-in",
}

//enum for defined notification tags
export enum NotificationType {
    TestNotification = "test-notification",
    NewVersion = "new-version",
    CheckInReminder = "check-in-reminder",
    Timesheet = "Timesheet",
}

//enum for types of WebPush messages
export enum WebPushMessageType {
    TestNotification = "TestNotification",
    NewVersion = "NewVersion",
    Announcement = "Announcement",
    CheckInReminder = "CheckInReminder",
    Schedule = "Schedule",
    OnCall = "OnCall",
    Job = "Job",
    Timesheet = "Timesheet",
    Custom = "Custom",
}

//payload types based on the message type
interface WebPushPayloadTypeMap {
    "TestNotification": undefined;
    "NewVersion": VersionCode;
    "Announcement": { title: string, body: string | null, tag: string, renotify: boolean, silent: boolean, requireInteraction: boolean };
    "CheckInReminder": { hours: int };
    "Schedule": WebPushPayload;
    "OnCall": WebPushPayload;
    "Job": WebPushPayload;
    "Timesheet": WebPushPayload;
    "Custom": WebPushPayload;
}

//structure for a WebPushMessage
export interface WebPushMessage<T extends WebPushMessageType> {
    type: WebPushMessageType;
    payload: WebPushPayloadTypeMap[T];
    messageId: int;
    dev: boolean;
}

//general webpushpayload
export interface WebPushPayload{
    title: string,
    body: string,
    renotify: boolean,
    silent: boolean,
    requireInteraction: boolean
}

declare global {
    //Periodic Sync type additions
    interface PeriodicSyncEvent extends ExtendableEvent {
        readonly tag: string;
    }

    interface WorkerGlobalScopeEventMap {
        "periodicsync": PeriodicSyncEvent;
    }

    interface BackgroundSyncOptions {
        minInterval: number;
    }

    interface PeriodicSyncManager {
        getTags(): Promise<string[]>;
        register(tag: string, options: BackgroundSyncOptions): Promise<void>;
        unregister(tag: string): Promise<void>;
    }

    interface ServiceWorkerRegistration {
        periodicSync: PeriodicSyncManager;
    }

    //custom ServiceWorkerEvent ServiceWorkerEventTarget for a serviceWorkers "statechange" event
    interface ServiceWorkerEventTarget extends EventTarget {
        readonly state: ServiceWorkerState;
    }

    interface ServiceWorkerEvent extends Event {
        target: ServiceWorkerEventTarget | null
    }
};