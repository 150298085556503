import React, { useEffect } from 'react';
import loginStyles from './LoginStyles';
import { GetAuthentication } from '@imas/api/auth';
import { Paper, Box, Typography } from '@mui/material';
import { getEmployeeData, setUserData } from '@imas/redux';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from '@imas/api';
import { Alignment } from '@imas/components/layout';
import { useNavigate } from 'react-router';
import { useAutomaticSnackbar, snackbar } from '@imas/utils/snackbar';
import { WebLogin } from './WebLogin/WebLogin';
import { onLogin } from 'src/startup';
import { ForgotLogin } from './ForgotLogin/ForgotLogin';

/** Login Page */
const Login = React.memo(() => {
	//page title
    document.title = "IMAS";

	//component styling
	const { classes, deviceInfo } = loginStyles();

	//redux dispatch
	const dispatch = useDispatch();

	//using navigate
	const navigate = useNavigate();

	//using automatic snackbar
	const showSnackbar = useAutomaticSnackbar();

	//use APIs
	const getLoginStatus = useApi(GetAuthentication, { disableErrorMessages: true });

	//check if we are in a non-production environment
	const isDev = window.location.hostname === "pmsdev.pnltest.net" || window.location.hostname === "imasdev.pnltest.net" || window.location.hostname === "localhost";

	//data for the logged in employee
	const employeeData = useSelector(getEmployeeData);

	//attempt automatic login
	useEffect(() => {
		//don't attempt login if location permission is not given
		if (!("geolocation" in navigator)) return showSnackbar("This device does not have the location capabilities required for login.", { variant: "error" });

		//get user location
		navigator.geolocation.getCurrentPosition(position => {
			//make request
			getLoginStatus(!deviceInfo.isMd ? { 
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
				accuracy: position.coords.accuracy
			} : undefined)
			.then((data) => {
				//call onLogin
				onLogin(data);

				//update store with user data
				dispatch(setUserData(data));
			})
			.catch(() => {});
		}, () => {
			//give error to the user
			showSnackbar("Unable to get your location, location is required for login.", { variant: "error" }, false);
		}, { enableHighAccuracy: true, maximumAge: 0 });
	}, [deviceInfo.isMd, dispatch, getLoginStatus, showSnackbar]);

	//handle accidentally navigating to login page
	useEffect(() => { if (employeeData !== null) navigate("/dashboard"); }, [employeeData, navigate]);

	//handle showing in dev message if the user is in the dev
	useEffect(() => { if (isDev) { snackbar.close("dev-reminder"); showSnackbar("This is the development version of IMAS.", { key: "dev-reminder", variant: "info" }, false); } }, [isDev, showSnackbar]);

	return (
		<Alignment column flex> 
			<Alignment 
				column 
				flex 
				sx={{
					margin: ['2vh 8%', '5vh 8%', '10vh 8%'],
					width: '-webkit-fill-available',
					maxWidth: '400px',
					alignSelf: 'center',
				}}
			>
				{/*  PNL Image */}
				<Box component={"img"} sx={{ width: '100%', objectFit: 'contain' }} src={"/images/logo.png"}/>

				{/* Login Container */}
				<Paper className={classes.loginContainer} sx={{ padding: '25px', marginTop: '20px' }}>
					{/* If this is a development environment then show a message here. */}
					{isDev ? <Typography variant={"h6"} sx={{ margin: 'auto', padding: '10px' }}>{"Development Environment"}</Typography> : null}

					{/* Site Selector */}
					

					{/* Username/Password Authentication Form */}
					<WebLogin/>

					{/* Microsoft Authentication */}
					{/**<MicrosoftLogin sx={{ marginTop: '20px' }}/> **/}

					{/* Forgot Username/Password pages and forms */}
					<ForgotLogin/>
					

				</Paper>

			</Alignment>
		</Alignment>
	);
});

export default Login;