import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@imas/redux';

//DeviceInfo interface
interface DeviceInfo {
    isXs: boolean,
    isSm: boolean,
    isMd: boolean,
    isLg: boolean,
    isXl: boolean,
    isTall: boolean
};

//initial state type
interface DeviceInfoState {
    value: DeviceInfo,
    status: string
};

/**
 * Slice for storing information related to authentication such as the loggedIn status of the employee or their data. 
 */
const initialState: DeviceInfoState = {
    value: {
        isXs: true,
        isSm: true,
        isMd: false,
        isLg: false,
        isXl: false,
        isTall: false
    },
    status: 'idle'
};

export const deviceInfoSlice = createSlice({
    name: 'deviceInfo',
    initialState,
    reducers: {
        setDeviceInfo: (state, action: PayloadAction<DeviceInfo>) => {
            state.value = action.payload;
        }
    }
});

export const { setDeviceInfo } = deviceInfoSlice.actions;

//selector functions
export const getDeviceInfo = (state: RootState) => state.deviceInfo.value;

const deviceInfoReducer = deviceInfoSlice.reducer;
export { deviceInfoReducer };