import { mapApi, ApiConfig, axiosData, SourceGetter, objectToFormData } from '@imas/api';
import axios from 'axios';
import { ClientPONumbersResult, ClientPurchaseOrder, ClientPurchaseOrderRevision, PoAmountResult, PoReference, TClientPurchaseOrderForm } from './types';

//client purchase orders base url
const CLIENT_PURCHASE_ORDERS_URL = "/api/clients/purchase-orders";

/* Get all purchase orders belonging to a client */
export const GetAllClientPurchaseOrders = mapApi((): ApiConfig<ClientPurchaseOrder[]> => ({
	method: 'get',
	url: `${CLIENT_PURCHASE_ORDERS_URL}`,
}));

/* Get a specific purchase order */
export const GetClientPurchaseOrder = mapApi((purchaseOrderId: int): ApiConfig<ClientPurchaseOrder> => ({
	method: 'get',
	url: `${CLIENT_PURCHASE_ORDERS_URL}/${purchaseOrderId}`,
}));

/* Get revisions for a purchase order */
export const GetClientPurchaseOrderRevisions = (getSource: SourceGetter) => {
    return async (purchaseOrderId: int): Promise<ClientPurchaseOrderRevision[]> => {
        return axiosData({
			method: 'get',
            url: `${CLIENT_PURCHASE_ORDERS_URL}/${purchaseOrderId}/revisions`,
            cancelToken: getSource().token,
		});
    };
};

/* Get client PO numbers for the client PO selector */
export const GetClientPONumbers = (getSource: SourceGetter) => {
    return async (clientId: int): Promise<ClientPONumbersResult[]> => {
        return axiosData({
			method: 'get',
            url: `${CLIENT_PURCHASE_ORDERS_URL}/${clientId}/numbers`,
            cancelToken: getSource().token,
		});
    };
};

/* Get amounts for a purchase order */
export const GetPoAmounts = (getSource: SourceGetter) => {
    return async (purchaseOrderId: int): Promise<PoAmountResult[]> => {
        return axiosData({
			method: 'get',
            url: `${CLIENT_PURCHASE_ORDERS_URL}/${purchaseOrderId}/amounts`,
            cancelToken: getSource().token,
		});
    };
};

/* Get proposal references for a purchase order */
export const GetPoReferences = (getSource: SourceGetter) => {
    return async (purchaseOrderId: int): Promise<PoReference[]> => {
        return axiosData({
			method: 'get',
            url: `${CLIENT_PURCHASE_ORDERS_URL}/${purchaseOrderId}/references`,
            cancelToken: getSource().token,
		});
    };
};

/* Gets job folder where PO should be located */
export const GetPoFilePath = (getSource: SourceGetter) => {
    return async (clientId: int): Promise<HierarchyId> => {
        return axiosData({
			method: 'get',
            url: `${CLIENT_PURCHASE_ORDERS_URL}/${clientId}/file-path`,
            cancelToken: getSource().token,
		});
    };
};

/* Create a new client purchase order */
export const CreateClientPurchaseOrder = mapApi((data: TClientPurchaseOrderForm): ApiConfig<ClientPurchaseOrder> => ({
	method: 'post',
	url: `${CLIENT_PURCHASE_ORDERS_URL}`,
	data: objectToFormData(data),
}));

/* Create a new client purchase order */
export const CreateClientPurchaseOrderRevision = mapApi((purchaseOrderId: int, reason: string): ApiConfig<boolean> => ({
	method: 'post',
	url: `${CLIENT_PURCHASE_ORDERS_URL}/revisions`,
	data: {
		purchaseOrderId,
		reason
	},
}));

/* Update a client purchase order */
export const UpdateClientPurchaseOrder = mapApi((contactId: int, data: TClientPurchaseOrderForm): ApiConfig<ClientPurchaseOrder> => ({
	method: 'put',
	url: `${CLIENT_PURCHASE_ORDERS_URL}/${contactId}`,
	data: objectToFormData(data),
}));

//No delete method for purchase orders because they are never deleted.
