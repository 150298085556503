import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, TextField, Typography } from '@mui/material';
import { makeStyles } from '@imas/styles';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Alignment } from '@imas/components/layout';
import { XDialogTitle } from '../mui';

export interface ReadOnlyDialogProps extends Omit<DialogProps, "open" | "onClose" | "title"> {
	open: boolean,
    body: string,
    title?: string
    confirmText?: string, 
    onClose: () => void,
};

const useStyles = makeStyles()(() => ({
    nameDialogueContent: {
        margin: '10px',
    },
    dialogue: {
        "& .MuiDialog-container .MuiDialog-paper": {
             width: '-webkit-fill-available',
        },
    },
}));

const ReadOnlyDialog = (props: ReadOnlyDialogProps) => {
    const { classes } = useStyles();

    const {
        open, body, title, confirmText, onClose, 
        
        ...dialogProps
    } = props;

    //if not open then return null
    if (!open) return null;

    return (
        <Dialog open={true} onClose={close} className={classes.dialogue} {...dialogProps}>
            <XDialogTitle fullScreen onClose={() => onClose()}>{title ?? ""}</XDialogTitle>
            {/* Dialog Title */}
            {/* {title ? 
            <DialogTitle>
                <Typography variant={'h5'} fontWeight={'bold'}>{}</Typography>
            </DialogTitle>
            : null} */}

            <DialogContent className={classes.nameDialogueContent}>
                <Typography variant={'body1'}>{body}</Typography>
            </DialogContent>
            <DialogActions>
                {/* Close Button */}
                <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => onClose()}
                >{confirmText ?? "Close"}</Button>
            </DialogActions>
        </Dialog>
    );
};


export { ReadOnlyDialog };