import { Button, Dialog, DialogActions, DialogContent, Typography, Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';

const ChoiceDialog = ({
    open, title, choices, confirmText, closeText, onConfirm, onClose
}: {
    open: boolean,
    title: string,
    choices: string[],
    confirmText?: string, 
    closeText?: string,
    onConfirm: (index: number, value: string) => void, 
    onClose: () => void,
}) => {
    //choice state
    const [choice, setChoice] = useState<number | null>(null);

    const close = () => {
        //clear choice value
        setChoice(null);

        //call onClose
        onClose();
    };

    return (
        <Dialog open={open} onClose={close}>
            {/* Choice Selection */}
            <DialogContent>
                {/* Title */}
                <Typography variant={"h5"} style={{ marginBottom: "5px" }}><Box fontWeight={"bold"}>{title}</Box></Typography>

                {/* Radio Button Control group */}
                <RadioGroup 
                    name={"choice-dialogue"} 
                    value={choice} 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChoice(Number(e.target.value))}
                >
                    {/* Generate Choice Radio Buttons */}
                    {choices.map((choice, index) => (
                        <FormControlLabel 
                            key={index}
                            value={index}
                            control={<Radio/>}
                            label={choice}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                {/* Cancel/Close Button */}
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={close}
                >{closeText ?? "Cancel"}</Button>

                {/* Choice Confirmation Button */}
                <Button
                    disabled={choice === null}
                    color={"primary"}
                    variant={"contained"}
                    onClick={ () => { onConfirm(choice ?? 0, choices[choice ?? 0]); close(); }}
                >{confirmText ?? "Confirm"}</Button>
            </DialogActions>
        </Dialog>
    );
};


export { ChoiceDialog };