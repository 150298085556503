import {
	GridFilterInputSingleSelect,
	GridFilterOperator,
	GridFilterItem,
	GridFilterInputMultipleSingleSelect,
} from "@mui/x-data-grid-pro";

//GridFilterInputMultipleSingleSelect
const parseObjectValue = (value: any) =>
	typeof value === "object" && value !== null
		? (value as { value: any; label: string }).value
		: value;

export const getFixedGridSingleSelectOperators: () => GridFilterOperator[] = () => [
	{
		value: "is",
		getApplyFilterFn: (filterItem: GridFilterItem) => {
			if (filterItem.value === null || filterItem.value === "") {
				return null;
			}
			return ({ value }): boolean =>
				parseObjectValue(value) === parseObjectValue(filterItem.value);
		},
		InputComponent: GridFilterInputSingleSelect,
	},
	{
		value: "not",
		getApplyFilterFn: (filterItem: GridFilterItem) => {
			if (filterItem.value === null || filterItem.value === "") {
				return null;
			}
			return ({ value }): boolean =>
				parseObjectValue(value) !== parseObjectValue(filterItem.value);
		},
		InputComponent: GridFilterInputSingleSelect,
	},
	{
		label: "is any of",
		value: "isAnyOf",
		getApplyFilterFn: (filterItem: GridFilterItem) => {
			if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
				return null;
			}
			const filterItemValues = filterItem.value.map(parseObjectValue);
			return ({ value }): boolean =>
				filterItemValues.includes(parseObjectValue(value));
		},
		InputComponent: GridFilterInputMultipleSingleSelect,
	},
];
