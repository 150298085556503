import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Countdown, { CountdownRendererFn, CountdownTimeDeltaFormatted } from 'react-countdown';
import { useIdleTimer } from 'react-idle-timer';
import { Navigate } from 'react-router-dom';
import idleTimeoutStyles from './IdleTimeoutStyles';

export interface IdleTimeoutProps extends React.PropsWithChildren<{}> {
    idleTime: number;
    logoutTime: number;
    enabled: boolean;
};

/** Used to detect an idle user and warn them of a pending automatic logout, and if the timeout is reached to redirect to /logout. */
export const IdleTimeout = ({ idleTime, logoutTime, enabled, children }: IdleTimeoutProps) => {
    const { classes } = idleTimeoutStyles();

    //logoutTimeout state, used to keep track of the time before the user is logged out after they have gone idle
    const [logoutTimeout, setLogoutTimeout] = useState<number | null>(null);

    //a reference which referecnes the value of the enabled prop. this is needed because enabled will not be updated in callbacks if provided without a ref
    const enabledRef = useRef<boolean>();

    //function for handling when the user goes idle
    const onIdle = (event: any) => {
        //only trigger the logout timer if the 'enabled=true'
        if (enabledRef.current) {
            //display the logout count down dialogue with the specified logout time
            setLogoutTimeout(Date.now() + (1000 * 60 * logoutTime));
        }
    };

    //function for handling when the user is active 
    const onActive = (event: any) => {
        //stop the logout count down 
        setLogoutTimeout(null);
    };

    //call the useIdleTimer hook
    //const { getRemainingTime, getLastActiveTime } = 
	useIdleTimer({
        timeout: (1000 * 60 * idleTime),
        onIdle: onIdle,
        onActive: onActive,
        crossTab: {
            emitOnAllTabs: true
        },
    });

    //update the enabledRef when the state of enabled changes
    useEffect(() => {
        enabledRef.current = enabled;
    }, [enabled]);

    //component for rendering the timeout countdown
    const CountDownRender: CountdownRendererFn = ({ 
        completed, formatted
    }: {
        completed: boolean,
        formatted: CountdownTimeDeltaFormatted
    }): React.ReactNode => {
        if (completed) {
            //prevent redirect if the logoutTimeout was cleared
            if (logoutTimeout === null) {
                return null;
            }

            //clear the logoutTimeout since it needs to be cleared for the dialogue to go away
            setLogoutTimeout(null);
            
            // render a redirect to the '/logout' path
            return <Navigate to="/logout"/>;
        } else {
            // render a count down
            return (
                <Typography
                    variant={"h5"}
                    className={classes.timeoutText}
                >{formatted.minutes}:{formatted.seconds.length === 1 ? "0" + formatted.seconds : formatted.seconds}</Typography>
            );
        }
    };

    //return the children of this component along with a dialogue which is uses to let the user know they are about to be logged out
    return (
        <React.Fragment>
            {children}
            <Dialog open={logoutTimeout !== null} onClose={() => setLogoutTimeout(null)}>
                <DialogTitle className={classes.timeoutTitle}>
                    <Typography variant={"h5"}>You are about to be logged out!</Typography>
                </DialogTitle>
                <DialogContent>
                    {/* Cause a re-render of the Countdown whenever logoutTime is updated */}
                    {logoutTime === null ? null :
                        <Countdown
                            date={logoutTimeout === null ? undefined : logoutTimeout}
                            renderer={CountDownRender}
                        />
                    }
                </DialogContent>
                <DialogActions>
                        
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};