import { ListItem, Box } from '@mui/material';
import { FolderInfoBreadcrumb } from '@imas/api/files/types';
import { getFileTypeIconURL } from '@imas/utils/files';
import React, { useContext } from 'react';
import { useFileExplorerItemStyles } from '../../FileExplorerItemContainer/FileExplorerItemList/FileExplorerItemListRow';
import { FileExplorerContext } from '../../FileExplorerContext';

export interface NavigationMenuItemProps {
    crumb: FolderInfoBreadcrumb;
};

const NavigationMenuItem = ({ crumb }: NavigationMenuItemProps) => {
    //get select checkboxes preference
    const { mode, canNavigateTo, navigate } = useContext(FileExplorerContext);
    
    //component styling
    const { classes } = useFileExplorerItemStyles({ mode });

    return (
        <ListItem 
            button
            disabled={!canNavigateTo(crumb.pathLocator)}
            onClick={() => navigate(crumb.name, crumb.pathLocator)}
            sx={{ padding: '2px 8px' }}
        >
            {/* Folder Icon */}
            <img src={getFileTypeIconURL(null, { isDirectory: true })} className={classes.icon} alt={"Folder Icon"}/>

            {/* Folder Name */}
            <Box sx={{ marginLeft: '12px', typography: 'body2' }}>{crumb.name}</Box>
        </ListItem>
    );
};

export { NavigationMenuItem };