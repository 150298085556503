import { SourceGetter } from '@imas/api';
import axios from 'axios';
import { CertificationMethod, vCertificationDetail } from 'src/api/types/api/certification/types';

/**
 *  Get all vCertificationDetail records
 */
 export const _GetvCertificationDetails = (getSource: SourceGetter) => {
    return (): Promise<vCertificationDetail[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vCertificationDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/proposals/project-requirements/vcertificationdetails',
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
       });
   };
};

/**
 *  Get vCertificationDetail records where to be included in proposals
 */
 export const _GetProposalvCertificationDetails = (getSource: SourceGetter) => {
    return (): Promise<vCertificationDetail[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vCertificationDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/proposals/project-requirements/vcertificationdetails/proposals',
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
       });
   };
};

/**
 *  Get CertificationMethods
 */
 export const _GetCertificationMethods = (getSource: SourceGetter) => {
    return (): Promise<CertificationMethod[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<CertificationMethod[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/certification-methods',
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
       });
   };
};

/**
 *  Create a CertificationMethod
 */
export const _CreateCertificationMethod = (getSource: SourceGetter) => {
    return (method: CertificationMethod): Promise<CertificationMethod> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<CertificationMethod>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/certification-methods',
                data: method,
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
       });
   };
};

/**
 *  Update a CertificationMethod
 */
export const _UpdateCertificationMethod = (getSource: SourceGetter) => {
    return (method: CertificationMethod): Promise<CertificationMethod> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<CertificationMethod>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/certification-methods',
                data: method,
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
       });
   };
};