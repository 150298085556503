import React, { MouseEvent, useCallback, useRef, useState } from 'react';
import { makeStyles } from "@imas/styles";
import { LoadingButton, LoadingButtonProps } from '@mui/lab';


const useStyles = makeStyles()(theme => ({
    loading: {
        paddingLeft: '35px',
    },
}));

export interface AsyncButtonProps extends Omit<LoadingButtonProps, 'loading' | 'onClick' | 'classes'> {
	onClick: (event: MouseEvent<HTMLButtonElement>) => Promise<any>;
};

/**
 * 	A button which accepts an async function for the onClick callback, while the onClick executes the button will be shown in a loading state.
 * 		
 */
export const AsyncButton = (props: AsyncButtonProps) => {
    const { onClick, ...buttonProps } = props;

    const { classes } = useStyles();

	//onClickRef
	const onClickRef = useRef(onClick);
	onClickRef.current = onClick;

	//loading state
	const [loading, setLoading] = useState(false);

	//onClick handler
	const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
		setLoading(true);
		
		onClickRef.current(event).finally(() => setLoading(false));
	}, [setLoading]);

    return (
       <LoadingButton
			loading={loading}
			onClick={handleClick}
			variant={'contained'}
			loadingPosition={'start'}
            classes={classes}
			{...buttonProps}
	   />
    );
};