import { Client } from '@imas/api/client';
import { Typography, createFilterOptions, AutocompleteProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

const ClientListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<VirtualizedListboxAdpater 
			{...props} 
			ref={ref} 
			renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<Client>) => {
				const row = data[index];
			
				return (
					<Typography 
						component={"li"} 
						{...row.props} 
						noWrap 
						style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}
					>{row.option.name}</Typography>
				);
			}}
		/>
	);
});

const clientsFilter = createFilterOptions<Client>({ stringify: (client) => `${client.name}` });

export const CLIENTS_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<Client, false, false, false, "div">, "filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {
	filterOptions: clientsFilter,

	//virtualization props
	disableListWrap: true,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: ClientListboxAdapter,
	// PaperComponent: ClientsPaper,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};