import { axiosData, SourceGetter } from "@imas/api";
import { EmployeeRecognition } from "./types";

//employee recognitions base url
const EMPLOYEE_RECOGNITIONS_URL = "/api/system/employee-recognitions";

/* Get records */
export const GetEmployeeRecognitions = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeRecognition[]> => {
        return await axiosData({
            method: 'get',
            url: `${EMPLOYEE_RECOGNITIONS_URL}`,
            cancelToken: getSource().token,
        });
    };
};

/* Update a record */
export const UpdateEmployeeRecognition = (getSource: SourceGetter) => {
    return async (recognition: EmployeeRecognition): Promise<EmployeeRecognition> => {
        return await axiosData({
            method: 'put',
            url: `${EMPLOYEE_RECOGNITIONS_URL}`,
            data: recognition,
            cancelToken: getSource().token,
        });
    };
};

/* Create a record */
export const CreateEmployeeRecognition = (getSource: SourceGetter) => {
    return async (recognition: EmployeeRecognition): Promise<EmployeeRecognition> => {
        return await axiosData({
            method: 'post',
            url: `${EMPLOYEE_RECOGNITIONS_URL}`,
            data: recognition,
            cancelToken: getSource().token,
        });
    };
};