import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext, useMuiWatch, FormCheckbox, FormTextField } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo, useState, useEffect, useCallback } from 'react';
import { useWorkPlaceReviewSection } from './WorkPlaceReviewFormUtils';

export const WorkPlaceReviewOther = memo(() => {
    const { control, isLoading } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//if the section form area is open
	const [sectionOpen, setSectionOpen] = useState(true);

	//get section values
	const [anyTrue, getAnyTrue] = useWorkPlaceReviewSection("other");

	//open
	const open = sectionOpen || anyTrue;

	//handles toggling sectionOpen
	const handleSectionToggle = useCallback((e: any, checked: boolean) => {
		if (getAnyTrue() && !checked) {
			return;
		}

		//toggle section open
		setSectionOpen(checked);
	}, [getAnyTrue, setSectionOpen]);

	//value of other
	const [other] = useMuiWatch({ control, name: ["other"] });

	//when the form is finished loading get the values for this section, if any are true open the collpased area
	useEffect(() => {
		if (getAnyTrue()) setSectionOpen(true);
	}, [isLoading, getAnyTrue, setSectionOpen]);
	
	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"OTHER HAZARDS: "}</b>
						{sectionOpen ? "The following additional safety hazards have been identified at this work area:" : "Additional safety hazards have been identified at this work area."}
					</Typography>
				}
				control={<Checkbox disabled={anyTrue} checked={open} onChange={handleSectionToggle}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={open} sx={{ marginLeft: '45px' }}>
				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"otherNight"}
						label={"Night work - lights needed"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherElectrical"}
						label={"Electrical GFI Required"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherCrane"}
						label={"Cranes"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherMachinery"}
						label={"Grinding or Welding activities"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherAboveWork"}
						label={"Others working above"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherBelowWork"}
						label={"Others working below - equipment tie offs required"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherForklift"}
						label={"Forklifts"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherHeat"}
						label={"Excessive heat"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherWind"}
						label={"Excessive wind/dust"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherRain"}
						label={"Rain"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherSnow"}
						label={"Snow"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherNoise"}
						label={"Loud noise (short or long exposure)"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherAerialDebris"}
						label={"Flying debris (wood chips, metal chips, etc.)"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"otherTripping"}
						label={"Tripping hazards such as extension cords, hoses, etc."}
						sx={{ marginRight: '20px'}}
					/>
				</Alignment>

				{/* Other & Description */}
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"other"}
						label={"Other"}
					/>

					{other ? (
						<FormTextField
							required
							control={control}
							name={"otherDesc"}
							multiline
							rows={3}
						/>
					) : null}
				</Alignment>
			</Collapse>

			{/* Protective Equipment */}
			<Alignment column sx={{ marginLeft: '45px' }}>
				<Typography variant={"body1"} sx={{ margin: '5px', fontWeight: 'bold' }}>{"Protective Equipment Required:"}</Typography>
				
				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"reqHeadPPE"}
						label={"Hard hat, helmet, etc."}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqHearingPPE"}
						label={"Ear plugs, Ear muffs, etc."}
						sx={{ marginRight: '20px'}}
					/>
					
					<FormCheckbox
						control={control}
						name={"reqLight"}
						label={"Temporary lights (Flood lights, etc.)"}
						sx={{ marginRight: '20px'}}
					/>
				</Alignment>
			</Alignment>
		</Alignment>
	);
});