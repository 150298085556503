import { useEffect, useState } from "react";

//function for converting file to a Data URL
export function fileToDataURL(file: File | Blob | undefined, mimeType?: string) {
    return new Promise<string | null | undefined>((resolve) => {
        if (file === undefined) {
            return resolve(undefined);
        } else {
            //create a file reader
            const reader = new FileReader();

            //register an onload listener
            reader.onload = (e) => {
                const result = e.target?.result;
                if (typeof result === "string") {
                    if (mimeType) {
                        return resolve(result.replace("text/html", mimeType));
                    }

                    return resolve(result);
                }
                else return resolve(null);  
            };

            //and an onabort & onerror listener
            reader.onabort = () => {
                resolve(null);
            };
        
            reader.onerror = () => {
                resolve(null);
            };   
            //read the file
            reader.readAsDataURL(file);
        }
    });
};

//hook which takes a File object and returns a data URL for that object  
export const useFileAsDataURL = (file: File | Blob | undefined, mimeType?: string) => {
    //data url
    const [dataURL, setDataURL] = useState<string | null | undefined>();

    //hook for converting file => data URL
    useEffect(() => {
        //set the dataURL to undefined
        setDataURL(undefined);

        //use the fileToDataURL function to convert the file
        fileToDataURL(file, mimeType)
        .then(result => setDataURL(result))
        .catch(() => setDataURL(null));
    }, [file, mimeType]);

    return dataURL;
};

