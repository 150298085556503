import { TreeView } from "@mui/lab";
import React, { FC, useState } from "react";
import { FileTableViewerResult } from "@imas/api/files/types";
import {
	Description as DescriptionIcon,
	Folder as FolderIcon,
	Add as AddIcon,
	Remove as RemoveIcon,
} from "@mui/icons-material";
import { FileTreeItem } from "./FileTreeItem/FileTreeItem";

interface BaseFolderViewerProps {
	/** HierarchyId of the top level item (the root folder). */
	top: HierarchyId | null;
	/** List of files contained inside the root folder. */
	files: FileTableViewerResult[];
	/** Whitelist/Blacklist filters. */
	filter?: FolderViewerFilter;
	/** Exclude folders from being shown in the tree. */
	excludeFolders?: boolean;
	/** CSS classname to apply to root element. */
	className?: string;
};

interface SingleFolderViewProps extends BaseFolderViewerProps {
	/** If multiple item selection is enabled. */
	multiSelect?: false;
	/** Callback with selected item. */
	onSelect: (selected: HierarchyId) => void;
};

interface MultiFolderViewProps extends BaseFolderViewerProps {
	/** If multiple item selection is enabled. */
	multiSelect: true;
	/** Callback with selected items. */
	onSelect: (selected: HierarchyId[]) => void;
};

type FolderViewProps = BaseFolderViewerProps & (SingleFolderViewProps | MultiFolderViewProps);

interface FolderViewerFilter {
	/** HierarchyId to blacklist from viewer. */
	fileBlacklist?: string[];
	/** HierarchyId to whitelist for viewer. */
	fileTypeWhitelist?: string[];
};

/** Has been replaced by the FileExplorer component, no longer use if possible. (LEGACY) */
export const FolderViewer: FC<FolderViewProps> = ({
	top,
	files,
	filter,
	multiSelect,
	excludeFolders,
	className,
	onSelect,
}) => {
	//selected files
	const [selected, setSelected] = useState<HierarchyId[]>([]);

	//sort function which orders by folders -> files -> alphabetically
	const sortFiles = (a: FileTableViewerResult, b: FileTableViewerResult) => {
		if (
			(a.isDirectory && b.isDirectory) ||
			(!a.isDirectory && !b.isDirectory)
		) {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
		} else {
			if (a.isDirectory) return -1;
			if (b.isDirectory) return 1;
		}

		return 0;
	};

	//filter out all files which dont match the filter
	const filterFiles = (x: FileTableViewerResult) => {
		if (filter === undefined) return true;

		//check if the node is in the fileBlacklist
		if (
			filter.fileBlacklist !== undefined &&
			(filter.fileBlacklist.includes(x.pathLocator) ||
				filter.fileBlacklist.includes(x.name))
		)
			return false;

		//check if the node is a directory
		if (x.isDirectory) return true;

		//check if the file type is not whitelisted
		if (
			filter.fileTypeWhitelist !== undefined &&
			!filter.fileTypeWhitelist.includes(x.fileType.toLowerCase().trim())
		)
			return false;

		return true;
	};

	//get node contents given a node
	const getContents = (node: FileTableViewerResult): React.ReactNode => {
		//if the node is a directory then return it's directory contents
		if (node.isDirectory) return getDirectory(node);

		//if the nde is a normal file show a TreeNode with the file's filename
		return (
			<FileTreeItem
				key={node.pathLocator}
				nodeId={node.pathLocator}
				labelText={node.name}
				labelIcon={DescriptionIcon}
			/>
		);
	};

	//return a node which represents a directory
	const getDirectory = (
		node: FileTableViewerResult | null = null
	): React.ReactNode => {
		//dir pathLocator
		const path = node === null ? top : node.pathLocator;

		//get a list of files which belong in this directory and which match the provided filter
		const dirFiles = files
			.filter((x) => x.parentPathLocator === path)
			.filter(filterFiles);

		//if the directory has no files then dont show it
		if (dirFiles.length === 0) return null;

		//sort the files
		dirFiles.sort(sortFiles);

		//if the path is the top level path then dont add a <TreeItem>
		if (path === top) {
			return <> {dirFiles.map((node) => getContents(node))} </>;
		}

		return (
			<FileTreeItem
				key={node?.pathLocator}
				nodeId={path === null ? "null" : path}
				labelText={node!.name}
				labelIcon={FolderIcon}
			>
				{dirFiles.map((node) => getContents(node))}
			</FileTreeItem>
		);
	};

	return (
		<TreeView
			multiSelect={multiSelect ? true : undefined}
			defaultCollapseIcon={<RemoveIcon />}
			defaultExpandIcon={<AddIcon />}
			selected={selected}
			onNodeSelect={(e: React.ChangeEvent<{}>, selected: string[] | string) => {
				let nodeIds = typeof selected === "string" ? [selected] : selected;

				if (excludeFolders) {
					//if selected node is a folder, if it is prevent it from being selected
					nodeIds = files
						.filter((x) => nodeIds.includes(x.pathLocator) && !x.isDirectory)
						.map((x) => x.pathLocator);
				}

				if (nodeIds.length === 0) return;

				setSelected(nodeIds);

				if (multiSelect) {
					onSelect(nodeIds as string & string[]);
				} else {
					onSelect(nodeIds[0] as string & string[]);
				}
			}}
			classes={{ root: className }}
		>
			{getDirectory()}
		</TreeView>
	);
};
