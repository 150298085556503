import { axiosData, SourceGetter } from "@imas/api";
import { AccountCode } from './types';

//account codes base url
const ACCOUNT_CODES_URL = "/api/system/account-codes";

/*  */
export const GetAccountCodes = (getSource: SourceGetter) => {
    return async (): Promise<AccountCode[]> => {
        return await axiosData({
            method: 'get',
            url: `${ACCOUNT_CODES_URL}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetCodeSortAccountCodes = (getSource: SourceGetter) => {
    return async (): Promise<AccountCode[]> => {
        return await axiosData({
            method: 'get',
            url: `${ACCOUNT_CODES_URL}/codesort`,
            cancelToken: getSource().token,
        });
    };
};