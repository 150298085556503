import validator from 'validator';

export function isValidEmail(email: string) {
    return validator.isEmail(email);
};


//validates a email list which is stored as a string with each email seperated by a dilimiter
export const isValidEmailList = (emailList: string, dilimiter: string = ";") => {
    //split list of emails into an array of strings
    const emails = emailList.split(dilimiter).map(x => x.trim());

    //ensure each email in the array is valid
    return emails.every(email => validator.isEmail(email));
};