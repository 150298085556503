import { TreeItem, TreeItemProps } from "@mui/lab";
import { FC } from "react";
import { Collapse, SvgIconTypeMap, Typography } from "@mui/material";
import { makeStyles } from "@imas/styles";
import { TransitionProps } from "@mui/material/transitions";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type FileTreeItemProps = TreeItemProps & {
	/** Icon for item label. */
	labelIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
	/** Label css class. */
	labelInfo?: string;
	/** Display text for item label. */
	labelText: string;
};

const useTreeItemStyles = makeStyles()((theme) => ({
	root: {
		padding: "0 10px 0 10px",
		color: theme.palette.text.secondary,
		"&:hover > $content": {
			backgroundColor: theme.palette.action.hover,
		},
	},
	content: {
		color: theme.palette.text.secondary,
		fontWeight: theme.typography.fontWeightMedium,
		"$expanded > &": {
			fontWeight: theme.typography.fontWeightRegular,
		},
		"& .MuiTreeItem-label": {
			"&:hover": {
				background: "unset",
			},
		},
		"& .MuiTreeItem-iconContainer": {
			marginLeft: "5px",
		},
	},
	group: {
		marginLeft: 0,
		"& $content": {
			paddingLeft: theme.spacing(2),
		},
	},
	expanded: {},
	selected: {},
	label: {
		fontWeight: "inherit",
		color: "inherit",
	},
	labelRoot: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0.5, 0),
	},
	labelIcon: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	labelText: {
		fontWeight: "inherit",
		flexGrow: 1,
	},
	labelInfo: {
		paddingRight: "10px",
	},
}));

//tree item transition component
function TransitionComponent(props: TransitionProps) {
	const style = useSpring({
		from: { opacity: 0, transform: "translate3d(20px,0,0)" },
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
};

export const FileTreeItem: FC<FileTreeItemProps> = ({
	labelText,
	labelIcon: LabelIcon,
	labelInfo,
	...other
}) => {
	const { classes } = useTreeItemStyles();

	return (
		<TreeItem
			TransitionComponent={TransitionComponent}
			label={
				<div className={classes.labelRoot}>
					<LabelIcon color="inherit" className={classes.labelIcon} />
					<Typography variant="body2" className={classes.labelText}>
						{labelText}
					</Typography>
					<Typography
						variant="caption"
						color="inherit"
						className={classes.labelInfo}
					>
						{labelInfo}
					</Typography>
				</div>
			}
			classes={{
				root: classes.root,
				content: classes.content,
				expanded: classes.expanded,
				selected: classes.selected,
				group: classes.group,
				label: classes.label,
			}}
			{...other}
		/>
	);
};
