import { UserData } from "@imas/api/auth/types";
import { Employee } from "@imas/api/employee";
import { UserRole } from "@imas/types";

const isUserData = (data: UserData | Employee): data is UserData => {
    return 'employee' in data && typeof data['employee'] === 'object';
};

//get user role from User record
export const getUserRole = (data?: UserData | Employee | string | null) => {
    const role = data ? typeof data === 'string' ? data : isUserData(data) ? data.employee.userRole : data.userRole : '';

    switch (role) {
        case "Users":
            return UserRole.User;
        case "Power Users":
            return UserRole.PowerUser;
        case "Super Users":
            return UserRole.SuperUser;
        case "Admins":
            return UserRole.Admin;
        default:
            return UserRole.None;
    };
};