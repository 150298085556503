import React, { forwardRef } from 'react';
import { useMergeRefs } from 'use-callback-ref';
import { FieldValues, FieldPath } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { FormInputProps } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';

export interface FormSSNProps<TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<string | null, TFieldValues, TName>,
    Omit<TextFieldProps, "name" | "defaultValue" | "error" | "value" | "onChange" | "onBlur" | "ref" | "select" | "SelectProps"> 
{
    disableErrorText?: boolean;
};

// SSN pattern (XXX-XX-XXXX)
const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;

const _FormSSN = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
        ...textFieldProps
    }: FormSSNProps<TFieldValues, TName>,
	extRef: React.ForwardedRef<HTMLInputElement>
) => {
    //use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
		context: { size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

	//merge refs
	const mergeRef = useMergeRefs([ref, extRef]);

    // Prevent input from being non-numeric (except for dash)
    const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9-]/.test(e.key) && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };

    // Format input as SSN (XXX-XX-XXXX)
    const formatSSN = (value: string) => {
        const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters
        const part1 = cleaned.slice(0, 3);
        const part2 = cleaned.slice(3, 5);
        const part3 = cleaned.slice(5, 9);
        if (cleaned.length > 5) {
            return `${part1}-${part2}-${part3}`;
        } else if (cleaned.length > 3) {
            return `${part1}-${part2}`;
        } else if (cleaned.length > 0) {
            return part1;
        }
        return "";
    };

    //render loading
    if (isLoading) return <FormFieldSkeleton {...textFieldProps} size={textFieldProps.size ?? size} />;

    //render TextField 
    return (
        <TextField
            {...textFieldProps}
			size={textFieldProps.size ?? size}
            disabled={isViewOnly || textFieldProps.disabled}
            name={name}
            required={isRequired}
            value={value ?? ""}
            onChange={e => {
                const newValue = formatSSN(e.target.value);
                onChange(newValue === "" ? null : newValue);
            }}
            onBlur={handleBlur}
            onKeyDown={handleInput}
            inputRef={mergeRef}
        />
    );
};

const FormSSN = forwardRef(_FormSSN);

export { FormSSN };
