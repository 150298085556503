import { useApi, useLoadApi } from '@imas/api';
import { Alignment, LabeledItem, Spacer, XSkeleton } from '@imas/components/layout';
import { resizeImage } from '@imas/utils/files';
import { Paper, List, ListItem, Divider, Skeleton, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Backdrop, Fab, Button, ButtonGroup, Box, Dialog, DialogActions, DialogContent, Typography, Checkbox, TextField, FormControlLabel, Zoom, IconButton } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { GetServiceOrderTrackingResult, GetvServiceOrderCharges, UpdateSignatureRequest } from 'src/api/service-order/api';
import { _SOTrackingResult } from 'src/api/types/api/service-orders/service-order/ServiceOrderTrackingResult';
import vServiceOrderDetail from 'src/api/types/api/service-orders/vServiceOrderDetails';
import MobileClientSignatureStyles from './MobileClientSignatureStyles';
import SignatureCanvas from 'react-signature-canvas';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import validator from 'validator';
import { SOSignatureDetail, ServiceOrderSignatureRequest } from 'src/api/types/api/service-orders/types';
import { isPopup } from '@imas/utils/misc';
import { Draw } from 'mdi-material-ui';
import { Delete } from '@mui/icons-material';

export interface ClientSignatureProps {
	signatureRequest: ServiceOrderSignatureRequest,
	signedRequests: ServiceOrderSignatureRequest[],
	//serviceOrder: _SOTrackingResult,
	//charges: vServiceOrderDetail[],
	signatureDetails: SOSignatureDetail[],
	setSigned: (signed: boolean) => void,
}

export const ServiceOrderClientSignature = ({signatureRequest, signedRequests, signatureDetails, setSigned}: ClientSignatureProps) => {
	//get navigation
	const navigate = useNavigate();

	//component styling
	const { classes, deviceInfo } = MobileClientSignatureStyles();

	//use automatic hiding snackbar
	const showSnackbar = useAutomaticSnackbar();

	//state variables
	const [signatureOpen, setSignatureOpen] = useState(false);
	const [toEmail, setToEmail] = useState(signatureRequest.sendCopy && signatureRequest.email !== '');
	const [signedName, setSignedName] = useState<string>('');
	const [signatureDataUrl, setSignatureDataUrl] = useState<string | null>(null);

	const height = window.innerHeight;
	const width = window.innerWidth;

	//api functions
	const updateSignatureRequest = useApi(UpdateSignatureRequest);

	//Number of days to show
	const numDays = useMemo(() => {
		return moment(signatureRequest.thruDate).diff(moment(signatureDetails[0].startDate), 'days');
	}, [signatureRequest, signatureDetails]);

	const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSignedName(
		  e.target.value
			.toLowerCase()
			.split(" ")
			.map(word => {
			  return word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(" ")
		);
	  };

	//Canvas functions
	let sigCanvas: SignatureCanvas | null;

	const handleSignatureClose = () => {
		setSignatureOpen(false);
	};

	const handleSignatureClear = () => {
		if (sigCanvas !== null) {
			sigCanvas.clear();
		}
	};

	const handleSignatureConfirm = () => {
		if (sigCanvas !== null) {

			//phone needs to rotate the image
			if (deviceInfo.isMd){
				setSignatureDataUrl(sigCanvas.toDataURL());
			}
			//desktop path
			else {
				let dataUrl = sigCanvas.toDataURL();

				//convert the dataurl into a file
				let arr = dataUrl.split(','),
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
				while(n--){
					   u8arr[n] = bstr.charCodeAt(n);
				   }			
			   
				   let sigfile = new File([u8arr], 'Signature for ' + signatureDetails[0]!.uniqueId.toString());
			   
				resizeImage(sigfile, {rotation:(270)})
					.then((resizedsig) => {
						const reader = new FileReader();
						reader.onloadend = () => {
							if (typeof reader.result === 'string') setSignatureDataUrl(reader.result);	
						};
						reader.readAsDataURL(resizedsig);
					})
					.catch((e) => console.log(e));
			}
		}
		handleSignatureClose();
	};

	const onSubmit = () => {

		//submitting snackbar
		const close = showSnackbar(`Submitting, this could take up to 15 seconds...`, { variant: "info", persist: true });

		let dataUrl = 'error';

		//process for typed signature
		if (signatureDataUrl === null) {
			if (sigCanvas !== null) {
				//let test = sigCanvas.getTrimmedCanvas();
				let test = sigCanvas.getCanvas();
				//let canvas = new SignatureCanvas({canvasProps: {width: 900, height:450}});
				//canvas.width = 900;
				//canvas.height = 450;
				//let test = canvas.getCanvas();
				let ctx = test.getContext("2d");
	
				if (ctx !== null) {
	
					if (signedName.length < 25) {
						ctx.font = "100px brush script mt";
					}
					else ctx.font = "75px brush script mt";
	
					// approximate the font height
					let approxFontHeight=parseInt(ctx.font);
					let centerX = 450;
					let centerY = 225;
	
					// alter the context to center-align the text
					ctx.textAlign="center";
			
					// draw the text centered at [centerX,centerY]
					ctx.fillText(signedName, centerX, centerY+approxFontHeight/4);

					dataUrl = test.toDataURL('image/png');
				}
			}
			else {
				console.log('canvas is null');
			}
		}
		//process for drawn signature
		else {
			dataUrl = signatureDataUrl;
		}

		//convert the dataurl into a file
		let arr = dataUrl.split(','),
		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
		while(n--){
			   u8arr[n] = bstr.charCodeAt(n);
		   }			

		   let sigfile = new File([u8arr], 'Signature for ' + signatureDetails[0]!.uniqueId.toString());

		resizeImage(sigfile)
			.then((resizedsig) => {
			   //updateSignature(serviceOrder!.uniqueId,  resizedsig, signatureRequest.name, toEmail, signatureRequest.email).then((response) => {
			   //	close();
			   //	showSnackbar(`Client signature submitted.`, { variant: 'success' }, true);
			   //});
			   updateSignatureRequest(signatureRequest.signatureRequestId, toEmail, resizedsig).then(() => {
				   close();
				   setSigned(true);
			   });
			})
			.catch((e) => console.log(e));
	};

	/*
	const useFileAsObjectURL = (file: File | Blob | undefined) => {
		//object url
	
		if (file === undefined) return;
	
		//convert and update objectURL
		const objURL = URL.createObjectURL(file);

		return objURL;
	};

	const showImg = (imgfile: File) => {
		const filePreviewURL = useFileAsObjectURL(imgfile);
		let img = document.createElement('img');
		if (filePreviewURL !== undefined) img.src = filePreviewURL;
		document.body.appendChild(img);
	};
	*/

	const emailClicked = () => {
		if(toEmail) {
			setToEmail(false);
		}
		else {
			setToEmail(true);
		}
	};

	const chargeItems = (chargeArray: SOSignatureDetail[]) => {
		chargeArray.sort((a, b) => (a.sortOrder || 100) - (b.sortOrder || 100));

		return chargeArray.map((charge, i) => {
			return (
				<TableRow key={charge.chargeId}>
					<TableCell align={'left'} sx={{ paddingLeft: 0.5, paddingRight: 0, margin: 'auto 0', }}>
						{charge.unitDescription}
					</TableCell>
					<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0',  }}>
						{charge.qty0?.toFixed(2)}
					</TableCell>
					{numDays >= 1 ? (
						<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0',  }}>
							{numDays >=1 ? charge.qty1?.toFixed(2) : null}
						</TableCell>
					) : null}
					{numDays >= 2 ? (
						<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0',  }}>
							{numDays >=2 ? charge.qty2?.toFixed(2) : null}
						</TableCell>
					) : null}
					{numDays >= 3 ? (
						<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0',  }}>
							{numDays >=3 ? charge.qty3?.toFixed(2) : null}
						</TableCell>
					) : null}
					{numDays >= 4 ? (
						<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0',  }}>
							{numDays >=4 ? charge.qty4?.toFixed(2) : null}
						</TableCell>
					) : null}
					{numDays >= 5 ? (
						<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0',  }}>
							{numDays >=5 ? charge.qty5?.toFixed(2) : null}
						</TableCell>
					) : null}
					{numDays >= 6 ? (
						<TableCell align={'center'} sx={{ paddingLeft: 0, paddingRight: 0, margin: 'auto 0', }}>
							{numDays >=6 ? charge.qty6?.toFixed(2) : null}
						</TableCell>
					) : null}
					<TableCell align={'center'} sx={{ paddingLeft: 1, paddingRight: 1, margin: 'auto 0', }}>
						{charge.totalQty?.toFixed(2)}
					</TableCell>
				</TableRow>
			);
		});
	};

	const otherSignatures = useMemo(() => {
		return signedRequests.map((request) => {
			return (
				<Typography color={'textPrimary'}>{`${request.name} signed on ${moment(request.signedDateTime).format('dddd, MMMM Do')} (Data thru ${moment(request.thruDate).format('M/D')})`}</Typography>
			);
		});
	}, [signedRequests]);

	const calculateTotal = (charge: vServiceOrderDetail) => {
		let total = 0;
		total += charge.qty0 ?? 0;
		if (numDays >= 1) total += charge.qty1 ?? 0;
		if (numDays >= 2) total += charge.qty2 ?? 0;
		if (numDays >= 3) total += charge.qty3 ?? 0;
		if (numDays >= 4) total += charge.qty4 ?? 0;
		if (numDays >= 5) total += charge.qty5 ?? 0;
		if (numDays >= 6) total += charge.qty6 ?? 0;

		return total;
	};

	return (
		<>
			<Alignment column className={classes.container}>

			<DialogContent className={classes.titleContainer}>
				{/* Header */}
				<Alignment row>
					{/* change header based on device size */}
					{/* <Paper className={classes.propNumContainer}>
						<Alignment column sx={{ minWidth: '52px' }}>
							
							{/* serviceorderid }
							<Typography variant={'h4'} className={classes.propNumText}>
								{serviceOrder.serviceOrderId}
							</Typography>

							{/* date range 'daily' or 'weekly' }
							<Typography variant={'subtitle1'} className={classes.propNumText}>
								{(serviceOrder.dateRange.length) > 10 ? 'Weekly' : 'Daily'}
							</Typography>
						</Alignment>
					</Paper> */}

					{/* Show the Client Name, JobNo, and JobName right of the box */}
					<Alignment column flex className={classes.detailsContainer}>
						{/* Client Name */}
						<Typography className={classes.clientName} variant={deviceInfo.isMd ? 'h4' : 'h5'} textAlign={deviceInfo.isMd ? 'left' : 'center'}>
							{signatureDetails[0].client}
						</Typography>

						{/* Job Number */}
						<Typography className={classes.darkModeHelp} variant={deviceInfo.isMd ? 'h5' : 'h6'} textAlign={deviceInfo.isMd ? 'left' : 'center'}>{signatureDetails[0].jobNo + ` (${signatureDetails[0].serviceOrderId})`}</Typography>

						{/* Full Job name  */}
                        <Typography className={classes.darkModeHelp} variant={deviceInfo.isMd ? 'h5' : 'h6'} textAlign={deviceInfo.isMd ? 'left' : 'center'} >{signatureDetails[0].proName}</Typography>
					</Alignment>

					
				</Alignment>

				{/* Divider for mobile*/}
				{!deviceInfo.isMd ? ( <>
                <Divider className={classes.headerDivider}/> </>) : null}
				{deviceInfo.isMd ? <Spacer vertical size={'15px'} /> : null}

				{/* Table */}
				<TableContainer >
					<Table stickyHeader sx={{}}>
						{/* Header */}
						<TableHead>
							<TableRow className={classes.tableTopRow}>
								<TableCell align={'left'} style={{ minWidth: '50%' }} sx={{ }}>
									{'Description'}
								</TableCell>
								<TableCell align={'center'} sx={{  }}>
									{moment(signatureDetails[0].startDate).format('ddd MM/DD')}
								</TableCell>
								{numDays >= 1 ? (
									<TableCell align={'center'} sx={{   }}>
										{moment(moment(signatureDetails[0].startDate).add(1, 'd')).format('ddd MM/DD')}
									</TableCell>
								) : null}
								{numDays >= 2 ? (
									<TableCell align={'center'} sx={{  }}>
										{moment(moment(signatureDetails[0].startDate).add(2, 'd')).format('ddd MM/DD')}
									</TableCell>
								) : null}
								{numDays >= 3 ? (
									<TableCell align={'center'} sx={{  }}>
										{moment(moment(signatureDetails[0].startDate).add(3, 'd')).format('ddd MM/DD')}
									</TableCell>
								) : null}
								{numDays >= 4 ? (
									<TableCell align={'center'} sx={{  }}>
										{moment(moment(signatureDetails[0].startDate).add(4, 'd')).format('ddd MM/DD')}
									</TableCell>
								) : null}
								{numDays >= 5 ? (
									<TableCell align={'center'} sx={{  }}>
										{moment(moment(signatureDetails[0].startDate).add(5, 'd')).format('ddd MM/DD')}
									</TableCell>
								) : null}
								{numDays >= 6 ? (
									<TableCell align={'center'} sx={{  }}>
										{moment(moment(signatureDetails[0].startDate).add(6, 'd')).format('ddd MM/DD')}
									</TableCell>
								) : null}
								<TableCell align={'center'} sx={{ }}>
									{'Total'}
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>{chargeItems(signatureDetails)}</TableBody>
					</Table>
				</TableContainer>
				<Spacer vertical/>

				{/* Stuff below the table */}
				<Typography className={classes.darkModeHelp} variant={'body2'} >{'Services and quantities are preliminary and are subject to change during the final review by the project manager.'}</Typography>
				<Spacer vertical/>

				<Alignment row flex>

					<Alignment column >
						{/* Work Note */}
						{signatureRequest.workNote !== null ?
							<LabeledItem label={"Work Summary"} labelProps={{variant: 'h6'}} item={signatureRequest.workNote} 
							/>
						: null}

						{/* Previous signatures */}
						{signedRequests.length !== 0 ? 
							<Typography className={classes.darkModeHelp} variant={'h6'} fontWeight={'bold'}>{'Signature History'}</Typography>
						: null}
						{otherSignatures}
					</Alignment>

					{/* Signature box in the same row if its not mobile */}
					{deviceInfo.isMd ? <Alignment row flex>
					<Alignment column sx={{margin: '0 20px auto auto'}}>
						<Button
							color={'primary'}
							sx={{padding: '6px', minWidth: '40px'}} 
							onClick={() => setSignatureOpen(true)}
						><Draw/></Button>

						{signatureDataUrl !== null ? 
							<Button
								color={'secondary'}
								sx={{marginTop: '10px', padding: '6px', minWidth: '40px'}} 
								onClick={() => setSignatureDataUrl(null)}
							><Delete/></Button>
						: null}
					</Alignment>
					
					
					{/* Signature box */}
					<Alignment column sx={{width: '400px'}}>
						
						{signatureDataUrl !== null ? 
							<Box component={'img'} src={signatureDataUrl} sx={{width:'180px', height: '90px'}} />
						: 
							<TextField 
								helperText={'Please sign your first and last name above.'}
								required
								spellCheck={false}
								size={'medium'}
								onChange={handleNameChange}
								value={signedName}
								className={classes.signatureTextfield}
								InputProps={{style: {fontFamily: 'brush script mt', fontSize: 40} }}
							/>
						}


						{/* Signer name and email*/}
						<LabeledItem label={"Name:"} item={signatureRequest.name} row/>

						<LabeledItem label={"Email:"} item={signatureRequest.email} row/>

						<FormControlLabel disabled={signatureRequest.email === ''} className={classes.darkModeHelp} control={<Checkbox checked={toEmail} onClick={emailClicked} />} label={'Email Copy'} />
					</Alignment>
			</Alignment> : null}
				</Alignment>

				{/* Signature box below if device is mobile */}
				{!deviceInfo.isMd ? 
				<Alignment column flex sx={{ marginTop: '20px'}}>
					<Alignment row>
						<Button
							color={'primary'}
							sx={{padding: '6px', minWidth: '40px'}} 
							onClick={() => setSignatureOpen(true)}
						><Draw/></Button>

						{signatureDataUrl !== null ? 
							<Button
								color={'secondary'}
								sx={{marginLeft: '10px', padding: '6px', minWidth: '40px'}} 
								onClick={() => setSignatureDataUrl(null)}
							><Delete/></Button>
						: null}
					</Alignment>
					
					
					{/* Signature box */}
					<Alignment column flex sx={{marginTop: '10px'}}>
						
						{signatureDataUrl !== null ? 
							<Box component={'img'} src={signatureDataUrl} sx={{width:'180px', height: '90px'}} />
						: 
							<TextField 
								helperText={'Please sign your first and last name above.'}
								required
								spellCheck={false}
								size={'medium'}
								onChange={handleNameChange}
								value={signedName}
								className={classes.signatureTextfield}
								InputProps={{style: {fontFamily: 'brush script mt', fontSize: 40} }}
							/>
						}


						{/* Signer name and email*/}
						<LabeledItem label={"Name:"} item={signatureRequest.name} row/>

						<LabeledItem label={"Email:"} item={signatureRequest.email} row/>

						<FormControlLabel disabled={signatureRequest.email === ''} className={classes.darkModeHelp} control={<Checkbox checked={toEmail} onClick={emailClicked} />} label={'Email Copy'} />
					</Alignment>
			</Alignment> 
			: null}

				</DialogContent>
			</Alignment>

			<Spacer vertical size={'10px'} />

			<Alignment rowReverse>

				<Spacer horizontal size={'15px'} />

				<Button
					variant={'contained'}
					onClick={() => onSubmit()}
					disabled={signatureDataUrl === null ? signedName === '' : false}
				>{'Submit'}</Button>

				<Spacer horizontal size={'10px'} />

				{isPopup() ? 
				<Button color={'secondary'} variant={'contained'} sx={{ marginLeft: 'auto' }} onClick={() => window.close()}>
					{'Exit'}
				</Button>
				: null}
			</Alignment>

			<Spacer vertical size={'15px'} />

			{/* Mobile Signature Dialog */}
			{!deviceInfo.isMd ? 
			<Dialog fullScreen style={{margin:0, padding:0}} open={signatureOpen} onClose={handleSignatureClose}>
					<div style={{position:'absolute', width:'100%', height:'100vh', margin:0, padding:0}}>
						<SignatureCanvas
							canvasProps={{width: width-10, height:height-10}}
							ref={(ref) => {{sigCanvas = ref;}}}
							backgroundColor={'#ffffff'}
							minWidth={2}
						></SignatureCanvas>
					</div>

					<div style={{position:'absolute', left:'0px', right:width-100, bottom:'50px', margin:0, padding:0}} >
						<Alignment column>			
							{/* <Fab onClick={handleSignatureClear} variant={'extended'} color={'secondary'} style={{transform:'rotate(.25turn)'}}>Clear</Fab>
							<Spacer vertical size={'60px'}/> */}
							<Fab onClick={handleSignatureClose} variant={'extended'} color={'secondary'} style={{transform:'rotate(.25turn)'}}>Cancel</Fab>
							<Spacer vertical size={'60px'}/>
							<Fab onClick={handleSignatureConfirm} variant={'extended'} color={'primary'} style={{transform:'rotate(.25turn)'}}>Ok</Fab>
						</Alignment>
					</div>										
			</Dialog>

			:

			// Desktop Signature Dialog
			<Dialog maxWidth={'lg'} fullWidth={true} open={signatureOpen} onClose={handleSignatureClose}>
	
				<DialogContent sx={{justifyContent:'center', alignSelf:'center'}}>
					<Box sx={{justifyContent:'center', alignSelf:'center', border:2}}>
					<SignatureCanvas
					 	canvasProps={{width:900, height:450}}
					 	ref={(ref) => {{sigCanvas = ref;}}}
					 	backgroundColor={'#ffffff'}
						minWidth={2}
					 >
					</SignatureCanvas>
					</Box>
				</DialogContent>

	  			<DialogActions>
				    {/* <Button onClick={handleSignatureFillText} color={'primary'}>Fill Text</Button> */}
				    <Button onClick={handleSignatureClear} color={'secondary'} sx={{marginRight: 'auto'}} >Clear</Button>
        		  	<Button onClick={handleSignatureClose} color={'secondary'}>Cancel</Button>
        		  	<Button onClick={handleSignatureConfirm}>Ok</Button>
        		</DialogActions>
	 		</Dialog> }

			<SignatureCanvas
				canvasProps={{width:900, height:450, hidden: true}}
				ref={(ref) => {{sigCanvas = ref;}}}
				backgroundColor={'#ffffff'}
			/>

		</>
	);
};
