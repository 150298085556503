import { ClientLocation } from '@imas/api/client';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { useMuiFormContext } from '@imas/mui-form';
import { ClientVContactPrimaryPhoneNumbersGrid } from '../../ClientContactSearch/ClientSearchView/ClientVContactPrimaryPhoneNumbersGrid';

export interface LocationContactsProps {
	cLocation?: ClientLocation | null;
};

export const LocationContacts = memo(({ cLocation }: LocationContactsProps) => {
	//use form context
	const { isLoading } = useMuiFormContext();

	return (
		<ClientVContactPrimaryPhoneNumbersGrid
			clientId={isLoading ? undefined : cLocation === null ? -1 : cLocation?.clientId}
			locationId={cLocation?.id}
			alternateBody={cLocation === null ? (<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until location is saved."}</Typography>) : undefined}
		/>
	);
});