import { UnwrappedApi, useApi, useLoadApi } from '@imas/api';
import { 
	ServiceOrderWorkPlaceReview, ServiceOrderWorkPlaceReviewEmployee,
	CreateServiceOrderWorkPlaceReviewEmployee, UpdateServiceOrderWorkPlaceReviewEmployee
} from '@imas/api/service-order';
import { Alignment } from '@imas/components/layout';
import { Typography, Checkbox, FormControlLabel, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Employee, EmployeeBasics, GetEmployees } from '@imas/api/employee';
import { useServiceOrderWorkPlaceReviewEmployees } from '@imas/api/data-hooks';
import { memo, useCallback, useEffect, useMemo, useState, useImperativeHandle, forwardRef } from 'react';
import Enumerable from 'linq';
import { VirtualizedSelectList } from '@imas/utils/virtualization';
import { useDialog, useConfirmationDialog } from '@imas/utils/dialog';
import { useSelector } from 'react-redux';
import { getEmployeeData } from '@imas/redux';

interface ReviewEmployeeRowProps {
	reviewEmployee: ServiceOrderWorkPlaceReviewEmployee;
	employee: EmployeeBasics;
	currentEmployee: Employee;
	update: (review: ServiceOrderWorkPlaceReviewEmployee, attested: boolean) => Promise<void>;
};

//review employee row
const ReviewEmployeeRow = ({ reviewEmployee, employee, currentEmployee, update }: ReviewEmployeeRowProps) => {
	// if the row is in a loading state
	const [loading, setLoading] = useState<boolean>(false); 

	//handle check click
	const handleChange = useCallback(() => {
		//set loading
		setLoading(true);

		//update the value of attested
		update(reviewEmployee, !reviewEmployee.attested)
		.then(() => setLoading(false))
		.catch(() => setLoading(false));
	}, [reviewEmployee, update, setLoading]);

	//set loading to false when review changes
	useEffect(() => {
		//set loading false
		setLoading(false);
	}, [reviewEmployee, setLoading]);

	return (
		<FormControlLabel
			label={`${employee.name}`}
			control={<Checkbox disabled={loading || reviewEmployee.employeeId !== currentEmployee.employeeId} checked={reviewEmployee.attested} onChange={handleChange}/>}
			sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
		/>
	);
};

const EmployeeImporter = ({ employees, onClose }: { employees: EmployeeBasics[]; onClose: (ids: EmployeeBasics[] | null) => void; }) => {
	//selected employee ids
	const [selected, setSelected] = useState<int[]>([]);
	
	return (
		<Dialog open onClose={() => onClose(null)} fullWidth maxWidth={"xs"}>
			<DialogContent>
				<Alignment column sx={{ height: '50vh' }}>
					<VirtualizedSelectList
						items={employees}
						getItemText={(x) => `${x.name}`}
						multiple
						selectedModel={selected}
						onSelectedModelChange={(model) => setSelected(model)}
						getSelectionProperty={(x) => x.id}
						checkbox
						size={"small"}
					/>
				</Alignment>
			</DialogContent>
			<DialogActions>
				{/* Cancel Button */}
				<Button
					color={"secondary"}
					startIcon={<CloseIcon/>}
					onClick={() => onClose(null)}
				>{"Cancel"}</Button>

				{/* Add Selected Button */}
				<Button
					disabled={selected.length < 1}
					startIcon={<AddIcon/>}
					onClick={() => {
						//get records from selected ids
						const selectedEmployees = Enumerable.from(employees)
							.join(selected, x => x.id, x => x, (employee) => employee)
							.toArray();
						
						//call close with records
						onClose(selectedEmployees);
					}}
				>{"Add"}</Button>
			</DialogActions>
		</Dialog>
	);
};

export interface WorkPlaceReviewEmployeesProps {
	review: ServiceOrderWorkPlaceReview;
};

export interface WorkPlaceReviewEmployeesRef {
	loading: boolean;
};

export const WorkPlaceReviewEmployees = memo(forwardRef<WorkPlaceReviewEmployeesRef, WorkPlaceReviewEmployeesProps>(({ review }, ref) => {
	//use dialog & confirmation dialog
	const [dialog] = useDialog();
	const [confirm] = useConfirmationDialog();
	
	//use APIs
	const createReviewEmployee = useApi(CreateServiceOrderWorkPlaceReviewEmployee);
	const updateReviewEmployee = useApi(UpdateServiceOrderWorkPlaceReviewEmployee);

	//get current employee info
	const currentEmployee = useSelector(getEmployeeData);

	//get all employees
	const { data: allEmployees } = useLoadApi(GetEmployees, [], []);
	
	//get list of employees currently added to the work place review 
	const [reviewEmployees] = useServiceOrderWorkPlaceReviewEmployees(review.id);

	//reviewEmployeesList
	const reviewEmployeesList = useMemo(() => {
		if (!allEmployees || !reviewEmployees) return;

		return Enumerable.from(reviewEmployees)
			.join(allEmployees, x => x.employeeId, x => x.id, (reviewEmployee, employee) => ({ reviewEmployee, employee }))
			.orderByDescending(x => x.reviewEmployee.attested)
			.thenBy(x => x.employee.name)
			.toArray();
	}, [allEmployees, reviewEmployees]);

	//get a list of employees not included in the displayEmployees list, also exclude inactive employees
	const notAddedEmployees = useMemo(() => {
		if (!allEmployees || !reviewEmployeesList) return;

		return Enumerable.from(allEmployees)
			.except(Enumerable.from(reviewEmployeesList).select(x => x.employee), x => x.id)
			.where(x => x.active && x.needsTime)
			.toArray();
	}, [allEmployees, reviewEmployeesList]);

	//imperative handle
	useImperativeHandle(ref, () => ({
		loading: reviewEmployeesList === undefined,
	}), [reviewEmployeesList]);

	//callback for handling updates to rows
	const handleUpdate = useCallback(async (review: ServiceOrderWorkPlaceReviewEmployee, attested: boolean): Promise<void> => {
		//confirm the user wans to attest
		if (attested) {
			const accepted = await confirm({
				prompt: "I have read this workplace safety review and understand the hazards nearby and the mitigation measures that will be taken.",
				confirmText: "Yes",
				cancelText: "No"
			});

			//if the user did not accept do not update the attested value
			if (!accepted) return;
		}

		//perform update
		await updateReviewEmployee(review.id, {
			...review,
			attested
		});
	}, [confirm, updateReviewEmployee]);

	return (
		<Alignment sx={{ marginTop: '10px', marginBottom: '20px' }}>
			<Alignment row>
				<Typography variant={"h6"} sx={{ fontWeight: 'bold' }}>{"Crew Members"}</Typography>

				{/* Add Employee's not in the list. */}
				<Button
					size={"small"}
					disabled={!notAddedEmployees || (review.reviewedByEmployeeId !== currentEmployee?.employeeId)}
					startIcon={<AddIcon/>}
					onClick={async () => {
						if (!notAddedEmployees) return;

						//open dialog
						const selectedEmployees = await dialog<EmployeeBasics[] | null>((close) => (
							<EmployeeImporter
								employees={notAddedEmployees}
								onClose={close}
							/>
						));

						//if selectedEmployees is null do nothing
						if (selectedEmployees === null) return;

						//add employees to review
						for (const employee of selectedEmployees) {
							try {
								await createReviewEmployee({
									workPlaceReviewId: review.id,
									employeeId: employee.id,
									attested: false,
								});
							} catch {}
						}
					}}
					sx={{ marginLeft: 'auto' }}
				>{"Add"}</Button>
			</Alignment>

			{/* List of Employees */}
			<Alignment column>
				{reviewEmployeesList && currentEmployee ? reviewEmployeesList.map(({ reviewEmployee, employee }) => (
					<ReviewEmployeeRow 
						reviewEmployee={reviewEmployee}
						employee={employee}
						currentEmployee={currentEmployee} 
						update={handleUpdate}
					/>
				)) : null}
			</Alignment>
		</Alignment>
	);
}));