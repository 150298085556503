import { Alignment } from '@imas/components/layout';
import { openPopup } from '@imas/utils/misc';
import { AppBar, Box, Typography, Paper, Button, Link} from '@mui/material';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { MuiFormSubmit, useMuiFormRef } from '@imas/mui-form';
import { ForgotPassword as TForgotPassword } from '@imas/api/auth/types/ForgotPassword';
import { useApi } from '@imas/api';
import { ForgotPassword } from 'src/api/misc/misc';
import { useCallback } from 'react';
import ForgotPasswordForm from './ForgotForm/ForgotPasswordForm';
import { isPopup } from '@imas/utils/misc';
import { Navigate, useNavigate, useParams } from 'react-router';
import forgotLoginStyles from './ForgotLoginStyles';

export const openForgotPasswordPage = (): Window | null => {
	return openPopup(`/forgot-password`, { name: 'Forgot Password', height: 850, width: 1500,});
};

export const ForgotPass= () => {
	document.title = "IMAS - Forgot Password";
	const { classes, deviceInfo } = forgotLoginStyles();

    const showSnackbar = useAutomaticSnackbar();
	const isPopupWindow = isPopup();
	const navigate = useNavigate();

	const [formRef, onFormRef] = useMuiFormRef<TForgotPassword, void>();
	const forgotPassword = useApi(ForgotPassword);
	const submit = useCallback( async ( data: TForgotPassword) => {
		//display notification
	
		const close = showSnackbar("Sending email...", {variant: "info", persist: true});
	
		try{
	
			await forgotPassword(data.userEmail);
	
			close();
			showSnackbar("Email sent.", {variant: 'success'}, true);
			
		} catch(e){
			 close(e);
			 showSnackbar("Invalid Credentials.", {variant: 'error'}, true);
			 throw navigate("/invalid-credentials");}
	
	
	
	
	  }, []);
    return (
       <>
       <Alignment column> <AppBar position={"static"} color={"primary"} sx={{height: '56px'}}>
					<Alignment row sx={{margin: 'auto 0 auto 0'}}>

						{/*  PNL Image */}	
						<Box component={"img"} sx={{margin: '0 10px 0 10px', maxWidth: '75px', width: '-webkit-fill-available' }} src={"/images/Logo-Transparent.png"}/>
						<Typography variant={'h5'} sx={{margin: 'auto 0 auto 10px'}}>{'Recover Password'}</Typography>

					</Alignment>
					
				</AppBar>
				<Alignment 
				column 
				flex 
				sx={{
					margin: ['2vh 8%', '5vh 8%', '10vh 8%'],
					width: '-webkit-fill-available',
					maxWidth: '400px',
					alignSelf: 'center',
				}}
			>

			<Paper className={classes.loginContainer} sx={{ padding: '25px', marginTop: '20px' }}>
				<Typography align="center">
					Please enter your work email below and we'll send an email with a link to reset your password.
				</Typography>

			<ForgotPasswordForm
				ref={onFormRef}
				/>

				
					<MuiFormSubmit
				formRef={formRef}
				onSubmit={submit}
				onSuccessfulSubmit={() => {
					window.close();
				}}
				disablePreventNavigation
				sx={{marginBottom: '10px'}}
				size={'small'}
			>{"Continue"}

			</MuiFormSubmit> 
			<Typography align='center'>
                    <Link sx={{cursor: 'pointer', marginTop: '15px'}} onClick={() => {
                 window.close();}}>{"Cancel"}
                    </Link>
                </Typography>
			
				 
			</Paper>

			</Alignment>
				
				
				</Alignment>
       </>
    );
};