import { DataHookResult, createUpdateHandler } from './_utils';
import { useHub, useOnHub } from '../_hubs';
import { useLoadApi, UseLoadApiOptionsFromApi } from '../_internals';
import { GetEmployeeTimeSheetSummary } from '../employee';
import { useEffect, useMemo } from 'react';
import { Moment } from 'moment';
import moment from 'moment';

type UseEmployeeTimeSheetSummaryArgs = { employeeId: int; fromDateNumber?: int; toDate?: Moment | Date; };

export const useEmployeeTimeSheetSummary = ({ employeeId, fromDateNumber, toDate }: UseEmployeeTimeSheetSummaryArgs, options?: UseLoadApiOptionsFromApi<typeof GetEmployeeTimeSheetSummary>) => {
	//load data
	let fromDate = moment().subtract(fromDateNumber, "days");
    const api = useLoadApi(GetEmployeeTimeSheetSummary, [employeeId, fromDate, toDate], [employeeId, fromDateNumber], options);

	//load signalR hub
	const hub = useHub("/time-sheets");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data and filter out irellivent data
	useOnHub(hub, "UpdateSummary", (type, record) => {
		if (record.employeeId !== employeeId) return;
		update(type, record);
	}, [employeeId]);

	return [api.data, api] as DataHookResult<typeof api>;
};