import { ChangePassword } from "@imas/api/auth/types/ChangePassword";
import React, { memo, useMemo, useContext, useCallback, useEffect } from 'react';
import { Editable, FormTextField, MuiForm, MuiFormPartial, MuiFormRef, useMuiForm } from "@imas/mui-form";
import { InputAdornment, Tooltip, Typography, List, ListItem  } from "@mui/material";
import { useMuiWatch } from "@imas/mui-form";
import { Alignment } from "@imas/components/layout";
import { Info, Info as InfoIcon } from "@mui/icons-material";
interface ChangePasswordProps extends MuiFormPartial {
    submitted: boolean
}

const ChangePasswordForm = React.memo(React.forwardRef<MuiFormRef<ChangePassword>, ChangePasswordProps>(({submitted, ...props}, ref) => {
    const { loading, viewOnly, ...otherProps} = props;
    const dontAllowChars = /[ \[\]{}(),.;?@`'"]/;
    const specialChars = /[*!$#%^:&|_\/\\~+\-=<>]/;
    const lowerCaseLetter = /[a-z]/;
    const upperCaseLetter = /[A-Z]/;
    const number = /\d/;
    

    const defaultValues: Editable<ChangePassword> = {
        password: null,
        confirmPassword: null
    };

    const { control, muiFormProps } = useMuiForm({
        defaultValues,
        validator: ({password, confirmPassword}, error) => {
            if(password !== confirmPassword) {
                error("confirmPassword", "pattern");
            }
            if(confirmPassword === null) {
                error("confirmPassword", "required");
            }
            if(password === null) {
                error("password", "required");
                return;
            }
            if(confirmPassword === null) {
                error("confirmPassword", "required");
                return;
            }
            if(password.toLowerCase().includes("password") || password.toLowerCase().includes("username")) {
                error("password", "invalid");
                return;
            }
            if(password?.length < 8) {
                error("password", "minLength");
                return;
            }
            if (dontAllowChars.test(password)) {
                error("password", "pattern");
                return;
            }           
            if (!specialChars.test(password)) {
                error("password", "pattern");
                return;
            }

            if (!lowerCaseLetter.test(password) && !upperCaseLetter.test(password)) {
                error("password", "The password must include at least one letter.");
                return;
            }
            if (!upperCaseLetter.test(password)) {
                error("password", "The password must include at least one upper case letter.");
                return;
            }
            if (!number.test(password)) {
                error("password", "The password must include at least one number.");
                return;
            }
            if(password !== confirmPassword) {
                error("password", "pattern");
                return;
            }
            return {password, confirmPassword};

        }
    });

	const [password] = useMuiWatch({ control, name: ["password"]});
    const [passwordConfirm] = useMuiWatch({control, name: ["confirmPassword"]});

    const passwordHelperText: React.ReactNode = useMemo(() => {
        if(password !== null)
        {
            if (password.toLowerCase().includes("password") || password.toLowerCase().includes("username")) {
                return "Invalid password";
            }
            if(password?.length < 8)
            {
                return (
                    "The password must be at least 8 characters long."
                );
            }
            if (dontAllowChars.test(password)) {
                return (
                    "The password includes at least one invalid character."
                );
            }   
            if (!number.test(password)) {
                return (
                    "The password must include at least one number."
                );
            }
            if (!specialChars.test(password)) {
                return (
                    "The password does not include at least one special character: * ! $ # % ^ : & | _ / \ ~ + - = < >"
                );
            }
            if (!lowerCaseLetter.test(password) && !upperCaseLetter.test(password)) {
                return (
                    "The password must include at least one letter."
                );
            }
            if (!upperCaseLetter.test(password)) {
                return (
                    "The password must include at least one upper case letter."
                );
            }
            return undefined;
        }

        return "Password Required";
    }, [ password]);

    const confirmPassword: React.ReactNode = useMemo(() => {
        if(password !== null)
        {
            if(passwordConfirm !== null)
            {
                if(password !== passwordConfirm)
                {
                    return (
                        "Passwords do not match"
                    );
                }
            }

        }
        return undefined;

    }, [passwordConfirm, password]);

    return (
        <MuiForm
        loading={loading}
            viewOnly={viewOnly}
            {...muiFormProps}
            {...otherProps}
            ref={ref}>
               
                <FormTextField
                control={control}
                disabled={submitted}
                name={'password'}
                label={"New Password"}
                sx={{margin: '10px 10px 10px 10px',}}
                type={'password'}
                helperText={passwordHelperText}
                InputProps={{
                    endAdornment: <InputAdornment position='end'><Tooltip arrow placement="right" 
                    title={<Typography sx={{fontSize: '14px'}}>
                        {"The new password must have the following requirements:"}
                        <List>
                            <ListItem>
                                {"- At least 8 characters long (no spaces)"}

                            </ListItem>
                            <ListItem>
                                {"- At least one letter"}

                            </ListItem>
                            <ListItem>
                                {"- At least one capital letter"}

                            </ListItem>
                            <ListItem>
                                {"- At least one number"}

                            </ListItem>
                            <ListItem>
                                {"- At least one special character: * ! $ # % ^ : & | _ / \ ~ + - = < >"}
                            </ListItem>
                            <ListItem>
                                {"- No other special character"}
                            </ListItem>
                            
                            
                            
                        </List>
                    </Typography>}>
                        <InfoIcon sx={{fontSize: '11px', cursor: 'help'}}/></Tooltip></InputAdornment>,
                  }}
                />
               

           
                <FormTextField
                control={control}
                disabled={submitted}
                name={'confirmPassword'}
                label={"Confirm Password"}
                sx={{margin: '10px 10px 10px 10px',}}
                type={'password'}
                helperText={confirmPassword}

                />
            </MuiForm>
    );
}));

export default ChangePasswordForm;