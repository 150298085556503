import { Button, Dialog, DialogTitle, DialogTitleProps, DialogActions, DialogContent, Typography, TypographyProps, DialogProps, Theme, SxProps, TextField, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close as CloseIcon, Check as CheckIcon, Add as AddIcon } from '@mui/icons-material';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@imas/styles';
import { useLoadApi } from '@imas/api';
import { GetAllClients, GetClientsLocationVContactPrimaryPhoneNumbers, vContactPrimaryPhoneNumber, Client } from '@imas/api/client';
import { TypedGridApiRef, TypedEditDataGridProps, useTGridApiRef, TypedGridColumnDefs, TDataGrid } from '@imas/data-grid';
import { DebouncedAutocomplete } from '../../inputs';
import Enumerable from 'linq';
import { Alignment, Spacer } from '../../layout';
import { useClients, useClientVContactPrimaryPhoneNumbers } from '@imas/api/data-hooks';
import { VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps, CLIENTS_AUTOCOMPLETE_VIRTUALIZER } from '@imas/utils/virtualization';
import { openClientLocationContactEditor } from 'src/pages/editors';
import { ContactListGrid, CONTACT_LIST_GRID_API_REF } from '../../data-grids/ContactListGrid';

export interface ContactDialogProps extends Omit<DialogProps, "open" | "onClose" | "title"> {
	/** Title of Dialog Box & Props for DialogTitle component */
	title?: string | React.ReactNode;
	titleProps?: Partial<DialogTitleProps>;
    /** If the dialog is open */
    open: boolean;

	/** Initial Client Id to filter to */
	initialClientid?: int;

    /** If the user must press the cancel button to close the dialog. */
    forceButtonCancel?: boolean;

	/** Sending the grid ApiRef */
	apiRef: CONTACTS_GRID_API_REF;

	showContactLists?: boolean;
	listApiRef?: CONTACT_LIST_GRID_API_REF;
    
    /** Confirmation Callback, if async then the dialog will stay open in the loading state until the callback resolves. */
    onInsertAndExit: () => Promise<void> | void;

	/** Insert Callback */
    onInsert: () => Promise<void> | void;
    
    /** Cancelation Callback */
    onCancel: () => void;
};

const ClientListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<VirtualizedListboxAdpater 
			{...props} 
			ref={ref} 
			renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<Client>) => {
				const row = data[index];
			
				return (
					<Typography 
						component={"li"} 
						{...row.props} 
						noWrap 
						style={{ ...style, top: (style.top as number) + 8 }}
					>{row.option.name}</Typography>
				);
			}}
		/>
	);
});

export interface vContactPrimaryPhoneNumberRM extends vContactPrimaryPhoneNumber {
};

//define the column order
export type CONTACTS_GRID_COLUMNS = ["name", "locationName", "mobilePhoneNumber", "email"];

//define the grid api ref type
export type CONTACTS_GRID_API_REF = TypedGridApiRef<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumberRM, never, never>;

//define grid props type
export type CONTACTS_GRID_PROPS = TypedEditDataGridProps<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumberRM, never, CONTACTS_GRID_COLUMNS, never>

const useStyles = makeStyles()(() => ({
	title: {
		fontWeight: 'bold',
	},
}));

export const ContactDialog = (props: ContactDialogProps) => {

	//contact dialog grid apiRef
	const defaultListApiRef: CONTACT_LIST_GRID_API_REF = useTGridApiRef();

    //props
    const { 
        title, titleProps, open, sx, initialClientid, apiRef, listApiRef,
        onInsertAndExit, onInsert, onCancel,

        ...dialogProps
    } = props;
    const forceButtonCancel = props.forceButtonCancel ?? false;
	const showList = props.showContactLists ?? false;
	const contactListApiRef = props.listApiRef ?? defaultListApiRef;

	//component styling
	const { classes, cx } = useStyles();

	//state variables
	const [selectedClient, setSelectedClient] = useState<Client>();

    //if onInsertAndExit returns a promise then this state will be set to true, will be set back to false on promise resolve/reject
    const [confResolving, setConfResolving] = useState(false);

	//get all clients
	const [clients] = useClients({mutate: (results) => results.filter(x => x.active)});

	//get vContactPrimaryPhoneNumber records
	const [unfilteredContacts, {clear: clearContacts}] = useClientVContactPrimaryPhoneNumbers(selectedClient !== undefined ? selectedClient.id : -1, { disabled: selectedClient === undefined || selectedClient.id === -1 });

	//filtered Contacts
	const contacts = useMemo(() => {
		if (!unfilteredContacts) return undefined;
		return unfilteredContacts.filter(x => x.active);
	}, [unfilteredContacts]);

	//when clients loads or initialClientId changes, set selected client to the given clientid 
	useEffect(() => {
		if (!clients) return;
		if (!initialClientid) {
			setSelectedClient(undefined);
			clearContacts();
		}
		setSelectedClient(clients.find(x => x.id === initialClientid));
	}, [clients, initialClientid]);

	//memoized grid column defs
	const columns = useMemo((): TypedGridColumnDefs<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumberRM, never, CONTACTS_GRID_COLUMNS, "row"> => {
		return [
			{ field: 'name', headerName: 'Contact:', width:150},
			{ field: 'locationName', headerName: 'Location:', width:200},
			{ field: 'mobilePhoneNumber', headerName: 'Mobile:', width: 120},
			{ field: 'email', headerName: 'Email:', width: 260},
		];
	}, []);	

    return (
        <Dialog 
            {...dialogProps}
            open={open}
			fullWidth
			maxWidth={'lg'}
            onClose={() => {
                //if confResolving is true prevent closing dialog
                if (confResolving) return;
                
                //if forceButtonCancel is true the then the user must use the cancel button to close the dialog
                if (forceButtonCancel) return;
                
                //call onCancel
                onCancel();
            }}
			sx={sx}
        >
			{title ? <DialogTitle {...titleProps} className={cx(classes.title, titleProps?.className)}>{title}</DialogTitle> : null} 

            <DialogContent>
				<Alignment row sx={{marginTop: '5px'}}>
					<Alignment column sx={{width: showList ? 800 : '100%', height: 500}}>
						{/* Client Filter */}
						<Autocomplete
							value={selectedClient}
							options={clients ?? []}
							onChange={(event, client) => { 
								if (client !== null) setSelectedClient(client);
							}}
							getOptionLabel={(client) => client.name}
							size={"x-small"}
							renderInput={(props) => <TextField label={"Client"} {...props} size={"x-small"}/>}
							renderOption={(props, value) => <li title={value.name} {...props}>{value}</li>}
							fullWidth
						
							//add virtualization
							{...CLIENTS_AUTOCOMPLETE_VIRTUALIZER}
							ListboxComponent={ClientListboxAdapter}
						/>

						<Spacer vertical/>
						<TDataGrid<vContactPrimaryPhoneNumber, vContactPrimaryPhoneNumberRM, never, CONTACTS_GRID_COLUMNS, never>
							apiRef={apiRef}
							editMode={undefined}
							disableAutosizer
							checkboxSelection
							disableColumnMenu
							//disableColumnReorder
							//disableColumnResize
							loading={!contacts && !!selectedClient}
							rows={contacts ?? []}
							columns={columns}
						/>
					</Alignment>

					{showList ? 
					<>
					<Spacer horizontal/>
					<Alignment column sx={{width: 330}}>
						<ContactListGrid apiRef={contactListApiRef}/>
					</Alignment>
					</>
					: null}
					
				</Alignment>
            </DialogContent>
            <DialogActions>
				<Button
                    //color={"secondary"}
					startIcon={<AddIcon/>}
                    variant={"contained"}
                    disabled={confResolving}
                    onClick={() => openClientLocationContactEditor({ clientId: initialClientid })}
					sx={{marginRight: 'auto'}}
                >{"New Contact"}</Button>

                <Button
                    color={"secondary"}
                    variant={"contained"}
                    disabled={confResolving}
                    onClick={onCancel}
                >{"Exit"}</Button>

				{/* Loading Button for Insert */}
                {/* <LoadingButton
                    color={"primary"}
                    variant={"contained"}
                    startIcon={<CheckIcon/>}
                    loading={confResolving}
                    loadingPosition={"start"}
                    onClick={() => {
                        const result = onInsert();
                    }}
                >{"Insert"}</LoadingButton> */}
                
                {/* Loading Button for Insert & Exit */}
                <LoadingButton
                    color={"primary"}
                    variant={"contained"}
                    startIcon={<CheckIcon/>}
                    loading={confResolving}
                    loadingPosition={"start"}
                    onClick={() => {
                        const result = onInsertAndExit();

                        if (result instanceof Promise) {
                            //setConfResolving to true
                            setConfResolving(true);

                            //set confResolving to false when the promise is fulfilled/rejected
                            result.finally(() => setConfResolving(false));
                        }
                    }}
                >{"Insert & Exit"}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};