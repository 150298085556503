import { axiosData, SourceGetter } from "@imas/api/_internals";
import { ReportingType } from "./types"; 
import { ReportingTypeForm as ReptForm } from "./types/reportingTypes/ReportingTypesForm";
import { objectToFormData } from "@imas/api/_internals";

const REPORTING_TYPES_URL = "api/proposals/reporting-details"

export const GetReportingTypes = (getSource: SourceGetter) => {
    return async (): Promise<ReportingType[]> => {
        return await axiosData({
            method: 'get',
            url: `${REPORTING_TYPES_URL}/reportTypes`,
            cancelToken: getSource().token,
        });
    };
};

export const GetReportingTypesById = (getSource: SourceGetter) => {
    return async (id: int): Promise<ReportingType> => {
        return axiosData({
            method: 'get',
            url: `${REPORTING_TYPES_URL}/reportTypesByID/${id}`,
            cancelToken: getSource().token
        });
    };
};

/* Update a record */
export const UpdateReportingTypes = (getSource: SourceGetter) => {
    return async (Type: ReptForm, id: int): Promise<ReportingType> => {
        return await axiosData({
            method: 'put',
            url: `${REPORTING_TYPES_URL}/reportTypes/${id}`,
            data: objectToFormData(Type),
            cancelToken: getSource().token,
        });
    };
};

/* Create a record */
export const CreateReportingTypes = (getSource: SourceGetter) => {
    return async (Type: ReptForm): Promise<ReportingType> => {
        return await axiosData({
            method: 'post',
            url: `${REPORTING_TYPES_URL}/reportTypes`,
            data: objectToFormData(Type),
            cancelToken: getSource().token,
        });
    };
};

export const DeleteReportingTypes = (getSource: SourceGetter) => {
    return async (id: int): Promise<ReportingType[]> => {
        return axiosData({
            method: 'delete',
            url: `${REPORTING_TYPES_URL} ${id}`,
            cancelToken: getSource().token
        });
    };
};