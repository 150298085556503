import { Alignment } from '@imas/components/layout';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Paper, Typography, Button } from '@mui/material';
import { Download as DownloadIcon, Replay as ReloadIcon } from '@mui/icons-material';
import { useLoadApi } from '@imas/api';
import { GetCurrentVersion } from 'src/api/misc/misc';
import { updateIMAS } from '@imas/utils/misc';
import useInterval from '@use-it/interval';

/** Handles Webpack Chunk loading errors, shows a custom error page which prompts the user to update or refresh the page. */
const ChunkErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
	if (error.name !== "ChunkLoadError") {
		//reset the error boundry
		resetErrorBoundary();

		//re throw non ChuckLoadErrors
		throw error;
	}

    //check what the most recent version of the app is
    const { data: currentVersion, call: refreshCurrentVersion } = useLoadApi(GetCurrentVersion, [], []);
	
    //refresh the currentVersion every 1 minute to check for an update unless
    useInterval(() => refreshCurrentVersion(), 1000 * 60 * 1);

	return (
		<Paper sx={{ margin: '10px', display: 'flex', flex: '1' }}>
			<Alignment column sx={{ margin: 'auto', maxWidth: '400px' }}>
				<Typography variant={"h4"}>{"Page not found."}</Typography>
				<Typography variant={"body1"}>{"The page you are looking for could not be loaded, this is most likely because you are not using the latest version of IMAS. Please try updating to the latest version, and if this issue persists contact an administrator."}</Typography>
				
				{/* Actions */}
				<Alignment rowReverse sx={{ "& > *:not(:last-child)": { marginLeft: '10px' } }}>
					{/* Update Button */}
					{currentVersion ? `${currentVersion.major}.${currentVersion.minor}.${currentVersion.revision}` !== IMAS.VERSION ? (
						<Button
							variant={"contained"}
							color={"primary"}
							onClick={() => {
								//call update
								updateIMAS();
							}}
							startIcon={<DownloadIcon/>}
						>{"Update"}</Button>
					) : null : null}

					{/* Reload Page Button */}
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => window.location.reload()}
						startIcon={<ReloadIcon sx={{ transform: 'scaleX(-1)' }}/>}
					>{"Reload"}</Button>
				</Alignment>
			</Alignment>
		</Paper>
	);
};

export const ChunkErrorBoundary = ({ children }: React.PropsWithChildren<{}>) => {
	return (
		<ErrorBoundary
			onError={(error, {}) => {
				//if the error is not a ChunkLoadError then re-throw it
				if (error.name !== "ChunkLoadError") throw error;
			}}
			FallbackComponent={ChunkErrorFallback}
		>{children}</ErrorBoundary>
	);
};