import React from "react";
import { Box } from "@mui/material";
import { JsonFile } from "src/api/types/api/JsonFile";

interface Props {
  offersignatureImage: JsonFile | undefined;
}

export const SignatureDisplay: React.FC<Props> = ({ offersignatureImage }) => {
  const imageSrc = offersignatureImage
    ? `data:image/jpeg;base64,${offersignatureImage.content}`
    : null;

  return (
    <Box
      sx={{
        width: 250,
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={`${offersignatureImage?.name} Signature`}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
        />
      ) : (
        "No signature available"
      )}
    </Box>
  );
};
