import React from 'react';
import { MuiForm, MuiFormRef, useMuiForm , Editable, FormTextField, FormNumberSelect, FormCheckbox } from '@imas/mui-form';


export interface TWebLoginForm {
    sitenum: int;
    username: string;
    password: string;
	remember: boolean;
};

const defaultValues: Editable<TWebLoginForm> = {
    sitenum: 2,
    username: null,
    password: null,
	remember: false,
};

const WebLoginForm = React.memo(React.forwardRef<MuiFormRef<TWebLoginForm>, {triggerSubmit: () => void}>(({triggerSubmit}, ref) => {

    //form hook
    const { control, muiFormProps } = useMuiForm({ 
        defaultValues,
        validator: ({ sitenum, username, password, remember }, error) => {
            //ensure required fields are required
            if (!sitenum) error("sitenum", "required");
            if (!username) error("username", "required");
            if (!password) error("password", "required");

            //ensure type safety
            if (!sitenum) return;
            if (!username) return;
            if (!password) return;

            return { sitenum, username, password, remember };
        },
    });


    //form data
    return (
        <MuiForm
            {...muiFormProps}
            ref={ref}
        >
            {/* Site Select */}
            <FormNumberSelect
                name={"sitenum"}
                control={control}
                label={"Site"}
				size={"small"}
                required
                options={[
                    { value: 2, label: "Phoenix" },
                    //{ value: 3, label: "Tuscon" },
                ]}
				inputProps={{ autocomplete: undefined, tabIndex: -1 }}
                sx={{ marginBottom: '20px' }}
            />

            {/* Name Field */}
            <FormTextField
                name={"username"}
                control={control}
                label={"Username"}
				size={"small"}
                required
                tabIndex={-1}
				inputProps={{ autocomplete: undefined, tabIndex: -1 }}
                sx={{ marginBottom: '20px' }}
            />

            {/* Password Field */}
            <FormTextField
                name={"password"}
                type={"password"}
                control={control}
                label={"Password"}
				size={"small"}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        triggerSubmit();
                    }
                }}
                required
                inputProps={{ tabIndex: -1 }}
                sx={{ marginBottom: '20px' }}
            />

			{/* Stay Signed In Checkbox */}
            <FormCheckbox
                name={"remember"}
                control={control}
                label={"Keep me signed in on this device"}
				size={"small"}
                required
                tabIndex={-1}
                sx={{ marginBottom: '20px' }}
            />
        </MuiForm>
    );
}));

export { WebLoginForm };