import { useSnackbar, OptionsObject, SnackbarKey } from "notistack";
import { useCallback, useEffect, useRef } from "react";

/**
 * 	A custom notistack hook which will clear the snackbars it shows when the component it is in unmounts. Prevents persistant snackbars from hanging around
 * 	longer than the lifetime of the component that created them.
 *
 *  Does not provide the closeSnackbar function like useSnackbar, instead provides a close callback when the enque function is called as the return value,
 * 	this helps reduce boilerplate, and in the case that the original api is desired then use useSnackbar.
 */
export const useAutomaticSnackbar = () => {
	//snackbar functions
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	//reference to the keys
	const keysRef = useRef<SnackbarKey[]>([]);

	//use effect for notification cleanup
	useEffect(() => {
		return () => {
			//for each stored snackbar key
			for (let key of keysRef.current) {
				//close the snackbar
				closeSnackbar(key);
			}
		};
	}, [closeSnackbar]);

	//function for closing a snackbar
	const close = useCallback((key: SnackbarKey) => {
		//close the snackbar
		closeSnackbar(key);

		//remove it from the list of keys
		keysRef.current = keysRef.current.filter((x) => x !== key);
	}, [closeSnackbar]);

	//function for enqueueing a snackbar which returns a callback which can be used to close the snackbar
	const enqueue = useCallback((msg: string, options: OptionsObject, autoHide: boolean = true) => {
		//show snackbar
		const key = enqueueSnackbar(msg, options);

		//save key if autoHide is true
		if (autoHide) keysRef.current = [...keysRef.current, key];

		//return a callback which can be used to close the snackbar, takes an optional error param and will throw the value if provided
		return (error?: any) => {
			//close the snackbar
			close(key);

			//return provided error
			return error;
		};
	}, [enqueueSnackbar, close]);

	//return actions
	return enqueue;
};
