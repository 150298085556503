import { ConfirmationDialog, ConfirmationDialogProps } from "@imas/components/dialogs";
import React, { useCallback } from "react";
import { useDialog } from "./useDialog";

export interface ShowConfirmationProps extends Pick<ConfirmationDialogProps, "title" | "titleProps" | "prompt" | "promptProps" | "body" | "forceButtonCancel" | "confirmText" | "confirmIcon" | "cancelText" | "cancelIcon"> {
	onConfirm?: (() => void) | (() => Promise<void>);
	container?: Element | null; 
};

export type ShowConfirmationFn = (props: ShowConfirmationProps) => Promise<boolean>;

export function useConfirmationDialog(): [ShowConfirmationFn] {
	//useDialog hook
	const [openDialog] = useDialog();

	//confirm function, displays a confirmation dialog, returns a promise which resolves true if the user confirmed & onConfirm ran without an error, false if they canceled or onConfirm threw an error
	const confirm = useCallback<ShowConfirmationFn>((props) => {
		const { container, onConfirm, forceButtonCancel, ...dialogProps } = props;
		
		return openDialog<boolean>((close) => (
			<ConfirmationDialog
				open
				{...dialogProps}
				forceButtonCancel={forceButtonCancel ?? true}
				onConfirm={async () => {
					try {
						//call onConfirm and await the resolution
						if (onConfirm) await onConfirm();

						//resolve true because onConfirm returned without an error
						close(true);
					} catch {
						//resolve false because onConfirm threw an error
						close(false);
					}
				}}
				onCancel={() => {
					//resolve false
					close(false);
				}}
				container={container}
				BackdropProps={container ? { style: { position: 'absolute' } } : undefined}
				style={container ? { position: 'absolute' } : undefined}
			/>
		));
	}, [openDialog]);
	
	return [confirm];
};