import { XSkeleton } from './XSkeleton';
import { Paper, PaperProps } from '@mui/material';

export interface LoadingPaperProps extends PaperProps {
	loading?: boolean;
};

export const LoadingPaper = ({ loading, ...paperProps }: LoadingPaperProps): JSX.Element => {
	return (
		<>
			<XSkeleton 
				variant={"rectangular"} 
				rounded 
				sx={paperProps.sx} 
				style={{ ...paperProps.style, display: loading ? undefined : 'none' }}
			/>
			<Paper 
				{...paperProps}
				style={{ ...paperProps.style, display: loading ? 'none' : undefined }}
			/>
		</>		
	);
};