import { ApiConfig, mapApi } from "@imas/api";
import { Site, vWorkClass } from './types';
import { axiosData, SourceGetter } from "@imas/api";

const SITES_BASE_URL = "/api/system/sites";

/*  */
export const GetSites = mapApi((): ApiConfig<Site[]> => ({
	method: 'get',
	url: `${SITES_BASE_URL}`,
}));

/*  */
export const GetSiteWorkClasses = mapApi((siteId?: int): ApiConfig<vWorkClass[]> => ({
	method: 'get',
	url: `${SITES_BASE_URL}/${siteId ?? -1}`,
}));

export const UpdateRSO = (getSource: SourceGetter) => {
    return async (siteNum: int, editorID: int, employeeID: int, primaryRSO: boolean): Promise<boolean> => {
        return await axiosData({
            method: 'put',
            url: `${SITES_BASE_URL}/${siteNum}/${editorID}/${employeeID}/${primaryRSO}`,
            cancelToken: getSource().token,
        });
    };
};
