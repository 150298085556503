export function getGeolocation(options?: PositionOptions): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        //get user location
        navigator.geolocation.getCurrentPosition(position => {
            resolve(position);
        }, (e) => {
            reject(e);
        }, options);
    });
};
