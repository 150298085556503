import { FormatAddressProps } from "./formatAddress";

interface FormatAddressHTMLProps extends FormatAddressProps {};

export function formatAddressHTML({ address1, address2, city, state, zip, country } : FormatAddressHTMLProps): React.ReactNode {
	if (`${address1 ?? ""}${city ?? ""}${state ?? ""}${zip ?? ""}${country ?? ""}` === "") return "None";

	return (
		<>
			{(address1 ?? "") !== "" ? <>{address1}<br/></> : null}
			{`${city ?? ""}${state ?? ""}${zip ?? ""}` !== "" ? <>{`${city ?? "[CITY]"}, ${state ?? "[STATE]"} ${zip ?? "ZIP"}`}<br/></> : null}
			{(country ?? "") !== "" ? <>{country}<br/></> : null}
		</>
	);
};