import { useState, useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import axios, { AxiosError } from 'axios';
import { WrappedApiFunction, ApiFunctionResult, ApiFunctionResultType } from './types';
import { useLoadApi, UseLoadApiOptions } from './useLoadApi';
import { useCancelableApi } from './useApi';


/**
 * usePromiseApi hook
 * 
 * Functions like useLoadApi but returns a promise to the result of the API call as the data value.
 */
export const usePromiseApi = <A extends any[], R extends ApiFunctionResult<any>>(wrappedApiFunction: WrappedApiFunction<A, R>, apiParams: A, deps?: any[], options?: UseLoadApiOptions<R>) => {
	//use api
	const { api, cancel } = useCancelableApi(wrappedApiFunction);

	//apiParams ref
	const apiParamsRef = useRef(apiParams);
	apiParamsRef.current = apiParams;

	//create a data promise
	const createPromise = useCallback((): Promise<ApiFunctionResultType<R>> => {
		return new Promise<ApiFunctionResultType<R>>((resolve) => {
			//call api
			const result = api(...apiParamsRef.current);

			//check if the result is a CacheResult
			if ('api' in result && 'cache' in result) {
				//hande api promise result
				result.api.then(x => resolve(x));
			} else {
				//handle just a promise
				result.then(x => resolve(x));
			}
		});
	}, [api]);

	//current data promise
	const [promise, setPromise] = useState<Promise<ApiFunctionResultType<R>>>(createPromise());

	//update the promise when deps change
	useEffect(() => setPromise(createPromise()), [setPromise, createPromise, ...(deps ?? [])]);

	//wrap call, so when call is called then a new promise is created
	const callWrapped = useCallback(() => {
		setPromise(createPromise());
	}, [setPromise, createPromise]);

    return {
        //a promise which will resolve to the result of the API call
        data: promise,

        //call the api again manually with the currently provided deps
        call: callWrapped,

        //cancel any ongoing API calls
        cancel,
    };
};