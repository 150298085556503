import { Moment } from 'moment';
import { LabSampleLog, ServiceOrderSample, TServiceOrderSampleForm } from './types';
import { SourceGetter, axiosData } from '@imas/api';
import moment from 'moment';

//service order attachments base url
const LAB_ITEMS_URL = "/api/service-orders/lab-items";

/* Get records for the lab item tracker */
export const GetLabSampleLogs = (getSource: SourceGetter) => {
    return async (startDate?: Moment, endDate?: Moment): Promise<LabSampleLog[]> => {
		return axiosData({
			method: 'get',
			url: `${LAB_ITEMS_URL}`,
			cancelToken: getSource().token,
            params: {
                fromDate: (startDate ?? moment().subtract(3, "years")).format(),
                toDate: (endDate ?? moment()).format(),
            },
		});
    };
};