import { Button, ButtonGroup } from '@mui/material';
import { Menu } from '@imas/constants/menus';
import { Link, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export interface DynamicSubNavigationProps {
    menu: Menu;
};

const DynamicSubNavigation = ({ menu }: DynamicSubNavigationProps) => {
    //get the current location
    const location = useLocation();

    //match current location to a menu category
    const category = useMemo(() => {
        //find a menu category which matches the current url
        return menu.find(x => location.pathname.startsWith(x.url)) ?? menu[0];
    }, [location, menu]);

    return (
        // A button group which contains 
        (<ButtonGroup variant={"text"} sx={{ color: "text.primary" }} color={"inherit"}>
            {category.options.filter(x => !x.mobileOnly).map((option, index) => {
                //button for individual menu items
                return (
					<Button
						variant={"text"}
						color={"inherit"}
						sx={{ borderColor: "#FFF" }}
						key={index}
						component={Link}
						to={option.url}
					>{option.name}</Button>
				);
            })}
        </ButtonGroup>)
    );
};

export default DynamicSubNavigation;