import { Button, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@imas/styles';
import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router';
import { useApi } from '@imas/api';
import { SubmitFeedback } from 'src/api/misc/misc';
import { FeedbackCategory } from 'src/api/types/api/misc/misc';
import { Spacer } from '@imas/components/layout';
import { PageFooter } from '@imas/components/navigation';
import { FeedbackForm } from '@imas/components/misc';

const useStyles = makeStyles()(theme => ({
    sorryText: {
        textAlign: 'center'
    },
    container: {
        display: 'flex',
        margin: 'auto',
        padding: '20px',
        flexDirection: 'column'
    }
}));

/**
 * Wrapper for App content which handles all errors and displays a Feedback form bug reporting.
 */
export const FeedbackErrorBoundary = ({ children }: React.PropsWithChildren<{}>) => {
    //use react router navigate
    const navigate = useNavigate();

    //use APIs
    const submitFeedback = useApi(SubmitFeedback);

    //variable which stores the componentStack string returned from the onError function
    //const [componentStack, setComponentStack] = useState<string | null>(null);

    //component styling 
    const {classes, theme } = useStyles();
    const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

    //submitAutomaticReport
    const submitAutomaticReport = (error: Error, info: { componentStack: string }) => {
        //automatically submit report this error to 'imasissue@pnltest.com' even if the user does not report it.

        //create the error report obj
        const report = {
            url: window.location.href,
            error: {
                message: error.message,
                name: error.name,
                stack: error.stack
            },
            compStack: info.componentStack
        };

        //construct report body
        let reportBody = "";
        reportBody += "<p>Automatically generated bug report.<p><br/>";
        reportBody += "<p>" + JSON.stringify(report, null, 4) + "<p/>";

        //submit the automatic feedback
        submitFeedback(FeedbackCategory.BugReport, reportBody, []);
    };

    //FallbackRenderer
    const FallbackRenderer = ({ error, resetErrorBoundary }: FallbackProps) => {
        //reset function
        const reset = () => {
            //navigate to home
            navigate("/dashboard");
            
            //reset the error bound
            resetErrorBoundary();
        };

        return (
            <>
                {/* Error Reporting */}
                <div className={classes.container}>
                    {/* Error Message */}
                    <Typography 
                        variant={"h5"} 
                        className={classes.sorryText}
                        color={"textPrimary"}
                    >{"Sorry! The application has crashed. Please tell us below what happened to help us fix the issue."}</Typography>
                    <Spacer vertical size={"20px"}/>

                    {/* Feedback Form */}
                    <FeedbackForm
                        defaultCategory={FeedbackCategory.BugReport}
                        forceDefault
                        onSubmit={() => reset()}
                    />
                    <Spacer vertical size={"10px"}/>
                    
                    {/* Skip Feedback Button */}
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        onClick={reset}
                    >{"Skip Feedback"}</Button>
                </div>

                {/* Page Footer */}
                {isSmall ? null : <PageFooter/>}
            </>
        );
    };

    return (
        <ErrorBoundary 
			fallbackRender={FallbackRenderer}
			onError={submitAutomaticReport}
		>{children}</ErrorBoundary>
    );
};