import { Typography, Paper, PaperProps, createFilterOptions, AutocompleteProps, PopperProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

const StringListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<VirtualizedListboxAdpater 
			{...props} 
			ref={ref} 
			renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<string>) => {
				const row = data[index];
			
				return (
					<Typography 
						component={"li"} 
						{...row.props} 
						noWrap 
						style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}
					>{row.option}</Typography>
				);
			}}
		/>
	);
});

export interface CreateStringAutocompleteVirtualizerProps {
	width?: int;
	dense?: boolean;
};

export const createStringAutocompleteVirtualizer = ({ width, dense }: CreateStringAutocompleteVirtualizerProps): NonNullable<Pick<AutocompleteProps<string, false, false, false, "div">, "filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> => {
	const lisboxPadding = dense ? 0 : 8;
	const itemSize = dense ? 24 : undefined;
	
	const StringListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
		return (
			<VirtualizedListboxAdpater 
				{...props} 
				ref={ref}
				listboxPadding={lisboxPadding}
				itemSize={itemSize}
				renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<string>) => {
					const row = data[index];
					
					return (
						<Typography
							component={"li"} 
							{...row.props} 
							noWrap
							sx={{ width: width === null ? undefined : `${width}px`, padding: dense ? '0 8px!important' : undefined, fontSize: dense ? '0.875rem' : undefined,  }}
							style={{ ...style, top: (style.top as number) + lisboxPadding }}
						>{row.option}</Typography>
					);
				}}
			/>
		);
	});

	const StringPaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
		return (
			<Paper 
				{...props}
				ref={ref} 
				style={{ minWidth: typeof width !== 'number' ? undefined : width + 24 }}
			/>
		);
	});
	
	return {
		disableListWrap: true,
		PopperComponent: VirtualizedListboxPopper, //(props: PopperProps) => <VirtualizedListboxPopper {...props} style={{ ...props.style, minWidth: props?.style?.width, width: undefined }}/>, //sx={{ minWidth: width }}/>,
		ListboxComponent: StringListboxAdapter,
		PaperComponent: StringPaper,
		renderOption: (props, option) => ({ props, option }),
		renderGroup: (params) => params,
	};
};

export const STRING_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<string, false, false, false, "div">, "filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {

	//virtualization props
	disableListWrap: true,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: StringListboxAdapter,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};