import { useCallback, useEffect } from 'react';
import { useApi, useLoadApi } from '@imas/api';
import { 
	GetClientLocationContact, CreateClientLocationContact, UpdateClientLocationContact, DeleteClientLocationContact,
	ClientLocationContact, ClientLocationContactForm as TClientLocationContactForm
} from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { MuiFormSubmit, MuiFormSubmitProps, useMuiFormRef } from '@imas/mui-form';
import { isPopup } from '@imas/utils/misc';
import { ResourceCollaboratorGroup, RESOURCES } from '@imas/utils/resource';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ExitToApp, Save as SaveIcon, Delete as DeleteIcon, FindInPage as FindInPageIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate, Navigate } from 'react-router-dom';
import { sendEditorUpdate, useEditorState } from '@imas/utils/editor';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { openEditorWindow } from '@imas/utils/editor';
import { ClientLocationContactForm } from './ClientLocationContactForm/ClientLocationContactForm';
import { getUserRoles } from '@imas/redux';
import { useSelector } from 'react-redux';
import { openClientLocationContactReportViewer } from './ClientLocationContactReportViewer';

export type ClientLocationContactEditorStateParams = { locationId?: string; clientId?: string; };

export const openClientLocationContactEditor = ({ contactId, locationId, clientId }: { contactId?: int | null; locationId?: int | null; clientId?: int | null; }): Window | null => {
	return openEditorWindow<ClientLocationContactEditorStateParams>(
		IMAS.PAGES.CLIENTS.LOCATIONS.CONTACTS(), 
		{ id: contactId ?? null, locationId: locationId ? `${locationId}` : undefined, clientId: clientId ? `${clientId}` : undefined, }, 
		{ height: 900, width: 1000, name: contactId ? `Contact #${contactId} Edit Form` : locationId ? `New Contact for Location #${locationId} Form` : clientId ? `New Contact for Client #${clientId} Form` : "New Contact Form" }
	);
};

export const ClientLocationContactEditor = () => {
	//page title
    document.title = "IMAS - Contact Editor";

	//editor state
	const [state, setState] = useEditorState<ClientLocationContactEditorStateParams>({ url: IMAS.PAGES.CLIENTS.LOCATIONS.CONTACTS() });

    //check if the user is an admin
    const { admin: isAdmin } = useSelector(getUserRoles);

	//use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

	//use navigate
	const navigate = useNavigate();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

    //use APIs
    const createContactApi = useApi(CreateClientLocationContact);
    const updateContactApi = useApi(UpdateClientLocationContact);
    const deleteContactApi = useApi(DeleteClientLocationContact);

    //useFormRef for contact form
    const [form, onForm, formRef] = useMuiFormRef<TClientLocationContactForm, ClientLocationContact>();

    //load the specified contact if state is a number
    const { data: contact, error: contactError, clear: clearContact } = useLoadApi(GetClientLocationContact, [state.id ?? -1], [state.id], { disabled: state.id === null });

    //reset form to blank for a new item
    useEffect(() => {
        //clear any loaded contact record
        clearContact();

        if (state.id === null) {
            formRef.current?.reset({
                locationId: state.locationId ? isNaN(Number(state.locationId)) ? null : Number(state.locationId) : null,
				name: null,
				active: true,
				title: null,
				first: null,
				preferredFirst: null,
				middle: null,
				last: null,
				suffix: null,
				jobTitle: null,
				department: null,
				email: null,
				ccEmail: null,
				notes: null,
				history: null,
				correspondencetype: 0,
				signaturemethodtype: 0
            });
        }
    }, [state, clearContact, formRef]);

    //reset the form to editing the loaded contact
    useEffect(() => {
        if (contact === undefined) return;
        else {
            formRef.current?.reset({
                ...contact,
            });
        }
    }, [contact, formRef]);

    //create a new contact record
    const createContact = useCallback(async (data: TClientLocationContactForm) => {
        //progress notification
        const close = showSnackbar("Creating contact...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await createContactApi(data);

            //close notification
            close();

			//send editor update
			sendEditorUpdate("/clients/locations/contacts", "CREATE", record);

            //return the new record
            return record;
        } catch (e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [createContactApi, showSnackbar]);

    //update a contact record
    const updateContact = useCallback(async (contactId: int, data: TClientLocationContactForm) => {
        //progress notification
        const close = showSnackbar("Updating contact...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await updateContactApi(contactId, data);
            
            //close notification
            close();
			
			//send editor update
			sendEditorUpdate("/clients/locations/contacts", "UPDATE", record);

            //return the updated record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [updateContactApi, showSnackbar]);

	//delete a contact record
	const deleteContact = useCallback(async (contactId: int) => {
        //progress notification
        const close = showSnackbar("Deleting contact...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await deleteContactApi(contactId);
            
            //close notification
            close();

			//send editor update
			sendEditorUpdate("/clients/locations/contacts", "DELETE", record);

            //return the deleted record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [deleteContactApi, showSnackbar]);

	//submit handler
	const onSubmit = useCallback<MuiFormSubmitProps<TClientLocationContactForm, ClientLocationContact>["onSubmit"]>(async (data) => {
		//check if a contact record is loaded, if not create a new record
		if (!contact) {
			return await createContact(data);
		} 
		//otherwise update the loaded record
		else {
			return await updateContact(contact.id, data);
		};
	}, [contact, createContact, updateContact]);

    //close form if there was an error loading the contact
    if (contactError !== undefined) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

    return (
        <Alignment column flex sx={{ margin: '10px' }}>
			<Alignment column flex>
				<ClientLocationContactForm
					key={state.id ?? "new"}
					clientId={state.clientId ? isNaN(Number(state.clientId)) ? null : Number(state.clientId) : null}
					locationId={state.locationId ? isNaN(Number(state.locationId)) ? null : Number(state.locationId) : null}
					contact={state.id === null ? null : contact}
					loading={state.id !== null && contact === undefined}
					ref={onForm}
				/>
			</Alignment>
            
            <Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
                {/* Save & Exit Button (Only show in a popup.) */}
                {isPopup() ? (
					<MuiFormSubmit
						formRef={form}
						onSubmit={onSubmit}
						onSuccessfulSubmit={() => {
							//call window.close() to close the popup window
							window.close();
						}}
						startIcon={<ExitToApp/>}
						size={'small'}
					>{"Save & Exit"}</MuiFormSubmit>
				) : null}

				{/* Save Button */}
				<MuiFormSubmit
                    formRef={form}
                    onSubmit={onSubmit}
                    onSuccessfulSubmit={(newContact) => {
						//if a new record was just created then change to edit the new record
						if (!contact) setState({ id: newContact.id }, { replace: true });
					}}
                    startIcon={<SaveIcon/>}
					size={'small'}
                >{"Save"}</MuiFormSubmit>

				{/* Resource Collaborators for this Contact Form */}
				{contact ? <ResourceCollaboratorGroup resource={RESOURCES.clientLocationContact(contact.id)} noPadding/> : null}

				{/* View report pdf */}
				<Button
					variant={"contained"}
					color={"primary"}
					disabled={contact === undefined}
					onClick={() => openClientLocationContactReportViewer(contact?.id ?? -1)}
					startIcon={<FindInPageIcon/>}
					sx={{marginRight: '140px'}}
					size={'small'}
				>{'Preview'}</Button>

				{/* Delete Button */}
				<Button
					variant={"contained"}
					color={"secondary"}
					disabled={contact === undefined}
					onClick={async () => {
						//ensure a contact is loaded
						if (!contact) return;
						if (!isAdmin) return showSnackbar("You must be an admin to delete a contact.", { variant: 'error' });

						//confirm that the user wants to delete this contact
						const deleted = await confirm({
							prompt: `Are you sure you would like to delete the following contact: '${contact.name}'?`,
							confirmText: "Delete",
							confirmIcon: <DeleteIcon/>,
							onConfirm: async () => {
								//call API
								await deleteContact(contact.id);
							}
						});

						//if contact was successfully deleted
						if (deleted) {
							//if this window is a popup close the window
							if (isPopup()) window.close();

							//otherwise navigate to the contacts & contacts page
							else navigate(IMAS.PAGES.CLIENTS_AND_CONTACTS.SEARCH(), { replace: true });
						}
					}}
					startIcon={<DeleteIcon/>}
					size={'small'}
					sx={{ marginRight: 'auto' }}
				>{"Delete"}</Button>
			</Alignment>
		</Alignment>
	);
};