import { Theme } from "@mui/material";
import { Css, CSSObject, Cx } from "tss-react";
import { useDeviceInfo } from "./DeviceInfoContext";
import { makeStyles } from "./makeStyles";
import { DeviceInfo } from "./types";

//custom makeStyles deviceInfo from the generated useStyles
export const makeDeviceStyles = <Params extends void = void, RuleNameSubsetReferencableInNestedSelectors extends string = never>(params?: { name?: string | Record<string, unknown> | undefined; } | undefined) => {
    return <RuleName extends string>(cssObjectByRuleNameOrGetCssObjectByRuleName: Record<RuleName, CSSObject> | ((theme: Theme, params: Params, classes: Record<RuleNameSubsetReferencableInNestedSelectors, string>) => Record<RuleNameSubsetReferencableInNestedSelectors | RuleName, CSSObject>)) => {
        const useStyles = makeStyles<Params, RuleNameSubsetReferencableInNestedSelectors>(params)(cssObjectByRuleNameOrGetCssObjectByRuleName);

        return (params: Params): {
            deviceInfo: DeviceInfo;
            classes: Record<RuleName, string>;
            theme: Theme;
            css: Css;
            cx: Cx;
        } => {
            return {
                deviceInfo: useDeviceInfo(),
                ...useStyles(params)
            };
        };
    };
};