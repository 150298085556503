import { vWorkClass } from 'src/api/types/api/misc/vWorkClass';
import { HourType } from 'src/api/types/api/misc/HourType';
import axios from 'axios';
import { objectToFormData, SourceGetter } from  '@imas/api';
import { JsonFile } from 'src/api/types/api/JsonFile';
import { FeedbackCategory, PaymentTerm } from 'src/api/types/api/misc/misc';
import { FileTables } from '@imas/api/files/types';
import { Moment } from 'moment';
import { GetAvailableVehsResult } from '../types/api/misc/vehicles/GetAvailableVehsResult';
import { ForgotUsernameRequest } from '@imas/api/auth/types/ForgotUser';

/**
 *  Version APIs 
 */
export { _GetCurrent as GetCurrentVersion } from './version';
export { _GetHistory as GetVersionHistory } from './version';
export { _GetChanges as GetVersionChanges } from './version';
export { _UpdateChanges as UpdateVersionChanges} from './version';
/**
 * Get a  FileInfoResult given the file's table and pathLocator
 */
//  export const GetFileInfo = (getSource: SourceGetter) => {
//      return (table: FileTables, filePathLocator: HierarchyId): Promise<FileInfoResult | null> => {
//         //get a source for the request 
//         const source = getSource();

//         //make API call and return a promise which resolves into the result
//         return new Promise<FileInfoResult | null>((resolve, reject) => {
//             axios({
//                 method: 'post',
//                 url: '/api/files/path',
//                 data: {
//                     table: table,
//                     path: filePathLocator 
//                 },
//                 cancelToken: source.token
//             }).then((response) => {
//                 if (response.status === 204) return resolve(null);

//                 //resolve promise
//                 resolve(response.data);
//             }).catch(e => reject(e));
//         });
//     };
// };

// /**
//  *  Get a FileViewResult given a parent folder and job folder location
//  */
// export const GetFolderView = (getSource: SourceGetter) => {
//     return (table: FileTables, folderPathLocator: HierarchyId): Promise<FileViewResult> => {
//         //get a source for the request 
//         const source = getSource();

//         //make API call and return a promise which resolves into the result
//         return new Promise<FileViewResult>((resolve, reject) => {
//             axios({
//                 method: 'get',
//                 url: '/api/files/folder-view',
//                 params: {
//                     table: table,
//                     folder: folderPathLocator 
//                 },
//                 cancelToken: source.token
//             }).then((response) => {
//                 //resolve promise
//                 resolve(response.data);
//             }).catch(e => reject(e));
//         });
//     };
// };

// //download a file as a blob
// export const GetFile = (getSource: SourceGetter) => {
//     return (pathLocator: HierarchyId, table: FileTables): Promise<Blob> => {
//        //get a source for the request 
//        const source = getSource();

//        //make API call and return a promise which resolves into the result
//        return new Promise<Blob>((resolve, reject) => {
//            axios({
//                method: 'get',
//                url: '/api/file',
//                responseType: 'blob',
//                params: {
//                    table: table,
//                    path: pathLocator 
//                },
//                cancelToken: source.token
//            }).then((response) => {
//                //resolve promise
//                resolve(response.data);
//            }).catch(e => reject(e));
//        });
//    };
// };

//download pdf file as a string
export const GetPdfString = (getSource: SourceGetter) => {
    return (pathLocator: HierarchyId, table: FileTables): Promise<string> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<string>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/json-file',
               params: {
                   table: table,
                   path: pathLocator 
               },
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

//get a list of PaymentTerms
export const GetPaymentTerms = (getSource: SourceGetter) => {
    return (): Promise<PaymentTerm[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<PaymentTerm[]>((resolve, reject) => {
           axios({
               method: 'get',
               url: '/api/payment-terms',
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

//get a list of HourTypes
export const GetHourTypes = (getSource: SourceGetter) => {
    return (date: DateString): Promise<HourType[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<HourType[]>((resolve, reject) => {
           axios({
                method: 'get',
                url: '/api/hour-types',
                params: {
                    date: date
                },
                cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};

//get a list of vWorkClasses
export const GetWorkClasses = (getSource: SourceGetter) => {
    return (showAll?: boolean): Promise<vWorkClass[]> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<vWorkClass[]>((resolve, reject) => {
           axios({
                method: 'get',
                url: '/api/work-classes',
                params: {
                    showAll: showAll ?? false
                },
                cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(response.data);
           }).catch(e => reject(e));
       });
   };
};



/**
 * Submit user feedback.
 */
 export const SubmitFeedback = (getSource: SourceGetter) => {
    return (category: FeedbackCategory, feedback: string, files: JsonFile[], autoGenerated: boolean = true): Promise<null> => {
       //get a source for the request 
       const source = getSource();

       //make API call and return a promise which resolves into the result
       return new Promise<null>((resolve, reject) => {
           axios({
               method: 'post',
               url: '/api/feedback',
               data: {
                   category: category,
                   feedback: feedback,
                   files: files,
                   auto: autoGenerated
               },
               cancelToken: source.token
           }).then((response) => {
               //resolve promise
               resolve(null);
           }).catch(e => reject(e));
       });
   };
};

export const ForgotUsername = (getSource: SourceGetter) => {
    return (email: String): Promise<null> => {
        const source = getSource();

        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: `/api/manage/forgot/username`,
                data: {
                    email: email
                },
                cancelToken: source.token,
                
            }).then((response) => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};


export const ForgotPassword = (getSource: SourceGetter) => {
    return (email: String): Promise<null> => {
        const source = getSource();

        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: `/api/manage/forgot/password`,
                data: {
                    email: email,
                    
                },
                cancelToken: source.token,
                
            }).then((response) => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};

export const ChangePassword = (getSource: SourceGetter) => {
    return (hash: String, password: String): Promise<int> => {
        const source = getSource();

        return new Promise<int>((resolve, reject) => {
            axios({
                method: 'post',
                url: `/api/manage/forgot/change`,
                data: {
                    hash: hash,
                    password: password,
                  
                },
                cancelToken: source.token,
                
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};




export const ApproveReportFeedbackEmail = (getSource: SourceGetter) => {
    return (feedbackID: int, preparedby: int, templateID: int, templatefeedbackpriority: int ): Promise<null> => {
        const source = getSource();

        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: `/api/manage/forgot/approve`,
                data: {
                    feedbackID: feedbackID,
                    preparedby: preparedby,
                    templateID: templateID,
                    templatefeedbackpriority: templatefeedbackpriority
                },
                cancelToken: source.token,


            }).then((response) => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));;
        });
    };
};

export const DeclineReportFeedbackEmail = (getSource: SourceGetter) => {
    return (templateresolution: String, preparedby: int,  templateID: int): Promise<null> => {
        const source = getSource();

        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: `/api/manage/forgot/decline`,
                data: {
                    templateresolution: templateresolution,
                    preparedby: preparedby,
                    templateID: templateID,
                },
                cancelToken: source.token,


            }).then((response) => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));;
        });
    };
};

