import { Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { Download as DownloadIcon, Close as CloseIcon } from '@mui/icons-material';
import useInterval from '@use-it/interval';
import React, { useEffect } from 'react';
import { useLoadApi } from '@imas/api';
import { GetCurrentVersion } from 'src/api/misc/misc';
import { snackbar, useAutomaticSnackbar } from '@imas/utils/snackbar';
import { useDialog } from '@imas/utils/dialog';
import { updateIMAS } from '@imas/utils/misc';

const UpdateHandler = React.memo(() => {
	//dialog hook
	const [dialog] = useDialog();

    //check what the most recent version of the app is
    const { data: currentVersion, call: refreshCurrentVersion } = useLoadApi(GetCurrentVersion, [], []); 

    //refresh the currentVersion every 30 minutes to check for an update unless
    useInterval(() => refreshCurrentVersion(), 1000 * 60 * 30);
    
    //use effect when currentVersion changes
    useEffect(() => {
        if (currentVersion === undefined) return;

		//current version string
		const CURRENT_VERSION = `${currentVersion.major}.${currentVersion.minor}.${currentVersion.revision}`;

        //check if the current version is the same as the downloaded app version
        if (CURRENT_VERSION !== IMAS.VERSION) {
			//show dialog
			dialog((close) => (
				<Dialog open onClose={() => close()}>
					<DialogContent>
						<Typography variant={"body1"}>
							{`Version ${CURRENT_VERSION} is now available.`}
						</Typography>
					</DialogContent>
					<DialogActions>
						{/* Ignore Update */}
						<Button
							variant={"contained"}
							color={"secondary"}
							onClick={() => {
								//call close
								close();
							}}
							startIcon={<CloseIcon/>}
						>{"Later"}</Button>
						
						{/* Install Update */}
						<Button
							variant={"contained"}
							color={"primary"}
							onClick={() => {
								//call update & close
								updateIMAS();
								close();
							}}
							startIcon={<DownloadIcon/>}
						>{"Update"}</Button>
					</DialogActions>
				</Dialog>
			));

            //show persistent update notification
            // showSnackbar(`An update is available.`, { 
            //     key: "update-available", 
            //     variant: "info", 
            //     persist: true, 
            //     action: (key) => (
            //         <>
            //             <Button color={"inherit"} onClick={update}>{"Install"}</Button>
            //             <Button color={"inherit"} onClick={() => snackbar.close(key)}>{"Later"}</Button>
            //         </>
            //     )
            // });
        }
    }, [currentVersion]);

    return null;
});

export { UpdateHandler };