import * as MuiGrid from "@mui/x-data-grid-pro";
import { TGDM, TGRM, TypedGridApiRef } from '@imas/data-grid';
import { v4 as uudi4 } from 'uuid';
import { Remote } from "mdi-material-ui";

//id of the persistent record used for adding new rows'
export const PERSISTENT_EDIT_ID = `PERSISTENT_EDIT_ROW-${uudi4()}`;

export const isChildOfPopper = (e: HTMLElement | EventTarget | null) => {
	let current: HTMLElement | null = e as HTMLElement | null; 
	
	while (current !== null && current !== undefined) {
		//check if the current element is a popper
		if (current?.classList?.contains('MuiPopover-root') || current?.classList?.contains('MuiDialog-root') || current?.classList?.contains('MuiModal-root')) {
			return true;
		}

		//this is for the date picker
		if (current?.classList?.contains('MuiInputBase-adornedEnd') || current?.classList?.contains('MuiCalendarPicker-root') || current?.classList?.contains('MuiDialogActions-root')) {
			return true;
		}

        // this is fot detail panels
        if (current?.classList?.contains('MuiDataGrid-detailPanel')) {
            return true;
        }

		//move to the next element up
		current = current?.parentElement;
	}

	return false;
};

// focus first the column of the persistent row
export const focusPersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">) => {
	if(apiRef.current === null) return;

    try {
        const newCurrentIndex = apiRef.current.getRowIndexRelativeToVisibleRows(PERSISTENT_EDIT_ID);
        apiRef.current.scrollToIndexes({ rowIndex: newCurrentIndex });
        
        const columns = apiRef.current.getAllColumns();
        apiRef.current.setCellFocus(PERSISTENT_EDIT_ID, columns[0].field);
    } catch {}
};

// returns if true if the persistent row exists on the grid
export const persistentRowExists = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">) => {
	if(apiRef.current === null) return false;
    try {
        return apiRef.current.getRow(PERSISTENT_EDIT_ID) !== null;
    } catch {return false;}
};

export const createPersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">, focus?: boolean) => {
	if(apiRef.current === null) return;

    // don't create a row that already exists
    //if (persistentRowExists(apiRef)) return;

    try {
        // //create the persistent edit row and set it to edit mode
        apiRef.current.updateRows([{ id: PERSISTENT_EDIT_ID }]);
        
        if (persistentRowExists(apiRef)) {
            // set row to edit mode if it is in view mode
            const mode = apiRef.current.getRowMode(PERSISTENT_EDIT_ID);
            if (mode === MuiGrid.GridRowModes.View) apiRef.current.startRowEditMode({ id: PERSISTENT_EDIT_ID });
        
            // focus the new row    
            if (focus) setTimeout(() => focusPersistentRow(apiRef));
        }
    } catch {}
};

// 3 useful functions [reset, remake, delete]

// delete the persistent row
export const deletePersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">) => {
	if(apiRef.current === null) return;

    // if there is no existing row we can't delete it 
    if (!persistentRowExists(apiRef)) return;

    try {
        //delete persistent row
        apiRef.current.updateRows([{ id: PERSISTENT_EDIT_ID, _action: 'delete' }]);
    } catch {}
};

// remake persistent row, delete + create
export const remakePersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">, focus?: boolean) => {
	if(apiRef.current === null) return;
    
    //delete persistent row
    //deletePersistentRow(apiRef);
    console.log('here');
    //re-create the persistent row
    createPersistentRow(apiRef, focus);

};

// resets the persistent row to default values (a remake if exist)
export const resetPersistentRow = (
    apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">,
    row: TGRM | null,
    focus?: boolean
  ) => {
    if (!apiRef.current || !row) return;
  
    // If there is no existing row, we can't reset it
    if (!persistentRowExists(apiRef)) return;
  
    // Clear the persistent row data
    clearPersistentRow(apiRef, row, focus);
  
    // Get the current row mode (edit or view)
    const mode = apiRef.current.getRowMode(PERSISTENT_EDIT_ID);
  
    if (mode === 'edit') {
      // Stop edit mode and force it into view mode
      apiRef.current.stopRowEditMode({
        id: PERSISTENT_EDIT_ID,
        ignoreModifications: true, // Ignore modifications to revert to the original row state
      });
  
      // Wait for the row to be stopped and transition to view mode
      setTimeout(() => {
        // Start edit mode again after the row has entered view mode
        apiRef.current.startRowEditMode({ id: PERSISTENT_EDIT_ID });
  
        // Optionally, focus on a particular cell if needed
        if (focus) {
          apiRef.current.setCellFocus(PERSISTENT_EDIT_ID, 'someColumnField');
        }
      }, 0); // Ensure the view mode transition completes before restarting edit mode
    } else {
      // If already in view mode, start edit mode directly
      apiRef.current.startRowEditMode({ id: PERSISTENT_EDIT_ID });
  
      // Optionally, focus on a particular cell if needed
      if (focus) {
        apiRef.current.setCellFocus(PERSISTENT_EDIT_ID, 'someColumnField');
      }
    }
  };
  
  
  

  export const clearPersistentRow = (
    apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">,
    row: TGRM,
    focus?: boolean
  ) => {
    if (!apiRef.current || !row) return;
  
    try {
      // Clear all row items by setting each field to null, excluding the id field
      const { id, ...clearedRow } = row;
      Object.keys(clearedRow).forEach((key) => {
        clearedRow[key] = null; // or set to "" based on your requirements
      });
  
      // Update the row with the cleared data
      apiRef.current.updateRows([{ id: PERSISTENT_EDIT_ID, ...clearedRow }]);
  
      // Optionally, set focus to the cleared row if needed
      if (focus) {
        apiRef.current.setCellFocus(PERSISTENT_EDIT_ID, 'someColumnField'); // Adjust the column field
      }
  
    } catch (error) {
      console.error("Error clearing persistent row:", error);
    }
  };