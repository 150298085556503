import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import moment from 'moment';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, usePrompt } from 'react-router-dom';
import { useApi } from '@imas/api';
import { CreateLightPole, UpdateLightPole } from 'src/api/misc/light-pole';
import { SlideUp } from '@imas/components/transitions';
import { XDialogTitle } from '@imas/components/mui';
import { LightPoleMeasurementFormData } from 'src/api/types/form/LightPoleMeasurementFormData';
import { fileToJson } from '@imas/utils/files';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { LightPoleMeasurement, vServiceOrderLightPole } from '../LightPoleMeasurementUtils';
import LightPoleMeasurementForm, { LightPoleMeasurementFormRef } from './LightPoleMeasurementForm/LightPoleMeasurementForm';
import { useDeviceInfo } from '@imas/styles';
import { useLoadApi } from '@imas/api';
const LightPoleMeasurementFormDialog = ({
    open,
    uniqueId,
    state,
    onSave,
    onCancel,
    pronum,
}: {
    open: boolean,
    uniqueId: int,
    state: LightPoleMeasurement | null | undefined,
    onSave: (lightPoles: LightPoleMeasurement) => void,
    onCancel: () => void,
    pronum: int,
}) => {
    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

	//use navigation
	const navigate = useNavigate();

    //use APIs
    const createLightPole  = useApi(CreateLightPole);
    const updateLightPole = useApi(UpdateLightPole);

    //get device info
    const deviceInfo = useDeviceInfo();

    //cache isSmall on component load
    const isSm = useMemo(() => deviceInfo.isSm, []);


    //saving state
    const [saving, setSaving] = useState<boolean>(false);

    //ref for MobileExpenseReportItemFormRef
    const [lightPoleMeasurementFormRef, lightPoleMeasurementItemFormRef] = useState<LightPoleMeasurementFormRef | null>(null);

    //ref callback
    const onLightPoleMeasurementFormRef = useCallback((node: LightPoleMeasurementFormRef | null) => {
        lightPoleMeasurementItemFormRef(node);
    }, []);

    //prevent navigation away from page if there are unsaved changes
    usePrompt("Are you sure you want to discard the changes?", open && !saving && (lightPoleMeasurementFormRef?.hasChanges ?? false));

    //reset saving state when state changes
    useEffect(() => { if (!open) setSaving(false); }, [state, open]);

    //function for creating a light pole light pole
    const create = useCallback((data: LightPoleMeasurementFormData) => {
        const date = data.date;

        //cancel if certain values are not provided
        if (date === null) return;


        //set enable saving state
        setSaving(true);  

        //progress notification
        const close = showSnackbar("Saving light pole data...", { variant: 'info', persist: true });
        
        (async () => {

            //make the request to add the hours for the day
            const record = await createLightPole(uniqueId, {
                ...data,
                date: date,
                file: null,
                mapNumber: data.mapNumber?.toString() ?? null,
            });
            
            //close notification
            close();

			//close measurement form dialog
			navigate(-1);

            //call onSave
            onSave(record);

			if (data.file !== null) {
				//get json file
            	const file = data.file ? typeof data.file === "string" ? null : await fileToJson(data.file) : null;
				//progress notification
				const close2 = showSnackbar("Saving image...", { variant: 'info', persist: true });

				//update the record to include the image
				const record2 = await updateLightPole(record.id, {
            	    ...data,
            	    date: date,
            	    file: file,
            	    mapNumber: data.mapNumber?.toString() ?? null,
            	});

            	//close notification
				close2();
                showSnackbar("Lightpole data and image saved successfully.", { variant: 'success' }, true);

            	//call onSave
				onSave(record2);
			}
			
        })().catch((e) => {
            //close notification
            close();

            //show error notification
            showSnackbar("Failed to save light pole: " + (e?.response?.data.toString() ?? "Unknown."), { variant: 'error' }, true);
            setSaving(false);
        });
    }, [showSnackbar, createLightPole, setSaving, uniqueId]);

    //function for creating a new light pole record
    const update = useCallback((lightPoleId: int, data: LightPoleMeasurementFormData) => {
        const date = data.date;

        //cancel if certain values are not provided
        if (date === null) return;

        //set enable saving state
        setSaving(true); 

        //progress notification
        const close = showSnackbar("Updating light pole...", { variant: 'info', persist: true });
        
        (async () => {
            //get json file
            const file = data.file ? typeof data.file === "string" ? null : await fileToJson(data.file) : null;

            //make the request to add the hours for the day
            const record = await updateLightPole(lightPoleId, {
                ...data,
                date: date,
                file: file,
                mapNumber: data.mapNumber?.toString() ?? null,
            });
            
            //close notification
            close();

			//close measurement form dialog
			navigate(-1);

            //call onSave
            onSave(record);

        })().catch((e) => {
            //close notification
            close();

            //show error notification
            showSnackbar("Failed to update light pole: " + (e?.response?.data.toString() ?? "Unknown."), { variant: 'error' }, true);
            setSaving(false);
        });
    }, [showSnackbar, updateLightPole, setSaving]);

    //on save callback
    const save = useCallback(() => {
        if (state === undefined) return;
        const formData = lightPoleMeasurementFormRef?.data.current;
        if (!formData) return;

        //check if state is null, if so create a new record
        if (state === null) {
            create(formData);
        } 
        //otherwise update the existing record
        else {
            update(state.id, formData);
        }

    }, [state, lightPoleMeasurementFormRef?.data, create, update]);

    //transform client object to ClientFormData
    const getInitialFormData = (): LightPoleMeasurementFormData | undefined => {
        if (state === undefined || state === null) return undefined;

        return {
            date: moment(state.date),
            refNumber: state.refNumber,
            file: state.pathLocator,
            latitude: state.latitude,
            longitude: state.longitude,
            accuracy: state.accuracy,
            mapNumber: state.mapNumber,
            poleNumber: state.poleNumber,
			angle: state.angle,
            address: state.address,
            city: state.city,
            state: state.state,
            zip: state.zip,
            baseThickness1: state.baseThickness1,
            baseThickness2: state.baseThickness2,
            baseThickness3: state.baseThickness3,
            baseThickness4: state.baseThickness4,
            poleCorrosion1: state.poleCorrosion1,
            poleCorrosion2: state.poleCorrosion2,
            poleCorrosion3: state.poleCorrosion3,
            poleCorrosion4: state.poleCorrosion4,
			poleNomThickness1: state.poleNomThickness1,		
			poleNomThickness2: state.poleNomThickness2,		
			poleNomThickness3: state.poleNomThickness3,		
			poleNomThickness4: state.poleNomThickness4,			
            impactTest: state.impactTest,
            charge: state.charge,
            groundCondition: state.groundCondition,
            paintCondition: state.paintCondition,
            notes: state.notes,
            nonAccessible: state.nonAccessible,
			junctionBox: state.junctionBox,
			solightpolenomthickness: state.solightpolenomthickness,
			solightpoletobereviewed: state.solightpoletobereviewed,
			solightpoletobereviewednotes: state.solightpoletobereviewednotes,
			arterial: state.arterial
        };
    };

    //get the memoized value getInitialFormData
    const initialFormData = useMemo(getInitialFormData, [state]);

    return (
        <Dialog open={open && !saving} fullScreen={!isSm} TransitionComponent={!isSm ? SlideUp : undefined} keepMounted>
            {!isSm ? ( 
                <XDialogTitle
                    onClose={onCancel}
                    fullScreen
                >{state ? "Editing Light Pole" : "New Light Pole"}</XDialogTitle>
            ) : null}
            {/* Content */}
            <DialogContent>
                <LightPoleMeasurementForm
                    key={state?.id ?? "new"}
                    data={initialFormData}
                    loading={state === undefined || (!open && !saving)}
                    saving={saving}
                    pronum={pronum}
                    ref={onLightPoleMeasurementFormRef}
                />
            </DialogContent>
            <DialogActions>
                {/* Cancel Dialog Action */}
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={onCancel}
                >{"Cancel"}</Button>

                {/* Save Action */}
                <Button
                    disabled={!(lightPoleMeasurementFormRef?.isValid ?? false)}
                    color={"primary"}
                    variant={"contained"}
                    onClick={save}
                >{"Save"}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LightPoleMeasurementFormDialog;