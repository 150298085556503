import { makeStyles } from '@imas/styles';
import { alpha } from '@mui/material';
import * as MuiGrid from "@mui/x-data-grid-pro";

type X = keyof MuiGrid.GridClasses;

const a: X = 'row';

export const useEditDataGridDisabledStyles = makeStyles()(theme => ({
	cellContent: {
		color: theme.palette.text.disabled,
	},
	'cell--withRenderer': {
		color: theme.palette.text.disabled,
	},
	'cell': {
		outline: 'none!important',
	},
	'row': {
		'&.Mui-selected.Mui-selected.Mui-selected.Mui-selected.Mui-selected.Mui-selected.Mui-selected.Mui-selected': {
			backgroundColor: 'unset',
			':hover': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	},
}));