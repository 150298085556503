import { mapApi, ApiConfig } from '@imas/api';
import { 
	Client, ClientForm, ClientLocation, ClientLocationContact, ClientNote, ClientPurchaseOrder, vContact, vClientContact, 
	vLocationPrimaryPhoneNumber, vContactPrimaryPhoneNumber,
} from './types';
import { Proposal, ProposalListResult } from '../proposal';
import { IdName } from '../types';

const CLIENTS_BASE_URL = "/api/clients";

/*  */
export const GetAllClients = mapApi((): ApiConfig<Client[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}`,
}));

/*  */
export const GetAllClientIdNames = mapApi((): ApiConfig<IdName[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/id-names`,
}));

/*  */
export const GetVClientContacts = mapApi((): ApiConfig<vClientContact[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/v-client-contacts`,
}));

/*  */
export const GetSimilarClients = mapApi((data: { name?: string | null; }, clientId?: int | null): ApiConfig<Client[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/similar`,
	data: {
		...data,
		excludedId: clientId ?? null,
	},
}));

/*  */
export const GetClient = mapApi((clientId: int): ApiConfig<Client> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId}`,
}));

/*  */
export const GetClientByName = mapApi((name: string): ApiConfig<Client> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/name`,
	params: { name },
}));

/*  */
export const GetClientLocations = mapApi((clientId: int | null): ApiConfig<ClientLocation[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/locations`,
}));

/*  */
export const GetClientContacts = mapApi((clientId: int | null): ApiConfig<ClientLocationContact[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/contacts`,
}));

/*  */
export const GetClientPurchaseOrders = mapApi((clientId: int | null): ApiConfig<ClientPurchaseOrder[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/purchase-orders`,
}));

/*  */
export const GetClientVContacts = mapApi((clientId: int | null): ApiConfig<vContact[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/v-contacts`,
}));

/*  */
export const GetClientVLocationPrimaryPhoneNumbers = mapApi((clientId: int | null): ApiConfig<vLocationPrimaryPhoneNumber[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/v-location-primary-phone-numbers`,
}));

/*  */
export const GetClientVContactPrimaryPhoneNumbers = mapApi((clientId: int | null): ApiConfig<vContactPrimaryPhoneNumber[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/v-contact-primary-phone-numbers`,
}));

/*  */
export const GetClientNotes = mapApi((clientId: int | null): ApiConfig<ClientNote[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/notes`,
}));

/*  */
export const GetClientProposals = mapApi((clientId: int | null): ApiConfig<Proposal[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/proposals`,
}));

/*  */
export const GetClientProposalIdNames = mapApi((clientId: int | null): ApiConfig<IdName[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId ?? -1}/proposals/id-names`,
}));

/*  */
export const GetClientProposalList = mapApi((clientId: int): ApiConfig<ProposalListResult[]> => ({
	method: 'get',
	url: `${CLIENTS_BASE_URL}/${clientId}/proposal-list`,
}));

/*  */
export const CreateClient = mapApi((data: ClientForm): ApiConfig<Client> => ({
	method: 'post',
	url: `${CLIENTS_BASE_URL}`,
	data,
}));

/*  */
export const CreateClientFolder = mapApi((clientId: int): ApiConfig<Client> => ({
	method: 'post',
	url: `${CLIENTS_BASE_URL}/${clientId}/folder`,
}));

/*  */
export const UpdateClient = mapApi((clientId: int, data: ClientForm): ApiConfig<Client> => ({
	method: 'put',
	url: `${CLIENTS_BASE_URL}/${clientId}`,
	data,
}));

/*  */
export const DeleteClient = mapApi((clientId: int): ApiConfig<Client> => ({
	method: 'delete',
	url: `${CLIENTS_BASE_URL}/${clientId}`,
}));