import { ApiConfig, axiosData, axiosNullableData, mapApi, SourceGetter } from '@imas/api';
import moment, { Moment } from 'moment';
import { TimeSheetSummaryResult } from '../time-sheet';
import { EmployeeBasics, EmployeeMilestoneDatesResult, EmployeeManagerSession, EmployeeRecognitionTracking, TimeOffAlertsResult, vEmployeeViolation, EmployeeViolationForm, EmployeeCertificationTracking } from './types';

//employees base url
const EMPLOYEES_URL = "/api/employees";

/*  */
export const GetEmployees = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeBasics[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployee = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<EmployeeBasics> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeManager = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<EmployeeBasics> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/manager`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeLatestCoachingSession = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<EmployeeManagerSession | null> => {
        return axiosNullableData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/latest-coaching-session`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeClosestRecognition = (getSource: SourceGetter) => {
    return async (employeeId: int, date: Moment): Promise<EmployeeRecognitionTracking | null> => {
        return axiosNullableData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/closest-recognition`,
			params : {
				date: date.toDate(),
			},
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeTimeSheetSummary = mapApi((employeeId: int, fromDate?: Moment | Date, toDate?: Moment | Date): ApiConfig<TimeSheetSummaryResult[]> => ({
	method: 'get',
	url: `${EMPLOYEES_URL}/${employeeId}/time-sheet-summary`,
	params : {
		fromDate: fromDate ? fromDate instanceof Date ? fromDate : fromDate.toDate() : moment().subtract(1, 'year').toDate(),
		toDate: toDate ? toDate instanceof Date ? toDate : toDate.toDate() : moment().toDate(),
	},
}));

/*  */
export const GetProjectManagers = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeBasics[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/project-managers`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeMilestoneDates = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeMilestoneDatesResult[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/milestone-dates`,
            cancelToken: getSource().token,
		});
    };
};

/* returns alerts for an employee */
export const GetEmployeeAlerts = (getSource: SourceGetter) => {
    return async (employeeid: int): Promise<TimeOffAlertsResult[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/alerts/${employeeid.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

export const GetAllEmployeeViolations = (getSource: SourceGetter) => {
    return async (employeeid : int): Promise<vEmployeeViolation[]> => {
        return axiosData({

            method: 'get',
            url: `${EMPLOYEES_URL}/violations/${employeeid.toString()}`,
            cancelToken: getSource().token
        });
    };
};

export const GetEmployeeViolation = (getSource: SourceGetter) => {
    return async (violationid : int): Promise<vEmployeeViolation> => {
        return axiosData({

            method: 'get',
            url: `${EMPLOYEES_URL}/violation/${violationid.toString()}`,
            cancelToken: getSource().token
        });
    };
};

export const SignViolation = (getSource: SourceGetter) => {
    return async(data: EmployeeViolationForm) => {
        return axiosData({
            method: 'put',
            url: `${EMPLOYEES_URL}/signViolation`,
            data,
            cancelToken: getSource().token,

        });
    };
};

export const getEmployeeCert = (getSource: SourceGetter) => {
    return async (id: int): Promise<EmployeeCertificationTracking[]> => {
        return axiosData({
            method: 'get',
            url: `${EMPLOYEES_URL}/certification/${id}`,
            cancelToken: getSource().token
        });
    };
};