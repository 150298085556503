import { List, ListItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Menu } from '@imas/constants/menus';
import { makeStyles } from '@imas/styles';

export interface DynamicSideNavigationProps {
    menu: Menu;
    closeNav: () => any;
};

const useDynamicSideNavigationStyles = makeStyles()((theme) => ({
    menuSubItem: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: 0,
    },
    menuButton: {
        width: '-webkit-fill-available',
        justifyContent: "left",
    }
}));

const DynamicSideNavigation = ({ menu, closeNav }: DynamicSideNavigationProps) => {
    const { classes } = useDynamicSideNavigationStyles();

    //get mobile safe categories & items
    const mobileSafeMenu: Menu = useMemo(() => {
        return menu.filter(category => category.options.some(option => option.mobileSafe)).map(category => ({ ...category, items: category.options.filter(option => option.mobileSafe) }));
    }, [menu]); 
    
    return (
        <List>
            {
                mobileSafeMenu.map(category => (
                    <React.Fragment key={category.url}>
                        {category.options.filter(option => option.mobileSafe).map(option => (
                            <ListItem 
                                key={option.url}
                                className={classes.menuSubItem}
                            >
                                <Button
                                    color={"inherit"}
                                    variant={"text"}
                                    startIcon={option.icon}
                                    component={Link} 
                                    to={option.url}
                                    onClick={closeNav}
                                    className={classes.menuButton}
                                >{option.name}</Button>
                            </ListItem>
                        ))}
                    </React.Fragment>
                ))
            }
        </List>
    );
};

export default DynamicSideNavigation;