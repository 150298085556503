import { useTheme } from "@mui/material/styles";
import Masonry from "react-masonry-css";
import { makeStyles } from '@imas/styles';
import React from 'react';
import clsx from 'clsx';

const masonryStyles = makeStyles()((theme) => ({
    masonryGrid: {
        display: "flex",
        marginLeft: theme.spacing(-1),
        width: "inherit",
        overflow: 'scroll',
    },
    masonryColumn: {
        paddingLeft: theme.spacing(1),
        backgroundClip: "padding-box",
    },
}));


const XMasonry = ({
    className,
    style,
    children 
}: React.PropsWithChildren<{
    className?: string,
    style?: React.CSSProperties,
}>) => {
    const { classes } = masonryStyles();
    const theme = useTheme();

    const breakpointCols = {
        default: 6,
        [theme.breakpoints.values.xl]: 6,
        [theme.breakpoints.values.lg]: 4,
        [theme.breakpoints.values.md]: 2,
        [theme.breakpoints.values.sm]: 1,
        [theme.breakpoints.values.xs]: 1,
    };

    return (
        <Masonry
            breakpointCols={breakpointCols}
            style={style}
            className={clsx(classes.masonryGrid)}
            columnClassName={classes.masonryColumn}
        >
            {children}
        </Masonry>
    );
};

export { XMasonry };
