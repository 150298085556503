
interface FormatNumberByFactorOptions {
	/** The number by which factor breakpoints will be determined, for example the factorOf for (thousand, million, billion) would be 1000, but for (KiB, MiB, GiB) would be 1024. */
	factorOf?: int;
	/** The string to be returned when the number === 0. */
	zeroResult?: string;
	/** The strings to be used as a suffix for each factor of numbers, for example for (thousand, million, billion) it would look something like ["T", "M", "B", "T", "P"]. */
	factors?: string[];
	/** How many digits should be shown after the decimal place (if not a whole number). */
	fractionDigits?: int;
};

/**
 * Format a number given a factor and list of sizes which will be used as a sufix for each factor teir.
 * 
 * Defaults to scientific notation (ex. 10 x 10^5).
 * 
 * @param amount The number to be formatted.
 * @param options Options for formatting.
 * @returns Formatted string.
 */
export const formatNumberByFactor = (number: number, options?: FormatNumberByFactorOptions): string => {
    //if the number is zero
    if (Math.floor(number) === 0) return options?.zeroResult ?? "0";

    let k = options?.factorOf ?? 10,
        dm = options?.fractionDigits ?? 2,
        sizes = options?.factors ?? [...new Array(50)].map((_, x) => `x 10^${x}`),
        i = Math.floor(Math.log(number) / Math.log(k));
    return `${parseFloat((number / Math.pow(k, i)).toFixed(dm))} ${sizes[i] ?? "Unknown"}`.trim();
};