// augment theme with lab & grid component styles
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

import { Theme } from '@mui/material/styles';
import { createTheme, lighten, darken, useMediaQuery } from '@mui/material';
import { AppSettings, getAppSettings } from '@imas/redux';
import merge from 'deepmerge';
import { useSelector } from 'react-redux';

//module augmentation to add custom fields to the theme
declare module '@mui/material/styles/createPalette' {
	interface PaletteOptions {
		grid: {
			border: string
		}
	}

	interface Palette {
		grid: {
			border: string
		}
	}

	interface TypeText {
		navigation: string
	}
};

declare module '@mui/styles' {
	interface DefaultTheme extends Theme {}
};

declare module '@mui/material/TextField' {
	interface TextFieldPropsSizeOverrides {
		"x-small": true;
	}
};

declare module '@mui/material/Autocomplete' {
	interface AutocompletePropsSizeOverrides {
		"x-small": true;
	}
};

declare module '@mui/lab/DatePicker' {

}


//PNL_BLUE color
const PNL_BLUE = "#104686";
const WHITE = '#FFFFFF';

//generate main app theme given appSettings
export const createAppTheme = (settings: AppSettings, prefersDark: boolean): Theme => {
//create the default theme object to use for breakpoints
const defaultTheme = createTheme();

//get the theme color for the app
const themeType = settings.general.theme ?? (prefersDark ? 'dark' : 'light');

const background = themeType === 'light' ? '#F1F1F1' : defaultTheme.palette.grey[900];
const editableColor = themeType === 'light' ? '#FFF' : '#303030';


//superdense table Overrides

//custom theme
const appTheme = createTheme({
	palette: {
		mode: themeType,
		primary: {
			main: PNL_BLUE,
			light: PNL_BLUE,
			dark: PNL_BLUE,
		},
		secondary: {
			light: '#ff4081',
			main: '#f50057',
			dark: '#c51162',
		},
		text: {
			navigation: '#FFFFFF',
		},
		grid: {
			border: themeType === 'light' ? 'rgba(224, 224, 224, 1)' : 'rgba(81, 81, 81, 1)',
		},
	},
	zIndex: {
		snackbar: 900,
	},
	components: {

		MuiPaper: {
			styleOverrides: {
				root: {
					//backgroundColor: themeType === 'light' ? undefined : defaultTheme.palette.grey[900],
					backgroundColor: background,
					backgroundImage: themeType === 'light' ? undefined : 'none',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					backgroundImage: themeType === 'light' ? undefined : 'none',
				},
			},
		},
		MuiAppBar:{
			styleOverrides: {
				root: {
					backgroundColor: PNL_BLUE,
					color: "#FFF",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: themeType === 'light' ? undefined : lighten(PNL_BLUE, 0.15),
				},
			},
		},
		MuiTypography: {
			// colorPrimary: {
			//   color: lighten(PNL_BLUE, 0.15),
			// },
		},
		MuiSpeedDial:{
			styleOverrides: {},
		},
		MuiSpeedDialAction: {
			styleOverrides: {
				staticTooltipLabel: {
					backgroundColor: themeType === 'light' ? undefined : defaultTheme.palette.grey[800],
				},
				fab: {
					backgroundColor: themeType === 'light' ? undefined : defaultTheme.palette.grey[800],
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {}
		},
		MuiAvatarGroup: {
			styleOverrides: {
				avatar: {
					borderWidth: '1px',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				textColorPrimary: {
					'&.Mui-selected': {
						color: themeType === 'light' ? undefined : lighten(PNL_BLUE, 0.15),
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				color: 'primary',
			},
			styleOverrides: {
				textPrimary: {
					color: themeType === 'light' ? undefined : lighten(PNL_BLUE, 0.2),
				},
			},
		},
		MuiButtonGroup: {
			styleOverrides: {
				groupedContained: {
					"&.MuiButtonGroup-groupedContained": {
						borderColor: themeType === 'light' ? darken(PNL_BLUE, 0.2) : lighten(PNL_BLUE, 0.2),
					},
				},
			},
		},
		// Change Input
		// MuiInputBase: {
		// 	styleOverrides: {
		// 		inputSizeSmall: {
		// 			padding: '4px 8px',
		// 			height: '1.375em',
		// 		}
		// 	}
		// },
		MuiOutlinedInput: {
			styleOverrides: {
				// sizeSmall: {
				// 	"& .MuiOutlinedInput-input": {
				// 		padding: '4px 8px',
				// 		height: '1.375em',
				// 	}
				// }
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				// sizeSmall: {
				// 	transform: 'translate(9px, 4px) scale(1)',
				// },
				// shrink: {
				// 	transform: 'translate(14px, -9px) scale(0.75)',
				// }
			},
		},
		// MuiInput: {
		// 	defaultProps: {
		// 		inputProps: {
		// 			autocomplete: "noautocomplete",
		// 		},
		// 	},
		// },
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: editableColor
				}
			},
			defaultProps: {
				autoComplete: 'noautocomplete',
				size: 'x-small',
			},
			variants: [
				{
					props: { size: 'x-small' },
					style: {
						"& .MuiInputLabel-root": {
							transform: 'translate(9px, 4px) scale(1)',
						},
						"& .MuiInputLabel-shrink": {
							transform: 'translate(14px, -9px) scale(0.75)',
						},
						"& .MuiOutlinedInput-input": {
							padding: '5px 8px 3px 8px',
							height: '1.375em',
						},
						"& .MuiInputBase-multiline": {
							padding: '2px',
						},
						"& .MuiInputBase-inputMultiline": {
							padding: '5px 6px 3px 6px',
						},
						"& .MuiSelect-select": {
							minHeight: '1.375em!important',
							height: '1.375em!important',
						},
						"& .MuiIconButton-root:not(.MuiAutocomplete-clearIndicator)": {
							padding: '4px',
							"& .MuiSvgIcon-root": {
								height: '0.75em',
								width: '0.75em',
							},
						},
					}	
				},
			],
		},
		MuiAutocomplete: {
			defaultProps: {
				size: 'x-small',
			},
			variants: [
				{
					props: { size: 'x-small' },
					style: {
						"& .MuiInputLabel-root": {
							transform: 'translate(9px, 4px) scale(1)',
						},
						"& .MuiInputLabel-shrink": {
							transform: 'translate(14px, -9px) scale(0.75)',
						},
						"& .MuiAutocomplete-inputRoot": {
							padding: '2px',
						},
						"& .MuiOutlinedInput-input.MuiAutocomplete-input": {
							padding: '3px 10px 1px 6px',
							height: '1.375em',
						},
					}	
				},
			],
		},
		MuiSelect: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiTableCell: {
			styleOverrides: settings.general.denseTables ? {
				root: { 
					padding: '4px 8px',
					"& .MuiCheckbox-root": {
						padding: '4px',
					},
				},
				sizeSmall: {
					padding: '2px 8px',
				},
			} : {},
		},

		/**
		 *  Data Grid Overrides
		 */
		MuiDataGrid: {
			styleOverrides: {
				columnHeaderTitle: {
					fontWeight: 'bold',
				},
				"cell--editing": {
					"& .MuiInputBase-input": {
						padding: '0 8px',
					},
					"& .MuiOutlinedInput-notchedOutline": {
						border: 'none',
					},
				},
				row: {
					"& .baseGridColor": {
						backgroundColor: themeType === 'light' ? background : undefined,
					},
					"& .MuiDataGrid-cell--editable": {
						backgroundColor: editableColor,
					},
					"& .MuiDataGrid-cellCheckbox": {
						backgroundColor: editableColor,
					},
					"& .edit-actions": {
						backgroundColor: editableColor,
					},
				},
				columnSeparator: {
					visibility: 'visible',
				},
				//iconButtonContainer: {
				//	display: 'none',
				//}

			},
			defaultProps: {
				density: "compact",
				rowHeight: 40,
				columnHeaderHeight: 40,
				hideFooter: true,
			}
		},

		/*
		* Lab Overrides
		*/
		},
	});


	const muiRteOverrides = {
		overrides: {
			MUIRichTextEditor: {
				root: {
					border: '1px solid rgb(221, 221, 221)'
				},
				toolbar: {
					padding: '5px',
					borderBottom: '1px solid rgb(221, 221, 221)'
				},
				editor: {
					margin: '15px',
					minHeight: '200px'
				},
					placeHolder: {
					margin: '15px',
				}
			}
		}
	};

	return merge(appTheme, muiRteOverrides);
};

//hook for getting the theme using createAppTheme
export const useAppTheme = () => {
	//get app settings
	const settings = useSelector(getAppSettings);

	//user user default theme
	const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');

	return createAppTheme(settings, prefersDark);
};