import { axiosData, SourceGetter } from "@imas/api";
import { State } from './types';

//states base url
const STATES_URL = "/api/system/states";

/**  */
export const GetStates = (getSource: SourceGetter) => {
    return async (): Promise<State[]> => {
        return await axiosData({
            method: 'get',
            url: `${STATES_URL}`,
            cancelToken: getSource().token,
        });
    };
};

/**  */
export const UpdateState = (getSource: SourceGetter) => {
    return async (state: State): Promise<State> => {
        return await axiosData({
            method: 'put',
            url: `${STATES_URL}`,
			data: state,
            cancelToken: getSource().token,
        });
    };
};

/**  */
export const CreateState = (getSource: SourceGetter) => {
    return async (state: State): Promise<State> => {
        return await axiosData({
            method: 'post',
            url: `${STATES_URL}`,
			data: state,
            cancelToken: getSource().token,
        });
    };
};
