export type VibrationPattern = "long-press";

export function vibrate(pattern: VibratePattern | VibrationPattern): boolean {
    if ("vibrate" in navigator) {
        if (typeof pattern === "string") {
            if (pattern === "long-press") {
                return navigator.vibrate(1);
            } else {
                return false;
            }
        } else {
            return navigator.vibrate(pattern);
        }
    }
    
    return false;
}