import { USD_COL } from './USD_COL';
import { INTEGER_COL } from './INTEGER_COL';

export interface ColumnTypesMap {
    "usd": typeof USD_COL;
    "int": typeof INTEGER_COL;
};

export type ColumnTypes = keyof ColumnTypesMap;

type TypedGridColumnTypesRecordPartial<T extends ColumnTypes[]> = {
    [K in keyof T as Exclude<T[K] extends ColumnTypes ? T[K] : never, never>]: T[K] extends ColumnTypes ? ColumnTypesMap[T[K]] : never;
};

//an instance of ColumnTypesMap
const COLUMN_TYPES_MAP: ColumnTypesMap = {
    "usd": USD_COL,
    "int": INTEGER_COL,
};

export function getColumnTypes<CT extends ColumnTypes[]>(types: CT): TypedGridColumnTypesRecordPartial<CT>  {
    //return a map for the specified column types => the column type implimentation 
    return types.map(type => ({ type, column: COLUMN_TYPES_MAP[type] })).reduce((prev, cur) => ({ ...prev, [cur.type]: cur.column }), {}) as TypedGridColumnTypesRecordPartial<CT>;
};

