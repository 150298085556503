import { formatNumberByFactor } from './formatNumberByFactor';

//format a number in file size format (ex. MB, KB etc....) with the given precision
export const formatFileSize = (bytes: number, fractionDigits?: number): string => {
    return formatNumberByFactor(bytes, {
		factorOf: 1000,
		zeroResult: "0 Bytes",
		factors: ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
		fractionDigits,
	});
};