import { Alignment, Spacer } from '@imas/components/layout';
import {FormTextField, useMuiFormContext } from '@imas/mui-form';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../VehicleInspectionStyles';
import { VehicleInspectionRow } from './VehicleInspectionRow/VehicleInspectionRow';
import { VehicleInspectionHeader } from './VehicleInspectionRow/VehicleInspectionHeader';


export const VehicleInspectionFormInterior = memo(() => {

	const { classes } = VehicleInspectionStyles();

	const { control } = useMuiFormContext<TVehicleInspectionForm>();

	return(
		
		<Alignment column>

			<Spacer vertical/>

			<Alignment row>
				{/* Interior Title */}
				<Typography className={classes.title} align={'center'} fontWeight={"bold"} variant={"h5"}>{"Interior"}</Typography>

			</Alignment>

			<VehicleInspectionHeader/>

			<VehicleInspectionRow label={'Horn Operation*:'} variable={'horn'} />
			<VehicleInspectionRow label={'Rear Mirror:'} variable={'rearMirror'} />
			<VehicleInspectionRow label={'Driver Seat:'} variable={'driverSeat'} />
			<VehicleInspectionRow label={'Passenger Seat:'} variable={'passSeat'} />
			<VehicleInspectionRow label={'Rear Seats:'} variable={'rearSeat'} />
			<VehicleInspectionRow label={'Instrumentation:'} variable={'instrumentation'} />
			<VehicleInspectionRow label={'Dashboard:'} variable={'dashboard'} />
			<VehicleInspectionRow label={'Interior Lights:'} variable={'interiorLight'} />
			<VehicleInspectionRow label={'Fire Extinguisher*:'} variable={'fireExt'} />
			<VehicleInspectionRow label={'First Aid Kit*:'} variable={'firstaidKit'} />

			{/* Notes Field */}
			<FormTextField
				control={control}
				name={'interiorNotes'}
				label={'Interior Notes'}
				fullWidth
				inputProps={{maxLength: 255}}
				multiline
				minRows={2}
			/>			

		</Alignment>

	);
});
