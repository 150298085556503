import { getDecimalColDef } from '@imas/data-grid';
import { HourColumnHeader } from './Components';
import { HourEditCell } from './EditCells';
import { HOUR_CELL_DECIMAL_OPTIONS } from './utils';

export const TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH = 35;

export const HOUR_COL_DEF = {
	...getDecimalColDef(HOUR_CELL_DECIMAL_OPTIONS),
	disableColumnMenu: true,
	disableColumnReorder: true,
	sortable: false,
	width: TIME_SHEET_CERTIFICATION_HOUR_COL_WIDTH,
	renderHeader: () => <HourColumnHeader/>,
	renderEditCell: (params: any) => <HourEditCell {...params}/>,
};
