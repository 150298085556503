import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { makeStyles } from '@imas/styles';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Alignment } from '@imas/components/layout';

export interface TextDialogProps {
	open: boolean,
    title?: string
    label?: string,
    value?: string,
    valueExt?: string,
    multiline?: boolean,
    rows?: number,
    validator?: (value: string) => string | null,
    confirmText?: string, 
    closeText?: string,
    onConfirm: (newValue: string) => void,
    onCancel: () => void,
};

const useStyles = makeStyles()(() => ({
    fileNameEditBox: {
        width: "-webkit-fill-available",
    },
    fileNameEditExtension: {
        margin: 'auto 0 auto 0',
    },
    nameDialogueContent: {
        margin: '10px',
    },
    dialogue: {
        "& .MuiDialog-container .MuiDialog-paper": {
             width: '-webkit-fill-available',
        },
    },
}));

const TextDialog = ({ open, title, label, value, valueExt, multiline, rows, validator, confirmText, closeText, onConfirm, onCancel }: TextDialogProps) => {
    const { classes } = useStyles();

    //text box value 
    const [newValue, setNewValue] = useState<string>(value ?? "");

    //close func
    const close = () => {
        setNewValue(value ?? "");
        onCancel();
    };

    //reset value when open changes
    useEffect(() => setNewValue(value ?? ""), [open]);

    //useMemo to check if string is valid
    const errText = useMemo(() => validator === undefined ? null : validator(newValue), [newValue]);
 
    //if not open then return null
    if (!open) return null;

    return (
        <Dialog open={true} onClose={close} maxWidth={"sm"} className={classes.dialogue}>
            {/* Dialog Title */}
            {title ? 
            <DialogTitle>
                <Typography variant={'h5'} fontWeight={'bold'}>{title}</Typography>
            </DialogTitle>
            : null}

            <DialogContent className={classes.nameDialogueContent}>
                {/* Name edit field */}
                <Alignment row sx={{marginTop: '5px'}}>
                    <TextField
                        multiline={multiline}
                        rows={multiline ? rows ?? 5 : undefined}
                        label={label}
                        error={errText !== null}
                        helperText={errText}
                        size={"small"}
                        autoFocus
                        value={newValue}
                        onChange={e => setNewValue(e.target.value)}
                        className={classes.fileNameEditBox}
                        onKeyPress={e => {
                            //ignore if multiline is true
                            if (multiline) return;

                            //detect if the user presses enter, if so call the onConfirm
                            if (e.key === "Enter") {
                                onConfirm(newValue);
                            }
                        }}
                    />
                    {valueExt === undefined ? null : <Typography className={classes.fileNameEditExtension}>{valueExt}</Typography>}
                </Alignment>
            </DialogContent>
            <DialogActions>
                {/* Cancel/Close Button */}
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={close}
                >{closeText ?? "Cancel"}</Button>

                {/* Choice Confirmation Button */}
                <Button
                    disabled={errText !== null}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => onConfirm(newValue)}
                >{confirmText ?? "Confirm"}</Button>
            </DialogActions>
        </Dialog>
    );
};


export { TextDialog };