import { useCallback } from 'react';
import { NativeInterop } from "./types";

export interface UseNativeResult {
    //if the current process is being run in a native context
    isNative: boolean;

    //native interface which provides abstracted methods 1 level above the real native interface, will show an error message if they are used outside of a native context
    native: NativeInterop;
};

export function useNative(): UseNativeResult  {
    //construct abstracted methods
    const openLocalFile = useCallback<NativeInterop["openLocal"]>(async (filePath) => {
        if (window?.native) {
            return await window.native.openLocal(filePath);
        }

        return "File system not accessible.";
    }, []); 

    console.log(window?.native);

    return {
        isNative: window?.native !== undefined,
        native: {
            openLocal: openLocalFile,
        },
    };
};