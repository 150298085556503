import { TimeSheetCertificationHour, TimeSheetDetail } from '@imas/api/time-sheet';
import { createContext } from 'react';
import { TimeSheetWeekday } from './types';
import { weekdayList, getCertificationHourDayTotal, HOUR_CELL_DECIMAL_OPTIONS } from '../../TimeSheetDetailsForm/TimeSheetDetailsFormUtils';

//re-export utils from TimeSheetDetailsForm
export { weekdayList, getCertificationHourDayTotal, HOUR_CELL_DECIMAL_OPTIONS };

export interface T_TimeSheetCertificationHoursContext {
	detail?: TimeSheetDetail;
};

export const TimeSheetCertificationHoursContext = createContext<T_TimeSheetCertificationHoursContext>({
	detail: undefined,
});

export const isNewHourValueOverDayTotal = (
	newHourValue: int | null | undefined, 
	day: TimeSheetWeekday, 
	detail: TimeSheetDetail, 
	certificationHours: TimeSheetCertificationHour[],
	excludedCert?: TimeSheetCertificationHour | null,
): boolean => {
	//if an excluded certification is specified filter it out
	const otherCertHours = certificationHours.filter(certHour => certHour.id !== excludedCert?.id);

	const dayHourTotal = detail[day] ?? 0;
	const dayCertHourTotal = getCertificationHourDayTotal(otherCertHours, day) + (newHourValue ?? 0);
	return dayCertHourTotal > dayHourTotal;
};