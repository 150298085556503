import { makeStyles } from "@imas/styles";
import { Box, BoxProps, styled, alpha } from '@mui/material';

export const cellStyles = makeStyles({ name: 'EditCellContainer' })(theme => ({
	base: { 
		zIndex: 2,
	},
	required: {
		outline: `solid 1px ${theme.palette.mode === 'light' ? alpha(theme.palette.error.light, 0.8) : alpha(theme.palette.error.dark, 0.8)}`,
	},
    error: {
        color: theme.palette.mode === 'light' ? theme.palette.error.light : theme.palette.error.dark
    },
    warning: {
        color: theme.palette.mode === 'light' ? theme.palette.warning.light : theme.palette.warning.dark,
    },
}));

const EditCell = styled(Box)({
	display: 'flex', 
	alignSelf: 'stretch',
	width: '100%',
});

export interface EditCellContainerProps extends BoxProps {
	required?: boolean;
	error?: boolean;
	warning?: boolean;
};

export const EditCellContainer = ({ required, error, warning, ...boxProps }: EditCellContainerProps) => {
	const { classes, cx } = cellStyles();
	return <EditCell 
		{...boxProps}
		className={cx(boxProps.className, classes.base, {
			[classes.required]: required,
			[classes.error]: error,
			[classes.warning]: warning,
		})}
	/>;
};