import { getApi } from '@imas/api';
import { PhoneNumber, PhoneNumberType, GetPhoneNumberTypes } from '@imas/api/phone-numbers';
import { Paper, PaperProps, ListSubheader, Box, Typography, AutocompleteProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

//phone number priorities
const phoneNumberPriorities: Record<number, string> = {
	1: "Primary",
	2: "Second",
	3: "Third",
	4: "Other",
};

//phone number types
const phoneNumberTypes: PhoneNumberType[] = [];

//populate phone number types constant
(async () => {
	const { api: getPhoneNumberTypes } = getApi(GetPhoneNumberTypes);

	//get phone number types
	phoneNumberTypes.push(...(await getPhoneNumberTypes()));
})().catch();

const VPhoneNumberListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<>
			{/* List Header */}
			<ListSubheader sx={{ marginTop: '5px', lineHeight: '32px', padding: '0px', display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ marginLeft: '15px', width: '200px' }}>{"Number"}</Box>
				<Box sx={{ width: '200px' }}>{"Type"}</Box>
			</ListSubheader>

			{/* List */}
			<VirtualizedListboxAdpater 
				{...props} 
				ref={ref} 
				renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<PhoneNumber>) => {
					const row = data[index];
				
					return (
						<li {...row.props}  style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}>
							<Typography noWrap sx={{ width: '200px' }}>{row.option.number}</Typography>
							<Typography noWrap sx={{ width: '200px' }}>{`${phoneNumberTypes.find(x => x.id === row.option.typeId)?.type ?? "Unknown"} (${phoneNumberPriorities[row.option.priority] ?? "Unknown"})`}</Typography>
						</li>
					);
				}}
			/>
		</>
	);
});

const PhoneNumberPaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
	return <Paper {...props} ref={ref} sx={{ minWidth: '400px'}}/>;
});

export const PHONE_NUMBERS_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<PhoneNumber, false, false, false, "div">, "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {
	//virtualization props
	disableListWrap: true,
	PaperComponent: PhoneNumberPaper,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: VPhoneNumberListboxAdapter,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};