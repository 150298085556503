import { axiosData, SourceGetter } from '@imas/api';
import { EmployeeExpenseReport, EmployeeExpenseReportDetail, ExpenseReportSummaryResult, ExpensableItemListResult, ExpenseItemPoolResult } from './types';
import moment, { Moment } from 'moment';

//employee expense reports base url
const EXPENSE_REPORTS_URL = "/api/employees/expense-reports";

/*  */
export const GetEmployeeExpenseReport = (getSource: SourceGetter) => {
    return async (reportId: int): Promise<EmployeeExpenseReport> => {
        return axiosData({
			method: 'get',
			url: `${EXPENSE_REPORTS_URL}/${reportId}`,
			cancelToken: getSource().token,
		});
    };
};
/*  */
export const GetDistinctEmployeeExpenseReportDetails = (getSource: SourceGetter) => {
    return async (): Promise<int[]> => {
        return axiosData({
			method: 'get',
			url: `${EXPENSE_REPORTS_URL}/distinct`,
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeExpenseReportDetails = (getSource: SourceGetter) => {
    return async (reportId: int): Promise<EmployeeExpenseReportDetail[]> => {
        return axiosData({
			method: 'get',
			url: `${EXPENSE_REPORTS_URL}/${reportId}/details`,
			cancelToken: getSource().token,
		});
    };
};
///// <param name="fromDate">Start date of the search.</param>
/*  */
export const GetEmployeeExpenseReportSummary = (getSource: SourceGetter) => {
    return async (startDate: Moment, endDate: Moment, employeeId?: int): Promise<ExpenseReportSummaryResult[]> => {
        return axiosData({
			method: 'get',
			url: `${EXPENSE_REPORTS_URL}/summary`,
			params: {
				fromDate: startDate.toDate(),
				toDate: endDate.toDate(),
				employeeId: employeeId ?? null,
			},
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeExpensableItemList = (getSource: SourceGetter) => {
    return async (): Promise<ExpensableItemListResult[]> => {
        return axiosData({
			method: 'get',
			url: `${EXPENSE_REPORTS_URL}/expensable-items`,
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeExpenseItemPool = (getSource: SourceGetter) => {
    return async (startDate?: Moment | null, endDate?: Moment | null): Promise<ExpenseItemPoolResult[]> => {
        return axiosData({
			method: 'get',
			url: `${EXPENSE_REPORTS_URL}/item-pool`,
			params: {
				fromDate: startDate ? startDate.toDate() : moment().subtract(30, "days").toDate(),
				toDate: endDate ? endDate.toDate() : moment().toDate(),
			},
			cancelToken: getSource().token,
		});
    };
};


/*  */
export const CreateEmployeeExpenseReport = (getSource: SourceGetter) => {
    return async (data?: { employeeId?: int; }): Promise<EmployeeExpenseReport> => {
        return axiosData({
			method: 'post',
			url: `${EXPENSE_REPORTS_URL}`,
			data,
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const SubmitEmployeeExpenseReport = (getSource: SourceGetter) => {
    return async (reportId: int): Promise<EmployeeExpenseReport> => {
        return axiosData({
			method: 'put',
			url: `${EXPENSE_REPORTS_URL}/${reportId}/submit`,
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const UnSubmitEmployeeExpenseReport = (getSource: SourceGetter) => {
    return async (reportId: int): Promise<EmployeeExpenseReport> => {
        return axiosData({
			method: 'put',
			url: `${EXPENSE_REPORTS_URL}/${reportId}/un-submit`,
			cancelToken: getSource().token,
		});
    };
};

/*  */
export const DeleteEmployeeExpenseReport = (getSource: SourceGetter) => {
    return async (reportId: int): Promise<EmployeeExpenseReport> => {
        return axiosData({
			method: 'delete',
			url: `${EXPENSE_REPORTS_URL}/${reportId}`,
			cancelToken: getSource().token,
		});
    };
};