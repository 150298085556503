import { GetProcedurePriceHistoryResult } from './types/operational-procedures/GetProcedurePriceHistoryResult';
import { fileToJson } from '@imas/utils/files';
import { SourceGetter, axiosData } from '../_internals';
import { OperationalProcedure, vOperationalProcedure, TOperationalProcedureForm } from './types';

//api base url
const OPERATIONAL_PROCEDURES_URL = "/api/certs-and-qc/operational-procedures";

/* Get vOperationalProcedure */
export const GetOperationalProcedure = (getSource: SourceGetter) => {
    return async (procId: int): Promise<OperationalProcedure> => {
        return axiosData({
			method: 'get',
            url: `${OPERATIONAL_PROCEDURES_URL}/${procId}`,
            cancelToken: getSource().token,
		});
    };
};

/* Get vOperationalProcedures */
export const GetvOperationalProcedures = (getSource: SourceGetter) => {
    return async (): Promise<vOperationalProcedure[]> => {
        return axiosData({
			method: 'get',
            url: `${OPERATIONAL_PROCEDURES_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/* Update OperationalProcedure */
export const UpdateOperationalProcedure = (getSource: SourceGetter) => {
    return async (procId: int, form: TOperationalProcedureForm): Promise<OperationalProcedure> => {
        return axiosData({
			method: 'put',
            url: `${OPERATIONAL_PROCEDURES_URL}/${procId}`,
			data: form,
            cancelToken: getSource().token,
		});
    };
};

/* Create OperationalProcedure */
export const CreateOperationalProcedure = (getSource: SourceGetter) => {
    return async (form: TOperationalProcedureForm): Promise<OperationalProcedure> => {
		const jsonFile = form.file ? await fileToJson(form.file) : null;
        return axiosData({
			method: 'post',
            url: `${OPERATIONAL_PROCEDURES_URL}`,
			data: {...form, file: jsonFile },
            cancelToken: getSource().token,
		});
    };
};

/* Get vOperationalProcedures */
export const GetProcedurePriceHistoryResults = (getSource: SourceGetter) => {
    return async (procId: int): Promise<GetProcedurePriceHistoryResult[]> => {
        return axiosData({
			method: 'get',
            url: `${OPERATIONAL_PROCEDURES_URL}/price-history/${procId}`,
            cancelToken: getSource().token,
		});
    };
};