import { vServiceOrderContactDetail } from './../types/api/service-orders/contacts/vServiceOrderContactDetail';
import { vServiceOrderSiteContact, vServiceOrderContact } from 'src/api/types/api/service-orders/types';
import { SourceGetter } from '@imas/api';
import axios from 'axios';

/**
 * Get a list of service order contact's given a service order uniqueId
 */
 export const GetServiceOrderContacts = (getSource: SourceGetter) => {
    return (uniqueId: number): Promise<vServiceOrderContact[]>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<vServiceOrderContact[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId + "/contacts",
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get a list of service order contact details given a service order uniqueId
 */
 export const GetvServiceOrderContactDetails = (getSource: SourceGetter) => {
    return (uniqueId: number): Promise<vServiceOrderContactDetail[]>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<vServiceOrderContactDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/contacts/details/' + uniqueId,
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Create a Service order contact from a proposal contact
 */
 export const CreateServiceOrderContact = (getSource: SourceGetter) => {
    return (uniqueid: int, contactid: int): Promise<boolean>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/contacts/' + uniqueid.toString(),
				data: contactid,
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Update the contact type of a service order  contact
 */
 export const UpdateServiceOrderContact = (getSource: SourceGetter) => {
    return (soContactid: int, correspondence: int, type: string): Promise<boolean>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-order/contacts/' + soContactid.toString() + '/' + correspondence.toString(),
				data: type,
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Delete a service order  contact
 */
 export const DeleteServiceOrderContact = (getSource: SourceGetter) => {
    return (soContactid: int): Promise<boolean>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'delete',
                url: '/api/service-order/contacts/' + soContactid.toString(),
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get a list of service order site contact's given a service order uniqueId
 */
 export const GetServiceOrderSiteContacts = (getSource: SourceGetter) => {
    return (uniqueId: number): Promise<vServiceOrderSiteContact[]>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<vServiceOrderSiteContact[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId + "/site-contacts",
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Create a Service order site contact from a proposal site contact
 */
 export const CreateServiceOrderSiteContact = (getSource: SourceGetter) => {
    return (uniqueid: int, siteContactid: int): Promise<boolean>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/contacts/site-contacts/' + uniqueid.toString(),
				data: siteContactid,
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Update the contact type of a service order site contact
 */
 export const UpdateServiceOrderSiteContact = (getSource: SourceGetter) => {
    return (soSiteContactid: int, type: string): Promise<boolean>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-order/contacts/site-contacts/' + soSiteContactid.toString(),
				data: type,
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Delete a service order site contact
 */
 export const DeleteServiceOrderSiteContact = (getSource: SourceGetter) => {
    return (soSiteContactid: int): Promise<boolean>  => {
        //get a source for the request 
        const source = getSource();

        //return a promise which will resolve when the request is complete
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'delete',
                url: '/api/service-order/contacts/site-contacts/' + soSiteContactid.toString(),
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise on successful execution
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};
