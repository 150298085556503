import { Button, Chip, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { FeedbackCategory } from 'src/api/types/api/misc/misc';
import { Alignment, Spacer } from '@imas/components/layout';
import { FileImporter, filesToJson } from '@imas/utils/files';
import { feedbackFormStyles } from './FeedbackFormStyles';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { useApi } from '@imas/api';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { SubmitFeedback } from 'src/api/misc/misc';

//FeedbackFormProps
export interface FeedbackFormProps {
    defaultCategory?: FeedbackCategory;
    forceDefault?: boolean;
    onSubmit: () => void;
};

/**
 * Renders the feedback form and handles the submission of user feedback.
 */
export const FeedbackForm = ({ defaultCategory, forceDefault, onSubmit }: FeedbackFormProps) => {
    const { classes } = feedbackFormStyles();
    
    //use automatic hiding snackbar
    const showSnackbar = useAutomaticSnackbar();

    //use APIs
    const submitFeedback = useApi(SubmitFeedback);

    //Feedback text value
    const [category, setCategory] = useState<FeedbackCategory>(defaultCategory ?? FeedbackCategory.None);
    const [feedback, setFeedback] = useState<string>("");
    const [attachments, setAttachments] = useState<File[]>([]);
    
    //feedback submit button
    const submit = () => {
        //display notification
        const close = showSnackbar("Submitting feedback...", { variant: "info", persist: true });

        //convert attachments
        filesToJson(attachments).then(files => {
            //submit feedback to server
            submitFeedback(category, feedback, files, false)
            .then(() => {
                //close notification
                close();

                //show success toast
                showSnackbar("Feedback submitted.", { variant: "success" }, false);
                
                //call onSubmit callback
                onSubmit();
            })
            .catch(e => {
                //show failure snackbar
                showSnackbar("Unable to submit feedback.", { variant: "error" }, false);

                //call onSubmit callback
                onSubmit();
            });
        });
    };

    return (
        <Alignment column>
            {/* Feeedback Type Selector */}
            <Select 
                disabled={forceDefault} 
                value={category} 
                onChange={event => setCategory(event.target.value as FeedbackCategory)} 
            >
                <MenuItem value={FeedbackCategory.None}>{"Select Feedback Category"}</MenuItem>
                <MenuItem value={FeedbackCategory.BugReport}>{"Report a Bug/Issue with the Website"}</MenuItem>
                <MenuItem value={FeedbackCategory.NewFeature}>{"Request a New Feature for the Website"}</MenuItem>
                <MenuItem value={FeedbackCategory.GeneralWebsite}>{"General Website Feedback"}</MenuItem>
                <MenuItem value={FeedbackCategory.EaseOfUse}>{"Comment on Ease of Use of Website"}</MenuItem>
                <MenuItem value={FeedbackCategory.CompanyFeedback}>{"Company Feedback"}</MenuItem>
                <MenuItem value={FeedbackCategory.CompanySuggestion}>{"Company Suggestion"}</MenuItem>
            </Select>
            <Spacer vertical size={"10px"}/>

            {/* User Feedback Field */}
            <TextField
                variant={"outlined"}
                multiline
                rows={10}
                value={feedback}
                onChange={event => setFeedback(event.target.value)}
            />
            <Spacer vertical size={"10px"}/>

            {/* User Uploaded Attachments */}
            <div className={classes.attachments}>
                {attachments.map((value, index) => (
                    <Chip
                        className={classes.attachmentChip}
                        key={index}
                        label={value.name}
                        onDelete={() => { setAttachments(attachments.filter(x => x.name !== value.name)); }}
                    />
                ))}
            </div>
            {attachments.length === 0 ? null : <Spacer vertical size={"10px"}/>}
            
            {/* Attachments Button */}
            <FileImporter
                color={"primary"}
                variant={"contained"}
                icon={AttachFileIcon}
                text={"Attach File(s)"}
                onImport={files => setAttachments([...attachments, ...files])}
                multiple
            />
            <Spacer vertical size={"10px"}/>
            
            {/* Submit Feedback Button */}
            <Button
                disabled={category === FeedbackCategory.None}
                className={classes.submit}
                color={"primary"}
                variant={"contained"}
                onClick={submit}
            >{"Submit Feedback"}</Button>
        </Alignment>
    );
};