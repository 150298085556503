import { memo, forwardRef } from 'react';
import { MuiForm, MuiFormPartial, MuiFormRef, useMuiForm , Editable } from '@imas/mui-form';
import { Client, ClientForm as TClientForm } from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { useCallbackRef } from '@imas/utils/hooks';
import { ClientARDetails } from './ClientARDetails';
import { ClientCorporateInfo } from './ClientCorporateInfo';
import { ClientLocations } from './ClientLocations';
import { ClientName } from './ClientName';
import { ClientNotes } from './ClientNotes';
import { ClientPaymentInfo } from './ClientPaymentInfo';
import { ClientPurchaseOrders } from './ClientPurchaseOrders';
import { ClientPreferences } from './ClientPreferences';

const defaultValues: Editable<TClientForm> = {
    name: null,
    abbrev: null,
    address: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    webpage: null,
	revenueMM: null,
	revenueMMAsOfDate: null,
    notes: null,
    active: true,
    clientCui: false,
    creditApproved: false,
    paymentTerms: null,
    badCredit: false,
    clientShort: null,
    correspondencetype: 0,
    signaturemethodtype: 0
};

export interface ClientFormValidatorRef {
    clients: Client[];
};

export const ClientForm = memo(forwardRef<MuiFormRef<TClientForm>, MuiFormPartial & { client?: Client | null; }>(({ client, ...props }, ref) => {
    //get a callback ref for the ValidatorRef
    const [validatorRef, onValidatorRef] = useCallbackRef<ClientFormValidatorRef>();

    //form hook
    const { muiFormProps } = useMuiForm({ 
        defaultValues, context: validatorRef ?? undefined,
        validator: ({ name, creditApproved, paymentTerms, clientShort, correspondencetype, signaturemethodtype, address1, address2,city, zip, ...otherFields }, error, context) => {
            //check if there similarNames
            if (name && context) {
                //try to find a exact name match
                if (context.clients.map(x => x.name.toLowerCase()).includes(name.toLowerCase())) {
                    //show error
                    error("name", "conflict", `There is already an client named '${name}'.`);
                } 
            }

            if(correspondencetype === null)
            {
                error("correspondencetype", "required");
                return;
            }
            if(signaturemethodtype === null)
            {
                error("signaturemethodtype", "required");
                return;
            }

			//if creditApproved is true then paymentTerms is required
			if (creditApproved) {
				if (paymentTerms === null) error("paymentTerms", "required");
			}

            //if clientShort is not empty
            if(clientShort !== null){
                //limit size of client short
                if(clientShort.length > 30)
                {
                    //show error
                    error("clientShort", "maxLength", 'Length of short name is too long.');
                }

            }

            if(address1 !== null)
            {
                if(address1.length > 30)
                {
                    error("address1", "maxLength", 'Length of Address is too long');
                }
            }

            if(address2 !== null)
            {
                if(address2.length > 60)
                {
                    error("address2", "maxLength", "Length of Suite/Other is too long");
                }

            }
            if( city !== null)
            {
                if(city.length > 30)
                {
                    error("city", "maxLength", "Length of City is too long");
                }
            }
            if( zip !== null)
            {
                if(zip.length > 10)
                {
                    error("zip", "maxLength", "Length of Zip is too long");
                }
            }
            //check if there are similar short names
            if(clientShort && context){
                //try to find an exact match
                if(context.clients.map(x => x.clientShort?.toLowerCase()).includes(clientShort.toLowerCase())){
                    //show error
                    error("clientShort", "conflict", `There is already a short name called '${clientShort}'.`);
                }
                clientShort = clientShort.trimEnd();
            }

            

            //ensure required fields are required
            if (name === null) error("name", "required");

            //ensure type safety
            if (name === null) return;

            return { name, creditApproved, paymentTerms, clientShort, correspondencetype, signaturemethodtype, address1, address2, city, zip, ...otherFields };
        },
		onSuccessfulSubmit: () => {
		}
    });

    //separate loading from other props
    const { loading, viewOnly, ...otherProps } = props;

    //form data
    return (
        <MuiForm
            loading={loading}
            viewOnly={viewOnly}
            {...otherProps}
            {...muiFormProps}
            ref={ref}
        >
			{/* Client Name Field */}
			<ClientName client={client} ref={onValidatorRef}/>

			<Alignment row sx={{ marginTop: '15px', color: 'text.primary' }}>
				<Alignment column sx={{ width: '400px', minWidth: '400px' }}>
					{/* Corporate Info */}
					<ClientCorporateInfo/>
				</Alignment>

				<Alignment column flex sx={{ marginLeft: '10px' }}>
					<Alignment row>
						{/* Payment Info */}
						<Alignment column sx={{ minWidth: '250px' }}>
							<ClientPaymentInfo/>
						</Alignment>
						
						{/* A/R Details */}
						<Alignment column flex sx={{ marginLeft: '10px' }}>
							<ClientARDetails client={client}/>
						</Alignment>
					</Alignment>
					{/* Preferences */}
                    <Alignment >
                        <ClientPreferences/>
                    </Alignment>
					{/* Purchase Orders */}
					<Alignment flex column sx={{ marginTop: '10px' }}>
						<ClientPurchaseOrders client={client}/>
					</Alignment>
				</Alignment>
			</Alignment>

			{/* Client Notes */}
			<Alignment column sx={{ marginTop: '10px', flex: '2 1 0' }}>
				<ClientNotes client={client}/>
			</Alignment>

			{/* Client Locations */}
			<Alignment column sx={{ marginTop: '10px', flex: '3 1 0' }}>
				<ClientLocations client={client}/>
			</Alignment>
        </MuiForm>
    );
}));