import { Alignment, Spacer } from '@imas/components/layout';
import {FormTextField, useMuiFormContext } from '@imas/mui-form';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../VehicleInspectionStyles';
import { VehicleInspectionRow } from './VehicleInspectionRow/VehicleInspectionRow';
import { VehicleInspectionHeader } from './VehicleInspectionRow/VehicleInspectionHeader';


export const VehicleInspectionFormLights = memo(() => {

	const { classes } = VehicleInspectionStyles();

	const { control } = useMuiFormContext<TVehicleInspectionForm>();

	return(
		
		<Alignment column>

			<Spacer vertical/>

			<Alignment row>
				{/* Lights Title */}
				<Typography className={classes.title} align={'center'} fontWeight={"bold"} variant={"h5"}>{"Lights"}</Typography>

			</Alignment>

			<VehicleInspectionHeader/>

			<VehicleInspectionRow label={'Head Lights:'} variable={'headLight'} />
			<VehicleInspectionRow label={'Turn Signal Lights:'} variable={'turnSignalLight'} />
			<VehicleInspectionRow label={'Reverse/Back-up Lights:'} variable={'reverseLight'} />
			<VehicleInspectionRow label={'Backup Alarm*:'} variable={'backupAlarm'} />
			<VehicleInspectionRow label={'Brake Lights:'} variable={'tailLight'} />
			<VehicleInspectionRow label={'Hazard Lights:'} variable={'hazardLight'} />
			<VehicleInspectionRow label={'License Lights:'} variable={'licenseLight'} />
			<VehicleInspectionRow label={'Strobe Lights*:'} variable={'strobeLight'} />

			{/* Notes Field */}
			<FormTextField
				control={control}
				name={'lightNotes'}
				label={'Lights Notes'}
				fullWidth
				inputProps={{maxLength: 255}}
				multiline
				minRows={2}
			/>
			
		</Alignment>

	);
});
