import { FileTableFile } from './../files/types/file/FileTableFile';
import { ApiConfig, mapApi } from  '@imas/api';
import { vWorkClass } from '../system';

const USER_BASE_URL = '/api/user';

/*  */
export const GetUserSignature = mapApi((): ApiConfig<FileTableFile> => ({
	method: 'get',
	url: `${USER_BASE_URL}/signature`,
}));

export const GetUserSignatureByID = mapApi((id: int): ApiConfig<FileTableFile> => ({
	method: 'get',
	url: `${USER_BASE_URL}/signature/${id}`,
}));

/*  */
export const GetUserWorkClass = mapApi((): ApiConfig<vWorkClass> => ({
	method: 'get',
	url: `${USER_BASE_URL}/work-class`,
}));

/*  */
export const GetUserHourTypeEligible = mapApi((hourTypeId: int): ApiConfig<boolean> => ({
	method: 'get',
	url: `${USER_BASE_URL}/hour-types/${hourTypeId}/eligible`,
}));