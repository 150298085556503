import axios, { AxiosRequestConfig } from 'axios';
import { objectToFormData } from './objectToFormData';

//utility function which handles calling axios and unwrapping the response data 
export async function axiosData<T>(config: AxiosRequestConfig): Promise<T> {
    return (await axios(config)).data;
};

//utility function which handles calling axios and unwrapping the response data, but if the response is a 204 then it returns null instead 
export async function axiosNullableData<T>(config: AxiosRequestConfig): Promise<T | null> {
    const response = await axios(config);

	if (response.status === 204) return null;
	return response.data;
};

//utility function which handles calling axios and unwrapping the response data & encodes the data attribute provided as FormData and modifies the Content-Type to 'multipart-form-data'. 
export async function axiosFormData<T>(config: AxiosRequestConfig): Promise<T> {
    return axiosData({
		...config,
		data: objectToFormData(config.data),
		headers: { ...config.headers, "Content-Type": "multipart/form-data" },
	});
};

//returns file name from the content-disposition header
const getFileNameFromContentDisposition = (disposition: string | null): string => {
    //source: https://stackoverflow.com/a/67994693

    //match patterns for different file name formats
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    //default filename
    let fileName: string = "download";
    if (disposition === null) return fileName;

    //try to get the utf8 formatted filename
    if (utf8FilenameRegex.test(disposition)) {
        const matches = utf8FilenameRegex.exec(disposition);
        if (matches && matches[1]) fileName = decodeURIComponent(matches[1]);
    }

    //try to get the ascii filename
    else {
        const matches = asciiFilenameRegex.exec(disposition);
        if (matches && matches[2]) fileName = matches[2];
    }

    //return the result
    return fileName;
};

//utility function which handles calling axios and unwrapping the response data, but returns a File object 
export async function axiosFile(config: AxiosRequestConfig): Promise<File> {
	try {
		//make API call and return a promise which resolves into the result
		const response = await axios({
			...config,
			responseType: 'blob',
		});

		//get content-disposition header
		const contentDisposition = response.headers["content-disposition"] as string | null;

		//get filename from content-disposition header
		const filename = getFileNameFromContentDisposition(contentDisposition);

		//file blob
		const blob = response.data as Blob;

		//resolve promise
		return new File([blob], filename, { type: blob.type });
	} catch (e) {
		if (axios.isAxiosError(e)) {
			if (e.response?.data instanceof Blob) {
				e.response.data = JSON.parse(await e.response.data.text());
				
				//re-throw error with error response data converted to json
				throw e;
			}
		}

		throw e;
	}
};