import { swShowNotification } from './../utils';
import { WebPushMessage, WebPushMessageType, NotificationType } from './../types';

export const handleCheckInReminder = async (sw: ServiceWorkerGlobalScope, message: WebPushMessage<WebPushMessageType.CheckInReminder>) => {
    const data = message.payload;
    
    //show the announcement notification
    await swShowNotification(sw, "You have been checked in for " + data.hours + " hours.", NotificationType.CheckInReminder, {
        renotify: true,
        requireInteraction: true
    }, message.messageId, message.dev);
};