import { CalibrationProcedureQualReq } from './types/operational-procedures/CalibrationProcedureQualReq';
import { SourceGetter, axiosData } from '../_internals';

//api base url
const CALIBRATION_PROCEDURE_QUAL_REQS_URL = "/api/certs-and-qc/cal-proc-qual-reqs";

/* Get CalibrationProcedureQualReqs for a procedure */
export const GetCalibrationProcedureQualReqs = (getSource: SourceGetter) => {
    return async (procId: int): Promise<CalibrationProcedureQualReq[]> => {
        return axiosData({
			method: 'get',
            url: `${CALIBRATION_PROCEDURE_QUAL_REQS_URL}/${procId}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create a CalibrationProcedureQualReqs */
export const CreateCalibrationProcedureQualReq = (getSource: SourceGetter) => {
    return async (procId: int, qualReq: string): Promise<CalibrationProcedureQualReq> => {
        return axiosData({
			method: 'post',
            url: `${CALIBRATION_PROCEDURE_QUAL_REQS_URL}`,
			data: {
				procId,
				qualReq,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Update a CalibrationProcedureQualReqs */
export const UpdateCalibrationProcedureQualReq = (getSource: SourceGetter) => {
    return async (qualReqJson: CalibrationProcedureQualReq): Promise<CalibrationProcedureQualReq> => {
        return axiosData({
			method: 'put',
            url: `${CALIBRATION_PROCEDURE_QUAL_REQS_URL}`,
			data: qualReqJson,
            cancelToken: getSource().token,
		});
    };
};

/* Delete a CalibrationProcedureQualReqs */
export const DeleteCalibrationProcedureQualReq = (getSource: SourceGetter) => {
    return async (calProcedureQualReqId: int): Promise<void> => {
        return axiosData({
			method: 'delete',
            url: `${CALIBRATION_PROCEDURE_QUAL_REQS_URL}/${calProcedureQualReqId}`,
            cancelToken: getSource().token,
		});
    };
};