import { Alignment } from '@imas/components/layout';
import { useApi, useLoadApi } from '@imas/api';
import { ClientLocationContact, GetClient, GetClientContacts, GetClientLocationClient, ClientLocationContactForm as TClientLocationContactForm } from '@imas/api/client';
import { useClients, useClientLocations } from '@imas/api/data-hooks';
import { useIsElementActive } from '@imas/utils/hooks';
import { Typography, TextField, Tooltip, List, ListItem, ListItemText, IconButton, Autocomplete } from '@mui/material';
import { Help as HelpIcon, Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { FormTextField, FormAutocomplete, FormCheckbox, useMuiFormContext, useMuiWatch } from '@imas/mui-form';
import { memo, useMemo, useState, useEffect } from 'react';
import { formatAddress } from '@imas/utils/formatting';
import { CLIENTS_AUTOCOMPLETE_VIRTUALIZER } from '@imas/utils/virtualization';
import { useEditor } from '@imas/utils/editor/useEditor';
import { openClientEditor as clientEditorOpener, openClientLocationEditor as clientLocationEditorOpener } from 'src/pages/editors';
import Fuse from 'fuse.js';
import Enumerable from 'linq';

interface ClientAndLocationPickerProps {
	clientId: int | null;
	locationId: int | null;
	contact?: ClientLocationContact | null;
};

export const ContactLocationAndClientAndName = memo((({ clientId: initClientId, locationId: initLocationId, contact }: ClientAndLocationPickerProps) => {
	//use form control
	const { control, setValue, isLoading } = useMuiFormContext<TClientLocationContactForm>();

	//use api for getting client
	const getClient = useApi(GetClient);
	//use api for getting client from location
	const getLocationClient = useApi(GetClientLocationClient);

	//internal clientId value
	const [clientId, setClientId] = useState(initClientId);

	//use client editor
	const [clientEditorOpen, openClientEditor] = useEditor({
		url: "/clients",
		opener: clientEditorOpener,
		onUpdate: (type, client) => {
			//if a new record was created from the opened editor then populate the clientId
			if (type === "CREATE") {
				setClientId(client.id);
				setValue("locationId", null);
			}
		},
	});

	//use location editor
	const [locationEditorOpen, openLocationEditor] = useEditor({
		url: "/clients/locations",
		opener: clientLocationEditorOpener,
		onUpdate: (type, location) => {
			//if a new record was created from the opened editor then populate the locationId field & clientId state
			if (type === "CREATE") {
				setClientId(location.clientId);
				setValue("locationId", location.id);
			}
		},
	});

	//check if the contact name input is the currently focused element
	const [contactNameFocused, contactNameRef] = useIsElementActive<HTMLInputElement>();

	//if the similar names tooltip should be open
	const [similarNamesTooltipOpen, setSimilarNamesTooltipOpen] = useState<boolean>(false);

	//subscribe to updates
	const [locationId, contactName] = useMuiWatch({ control, name: ["locationId", "name"] });

	//when clientID changes update correspondanceType and signatureMethodType
	useEffect(() => {
		if(clientId === null || !!contact) return;
		
		(async () => {
			const client = await getClient(clientId);
			setValue("correspondencetype", client.correspondencetype);
			setValue("signaturemethodtype", client.signaturemethodtype);
		})().catch(() => {});

 	}, [clientId, contact, getClient]);
	//when locationId changes update clientId
	useEffect(() => {
		if (locationId === null) return;

		(async () => {
			const client = await getLocationClient(locationId);
			setClientId(client.id);
		})().catch(() => {});
	}, [locationId, getLocationClient, setClientId]);

	//get all clients
	const [clients] = useClients();

	//get all locations for the selected client
	const [locations] = useClientLocations(clientId, { disabled: clientId === null });

	//get all contacts for the selected client
	const { data: contacts } = useLoadApi(GetClientContacts, [clientId], [clientId], { disabled: clientId === null });

	//filter contacts to remove the contact currently being edited (if in edit mode)
	const filteredContacts = useMemo(() => (contacts ?? []).filter(x => x.id !== contact?.id), [contacts, contact]);

	//get contacts with a similar name
	const similarContacts = useMemo(() => {
		if (contactName === null) return [];

		//crease a fuse object for the list of schedule entries
		const fuse = new Fuse<ClientLocationContact>(filteredContacts ?? [], {
			includeMatches: true,
			includeScore: true,
			keys: [{
				name: "name",
				weight: 3,
			}, "first", "last", "preferredFirst"],
		});
		
		return Enumerable.from(fuse.search(contactName)).orderBy(x => x.score ?? 0).select(x => x.item).toArray();
	}, [filteredContacts, contactName]);

	//get helper text for name input
	const similarNameHelperText: React.ReactNode = useMemo(() => {
		//if there is more than 0 similar locations let the user know they exist and what they are
		if (similarContacts.length > 0) {
			//get a copy of similarContacts
			const remainingContacts = [...similarContacts]; 

			//get the first 10 contacts
			const firstTenContacts = remainingContacts.splice(0, 10);

			//title
			const title = (
				<List dense>
					{firstTenContacts.map(contact => (
						<ListItem key={contact.id} sx={{ padding: '0' }}><ListItemText primary={contact.name}/></ListItem>
					))}
					{remainingContacts.length > 0 ? <ListItem sx={{ padding: '0' }}><ListItemText primary={`and ${remainingContacts.length} more...`}/></ListItem> : null}
				</List>
			);

			//get the # of exact name matchs and # of similar names
			const exactNameMatches = similarContacts.filter(x => x.name.toLowerCase() === (contactName ?? "").toLowerCase()).length;
			const similarNames = similarContacts.length - exactNameMatches; 

			return (
				<>
					{`There ${similarNames > 1 ? "are" : "is"} ${similarNames} contact${similarNames > 1 ? "s" : ""} with a similar name${exactNameMatches > 0 ? ` and ${exactNameMatches} contact${exactNameMatches > 1 ? "s" : ""} with the same name` : ""}.`}
					<Tooltip
						open={similarNamesTooltipOpen || contactNameFocused} 
						title={title} 
						onOpen={() => setSimilarNamesTooltipOpen(true)}
						onClose={() => setSimilarNamesTooltipOpen(false)}
						arrow
					>
						<HelpIcon sx={{ margin: '0 0 -3px 5px', height: '15px', width: '15px' }}/>
					</Tooltip>
				</>
			);
		}
		return undefined;
	}, [similarContacts, contactNameFocused, contactName, similarNamesTooltipOpen, setSimilarNamesTooltipOpen]);

	//selected client
	const client = useMemo(() => (clients ?? []).find(x => x.id === clientId) ?? null, [clientId, clients]);

	//selected location
	const location = useMemo(() => (locations ?? []).find(x => x.id === locationId) ?? null, [locationId, locations]);

	return (
		<Alignment column>
			<Alignment row sx={{ marginBottom: '10px' }}>
				{/* Client Field (Not Part of Form) */}
				<Autocomplete
					loading={isLoading || !clients || (locationId !== null && !client)}
					autoComplete
					autoHighlight
					fullWidth
					disableClearable
					disabled={contact !== null || initClientId !== null || initLocationId !== null}
					options={clients ?? []}
					value={((clients ?? []).find(client =>  client.id === clientId) ?? null) as any}
					onChange={(_, client) => {
						setClientId(client.id);
					}}
					getOptionLabel={(client) => client.name}
					getOptionDisabled={(client) => !client.active}
					renderInput={(params) => <TextField {...params} label={"Client"} required error={clientId === null}/>}
					sx={{ height: '37px', "& .MuiInputBase-input": { fontSize: '1.5em', padding: '0 8px' } }}

					//add virtualization
					{...CLIENTS_AUTOCOMPLETE_VIRTUALIZER}
				/>

				{/* Edit Selected Client Button */}
				<Tooltip title={`Edit '${client?.name ?? "Loading..."}'`} arrow>
					<IconButton
						disabled={isLoading || clientEditorOpen || client === null}
						onClick={() => {
							if (client === null) return;

							//open editor for selected client
							openClientEditor(client.id);
						}}
						sx={{ margin: 'auto 0 auto 10px' }}
					><EditIcon/></IconButton>
				</Tooltip>

				{/* New Client Button */}
				<Tooltip title={`New Client`} arrow>
					<IconButton
						disabled={isLoading || clientEditorOpen || contact !== null || initClientId !== null}
						onClick={() => {
							//open editor for selected client
							openClientEditor();
						}}
						sx={{ margin: 'auto 0 auto 10px' }}
					><AddIcon/></IconButton>
				</Tooltip>
			</Alignment>

			<Alignment row sx={{ marginBottom: '10px' }}>
				{/* Location Field */}
				<FormAutocomplete
					control={control}
					name={"locationId"}
					optionsLoading={clientId !== null && !locations}
					required
					autoComplete
					autoHighlight
					fullWidth
					disableClearable
					disabled={client === null}
					options={locations ?? []}
					getValue={(locationId, options) => options.find((x) => x.id === locationId)}
					onChange={(location) => {
						if (location) setClientId(location.clientId);
						return location?.id ?? null;
					}}
					getOptionLabel={(location) => location.name}
					getOptionDisabled={(location) => !location.active}
					renderInput={(params) => <TextField {...params} label={"Location"}/>}
					sx={{ height: '37px', "& .MuiInputBase-input": { fontSize: '1.5em', padding: '0 8px' } }}

					//add virtualization
					//{...CLIENTS_AUTOCOMPLETE_VIRTUALIZER}
				/>

				{/* Edit Selected Location Button */}
				<Tooltip title={`Edit '${location?.name ?? "Loading..."}'`} arrow>
					<IconButton
						disabled={isLoading || locationEditorOpen || location === null}
						onClick={() => {
							if (location === null) return;

							//open editor for selected location
							openLocationEditor({ locationId: location.id });
						}}
						sx={{ margin: 'auto 0 auto 10px' }}
					><EditIcon/></IconButton>
				</Tooltip>

				{/* New Location Button */}
				<Tooltip title={`New Location`} arrow>
					<IconButton
						disabled={isLoading || locationEditorOpen || contact !== null || initLocationId !== null}
						onClick={() => {
							//open editor for with the selected client
							openLocationEditor({ clientId });
						}}
						sx={{ margin: 'auto 0 auto 10px' }}
					><AddIcon/></IconButton>
				</Tooltip>
			</Alignment>

			{/* Location Billing Address */}
			<Alignment row sx={{ color: 'text.primary', marginLeft: '25px', marginBottom: '10px' }}>
				<Typography variant={"body1"} sx={{ fontWeight: 'bold', marginRight: '10px' }}>{"Billing Address:"}</Typography>
				<Typography variant={"body1"}>{location ? formatAddress({ address1: location.billingAddress1, city: location.billingCity, state: location.billingState, zip: location.billingZip }) : "--"}</Typography>
			</Alignment>

			{/* Location Business Address */}
			<Alignment row sx={{ color: 'text.primary', marginLeft: '25px', marginBottom: '10px' }}>
				<Typography variant={"body1"} sx={{ fontWeight: 'bold', marginRight: '10px' }}>{"Business Address:"}</Typography>
				<Typography variant={"body1"}>{location ? formatAddress({ address1: location.buisnessAddress1, city: location.businessCity, state: location.businessState, zip: location.businessZip }) : "--"}</Typography>
			</Alignment>

			{/* Contact Name */}
			<Alignment row sx={{ marginTop: '15px' }}>
				<FormTextField
					control={control}
					name={"name"}
					label={"Contact (Display Name)"}
					required
					fullWidth
					disabled={locationId === null}
					FormHelperTextProps={{ sx: { color: 'warning.main'} }}
					helperText={similarNameHelperText}
					onBlur={({ lastValue, currentValue: value, setValue, getValues }) => {
						if (lastValue === null && value !== null) {
							//extract first/last from display name
							const [newFirst, newLast] = value.split(" ");

							//get current first & last name values
							const [first, last] = getValues(["first", "last"]);
							
							//update first / last name if they are empty
							if (first === null && typeof newFirst === "string") setValue("first", newFirst);
							if (last === null && typeof newLast === "string") setValue("last", newLast);
						}
					}}
					sx={{ height: '54px', "& .MuiInputBase-input": { fontSize: '2em', padding: '5px 10px' } }}
					ref={contactNameRef}
				/>

				{/* Active Field */}
				{contact === null ? null : (
					<FormCheckbox
						name={"active"}
						control={control}
						label={"Active"}
						sx={{ margin: 'auto 5px auto 10px'}}
					/> 
				)}
			</Alignment>
		</Alignment>
	);
}));
