import { EmployeeBasics, vEmployee } from "@imas/api/employee";
import { EmployeeDirectoryRecord } from "src/pages/Home/EmployeeDirectory/EmployeeDirectory";

//used for business cards
export const EmployeeToVCard = (employee: EmployeeDirectoryRecord | vEmployee) => {
    if ('arraCert' in employee) return vEmployeeToVCard(employee);
    else return EmployeeDirectoryRecordToVCard(employee);
};

export const GetVCardFile = (employee: EmployeeDirectoryRecord) => {
    const vCard = EmployeeDirectoryRecordToSyncVCard(employee);
    //let blob = new Blob([vCard], 'text');
    return new File([vCard], `${employee.name} Directory.vcf`);
};

export const GetMasterVCF = (employees: EmployeeBasics[]) => {
    let vCardList = "";
    employees.forEach((employee) => {
        vCardList = vCardList + EmployeeBasicsToSyncVCard(employee) + "\n";
    });

    return new File([vCardList], `PNL Directory.vcf`);
};

//Business Card
export const EmployeeDirectoryRecordToVCard = (employee: EmployeeDirectoryRecord) => {
    //vcard info, tabs replaced for it to work correctly
    const vCard = 
    `BEGIN:VCARD
        VERSION:3.0
        FN:${employee.name}
        N:${employee.last};${employee.first};;;${employee.suffix ?? ""}
        EMAIL;TYPE=Work:${employee.pnlEmail === '--' ? "" : employee.pnlEmail}
        TEL;TYPE=Mobile:${employee.mobilePhone}
        TEL;TYPE=Work:602-431-8887
        TEL;TYPE=Work Fax:602-431-8889
        TITLE:${employee.title}
        URL:https://pnltest.com
        ADR;TYPE=Work:;;941 South Park Lane;Tempe;Arizona;85281;USA
        ORG:Phoenix National Laboratories
        UID:${employee.id}
    END:VCARD`.replaceAll("    ", "");   

    return vCard;
};

//Business Card
export const vEmployeeToVCard = (employee: vEmployee) => {
    //vcard info, tabs replaced for it to work correctly
    const vCard = 
    `BEGIN:VCARD
        VERSION:3.0
        FN:${employee.name}
        N:${employee.last};${employee.first};;;${employee.suffix ?? ""}
        EMAIL;TYPE=Work:${employee.email ?? ""}
        TEL;TYPE=Mobile:${employee.mobile ?? ""}
        TEL;TYPE=Work:602-431-8887
        TEL;TYPE=Work Fax:602-431-8889
        TITLE:${employee.title}
        URL:https://pnltest.com
        ADR;TYPE=Work:;;941 South Park Lane;Tempe;Arizona;85281;USA
        ORG:Phoenix National Laboratories
        UID:${employee.employeeId}
    END:VCARD`.replaceAll("    ", ""); 

    return vCard;
};

//Employee Contact Info
export const EmployeeDirectoryRecordToSyncVCard = (employee: EmployeeDirectoryRecord) => {
    //vcard info, tabs replaced for it to work correctly
    const vCard = 
    `BEGIN:VCARD
        VERSION:3.0
        FN:${employee.name}
        N:${employee.last};${employee.first};;;${employee.suffix ?? ""}
        EMAIL;TYPE=Work:${employee.pnlEmail === '--' ? "" : employee.pnlEmail}
        TEL;TYPE=Mobile:${employee.mobilePhone}
        TITLE:${employee.title}
        URL:https://pnltest.com
        ORG:Phoenix National Laboratories;${depIdSwitch(employee.departmentId)}
        UID:${employee.id}
    END:VCARD`.replaceAll("    ", "");   

    return vCard;
};

//These are used for the mass contact sync
export const EmployeeBasicsToSyncVCard = (employee: EmployeeBasics) => {
    //vcard info, tabs replaced for it to work correctly
    const vCard = 
    `BEGIN:VCARD
        VERSION:3.0
        FN:${employee.name}
        N:${employee.last};${employee.first};;;${employee.suffix ?? ""}
        EMAIL;TYPE=Work:${employee.pnlEmail ?? ""}
        TEL;TYPE=Mobile:${employee.mobilePhone ?? ""}
        TITLE:${employee.title}
        URL:https://pnltest.com
        ORG:Phoenix National Laboratories;${depIdSwitch(employee.departmentId)}
        UID:${employee.id}
    END:VCARD`.replaceAll("    ", "");   

    return vCard;
};

const depIdSwitch = (depId: int) => {
    switch (depId) {
        case 0: return "Corporate";
        case 4: return "Nondestructive";
        case 5: return "Welding Technology";
        case 6: return "Physical/Mechanical";
        case 7: return "Metallurgical/Materials";
        default: return "";
    }
};