
import { darken } from '@mui/material';
import { makeDeviceStyles } from '@imas/styles';

export const checkInStyles = makeDeviceStyles()((theme) => ({
    checkInContainer: {
        width: '-webkit-fill-available',
        
    },
    checkInBtn: {
        width: '-webkit-fill-available',
        margin: '5px',
    },
    undoCheckInBtn: {
        margin: '5px 5px 5px 0',
    },
    dialog: {
        "& .MuiDialog-paper": {
            width: '-webkit-fill-available',
        },
    },
    select: {
        width: '-webkit-fill-available',
    },
    checkingIntoSite: {
        background: theme.palette.mode === 'light' ? theme.palette.info.main : theme.palette.info.dark,
        "&:hover": {
            background: darken(theme.palette.mode === 'light' ? theme.palette.info.main : theme.palette.info.dark, 0.10),
        },
    },
    checkingOutOfSite: {
        background: theme.palette.mode === 'light' ? theme.palette.warning.main : theme.palette.warning.dark,
        "&:hover": {
            background: darken(theme.palette.mode === 'light' ? theme.palette.warning.main : theme.palette.warning.dark, 0.10),
        },
    },
}));