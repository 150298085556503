import { makeDeviceStyles } from '@imas/styles';

export const VehicleInspectionStyles = makeDeviceStyles()((theme) => ({
	title: {
		color: theme.palette.text.primary,
		fontWeight: 'bold',
		width: '-webkit-fill-available'
	},
	leftTypography: {
		color: theme.palette.text.primary,
		width: '-webkit-fill-available',
		margin: 'auto 0 auto 0',
		fontWeight: 'bold',
	},
	headerTypography: {
		color: theme.palette.text.primary,
		marginTop: 'auto',
		height: '24px',
		fontWeight: 'bold',
	},
	label: {
		color: theme.palette.text.primary,
		margin: 'auto auto auto auto',
	},
	textField: {
		width: '250px',
	},
	radioButton: {
		margin: 'auto auto auto auto',
		"& .mui-dk2fdp-MuiButtonBase-root-MuiRadio-root.Mui-disabled": {
			color: "red",
		},
	},
	plainText: {
		color: theme.palette.text.primary,
	},
	redRadioButton: {
		color: 'red',
		margin: 'auto auto auto auto',
		'&$disabled': {
			color: '#4B8DF8'
		},
		"& .MuiInputBase-input.Mui-disabled": {
			color: "red",
		},
		"& .mui-dk2fdp-MuiButtonBase-root-MuiRadio-root.Mui-disabled": {
			color: "red",
		},
	}

}));