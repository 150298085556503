import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { getGridNumericOperators } from '@mui/x-data-grid-pro';
import { TypedGridColTypeDef, TypedGridRenderEditCellParams } from '../typed-grid';
import { NumberInputBase, NumberInputBaseProps } from '@imas/components/inputs';
import { experimental_sx as sx } from '@mui/system';
import { cellStyles } from './utils';
import clsx from 'clsx';

//styled NumberInputBase
export const NumberInputEditCell = styled(NumberInputBase)(
    sx({
        typography: 'body2',
        "& > input": {
            padding: '0 8px',
        },
    })
);

export interface IntegerEditCellProps extends TypedGridRenderEditCellParams<{ id: string | number; value: number; }, { value: number; }, never, "value", "cell">, Pick<NumberInputBaseProps, "thousandSeparator" | "prefix" | "allowNegative"> {
    validator?: (value: int) => boolean;
};

export const IntegerEditCell = (props: IntegerEditCellProps) => {
    //get styles
    const { classes } = cellStyles();

    //input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (props?.hasFocus) ref.current?.focus(); }, [props?.hasFocus]);

    //get input props
    const { thousandSeparator, prefix, allowNegative, validator } = props;

    //get GridRenderEditCellParams
    const { id, value, field, api } = props;

    //check if the value is valid
    const isValid = !isNaN(Number(value)) && (validator ? validator(Number(value)) : true);

    return (
        <NumberInputEditCell
            value={Number(value)}
            onValueChange={({ floatValue }) => {
                api.setEditCellValue({ id, field, value: floatValue } as any);
            }}
            fullWidth
            className={clsx({ [classes.error]: !isValid })}
            ref={ref}
            {...{ thousandSeparator, prefix, allowNegative }}
        />
    );
};

export const INTEGER_COL: TypedGridColTypeDef<number>  = {
    type: 'number',
    renderEditCell: (props) => <IntegerEditCell {...props}/>,
    filterOperators: getGridNumericOperators().filter(operator => operator.value === '>' || operator.value === '<' || operator.value === '>=' || operator.value === '<=' || operator.value === '=') as any,
};