import { Moment } from 'moment';
import { SourceGetter } from '@imas/api';
import axios from 'axios';
import { EmployeeWorkEvent } from 'src/api/types/api/employee/employee';
import { getDeviceString } from '@imas/utils/device';

/**
 * Checks the checked-in status of the user.
 */
 export const _CheckedInStatus = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (): Promise<EmployeeWorkEvent | null> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeWorkEvent | null>((resolve, reject) => {
            //make API request
            axios({
                method: 'get',
                url: '/api/employee/check-in',
                cancelToken: source.token
            }).then((response) => {
                if (response.status === 204) return resolve(null);

                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Check-In / Check Out user
 */
 export const _CheckIn = (getSource: SourceGetter) => {
    //return the wrapped API function
    return ({ 
        empEventId, workId, workTypeId, workLocationTypeId, onSite, intoJob, latitude, longitude, accuracy 
    }: { 
        empEventId?: int, workId?:int, workTypeId?: int, workLocationTypeId?: int, onSite?: boolean, intoJob?: boolean, latitude: number, longitude: number, accuracy: number 
    }): Promise<EmployeeWorkEvent[]> => {
        //get a source for the request
        const source = getSource();
        
        //create a promise for handling the result
        return new Promise<EmployeeWorkEvent[]>((resolve, reject) => {
            getDeviceString()
            .then(deviceStr => {
                //make API request
                axios({
                    method: 'post',
                    url: '/api/employee/check-in',
                    data: {
                        empEventId: empEventId ?? null,
                        workId: workId ?? null,
                        workTypeId: workTypeId ?? null,
                        workLocationTypeId: workLocationTypeId ?? null,
                        onSite: onSite ?? null,
						intoJob: intoJob ?? false,
                        latitude,
                        longitude,
                        accuracy,
                        device: deviceStr
                    },
                    cancelToken: source.token
                }).then(response => {
                    //resolve the promise
                    resolve(response.data);
                }).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    };
};

/**
 * Checks if there is a check-in event which can be un-done
 */
 export const _CheckInHistory = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (fromDate: Moment, toDate: Moment): Promise<EmployeeWorkEvent[]> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeWorkEvent[]>((resolve, reject) => {
            //make API request
            axios({
                method: 'get',
                url: '/api/employee/check-in/history',
                params: {
                    fromDate: fromDate.format(),
                    toDate: toDate.format()
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Checks if there is a check-in event which can be un-done
 */
 export const _UndoCheckInStatus = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (): Promise<EmployeeWorkEvent | null> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<EmployeeWorkEvent | null>((resolve, reject) => {
            //make API request
            axios({
                method: 'get',
                url: '/api/employee/check-in/undo',
                cancelToken: source.token
            }).then((response) => {
                if (response.status === 204) return resolve(null);

                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Undo a check-in event
 */
 export const _UndoCheckIn = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (): Promise<null> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<null>((resolve, reject) => {
            //make API request
            axios({
                method: 'post',
                url: '/api/employee/check-in/undo',
                cancelToken: source.token
            }).then(() => {
                //resolve the promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};