import { useState, useCallback, useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import axios, { AxiosError } from 'axios';
import { WrappedApiFunction, ApiFunctionResult, ApiFunctionResultType } from "./types";
import { useCancelableApi } from "./useApi";

//useLoadApi options object
export interface UseLoadApiOptions<R extends ApiFunctionResult<any>> {
    resetData?: boolean;
    disabled?: boolean;
	mutate?: (result: ApiFunctionResultType<R>) => ApiFunctionResultType<R>;
    onError?: (error: any) => void;
	onAxiosError?: (error: AxiosError) => void; 
    debounce?: int;
	retries?: int;
};

//useLoadApi options typed from a WrappedApiFunction
export type UseLoadApiOptionsFromApi<API extends WrappedApiFunction<any, any>> = API extends WrappedApiFunction<any, infer R> ? UseLoadApiOptions<R> : never;

/**
 * useLoadApi hook
 * 
 * The use case for this hook is loading data async and you want to know if the data has been loaded yet or not because
 * the entire component depends on the data being loaded from it.
 */
export const useLoadApi = <A extends any[], R extends ApiFunctionResult<any>>(wrappedApiFunction: WrappedApiFunction<A, R>, apiParams: A, deps?: any[], options?: UseLoadApiOptions<R>) => {
    //use api
    const { api, cancel } = useCancelableApi(wrappedApiFunction);

	//internal state
	const retries = useRef(0);

    //state
    const [result, setResult] = useState<ApiFunctionResultType<R> | undefined>(undefined);
    const [error, setError] = useState<any>(undefined);
    const [requesting, setRequesting] = useState<boolean>(false);

	//options ref
	const optionsRef = useRef(options);
	optionsRef.current = options;

	//apiParams ref
	const apiParamsRef = useRef(apiParams);
	apiParamsRef.current = apiParams;

	//apiDeps
	const apiDeps = deps ?? [];

    //handle an error
    const handleError = useCallback((e: any) => {
		//retry if retries is enabled and the error is an axios error
		if ((optionsRef.current?.retries ?? 3) > retries.current && axios.isAxiosError(e)) {
			retries.current++;
			
			//call the api again after a delay, exponentially increasing the delay each time
			setTimeout(() => callApiRef.current(), 1000 ^ retries.current);
			
			return;
		}

        //set error
        setError(e);
        setRequesting(false);

        //call error handler if any was provided
        if (optionsRef.current?.onError) optionsRef.current.onError(e);
		
		//if the error is an axios error call the onAxiosError hanlder if provided
		if (axios.isAxiosError(e) && optionsRef.current?.onAxiosError) optionsRef.current.onAxiosError(e); 
    }, [setError]);

	//update return value
	const updateResult = useCallback((result: ApiFunctionResultType<R>) => {
		if (optionsRef.current?.mutate) {
			setResult(optionsRef.current.mutate(result));
		} else {
			setResult(result);
		}
        setRequesting(false);
	}, [setResult]);

    //function for calling the API
    const callApi = useCallback(async (resetData?: boolean) => { 
        //if resetData param is true then clear the cached result
        if (resetData ?? false) setResult(undefined);
        setError(undefined);
        setRequesting(true);

        //call api
        const result = api(...apiParamsRef.current);
        
        //check if the result is a CacheResult
        if ('api' in result && 'cache' in result) {
            //get cache then api result
            try { updateResult(await result.cache); } catch (e) { if (e) handleError(e); };
            try { updateResult(await result.api); } catch (e) { if (e) handleError(e); };
        } else {
            //if it is not it is a normal promise, deal with that here.
            result.then(x => updateResult(x)).catch((e: any) => handleError(e)); 
        }

    }, [api, handleError, ...apiDeps]); 

    //debounced callback
    const callApiDebounced = useDebouncedCallback(callApi, options?.debounce);

    //callApi ref (ensures the most recent callApi is used if options?.debounce is changed)
    const callApiRef = useRef(optionsRef.current?.debounce ? callApiDebounced : callApi);
	callApiRef.current = optionsRef.current?.debounce ? callApiDebounced : callApi;
    
    //callCurrentApi is a static function which calls the api using the function currently stored in the callApiRef
    const callCurrentApi = useCallback(async (resetData?: boolean) => { await callApiRef.current(resetData); }, []);
    
    //clear the result value, setting it to undefined (useful for when resetData is false or results are no longer wanted)
    const clear = useCallback(() => setResult(undefined), []);

    //useEffect for calling the api on load, or if the deps list is provided, when deps change (or if refresh token changes)
    useEffect(() => {
        //dont run the useEffect function if disabled
        if(optionsRef.current?.disabled) return;

        //if options.resetData is true then clear the cached result
        if (optionsRef.current?.resetData ?? true) setResult(undefined);
        setError(undefined);

        //cancel previous requests to the API
        cancel();

        //call the api
		callApiRef.current();
    }, [cancel, ...apiDeps]);

    return {
        //the data returned from the API
        data: result,

        //the error which ocurred if the request failed
        error: error,

        //if the hook is currently requesting
        requesting,

        //call the api again manually with the currently provided deps
        call: callCurrentApi,

        //cancel any ongoing API calls
        cancel: cancel,

        //update the cached API without re-calling the API
        update: setResult,

        //clear the cached API response
        clear: clear
    };
};