import { ApiConfig, axiosData, mapApi, SourceGetter } from '@imas/api';
import { ProposalAdditionalScope, ProposalAdditionalScopeForm, vProposalAdditionalScope } from './types';

//proposal pre liens base url
const PROPOSAL_ADDITIONAL_SCOPES_URL = "/api/proposals/additional-scopes";

/*  */
export const GetAllProposalAdditionalScopes = mapApi((): ApiConfig<ProposalAdditionalScope[]> => ({
	method: 'get',
	url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}`,
}));

/*  */
export const GetAllProposalVAdditionalScopes = mapApi((): ApiConfig<vProposalAdditionalScope[]> => ({
	method: 'get',
	url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}/v-additional-scopes`,
}));

/* Get additional scopes for SO */
export const GetSOAdditionalScopes = (getSource: SourceGetter) => {
    return async (uniqueid: int): Promise<vProposalAdditionalScope[]> => {
        return await axiosData({
            method: 'get',
            url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}/${uniqueid.toString()}/so-scopes`,
            cancelToken: getSource().token,
        });
    };
};

/*  */
export const GetProposalAdditionalScope = mapApi((additionalScopeId: int): ApiConfig<ProposalAdditionalScope> => ({
	method: 'get',
	url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}/${additionalScopeId}`,
}));

/*  */
export const CreateProposalAdditionalScope = mapApi((data: ProposalAdditionalScopeForm): ApiConfig<ProposalAdditionalScope> => ({
	method: 'post',
	url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}`,
	data,
}));

/*  */
export const UpdateProposalAdditionalScope = mapApi((additionalScopeId: int, data: ProposalAdditionalScopeForm): ApiConfig<ProposalAdditionalScope> => ({
	method: 'put',
	url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}/${additionalScopeId}`,
	data,
}));

/*  */
export const DeleteProposalAdditionalScope = mapApi((additionalScopeId: int): ApiConfig<ProposalAdditionalScope> => ({
	method: 'delete',
	url: `${PROPOSAL_ADDITIONAL_SCOPES_URL}/${additionalScopeId}`,
}));