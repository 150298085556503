//list of resources names which map to strings & functions to get a resource string
export const RESOURCES = {
	client: (clientId: int) => `/clients/${clientId}`,
	clientLocation: (locationId: int) => `/clients/locations/${locationId}`,
	clientLocationContact: (locationId: int) => `/clients/locations/contacts/${locationId}`,

	proposalServices: (serviceId: int) => `/proposals/services/${serviceId}`,
    proposalServiceFees: (serviceId: int) => `/proposals/services/${serviceId}/fees`,

	serviceOrderWorkPlaceReviews: (serviceOrderId: int) => `/service-orders/work-place-reviews/${serviceOrderId}`,
};