import { POListItem } from './types/fee-details/POListItem';
import { axiosData, SourceGetter } from '@imas/api';
import { ProposalPurchaseOrder, ProposalPurchaseOrderForm, vProposalPurchaseOrder } from './types';

//proposal purchase orders base url
const PROPOSAL_PURCHASE_ORDERS_URL = "/api/proposals/purchase-orders";

/*  */
export const GetAllProposalPurchaseOrders = (getSource: SourceGetter) => {
    return async (): Promise<ProposalPurchaseOrder[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PURCHASE_ORDERS_URL}`,
            cancelToken: getSource().token,
		});
    };
};
/*  */
export const GetAllProposalVPurchaseOrders = (getSource: SourceGetter) => {
    return async (): Promise<vProposalPurchaseOrder[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PURCHASE_ORDERS_URL}/v-purchase-orders`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetProposalPurchaseOrder = (getSource: SourceGetter) => {
    return async (purchaseOrderId: int): Promise<ProposalPurchaseOrder> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_PURCHASE_ORDERS_URL}/${purchaseOrderId}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const CreateProposalPurchaseOrder = (getSource: SourceGetter) => {
    return async (data: ProposalPurchaseOrderForm): Promise<ProposalPurchaseOrder> => {
		return axiosData({
			method: 'post',
			url: `${PROPOSAL_PURCHASE_ORDERS_URL}`,
			data,
			cancelToken: getSource().token,
		});
	};
};

export const CreateProposalPurchaseOrders = (getSource: SourceGetter) => {
    return async (pronum: int, clientPONums: int[]): Promise<boolean> => {
		return axiosData({
			method: 'post',
			url: `${PROPOSAL_PURCHASE_ORDERS_URL}/${pronum}`,
			data: {
                clientPONums: clientPONums
            },
			cancelToken: getSource().token,
		});
	};
};

/*  */
export const UpdateProposalPurchaseOrder = (getSource: SourceGetter) => {
    return async (purchaseOrderId: int, data: ProposalPurchaseOrderForm): Promise<ProposalPurchaseOrder> => {
		return axiosData({
            method: 'put',
            url: `${PROPOSAL_PURCHASE_ORDERS_URL}/${purchaseOrderId}`,
            data,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const DeleteProposalPurchaseOrder = (getSource: SourceGetter) => {
    return async (purchaseOrderId: int): Promise<ProposalPurchaseOrder> => {
        return axiosData({
            method: 'delete',
            url: `${PROPOSAL_PURCHASE_ORDERS_URL}/${purchaseOrderId}`,
            cancelToken: getSource().token,
		});
    };
};

export const GetPOList = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<POListItem[]> => {
        return axiosData({
            method: 'get',
            url: 'api/proposals/fee-details/po-list/' + pronum.toString(),
            cancelToken: getSource().token,
		});
    };
};