import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { local } from '@imas/utils/storage';
import { UserData } from '@imas/api/auth/types';
import { Employee } from '@imas/api/employee/types';
import { RootState } from '@imas/redux';

//key of the localStorage where the AuthInfo is cached
const AUTH_CACHE_KEY = "authCache";

export interface AuthInfo {
    //if the user is on the local PNL network
    isLocal: boolean;
    
    //contains a full Employee object if loggedIn is true
    user: UserData | null;
};

//init state type
interface AuthState {
    value: AuthInfo;
    status: string;
};

//helper function which loads the AuthInfo object from local storage and checks if it is valid, if it is not valid it returns a default state
const getAuthCache = (): AuthInfo => {
        //load the initial value of AuthState from local storage
    const authCacheString = local.get(AUTH_CACHE_KEY);

    //try and parse it into json
    const authCacheJson = JSON.parse(authCacheString ?? "null") as object | null;

    //check if authCacheJson as the "isLocal", and "user" properties, if it does not nullify it
    if (authCacheJson && ("isLocal" in authCacheJson && "user" in authCacheJson)) {
        return authCacheJson as AuthInfo;
    }
    
    return {
        isLocal: false,
        user: null,
    };
};

//helper function which will write a AuthInfo object into local storage
const updateAuthCache = (state: AuthInfo) => {
    local.set(AUTH_CACHE_KEY, JSON.stringify(state));
};

/**
 * Slice for storing information related to authentication such as the authenticated status of the user and related data. 
 */
const initialState: AuthState = {
    value: getAuthCache(),
    status: 'idle'
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<UserData | null>) => {
            //update the value of user
            state.value.user = action.payload;

            //update the auth cache
            updateAuthCache(state.value);
        },
        setIsLocal: (state, action: PayloadAction<boolean>) => {
            //don't update isLocal if it is the same value
            if (state.value.isLocal === action.payload) return;

            state.value.isLocal = action.payload;
            
            //update the auth cache
            updateAuthCache(state.value);
        },

        //syncs this tab's AuthState with the current value of the AuthState cache
        syncAuthCache: (state, _: PayloadAction<void>) => {
            state.value = getAuthCache();
        }
    },
});

export const { setUserData, setIsLocal, syncAuthCache } = authSlice.actions;

//selector functions
export const getIsUserAuthenticated = (state: RootState) => state.auth.value.user !== null;
export const getIsLocal = (state: RootState) => state.appSettings.value.general.officeMode;
export const getUserData = (state: RootState): UserData | null => state.auth.value.user;
// export const getUserRole = (state: RootState): UserRole => {
//     switch (state.auth.value.user?.employee.userRole) {
//         case "Users":
//             return UserRole.User;
//         case "Power Users":
//             return UserRole.PowerUser;
//         case "Super Users":
//             return UserRole.SuperUser;
//         case "Admins":
//             return UserRole.Admin;
//         default:
//             return UserRole.None;
//     }
// };
export const getEmployeeData = (state: RootState): Employee | null => state.auth.value.user?.employee ?? null;
export const getRole = (state: RootState) => state.auth.value.user?.employee.userRole ?? "Users";

//returns a dict which contains the roles and if the user has that role or higher 
export const getUserRoles = (state: RootState) => {
    const role = getRole(state);
    return {
        employeeManager: state.auth.value.user?.employee.isManager ?? false,
        hrManager: state.auth.value.user?.employee.hrManager ?? false,
        dbManager: state.auth.value.user?.employee.dataManager ?? false,
        tsManager: state.auth.value.user?.employee.tsManager ?? false,
		projManager: state.auth.value.user?.employee.projManager ?? 0,
        powerUser: (role === "Power Users" || role === "Super Users" || role === "Admins"),
        superuser: (role === "Super Users" || role === "Admins"),
        admin: (role === "Admins"),
    };
};

export const authReducer = authSlice.reducer;