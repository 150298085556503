import React from 'react';
import { useState } from 'react';
import { Link as ExternalLink, LinkProps } from '@mui/material';
import { VERSION } from '@imas/constants';
import VersionHistoryDialog from './VersionHistoryDialog/VersionHistoryDialog';

export interface VersionHistoryProps {
    disabled?: boolean;
    linkProps?: LinkProps;
    className?: string;
    onOpen?: () => void;
};

const VersionHistory = React.memo(({ disabled, linkProps, className, onOpen }: VersionHistoryProps) => {
    //if the version history dialog is open
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    
    return (
        <>
            {/* Open Dialog Link */}
            <ExternalLink
                underline={"none"}
                aria-disabled={disabled}
                onClick={() => {
                    if (disabled) return;
                    if (onOpen) onOpen();
                    setDialogOpen(true);
                }}
                className={className}
                {...linkProps}
            >{"v" + VERSION}</  ExternalLink>

            {/* Show Dialog if dialogOpen is true */}
            {/* {dialogOpen ? (
                <VersionHistoryDialog onClose={() => setDialogOpen(false)}/>
            ) : null} */}
        </>
    );
});

export { VersionHistory };