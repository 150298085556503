import { FolderInfoItem } from '@imas/api/files/types';
import { InternalHandlerResult, InternalHandlerFailure, InternalHandlerSuccess, FileError, FileExplorerMode } from "./types";

export const getLevel = (hierarchyId: HierarchyId): int => {
    //check if the id is root
    if (hierarchyId === "/") return 0;

    //otherwise remove start and end slashes, then split the id on remaining internal slashes and return the number of resulting tokens
    return hierarchyId.replace(/^\\\+|\\\|+$/g, '').split("/").length;
};

export const separateHandlerResults = <T>(results: InternalHandlerResult<T>[]): [InternalHandlerFailure<T>[], T[]] => {
    return [
        results.filter((result): result is InternalHandlerFailure<T> => result.error !== null),
        results.filter((result): result is InternalHandlerSuccess<T> => result.error === null).map(x => x.file),
    ];
};

export const getFileError = (error: FileError | int | null): FileError | null => {
    if (typeof error === "string" || error === null) return error;
    
    switch(error) {
        case 547:
            return "in-use";
        case 50000:
            return "must-be-user";
        case 50001:
            return "must-be-power-user";
        case 50002:
            return "must-be-super-user";
        case 50003:
            return "must-be-admin";
        default:
            return "unknown";
    };
};

export const getFileErrorMessage = (error: FileError): string => {
    switch(error) {
        case "in-use":
            return "Unable to perform action, the file is currently in use.";
        case "is-referenced":
            return "Unable to perform action, the file is referenced by another record.";
        case "must-be-user":
            return "You must be a User to perform this action.";
        case "must-be-power-user":
            return "You must be a Power User to perform this action.";
        case "must-be-super-user":
            return "You must be a Super User to perform this action.";
        case "must-be-admin":
            return "You must be an Admin to perform this action.";
        default:
            return "An unknown error has occurred, please contact an administrator.";
    };
};

export const getModeFilter = (mode: FileExplorerMode): (row: FolderInfoItem) => boolean => {
    if (mode === "upload-file" || mode === "upload-files" || mode === "pick-folder") return (row) => row.isDir;
    else return () => true;
};

export const getModeDisplayName = (mode: FileExplorerMode): string => {
    switch (mode) {
        case "explorer":
            return "File explorer";
        case "pick-file":
            return "File picker";
        case "pick-files":
            return "Multiple file picker";
        case "pick-folder":
            return "Folder picker";
        case "upload-file":
            return "File uploader";
        case "upload-files": 
            return "Multiple file uploader";
        default:
            return "Unknown mode";
    };
};