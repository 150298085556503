import { mapApi, ApiConfig } from '@imas/api';
import { vCertificationDetail } from 'src/api/types/api/certification/types';
import { ProposalCertification } from 'src/api/types/api/proposals/ProposalCertification';

//proposal project requirements base url
const PROPOSAL_PROJECT_REQUIREMENTS_URL = "/api/proposals/project-requirements/proposal-certifications";


/*  */
export const GetProposalProjectRequirements = mapApi((pronum: int): ApiConfig<ProposalCertification[]> => ({
	method: 'get',
	url: `${PROPOSAL_PROJECT_REQUIREMENTS_URL}/${pronum}`,
}));

/*  */
export const CreateProposalProjectRequirement = mapApi((pronum: int, certlevelID: int): ApiConfig<ProposalCertification> => ({
	method: 'post',
	url: `${PROPOSAL_PROJECT_REQUIREMENTS_URL}/${pronum}/${certlevelID}`,
}));

/*  */
export const UpdateProposalProjectRequirement = mapApi((procertid: int, certid: int, certlevelid: int, certlevel: string): ApiConfig<ProposalCertification> => ({
	method: 'put',
	url: `${PROPOSAL_PROJECT_REQUIREMENTS_URL}/${procertid}`,
	data: {
		procertid: procertid,
		certid: certid,
		certlevelid: certlevelid,
		certlevel: certlevel,
	},
}));

/*  */
export const DeleteProposalProjectRequirement = mapApi((procertid: int): ApiConfig<void> => ({
	method: 'delete',
	url: `${PROPOSAL_PROJECT_REQUIREMENTS_URL}/${procertid}`,
}));
