import { Button, Popper, Paper, ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuCategory } from '@imas/constants/menus';
import dynamicToolbarStyles from './DynamicToolbarStyles';

/** A toolbar menu which is dynamically generated given a Menu object. */
const DynamicToolbar = ({ menu }: { menu: Menu }) => {
    //styling
    const { classes } = dynamicToolbarStyles();

    //element to display popover on
    const [anchorEl, setAnchorEl] = useState<EventTarget & Element | null>(null);
    
    //currently selected menu category
    const [selectedCategory, setSelectedCategory] = useState<MenuCategory | null>(null);

    //handles opening a menu category
    const openMenu = (event: React.UIEvent, category: MenuCategory) => {
        //set the selected menu category
        setSelectedCategory(category);

        //change the anchorEl if it has changed
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    //handles closing the menu
    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            {/* Add top level dropdown buttons */}
            {
                menu.map((category) => {
                    return (
						<Button
							variant={"text"}
							color={"inherit"}
							className={classes.dropdownButton}
							key={category.url}
							component={Link}
							to={category.options[0].url}
							onMouseOver={(event: React.UIEvent) => openMenu(event, category)}
						>{category.name}</Button>
					);
                })
            }

            {/* Popper which will display the dropdown */}
            <Popper 
                id={selectedCategory === null ? "unopened" : selectedCategory.name} 
                open={anchorEl !== null} 
                placement={"bottom-start"}
                style={{ zIndex: 1150 }}
                anchorEl={anchorEl}
                onMouseLeave={closeMenu}
            >
                {/* ClickAwayListener to detect when the user is not interacting with the menu and therefore it should be closed */}
                <ClickAwayListener onClickAway={closeMenu}>
                    {/* Container */}
                    <Paper className={classes.dropdownContainer}>
                        {/* List of buttons for each MenuItem */}
                        { selectedCategory === null ? null :
                            selectedCategory.options.map(option => {
                                return (
									<Button      
										variant={"text"}
										color={"inherit"}
										className={classes.dropdownOptionButton} 
										key={option.url}
										component={Link}
										to={option.url}
									>{option.name}</Button>
								);
                            })
                        }
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </React.Fragment>
    );
};

export default DynamicToolbar;