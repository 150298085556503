import React from 'react';
import { CollaboratorGroup, CollaboratorGroupProps } from "./CollaboratorGroup";
import { useResource } from "./useResource";

//ResourceCollaboratorGroupProps
export interface ResourceCollaboratorGroupProps extends Omit<CollaboratorGroupProps, "collaborators"> {
	resource: string;
};

export const ResourceCollaboratorGroup = React.memo((props: ResourceCollaboratorGroupProps) => {
	const { resource, ...otherProps } = props;

	//use resource hook
	const { collaborators } = useResource(resource);

	return (
		<CollaboratorGroup
			collaborators={collaborators}
			{...otherProps}
		/>
	);
});