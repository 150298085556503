import { Button, List, ListItem, ListItemText } from '@mui/material';
import { Skeleton } from '@mui/material';
import { useLoadApi } from '@imas/api';
import { useContext, useMemo } from 'react';
import { Alignment, LabeledItem } from '@imas/components/layout';
import onCallScheduleStyles from './OnCallScheduleStyles';
import OnCallScores from './OnCallScores/OnCallScores';
import { DailyScheduleViewerContext } from '../DailyScheduleViewerContext';
import useResizeObserver from 'use-resize-observer';

export interface OnCallScheduleProps {
	/** Callback when component size changes. */
	onResize?: (params: { width?: int; height?: int; }) => void;
};

export const OnCallSchedule = ({ onResize }: OnCallScheduleProps) => {
    const { classes, cx } = onCallScheduleStyles();

	//selected date from DailyScheduleViewerContext
	const { onCallSchedule } = useContext(DailyScheduleViewerContext);
	
	//resize observer
	const { ref } = useResizeObserver({
		onResize: ({ width, height }) => {
			if (onResize) onResize({ width, height });
		},
	});

    //if the schedule is still TBD
    const scheduleIsTBD = useMemo(() => {
        if (!onCallSchedule) return true;
        if (onCallSchedule.length === 0 || onCallSchedule[0].employeeNameShort === "TBD") return true;
        return false;
    }, [onCallSchedule]);

    return (
        // On Call Schedule container
        (<Alignment column className={classes.container} ref={ref}>
            {onCallSchedule ? (
				<>
					{/* Title & Scores Button */}
					<Alignment row className={classes.topContainer}>
						{/* Title */}
						{/* <Typography variant={"body1"} className={styles.onCallText}>{"On Call:"}</Typography> */}
						<LabeledItem label={"On Call:"} labelProps={{ className: classes.onCallText, variant: "h6" }} item={scheduleIsTBD ? " TBD" : ""} inline row/>
						
						{/* Scores Button */}
						<OnCallScores text={"Scores"} color={"primary"} variant={"contained"} className={classes.onCallScoresButton}/>
					</Alignment>

					{/* On Call Schedule Items */}
					<List className={classes.scheduleContainer}>
						{/* If the first on call entry's Name is 'TBD' then display some bigger text with just TBD, otherwise show the entries */}
						{scheduleIsTBD ? null : (
							onCallSchedule.map((data, index) => (
								<ListItem key={index} className={classes.onCallEntry}>
									<ListItemText primary={data.employeeNameShort + " " + data.nextOncallRange}/>
								</ListItem>
							))
						)}
					</List>
				</>
			) : (
				<>
					<Alignment row className={classes.topContainer}>
						<Skeleton className={cx(classes.onCallText, classes.onCallTextSkeleton)} variant={"text"} height={50} width={120}/>
						<Skeleton className={classes.onCallScoresButton} height={50} width={90}><Button/></Skeleton>
					</Alignment>
					<div className={classes.scheduleContainer}>
						<Skeleton className={classes.onCallEntry} variant={"text"} height={30} width={200}/>
						<Skeleton className={classes.onCallEntry} variant={"text"} height={30} width={200}/>
					</div>
				</>
			)}
        </Alignment>)
    );
};