import { axiosData, objectToFormData, SourceGetter } from '@imas/api';
import { EmployeeExpenseReportDetail, EmployeeExpenseReportDetailForm } from './types';

//employees base url
const EXPENSE_REPORT_DETAILS_URL = "/api/employees/expense-reports/details";

/*  */
export const CreateEmployeeExpenseReportDetail = (getSource: SourceGetter) => {
    return async (data: EmployeeExpenseReportDetailForm): Promise<EmployeeExpenseReportDetail> => {
        return axiosData({
			method: 'post',
            url: `${EXPENSE_REPORT_DETAILS_URL}`,
			data: objectToFormData(data),
			headers: { 'Content-Type': 'multipart/form-data' },
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const UpdateEmployeeExpenseReportDetail = (getSource: SourceGetter) => {
    return async (detailId: int, data: EmployeeExpenseReportDetailForm): Promise<EmployeeExpenseReportDetail> => {
        return axiosData({
			method: 'put',
            url: `${EXPENSE_REPORT_DETAILS_URL}/${detailId}`,
			data: objectToFormData(data),
			headers: { 'Content-Type': 'multipart/form-data' },
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const DeleteEmployeeExpenseReportDetail = (getSource: SourceGetter) => {
    return async (detailId: int): Promise<EmployeeExpenseReportDetail> => {
        return axiosData({
			method: 'delete',
            url: `${EXPENSE_REPORT_DETAILS_URL}/${detailId}`,
            cancelToken: getSource().token,
		});
    };
};