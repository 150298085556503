import { makeDeviceStyles } from '@imas/styles';

const versionChangesStyles = makeDeviceStyles()((theme) => ({
    categoryTitle: {
        //textDecoration: 'underline'
    },
    container: {
        padding: '10px',
    },
    loadingText: {
        width: '-webkit-fill-available',
    },
}));

export default versionChangesStyles;