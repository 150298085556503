import React, { useMemo } from 'react';
import * as MuiGrid from "@mui/x-data-grid-pro";
import { getFixedGridBooleanOperators } from '../utils/getFixedGridBooleanOperators';
import { PatchedDataGrid } from './PatchedDataGrid';

//return operators given the col type
const getTypeOperators = (colTypes: MuiGrid.GridColumnTypesRecord | undefined, type?: string): MuiGrid.GridFilterOperator[] => {
    //try to get colType operators if the type was in the colTypes map
    if (type && colTypes && type in colTypes) {
        const ops = colTypes[type].filterOperators;
        
        if (ops !== undefined) return ops;
    }

    switch (type) {
        case "number":
            return MuiGrid.getGridNumericOperators();
        case "date":
        case "dateTime":
            return MuiGrid.getGridDateOperators();
        case "boolean":
            return getFixedGridBooleanOperators();
        case "singleSelect":
            return MuiGrid.getGridSingleSelectOperators();
        default:
            return MuiGrid.getGridStringOperators();
    };
};

//function which returns modified grid filterOperators
const getModifiedFilterOperators = (operators: MuiGrid.GridFilterOperator[] | undefined, colTypes: MuiGrid.GridColumnTypesRecord | undefined, type: string | undefined, apiRef: React.MutableRefObject<MuiGrid.GridApi>): MuiGrid.GridFilterOperator[] => {
    return (operators ?? getTypeOperators(colTypes, type)).map(operator => ({
        ...operator,
        getApplyFilterFn: (filterItem: MuiGrid.GridFilterItem, column: MuiGrid.GridColDef) => {
            const applyFilter = operator.getApplyFilterFn(filterItem, column);
            if (applyFilter === null) return null;

            //get data model
            //const editing = Object.keys(apiRef.current?.getRowModels().forEach);
            const editing: string[] = [];
            try {
                apiRef.current?.getRowModels().forEach((value, key) => {
                    if (apiRef.current.getRowMode(key) === "edit") editing.push(key.toString());
                });
            } catch {}

            //return modified filter func
            return (params: MuiGrid.GridCellParams) => {
                if (editing.includes(params.id.toString())) return true;

                return applyFilter(params);
            };
        },
    }));
};


/** A DataGrid where editable rows will not be filtered out */
export const NoEditFilterDataGrid = (props: Omit<MuiGrid.DataGridProProps, "apiRef"> & { apiRef: React.MutableRefObject<MuiGrid.GridApi>; }) => {
    const { columns, apiRef, ...gridProps } = props;

    //columns with modified filter
    const modifiedColumns = useMemo(() => {
        return columns.map(col => ({
            ...col,
            filterOperators: getModifiedFilterOperators(col?.filterOperators, undefined, col?.type, apiRef),
        }));
    }, [columns]);

    return <PatchedDataGrid 
        apiRef={apiRef} 
        columns={modifiedColumns}
        {...gridProps}
    />;
};