import { SourceGetter, axiosData } from "../_internals";
import { Project, vProjectProposal } from "./types";

//proposal projects base url
const PROPOSAL_PROJECTS_URL = "/api/proposals/projects";

//returns all projects
export const GetProjects = (getSource: SourceGetter) => {
    return async (): Promise<Project[]> => {
        return await axiosData({
            method: 'get',
            url: `${PROPOSAL_PROJECTS_URL}`,
            cancelToken: getSource().token,
        });
    };
};

//returns all project proposals
export const GetProjectProposals = (getSource: SourceGetter) => {
    return async (): Promise<vProjectProposal[]> => {
        return await axiosData({
            method: 'get',
            url: `${PROPOSAL_PROJECTS_URL}/proposals`,
            cancelToken: getSource().token,
        });
    };
};

//given a proposal, will return the projectid of 
export const GetProjectId = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<int | null> => {
        return await axiosData({
            method: 'get',
            url: `${PROPOSAL_PROJECTS_URL}/${pronum.toString()}`,
            cancelToken: getSource().token,
        });
    };
};

//update a project
export const CreateProject = (getSource: SourceGetter) => {
    return async (name: string): Promise<Project> => {
        return await axiosData({
            method: 'post',
            url: `${PROPOSAL_PROJECTS_URL}`,
            data: {
                item: name,
            },
            cancelToken: getSource().token,
        });
    };
};

//update a project
export const UpdateProject = (getSource: SourceGetter) => {
    return async (project: Project): Promise<boolean> => {
        return await axiosData({
            method: 'put',
            url: `${PROPOSAL_PROJECTS_URL}`,
            data: project,
            cancelToken: getSource().token,
        });
    };
};

//delete a project
export const DeleteProject = (getSource: SourceGetter) => {
    return async (projectId: int): Promise<boolean> => {
        return await axiosData({
            method: 'delete',
            url: `${PROPOSAL_PROJECTS_URL}/${projectId}`,
            cancelToken: getSource().token,
        });
    };
};

//delete a project
export const CreateProjectProposals = (getSource: SourceGetter) => {
    return async (projectId: int, pronums: int[]): Promise<boolean> => {
        return await axiosData({
            method: 'post',
            url: `${PROPOSAL_PROJECTS_URL}/proposals`,
            data: {
                projectId: projectId,
                pronums: pronums
            },
            cancelToken: getSource().token,
        });
    };
};

//delete a project
export const DeleteProjectProposal = (getSource: SourceGetter) => {
    return async (projectPronum: int): Promise<boolean> => {
        return await axiosData({
            method: 'delete',
            url: `${PROPOSAL_PROJECTS_URL}/proposals/${projectPronum}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetProjectSummary = (getSource: SourceGetter) => {
    return async (projectId: int): Promise<boolean> => {
        return await axiosData({
            method: 'get',
            url: `${PROPOSAL_PROJECTS_URL}/summary/${projectId}`,
            cancelToken: getSource().token,

        });
    };
};