import Resizer from "react-image-file-resizer";

//ResizeOptions interface
interface ResizeOptions {
    maxWidth?: number;
    maxHeight?: number;
    compressFormat?: "JPEG" | "WEBP";
    quality?: number;
    rotation?: 0 | 90 | 180 | 270 | 360;
}

//resize an image file, will ignore and not resize non-image files and just return the original File object instead, returns a promise
export function resizeImage (file: File, resizeOptions?: ResizeOptions) {
    return new Promise<File>((resolve) => {
        try {
            Resizer.imageFileResizer(
                file,
                resizeOptions?.maxWidth ?? 2000,
                resizeOptions?.maxHeight ?? 2000,
                resizeOptions?.compressFormat ?? "JPEG",
                resizeOptions?.quality ?? 90,
                resizeOptions?.rotation ?? 0,
                (resultFile) => resolve(new File([resultFile as File], (resultFile as File).name.replace("JPEG", 'jpg'), {type: (resultFile as File).type})),
                "file"
            );
        } catch {
            resolve(file);
        }
    });
}