import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, TextField, Toolbar, Typography } from '@mui/material';
import { Alignment, Spacer } from '@imas/components/layout';
import { useLoadApi } from '@imas/api';
import { GetVersionHistory } from 'src/api/misc/misc';
import moment from 'moment';
import { useState } from 'react';
import { VersionCode } from 'src/api/types/api/misc/misc';
import { useEffect } from 'react';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import VersionChanges from './VersionChanges/VersionChanges';
import { Autocomplete } from '@mui/material';
import { makeDeviceStyles } from '@imas/styles';
import { XDialogTitle } from '@imas/components/mui';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeDeviceStyles()((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            flex: '1',
            overflow: 'hidden',
            minHeight: '90vh'
        }
    },
    content: {
        width: '-webkit-fill-available',
        display: 'flex',
        flex: '1',
        overflow: 'scroll',
		paddingBottom: 0,
    },
	dialogTitle: {
		background: theme.palette.primary.main,
		width: '-webkit-fill-available',
        display: 'flex',
        overflow: 'hidden'
	},
	title: {
		margin: '0 auto',
		color: theme.palette.text.navigation,
	},
	versionHistoryTitle: {
        margin: '0 auto',
    },
    versionListTitle: {
        margin: '0 auto',
    },
    versionTitle: {
        margin: '0 0 0 15px',
    },
    versionList: {
        width: '-webkit-fill-available',
    },
    versionSearch: {
        paddingTop: '10px'
    }, 
    list: {
        overflow: 'scroll'
    },
    mobileVerHeader: {
        marginLeft: '20px'
    },
    changes: {
        width: '-webkit-fill-available',
    }
}));

const VersionHistoryDialog = ({
    onClose
}: {
    onClose: () => void,
}) => {
    //component styling & deviceInfo
    const { classes, deviceInfo } = useStyles();

    //get version history
    const { data: versionHistory } = useLoadApi(GetVersionHistory, [], []);

    //version currently selected
    const [selectedVersion, setSelectedVersion] = useState<VersionCode>();

    //select most recent version by default when loaded
    useEffect(() => {
        if (versionHistory) {
            setSelectedVersion(versionHistory[0]);
        }
    }, [versionHistory]);

	//prevents closeing by clicking on the backdrop
	const onCloseNoBackdrop = (event: object, reason: string) => {
		if (reason === 'backdropClick') return;
		else onClose();
	};
    const navigate = useNavigate();
    const [click, setClick] = useState(1);

    return (
        <Dialog open={true} fullScreen={!deviceInfo.isSm} onClose={onCloseNoBackdrop} maxWidth={"lg"} className={classes.dialog}>
			<XDialogTitle fullScreen onClose={onClose}>
				<Typography variant={"h4"} className={classes.title}>{deviceInfo.isMd ? "Integrated Management and Analytics System (IMAS)" : "IMAS"}</Typography>
				<Spacer horizontal size={'10px'}/>
				<img onClick={() => {
                    setClick(click + 1);
                    if(click === 10)
                    {
                        navigate('/home/crouton');
                        onClose();
                    }

                }} src="/icons/imas-icon-48.png" alt={"PNL Logo Icon"}/>
				<Spacer horizontal size={'10px'}/>
			</XDialogTitle>
            <DialogContent className={classes.content}>
                

                    {deviceInfo.isSm ? (
                        <>
                        {/* Desktop */}

                        <Alignment column>
                        <Typography variant={"h5"} className={classes.versionHistoryTitle}>{"About IMAS"}</Typography>
                        <Typography align={'center'} variant={"body1"} className={classes.versionHistoryTitle}>{"IMAS was developed by Phoenix National Labs, LLC to provide an integrated solution to manage all company operations. These include a customer relationship manager, lead and proposal tracking, fee schedule manager, scheduling work, human resources, P.O. and A/P tracking, invoicing and A/R tracking, quality control, B.I. with scorecards and analytics, and more."}</Typography>
                        <Spacer vertical size={'20px'}/>
                        <Typography align={'center'} variant={"body1"} className={classes.versionHistoryTitle}>{"For help, contact Kyle Young (kyoung@pnltest.com), or Gil Mendez (gilm@pnltest.com)."}</Typography>
                        <Typography align={'center'} variant={"body1"} className={classes.versionHistoryTitle}>{"Please report any bugs or new ideas by using the feedback form."}</Typography>

                        <Spacer vertical/>
                        <Divider/>
                        <Spacer vertical size={'10px'}/>	
                        <Typography variant={"h5"} className={classes.versionListTitle}>{"Version History"}</Typography>
                        <Alignment row sx={{ overflowY: 'scroll', position: 'sticky'}}>
                            <Alignment column sx={{width: '15%', overflowY: 'scroll', position: 'sticky'}} >
                            <List className={classes.list}>
                                                    {(versionHistory ?? []).slice(0, 20).map((version, index) => (
                                                        <ListItem key={index} button onClick={() => setSelectedVersion(version)}>
                                                            <ListItemText
                                                                primary={"v" + version.major + "." + version.minor + "." + version.revision}
                                                                secondary={moment(version.dateTime).format("M/DD/YYYY")}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                            </Alignment>
                        <VersionChanges version={selectedVersion}/>

                        </Alignment>
                        <Divider/>

                </Alignment>
              

                
                </>
                    ) :<>
                    {/* Mobile */}

                    <Alignment column flex overflowScroll>
					<Alignment column>
                            <Typography variant={"h5"} className={classes.versionHistoryTitle}>{"About IMAS"}</Typography>
							<Typography align={'center'} variant={"body1"} className={classes.versionHistoryTitle}>{"IMAS was developed by Phoenix National Labs, LLC to provide an integrated solution to manage all company operations. These include a customer relationship manager, lead and proposal tracking, fee schedule manager, scheduling work, human resources, P.O. and A/P tracking, invoicing and A/R tracking, quality control, B.I. with scorecards and analytics, and more."}</Typography>
							<Spacer vertical size={'20px'}/>
							<Typography align={'center'} variant={"body1"} className={classes.versionHistoryTitle}>{"For help, contact Kyle Young (kyoung@pnltest.com), or Gil Mendez (gilm@pnltest.com)."}</Typography>
							<Typography align={'center'} variant={"body1"} className={classes.versionHistoryTitle}>{"Please report any bugs or new ideas by using the feedback form."}</Typography>

							<Spacer vertical/>
							<Divider/>
							<Spacer vertical size={'10px'}/>					
					</Alignment>
                                        <Alignment column className={deviceInfo.isMd ? classes.versionSearch : classes.versionList} hidden={!deviceInfo.isMd && selectedVersion !== undefined}>
                                            {/* Version History Header */}
                                            <Typography variant={"h5"} className={classes.versionListTitle}>{"Version History"}</Typography>
                                                                            
                                                {/* Version History List (last 20 versions)*/}
                                                <List className={classes.list}>
                                                    {(versionHistory ?? []).slice(0, 20).map((version, index) => (
                                                        <ListItem key={index} button onClick={() => setSelectedVersion(version)}>
                                                            <ListItemText
                                                                primary={"v" + version.major + "." + version.minor + "." + version.revision}
                                                                secondary={moment(version.dateTime).format("MM/DD/YYYY")}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                        </Alignment>
                                        <Alignment column className={classes.changes} hidden={!deviceInfo.isMd && selectedVersion === undefined} flex> 
                                         <Toolbar>
                                {/* Back Button */}
                                <IconButton onClick={() => setSelectedVersion(undefined)} size={"medium"}><ArrowBackIcon/></IconButton>
                                
                                {/* Header */}
                                <Typography
                                    className={classes.mobileVerHeader}
                                    variant={"h6"}
                                >{"Version " + selectedVersion?.major + "." + selectedVersion?.minor + "." + selectedVersion?.revision} </Typography>
                            </Toolbar>
                            <VersionChanges version={selectedVersion}/>

                                        </Alignment>

                                        </Alignment>

                                        </>}

                        
                        {/* Mobile Header & Desktop Header */}
                        {/* Version Changes */}

            </DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					color={'secondary'}
				>{'Close'}</Button>
			</DialogActions>
        </Dialog>
    );
};

export default VersionHistoryDialog;