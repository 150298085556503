import { useRef } from 'react';
import { useActiveElement } from './useActiveElement';

export function useIsElementActive<T extends Element = Element>(): [boolean, React.RefObject<T>] {
	//currently active element
	const activeElement = useActiveElement();

	//ref to attach to the target element which should be checked
	const targetElementRef = useRef<T>(null);

	//return the boolean & ref
	return [activeElement === targetElementRef.current, targetElementRef];
};