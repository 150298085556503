import { useLoadApi } from '@imas/api';
import { ClientForm as TClientForm } from '@imas/api/client'; 
import { GetStates, GetCountries } from '@imas/api/system';
import { Alignment } from '@imas/components/layout';
import { FormTextField, FormNumberTextField, FormDatePicker, FormAutocomplete, useMuiFormControl, useMuiWatch } from '@imas/mui-form';
import { TextField, Typography, Tooltip, IconButton } from '@mui/material';
import { OpenInBrowser } from '@mui/icons-material';
import { memo, useMemo } from 'react';
import { getMapsLink } from '@imas/utils/misc';
import validator from 'validator';
import moment from 'moment';
import { formatAddress } from '@imas/utils/formatting';
import MapIcon from '@mui/icons-material/Map';
import { State, Country } from '@imas/api/system';
import { useClients } from '@imas/api/data-hooks';
import { useState } from 'react';
import { createObjectAutocompleteVirtualizer } from '@imas/utils/virtualization';


const ClientWebsite = () => {
	//get form control
	const control = useMuiFormControl<TClientForm>();
	const [webpage] = useMuiWatch({ control, name: ["webpage"] });
	//check if the webpage is a valid web URL
	const isValid = validator.isURL(webpage ?? "", { protocols: ["http", "https"] });

	return (
		<Alignment row sx={{ marginBottom: '10px' }}>
			<FormTextField
				name={"webpage"}
				control={control}
				fullWidth
				label={"Web Page"}
				helperText={webpage !== null && !isValid ? "This is not a valid URL." : undefined}
				sx={{ marginRight: '10px' }}
			/>
			<Tooltip title={"Open URL in new tab."} arrow>
				<IconButton
					disabled={webpage === null}
					size={"small"}
					onClick={() => window.open((webpage ?? "").includes(":") ? (webpage ?? "") : `http://${webpage}`)}
					sx={{ width: '30px', height: '30px' }}
				><OpenInBrowser/></IconButton>
			</Tooltip>
		</Alignment>
	);
};

export const ClientCorporateInfo = memo(() => {

	const STATE_AUTOCOMPLETE_VIRTUALIZER = createObjectAutocompleteVirtualizer<State>({
		columns: [
			{field: 'abbreviation', label: '', width: 50},
			{field: 'name', label: 'State', width: 200},
			{field: 'country', label: 'Country', width: 350},

		],
		getLabel: (e) => `${e.abbreviation}`,
		dense: true
	});

	const COUNTRY_AUTOCOMPLETE_VIRTUALIZER = createObjectAutocompleteVirtualizer<Country>({
		columns: [
			{field: 'countryAbbrev3', label: '', width: 50},
			{field: 'name', label: '', width: 350},
		],
		getLabel: (e) => `${e.countryAbbrev3}`,
		dense: true

	});
	//get form control
	const control = useMuiFormControl<TClientForm>();
	//initialize constants
	const [address] = useMuiWatch({ control, name: ["address1"]});
	const [address2] = useMuiWatch({ control, name: ["address2"]});
	const [city] = useMuiWatch({ control, name: ["city"]});
	const [state] = useMuiWatch({ control, name: ["state"]});
	const [zip] = useMuiWatch({ control, name: ["zip"]});
	const [country] = useMuiWatch({ control, name: ["country"]});


	//get list of states
	const { data: states } = useLoadApi(GetStates, [], []);

	//get countries
	const { data: countries } = useLoadApi(GetCountries, [], []);

	//sorts countries so USA is default first in list
	const sortedCountries = countries?.sort((a,b) => {
		if(a.countryAbbrev3 === 'USA')
		{
			return -1;} else if (b.countryAbbrev3 === 'USA')
		{
			return 1; } else 
		{
			return a.countryAbbrev3.localeCompare(b.countryAbbrev3);
		}
	});

	return (
		<Alignment column>
			<Typography variant={"h6"} sx={{ marginBottom: '5px', fontWeight: 'bold' }}>{"Corporate"}</Typography>

			{/* Address Field */}
			<FormTextField
				control={control}
				name={"address1"}
				label={"Street Address"}
				rows={1}
				sx={{ marginBottom: '10px' }}
			/>
			<FormTextField
				control={control}
				name={"address2"}
				label={"Suite/Other"}
				rows={1}
				sx={{ marginBottom: '10px' }}
			/>

			<Alignment row sx={{ marginBottom: '10px' }}>
				{/* City Field */}
				<FormTextField
					control={control}
					name={"city"}
					label={"City"}
					fullWidth
					sx={{ marginRight: '10px' }}
				/>

				{/* State Field */}
				<FormAutocomplete
					control={control}
					name={"state"}
					autoComplete
					autoSelect
					loading={!states}
					options={states ?? []}
					getValue={(value, options) => options.find(x => x.abbreviation === value)}
					onChange={(state, setValue) => {
						if (state) {
							setValue("country", state.countryAbbrev3);

							//return selected state name
							return state.abbreviation;
						}

						return null;
					}}
					renderInput={(params) => <TextField {...params} label={"State"}/>}
					sx={{ marginRight: '10px', minWidth: '100px' }}

					//add virtualization
					{...STATE_AUTOCOMPLETE_VIRTUALIZER}
				/>
				
				{/* Zip Field */}
				<FormTextField
					control={control}
					name={"zip"}
					label={"Zip"}
					sx={{ minWidth: '110px' }}
				/>
			</Alignment>
			<Alignment row sx={{ marginBottom: '10px' }}>

			{/* Country */}
			<FormAutocomplete
				control={control}
				name={"country"}
				loading={!sortedCountries}
				autoComplete
				autoHighlight
				options={sortedCountries?? []}
				getValue={(country, options) => options.find((x) => x.countryAbbrev3 === country)}
				onChange={(country, setValue) => {
					if (country) {
						//update country value
						setValue("country", country?.countryAbbrev3);

						return country.countryAbbrev3;}

					return null;
					}}
				renderInput={(params) => <TextField {...params} label={"Country"}/>}
                sx={{ width: '360px', marginRight: '10px'}}

				{...COUNTRY_AUTOCOMPLETE_VIRTUALIZER}
			/>

			{/* Address button */}
			<Tooltip title={"Open Address in new tab."} arrow>
				<IconButton
					disabled={address === null || city === null || state === null || zip === null || country === null}
					size={"small"}
					onClick={() => window.open(getMapsLink(formatAddress({address1: address, address2: address2,  city : city, state: state, zip: zip, country: country})))}
					sx={{ width: '30px', height: '30px' }}
				>
				<MapIcon/>
				</IconButton>
			</Tooltip>
				

			</Alignment>
			{/* Web Page Field */}
			<ClientWebsite/>

			<Alignment row flex sx={{ marginBottom: '10px' }}>
				{/* Revenue */}
				<FormNumberTextField
					control={control}
					name={"revenueMM"}
					label={"Revenue (USD)"}
					fullWidth
					decimalScale={3}
					thousandSeparator
					prefix={"$"}
					suffix={" Million"}
					placeholder={"$0 Million"}
					onBlur={({ currentValue, lastValue, setValue }) => {
						if (currentValue !== lastValue) setValue("revenueMMAsOfDate", moment().endOf("day").toDate());
					}}
				/>

				{/* Revenue as of Date */}
				<FormDatePicker
					control={control}
					name={"revenueMMAsOfDate"}
					inputFormat={"M/D/YYYY"}
					disableMaskedInput
					renderInput={(params) => <TextField {...params} label={"As Of"}/>}
					sx={{ minWidth: '150px', marginLeft: '10px' }}
				/>
			</Alignment>

			{/* Notes Field */}
			<FormTextField
				control={control}
				name={"notes"}
				label={"Notes"}
				multiline
				rows={4}
			/>
		</Alignment>
	);
});