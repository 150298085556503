import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import React, { Suspense } from 'react';
import { getIsUserAuthenticated } from '@imas/redux';
import { XSkeleton } from '@imas/components/layout';
import { TopNavigation, PageFooter } from '@imas/components/navigation';
import { makeDeviceStyles } from '@imas/styles';
import { ChunkErrorBoundary } from '@imas/components/misc';
import { isPopup } from '@imas/utils/misc';

const useStyles = makeDeviceStyles()((theme) => ({
    contentRoot: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        width: '100%',
        flex: '1 1 0',
        overflow: 'auto',
        backgroundColor: theme.palette.mode === 'light' ? '#F1F1F1' : undefined
    },
}));

export interface PageProps {
    /** If the app navigation should be hidden. */
    hideNav?: boolean;
    /** If the footer should be shown, this is false by default. This will be overriden if the device is smaller than medium or the window is a popup. */
    showFooter?: boolean;
	/** If this is ture the footer will be forced to be shown no matter what. (Used on Login Page) */
	forceFooter?: boolean;
    /** If unauthenticated users should be allowed to view this page, by default authentication is required. */
    allowUnauthenticated?: boolean;
    /** Page content. */
    children: React.ReactNode;
};

/** 
 * Page Wrapper Component 
 * 
 * Handles authentication re-routing to /login, rendering footer & header on page, and rendering page loader while page content loads if it is code-split.
 */
export const Page = (props: PageProps) => {
    //get component styling & device info
    const { classes, deviceInfo } = useStyles();

	//check if this window is a popup
	const isPopupWindow = isPopup();

    //get showFooter value, if device is md or larger then use the value of showFooter, otherwise default to false.
    const showFooter = deviceInfo.isMd ? props.showFooter ?? false : false;

    //user authenticated state
    const userIsAuthenticated = useSelector(getIsUserAuthenticated);

    //if the user is not authenticated then return them to the login page, unless this page allows unauthenticated users

    if (!userIsAuthenticated && !props.allowUnauthenticated) return <Navigate to="/login"/>;
    return (
		<>
            {/* Display the Top Navigation Bar Unless it should be hidden. (Always hide it if in a popup window.) */}
            {console.log(props.hideNav + " Hide Nav")}
            {console.log(props.allowUnauthenticated + " UnAuth")}
            {console.log(isPopupWindow + " Is Popup")}

            {props.hideNav || props.allowUnauthenticated || isPopupWindow ? null : <TopNavigation key={"top-navigation"}/>}

			{/* Content Root Container */}
			<div className={classes.contentRoot}>
				{/* Chunk Loading Error Boundry */}
				<ChunkErrorBoundary>
					{/* Page Content, Wrapped in Suspense to handle Code-Splitting */}
					<Suspense fallback={<XSkeleton variant={"rectangular"} sx={{ flex: '1', margin: '10px', borderRadius: 1, }}/>}>
						{props.children}
					</Suspense>
				</ChunkErrorBoundary>
			</div>
            
            {/* Display the Page footer conditionally based on screen size and login status. (Always hide it if in a popup window.) */}
            {props.forceFooter || (showFooter && !isPopupWindow) ? <PageFooter key={"page-footer"}/> : null}
		</>
    );
};