import { OptionsObject, SnackbarKey } from 'notistack';
import { useSnackbarRef } from './SnackbarUtilsConfigurator';


/**
 * 	API Allowing the use of notistack outside of React components. 
 */

//function for enqueuing a snackbar via ref
const enqueue = (msg: string, options: OptionsObject): SnackbarKey => {
    return useSnackbarRef.current?.enqueueSnackbar(msg, options) ?? "";
};

export const snackbar = {
    /**
     * Enqueue a notistack notification.
     */
    success: (msg: string, options?: OptionsObject): SnackbarKey => {
        return enqueue(msg, { ...options, variant: 'success'});
    },

    /**
     * Enqueue a notistack notification.
     */
    warning: (msg: string, options?: OptionsObject): SnackbarKey => {
        return enqueue(msg, { ...options, variant: 'warning'});
    },

    /**
     * Enqueue a notistack notification.
     */
    info: (msg: string, options?: OptionsObject): SnackbarKey => {
        return enqueue(msg, { ...options, variant: 'info'});
    },

    /**
     * Enqueue a notistack notification.
     */
    error: (msg: string, options?: OptionsObject): SnackbarKey => {
        return enqueue(msg, { ...options, variant: 'error'});
    },

    enqueue: enqueue,

    /**
     * Close a enqueued snackbar.
     */
    close: (key: SnackbarKey) => {
        useSnackbarRef.current?.closeSnackbar(key);
    },
};