import { Box, ListItem, Checkbox, alpha, lighten } from '@mui/material';
import { FolderInfoItem } from '@imas/api/files/types';
import { makeStyles } from "@imas/styles";
import clsx from 'clsx';
import { useDrag, DragPreviewImage, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { ListChildComponentProps } from 'react-window';
import { FileExplorerMode } from '../../types';
import { FileExplorerItemListRowData } from './FileExplorerItemList';
import { FileExplorerDisplayItem } from '../FileExplorerItemContainer'; 

export const useFileExplorerItemStyles = makeStyles<{ mode: FileExplorerMode }>()((theme, { mode: explorerMode }) => ({
    row: {
        width: 'min-content!important',
        padding: '0 12px',
        color: theme.palette.text.primary,
        "&:hover, &.Mui-selected": {
            backgroundColor:
                theme.palette.mode === 'light'
                ? lighten(theme.palette.grey[600], 0.75)
                : alpha(theme.palette.grey[600], 0.5)
        },
        "&.Mui-selected:hover": {
            cursor: explorerMode === 'explorer' ? 'grab' : 'unset',
            backgroundColor:
                 theme.palette.mode === 'light'
                 ? lighten(theme.palette.grey[600], 0.5)
                 : alpha(theme.palette.grey[600], 0.75)
        },
    },
    dropTarget: {
        backgroundColor: `${theme.palette.mode === 'light'
        ? alpha(theme.palette.success.light, 0.25)
        : alpha(theme.palette.success.dark, 0.25)}!important`,
    },
    column: {
        border: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '2px 12px 2px 4px',
    },
    checkbox: {
        padding: '4px',
        marginRight: '12px',
    },
    nonPrimary: {
        color: theme.palette.text.secondary,
    },
    icon: {
        maxHeight: '20px',
    },
    dragIcon: {
        backgroundColor: alpha("#00F", 0.3), 
    },
}));

interface FileExplorerItemListRowIntractableProps extends Pick<ListChildComponentProps<{}>, "style">, FileExplorerItemListRowData {
    item: FileExplorerDisplayItem;
};

//fully interactable row component with drag/drop functionality 
const InteractableFileExplorerItemListRow = ({ style, item, columnWidths, mode, canUpload, showCheckboxes, getItem, onSelect, onOpen, onMenu, onMove, upload }: FileExplorerItemListRowIntractableProps) => {
    //component styling
    const { classes } = useFileExplorerItemStyles({ mode });

    //drag hook for moving file
    const [, drag, preview] = useDrag(() => ({
        type: "MOVE_FILE",
        item: item,
        end: (_, monitor) => {
            //get drop result (target folder)
            const target = monitor.getDropResult<FolderInfoItem>();

            //call move with the destination as the targeted folder
            if (target !== null) {
                onMove(target);
            };
        },
        canDrag: () => mode === "explorer" && item.selected,
    }), [item, mode]);

    //drop hook for moving file to new folder or uploading a new file
    const [{ isTarget }, drop] = useDrop(() => ({
        accept: ["MOVE_FILE", NativeTypes.FILE],
        drop: (dragItem, monitor) => { 
            //return the target folder if type is "MOVE_FILE"
            if (monitor.getItemType() === "MOVE_FILE") return item;
            //call upload handler if the item type is native file and provide file list & folder info 
            else if (monitor.getItemType() === NativeTypes.FILE) upload((dragItem as { files: File[] }).files, item.item);
        },
        canDrop: (_, monitor) => {
            //ensure target is a folder & is not selected if type is "MOVE_FILE"
            if (monitor.getItemType() === "MOVE_FILE") return item.isDir && !item.selected;
            //ensure the target is a folder & canUpload is true
            else if (monitor.getItemType() === NativeTypes.FILE) return canUpload && item.isDir;
            return false;
        },
        collect: (monitor) => ({
            isTarget: monitor.canDrop() && monitor.isOver(),
        }),
    }), [item, canUpload]);

    return (
        <ListItem
            style={style}
            selected={item.selected}
            onClick={(e) => onSelect(item.item, e.ctrlKey, e.shiftKey)}
            onDoubleClick={() => onOpen(item.item)}
			onContextMenu={(e) => onMenu(item.item, e)}
            className={clsx(classes.row, { [classes.dropTarget]: isTarget })}
            ref={(ref) => { drag(ref); drop(ref); }}
            sx={{ typography: 'body2' }}
        >
            {/* Show Select Checkbox if Enabled */}
            {showCheckboxes ? (
                <Checkbox
                    className={classes.checkbox}
                    checked={item.selected}
                    size={"small"}
                    onChange={(e) => { onSelect(item.item, true); e.stopPropagation(); }}
                    onClick={(e) => e.stopPropagation()}
                />
            ) : undefined}

            {/* Item Drag Preview Image */}
            <DragPreviewImage src={item.iconUrl} connect={preview}/>

            {/* Item Icon */}
            <img src={item.iconUrl} className={classes.icon} alt={"File Type Icon"}/>

            {/* Item Properties */}
            <Box className={classes.column} style={{ width: columnWidths.name }}>{item.name}</Box>
            <Box className={clsx(classes.column, classes.nonPrimary)} style={{ width: columnWidths.date }}>{item.date}</Box>
            <Box className={clsx(classes.column, classes.nonPrimary)} style={{ width: columnWidths.type }}>{item.type}</Box>
            <Box className={clsx(classes.column, classes.nonPrimary)} style={{ width: columnWidths.size }}>{item.size}</Box>
        </ListItem>
    );
};

const FileExplorerItemListRow = ({ index, style, data, isScrolling }: ListChildComponentProps<FileExplorerItemListRowData>) => {
    //get select checkboxes preference
    const { columnWidths, mode, showCheckboxes, getItem, onSelect, onOpen, onMenu } = data;

    //component styling
    const { classes } = useFileExplorerItemStyles({ mode });

    //get this row's display item
    const item = getItem(index);

    //if scrolling, render row without drag hooks to increase performance
    if (isScrolling) {
        return (
            <ListItem
                style={style}
                selected={item.selected}
                onClick={(e) => onSelect(item.item, e.ctrlKey, e.shiftKey)}
                onDoubleClick={() => onOpen(item.item)}
				onContextMenu={(e) => onMenu(item.item, e)}
                className={classes.row}
                sx={{ typography: 'body2' }}
            >
                {/* Show Select Checkbox if Enabled */}
                {showCheckboxes ? (
                    <Checkbox
                        className={classes.checkbox}
                        checked={item.selected}
                        size={"small"}
                        onChange={(e) => { onSelect(item.item, true); e.stopPropagation(); }}
                        onClick={(e) => e.stopPropagation()}
                    />
                ) : null}

                {/* Item Icon */}
                <img src={item.iconUrl} className={classes.icon} alt={"File Type Icon"}/>

                {/* Item Properties */}
                <Box className={classes.column} style={{ width: columnWidths.name }}>{item.name}</Box>
                <Box className={clsx(classes.column, classes.nonPrimary)} style={{ width: columnWidths.date }}>{item.date}</Box>
                <Box className={clsx(classes.column, classes.nonPrimary)} style={{ width: columnWidths.type }}>{item.type}</Box>
                <Box className={clsx(classes.column, classes.nonPrimary)} style={{ width: columnWidths.size }}>{item.size}</Box>
            </ListItem>
        );
    };

    //render interactable row
    return <InteractableFileExplorerItemListRow item={item} style={style} {...data}/>;

};

export { FileExplorerItemListRow };