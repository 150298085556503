import { DataGridEditTextField, DecimalEditCell, EditCellContainer, TypedGridRenderEditCellParams } from '@imas/data-grid';
import { createObjectAutocompleteVirtualizer } from '@imas/utils/virtualization';
import { TimeSheetCertificationHour } from '@imas/api/time-sheet';
import { TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE } from './types';
import { Certification } from '@imas/api/certifications';
import { Autocomplete } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useHourCellStyles } from '../../TimeSheetDetailsForm/TimeSheetDetailsFormUtils/CellsAndHeaders';
import { TimeSheetDetail } from 'src/api/types/api/time-sheet/types';
import { HOUR_CELL_DECIMAL_OPTIONS, isNewHourValueOverDayTotal, TimeSheetCertificationHoursContext } from './utils';

export const TIME_SHEET_CERTIFICATION_METHOD_COL_WIDTH = 80;

const CERTIFICATION_AUTOCOMPLETE_VIRTUALIZER = createObjectAutocompleteVirtualizer<Certification>({ 
	columns: [
		{ field: 'abbreviation', label: 'Method', width: TIME_SHEET_CERTIFICATION_METHOD_COL_WIDTH },
		{ field: 'name', label: '', width: 350 },
	],
	getLabel: (certification) => certification.abbreviation,
	dense: true
});

interface CertificationEditCellProps extends TypedGridRenderEditCellParams<TimeSheetCertificationHour, TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, 'certificationId', 'row'> {
    certifications?: Certification[];
	existingCertificationIds: int[];
};

export const CertificationEditCell = ({ id, value, field, api, hasFocus, certifications,existingCertificationIds }: CertificationEditCellProps) => {
	//input ref
    const ref = useRef<HTMLInputElement | null>(null);
	
    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={!(typeof value === 'number')}>
			<Autocomplete
				value={(certifications ?? []).find(x => x.id === value) ?? null}
				options={certifications ?? []}
				loading={!certifications}
				onChange={(_, value) => {
					api.setEditCellValue({ id, field, value: value?.id ?? null });
				}}
				fullWidth
				//disableClearable
				openOnFocus
				autoHighlight
				onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
				renderInput={(props) => (
					<DataGridEditTextField 
						{...props}
						InputProps={{
							...props.InputProps,
							endAdornment: undefined,
						}}
						inputRef={ref}
					/>
				)}
				sx={{ typography: 'body2' }}
				{...CERTIFICATION_AUTOCOMPLETE_VIRTUALIZER}
				getOptionDisabled={(x => existingCertificationIds.includes(x.id))}
			/>
		</EditCellContainer>
    );
};

interface HourEditCellProps extends TypedGridRenderEditCellParams<TimeSheetCertificationHour, TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun', 'row'> {
	detail?: TimeSheetDetail;
};

export const HourEditCell = (props: HourEditCellProps) => {
	const { classes, cx } = useHourCellStyles();
	const { row, field, value, api } = props;
	const { detail } = useContext(TimeSheetCertificationHoursContext);
	const certificationHours = useMemo(() => { return [...api.getRowModels().values()]; }, []);

	const overHours = useMemo(() => {
		if (!detail) return false;
		return isNewHourValueOverDayTotal(value, field, detail, certificationHours, row);
	}, [value, field, detail, certificationHours, row]);
	
	return (
		<DecimalEditCell<false, HourEditCellProps>
			{...props}
			{...HOUR_CELL_DECIMAL_OPTIONS}
			className={cx({ [classes.invalidHours]: overHours }, classes.hourEdit)}
		/>
	);
};