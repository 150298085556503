import { useApi } from '@imas/api';
import { ClientNote, CreateClientNote, UpdateClientNote, DeleteClientNote } from '@imas/api/client';
import { useClientNotes } from '@imas/api/data-hooks';
import { EditDataGrid, TypedEditDataGridProps, useTGridApiRef, TypedGridApiRef, TypedGridColumnDefs } from '@imas/data-grid';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { memo, useMemo, useCallback } from 'react';

export interface ClientNoteRM extends Pick<ClientNote, "notes"> {};

export interface ClientNotesFormProps {
	/** ID of client. */
	clientId: int;
};

//define the column order for the phone numbers grid & which columns are editable
export type CLIENT_NOTES_GRID_COLUMNS = ["notes"];
export type CLIENT_NOTES_EDITABLE_COLUMNS = "notes";

//define the grid api ref type for the phone numbers grid
export type CLIENT_NOTES_GRID_API_REF = TypedGridApiRef<ClientNote, ClientNoteRM, CLIENT_NOTES_EDITABLE_COLUMNS, "row">;

//grid props
export type CLIENT_NOTES_GRID_PROPS = TypedEditDataGridProps<ClientNote, ClientNoteRM, CLIENT_NOTES_EDITABLE_COLUMNS, CLIENT_NOTES_GRID_COLUMNS, "row">

export const ClientNotesForm = memo(({ clientId }: ClientNotesFormProps) => {
	//use confirmation dialog
	const [confirm] = useConfirmationDialog();
	
	//get phone numbers for specified groupType and groupId
	const [notes] = useClientNotes(clientId);

	//APIs
	const createNote = useApi(CreateClientNote);
	const updateNote = useApi(UpdateClientNote);
	const deleteNote = useApi(DeleteClientNote);

	//grid apiRef
	const apiRef: CLIENT_NOTES_GRID_API_REF = useTGridApiRef();

	//memoized grid column defs
    const columns = useMemo((): TypedGridColumnDefs<ClientNote, ClientNoteRM, CLIENT_NOTES_EDITABLE_COLUMNS, CLIENT_NOTES_GRID_COLUMNS, "row"> => {
        return [
            { field: 'notes', headerName: '', flex: 1, editable: true },
        ];
    }, []);

	//validator
	const validator = useCallback<CLIENT_NOTES_GRID_PROPS["rowValidator"]>(async (row) => {
		if (row.notes === undefined || row.notes === "") throw new Error("Note cannot be empty.");
		
		return {
			notes: row.notes,
		};
	}, []);
	
	//row create handler
	const onCreate = useCallback<CLIENT_NOTES_GRID_PROPS["onRowCreate"]>(async (row) => {
		//create the record
		return await createNote({ clientId, ...row });
	}, [clientId, createNote]);

	//row update handler
	const onUpdate = useCallback<CLIENT_NOTES_GRID_PROPS["onRowUpdate"]>(async (id, row, note) => {
		//update the record
		return await updateNote(note.id, { ...note, ...row });
	}, [updateNote]);
	
	//row delete handler
	const onDelete = useCallback<Exclude<CLIENT_NOTES_GRID_PROPS["onRowDelete"], undefined>>(async (id, note) => {
		//confirm the user wants to delete the phone number
		const deleteConfirmed  = await confirm({
			title: "Delete Confirmation",
			prompt: (
				<>
					<Typography variant={"body1"}>{`Are you sure you would like to delete the following note?`}</Typography><br/>
					<Typography variant={"body1"} sx={{ textAlign: 'center' }}>{note.notes}</Typography>
				</>
			),
			confirmText: "Delete",
			confirmIcon: <DeleteIcon/>
		});
		if (!deleteConfirmed) throw new Error();
		
		//delete the record
		await deleteNote(id);
	}, [deleteNote, confirm]);

	return (
		<EditDataGrid<ClientNote, ClientNoteRM, CLIENT_NOTES_EDITABLE_COLUMNS, CLIENT_NOTES_GRID_COLUMNS, "row">
			apiRef={apiRef}
			editMode={"row"}
			columnHeaderHeight={0}
			experimental_disableCreateReorder
			loading={!notes}
			rows={notes ?? []}
			columns={columns}
			rowValidator={validator}
			onRowCreate={onCreate}
			onRowUpdate={onUpdate}
			onRowDelete={onDelete}
			sx={{ border: 'none' }}
		/>
	);
});