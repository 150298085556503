import { axiosData, SourceGetter } from "@imas/api";
import { ProposalService, ProposalServiceForm, ProposalServiceFee, vProposalService, GetProposalServiceResult } from "./types";

//proposal services base url
const SERVICES_URL = "/api/proposals/services";

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services */
export const GetProposalServices = (getSource: SourceGetter) => {
    return async (): Promise<ProposalService[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services */
export const GetvProposalServices = (getSource: SourceGetter) => {
    return async (): Promise<vProposalService[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}/view`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services */
export const GetProposalServicesTVF = (getSource: SourceGetter) => {
    return async (sitenum: int): Promise<GetProposalServiceResult[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}/${sitenum.toString()}/TVF`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services__serviceId_ */
export const GetProposalService = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<ProposalService> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}/${serviceId}`,
            cancelToken: getSource().token,
        });
    };
};


/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services__serviceId__fees */
export const GetProposalServiceFees = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<ProposalServiceFee[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}/${serviceId}/fees`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services_subcategories */
export const GetProposalServiceSubcategories = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}/subcategories`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services_units */
export const GetProposalServiceUnits = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICES_URL}/units`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/get_api_proposals_services_find_similar */
export const FindSimilarProposalServices = (getSource: SourceGetter) => {
    return async (name: string | null, excludedId?: int): Promise<ProposalService[]> => {
        return axiosData({
            method: 'get',
            url: `${SERVICES_URL}/find-similar`,
            params: {
                name,
                excludedId,
            },
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/post_api_proposals_services */
export const CreateProposalService = (getSource: SourceGetter) => {
    return async (data: ProposalServiceForm): Promise<ProposalService> => {
        return await axiosData({
            method: 'post',
            url: `${SERVICES_URL}`,
			data,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/ProposalServices/put_api_proposals_services__serviceId_ */
export const UpdateProposalService = (getSource: SourceGetter) => {
    return async (serviceId: int, data: ProposalServiceForm): Promise<ProposalService> => {
        return await axiosData({
            method: 'put',
            url: `${SERVICES_URL}/${serviceId}`,
			data,
            cancelToken: getSource().token,
        });
    };
};

/*  */
// export const PatchProposalService = (getSource: SourceGetter) => {
//     return async (serviceId: int, data: Partial<ProposalService>): Promise<ProposalService> => {
//         return await axiosData({
//             method: 'patch',
//             url: `${SERVICES_URL}/${serviceId}`,
//             headers: { "Content-Type": "application/json-patch+json" },
//             data: objectToPatchRequest(data),
//             cancelToken: getSource().token,
//         });
//     };
// };

/* https://localhost:44363/swagger/index.html#/ProposalServices/delete_api_proposals_services__serviceId_ */
export const DeleteProposalService = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<ProposalService> => {
        return await axiosData({
            method: 'delete',
            url: `${SERVICES_URL}/${serviceId}`,
            cancelToken: getSource().token,
        });
    };
};