import { JsonFile } from 'src/api/types/api/JsonFile';

//convert file to a JsonFile
export const fileToJson = (file: File): Promise<JsonFile> => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<JsonFile>((resolve, reject) => {
        reader.onload = () => { 
			resolve({
				name: file.name,
				content: (reader.result as string).split(",")[1]
			});
		};
        reader.onerror = () => reject(null);
    }); 
};

//convert array of files to array of JsonFiles
export const filesToJson = (files: File[]): Promise<JsonFile[]> => {
    return new Promise((resolve, reject) => {
        (async () => {
            const result: JsonFile[] = [];

            for (let file of files) { result.push(await fileToJson(file)); }

            resolve(result);
        })().catch(() => reject(null));
    });
};