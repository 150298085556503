import { makeDeviceStyles } from '@imas/styles';

const MobileClientSignatureStyles = makeDeviceStyles()((theme) => ({
    container: {
        flex: '1',
        overflow: 'hidden'
    },
    header: {
        padding: '5px'
    },
    methodBtn: {
        margin: 'auto 0 auto auto '
    },
    list: {
        overflow: 'scroll'
    },
    item: {
        width: '-webkit-fill-available',
        display: 'flex',
        flexDirection: 'column',
    },
    itemBordered: {
        borderTop: theme.palette.mode === 'light' ? 'solid rgba(0, 0, 0, 0.15) 1px' : 'solid rgba(255, 255, 255, 0.12) 1px',
    },
    itemRow: {
        width: '-webkit-fill-available',
    },
    itemRightText: {
        marginLeft: 'auto'
    },
    editableIndicator: {
        marginRight: '16px',
        width: '10px',
        height: '100%',
        backgroundColor: theme.palette.success.main
    },
    lockedIndicator: {

    },
    hourSummaryTitle: {
        fontWeight: 'bold',
    },
    errText: {
        alignSelf: 'start',
    },
	tableTopRow: {
        "& .MuiTableCell-head": {
            fontWeight: 'bold',
			padding:2,
            "& > div > p": {
				margin: 'auto 0',
                fontWeight: 'bold',
				padding:2,
            },
        },
    },
	tableCell: {
		border: 1,
		margin: 'auto 0',
	},
	propNumContainer: {
        background: theme.palette.primary.main,
        margin: 'auto 20px auto 10px',
        padding: '10px',
    },
	propNumText: {
        color: theme.palette.text.navigation,
        margin: 'auto',
    },
    detailsContainer: {
        justifyContent: 'center',
    },
	clientName: {
        fontWeight: 600,
		color: theme.palette.mode === 'light' ? '#000' : theme.palette.text.navigation,
    },
	darkModeHelp: {
		color: theme.palette.mode === 'light' ? '#000' : theme.palette.text.navigation,
	},
	titleContainer: {
        padding: '16px 24px 0px 24px',
    },
	headerDivider: {
        margin: "10px 0 10px 0",
    },
	smallJobName: {
        //marginTop: '10px',
        textAlign: 'center',
		color: theme.palette.mode === 'light' ? '#000' : theme.palette.text.navigation,
    },
	link: {
        color: theme.palette.text.navigation,
        display: 'flex'
    },
	pageText : {
		margin: '10px 0 0 10px'
	},
	signatureTextfield: {
		
	},
}));

export default MobileClientSignatureStyles;