import { useState, useEffect } from 'react';

//hook for detecting camera or audio input or other media like output
export const useDeviceDevices = () => {
    //state 
    const [state, setState] = useState<{
        hasAudioInput: boolean,
        hasVideoInput: boolean,
        hasMedia: boolean,
        audioInputDevices: MediaDeviceInfo[],
        videoInputDevices: MediaDeviceInfo[],
        mediaDevices: MediaDeviceInfo[]
    }>({
        hasAudioInput: false,
        hasVideoInput: false,
        hasMedia: false,
        audioInputDevices: [],
        videoInputDevices: [],
        mediaDevices: []
    });

    //detect media devices on mount
    useEffect(() => {
        navigator?.mediaDevices?.enumerateDevices().then((devices) => {
            setState({
                hasAudioInput: devices.some(x => x.kind === "audioinput"),
                hasVideoInput: devices.some(x => x.kind === "videoinput"),
                hasMedia: devices.some(x => x.kind === "audiooutput"),
                audioInputDevices: devices.filter(x => x.kind === "audioinput") as MediaDeviceInfo[],
                videoInputDevices: devices.filter(x => x.kind === "videoinput") as MediaDeviceInfo[],
                mediaDevices: devices.filter(x => x.kind === "audiooutput") as MediaDeviceInfo[]
            });
        });
    }, []);

    return state;
};