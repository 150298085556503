import { SourceGetter, useApi, useLoadApi } from  '@imas/api';
import axios from 'axios';
import { GOOGLE_MAPS_API_KEY } from '@imas/constants';
import { GetSites, Site } from '@imas/api/system';
import { isRejected } from '@reduxjs/toolkit';
import { DistanceMatrixService } from '@react-google-maps/api';


//https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=AIzaSyCZK37W936HQXLbB2uk74637yHJcGJpjTs


/**
 *  Get information about a lat/long from the google maps API
 */
 export const _GetGeoLocationInformation = (getSource: SourceGetter) => {
    //return the wrapped API function
    return (latitude: double | null, longitude: double | null): Promise<google.maps.GeocoderResponse | null> => {
        //get a source for the request
        const source = getSource();

        //create a promise for handling the result
        return new Promise<google.maps.GeocoderResponse | null>((resolve, reject) => {
            if (latitude === null || longitude === null) return reject(null);

            //make API request
            axios({
                method: 'post',
                url: 'https://maps.googleapis.com/maps/api/geocode/json',
                params: {
                    latlng: `${latitude},${longitude}`,
                    key: GOOGLE_MAPS_API_KEY,
                },
                cancelToken: source.token
            }).then((response) => {
                if (response.status === 204) return resolve(null);
                
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export interface DistanceMatrixResponse {
	destination_addresses: string[],
	origin_addresses: string[],
	rows: [{
		elements: [{
			distance: {
				text: string,
				value: int,
			},
			duration: {
				text: string,
				value: int,
			},
			status: string,
		}]
	}],
	status: string,
}

/**
 * Get the mileage and travel time from a site to a servicelocation
 */
export const _GetDistanceMatrix = (getSource: SourceGetter) => {

	//return the wrapped API function
	return (sitenum: int | null, serviceLocation: any | null): Promise<DistanceMatrixResponse> => {

		//get a source for the request
        const source = getSource();

		//create a promise for handling the result
		return new Promise<DistanceMatrixResponse>((resolve, reject) => {
            if (sitenum === null || serviceLocation === null) return reject(null);

			//make API request
			axios({
				method: 'get',
				url: '/api/mileage/' + sitenum.toString(),
				params: {
					serviceLocation: serviceLocation,
				},
				cancelToken: source.token
			}).then((response) => {	
				console.log(response.data);				
				//resolve the promise
				resolve(response.data);
			}).catch(e => reject(e));
		});
	};
};