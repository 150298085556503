import { mapApi, ApiConfig } from '@imas/api';
import { getAuthLocation } from '@imas/utils/geolocation';
import { getAuthDevice, getDeviceUniqueId } from '@imas/utils/device';
import { UserData } from './types';

//auth base url
const AUTH_URL = "/api/auth";

/*  */
export const GetAuthentication = mapApi((params?: { latitude: double, longitude: double, accuracy: double }): ApiConfig<UserData> => ({
	method: 'get',
	url: `${AUTH_URL}`,
	params: {
		latitude: params?.latitude,
		longitude: params?.longitude,
		accuracy: params?.accuracy,
		deviceId: getDeviceUniqueId(),
	},
}));

/*  */
export const AuthenticateUser = mapApi(async ({ username, password, remember, sitenum }: {username: string, password: string, remember: boolean, sitenum: int}): Promise<ApiConfig<UserData>> => ({
	method: 'post',
	url: `${AUTH_URL}`,
	data: {
		username,
		password,
		sitenum,
		remember,
		location: await getAuthLocation(),
		device: await getAuthDevice(),
	},
}));

/*  */
export const AuthenticateMicrosoftUser = mapApi(async ({ accessToken, sitenum }: { accessToken: string, sitenum: int }): Promise<ApiConfig<UserData>> => ({
	method: 'post',
	url: `${AUTH_URL}/microsoft`,
	data: {
		accessToken,
		sitenum,
		location: await getAuthLocation(),
		device: await getAuthDevice(),
	},
}));

/*  */
export const LogOutUser = mapApi((): ApiConfig<void> => ({
	method: 'delete',
	url: `${AUTH_URL}`,
}));

/*  */
export const CheckSession = mapApi((): ApiConfig<boolean> => ({
	method: 'get',
	url: `${AUTH_URL}/session`,
}));