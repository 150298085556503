import { Button, IconButton, PropTypes, SvgIconTypeMap, ButtonPropsColorOverrides } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from 'react';
import { FileTables } from '@imas/api/files/types';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { getFileDownloadUrl } from '@imas/utils/files';
import { OverridableStringUnion } from '@mui/types';

//DragEventCallback interface
interface DragEventCallback<T> { (event: React.DragEvent<T>): void };

//FileDownloaderProps
interface FileDownloaderProps {
    pathLocator: string,
    fileTable: FileTables,
    filename?: string,
    disabled?: boolean,
    text?: string,
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    iconLocation?: "start" | "end",
    size?: "small" | "medium" | undefined,
    color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>,
    variant?: "text" | "outlined" | "contained",
    className?: string,
    onDownload?: () => void
};

//FileDownloaderRef
export interface FileDownloaderRef<T> {
    onDragStart: DragEventCallback<T>
};

const FileDownloader = forwardRef<FileDownloaderRef<any>, FileDownloaderProps>((props: FileDownloaderProps, ref) => {
    //component props
    const { pathLocator, fileTable, filename, text, disabled, icon, iconLocation, size, color, variant, className, onDownload } = props;

    //default value handling
    const Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = icon === undefined ? CloudDownloadIcon : icon;    

    //onDragStart function
    const onDragStart = (event: React.DragEvent<any>) => {
        if (disabled) return;

        //set the data of the transfer
        event.dataTransfer.setData("DownloadURL", "application/octet-stream:" + (filename ?? "download") + ":" + getFileDownloadUrl(pathLocator, fileTable));
    
        //also trigger onDownload
        if(onDownload !== undefined) onDownload();
    };

    //set ref on load
    useImperativeHandle(ref, () => ({
        onDragStart: onDragStart
    }));

    //show a Icon button if no text was provided, otherwise show a normal button
    if (text === undefined) {
        return (
            <IconButton
                disabled={disabled}
                size={size}
                color={color}
                className={className}
                onClick={onDownload}
                href={getFileDownloadUrl(pathLocator, fileTable)}
                download
            ><Icon/></IconButton>
        );
    } else {
        return (
            <Button
                disabled={disabled}
                size={size}
                color={color}
                variant={variant} 
                className={className}
                endIcon={iconLocation === "end" ? <Icon/> : null} 
                startIcon={iconLocation === "start" || iconLocation === undefined ? <Icon/> : null}
                onClick={onDownload}
                href={getFileDownloadUrl(pathLocator, fileTable)}
                download
            >{text}</Button>
        );
    }
});


export { FileDownloader };