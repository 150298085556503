import { useLoadApi } from '@imas/api';
import { GetCertifications } from '@imas/api/certifications';
import { TimeSheetCertificationHour, TimeSheetDetail } from '@imas/api/time-sheet';
import { TypedGridColumnDefs } from '@imas/data-grid';
import { useMemo, useRef } from 'react';
import Enumerable from 'linq';
import { TimeSheetWeekdayWidthMap } from '../../TimeSheetDetailsForm/TimeSheetDetailsFormUtils';
import { HourColumnHeader } from './Components';
import { CertificationEditCell, TIME_SHEET_CERTIFICATION_METHOD_COL_WIDTH } from './EditCells';
import { TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_COLUMNS } from './types';
import { HOUR_COL_DEF } from './columnTypes';

export interface UseTimeSheetCertificationHoursColDefsProps {
	detail?: TimeSheetDetail;
	managementMode: boolean;
	hourColWidths?: TimeSheetWeekdayWidthMap;
	existingCertificationIds: int[];
};

export const useTimeSheetCertificationHoursFormColDefs = ({ detail, existingCertificationIds, managementMode, hourColWidths }: UseTimeSheetCertificationHoursColDefsProps) => {
	const { data: certifications } = useLoadApi(GetCertifications, [], [], {
		mutate: (result) => {
			return Enumerable.from(result)
				.where(x => x.categoryId !== null)
				.orderBy(x => x.abbreviation)
				.toArray();
		},
	});

	const hourColWidthsRef = useRef(hourColWidths);
	hourColWidthsRef.current = hourColWidths;

	const columns = useMemo((): TypedGridColumnDefs<TimeSheetCertificationHour, TimeSheetCertificationHourRM, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_EDITABLE, TIME_SHEET_CERTIFICATION_HOURS_EDIT_GRID_COLUMNS, "row"> => {
		return [
			{ 
				editable: true, 
				field: 'certificationId', 
				headerName: 'Method', 
				width: TIME_SHEET_CERTIFICATION_METHOD_COL_WIDTH,
				type: 'int',
				renderEditCell: (props) => <CertificationEditCell {...props} certifications={certifications} existingCertificationIds={existingCertificationIds}/>,
				valueFormatter: ({ value }) => certifications?.find(x => x.id === value)?.abbreviation ?? '',
			},
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'sat', type: 'hours', width: hourColWidthsRef.current?.sat },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'sun', type: 'hours', width: hourColWidthsRef.current?.sun },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'mon', type: 'hours', width: hourColWidthsRef.current?.mon },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'tue', type: 'hours', width: hourColWidthsRef.current?.tue },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'wed', type: 'hours', width: hourColWidthsRef.current?.wed },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'thu', type: 'hours', width: hourColWidthsRef.current?.thu },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'fri', type: 'hours', width: hourColWidthsRef.current?.fri },
		];
	}, [managementMode, detail, certifications, existingCertificationIds]);

	//if the columns are loading data
	const loading = useMemo(() => !detail || !certifications, [detail, certifications]);

	return { columns, loading };
};