import { getDeviceUniqueId } from '@imas/utils/device';
import { useLoadApi, useApi } from '@imas/api';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { CreateUserNotificationSubscription, GetUserNotificationSubscriptions } from '@imas/api/user';

//returns the current user's notification subscription for this device + all of their other subcriptions
export const useNotificationSubscription = () => {
    //use api for creating notification subscription
    const createNotificationSubscription = useApi(CreateUserNotificationSubscription);

    //this device's pushSubscription, if it exists
    const [pushSubscription, setPushSubscription] = useState<PushSubscription | null | undefined>(undefined);

    //get notification subscriptions belonging to the user
    const { data: notificationSubscriptions, call: refreshNotificationSubscriptions } = useLoadApi(GetUserNotificationSubscriptions, [], []);

    //device's unique id 
    const deviceUniqueId = getDeviceUniqueId();

    //find the subscription for this device in the user's notification subscriptions
    const getDeviceSubscription = () => {
        if (notificationSubscriptions === undefined) return undefined;

        return notificationSubscriptions.find(x => x.deviceId === deviceUniqueId) ?? null;
    };

    //memoized value of deviceSubscription
    const deviceSubscription = useMemo(getDeviceSubscription, [deviceUniqueId, notificationSubscriptions]);

    //refresh subscription information
    const refresh = useCallback(() => {
        refreshNotificationSubscriptions();
    }, [refreshNotificationSubscriptions]);

    //use effect, checks if this device has a pushSubscription which is not stored in the user's list of notification subscriptions, if it is not then it will call to add it
    useEffect(() => {
        //if this device has a pushSubscription
        if (pushSubscription) {
            //but the "deviceSubscription" is null or if deviceSubscription.p256dh does not match this devices push subscription
            if (deviceSubscription === null || deviceSubscription?.p256dh !== pushSubscription.toJSON().keys?.p256dh) {
                //try to add this device's subscription to the user's stored list of notification subscriptions
                //get subscription json
                const subscriptionJson = pushSubscription.toJSON(); 

                //show error if keys are not provided
                if (subscriptionJson.endpoint === undefined || subscriptionJson.keys?.auth === undefined || subscriptionJson.keys?.p256dh === undefined) {
                    throw "Invalid subscription object.";
                }

                //make request to store subscription
                createNotificationSubscription({ 
                    endpoint: subscriptionJson.endpoint, 
                    expirationDate: subscriptionJson.expirationTime ?? null,  
                    auth: subscriptionJson.keys.auth, 
                    p256dh: subscriptionJson.keys.p256dh 
                }).then(() => {
                    //refresh notificationSubscriptions
                    refreshNotificationSubscriptions();
                }).catch(() => console.error("Unable to store existing push notification subscription."));
            }
        }
    }, [pushSubscription, deviceSubscription]);

    //use effect to get device push subscription 
    useEffect(() => {
        (async () => {
            //get service worker registration
            const registration = await navigator.serviceWorker.getRegistration();

            //if there is no registration
            if (registration === undefined) throw null;

            //check if the device supports web push
            if (!('pushManager' in registration)) throw null;

            //get existing subscription if it exists
            const currentSubscription = await registration.pushManager.getSubscription();

            //store the subscription
            setPushSubscription(currentSubscription);
        })().catch(() => setPushSubscription(null));
    }, []);

    return {
        deviceSubscription,
        allSubscriptions: notificationSubscriptions ?? [],
        refreshSubscriptions: refresh
    };
};