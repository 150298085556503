import { axiosData, SourceGetter } from '@imas/api';
import { ProposalTest } from './types';

//proposal tests base url
const PROPOSAL_TESTS_URL = "/api/proposals/tests";

/*  */
export const GetProposalTests = (getSource: SourceGetter) => {
    return async (pronum: int): Promise<ProposalTest[]> => {
        return axiosData({
			method: 'get',
            url: `${PROPOSAL_TESTS_URL}/${pronum}`,
            cancelToken: getSource().token,
		});
    };
};

export const CreateProposalTests = (getSource: SourceGetter) => {
    return async (pronum: int, uniqueId: int, testIds: int[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${PROPOSAL_TESTS_URL}`,
            data: {
                pronum,
                uniqueId,
                testIds,
            },
            cancelToken: getSource().token,
		});
    };
};