import { AppBar, Link, Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { VersionHistory } from '@imas/components/misc';
import { makeDeviceStyles } from '@imas/styles';
import { getIsUserAuthenticated } from '@imas/redux';
import { Alignment, Spacer } from '@imas/components/layout';
import VersionHistoryDialog from '@imas/components/misc/VersionHistory/VersionHistoryDialog/VersionHistoryDialog';
import React, { useMemo, useState } from 'react';


//PageFooter component styling
const useStyles = makeDeviceStyles()((theme) => ({
    footer: {
        top: 'auto', 
        bottom: 0,
        "@media (max-height: 500px)": {
            display: 'none',
        },
        zIndex: 850,
    },
    toolbar: {
        minHeight: '48px',
    },
    link: {
        color: theme.palette.text.navigation,
        display: 'flex'
    },
    version: {
        color: theme.palette.text.navigation,
        marginLeft: 'auto'
    },
    logoImg: {
        paddingTop: '4px',
        paddingBottom: '4px',
        margin: "auto 0px auto 20px",
    },
}));



/* Persistent Footer Component for Page */
const PageFooter = () => {
    //component styling & deviceInfo   
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const { classes, deviceInfo } = useStyles();

    //get if the user is logged in
    const loggedIn = useSelector(getIsUserAuthenticated);

    return (
		<AppBar className={classes.footer} position={"static"}>
			<Toolbar className={classes.toolbar}>
				{/* Spacing */}
				<Spacer horizontal size={"40px"}/>
				
				{/* PNL Copyright and link to main website */}
				<Link 
					className={classes.link}
					variant={"subtitle1"}
					href={"https://pnltest.com/"}
					tabIndex={-1}
				>&#169;{new Date().getFullYear()} - PNL</Link>
				<Spacer horizontal size={"40px"}/>

				{/* PNL Address and link to google maps */}
				<Link
					className={classes.link}
					variant={"subtitle1"} 
					href={"https://goo.gl/maps/HUs5YQ5jdsGYoCd49"}
					tabIndex={-1}
				>{!deviceInfo.isMd ? "Map" : "941 South Park Lane, Tempe, AZ 85281"}</Link>
				<Spacer horizontal size={"40px"}/>


				{/* Version Number */}
                <Alignment sx={{marginLeft: 'auto', marginRight: '0'}} onClick={() => {
                    if(loggedIn)
                    setDialogOpen(true);
                }}>
                <VersionHistory
					disabled={!loggedIn}
					linkProps={{ variant: "subtitle1" }} 
					className={classes.version}
				/>

                </Alignment>
				

				{/* Logo! */}
				{deviceInfo.isMd ? <img className={classes.logoImg } src="/icons/imas-icon-48.png" alt={"PNL Logo Icon"}/> : null}
                {dialogOpen ? (
                <VersionHistoryDialog onClose={() => setDialogOpen(false)}/>
            ) : null}
			</Toolbar>
		</AppBar>
    );
};

export { PageFooter };