import axios from 'axios';
import { SourceGetter } from  '@imas/api';
import { FileTables, FileViewResult, FolderInfo } from './types';

/**
 *  Get folder info.
 */
export const GetFolderInfo = (getSource: SourceGetter) => {
    return async (table: FileTables, folderPathLocator: HierarchyId): Promise<FolderInfo> => {
        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'get',
            url: '/api/files/folder',
            params: {
                table: table,
                folder: folderPathLocator
            },
            cancelToken: getSource().token,
        });

        //return result
        return result.data;
    };
};

/**
*  Get a FileViewResult given a parent folder and job folder location
*/
export const GetTreeView = (getSource: SourceGetter) => {
    return async (table: FileTables, folderPathLocator: HierarchyId): Promise<FileViewResult> => {
        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'get',
            url: '/api/files/folder/tree-view',
            params: {
                table: table,
                folder: folderPathLocator
            },
            cancelToken: getSource().token,
        });

        //return result
        return result.data;
    };
};
