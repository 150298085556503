import { UserData } from '@imas/api/auth';
import { getApi } from '@imas/api';
import { cacheDb } from '@imas/utils/storage';
import { Employee } from '@imas/api/employee';
import { GetDailyScheduledEvents } from './api/schedule';
import moment from 'moment';


//task for loading the personal, field, lab, and all schedule for the current day and current user
const getSchedule = (employeeData: Employee) => {
    //get date for today
    const today = moment();

    //get api
    const { api: getSchedule } = getApi(GetDailyScheduledEvents);

	//get personal, field, lab, and all schedules for current day
	getSchedule(employeeData.employeeId, today, employeeData.sitenum, null);
	getSchedule(employeeData.employeeId, today, employeeData.sitenum, 0);
	getSchedule(employeeData.employeeId, today, employeeData.sitenum, 1);
	getSchedule(employeeData.employeeId, today, employeeData.sitenum, null);
};

//task for removing cached schedule more than 1 week old
const clearOldCachedSchedule = () => {
    //clear normal schedule more than 1 day old
    cacheDb.transaction('rw', cacheDb.dailyScheduledEvents, async () => {
        const keys = await cacheDb.dailyScheduledEvents.where("date").below(moment().subtract(7, "days").startOf("day").toDate()).primaryKeys();
        cacheDb.dailyScheduledEvents.bulkDelete(keys);
    });
};

//on login function (runs when the employee logs in)
export const onLogin = (data: UserData) => {
    getSchedule(data.employee);
};

//startup function (runs on apps first load)
export const onStartup = () => {
    clearOldCachedSchedule();
};