import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserRoles } from '@imas/redux';
import { useLoadApi } from '@imas/api';
import { GetEmployeeMilestoneDates } from '@imas/api/employee';
import { Alignment, XSkeleton } from '@imas/components/layout';
import { Link } from 'react-router-dom';
import { EmployeeMilestoneDatesResult } from 'src/api/types/api/employee/employee';
import moment from 'moment';
import { employeeMilestoneViewerStyles } from './EmployeeMilestoneViewerStyles';

export const EmployeeMilestoneViewer = () => {
    //component styling
    const { classes } = employeeMilestoneViewerStyles();

    //get user roles
    const userRoles = useSelector(getUserRoles);

    //get anniversaries
    const { data: milestoneDates } = useLoadApi(GetEmployeeMilestoneDates, [], []);

    //render nothing of the user is not a admin or manager
    //if (!(userRoles.admin || userRoles.manager)) return null;

    const EmployeeMilestoneViewerCategory = ({ label, items }: { label: string, items?: EmployeeMilestoneDatesResult[] }) => {
        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.length === 0 ? (
                        <ListItem><ListItemText primary={"None"}/></ListItem>
                    ) : items.sort((a, b) => moment(a.milestoneDate).valueOf() - moment(b.milestoneDate).valueOf()).map((item, index) => (
                        <ListItem key={index} className={classes.listItem} selected={moment(item.milestoneDate).isSame(moment(), "day")} button component={Link} to={`/home/employee-directory/${item.employeeId}`}>
                            <ListItemText primary={`${item.employeeName}${item.milestoneId === 2 ? (` (${item.milestoneYear.toFixed()} ${Math.round(item.milestoneYear) === 1 ? "Year" : "Years"}${item.milestoneYear !== item.milestoneBridgeYear ? ` / ${item.milestoneBridgeYear.toFixed()} Overall` : "" })`) : ""}`} secondary={moment(item.milestoneDate).format("ddd, MMM Do")}/>
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

    return (
        <Paper className={classes.viewerContainer}>
            <Typography variant={"h5"} style={{ fontWeight: 'bold', margin: '10px auto' }}>{"Important Dates"}</Typography>
            
            <Alignment overflowScroll style={{ margin: '0 15px', flex: '1 1 0' }}>
                {/* Anniversaries */}
                <EmployeeMilestoneViewerCategory label={"Anniversaries"} items={milestoneDates ? milestoneDates.filter(x => x.milestoneId === 2) : undefined}/>

                {/* Birthdays */}
                <EmployeeMilestoneViewerCategory label={"Birthdays"} items={milestoneDates ? milestoneDates.filter(x => x.milestoneId === 0) : undefined}/>

                {/* New Hires */}
                <EmployeeMilestoneViewerCategory label={"New Hires"} items={milestoneDates ? milestoneDates.filter(x => x.milestoneId === 1) : undefined}/>
            </Alignment>
        </Paper>
    );
};