import { axiosData, axiosNullableData, SourceGetter } from "@imas/api";
import { ServiceVariant, CreateServiceVariantRequest } from './types';

//service variants base url
const SERVICE_VARIANTS_URL = "/api/proposals/service-variants";

//gets all service variants
export const GetServiceVariants = (getSource: SourceGetter) => {
    return async (): Promise<ServiceVariant[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_VARIANTS_URL}`,
            cancelToken: getSource().token,
        });
    };
};

//update a service variant
export const UpdateServiceVariant = (getSource: SourceGetter) => {
    return async (variant: ServiceVariant): Promise<ServiceVariant> => {
        return await axiosData({
            method: 'put',
            url: `${SERVICE_VARIANTS_URL}`,
			data: variant,
            cancelToken: getSource().token,
        });
    };
};

//create a new service variant
export const CreateServiceVariant = (getSource: SourceGetter) => {
    return async (request: CreateServiceVariantRequest): Promise<ServiceVariant> => {
        return await axiosData({
            method: 'post',
            url: `${SERVICE_VARIANTS_URL}`,
			data: request,
            cancelToken: getSource().token,
        });
    };
};

//delete a service variant
export const DeleteServiceVariant = (getSource: SourceGetter) => {
    return async (servicevariantid: int): Promise<void> => {
        return await axiosData({
            method: 'delete',
            url: `${SERVICE_VARIANTS_URL}/${servicevariantid.toString()}`,
            cancelToken: getSource().token,
        });
    };
};