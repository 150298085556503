export type { _VersionCode as VersionCode } from './version/VersionCode';
export type { _VersionCodeItem as VersionCodeItem } from './version/VersionCodeItem';

export enum FeedbackCategory {
    None = "NONE",
    BugReport = "BUG_REPORT",
    NewFeature = "NEW_FEATURE",
    GeneralWebsite = "GENERAL_WEBSITE",
    EaseOfUse = "EASE_OF_USE",
    CompanyFeedback = "COMPANY_FEEDBACK",
    CompanySuggestion = "COMPANY_SUGGESTION"
};

export type { _PaymentTerm as PaymentTerm } from './PaymentTerm';

export * from './Message';
export * from '../../../../modules/api/mangager/types/ScoreCard/ScoreCard';
export * from './vehicles/GetAvailableVehsResult';
export * from './vehicles/TVehicleInspectionForm';
export * from './vehicles/VehicleInspection';
export * from './vehicles/vVehicle';
export * from './vehicles/vVechicleInspection';