import { HubProvider } from '@imas/api';
import { DialogProvider } from '@imas/utils/dialog';
import { FC } from 'react';

const AppProviders: FC<{}> = ({ children }) => {
	return (
		<HubProvider>
			<DialogProvider>
				{children}
			</DialogProvider>
		</HubProvider>
	);
};

export default AppProviders;