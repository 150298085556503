import axios from 'axios';
import { axiosData, axiosFile, objectToFormData, SourceGetter } from '@imas/api';
import { FileInfoResult, FileTableFile, FileTables, FileInfoQuery } from './types';
import { JsonFile } from 'src/api/types/api/JsonFile';

//files base url
const FILES_URL = "/api/files";

/** */
export const GetFile = (getSource: SourceGetter) => {
    return async (table: FileTables, file: HierarchyId): Promise<FileTableFile> => {
        return await axiosData({
            method: 'get',
            url: `${FILES_URL}`,
            params: {
                table,
                file,
            },
            cancelToken: getSource().token
        });
    };
};

export const GetFileFromReportingServer = (getSource: SourceGetter) => {
	return (url: string, name: string) => {
        return new Promise<JsonFile>((resolve, reject) => {
            axios({
				method: 'get',
            	url: `${FILES_URL}/reporting-server`,
            	params: {
            	    url,
					name,
            	},
            	cancelToken: getSource().token
        	}).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/** Get DownloadFile url. */
export const DownloadFileURL = (table: FileTables, file: HierarchyId): string => {
	return axios.getUri({
		method: 'get',
		url: `${FILES_URL}/download`,
		params: {
			table,
			file,
		},
	});
};

/** */
export const DownloadFile = (getSource: SourceGetter) => {
    return async (table: FileTables, file: HierarchyId): Promise<File> => {
        return await axiosFile({
            method: 'get',
            url: `${FILES_URL}/download`,
            params: {
                table,
                file,
            },
            cancelToken: getSource().token
        });
    };
};

// Upload a file
export const UploadFile = (getSource: SourceGetter) => {
    return async (table: FileTables, folder: HierarchyId, file: File): Promise<int | null> => {
        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'post',
            url: '/api/file',
            data: objectToFormData({
                table,
                folder,
                file
            }),
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: getSource().token,
        });

        //return result
        if (result.status === 204) return null;
        return result.data;
    };
};

// Rename a given file
export const RenameFile = (getSource: SourceGetter) => {
    return async (table: FileTables, file: HierarchyId, name: string): Promise<int | null> => {
        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'post',
            url: '/api/file/rename',
            data: {
                table,
                file,
                name
            },
            cancelToken: getSource().token,
        });

        //return result
        if (result.status === 204) return null;
        return result.data;
    };
};

// Move a given file
export const MoveFile = (getSource: SourceGetter) => {
    return async (table: FileTables, file: HierarchyId, destination: HierarchyId): Promise<int | null> => {
        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'post',
            url: '/api/file/move',
            data: {
                table,
                file,
                destination
            },
            cancelToken: getSource().token,
        });

        //return result
        if (result.status === 204) return null;
        return result.data;
    };
};

// Delete a given file
export const DeleteFile = (getSource: SourceGetter) => {
    return async (table: FileTables, file: HierarchyId): Promise<int | null> => {
        //make API call and return a promise which resolves into the result
        const result = await axios({
            method: 'delete',
            url: '/api/file',
            data: {
                table,
                file
            },
            cancelToken: getSource().token,
        });

        //return result
        if (result.status === 204) return null;
        return result.data;
    };
};


/** */
export const GetFilePreview = (getSource: SourceGetter) => {
    return (pathLocator: HierarchyId, table: FileTables, quality?: float, useWebP?: boolean): Promise<File> => {
		return axiosFile({
			method: 'get',
			url: '/api/file/preview',
			params: {
				table: table,
				file: pathLocator,
				quality: quality ?? null,
				useWebP: useWebP ?? false,
			},
			cancelToken: getSource().token,
		});
    };
};

/**
 * Get a  FileInfoResult given the file's table and pathLocator
 */
export const GetFileInfo = (getSource: SourceGetter) => {
    return (table: FileTables, filePathLocator: HierarchyId): Promise<FileInfoResult> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<FileInfoResult>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/files/path',
                data: {
                    table: table,
                    path: filePathLocator
                },
                cancelToken: source.token
            }).then((response) => {
                if (response.status === 204) return reject("Not Found");

                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
* Get a list of FileInfoResult given a list of file's and their table and pathLocator
*/
export const GetFileInfos = (getSource: SourceGetter) => {
    return (files: FileInfoQuery[]): Promise<FileInfoResult[]> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<FileInfoResult[]>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/files/paths',
                data: files,
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
* Test
*/
export const ScopeImageTest = (getSource: SourceGetter) => {
    return (pronum: int): Promise<void> => {
        //get a source for the request 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<void>((resolve, reject) => {
            axios({
                method: 'get',
                url: `/api/${pronum}/scope-image`,
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};