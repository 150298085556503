import { makeDeviceStyles } from '@imas/styles';
import { darken } from '@mui/material';

export const dailyScheduleDialogStyles = makeDeviceStyles()((theme) => ({
    container: {
        display: 'flex',
        minWidth: '300px',
        minHeight: '250px',
    },
    containerSpinner: {
        margin: 'auto',
    },
    titleContainer: {
        padding: '16px 24px 0px 24px',
    },
    propNumContainer: {
        background: theme.palette.primary.main,
        margin: 'auto 20px auto 10px',
        padding: '10px',
    },
    smallJobName: {
        marginTop: '10px',
        textAlign: 'center',
    },
    propNumText: {
        color: theme.palette.text.navigation,
        margin: 'auto',
    },
    detailsContainer: {
        justifyContent: 'center',
    },
    clientName: {
        fontWeight: 600,
    },
    boldedDescriptor: {
        fontWeight: 600,
        margin: 'auto 5px auto 0',
    },
    alignedText: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    timeContainer: {
        padding: '10px',
    },
    headerDivider: {
        margin: "10px 0 10px 0",
    },
    fullViewButton: {
        width: '100%',
    },
    checkIntoJob: {
        background: theme.palette.mode === 'light' ? theme.palette.warning.main : theme.palette.warning.dark,
        "&:hover": {
            background: darken(theme.palette.mode === 'light' ? theme.palette.warning.main : theme.palette.warning.dark, 0.10),
        },
    },
}));