import { DATE_COL, NULLABLE_DATE_COL } from './DATE_COL';
import { INTEGER_COL } from './INTEGER_COL';
import { USD_COL, NULLABLE_USD_COL } from './USD_COL';

export const ALL_COL_TYPES = {
	"date": NULLABLE_DATE_COL,
	"required-date": DATE_COL,
	"usd": NULLABLE_USD_COL,
    "required-usd": USD_COL,
	"int": INTEGER_COL,
};