import { SourceGetter, axiosData } from '../_internals';
import { vClientLocationContactListDetail } from './types';


//contact list details base url
const CONTACT_LIST_DETAILS_URL = "/api/clients/locations/contacts/lists/details";

/* Get contact list details given a contact list id */
export const GetContactListDetails = (getSource: SourceGetter) => {
    return async (contactListId: int): Promise<vClientLocationContactListDetail[]> => {
        return axiosData({
			method: 'get',
            url: `${CONTACT_LIST_DETAILS_URL}/${contactListId.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

/* Create ContactListDetails given a contactListId and contactIds */
export const CreateContactListDetails = (getSource: SourceGetter) => {
    return async (contactListId: int, contactIds: int[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
            url: `${CONTACT_LIST_DETAILS_URL}`,
			data: {
				contactListId,
				contactIds,
			},
            cancelToken: getSource().token,
		});
    };
};

/* Update a ContactListDetail */
export const UpdateContactListDetail = (getSource: SourceGetter) => {
    return async (contactListDetailId: int, contactType: string, correspondenceType: int): Promise<vClientLocationContactListDetail> => {
        return axiosData({
			method: 'put',
            url: `${CONTACT_LIST_DETAILS_URL}`,
			data: {
				id: contactListDetailId,
				contactType: contactType,
				correspondenceType: correspondenceType
			},
            cancelToken: getSource().token,
		});
    };
};

/* Delete a contact list detail */
export const DeleteContactListDetail = (getSource: SourceGetter) => {
    return async (contactListDetailId: int): Promise<void> => {
        return axiosData({
			method: 'delete',
            url: `${CONTACT_LIST_DETAILS_URL}/${contactListDetailId.toString()}`,
            cancelToken: getSource().token,
		});
    };
};
