import { UserRole } from "@imas/types";

// export interface MenuOption {
//     name: string;
//     path: string;
//     requiredRole: UserRole;
//     mobileSafe?: boolean;
// 	baseUrl?: true;
//     icon?: React.ReactNode;
// };


export class MenuOption {
	public name: string;
	public url: string;
	public requiredRole: UserRole;
	public options: MenuOption[] | null;
	public mobileSafe: boolean;
	public icon?: React.ReactNode;
	public mobileOnly: boolean

	//parrent of this menu option, will be null for top level menu options
	public parrent: MenuOption | null = null;

	constructor(params: { name: string, url: string, requiredRole: UserRole, options?: MenuOption[], mobileSafe?: true, baseUrl?: true, icon?: React.ReactNode, mobileOnly?: boolean }) {
		this.name = params.name;
		this.url = params.url;
		this.requiredRole = params.requiredRole;
		this.options = params.options ?? null;

		//set this menu option as the parrent for all sub options
		if (this.options) {
			for (const option of this.options) {
				option.parrent = this;
			}
		}

		this.mobileSafe = params?.mobileSafe ?? false;
		this.mobileOnly = params.mobileOnly ?? false;
		this.icon = params?.icon;
	}

	//get the page URL for a specified option (recursive)
	// public getPageURL(): string {
	// 	return this.parrent ? `${this.parrent.getPageURL()}/${this.path}` : `/${this.path}`
	// }
};

export interface MenuCategory {
    name: string;
	url: string;
    options: MenuOption[];
};

export type Menu = MenuCategory[]; 

