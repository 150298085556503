import { openPopup, OpenPopupOptions } from '@imas/utils/misc';
import { getEditorURL, getEditorURLOptions } from './getEditorURL';
import { EditorItem, EditorItemFields } from "./types";

export interface OpenEditorWindowOptions extends getEditorURLOptions, OpenPopupOptions {};

export function openEditorWindow<T extends EditorItemFields = {}>(url: string, item: EditorItem<T> | { id: int | null; }, options?: OpenEditorWindowOptions): Window | null {	
	//separate getEditorURLOptions from OpenEditorWindowOptions
	const { name, height, width, top, left, ...getUrlOptions } = options ?? {};
	
	//get url
	const editUrl = getEditorURL<T>(url, item as EditorItem<T>, getUrlOptions);
	if (editUrl === null) throw new Error("Invalid params.");

	//open new window popup
	return openPopup(editUrl, { name, height, width, top, left });
};