import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext,useMuiWatch, FormCheckbox } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo } from 'react';

export const WorkPlaceReviewLockOut = memo(() => {
    const { control, isLoading, setValue } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//get the value of lockoutNone, lockoutInspector, and lockoutClient
	const [lockoutNone, lockoutInspector, lockoutClient] = useMuiWatch({ control, name: ["lockoutNone", "lockoutInspector", "lockoutClient"] });
	
	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"LOCK OUT / TAG OUT: "}</b>
						{!lockoutNone ? "The following lock out or tag out conditions are required at this work area:" : <>{"Lock out or tag out conditions are required at this work area. "}<i>{"Do not forget to remove your locks at the end of your shift."}</i></>}
					</Typography>
				}
				control={<Checkbox disabled={isLoading || !lockoutNone} checked={!lockoutNone} onChange={() => setValue("lockoutNone", false)}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={!lockoutNone} sx={{ marginLeft: '45px' }}>
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"lockoutNone"}
						label={"None"}
						disabled={lockoutInspector || lockoutClient}
					/>

					<FormCheckbox
						control={control}
						name={"lockoutInspector"}
						label={"Lock(s) provided by PNL. Locks can only be removed by PNL personnel."}
						disabled={lockoutNone || lockoutClient}
					/>

					<FormCheckbox
						control={control}
						name={"lockoutClient"}
						label={"Lock(s)/Tag(s) provided by the client."}
						disabled={lockoutNone || lockoutInspector}
					/>
				</Alignment>
			</Collapse>
		</Alignment>
	);
});