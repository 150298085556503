import { lighten, alpha } from '@mui/material/styles';
import { makeStyles } from '@imas/styles';

export const employeeAlertsViewerStyles = makeStyles()((theme) => ({
    viewerContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
    },
	alertItem: {
		borderTop: theme.palette.mode === 'light' ? "solid rgba(0, 0, 0, 0.15) 1px" : "solid rgba(255, 255, 255, 0.12) 1px",
        paddingTop: '0',
        paddingBottom: '0',
        "&.Mui-selected": {
            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.75) : alpha(theme.palette.success.main, 0.1),
            "&:hover": {
                backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.7) : alpha(theme.palette.success.main, 0.15),
            },
        },
    },
    violationItem: {
        borderTop: theme.palette.mode === 'light' ? "solid rgba(0, 0, 0, 0.15) 1px" : "solid rgba(255, 255, 255, 0.12) 1px",
        paddingTop: '0',
        paddingBottom: '0',
        cursor: 'pointer', // Add pointer cursor for hover effect
        "&:hover": {
            backgroundColor: theme.palette.mode === 'light' 
                ? lighten(theme.palette.primary.main, 0.8) 
                : alpha(theme.palette.primary.main, 0.2), // Slightly lightened background color on hover
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.mode === 'light' 
                ? lighten(theme.palette.primary.main, 0.75) 
                : alpha(theme.palette.primary.main, 0.1),
            "&:hover": {
                backgroundColor: theme.palette.mode === 'light' 
                    ? lighten(theme.palette.primary.main, 0.7) 
                    : alpha(theme.palette.primary.main, 0.15), // Hover effect on selected items
            },
        },
    },
    
    listItem: {
        paddingTop: '0',
        paddingBottom: '0',
        "&.Mui-selected": {
            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.75) : alpha(theme.palette.success.main, 0.1),
            "&:hover": {
                backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.7) : alpha(theme.palette.success.main, 0.15),
            },
        },
    },
}));