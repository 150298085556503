import React from 'react';
import { useMuiFormContext } from './utils';

export interface FormLoadingProps {
	loading?: boolean;
    loader: React.ReactNode;
    children: React.ReactNode;
};

//utility component for rendering a different component while the form is loading
export const FormLoading: React.FC<FormLoadingProps> = ({ loading, loader, children }): JSX.Element => {
    const { isLoading } = useMuiFormContext<{}>();

    //if the form is currently loading show the loading component
    if (isLoading || loading) return <>{loader}</>;
    else return <>{children}</>;
};