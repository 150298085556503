import React from 'react';
import { XSkeleton } from '@imas/components/layout';
import { FormLoading } from "./FormLoading";
import { SxProps, Theme, Tooltip, TooltipProps } from '@mui/material';
import { HelpOutlineRounded } from '@mui/icons-material';

//FormTooltipProps
export interface FormTooltipProps extends Pick<TooltipProps, "title" | "placement" | "arrow"> {
	icon?: typeof HelpOutlineRounded;
	sx?: SxProps<Theme>;
};

//Form Tooltip Component
export const FormTooltip = React.memo((props: FormTooltipProps) => {
	const { sx, icon, ...tooltipProps } = props;

	//tooltip icon
	const TooltipIcon = icon ?? HelpOutlineRounded;

	return (
		<FormLoading loader={<XSkeleton variant={"circular"} sx={{ minHeight: '20px', minWidth: '20px', margin: '5px', ...sx }}/>}>     
			<Tooltip {...tooltipProps}>
				<TooltipIcon
					sx={sx}
				/>
			</Tooltip>
		</FormLoading>
	);
});