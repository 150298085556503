import { Typography } from '@mui/material';
import { ClientNotesForm } from '@imas/components/forms';
import { Alignment } from '@imas/components/layout';
import { Client } from '@imas/api/client';
import { FormPaper } from '@imas/mui-form';
import { memo } from 'react';

interface ClientNotesProps {
	client?: Client | null;
};

export const ClientNotes = memo(({ client }: ClientNotesProps) => {
	return (
		<FormPaper sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
			<Typography variant={"h6"} sx={{ marginLeft: '10px', marginTop: '3px', fontWeight: 'bold' }}>{"S.O. Notes"}</Typography>
		
			{client === null ? (
				<Typography variant={"body1"} sx={{ marginLeft: '10px' }}>{"Unavailable until client is saved."}</Typography>
			) : client ? (
				<Alignment column flex>
					<ClientNotesForm clientId={client.id}/>
				</Alignment>
			) : null}
		</FormPaper>
	);
});