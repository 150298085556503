import { Alignment, Spacer } from '@imas/components/layout';
import {FormTextField, useMuiFormContext } from '@imas/mui-form';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspectionStyles } from '../../VehicleInspectionStyles';
import { VehicleInspectionRow } from './VehicleInspectionRow/VehicleInspectionRow';
import { VehicleInspectionHeader } from './VehicleInspectionRow/VehicleInspectionHeader';


export const VehicleInspectionFormMechanical = memo(() => {

	const { classes } = VehicleInspectionStyles();

	const { control } = useMuiFormContext<TVehicleInspectionForm>();

	return(
		
		<Alignment column>

			<Spacer vertical/>

			<Alignment row>
				{/* Mechanical Title */}
				<Typography className={classes.title} align={'center'} fontWeight={"bold"} variant={"h5"}>{"Mechanical"}</Typography>

			</Alignment>

			<VehicleInspectionHeader/>

			<VehicleInspectionRow label={'Parking Brake*:'} variable={'parkingBrake'} />
			<VehicleInspectionRow label={'Engine Operation:'} variable={'engine'} />
			<VehicleInspectionRow label={'Transmission & Clutch:'} variable={'transmission'} />
			<VehicleInspectionRow label={'Shocks, Struts, & Steering:'} variable={'suspension'} />
			<VehicleInspectionRow label={'Exhaust:'} variable={'exhaust'} />
			<VehicleInspectionRow label={'Fuel Lines, Tank, Pump, & Cap:'} variable={'fuel'} />
			<VehicleInspectionRow label={'Electrical & Wiring:'} variable={'electrical'} />
			<VehicleInspectionRow label={'Air Conditioner Operation:'} variable={'airConditioner'} />
			<VehicleInspectionRow label={'Heater Operation:'} variable={'heater'} />
			<VehicleInspectionRow label={'Battery, Starter, & Cables:'} variable={'battery'} />

			{/* Notes Field */}
			<FormTextField
				control={control}
				name={'mechanicalNotes'}
				label={'Mechanical Notes'}
				fullWidth
				inputProps={{maxLength: 255}}
				multiline
				minRows={2}
			/>

		</Alignment>

	);
});
