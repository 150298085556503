import { mapApi, ApiConfig } from '@imas/api';
import { TimeSheetCertificationHour, TimeSheetCertificationHourForm } from './types';

const TIME_SHEET_CERTIFICATION_HOURS_BASE_URL = "/api/time-sheets/certification-hours";

/*  */
export const GetTimeSheetCertificationHour = mapApi((certificationHourId: int): ApiConfig<TimeSheetCertificationHour> => ({
	method: 'get',
	url: `${TIME_SHEET_CERTIFICATION_HOURS_BASE_URL}/${certificationHourId}`,
}));

/*  */
export const CreateTimeSheetCertificationHour = mapApi((data: TimeSheetCertificationHourForm): ApiConfig<TimeSheetCertificationHour> => ({
	method: 'post',
	url: `${TIME_SHEET_CERTIFICATION_HOURS_BASE_URL}`,
	data,
}));

/*  */
export const UpdateTimeSheetCertificationHour = mapApi((certificationHourId: int, data: TimeSheetCertificationHourForm): ApiConfig<TimeSheetCertificationHour> => ({
	method: 'put',
	url: `${TIME_SHEET_CERTIFICATION_HOURS_BASE_URL}/${certificationHourId}`,
	data,
}));

/*  */
export const DeleteTimeSheetCertificationHour = mapApi((certificationHourId: int): ApiConfig<TimeSheetCertificationHour> => ({
	method: 'delete',
	url: `${TIME_SHEET_CERTIFICATION_HOURS_BASE_URL}/${certificationHourId}`,
}));