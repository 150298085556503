import React from 'react';
import { Skeleton, Theme, TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';

export interface FormFieldSkeletonProps {
    sx?: SxProps<Theme>;
    style?: React.CSSProperties;
	size?: TextFieldProps["size"];
    className?: string;
    multiline?: boolean;
    rows?: number | string;
    minRows?: number | string;
};

const rowsToPx = (rows: FormFieldSkeletonProps["minRows"]) => {
	return `${(Number(rows) * 23) + 12}px`;
};

const getHeight = (size: FormFieldSkeletonProps["size"], multiline: FormFieldSkeletonProps["multiline"], rows: FormFieldSkeletonProps["rows"]) => {
	if (multiline) {
		if (rows) return rowsToPx(rows);
		else return '-webkit-fill-available';
	}

	if (size === "x-small") return '30px';
	return '40px';
};

export const FormFieldSkeleton = ({ sx, style, size, className, multiline, rows, minRows }: FormFieldSkeletonProps) => {
    return (
        <Skeleton
            variant={"rectangular"} 
            sx={{
                height: getHeight(size ?? "x-small", multiline, rows),
                minHeight: minRows && multiline ? rowsToPx(rows) : undefined,
                borderRadius: '4px',
                width: '100%',
                ...sx,
            }}
            style={style}
            className={className}
        />
    );
};