import { TimeSheetCertificationHoursForm } from '@imas/components/forms';
import { Alignment } from '@imas/components/layout';
import { TypedGridRenderCellParams } from '@imas/data-grid';
import { Badge, BadgeProps, Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { isValidElement, useContext } from 'react';
import { TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_PROPS, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TIME_SHEET_DETAILS_EDIT_GRID_API_REF, TimeSheetDetailDM, TimeSheetDailyNotes } from './types';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { gridDetailPanelExpandedRowsContentCacheSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { TIME_SHEET_DETAIL_EDITOR_PANEL_CONTENT_HEIGHT, getWeekdayDate, useCertHourColWidths, useCertHourMargin, weekdayFullNameMap, weekdayList } from './utils';
import { makeStyles } from '@imas/styles';
import { TimeSheetDetailsFormContext } from './TimeSheetDetailsFormContext';

type Init<T extends any[]> = T extends [infer INIT, ...any] ? INIT : never;
type TimeSheetDetailEdtiorPanelContentProps = Init<Parameters<NonNullable<TIME_SHEET_DETAILS_EDIT_GRID_PROPS["getDetailPanelContent"]>>> & {
	isEditable: boolean;
	api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF;
};


// const useRowEditData = (id: TGDM_Id<TimeSheetDetail>, api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF) => {
// 	const get = useCallback((field: TimeSheetWeekdays) => api.current.getColumn(field).computedWidth, [api]);

// 	const [editModel, setEditModel] = useState(api.current.getEditRowsModel()[id]);

// 	useEffect(() => {
// 		return api.current.subscribeEvent('editRowsModelChange', (model) => {
// 			setEditModel((model as TypedGridEditRowsModel<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, "row">)[id]);
// 		});
// 	}, [id, api, setEditModel]);

// 	return editModel;
// };

const useTimeSheetDetailEditorPanelContentStyles = makeStyles()((theme) => ({
	container: {
		height: `${TIME_SHEET_DETAIL_EDITOR_PANEL_CONTENT_HEIGHT}px`,
		borderBottom: `1px solid ${theme.palette.grid.border}`,
	},
}));

export const TimeSheetDetailEditorPanelContent = ({ isEditable, row, api }: TimeSheetDetailEdtiorPanelContentProps) => {
	const { classes } = useTimeSheetDetailEditorPanelContentStyles();
	const marginLeft = useCertHourMargin(api, -80);
	const hourColWidths = useCertHourColWidths(api);

	return(
		<Alignment column className={classes.container}>
			{row.serviceOrderId ? (
				<Alignment 
					column 
					overflowHidden 
					style={{ marginLeft: `${marginLeft}px` }} 
					sx={{ marginBottom: '20px', height: `${TIME_SHEET_DETAIL_EDITOR_PANEL_CONTENT_HEIGHT}px` }}
				>
					<TimeSheetCertificationHoursForm disabled={!isEditable} timeSheetDetailId={row.id} hourColWidths={hourColWidths}/>
				</Alignment>
			) : null}
		</Alignment>
	);
};

export const TimeSheetDetailEditorPanelToggle = (props: TypedGridRenderCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, any, 'row'>) => {
	const { id, value: isExpanded, row } = props;
	const apiRef = useGridApiContext();
	const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
	const hasDetail = isValidElement(contentCache[id]);

	return (
		<Tooltip title={isExpanded ? 'Close Method Hours' : 'Show Method Hours'} placement={'right'} arrow>
			<IconButton
				size={'small'}
				tabIndex={-1}
				disabled={!hasDetail || !row.serviceOrderId}
				aria-label={isExpanded ? 'Close' : 'Open'}
			>
				<ExpandMoreIcon
					sx={{
						transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
						transition: (theme) => theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
					}}
					fontSize={'inherit'}
				/>
			</IconButton>
		</Tooltip>
	);
};

export const NotesBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
	  right: 2,
	  top: 3,
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '0 2px',
	  minWidth: '16px',
	  height: '16px',
	},
  }));

export const NotesTooltip = ({ notes }: { notes: TimeSheetDailyNotes; }) => {
	const { weekOf } = useContext(TimeSheetDetailsFormContext);

	return (
		<Box>
			{weekdayList.map(day => ({ title: `${weekdayFullNameMap[day]} - ${weekOf ? getWeekdayDate(weekOf, day).format('M/D') : '--/--'}`, notes: notes[day] })).filter(x => !!x.notes).map(({ title, notes }) => (
				<>
					<Typography variant='h6' sx={{ fontWeight: 'bold' }}>{title}</Typography>
					<Typography variant='body1'>{notes}</Typography>
				</>
			))}
		</Box>
	);
};