import { useEffect, useCallback } from "react";
import { WrappedApiFunction, ApiFunctionResult, ApiFunctionResultCallback, ApiFunctionResultType } from "./types";
import { useCancelableApi } from "./useApi";

//useEffectApi options object
interface UseEffectApiOptions {
    disabled?: boolean;
    onError?: (error: any) => void;
};

/**
 * useEffectApi
 * 
 * @param wrappedApiFunction 
 * @param apiFunctionParams 
 * @param apiCallback 
 * @param deps 
 * @param options 
 */
export const useEffectApi = <A extends any[], R extends ApiFunctionResult<any>>(wrappedApiFunction: WrappedApiFunction<A, R>, apiFunctionParams: A, apiCallback: ApiFunctionResultCallback<R>, deps: React.DependencyList, options?: UseEffectApiOptions) => {
    //use api
    const { api, cancel } = useCancelableApi(wrappedApiFunction);

    //handle an error
    const handleError = useCallback((e: any) => {
        //call error handler if any was provided
        if (options?.onError) options.onError(e);
    }, [options?.onError]);

    //useEffect for calling the API based on deps change
    useEffect(() => {
        //cancel any ongoing requests
        cancel();
        
        //if disabled return and don't execute the api
        if (options?.disabled) return;

        //call the api
        const result = api(...apiFunctionParams);
        
        //check if the result is a CacheResult
        if ('api' in result && 'cache' in result) {

            //get cache then api result
            (async () => {
                try { apiCallback(await result.cache); } catch (e) { if (e) handleError(e); };
                try { apiCallback(await result.api); } catch (e) { if (e) handleError(e); };
            })().catch((e: any) => { if (e) handleError(e); });
        } else {
            //if it is not it is a normal promise, deal with that here.
            result.then((x: ApiFunctionResultType<R>) => apiCallback(x)).catch(e => handleError(e)); 
        }
    }, deps);
};