import { getDecimalColDef } from '@imas/data-grid';
import { HourCell } from './CellsAndHeaders';
import { HourEditCell } from './EditCells';
import { TIME_SHEET_DETAILS_COL_WIDTHS, HOUR_CELL_DECIMAL_OPTIONS } from './utils';

export const HOUR_COL_DEF = {
	...getDecimalColDef(HOUR_CELL_DECIMAL_OPTIONS),
	disableColumnMenu: true,
	disableReorder: true,
	sortable: false,
	resizable: false,
	width: TIME_SHEET_DETAILS_COL_WIDTHS.HOURS,
	headerName: '',
	renderCell: (params: any) => <HourCell {...params}/>,
	renderEditCell: (params: any) => <HourEditCell {...params}/>,
};