import { DialogContext } from './DialogContext';
import React, { useRef, useContext, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type UseDialogCloseCallback<T = void> = (value: T) => void
export type UseDialogCloseCallbackGenerator<T = void> = (key: UUID) => UseDialogCloseCallback<T>;

export type DialogRenderer<T = void> = (close: UseDialogCloseCallback<T>) => React.ReactNode;

export type UseDialogOpenFn = <T = void>(dialog: DialogRenderer<T>) => Promise<T>;

type UseDialogOpenDialogRecord = { 
	key: UUID; 
	resolver: (value?: any) => void;
};

export function useDialog(): [UseDialogOpenFn] {
	//open dialogs ref
	const openDialogsRef = useRef<UseDialogOpenDialogRecord[]>([]);

	//use DialogContext
	const { open, close } = useContext(DialogContext);

	//when the component this hook is in unmounts close the dialog it has open
	useEffect(() => {
		return () => {
			for (const dialog of openDialogsRef.current) {
				//close dialog
				close(dialog.key);
			}
		};
	}, []);

	//closeDialog callback which closes the dialog given the current dialogKeyRef
	const createCloseDialog = useCallback<UseDialogCloseCallbackGenerator<any>>((key: UUID) => (value: any) => {
		//get the OpenDialogRecord given the key
		const record = openDialogsRef.current.find(x => x.key === key);

		if (record) {
			//close the dialog
			close(record.key);

			//resolve the dialog promise
			record.resolver(value);

			//remove the OpenDialogRecord from the openDialogsRef
			openDialogsRef.current = openDialogsRef.current.filter(x => x.key !== key);
		} else {
			console.error("Cannot close a dialog which has been already closed, please ensure the 'close' function from the dialog generator function is only called once.");
			return;
		}
	}, [close]);
	
	//open dialog callback
	const openDialog = useCallback(<T = void>(generator: DialogRenderer<T>): Promise<T> => {
		//generate a UUID key for the new dialog
		const key = uuidv4();

		//call the generator to get the react node to be shown
		const dialog = generator(createCloseDialog(key));
		
		//return a promise which resolves when the dialog is no longer open
		return new Promise<T>(resolve => {
			//add new dialog to the list of open dialogs
			openDialogsRef.current.push({	
				//call open with the new dialog
				key: open(dialog, { key }),
				//save this promise's resolver
				resolver: resolve,
			});
		});
	}, [open, createCloseDialog]);

	return [openDialog];
};