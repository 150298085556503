import axios from "axios";
import { SourceGetter } from "@imas/api";
import { WeldTestCategory } from './types';

//weld/sample test categories base url
const WELD_TEST_CATEGORIES_URL = "/api/system/test-categories";

/**  */
export const GetWeldTestCategories = (getSource: SourceGetter) => {
    return async (): Promise<WeldTestCategory[]> => {
        //make API call
        const result = await axios({
            method: 'get',
            url: `${WELD_TEST_CATEGORIES_URL}`,
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};

/**  */
/* export const GetWeldTestCategory = (getSource: SourceGetter) => {
    return async (categoryId: int): Promise<WeldTestCategory | null> => {
        //make API call
        const result = await axios({
            method: 'get',
            url: `${WELD_TEST_CATEGORIES_URL}/${categoryId}`,
            cancelToken: getSource().token,
        });
        
        //if the status is 204 return null, otherwise return the result
        if (result.status === 204) return null;
        return result.data;
    };
}; */

export const CreateWeldTestCategory = (getSource: SourceGetter) => {
    return async (level: int, category: string): Promise<WeldTestCategory> => {
        //make API call
        const result = await axios({
            method: 'post',
            url: `${WELD_TEST_CATEGORIES_URL}/${level}`,
            data: {
                item: category
            },
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};

export const UpdateWeldTestCategory = (getSource: SourceGetter) => {
    return async (testCategoryId: int, category: string): Promise<WeldTestCategory> => {
        //make API call
        const result = await axios({
            method: 'put',
            url: `${WELD_TEST_CATEGORIES_URL}/${testCategoryId}`,
            data: {
                item: category
            },
            cancelToken: getSource().token,
        });
        
        //return result
        return result.data;
    };
};