import { OnCallScheduleResult, ScheduleEventEntry } from 'src/api/types/api/schedule/schedule';
import moment, { Moment } from 'moment';
import { EmployeeWorkEvent } from 'src/api/types/api/employee/employee';
import { createContext } from 'react';

interface RefreshEventsCallback { (): void }
interface GetHoursCallback { (entry: ScheduleEventEntry | null): number }

export const DailyScheduleViewerContext = createContext<{
	onCallSchedule?: OnCallScheduleResult[],
    lastEvent?: EmployeeWorkEvent | null,
    checkInHistory: EmployeeWorkEvent[],
    selectedDay: Moment,
    totalHoursWorked: number,
    refreshEvents: RefreshEventsCallback,
    getHours: GetHoursCallback
}>({
	onCallSchedule: undefined,
    lastEvent: undefined,
    checkInHistory: [],
    selectedDay: moment(),
    totalHoursWorked: 0,
    refreshEvents: () => null,
    getHours: (_) => 0
});