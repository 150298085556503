import { makeStyles } from '@imas/styles';

const idleTimeoutStyles = makeStyles()((theme) => ({
    timeoutTitle: {
        fontWeight: 600,
    },
    timeoutText: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        padding: '20px',
        textAlign: 'center'
    }
}));

export default idleTimeoutStyles;