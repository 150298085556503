import { useEffect, useRef } from 'react';
import { InputAdornment } from '@mui/material';
import { getGridNumericOperators } from "@mui/x-data-grid-pro";
import { TGDM, TGEM, TGRM, TGRM_Key, TypedGridColTypeDef, TypedGridRenderEditCellParams } from '../typed-grid';
import { formatUSD } from '@imas/utils/formatting';
import { NumberInputEditCell } from './INTEGER_COL';
import { EditCellContainer } from './utils';

/* export type USDEditCellProps<T extends boolean | undefined> = T extends true ? {
	required: T;
} & TypedGridRenderEditCellParams<{ id: string | number; value: number; }, { value: number; }, never, "value", "cell"> : {
	required?: T;
} & TypedGridRenderEditCellParams<{ id: string | number; value: number | null; }, { value: number | null; }, never, "value", "cell">;

export const USDEditCell = <T extends boolean | undefined>(props: USDEditCellProps<T>) => {
	//spread props
	const { id, value, field, api, hasFocus, required } = props;

    //input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={required && typeof value !== "number"}>
			<NumberInputEditCell
				value={value === undefined || value === null ? null : value}
				onValueChange={({ floatValue }) => {
					//if field is not required then floatValue should null instead of undefined
					const value = required ? floatValue : floatValue ?? null;
					api.setEditCellValue({ id, field, value } as any);
				}}
				thousandSeparator={","}
				prefix={"$"}
				allowNegative={false}
				decimalScale={2}
				fixedDecimalScale
				fullWidth
				ref={ref}
			/>
		</EditCellContainer>
    );
}; */

/* export type USDEditCellProps<T extends boolean | undefined> = T extends true ? {
	required: T;
} & TypedGridRenderEditCellParams<{ id: string | number; value: number; }, { value: number; }, never, "value", "cell"> : {
	required?: T;
} & TypedGridRenderEditCellParams<{ id: string | number; value: number | null; }, { value: number | null; }, never, "value", "cell">; */

/* export type USDEditCellProps<
	DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> */

export const USDEditCell = (props: any) => {
	//spread props
	const { id, value, field, api, hasFocus, required } = props;

    //input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={required && typeof value !== "number"}>
			<NumberInputEditCell
				value={value === undefined || value === null ? null : value}
				onValueChange={({ floatValue }) => {
					//if field is not required then floatValue should null instead of undefined
					//const value = required ? floatValue : floatValue ?? null;
					api.setEditCellValue({ id, field, value: floatValue } as any);
				}}
				thousandSeparator={","}
				prefix={"$"}
				allowNegative={false}
				decimalScale={2}
				fixedDecimalScale
				fullWidth
				ref={ref}
			/>
		</EditCellContainer>
    );
};

//USD filter operators
const filterOperators = getGridNumericOperators()
	.map(operator => {
		if (operator.value === '>' || operator.value === '<' || operator.value === '>=' || operator.value === '<=') return {
			...operator,
			InputComponentProps: {
				...operator.InputComponentProps,
				InputProps: {
					...operator.InputComponentProps?.InputProps,
					startAdornment: <InputAdornment position={"start"}>{"$"}</InputAdornment>,
				},
			},
		};

		return operator;
	}) as any;

//USD valueFormatter
const valueFormatter = ({ value }: { value: any }): string => value === null ? "" : formatUSD(value);

export const USD_COL: TypedGridColTypeDef<number>  = {
    type: 'number',
	valueFormatter,
    renderEditCell: (props) => <USDEditCell required {...props}/>,
    filterOperators,
};

export const NULLABLE_USD_COL: TypedGridColTypeDef<number | null>  = {
    type: 'number',
	valueFormatter,
    renderEditCell: (props) => <USDEditCell {...props}/>,
    filterOperators,
};

//v6 col def
export const USD_COL_V6 = {
	type: 'number',
	valueFormatter,
	renderEditCell: (props: any) => <USDEditCell {...props} />
};