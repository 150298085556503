import { SourceGetter } from '@imas/api';
import { ServiceOrderReportRevision, SOReportVersionResult, VServiceOrderReportRevision } from 'src/api/types/api/service-orders/types';
import axios from 'axios';

/**
 *  Get a list of Report Revisions for the report
 */
export const _GetRevisions = (getSource: SourceGetter) => {
    return (uniqueId: number, reportId: number): Promise<ServiceOrderReportRevision[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderReportRevision[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/' + reportId.toString() + '/revisions',
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

export const _GetVRevisions = (getSource: SourceGetter) => {
    return (uniqueId: number, reportId: number): Promise<VServiceOrderReportRevision[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<VServiceOrderReportRevision[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/' + reportId.toString() + '/v-revisions',
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Delete the current report revision entry if it exists for a service order report
 */
export const _DeleteLastRevision = (getSource: SourceGetter) => {
    return (uniqueId: number, reportId: number): Promise<SOReportVersionResult> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<SOReportVersionResult>((resolve, reject) => {
            axios({
                method: 'delete',
                url: '/api/service-order/' + uniqueId.toString() + '/reports/' + reportId.toString() + '/last-version',
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};