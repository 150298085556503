import { Editable, OnFormFieldBlur, RegisterOnBlurCallback, RegisterOnResetCallback } from './types';
import { FieldValues, UseFormTrigger, UseFormSetValue, UseFormGetValues, Control, UseFormReset, UseFormSetError } from 'react-hook-form';
import React from "react";

//FormContext type
export interface TFormContext<TFieldValues extends FieldValues> {
    isLoading: boolean;
    isViewOnly: boolean;
    control: Control<TFieldValues>;
	size: "small" | "x-small";
    trigger: UseFormTrigger<TFieldValues>;
    setError: UseFormSetError<TFieldValues>;
    setValue: UseFormSetValue<TFieldValues>;
    getValues: UseFormGetValues<TFieldValues>;
    reset: UseFormReset<TFieldValues>;
	onFieldBlur: OnFormFieldBlur<TFieldValues>;

	//callback register funcs
	registerOnResetCallback: RegisterOnResetCallback<TFieldValues>;
	//registerOnChangeCallback: RegisterOnResetCallback<TFieldValues>;
	registerOnBlurCallback: RegisterOnBlurCallback<TFieldValues>;
};

//EditableFormContext type
export type TEditableFormContext<TFieldValues extends FieldValues> = TFormContext<Editable<TFieldValues>>;

//actual context object
const MuiFormContext = React.createContext<TFormContext<FieldValues>>({
    isLoading: false,
    isViewOnly: false,
    control: {} as Control<{}>,
	size: "x-small",
    trigger: async (name, options) => (false),
    setError: (name, error, config) => {},
    setValue: (name, value, options) => {},
    getValues: (() => {}) as UseFormGetValues<{}>,
    reset: (values, keepStateOptions) => {},
	onFieldBlur: () => {},

	registerOnResetCallback: () => { return () => {}; },
	registerOnBlurCallback: () => { return () => {}; },
});

export default MuiFormContext;