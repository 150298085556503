import { useHub, useOnHub } from '@imas/api';
import { useEffect, useState } from 'react';
import { ResourceCollaborator } from "./types";

export interface useResourceResult {
    collaborators: ResourceCollaborator[];
};

/** Takes the Name/ID of a resource and will register with the Resources Hub so that users who are using the same resource can see eachother. */
export function useResource(resource: string): useResourceResult {
    //collaborators state
    const [collaborators, setCollaborator] = useState<ResourceCollaborator[]>([]);

    //use the resource hub (dont auto connect)
    const resourcesHub = useHub("/resources");

    //join the specified resource on mount & whenever it changes 
    useEffect(() => {
        //check if there is an active connection, if not set the onConnect callback instead of calling invoke directly
        if (!resourcesHub.isConnected) {
            resourcesHub.onConnect = async () => {
                resourcesHub.invoke("JoinResource", resource);
            };
        } else {
            resourcesHub.invoke("JoinResource", resource);
        }  
    }, [resource]);

    //handle collaborator updates
    useOnHub(resourcesHub, "CollaboratorsUpdate", (newCollaborators) => {
        setCollaborator(newCollaborators);
    }, []);

    return {
        collaborators,
    };
};