import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext, useMuiWatch, FormCheckbox, FormTextField } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo } from 'react';
import { useResetSection } from './WorkPlaceReviewFormUtils';

export const WorkPlaceReviewHazardMaterials = memo(() => {
    const { control, isLoading, setValue } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//get the value of matNone and matOther
	const [matNone, matOther] = useMuiWatch({ control, name: ["matNone", "matOther"] });

	//use reset section
	const reset = useResetSection("mat");
	
	return (
		<Alignment column sx={{ marginTop: '10px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"HAZARD MATERIALS: "}</b>
						{!matNone ? "The following hazardous materials have been identified at this work area:" : "Hazardous materials have been identified at this work area."}
					</Typography>
				}
				control={<Checkbox disabled={isLoading || !matNone} checked={!matNone} onChange={() => setValue("matNone", false)}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={!matNone} sx={{ marginLeft: '45px' }}>
				<FormCheckbox
					control={control}
					name={"matNone"}
					label={"None"}
					onBlur={({ currentValue }) => {
						//reset values of the section if None is set to true
						if (currentValue) reset();
					}}
				/>

				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"matDust"}
						label={"Dust, dirt, etc."}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matSilica"}
						label={"Silica, sand, etc."}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matAsbestos"}
						label={"Asbestos"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matSmoke"}
						label={"Smoke"}
						sx={{ marginRight: '20px'}}
					/>
					
					<FormCheckbox
						control={control}
						name={"matCorrosive"}
						label={"Corrosive/Chemical agents"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matInhalant"}
						label={"Inhalants (Paints, solvents, adhesives, pesticies, etc.)"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matFlammableLiquid"}
						label={"Gasoline, oil, alcohol, etc."}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matExplosive"}
						label={"Gas, explosive fumes, etc."}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matReactive"}
						label={"Reactive agents (as listed on SDS)"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matMold"}
						label={"Mold/mildew"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"matRadioactive"}
						label={"Radioactive elements - See form 269 for protective measures"}
						sx={{ marginRight: '20px'}}
					/>
				</Alignment>

				{/* Other & Description */}
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"matOther"}
						label={"Other"}
					/>

					{matOther ? (
						<FormTextField
							required
							control={control}
							name={"matOtherDesc"}
							multiline
							rows={3}
						/>
					) : null}
				</Alignment>
			</Collapse>

			{/* Protective Equipment */}
			<Alignment column sx={{ marginLeft: '45px' }}>
				<Typography variant={"body1"} sx={{ margin: '5px', fontWeight: 'bold' }}>{"Protective Equipment Required:"}</Typography>
				
				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"reqHandArmPPE"}
						label={"Gloves (leather, canvas, metal mesh, rubber, fabric, etc.)"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqEyeFacePPE"}
						label={"Googles, glasses, faceshields, etc."}
						sx={{ marginRight: '20px'}}
					/>
					
					<FormCheckbox
						control={control}
						name={"reqFootLegPPE"}
						label={"Foot/Leg guards (Steel-toe, metatarsals, shin, leggings, etc.)"}
						sx={{ marginRight: '20px'}}
					/>
					
					<FormCheckbox
						control={control}
						name={"reqKneepad"}
						label={"Knee pads"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqBodyPPE"}
						label={"Full body suit"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqMask"}
						label={"Mask"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"reqRespirator"}
						label={"Respirator"}
					/>
					
				</Alignment>
			</Alignment>
		</Alignment>
	);
});