import { ScheduleEventEntry } from 'src/api/types/api/schedule/schedule';
import { EmployeeWorkEvent } from 'src/api/types/api/employee/employee';
import { roundFloat } from '@imas/utils/math';
import moment, { Moment } from 'moment';

//get workId & workTypeId from a ScheduleEventEntry
export const getWorkInfo = (entry: ScheduleEventEntry | null): { workId: int, workTypeId: int } => {
    if (entry === null) {
        return {
            workId: 0,
            workTypeId: 0
        };
    }
    else if (entry.uniqueId) {
        return {
            workId: entry.uniqueId,
            workTypeId: 1
        };
    } else if (entry.dailyAssignmentId) {
        return {
            workId: entry.dailyAssignmentId,
            workTypeId: 2
        };
    } else {
        return {
            workId: -1,
            workTypeId: -1
        };
    }
};

//get eventDuration
export const getCheckInDuration = (startEvent: EmployeeWorkEvent, events: EmployeeWorkEvent[]) => {
    //try to find the closing event for the startEvent 
    const endEvent = events.find(x => x.typeId === 5 && x.parentId === startEvent.id);
    
    if (endEvent === undefined) return 0;

    //return the # of hours between start and end event
    return roundFloat(moment(endEvent.inDateTime).startOf('minute').diff(moment(startEvent.inDateTime).startOf('minute'), "minutes") / 60, 0.25);
};

//get the amount of time worked for a given event (null for general)
export const getWorkedTime = (entry: ScheduleEventEntry | null, history: EmployeeWorkEvent[], date: Moment) => {
    //get workId and workTypeId from the entry 
    const { workId, workTypeId } = getWorkInfo(entry);

    //get all start events, filtered by the entry & date specified
    const startEvents = history.filter(x => x.workId === workId && x.workTypeId === workTypeId).filter(x => moment(x.inDateTime).isSame(date, "day")).filter(x => x.typeId === 2);

    //running total of time for this event
    var totalTime = 0;

    //for each start event get the total time of that event
    for (var startEvent of startEvents) { totalTime += getCheckInDuration(startEvent, history); }

    //return total time
    return totalTime;
};

export const getTotalWorkedTime = (history: EmployeeWorkEvent[], date: Moment) => {
    //total time for all entries + general
    var totalTime = 0;

    //get all start events filtered by date specified
    const startEvents = history.filter(x => moment(x.inDateTime).isSame(date, "day")).filter(x => x.typeId === 2);

    //for each start event get the total time of that event
    for (var startEvent of startEvents) { totalTime += getCheckInDuration(startEvent, history); }

    //return total time
    return totalTime;
};


export const getCheckInTime = (history: EmployeeWorkEvent[], date: Moment): string | null => {
    // Filter events that match the given date and have typeId of 2
    const startEvents = history
        .filter(x => moment(x.inDateTime).isSame(date, "day"))
        .filter(x => x.typeId === 2);

    if (startEvents.length === 0) {
        return null;
    }
    
    const value = startEvents[startEvents.length - 1]?.inDateTime;

    if (!value) {
        return null; // If value is undefined or null, return null
    }

    const datePart = value.substring(0, 10);
    const timePart = value.substring(11, 19);
    
    if (!datePart || !timePart) {
        return null; // If datePart or timePart is empty, return null
    }

    const dateComponents = datePart.split(/[-]+/);

    if (dateComponents.length !== 3) {
        return null; // Ensure that the dateComponents array has exactly 3 parts
    }

    const timeComponents = timePart.split(/[:]+/);

    if (timeComponents.length < 2) {
        return null; // Ensure that the timeComponents array has at least hours and minutes
    }

    // Convert hours to 12-hour format
    let hour = parseInt(timeComponents[0]);
    const minute = timeComponents[1];
    const period = hour >= 12 ? "PM" : "AM";

    if (hour > 12) {
        hour -= 12;
    } else if (hour === 0) {
        hour = 12;
    }

    const formattedTime = `${hour}:${minute} ${period}`;

    // Format the date as MM/DD/YYYY and append the time part in AM/PM format
    return `${dateComponents[1]}/${dateComponents[2]} ${formattedTime}`;
};

