import { mapApi, ApiConfig } from '@imas/api';
import moment, { Moment } from 'moment';
import { Holiday } from './types/Holiday';

export const GetHolidays = mapApi((fromDate?: Moment, toDate?: Moment): ApiConfig<Holiday[]> => ({
	method: 'get',
	url: `/api/holidays`,
	params: {
		fromDate: (fromDate ?? moment()).format(),
		toDate: (toDate ?? moment().add(1, 'week')).format(),
	},
}));