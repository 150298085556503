import { forwardRef } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { InputBase, InputBaseProps } from '@mui/material';

export interface MaskedInputBaseProps extends Omit<InputBaseProps, "inputRef">, Pick<MaskedInputProps, "mask" | "guide" | "placeholderChar" | "keepCharPositions" | "pipe"> {

};

const MaskedInputBase = forwardRef<HTMLInputElement, MaskedInputBaseProps>((props, ref) => {
    //extract masked input props
    const { 
        value, onChange,
        mask, guide, placeholderChar, keepCharPositions, pipe, 
        ...inputBaseProps 
    } = props;

    //combine them
    const maskedInputProps = { mask, guide, placeholderChar, keepCharPositions, pipe };

    return (
        <MaskedInput
            {...maskedInputProps}
            value={value as MaskedInputProps["value"]}
            onChange={onChange}
            render={(textMaskRef, props) => (
                <InputBase
                    {...inputBaseProps}
                    inputRef={node => {
                        if (node === null) return;
                        textMaskRef(node); // Keep this so the component can still function

                        //pass the ref up the chain
                        if (ref) {
                            if (typeof ref === "function") ref(node);
                            else ref.current = node;
                        }
                    }}
                    {...props}
                />
            )}
        />
    );
});

export { MaskedInputBase };