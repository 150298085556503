import React, { useCallback, useMemo, useState, useContext, useEffect, useRef } from "react";
import { 
	FieldErrors, FieldValues, FieldPath, Resolver, ResolverResult, useForm, useController, useFormState, useWatch, 
	UnpackNestedValue, PathValue, UseFormReset, Control, FieldPathValues 
} from "react-hook-form";
import { MuiFormRef } from "./MuiForm";
import MuiFormContext, { TFormContext, TEditableFormContext } from "./MuiFormContext";
import { 
	Editable, EditableControl, UseEditableFormProps, UseEditableFormReturn, SpreadableFormProps, ErrorAdder, 
	FormValidator, OnFormFieldBlur, UseFormControlProps, UseFormControlReturn
} from "./types";

export const getErrorAdder = <T extends FieldValues>(values: UnpackNestedValue<Editable<T>>): [errors: FieldErrors<Editable<T>>, errorAdder: ErrorAdder<T>] => {
    //errors list object
    const errors: FieldErrors<Editable<T>> = {} as FieldErrors<Editable<T>>;

    //addError function which modified the errors list
    const addError: ErrorAdder<T> = (field, type, message) => {
        //@ts-ignore
        errors[field] = { 
            type,
            message,
        };
    };

    return [errors, addError];
};

export const useMuiForm = <TFieldValues extends FieldValues, TContext extends object = object>(props: UseEditableFormProps<TFieldValues, TContext>): UseEditableFormReturn<TFieldValues, TContext>  => {
    //extract validator from props
    const { validator: rawValidator, onReset: rawOnReset, onFieldBlur: onFieldBlurRaw, onSuccessfulSubmit: rawOnSuccessfulSubmit, mode, ...useFormProps } = props;

	//wrap functions passed into a ref and then provide proxy callbacks which call the refs
	const validatorRef = useRef(rawValidator);
	validatorRef.current = rawValidator;
	const validator = useCallback<FormValidator<TFieldValues, TContext>>((...args) => validatorRef.current(...args), []);

	const onResetRef = useRef(rawOnReset);
	onResetRef.current = rawOnReset;
	const onReset = useCallback<() => void>(() => { if (onResetRef.current) onResetRef.current(); }, []);

	const onSuccessfulSubmitRef = useRef(rawOnSuccessfulSubmit);
	onSuccessfulSubmitRef.current = rawOnSuccessfulSubmit;
	const onSuccessfulSubmit = useCallback<() => void>(() => { if (onSuccessfulSubmitRef.current) onSuccessfulSubmitRef.current(); }, []);

	const onFieldBlurRef = useRef(onFieldBlurRaw);
	onFieldBlurRef.current = onFieldBlurRaw;
	const onFieldBlur = useCallback<OnFormFieldBlur<Editable<TFieldValues>>>((...args) => { if(onFieldBlurRef.current) onFieldBlurRef.current(...args); }, []);

    //custom resolver for the useForm hook
    const resolver: Resolver<Editable<TFieldValues>, TContext> = useCallback((formValues, context, options): ResolverResult<Editable<TFieldValues>> => {
        const [errors, addError] = getErrorAdder<TFieldValues>(formValues);
        const values = validator(formValues, addError, context ?? null);

        if (values === null || values === undefined) {
            return {
                values: {},
                errors,
            };
        }
        else if (Object.keys(errors).length > 0) {
            return {
                values: {},
                errors,
            };
        }

        return {
            values,
            errors: errors
        };
    }, [validator]);

    //useForm hook
    const methods = useForm<Editable<TFieldValues>, TContext>({
        mode: mode ?? "all",
        ...useFormProps,
        resolver: resolver
    });

    //useEffect validate the form when the context value changes
    useEffect(() => { methods.trigger().then(() => {}).catch(() => {}); }, [useFormProps.context]);

    //reset method which will also call the onReset method if it was provided
    const reset = useCallback<UseFormReset<Editable<TFieldValues>>>((values, options) => {
        //call callback
        if (onReset) onReset();

        //call reset
        methods.reset(values, options);
    }, [methods.reset, onReset]);

    //spreadable form props memoized
    const muiFormProps: SpreadableFormProps<TFieldValues, TContext> = useMemo(() => ({
        context: useFormProps.context,
        validator,
        control: methods.control as EditableControl<TFieldValues>,
        handleSubmit: methods.handleSubmit,
        trigger: methods.trigger,
        setError: methods.setError,
        setValue: methods.setValue,
        getValues: methods.getValues,
        reset: reset,
		onSuccessfulSubmit,
		onFieldBlur,
    }), [useFormProps.context, validator, methods.control, methods.handleSubmit, methods.trigger, methods.setError, methods.setValue, methods.getValues, reset]);

    return {
        muiFormProps,
        ...methods
    };
};

//handles custom form field logic which is consistent across all forms
export const useMuiFormController = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(props: UseFormControlProps<TFieldValues, TName>): UseFormControlReturn<TFieldValues, TName> => {
    const {
        name, control, shouldUnregister, defaultValue, rules,
        loading, required, noErrorText, dependents, onBlur,
    } = props;

    //useController hook
    const { 
        field: { value, onChange: onFieldChange, onBlur: onFieldBlur, ref },
        fieldState: { error },
    } = useController({ 
        name, control, shouldUnregister, defaultValue,
        rules: { ...rules, required: rules?.required ?? required}
    });

    //get form context
    const context = (useContext(MuiFormContext) as unknown as TFormContext<TFieldValues>);
	const { trigger, registerOnResetCallback } = context;

    //compute if the field is required
    const isRequired = useMemo(() => required || (rules?.required === undefined ? undefined : true) || error?.type === "required", [required, rules?.required, error]);

    //if the field has an error
    const hasError = error !== undefined || (isRequired && value === null);

    //error message
    const helperText = useMemo(() => {
        if (noErrorText) return undefined;
        if (!error) return undefined;
        else if (error.type === "required") return undefined;
        return error.message; 
    }, [error, noErrorText]);

    //wrapped onChange function which handles triggering form validation on dependents
    const onChange = useMemo((): (...event: any[]) => void => {
        //if this form has no dependents then just return the onFieldChange func
        if (dependents === undefined || dependents.length === 0) return  (...event: any[]): void => {
            //call onFieldChange to update the value of the field
            onFieldChange(...event);
        };

        return (...event: any[]): void => {
            //call onFieldChange to update the value of the field
            onFieldChange(...event);

            //trigger dependents to be validated
            trigger(dependents).then(() => {}).catch(() => {});
        };
    }, [onFieldChange, dependents, trigger]);

    //a snapshot of value which is updated whenever handleBlur is called
    const lastBlurValue = useRef<UnpackNestedValue<PathValue<TFieldValues, TName>>>(value);

	//register an onReset callback to update lastBlurValue when the form is reset
	useEffect(() => {
		const unregister = registerOnResetCallback(name, value => { lastBlurValue.current = value; });
		
		//unregister on unmount
		return () => unregister();
	}, [name, registerOnResetCallback]);

	//ref to blur function
	const handleBlurRef = useRef((currentValue?: any) => { onFieldBlur(); });
	handleBlurRef.current = (currentValue?: any) => {
		//call form form blur handler
		context.onFieldBlur(name, {
			lastValue: lastBlurValue.current,
			currentValue: currentValue ?? value,
			setValue: context.setValue,
			getValues: context.getValues,
		});

		//if a onBlur function was provided, call it, if it returns a value update the value of the field
		if (onBlur) {
			//call field specific blur handler
			const result = onBlur({
				lastValue: lastBlurValue.current,
				currentValue: currentValue ?? value,
				setValue: context.setValue,
				getValues: context.getValues,
			});
			
			if (result !== undefined) onChange(result);
			
			//update lastBlurValue
			lastBlurValue.current = result ?? currentValue ?? value;
		} else {
			//update lastBlurValue
			lastBlurValue.current = currentValue ?? value;
		}

		//useController blur
		onFieldBlur();
	};

    //blur handling callback which calls the current blur hanlder stored in handleBlurRef
    const handleBlur = useCallback((currentValue?: any) => handleBlurRef.current(currentValue), []);

    return {
        name, value, onChange, ref,
        isRequired, isLoading: (context.isLoading || (loading ?? false)), isViewOnly: context.isViewOnly, hasError, helperText, handleBlur,
        context
    };
};

/**
 * Util functions for sub components of a custom <Form> element;
 */
export function useMuiFormRef<T extends FieldValues, R extends any = void>(): [formRef: MuiFormRef<T, R> | null, onFormRef: (node: MuiFormRef<T, R> | null) => void, mutableFormRef: React.MutableRefObject<MuiFormRef<T, R> | null>] {
    //form ref state
    const [formRef, setFormRef] = useState<MuiFormRef<T, R> | null>(null);

	 //form mutable ref
	 const mutableFormRef = useRef(formRef);
	 mutableFormRef.current = formRef;

    //form ref setter callback
    const onFormRef = useCallback((node: MuiFormRef<T, R> | null) => {
        setFormRef(node);
    }, [setFormRef]);

    //return formRef and onFormRef 
    return [formRef, onFormRef, mutableFormRef];
};

export const useMuiFormContext = <TFieldValues extends FieldValues>() => {
    //get control from form context
    return (useContext(MuiFormContext) as TEditableFormContext<TFieldValues>);
};

export const useMuiFormControl = <TFieldValues extends FieldValues>() => {
    //get control from form context
    const { control } = (useContext(MuiFormContext) as TEditableFormContext<TFieldValues>);

    return control;
};

export const useMuiFormState = <TFieldValues extends FieldValues>() => {
    //get control from form context
    const { control } = (useContext(MuiFormContext) as TEditableFormContext<TFieldValues>);

    return useFormState({ control });
};

export function useMuiWatch<TFieldValues extends FieldValues, TFieldNames extends FieldPath<TFieldValues>[]>(props: {
    control: Control<TFieldValues, any>;
    name: readonly [...TFieldNames];
    disabled?: boolean;
}): FieldPathValues<TFieldValues, TFieldNames> {
    //subscribe to watch
    const result = useWatch<TFieldValues, TFieldNames>(props);

    return result;
};