import { AxiosRequestConfig } from 'axios';
import { axiosData, axiosFile } from './axiosUtils';
import { objectToFormData } from './objectToFormData';
import { WrappedApiFunction, SourceGetter } from './types';

export interface ModifyConfigProps {
	/** Specify special encoding for the provided data. */
	encodeAs?: 'FormData';
	returnAs?: 'File';
};

export function modifyConfig({ encodeAs }: ModifyConfigProps, originalConfig: AxiosRequestConfig): AxiosRequestConfig {
	let config = originalConfig;

	//if encodeAs is provided then encode the data in the config
	if (encodeAs) {
		//encode the data as a FormData object
		if (encodeAs === "FormData") {
			config = {
				...config,
				data: objectToFormData(config.data),
				headers: { ...config.headers, "Content-Type": "multipart/form-data" },
			};
		}
	}

	return config;
}; 

export interface ApiConfig<T extends any> extends AxiosRequestConfig, ModifyConfigProps {};

/**
 * 	Utility for mapping an API endpoint to an API function, can define the API parameters and response by giving mapApi a function which takes in
 * 	parameters and returns a ApiConfig object with a type provided result type.
 */
export function mapApi<A extends any[], R extends any>(configGen: (...args: A) => ApiConfig<R> | Promise<ApiConfig<R>>): WrappedApiFunction<A, Promise<R>> {
	//return API function
	return (getSource: SourceGetter) => {
		return async (...args: A): Promise<R> => {
			//use configGen provided to get axios request config from
			const result = configGen(...args);

			//if result is a promise resolve it
			const { encodeAs, returnAs, ...config } = result instanceof Promise ? await result : result;

			//modify config based on provided settings
			const modifiedConfig = modifyConfig({ encodeAs, returnAs }, config);
			
			//get response
			switch (returnAs) {
				case 'File':
					return axiosFile({
						...modifiedConfig,
						cancelToken: getSource().token,
					}) as Promise<R>;
				default:
					return axiosData({
						...modifiedConfig,
						cancelToken: getSource().token,
					});
			}
		}; 
	};
};