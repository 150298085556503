export enum UserRole {
    //the none role, meaning no role is required, not even the User role
    None = 0,

    //primary user roles, a user must have one of these
    User =		0b00000000_00000000_00000000_00000000_00000000_00000000_00000000_00000001,
    PowerUser = 0b00000000_00000000_00000000_00000000_00000000_00000000_00000000_00000010,
    SuperUser = 0b00000000_00000000_00000000_00000000_00000000_00000000_00000000_00000100,
    Admin =		0b00000000_00000000_00000000_00000000_00000000_00000000_00000000_00001000,

    //secondary user roles, a user can have any number of these
    Manager =	0b00000000_00000000_00000000_00000000_00000000_00000001_00000000_00000000,
};