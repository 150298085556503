import { Alignment } from '@imas/components/layout';
import { Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { useMuiFormContext, useMuiWatch, FormCheckbox, FormTextField } from '@imas/mui-form';
import { ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm } from '@imas/api/service-order';
import { memo } from 'react';
import { useResetSection } from './WorkPlaceReviewFormUtils';

export const WorkPlaceReviewFallHazards = memo(() => {
    const { control, isLoading, setValue } = useMuiFormContext<TServiceOrderWorkPlaceReviewForm>();

	//get the value of fallNone and fallOther
	const [fallNone, fallOther] = useMuiWatch({ control, name: ["fallNone", "fallOther"] });
	
	//use reset section
	const reset = useResetSection("fall");

	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Section Header */}
			<FormControlLabel
				label={
					<Typography variant={"body1"}>
						<b>{"FALL HAZARDS: "}</b>
						{!fallNone ? "The following slip, trip, and fall hazards have been identified at this work area:" : "Slip, trip, and fall hazards have been identified at this work area."}
					</Typography>
				}
				control={<Checkbox disabled={isLoading || !fallNone} checked={!fallNone} onChange={() => setValue("fallNone", false)}/>}
				sx={{ marginLeft: '0', marginRight: '0', "& .MuiCheckbox-root": { marginRight: '4px', padding: '4px' } }}
			/>

			<Collapse in={!fallNone} sx={{ marginLeft: '45px' }}>
				<FormCheckbox
					control={control}
					name={"fallNone"}
					label={"None"}
					onBlur={({ currentValue }) => {
						//reset values of the section if None is set to true
						if (currentValue) reset();
					}}
				/>

				<Alignment row sx={{ flexWrap: 'wrap' }}>
					<FormCheckbox
						control={control}
						name={"fallStairwell"}
						label={"Stairwells"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallPerimeter"}
						label={"Perimeter Edge"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallRollingScaffold"}
						label={"Rolling Scaffold"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallScaffoldGreen"}
						label={"Scaffold Green Tag"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallScaffoldYellow"}
						label={"Scaffold Yellow Tag"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallScaffoldRed"}
						label={"Scaffold Red Tag"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallRoof"}
						label={"Roof"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallLadder"}
						label={"Ladders"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallElevatorShaft"}
						label={"Elevator Shafts"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallLift"}
						label={"Aerial/Scissor Lifts"}
						sx={{ marginRight: '20px'}}
					/>

					<FormCheckbox
						control={control}
						name={"fallOpenArea"}
						label={"Areas over 10' high with open sides and ends with no gaurdrails and toe gaurds"}
						sx={{ marginRight: '20px'}}
					/>
				</Alignment>

				{/* Other & Description */}
				<Alignment column>
					<FormCheckbox
						control={control}
						name={"fallOther"}
						label={"Other"}
					/>

					{fallOther ? (
						<FormTextField
							required
							control={control}
							name={"fallOtherDesc"}
							multiline
							rows={3}
						/>
					) : null}
				</Alignment>
			</Collapse>
			
			{/* Protective Equipment */}
			<Alignment column sx={{ marginLeft: '45px' }}>
				<Typography variant={"body1"} sx={{ margin: '5px', fontWeight: 'bold' }}>{"Protective Equipment Required:"}</Typography>
				
				<FormCheckbox
					control={control}
					name={"reqFullBodyHarness"}
					label={"Full Body Harness"}
				/>
			</Alignment>
		</Alignment>
	);
});