import { axiosData, axiosNullableData, SourceGetter } from "@imas/api";
import { ProposalServiceBundle, ProposalServiceBundleDetail, vProposalService, BundleParentService } from './types';

//service variants base url
const SERVICE_BUNDLES_URL = "/api/proposals/service-bundles";

//gets a service bundle
export const GetServiceBundle = (getSource: SourceGetter) => {
    return async (serviceId: int, siteNum: int): Promise<ProposalServiceBundle> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_BUNDLES_URL}/${serviceId}/${siteNum}`,
            cancelToken: getSource().token,
        });
    };
};

//creates a service bundle
export const CreateServiceBundle = (getSource: SourceGetter) => {
    return async (serviceId: int, siteNum: int): Promise<ProposalServiceBundle> => {
        return await axiosData({
            method: 'post',
            url: `${SERVICE_BUNDLES_URL}/${serviceId}/${siteNum}`,
            cancelToken: getSource().token,
        });
    };
};

//update a service bundle
export const UpdateServiceBundle = (getSource: SourceGetter) => {
    return async (bundle: ProposalServiceBundle): Promise<void> => {
        return await axiosData({
            method: 'put',
            url: `${SERVICE_BUNDLES_URL}`,
			data: bundle,
            cancelToken: getSource().token,
        });
    };
};

//gets a servicebundledetails for a bundle
export const GetServiceBundleDetails = (getSource: SourceGetter) => {
    return async (bundleId: int): Promise<ProposalServiceBundleDetail[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_BUNDLES_URL}/details/${bundleId}`,
            cancelToken: getSource().token,
        });
    };
};

//update a service bundle detail
export const UpdateServiceBundleDetail = (getSource: SourceGetter) => {
    return async (detail: ProposalServiceBundleDetail): Promise<ProposalServiceBundleDetail> => {
        return await axiosData({
            method: 'put',
            url: `${SERVICE_BUNDLES_URL}/details`,
			data: detail,
            cancelToken: getSource().token,
        });
    };
};

//create a service bundle details
export const CreateServiceBundleDetails = (getSource: SourceGetter) => {
    return async (bundleId: int, vProposalServices: vProposalService[]): Promise<string[]> => {
        return await axiosData({
            method: 'post',
            url: `${SERVICE_BUNDLES_URL}/details`,
			data: {bundleId, vProposalServices},
            cancelToken: getSource().token,
        });
    };
};

//create a service bundle details
export const DeleteServiceBundleDetail = (getSource: SourceGetter) => {
    return async (bundleDetailId: int): Promise<void> => {
        return await axiosData({
            method: 'delete',
            url: `${SERVICE_BUNDLES_URL}/details/${bundleDetailId.toString()}`,
            cancelToken: getSource().token,
        });
    };
};

//gets a servicebundledetails for a bundle
export const GetBundleParentServices = (getSource: SourceGetter) => {
    return async (serviceId: int): Promise<BundleParentService[]> => {
        return await axiosData({
            method: 'get',
            url: `${SERVICE_BUNDLES_URL}/parents/${serviceId}`,
            cancelToken: getSource().token,
        });
    };
};