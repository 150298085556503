import { memo, useMemo, useRef, useCallback, useEffect, useContext } from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { ScheduleEventEntry, ScheduleType } from 'src/api/types/api/schedule/schedule';
import { DailyScheduleEntry } from './DailyScheduleEntry/DailyScheduleEntry';
import { DailyScheduleViewerContext } from './DailyScheduleViewerContext';
import { OnCallSchedule } from './OnCallSchedule/OnCallSchedule';

interface DailyScheduleEntryListData {
	events: (ScheduleEventEntry | null | "on-call")[];
	type: ScheduleType;
    checkedInId?: int;
    onClick: (entry: ScheduleEventEntry) => void;
	containerWidth: int;
	setSize: (index: int, size: int) => void;
};

const DailyScheduleEntryRow = ({ index, data: { events, type, checkedInId, onClick, containerWidth, setSize }, style }: ListChildComponentProps<DailyScheduleEntryListData>) => {
	const data = events[index];

	//root div
	const root = useRef<HTMLDivElement | null>(null);

	//selected date from DailyScheduleViewerContext
	const { onCallSchedule } = useContext(DailyScheduleViewerContext);

	//callback for updating size map
	const updateSize = useCallback(() => {
		const height = root.current?.getBoundingClientRect().height;
		if (height !== undefined) setSize(index, height);
	}, []);

	//update size map when width changes
	useEffect(() => {
		updateSize();
	}, [data, containerWidth, onCallSchedule]);
	
	return (
		<div style={style}>
			<div ref={root}>
				{data === "on-call" ? (
					<OnCallSchedule onResize={updateSize}/>
				) : (
					<DailyScheduleEntry
						entry={data}
						type={type}
						checkedInId={checkedInId}
						onClick={onClick}
					/>
				)}
			</div>
		</div>
	);
};

export interface DailyScheduleEntryListProps {
	width: int;
	height: int;
	events?: ScheduleEventEntry[];
	type: ScheduleType;
    checkedInId?: int;
    onClick: (entry: ScheduleEventEntry) => void;
};

export const DailyScheduleEntryList = memo(({ width, height, events, type, checkedInId, onClick }: DailyScheduleEntryListProps) => {
	//list ref
	const listRef = useRef<VariableSizeList<DailyScheduleEntryListData> | null>(null);

	//list size mapping (used to correctly render item height as needed)
	const sizeMap = useRef<Record<int, int>>({});
	const setSize = useCallback((index: int, size: int) => {
		//update map if value has changes & call resetAfterIndex
		if (sizeMap.current[index] !== size) {
			sizeMap.current[index] = size;
			listRef.current?.resetAfterIndex(index, false);
		}
	}, []);
	const getSize = useCallback((index: int) => {
		return sizeMap.current[index] ?? 145;
	}, []);

	//when events changes clear the size map
	useEffect(() => { 
		//force list to get new heights
		listRef.current?.resetAfterIndex(0);
	}, [events]);
	
	//item data memoized
	const itemData = useMemo((): DailyScheduleEntryListData => ({
		events: events ? ["on-call", ...events] : ["on-call", null, null, null],
		type,
		checkedInId,
		onClick,
		containerWidth: width,
		setSize,
	}), [events, type, checkedInId, onClick, width, setSize]);

	return (
		<VariableSizeList
			width={width}
			height={height}
			itemData={itemData}
			itemCount={itemData.events.length}
			itemSize={getSize}
			ref={listRef}
		>{DailyScheduleEntryRow}</VariableSizeList>
	);
});