import { useEffect, useRef } from "react";
import { HubRegistration, HubRegistrationType } from "./types";

export function useOnHub<
    R extends HubRegistration<any>, 
    H extends HubRegistrationType<R>, 
    M extends Exclude<keyof H["client"], number | symbol>
>(registration: R, method: M, callback: (...args: Parameters<H["client"][M]>) => ReturnType<H["client"][M]>, deps?: React.DependencyList) {
    //ref to the most recent callback method
    const callbackRef = useRef<typeof callback>(callback);

    //update the callbackRef when deps changes
    useEffect(() => { callbackRef.current = callback; }, deps);

    //register a listener for the specified method
    useEffect(() => {
        //register receiver
        registration.connection.on(method, (...args: any[]) => {
            return callbackRef.current(...args as any);
        });
    }, []);
};