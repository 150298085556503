import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { authReducer } from './slices/authSlice';
import { deviceInfoReducer }from './slices/deviceInfoSlice';
import { secretsReducer } from './slices/secretsSlice';
import { appSettingsReducer } from './slices/settingsSlice';

//combined root reducer
const rootReducer = combineReducers({
    auth: authReducer,
    deviceInfo: deviceInfoReducer,
	secrets: secretsReducer,
    appSettings: appSettingsReducer,
});

//get RootState type from the reducer
export type RootState = ReturnType<typeof rootReducer>;

//redux middlewares
const middlewares = [logger];

//main redux store for the application
export const store = createStore(rootReducer, {}, applyMiddleware(...middlewares));
