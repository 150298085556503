import { getDeviceBrowser, getSignificantBrandVersion } from './getDeviceString';
import { DeviceData } from './types';

//get device data as an object - using legacy methods
const getDeviceDataLegacy = (): DeviceData => ({
    model: null,
    platform: navigator?.platform ?? "Unknown",
    browser: getDeviceBrowser(),
});

//get device data as an object
export const getDeviceData = async (): Promise<DeviceData> => {
    //check if userAgentData is available
    if (navigator?.userAgentData === undefined) return getDeviceDataLegacy();

    //get information about this device's model, platform, and platform version
    const values = await navigator.userAgentData.getHighEntropyValues(["model", "platform", "platformVersion"]);

    //get browser significant brand version
    const brandVersion = getSignificantBrandVersion(navigator.userAgentData.brands);

    //get platform string (includes ver # if available)
    const platform = (values?.platform ?? "Unknown Platform") + (values.platformVersion ? ` ${values.platformVersion}` : "");

    return {
        model: values.model ?? null,
        platform: platform,
        browser: brandVersion?.brand ?? "Unknown Browser",
    };
};