import { axiosData, SourceGetter } from "@imas/api";
import { CreateDepartmentSubCodeRequest, DepartmentCode, DepartmentSubCode } from './types';
import { vDepartmentCode } from "./types/department-codes/vDepartmentCode";

//department codes base url
const DEPARTMENT_CODES_URL = "/api/system/department-codes";

/*  */
export const GetDepartmentCodes = (getSource: SourceGetter) => {
    return async (): Promise<DepartmentCode[]> => {
        return await axiosData({
            method: 'get',
            url: `${DEPARTMENT_CODES_URL}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetDepartmentSubCodes = (getSource: SourceGetter) => {
    return async (): Promise<DepartmentSubCode[]> => {
        return await axiosData({
            method: 'get',
            url: `${DEPARTMENT_CODES_URL}/sub-codes`,
            cancelToken: getSource().token,
        });
    };
};

export const GetvDepartment = (getSource: SourceGetter) => {
    return async (): Promise<vDepartmentCode[]> => {
        return await axiosData({
            method: 'get',
            url: `${DEPARTMENT_CODES_URL}/vDepartmentCode`,
            cancelToken: getSource().token
        });
    };
};

export const UpdatevDepartment = (getSource: SourceGetter) => {
    return async (department: vDepartmentCode): Promise<vDepartmentCode> => {
        return axiosData({
            method: 'put',
            url: `${DEPARTMENT_CODES_URL}/vDepartmentCode`,
            data: department,
            cancelToken: getSource().token
        });
    } ;
};

export const UpdateDepartmentCode = (getSource: SourceGetter) => {
    return async (department: DepartmentCode): Promise<DepartmentCode> => {
        return await axiosData({
            method: 'put',
            url: `${DEPARTMENT_CODES_URL}`,
			data: department,
            cancelToken: getSource().token,
        });
    };
};

export const UpdateDepartmentSubCode = (getSource: SourceGetter) => {
    return async (subDepartment: DepartmentCode): Promise<DepartmentSubCode> => {
        return await axiosData({
            method: 'put',
            url: `${DEPARTMENT_CODES_URL}/sub-codes`,
			data: subDepartment,
            cancelToken: getSource().token,
        });
    };
};

export const CreateDepartmentSubCode = (getSource: SourceGetter) => {
    return async (request: CreateDepartmentSubCodeRequest): Promise<DepartmentSubCode> => {
        return await axiosData({
            method: 'post',
            url: `${DEPARTMENT_CODES_URL}/sub-codes`,
			data: request,
            cancelToken: getSource().token,
        });
    };
};
