import React from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps, Skeleton, SxProps, Theme, FormHelperText } from '@mui/material';
import { FormInputProps, OnBlur } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';


export interface FormCheckboxProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<boolean, TFieldValues, TName>,
    Pick<CheckboxProps, "color" | "size" | "icon" | "checkedIcon" | "disabled" | "tabIndex">,
    Pick<FormControlLabelProps, "label" | "labelPlacement" | "sx" | "style" | "className">
{
	checkboxSx?: SxProps<Theme>;
};

const FormCheckbox = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>>({
		name, control, rules, shouldUnregister, defaultValue,
		loading, required, noErrorText, dependents, onBlur,
        label, labelPlacement, sx, style, className, checkboxSx, ...checkboxProps
    }: FormCheckboxProps<TFieldValues, TName>
) => {
    //use form controller hook
    const {
        value, onChange, handleBlur, ref,
        isLoading, isViewOnly, hasError, helperText
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

    //render loading
    if (isLoading) return (
        <FormControlLabel
            disabled
            label={<Skeleton variant={"rectangular"} width={typeof label === "string" ? label.length * 8 : 100} sx={{ borderRadius: '4px' }}/>}
            control={<Skeleton variant={"rectangular"} sx={{ minHeight: '20px', height: '20px', maxHight: '20px', minWidth: '20px', width: '20px', margin: '5px', marginRight: '10px', borderRadius: '2px', ...checkboxSx }}/>}
            labelPlacement={labelPlacement}
            sx={{ marginLeft: 0, marginRight: '10px', ...sx }}
            style={style}
            className={className}
        />
    );

    //render NumberTextField 
    return (
		<>
			<FormControlLabel
				label={label}
				labelPlacement={labelPlacement}
				sx={{ color: hasError ? 'error.main' : 'text.primary', marginLeft: 0, marginRight: '10px', ...sx }}
				style={style}
				className={className}
				control={<Checkbox
					{...checkboxProps}
					disabled={isViewOnly || checkboxProps.disabled}
					name={name}
					ref={ref}
					checked={value}
					onChange={(_, value) => {
						onChange(value);

						//call blur with the new value
						handleBlur(value);
					}}
					sx={{ padding: '3px', marginRight: '4px', ...checkboxSx }}
				/>}
			/>

			{/* Render Helper Text if it Exists */}
			{helperText ? <FormHelperText error={hasError}>{helperText}</FormHelperText> : null}
		</>
    );
};

export { FormCheckbox };