export const HOUR_TYPES = {
	'REGULAR': 1,
	'PERSONAL': 2,
	'HOLIDAY': 3,
	'SICK': 4,
	'VACATION': 5,
	'FF-EMP': 6,
	'FF-CARE': 7,
	'VACPAYOUT': 8,
	'OTHER': 9
};