import { lighten, alpha } from '@mui/material/styles';
import { makeStyles } from '@imas/styles';

export const employeeMilestoneViewerStyles = makeStyles()((theme) => ({
    viewerContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '-webkit-fill-available',
    },
    listItem: {
        paddingTop: '0',
        paddingBottom: '0',
        "&.Mui-selected": {
            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.75) : alpha(theme.palette.success.main, 0.1),
            "&:hover": {
                backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.success.main, 0.7) : alpha(theme.palette.success.main, 0.15),
            },
        },
    },
}));