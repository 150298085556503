import { memo, useRef, useEffect, useCallback } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { CheckSession, LogOutUser } from '@imas/api/auth';
import { GetSecrets } from '@imas/api/system';
import { useSelector, useDispatch } from 'react-redux';
import useInterval from '@use-it/interval';
import { getIsUserAuthenticated, setUserData, syncAuthCache, setSecrets } from '@imas/redux';
import { useApi } from '@imas/api';
import LightPole from './pages/LightPole/LightPole';
import { onStartup } from './startup';
import { PermissionLockout, UpdateHandler, FeedbackErrorBoundary, IdleTimeout } from '@imas/components/misc';
import { Login, Dashboard, Home, ServiceOrders, Managers, Proposals, User, ClientsAndContacts, Clients, Files, Page, CertsAndQC, Admin } from 'src/pages';
import { ClientSignaturePage } from './pages/ServiceOrders/ServiceOrderClientSignature/ClientSignaturePage';
import { ForgotPass } from './pages/Login/ForgotLogin/ForgotPassword';
import { ForgotUser } from './pages/Login/ForgotLogin/ForgotUser';
import { InvalidCredentails } from './pages/Login/ForgotLogin/InvalidCredentials';
import { ChangePass } from './pages/Login/ForgotLogin/ChangePassword';
import { ApplicantLandingPage } from './pages/Managers/Applicant/ApplicantViewer/ApplicantLanding/ApplicantLanding';
import { ApplicantAccepted } from './pages/Managers/Applicant/ApplicantViewer/ApplicantLanding/ApplicantAccepted';
const App = memo(() => {
    //redux dispatch
    const dispatch = useDispatch();

    //react router
    const navigate = useNavigate();
    const location = useLocation();

    //use APIs
    const logOutUser = useApi(LogOutUser);
    const checkSession = useApi(CheckSession);
	const getSecrets = useApi(GetSecrets);

    //if the user is currently authenticated (with ref)
    const userIsAuthenticated = useSelector(getIsUserAuthenticated);
	const userIsAuthenticatedRef = useRef(userIsAuthenticated);
	userIsAuthenticatedRef.current = userIsAuthenticated;

    //ref to last URL that was visited before a redirect to login so the user can be put back there.
	const lastPathnameRef = useRef(window.location.pathname === "/login" ? "/dashboard" : location.pathname + location.search);

    //function to check if the current session is still valid
    const checkIfValidSession = useCallback(() => {
        //get result promise
        checkSession().then(valid => {
            //if the session is still authenticated then exit
            if (valid) {
                if (!userIsAuthenticatedRef.current) {
                    //sync this tab with the most up to date auth cache
                    dispatch(syncAuthCache());
                }
            } else {
                //if the session is not authenticated and the user is logged in then log them out
                if (userIsAuthenticatedRef.current) navigate("/logout");
            }
        }).catch(e => console.error(e));
    }, [dispatch, checkSession]);

    //detect changes in the employeeData variable and redirect to the last page visited if it has been set to 'true'
    useEffect(() => {
        if (userIsAuthenticated) {
           
            //We dont want users to go back to the forgot password or invalid credentials page.
            if( lastPathnameRef.current.includes('/change-password') || lastPathnameRef.current.includes('/invalid-credentials') 
                || lastPathnameRef.current.includes('/recover-password') || lastPathnameRef.current.includes('/recover-username'))
            {
                lastPathnameRef.current = '/dashboard';
                navigate(lastPathnameRef.current);

            }
            //navigate to previous page
            else
            {
                navigate(lastPathnameRef.current);

            }
        } 
        //run on logout
        else {
            if (
                //routes that don't need credentials
                !location.pathname.includes('/sign-service-order') &&
                !location.pathname.includes('/reset-password') &&
                !location.pathname.includes('/recover-username') &&
                !location.pathname.includes('/recover-password')  &&
                !location.pathname.includes('/invalid-credentials') &&
                !location.pathname.includes('/sign-offer') &&
                !location.pathname.includes('/offer-accepted')


              ) {
                navigate("/login");
              }
              
        }
    }, [userIsAuthenticated]);

	//when userIsAuthenticated changes, update the secets stored in the redux store
	useEffect(() => {
		if (userIsAuthenticated) {
			//load secrets
			getSecrets()
			.then(secrets => {
				dispatch(setSecrets(secrets));
			})
			.catch(() => {});
		} else {
			//clear secrets
			dispatch(setSecrets(null));
		}
	}, [userIsAuthenticated]);

    //whenever URL location changes update lastPathname 
    useEffect(() => {
        if (location.pathname !== lastPathnameRef.current) {
            //ignore '/login' & '/logout' route
            if (location.pathname !== "/login" && location.pathname !== "/logout") lastPathnameRef.current = `${location.pathname}${location.search}`;
        }
    }, [location]);

    //register a interval which checks if the user is still authenticated
    useInterval(() => {
        checkIfValidSession();
    }, 10000);

    //functions run on app load
    useEffect(() => {
        //run onload
        onStartup();

        //check if the current session is valid
        checkIfValidSession();
    }, [checkIfValidSession]);

    //logout component
    const LogoutComponent = useCallback(() => {
        if (location.pathname === "/logout") { 
            //log out the user
            logOutUser().then(() => {
                //log the user out
                dispatch(setUserData(null));
            }).catch(() => {
                //log the user out
                dispatch(setUserData(null));
            });
        };
        
        //render nothing
        return null;
    }, [location.pathname, logOutUser, dispatch]);
    
    return (
        /* Top Level Error Boundary */
        (<FeedbackErrorBoundary>
            {/* Permission Lockout Component, Prevents Users from Using the App if they Do not have the required permissions allowed. */}
            <PermissionLockout/>
            {/* Update Handler Component, Prompts users to update if a new version is available. */}
            <UpdateHandler/>
            {/* IdleTimeout wrapper, will check if the user has gone idle and display a logout timer which if reaches 0 will log the user out */}
            <IdleTimeout idleTime={240} logoutTime={2} enabled={userIsAuthenticated}>
				{/* App Routing */}
				<Routes>
					{/* Login Page */}
					<Route path={"/login"} element={<Page hideNav forceFooter allowUnauthenticated><Login/></Page>}/>


					{/* Dashboard Route */}
					<Route path={"/dashboard/*"} element={<Page showFooter><Dashboard/></Page>}/>

					{/* Home Route */}
					<Route path={"/home/*"} element={<Page><Home/></Page>}/>

					{/* Clients Route */}
					<Route path={"/clients/*"} element={<Page><Clients/></Page>}/>
					
					{/* Clients & Contacts Route */}
					<Route path={"/clients-and-contacts/*"} element={<Page><ClientsAndContacts/></Page>}/>

                    {/* Admin Route */}
                    <Route path={"/admin/*"} element={<Page><Admin/></Page>}/>

					{/* Service Order Route */}
					<Route path={"/service-orders/*"} element={<Page><ServiceOrders/></Page>}/>

					{/* Client portal to sign service orders */}
					<Route path={"/sign-service-order/:hashKey"} element={<Page hideNav allowUnauthenticated={true}><ClientSignaturePage/></Page>} />
					<Route path={"/sign-service-order"} element={<Page hideNav allowUnauthenticated={true}><ClientSignaturePage/></Page>} />
                    <Route path={"/sign-offer/:id"} element={<Page hideNav allowUnauthenticated={true}><ApplicantLandingPage/></Page>} />
                    <Route path={"/offer-accepted"} element={<Page hideNav allowUnauthenticated={true}><ApplicantAccepted/></Page>}></Route>
                    <Route path={"/recover-password"} element={<Page hideNav allowUnauthenticated={true}> <ForgotPass/> </Page>}/>
                    <Route path={"/recover-username"} element={<Page hideNav allowUnauthenticated={true}> <ForgotUser/> </Page>}/>
                    <Route path={"/invalid-credentials"} element={<Page hideNav allowUnauthenticated={true}> <InvalidCredentails/> </Page>}></Route>
                    <Route path={"/reset-password/:hashKey"} element={<Page hideNav allowUnauthenticated={true}> <ChangePass/> </Page>}/>
                    <Route path={"/reset-password"} element={<Page hideNav allowUnauthenticated={true}> <ChangePass/> </Page>}/>
 


					{/* Certs and QC Route */}
					<Route path={"/certs-and-qc/*"} element={<Page><CertsAndQC/></Page>}/>

					{/* Proposals Route */}
					<Route path={"/proposals/*"} element={<Page><Proposals/></Page>}/>

					{/* Manage Rote */}
					<Route path={"/manage/*"} element={<Page><Managers/></Page>}/>

					{/* User Route */}
					<Route path={"/user/*"} element={<Page><User/></Page>}/>

					{/* Light Pole */}
					<Route path={"/light-pole/*"} element={<Page><LightPole/></Page>}/>

					{/* File Explorer Page */}
					<Route path={"/files/*"} element={<Page hideNav><Files/></Page>}/>

					{/* Logout Route, calls /api/auth/logout and then sets loggedIn to false, redirecting the user to the login screen */}
					<Route path={"/logout"} element={<LogoutComponent/>}/>


					{/* Invalid route redirection to /dashboard */}
					<Route path={"*"} element={<RedirectLogger/>}/>
				</Routes>
			</IdleTimeout>
        </FeedbackErrorBoundary>)
    );
});

const RedirectLogger = () => {
    const location = useLocation();
    console.log(`Invalid page url '${location}', redirecting to '/dashboard'.`);
    return <Navigate to="/dashboard"/>;
};

export default App;