import { Box, Typography, Button } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@imas/styles';
import { Resizable } from 'react-resizable';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles()(theme => ({
    column: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '4px', 
        border: 'none', 
        color: theme.palette.text.secondary, 
        // backgroundColor: theme.palette.mode === 'light' 
        //     ? theme.palette.grey[50]
        //     : theme.palette.grey[900],
    },
    columnLabel: { 
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: 'auto auto auto 0', 
        userSelect: 'none' 
    },
    columnResizerContainer: {
        display: 'flex',
        cursor: 'col-resize',
        touchAction: 'none',
        padding: '0 5px',
    },
    columnResizer: { 
        width: '1px', 
        height: '-webkit-fill-available', 
        backgroundColor: theme.palette.text.secondary,
    },
}));

export interface FileListColumnProps {
    label: string;
    offset?: int;
    minWidth?: int;
    defaultWidth: int;
	onClick: () => void;
    onWidthChange: (width: int) => void;
};

//default offset
const DEFAULT_OFFSET = 12;

//memoized to where the component will never re-render //ZM: I undid the never-rerender so that onClick would update and sorting would work
const FileExplorerItemListColumn = React.memo(({ label, offset, minWidth, defaultWidth, onClick, onWidthChange }: FileListColumnProps) => {
    //component styling
    const { classes } = useStyles(); 

	//ref for offtset & onWidthChange
	const offsetRef = useRef(offset);
	offsetRef.current = offset;
	const onWidthChangeRef = useRef(onWidthChange);
	onWidthChangeRef.current = onWidthChange;

    //column width
    const [width, setWidth] = useState(defaultWidth + (offset ?? 0) + DEFAULT_OFFSET);

    //debounced width 
    const [debouncedWidth] = useDebounce(width, 100);

    //call onWidthChange callback when debouncedWidth changes
    useEffect(() => onWidthChangeRef.current(debouncedWidth - (offsetRef.current ?? 0) - DEFAULT_OFFSET), [debouncedWidth]);

    return (
        <Resizable
            height={50}
            width={width} 
            minConstraints={minWidth ? [minWidth, minWidth] : undefined}
            onResize={(_, { size }) => setWidth(size.width)}
            axis={"x"}
            handle={<Box className={classes.columnResizerContainer}><Box className={classes.columnResizer}/></Box>}
        >
            <Box
                width={width}
                className={classes.column}
            >
                {/* <Typography variant={"body2"} className={classes.columnLabel}>{label}</Typography> */}
				<Button fullWidth onClick={onClick} variant={"text"} className={classes.columnLabel} color={'info'}><Typography variant={"body2"} className={classes.columnLabel}>{label}</Typography></Button>
  

                {/* ColumnResize Handle */}
                {/* <Box
                    className={classes.columnResizerContainer}
                >
                    <Box className={classes.columnResizer}/>
                </Box> */}
            </Box>
        </Resizable>
    );
}, /* () => true */);

export { FileExplorerItemListColumn };