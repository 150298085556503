import { isPopup } from '@imas/utils/misc';
import { useCallback, useMemo } from "react";
import { NavigateOptions, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditorItem, EditorItemFields } from "./types";
import { getEditorURL, getEditorURLOptions } from "./getEditorURL";

//EditorState
type EditorState<T extends EditorItemFields> = { 
	readOnly: boolean; 
} & EditorItem<T>;

//EditorStateSetterOptions
type EditorStateSetterOptions = NavigateOptions & getEditorURLOptions;

//EditorStateSetter
type EditorStateSetter<T extends EditorItemFields> = {
	(item: EditorItem<T>, options?: EditorStateSetterOptions): void;
};

//useEditorStateParams
export interface useEditorStateParams {
	url: string;
	redirectUrl?: string;
	paramName?: string;
};

//useEditorStateResult
export type useEditorStateResult<T extends EditorItemFields> = [ 
	state: EditorState<T>,
	setState: EditorStateSetter<T>,
];

//hook which will parse the current editor state & search params to return the current edit state
export function useEditorState<T extends EditorItemFields = {}>(params: useEditorStateParams): useEditorStateResult<T> {
	//hook params
	const { url, redirectUrl, paramName } = params;
	
	//url utilities
	const [searchParams] = useSearchParams();
	const routerParams = useParams();
	const navigate = useNavigate();

	//get router param values
	const itemId = routerParams[paramName ?? "id"];

	//get editor state 
	const state = useMemo((): EditorState<T> => {
		//other url param values
		const editorParams = [...searchParams].map(x => ({ [x[0]]: x[1] })).reduce((a, b) => ({ ...a, ...b }), {});

		//create new item
		if (itemId === "new") return {
			id: null,
			...editorParams,
			readOnly: false,
		} as EditorState<T>;

		//edit existing item mode
        else {
            //convert itemId to int
            const convertedId = Number(itemId);
            
			//ensure id is a number
			if (isNaN(convertedId)) {
				//close popup OR redirect to redirectUrl (defaults to /dashboard)
				if (isPopup()) window.close();
				else {
					navigate(redirectUrl ?? "/dashboard");
				}

				return {
					id: null,
					...editorParams,
					readOnly: false,
				} as EditorState<T>;
			}

			//get the searchParam mode value if provided
			const readOnly = searchParams.get("mode") === "view"; 

			//return item with id
			return {
				id: convertedId,
				...editorParams,
				readOnly,
			} as EditorState<T>;
        };
	}, [searchParams, itemId]);

	//editor state setter
	const setState = useCallback<EditorStateSetter<T>>((item, options) => {
		//seperate getEditorURL options from navigate options
		const { readOnly, ...navigateOptions } = options ?? {};
		
		//execute navigation
		navigate(getEditorURL(url, item, { readOnly }), navigateOptions);
	}, []);

	return [
		state,
		setState
	];
};