import { objectToFormData, SourceGetter, axiosData } from "@imas/api";
import { WeldTest, WeldTestForm, WeldTestsSearchQuery, WeldTestsSearchResult } from "./types";

//proposal weld tests base url
const WELD_TESTS_URL = "/api/proposals/weld-tests";

/* https://localhost:44363/swagger/index.html#/WeldTests/get_api_proposals_weld_tests */
export const GetProposalWeldTests = (getSource: SourceGetter) => {
    return async (): Promise<WeldTest[]> => {
        return axiosData({
			method: 'get',
            url: `${WELD_TESTS_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/* https://localhost:44363/swagger/index.html#/WeldTests/get_api_proposals_weld_tests_search */
export const ProposalWeldTestsSearch = (getSource: SourceGetter) => {
    return async (query: WeldTestsSearchQuery): Promise<WeldTestsSearchResult> => {
        return axiosData({
            method: 'get',
            url: `${WELD_TESTS_URL}/search`,
            params: query,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/WeldTests/get_api_proposals_weld_tests_find_similar */
export const FindSimilarProposalWeldTests = (getSource: SourceGetter) => {
    return async (name: string | null, excludedId?: int): Promise<WeldTest[]> => {
        return axiosData({
            method: 'get',
            url: `${WELD_TESTS_URL}/find-similar`,
            params: {
                name,
                excludedId,
            },
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/WeldTests/get_api_proposals_weld_tests__weldTestId_ */
export const GetProposalWeldTest = (getSource: SourceGetter) => {
    return async (weldTestId: int): Promise<WeldTest> => {
        return axiosData({
            method: 'get',
            url: `${WELD_TESTS_URL}/${weldTestId}`,
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/WeldTests/post_api_proposals_weld_tests */
export const CreateProposalWeldTest = (getSource: SourceGetter) => {
    return async (data: WeldTestForm): Promise<WeldTest> => {
        return axiosData({
            method: 'post',
            url: WELD_TESTS_URL,
            data: objectToFormData(data),
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/WeldTests/put_api_proposals_weld_tests__weldTestId_ */
export const UpdateProposalWeldTest = (getSource: SourceGetter) => {
    return async (weldTestId: int, data: WeldTestForm): Promise<WeldTest> => {
        return axiosData({
            method: 'put',
            url: `${WELD_TESTS_URL}/${weldTestId}`,
            data: objectToFormData(data),
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: getSource().token,
        });
    };
};

/* https://localhost:44363/swagger/index.html#/WeldTests/delete_api_proposals_weld_tests__weldTestId_ */
export const DeleteProposalWeldTest = (getSource: SourceGetter) => {
    return async (weldTestId: int): Promise<WeldTest> => {
        return axiosData({
            method: 'delete',
            url: `${WELD_TESTS_URL}/${weldTestId}`,
            cancelToken: getSource().token,
        });
    };
};