import { FileExplorerContextMenuEvent, FileExplorerContextMenuAction } from './types';
import { TFileExplorerContext } from '../FileExplorerContext';
import { downloadFiles, openFile } from '@imas/utils/files';

export const getDefaultActions = (event: FileExplorerContextMenuEvent, context: TFileExplorerContext): FileExplorerContextMenuAction[][] => {
    if (event.variant === "item") {
        //separate files & folders
        const files = event.items.filter(x => !x.isDir);
        const folders = event.items.filter(x => !x.isDir);

        //actions
        const actions: FileExplorerContextMenuAction[][] = [];

        //add file actions
        if (files.length > 0) {
			//file actions 
			const basicFileActions: FileExplorerContextMenuAction[] = [];

			//single file actions
			if (files.length === 1) {
				//the selected file
				const file = files[0];

				basicFileActions.push({
					label: "View",
					click: () => { openFile(context.table, file.pathLocator); },
				});
			}

			//multi file actions
            basicFileActions.push({ 
				label: `Download ${files.length > 1 ? `(${files.length})` : ""}`,
				click: () => downloadFiles(files.map(x => x.pathLocator), context.table),
			});

			//add basicFileActions
			actions.push(basicFileActions);
        }

		//if it is a single folder

        //return actions
        return actions;
    } else return [];
};