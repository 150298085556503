import { Box } from '@mui/material';
import { memo, useMemo, useEffect, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PDFEmbedSDK } from './PDFEmbedSDK';

export interface PDFViewerProps extends Omit<PDFEmbedSDK.OpenViewerOptions, "mode" | "enableFormFilling" | "showAnnotationTools"> {
	source: PDFEmbedSDK.OpenViewerSource;
};

export const PDFViewer = memo((props: PDFViewerProps) => {
	const propsWithDefaults = useMemo(() => ({ 
		...props, 
		showAnnotationTools: false,
		enableFormFilling: false, 
	}), [props]);

	//generate an ID for the lifetime of the div
	const divId = useRef<string>(uuidv4());

	//props ref
	const propsRef = useRef(propsWithDefaults);
	propsRef.current = propsWithDefaults;

	//SDK ref
	const sdkRef = useRef<{ client?: PDFEmbedSDK.Client; viewer?: PDFEmbedSDK.Viewer; }>({});

	//render PDF
	const renderPDF = useCallback(({ source, ...options }: PDFViewerProps) => {
		if (!sdkRef.current.client) return;
		
		//get a viewer for the div
		sdkRef.current.viewer = sdkRef.current.client.getViewer(divId.current);

		//open the PDF
		sdkRef.current.viewer.open(source, options);
	}, []);

	//create viewer
	useEffect(() => {
		//create a client
		sdkRef.current.client = new PDFEmbedSDK.Client();

		//wait for the SDK to be ready
		sdkRef.current.client.ready().then(() => {
			renderPDF(propsRef.current);
		});
	}, [renderPDF]);

	//re-render PDF when source changes (if view is ready)
	useEffect(() => {
		if (sdkRef.current.viewer) {
			renderPDF(propsWithDefaults);
		}
	}, [propsWithDefaults, renderPDF]);


	return (
		<Box
			id={divId.current}
			sx={{
				flex: '1',
				width: '100%',
				height: '100vh', // Full viewport height
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		/>
	);
	
});