import { v4 as uuidv4 } from 'uuid';
import { local } from '@imas/utils/storage';

const UNIQUE_ID_STORAGE_KEY = "deviceUniqueId";

//get this device's uniqueId from localStorage
export const getDeviceUniqueId = (): UUID => {
    //get the unique id
    const uniqueId = local.get(UNIQUE_ID_STORAGE_KEY);

    //if the uniqueId is null assign the device a uniqueId
    if (uniqueId === null) {
        //create new uniqueId
        const newUniqueId = uuidv4();

        //save new uniqueId
        local.set(UNIQUE_ID_STORAGE_KEY, newUniqueId);

        //return new id
        return newUniqueId;
    }

    //return found id
    return uniqueId;
};