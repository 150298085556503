import { Button, Dialog, DialogTitle, DialogTitleProps, DialogActions, DialogContent, Typography, TypographyProps, DialogProps, Theme, SxProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { makeStyles } from '@imas/styles';

export interface ConfirmationDialogProps extends Omit<DialogProps, "open" | "onClose" | "title"> {
	/** Title of Dialog Box & Props for DialogTitle component */
	title?: string | React.ReactNode;
	titleProps?: Partial<DialogTitleProps>;
    /** User facing prompt */
    prompt: string | React.ReactNode;
    /** Props for the Typography element used to display prompt if prompt is a string. */
    promptProps?: TypographyProps;
    /** Body of prompt dialog if needed. */
    body?: React.ReactNode;
    /** If the dialog is open */
    open: boolean;
    /** If the user must press the cancel button to close the dialog. */
    forceButtonCancel?: boolean;
    
    /** Confirmation Button Text */
    confirmText?: string;
    /** Confirmation Button Icon */
    confirmIcon?: React.ReactNode;
    /** Confirmation Callback, if async then the dialog will stay open in the loading state until the callback resolves. */
    onConfirm: () => Promise<void> | void;
    
    /** Cancel Button Text */
    cancelText?: string;
    /** Cancel Button Icon */
    cancelIcon?: React.ReactNode;
    /** Cancelation Callback */
    onCancel: () => void;
};

const useStyles = makeStyles()(() => ({
	title: {
		fontWeight: 'bold',
	},
}));

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    //props
    const { 
        title, titleProps, prompt, promptProps, body, open, sx,
        confirmText, confirmIcon: confirmIconProp, onConfirm,
        cancelText, cancelIcon, onCancel,

        ...dialogProps
    } = props;
    const forceButtonCancel = props.forceButtonCancel ?? false;

	//component styling
	const { classes, cx } = useStyles();

	//default confirm icon
	const confirmIcon = confirmIconProp === null ? undefined : confirmIconProp ?? <CheckIcon/>;

    //if onConfirm returns a promise then this state will be set to true, will be set back to false on promise resolve/reject
    const [confResolving, setConfResolving] = useState(false);

    return (
        <Dialog 
            {...dialogProps}
            open={open}
            onClose={() => {
                //if confResolving is true prevent closing dialog
                if (confResolving) return;
                
                //if forceButtonCancel is true the then the user must use the cancel button to close the dialog
                if (forceButtonCancel) return;
                
                //call onCancel
                onCancel();
            }}
			sx={sx}
        >
			{title ? <DialogTitle {...titleProps} className={cx(classes.title, titleProps?.className)}>{title}</DialogTitle> : null} 
            <DialogContent>
                {typeof(prompt) === "string" ? <Typography {...promptProps}>{prompt}</Typography> : prompt}
                {body ?? null}
            </DialogContent>
            <DialogActions>
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    disabled={confResolving}
                    startIcon={confirmIcon ? (cancelIcon ? cancelIcon : <CloseIcon/>) : cancelIcon}
                    onClick={onCancel}
                >{cancelText ?? "Cancel"}</Button>
                
                {/* Loading Button for Confirm */}
                <LoadingButton
                    color={"primary"}
                    variant={"contained"}
                    startIcon={confirmIcon}
                    loading={confResolving}
                    loadingPosition={confirmIcon ? "start" : undefined}
                    onClick={() => {
                        const result = onConfirm();

                        if (result instanceof Promise) {
                            //setConfResolving to true
                            setConfResolving(true);

                            //set confResolving to false when the promise is fulfilled/rejected
                            result.finally(() => setConfResolving(false));
                        }
                    }}
                >{confirmText ?? "Confirm"}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};