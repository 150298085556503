import { UpdateType } from '@imas/api';
import Dexie, { Table } from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useRef, useState } from 'react';
import { Client, ClientLocation, ClientLocationContact, vClientContact, vContact, vLocationPrimaryPhoneNumber, vContactPrimaryPhoneNumber } from '../client';

const IMAS_CACHE_DB_VER = 1;

export interface IMASCacheDbRecord { 
	id: string | int;
};

export interface IMASCacheDbTableMap {
	"Clients": Client;
	"ClientLocations": ClientLocation;
	"ClientLocationContacts": ClientLocationContact;
	"vClientContacts": vClientContact;
	"vContacts": vContact;
	"vLocationPrimaryPhoneNumbers": vLocationPrimaryPhoneNumber;
	"vContactPrimaryPhoneNumbers": vContactPrimaryPhoneNumber;
};

export type IMASCacheDbTables = keyof IMASCacheDbTableMap;

//define the CacheDb
export class IMASCacheDb extends Dexie {
	//tables
	Clients!: Table<Client>;
	ClientLocations!: Table<ClientLocation>;
	ClientLocationContacts!: Table<ClientLocationContact>;
	vClientContacts!: Table<vClientContact>;
	vContacts!: Table<vContact>;
	vLocationPrimaryPhoneNumbers!: Table<vLocationPrimaryPhoneNumber>;
	vContactPrimaryPhoneNumbers!: Table<vContactPrimaryPhoneNumber>;
	
	constructor() {
		super("IMASCacheDb");

		//define tables
		this.version(IMAS_CACHE_DB_VER).stores({
			Clients: `id`,
			ClientLocations: `id,clientId`,
			ClientLocationContacts: `id,locationId`,
			vClientContacts: `id`,
			vContacts: `id,locationId,clientId`,
			vLocationPrimaryPhoneNumbers: `id,clientId`,
			vContactPrimaryPhoneNumbers: `id,locationId,clientId`,
		});
	};
};

//global instance of IMASCacheDb
export const CACHE_DB = new IMASCacheDb();

//a client interface which interacts with the specified CacheDb table
export class CacheClient<T extends IMASCacheDbTables, TV extends IMASCacheDbRecord = IMASCacheDbTableMap[T]> {
	public readonly db: IMASCacheDb = CACHE_DB;
	public readonly tName: T;

	public get store () { return this.db[this.tName]; }
	public get table () { return this.store; }

	public constructor(table: T) {
		this.tName = table;
	};

	/** Checks the current app settings to see if the cache is enabled. */
	public isEnabled(): boolean {
		if (IMAS.store.getState().appSettings.value.avanced.cache === null) return false;
		return true;
	}

	/** Get all/single value(s) from the table. */
	public async get<DV = undefined>(params?: { id: undefined, defaultValue?: DV }): Promise<TV[] | DV>;
	public async get<T extends IMASCacheDbRecord["id"], DV = undefined,>(params: { id: T, defaultValue?: DV }): Promise<TV | DV>;
	public async get<T extends IMASCacheDbRecord["id"] | undefined = undefined, DV = undefined>({ id, defaultValue }: { id: T, defaultValue?: DV } = {} as any): Promise<TV[] | TV | DV> {
		if (!this.isEnabled()) return defaultValue as DV;
		
		if (id === undefined) {
			const results = await this.store.toArray();

			//if the results does not meet the threshold length, return the default value
			if (results.length === 0) return defaultValue as DV;
			return results as unknown as TV[];
		}
		return ((await this.store.get(id)) ?? defaultValue) as TV | DV;
	};

	/** Handle updates to the table given the type of update & single/multiple values.  */
	public async update(type: UpdateType, value: TV[] | TV): Promise<void> {
		if (!this.isEnabled()) return;

		if (type === "CREATE" || type === "UPDATE") {
			if (value instanceof Array) {
				await (this.store.bulkPut as unknown as (values: any[]) => Promise<void>)(value);
			} else {
				await this.store.put(value as any);
			}
		} else {
			if (value instanceof Array) {
				await this.store.bulkDelete(value.map(x => x.id));
			} else {
				await this.store.delete(value.id);
			}
		}
	};
};

//use cache hook
export function useCache<T extends IMASCacheDbTables, V>(
	table: T,
	query: (client: CacheClient<T>) => V | Promise<V>,
	deps?: any[],
): [V | undefined, CacheClient<T>] {
	//intance of cache client
	const clientRef = useRef(new CacheClient(table));

	//result
	const [result, setResult] = useState<V | undefined>(undefined);

	//live dexie query
	useEffect(() => {
		//reset result
		setResult(undefined);

		//make query & set result
		(async () => { setResult(await query(clientRef.current)); })();
	}, deps ?? []);

	return [result, clientRef.current];
};

//use cache hook (internally uses useLiveQuery)
export function useLiveCache<T extends IMASCacheDbTables, V>(
	table: T,
	query: (client: CacheClient<T>) => V | Promise<V>,
	deps?: any[],
): [V | undefined, CacheClient<T>] {
	//intance of cache client
	const clientRef = useRef(new CacheClient(table));

	//live dexie query
	const result = useLiveQuery(() => query(clientRef.current), deps);

	return [result, clientRef.current];
};