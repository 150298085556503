import { Moment, isMoment } from 'moment'; 

//ConvertibleTypes type
type ConvertibleTypes = File | Date | Moment | string | number | boolean | null | undefined;

//type
type ConvertibleListTypes =  File[] | Date[] | Moment[] | string[] | number[] | boolean[];

//DeepConvertibleTypes type
type DeepFormConvertible = { [key: string]: DeepFormConvertible | File | Date | string | number | null | undefined };

//FormConvertible type
type FormConvertible = Record<string, any>;// ConvertibleTypes | ConvertibleListTypes | DeepFormConvertible>;

export function objectToFormData<T extends FormConvertible>(data: T): FormData {
    //create FormData instance
    const form = new FormData();
    
    //add each property to the form
    for (const key in data) {
        const value = data[key] as any;

        //check for null or undefined do nothing
        if (value === null || value === undefined) continue;

        //check for special types like Date, File, Moment objects and Lists 
        if (value instanceof Date) {
            //serialize date to string
            form.set(key, value.toISOString());
            continue;
        } else if (value instanceof File) {
            //append single file
            form.set(key, value);
            continue;
        } else if (isMoment(value)) {
            //serialize moment object
            form.set(key, value.toISOString());
            continue;
        } else if (Array.isArray(value)) {
            //check if array is empty
            if (value.length < 1) {
				continue;
			};

            //check the type of the first element, and convert accordingly ignoring items of other types
            if (value[0] instanceof Date) {
                for (const item of value) {
                    if (item instanceof Date) form.append(key, item.toISOString());
                }
                continue;
            } else if (value[0] instanceof File) {
                for (const item of value) {
                    if (item instanceof File) form.append(key, item);
                }
                continue;
            } else if (value[0] instanceof File) {
                for (const item of value) {
                    if (item instanceof File) form.append(key, item);
                }
                continue;
            } else if (typeof value[0] === "string") {
                for (const item of value) {
                    if (typeof item === "string") form.append(key, item);
                }
                continue;
            } else if (typeof value[0] === "number") {
                for (const item of value) {
                    if (typeof item === "number") form.append(key, item.toString());
                }
                continue;
            } else if (typeof value[0] === "boolean") {
                for (const item of value) {
                    if (typeof item === "boolean") form.append(key, item.toString());
                }
                continue;
            }
        } else if (value instanceof Object) {
            //handle object serialization
            serializeFormObject(form, key, value);
        } else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
            //set string, number, & bool
            form.set(key, value.toString());
        };
    }

    //return the constructed form object
    return form;
}

//recursive function for serializing a object into a form
function serializeFormObject(form: FormData, prefix: string, obj: DeepFormConvertible) {
    for (const key in obj) {
        //get the fullKey and value
        const fullKey = `${prefix}.${key}`;
        const value = obj[key];
        
        if (value instanceof File) {
            //add file
            form.set(fullKey, value);
            continue;
        }
        else if (value instanceof Date) {
            //serialize date
            form.set(fullKey, value.toISOString());
            continue;
        }
        else if (value instanceof Object) {
            serializeFormObject(form, fullKey, value);
            continue;
        } 
        else if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
            //set string, number, & bool
            form.set(fullKey, value.toString());
        }
    }
};