import { Dialog, DialogContent, List, ListItem, Button, Typography, ButtonPropsColorOverrides, DialogActions } from '@mui/material';
import moment from 'moment';
import React, { useState, useMemo } from 'react';
import { GetOnCallScores } from 'src/api/schedule';
import { Alignment } from '@imas/components/layout';
import { Alarm as AlarmIcon } from '@mui/icons-material';
import onCallScoresStyles from './OnCallScoresStyles';
import { SlideUp } from '@imas/components/transitions';
import { XDialogTitle } from '@imas/components/mui';
import { OverridableStringUnion } from '@mui/types';
import { useLoadApi } from '@imas/api';
import { OnCallScoreResult } from 'src/api/types/api/schedule/schedule';


const OnCallScores = ({
    text, color, variant, className
}: {
    text: string,
    color: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>,
    variant: 'text' | 'outlined' | 'contained',
    className: string
}) => {
    //component styling
    const { classes, deviceInfo } = onCallScoresStyles();

    //variables
    const [scoresOpen, setScoresOpen] = useState<boolean>(false);

    //get correct saturday for the scores
    const saturday = useMemo(() => {
        const today = moment();
        if (today.day() === 0) {
            return moment().day(-6);
        } else {
            return moment().day(6);
        }
    }, []);

    //load on call scores
    const { data: onCallScores } = useLoadApi(GetOnCallScores, [saturday, saturday.clone().add(1, "day"), null], [saturday]);
    //const onCallScores: OnCallScoreResult[] = [];

    //sort the on call scores
    const sortedOnCallScores = useMemo(() => {
        if (!onCallScores) return undefined;
		if (!Array.isArray(onCallScores)) return undefined;
        return onCallScores.sort((a, b) => (a.onCallScore > b.onCallScore) ? -1 : ((b.onCallScore > a.onCallScore) ? 1 : 0));
    }, [onCallScores]);

    return (
        (<React.Fragment>
            {/* Button used to open the Scores Dialogue */}
            <Button
                size={"small"}
                color={color}
                variant={variant}
                className={className}
                onClick={() => setScoresOpen(true)}
            >{text}</Button>
            {/* Dialogue which displays the On Call Scores */}
            <Dialog 
                open={scoresOpen} 
                onClose={() => setScoresOpen(false)}
                fullScreen={!deviceInfo.isMd}
                TransitionComponent={!deviceInfo.isMd ? SlideUp : undefined}
            >
                {/* Dialogue Title with Icon */}
                <XDialogTitle
                    fullScreen={!deviceInfo.isMd}
                    onClose={!deviceInfo.isMd ? () => setScoresOpen(false) : undefined}
                >
                    <Alignment row>
                        {!deviceInfo.isMd ? null : <AlarmIcon className={classes.onCallIcon}/>}
                        <Typography variant={"h5"} className={classes.onCallText}>{"On Call Scores"}</Typography>
                    </Alignment>
                </XDialogTitle>

                {/* Dialogue Content with a list of employee's and their scores. */}
                <DialogContent>
                    {!sortedOnCallScores ? null : (
                        <List>
                            {sortedOnCallScores.map((data, index) => (
                                // Individual score entries
                                (<ListItem key={index} className={classes.scoreItem}>
                                    <Typography variant={"subtitle1"} className={classes.scoreText}>{data.onCallScore.toFixed(1)}</Typography>
                                    <Typography variant={"subtitle1"}>{data.employeeName}</Typography>
                                </ListItem>)
                            ))}
                        </List>
                    )}
                </DialogContent>

                {/* Close Dialog Action */}
                <DialogActions>
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        onClick={() => setScoresOpen(false)}
                    >{"Close"}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>)
    );
};

export default OnCallScores;