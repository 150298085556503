import * as CONSTANTS from './constants';
import * as API from './api';
import { store as reduxStore } from './redux';
import { db } from '@imas/utils/storage';

//declare global IMAS namespace & introduce variables
declare global {
	namespace IMAS {
		/** Current App Version */
		const VERSION: typeof CONSTANTS.VERSION;
		
		/** Visitable App Pages */
		const PAGES: typeof CONSTANTS.PAGES;

		/** IMAS Api */
		const api: typeof API;

		/** Redux Store */
		const store: typeof reduxStore;
	}
};

//add IMAS namespace to global
global.IMAS = {
	VERSION: CONSTANTS.VERSION,
	PAGES: CONSTANTS.PAGES,

	api: API,
	store: reduxStore,
};

export function addIMAS() {
	//function for adding IMAS namespace to global
	global.IMAS = {
		VERSION: CONSTANTS.VERSION,
		PAGES: CONSTANTS.PAGES,

		api: API,
		store: reduxStore,
	};
};

//initialize imas module
export default function initIMAS() {
	//add imas interface to global object
	addIMAS();

	//apply API config
	API.configureAPI();
	
	//initalize the legacy IndexedDB cache database
	db.init();
};