import { Box, alpha } from '@mui/material';
import React, { useCallback, useContext, useMemo, useEffect, useRef } from 'react';
import { FolderInfoItem } from '@imas/api/files/types';
import { FileExplorerContext } from '../FileExplorerContext';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@imas/styles';
import { FileExplorerItemList } from './FileExplorerItemList/FileExplorerItemList';
import { getFileTypeIconURL, formatFileSize, openFile } from '@imas/utils/files';


const useStyles = makeStyles()((theme) => ({
    dropTarget: {
        backgroundColor: `${theme.palette.mode === 'light'
        ? alpha(theme.palette.success.light, 0.25)
        : alpha(theme.palette.success.dark, 0.25)}`,
    },
}));

//display item interface
export interface FileExplorerDisplayItem {
    //reference to original item
    item: FolderInfoItem;

    //display attributes
    iconUrl: string;
    name: string;
    date: string;
    type: string;
    size: string;
    isDir: boolean;
    selected: boolean;
};

const FileExplorerItemContainer = React.memo(() => {
    //component styling
    const { classes } = useStyles();
    
    //use explorer context
    const { sorting, table, mode, canUpload, currentDir, items, selected, contextMenu, setSelected, navigate, upload, move, preferences: { selectCheckboxes, includeExtInFilename } } = useContext(FileExplorerContext);

    //generate display items, they are updated when items, or selected changes
    const displayItems = useMemo((): FileExplorerDisplayItem[] => {
        return (items ?? []).map(item => ({
            item,
            iconUrl: getFileTypeIconURL(item.type, { isDirectory: item.isDir }),
            name: includeExtInFilename && item.type ? `${item.name}.${item.type}` : item.name,
            date: moment(item.lastWrite).format("M/D/YYYY h:mm A"),
            type: item.type ?? "",
            size: item.isDir ? "" : formatFileSize(item.size, 0),
            isDir: item.isDir,
            selected: selected.includes(item),
        }));
    }, [includeExtInFilename, items, selected, sorting]);

	//items & selected ref
	const itemsRef = useRef(items ?? []);
    useEffect(() => { itemsRef.current = items ?? []; }, [items]);
	const selectedRef = useRef(selected);
    useEffect(() => { selectedRef.current = selected; }, [selected]);
	
    //last selected ref
    const lastSelectedRef = useRef<FolderInfoItem>();

    //reset selected files when items changes
    useEffect(() => setSelected([]), [items, setSelected]);

    //when items changes then update lastSelectedRef to be the first element
    useEffect(() => { if (items && items.length > 0) lastSelectedRef.current = items[0]; }, [items]);

    //check if a specific item is selected
    const getItem = useCallback((index: int) => {
        return displayItems[index];
    }, [displayItems]);

    //callback for when a row is selected
    const selectCallback = useCallback((row: FolderInfoItem, ctrlKey?: boolean, shiftKey?: boolean) => {
        if (!itemsRef.current) return;

        //close context menu if it is open
        contextMenu.current.close();
        
        //update selected
        setSelected(x => {
            if (!shiftKey) lastSelectedRef.current = row;

            //newSelected list
            let newSelected: FolderInfoItem[];

            //if only single select is allowed
            const selectSingleOnly = mode === "pick-file" || mode === "pick-folder" || mode === "upload-file" || mode === "upload-files";

            //apply selection
            if (shiftKey && !selectSingleOnly) {
                const lastSelected = lastSelectedRef.current;
                if (!lastSelected) return x;
                
                //get the position of the last selected row in the items
                const lastSelectedIndex = itemsRef.current.findIndex(y => y.pathLocator === lastSelected.pathLocator);
                const selectedIndex = itemsRef.current.findIndex(y => row.pathLocator === y.pathLocator);

                //get lowest & highest index between the two
                const lowest = lastSelectedIndex > selectedIndex ? selectedIndex : lastSelectedIndex;
                const highest = lastSelectedIndex > selectedIndex ? lastSelectedIndex : selectedIndex;

				//get a list of items which will be the new selected set of items
                newSelected = itemsRef.current.filter((y, index) => highest >= index && index >= lowest);
            }
            else if (ctrlKey && !selectSingleOnly) newSelected = x.includes(row) ? x.filter(y => y.pathLocator !== row.pathLocator) : [...x, row];
            else newSelected = [row];

            //filter out folders if only file picking is allowed
            if (mode === "pick-file" || mode === "pick-files") newSelected = newSelected.filter(y => !y.isDir);

            //filter out files if only folder picking is allowed
            if (mode === "pick-folder" || mode === "upload-file" || mode === "upload-files") newSelected = newSelected.filter(y => y.isDir);

            //return the newSelected list
            return newSelected;
        });
    }, [contextMenu, mode, setSelected]);

    //callback for when a row is opened
    const openCallback = useCallback((row: FolderInfoItem) => {
        //if the item is a folder
        if (row.type === null) {
            //navigate to the folder
            navigate(row.name, row.pathLocator);
        } 
		//if the item is a file
		else {
			//if the mode is "explorer" then open the file in the file viewer
			if (mode === "explorer") return openFile(table, row.pathLocator);
		}
    }, [table, mode, navigate]);

    //callback for right clicking on item
    const menuCallback = useCallback((row: FolderInfoItem, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		//prevent default & stop propagation of the event
		e.preventDefault();
		e.stopPropagation();
		
		//if the row is already selected show a context menu for all selected items
		if (selectedRef.current.some(x => x.pathLocator === row.pathLocator)) {
			//open the FileExplorerContextMenu for all selected items
			contextMenu.current.open({ variant: "item", items: selectedRef.current, origin: { x: e.pageX, y: e.pageY } }); 
		} else {
			//set the item as the selected item
			setSelected([row]);
					
			//open the FileExplorerContextMenu for the item which was clicked
			contextMenu.current.open({ variant: "item", items: [row], origin: { x: e.pageX, y: e.pageY } });
		}
    }, [setSelected]);

    //callback when moving files to a new folder is done
    const handleMove = useCallback((row: FolderInfoItem) => {
        setSelected(x => {
            //execute move for selected files
            move(x, row);

            return x;
        });
    }, [setSelected, move]); 

    //compute the state of the checkbox
    const checkboxState = useMemo(() => {
        //check if all of the rows are selected
        if (displayItems.every(x => x.selected)) return "checked";

        //check if any rows are selected
        if (displayItems.some(x => x.selected)) return "indeterminate";

        //return false
        else return false;
    }, [displayItems]);

    //drop hook for uploading a new file
    const [{ isTarget }, drop] = useDrop(() => ({
        accept: NativeTypes.FILE,
        drop: (item) => { 
            console.log(item);
            upload((item as { files: File[] }).files, { name: currentDir.name, pathLocator: currentDir.pathLocator, lastWrite: moment().format(), type: null, size: 0, isDir: true });
        },
        canDrop: (_, monitor) => {
            return (mode === "explorer" || mode === "upload-file" || mode === "upload-files") && monitor.isOver({ shallow: true });
        },
        collect: (monitor) => ({
            isTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
        }),
    }), [mode, currentDir]);

    return (
        <Box 
            className={clsx({ [classes.dropTarget]: isTarget })}
            sx={{ flex: '1', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
            onContextMenu={(e) => { 
                //prevent default menu from opening
                e.preventDefault(); 
                
                //open the FileExplorerContextMenu
                contextMenu.current.open({ variant: "item", items: selected, origin: { x: e.pageX, y: e.pageY } }); 
            }} 
            ref={drop}
        >
            <FileExplorerItemList
                totalItems={displayItems.length}
                data={{
                    mode,
					canUpload,
                    showCheckboxes: selectCheckboxes,
                    getItem,
                    onSelect: selectCallback,
                    onOpen: openCallback,
					onMenu: menuCallback,
                    onMove: handleMove,
                    upload,
                }}
                checkboxState={checkboxState}
                onCheckAll={() => {
                    //if checkboxState === "checked" then just set selected to an empty array
                    if (checkboxState === "checked") setSelected([]);
                    //in all other cases set selected to the entire array of rows
                    else setSelected([...displayItems.map(x => x.item)]);
                }}
            />

            {/* Item Context Menu */}
            {/* <Menu
                open={contextMenu !== null && selected.length > 0}
                onClose={closeMenu}
                onClick={closeMenu}
                anchorReference={"anchorPosition"}
                anchorPosition={contextMenu !== null ? { top: contextMenu.y, left: contextMenu.x } : undefined}
            >
                <MenuItem onClick={() => {
                    //download files, ignore folders
                    downloadFiles(selected.filter(x => !x.isDir).map(x => x.pathLocator), table);
                }}>
                    <ListItemIcon><DownloadIcon fontSize={"small"}/></ListItemIcon>
                    {`Download ${selected.length > 1 ? `selected files (${selected.length})` : `"${selected[0]?.name ?? "file"}${selected[0]?.type ? `.${selected[0].type}` : ''}"`}`}
                </MenuItem>
            </Menu> */}
        </Box>
    );

    // return (
    //     <Alignment 
    //         column 
    //         flex 
    //         overflowHidden 
    //         onContextMenu={(e) => { e.preventDefault(); setContextMenu({ x: e.pageX, y: e.pageY }); }}
    //     >
    //         <Alignment row flex overflowHidden>
    //             <TableContainer sx={{ flex: '1', overflow: 'scroll' }} className={clsx({ [classes.dropTarget]: isTarget })} ref={drop}>
    //                 <Table stickyHeader sx={{ width: 'unset' }}>
    //                     <TableHead>
    //                         <TableRow>
    //                             {/* Select Checkbox */}
    //                             {selectCheckboxes ? (
    //                                 <TableCell sx={theme => ({ border: 'none', backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900], })}><Checkbox
    //                                     size={"small"}
    //                                     disabled={mode === "pick-file" || mode === "pick-folder" || mode === "upload-file" || mode === "upload-files"}
    //                                     checked={checkboxState === "checked"}
    //                                     indeterminate={checkboxState === "indeterminate"}
    //                                     onClick={() => {
    //                                         if (!items) return;

    //                                         //if checkboxState === "checked" then just set selected to an empty array
    //                                         if (checkboxState === "checked") setSelected([]);
    //                                         //in all other cases set selected to the entire array of rows
    //                                         else setSelected([...items]);
    //                                     }}
    //                                 /></TableCell>
    //                             ) : undefined}

    //                             {/* Columns */}
    //                             <FileListColumn label={"Name"} defaultWidth={columns.name} onWidthChange={(width) => setColumns(x => ({ ...x, name: width }))}/>
    //                             <FileListColumn label={"Date modified"} defaultWidth={columns.date} onWidthChange={(width) => setColumns(x => ({ ...x, date: width }))}/>
    //                             <FileListColumn label={"Type"} defaultWidth={columns.type} onWidthChange={(width) => setColumns(x => ({ ...x, type: width }))}/>
    //                             <FileListColumn label={"Size"} defaultWidth={columns.size} onWidthChange={(width) => setColumns(x => ({ ...x, size: width }))}/>
    //                         </TableRow>
    //                     </TableHead>
    //                     <TableBody
    //                         sx={{
    //                             "& tr > td:nth-last-of-type(4) *": { maxWidth: columns.name },
    //                             "& tr > td:nth-last-of-type(3) *": { maxWidth: columns.date },
    //                             "& tr > td:nth-last-of-type(2) *": { maxWidth: columns.type },
    //                             "& tr > td:nth-last-of-type(1) *": { maxWidth: columns.size },
    //                         }}
    //                     >
    //                         {(items ?? []).map(item => (
    //                             <FileExplorerItem 
    //                                 key={item.pathLocator}
    //                                 data={item}
    //                                 selected={selected.includes(item)}
    //                                 onSelect={selectCallback}
    //                                 onOpen={openCallback}
    //                                 onMenu={menuCallback}
    //                                 move={handleMove}
    //                             />
    //                         ))}
    //                     </TableBody>
    //                 </Table>

    //                 {/* If this folder has no contents show a message */}
    //                 {items ? items.length === 0 ? (
    //                     <Typography 
    //                         sx={{ margin: '10px auto', textAlign: 'center', color: 'text.secondary' }}
    //                         variant={"body2"}
    //                     >{"This folder is empty."}</Typography>
    //                 ) : null : null}
    //             </TableContainer>
    //         </Alignment>

    //         {/* Selected Item Context Menu */}
    //         <Menu
    //             open={contextMenu !== null && selected.length > 0}
    //             onClose={closeMenu}
    //             onClick={closeMenu}
    //             anchorReference={"anchorPosition"}
    //             anchorPosition={contextMenu !== null ? { top: contextMenu.y, left: contextMenu.x } : undefined}
    //         >
    //             <MenuItem onClick={() => {
    //                 //download files, ignore folders
    //                 downloadFiles(selected.filter(x => !x.isDir).map(x => x.pathLocator), table);
    //             }}>
    //                 <ListItemIcon><DownloadIcon fontSize={"small"}/></ListItemIcon>
    //                 {`Download ${selected.length > 1 ? `selected files (${selected.length})` : `"${selected[0]?.name ?? "file"}${selected[0]?.type ? `.${selected[0].type}` : ''}"`}`}
    //             </MenuItem>
    //         </Menu>
    //     </Alignment>
    // );
});

export { FileExplorerItemContainer };