import { memo } from 'react';
import { Alignment } from '@imas/components/layout';
import { ClientLocationContactForm as TClientLocationContactForm } from '@imas/api/client';
import { Typography } from '@mui/material';
import { FormNumberSelect,  useMuiFormControl, useMuiWatch, useMuiFormContext } from '@imas/mui-form';

export const ClientLocationContactPreferences = memo(() => {

    const control = useMuiFormControl<TClientLocationContactForm>();
    //value of correspondace Option field
    const correspondanceOptions = [{label:"All", value:0}, {label:"PC Reports", value: 1}, {label: "Final Reports", value: 2}];
    //value of Signiture method Option field
    const signitureMethodOptions = [{label:"Either", value:0}, {label:"Email Link", value: 1}, {label: "Email PDF", value: 2}];

    return(
        <Alignment>
        <Typography variant={"h6"} color={"textPrimary"}sx={{ marginBottom: '5px', fontWeight: 'bold' }}>{"Preferences"}</Typography>
        <Alignment row >
            {/*Reporting Field */}
            <FormNumberSelect
            name={"correspondencetype"}
            control={control}
            label={"Reporting"}
            options={(option) => (correspondanceOptions ?? []).map(correspondance => option(correspondance.value, correspondance.label))}
            sx={{minWidth: '200px'}}
            required
            />
            {/*Signature Method Field */}
            <FormNumberSelect
            name={"signaturemethodtype"}
            control={control}
            label={"Signature Method"}
            options={(option) => (signitureMethodOptions ?? []).map(sigtype => option(sigtype.value, sigtype.label))}
            sx={{minWidth: '200px',
                marginLeft: '10px'}}
            required

            />
        </Alignment>
    </Alignment>
    );
});