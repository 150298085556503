import { mapApi, ApiConfig } from '@imas/api';
import { ApproveTimeSheetRequest, ApproveTimeSheetResult, CreateTimeSheetRequest, EmployeeWorkHours, ServiceOrderEmployeeHour, TimeSheet, TimeSheetCertificationHour, TimeSheetDetail, TimeSheetForm, TimeSheetSummaryResult, vTimeSheetDetail } from './types';
import { Moment } from 'moment';

const TIME_SHEETS_BASE_URL = "/api/time-sheets";

/*  */
export const GetTimeSheetSummary = mapApi((fromDate: Moment | Date, toDate: Moment | Date, employeeId: int | null = null, managerId: int | null = null, includeDrOnly: boolean = false, includeManager: boolean = false, includeMissingOnly: boolean = false): ApiConfig<TimeSheetSummaryResult[]> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/summary-list`,
	params : {
		fromDate: fromDate instanceof Date ? fromDate : fromDate.toDate(),
		toDate: toDate instanceof Date ? toDate : toDate.toDate(),
		employeeId,
		managerId,
		includeDrOnly,
		includeManager,
		includeMissingOnly,
	},
}));

/*  */
export const GetTimeSheet = mapApi((timeSheetId: int): ApiConfig<TimeSheet> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}`,
}));

/*  */
export const GetTimeSheetSupportingDocument = mapApi((timeSheetId: int): ApiConfig<File> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/supporting-document`,
	returnAs: 'File',
}));

/*  */
export const GetTimeSheetDetails = mapApi((timeSheetId: int): ApiConfig<TimeSheetDetail[]> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/details`,
}));

/*  */
export const GetTimeSheetDetailsView = mapApi((timeSheetId: int): ApiConfig<vTimeSheetDetail[]> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/v-details`,
}));

/*  */
export const GetTimeSheetCertificationHours = mapApi((timeSheetId: int): ApiConfig<TimeSheetCertificationHour[]> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/certification-hours`,
}));

/*  */
export const GetTimeSheetServiceOrderEmployeeHours = mapApi((timeSheetId: int): ApiConfig<ServiceOrderEmployeeHour[]> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/service-order-employee-hours`,
}));

/*  */
export const GetTimeSheetEmployeeWorkHours = mapApi((timeSheetId: int): ApiConfig<EmployeeWorkHours> => ({
	method: 'get',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/employee-work-hours`,
}));

/*  */
export const CreateTimeSheet = mapApi((data: CreateTimeSheetRequest): ApiConfig<TimeSheet> => ({
	method: 'post',
	url: `${TIME_SHEETS_BASE_URL}`,
	data,
}));

/*  */
export const UpdateTimeSheet = mapApi((timeSheetId: int, data: TimeSheetForm): ApiConfig<TimeSheet> => ({
	method: 'put',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}`,
	data,
	encodeAs: 'FormData',
}));

/*  */
export const SubmitTimeSheet = mapApi((timeSheetId: int): ApiConfig<TimeSheet> => ({
	method: 'put',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/submit`,
}));

/*  */
export const UnSubmitTimeSheet = mapApi((timeSheetId: int): ApiConfig<TimeSheet> => ({
	method: 'put',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/un-submit`,
}));

/*  */
export const ApproveTimeSheet = mapApi((timeSheetId: int, data: ApproveTimeSheetRequest): ApiConfig<ApproveTimeSheetResult> => ({
	method: 'put',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/approve`,
	data,
}));


/*  */
export const LoadTimeSheetJobs = mapApi((timeSheetId: int): ApiConfig<TimeSheetDetail[]> => ({
	method: 'put',
	url: `${TIME_SHEETS_BASE_URL}/${timeSheetId}/load-jobs`,
}));