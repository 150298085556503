import { ServiceOrderSignatureRequest } from './../types/api/service-orders/service-order/ServiceOrderSignatureRequest';
import { objectToFormData, SourceGetter } from '@imas/api';
import axios from 'axios';
import { SOSignatureDetail, SOTrackingResult } from '../types/api/service-orders/types';
import vServiceOrderDetail from '../types/api/service-orders/vServiceOrderDetails';

/**
 * These apis do not require a login, so that clients can use them.
 */

const CLIENT_SIGNATURES_URL = '/api/client-signatures';

/**
 *  Get signature requests for a service order
 */
 export const GetSignatureRequests = (getSource: SourceGetter) => {
    return (uniqueid: int): Promise<ServiceOrderSignatureRequest[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderSignatureRequest[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: `${CLIENT_SIGNATURES_URL}/so/${uniqueid}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Send the email for an existing signature request
 */
 export const ResendSignatureRequest = (getSource: SourceGetter) => {
    return (signatureRequestId: int): Promise<boolean> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'post',
                url: `${CLIENT_SIGNATURES_URL}/resend/${signatureRequestId}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Send the email with pdf doc for an existing signature request
 */
export const ResendSignatureDocRequest = (getSource: SourceGetter) => {
    return (signatureRequestId: int): Promise<boolean> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'post',
                url: `${CLIENT_SIGNATURES_URL}/resend-pdf/${signatureRequestId}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Get the service order tracking result for given a signature's hashCode
 */
 export const GetSignatureTrackingResult = (getSource: SourceGetter) => {
    return (hashCode: string): Promise<SOTrackingResult | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<SOTrackingResult | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: `${CLIENT_SIGNATURES_URL}/tracking/${hashCode}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Get a signature request given the signature's hashCode
 */
export const GetSOSignatureDetails = (getSource: SourceGetter) => {
    return (hashCode: string): Promise<SOSignatureDetail[] | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<SOSignatureDetail[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: `${CLIENT_SIGNATURES_URL}/${hashCode}/details`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Get a signature request given the signature's hashCode
 */
 export const GetSOSignatureRequest = (getSource: SourceGetter) => {
    return (hashCode: string): Promise<ServiceOrderSignatureRequest | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderSignatureRequest | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: `${CLIENT_SIGNATURES_URL}/${hashCode}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Get all signed signature requests for a service order given a signature's hashCode
 */
 export const GetSignedSignatureRequests = (getSource: SourceGetter) => {
    return (hashCode: string): Promise<ServiceOrderSignatureRequest[] | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderSignatureRequest[] | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: `${CLIENT_SIGNATURES_URL}/signed/${hashCode}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Get all signed signature requests for a service order given a signature's hashCode
 */
 export const GetSignatureCharges = (getSource: SourceGetter) => {
    return (hashCode: string): Promise<vServiceOrderDetail[] | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<vServiceOrderDetail[] | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: `${CLIENT_SIGNATURES_URL}/charges/${hashCode}`,
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};

// Update the client signature given a uniqueid and a signature file
export const UpdateSignatureRequest = (getSource: SourceGetter) => {
    return  (signatureRequestId: int, sendCopy: boolean, file: File, ): Promise<boolean> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'put',
                url: `${CLIENT_SIGNATURES_URL}`,
				data: objectToFormData({ signatureRequestId:signatureRequestId, sendCopy:sendCopy, file: file }),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};