import React, { forwardRef } from 'react';
import { useMergeRefs } from 'use-callback-ref';
import { FieldValues, FieldPath } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { FormInputProps } from './types';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';

export interface FormPhoneNumberProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<string | null, TFieldValues, TName>,
    Omit<TextFieldProps, "name" | "defaultValue" | "error" | "value" | "onChange" | "onBlur" | "ref" | "select" | "SelectProps"> 
{
    disableErrorText?: boolean;
}

// Updated pattern for phone number in format (XXX) XXX-XXXX
const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;

const _FormPhoneNumber = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
        ...textFieldProps
    }: FormPhoneNumberProps<TFieldValues, TName>,
	extRef: React.ForwardedRef<HTMLInputElement>
) => {
    // use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
		context: { size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

	// merge refs
	const mergeRef = useMergeRefs([ref, extRef]);

    // Prevent non-numeric input
    const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };

    // Format phone number as (XXX) XXX-XXXX
    const formatPhoneNumber = (value: string) => {
        const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters
        const part1 = cleaned.slice(0, 3); // Area code
        const part2 = cleaned.slice(3, 6); // First 3 digits
        const part3 = cleaned.slice(6, 10); // Last 4 digits
        
        if (cleaned.length > 6) {
            return `(${part1}) ${part2}-${part3}`;
        } else if (cleaned.length > 3) {
            return `(${part1}) ${part2}`;
        } else if (cleaned.length > 0) {
            return `(${part1}`;
        }
        
        return "";
    };

    // Render loading state
    if (isLoading) return <FormFieldSkeleton {...textFieldProps} size={textFieldProps.size ?? size} />;

    // Render TextField 
    return (
        <TextField
            {...textFieldProps}
			size={textFieldProps.size ?? size}
            disabled={isViewOnly || textFieldProps.disabled}
            name={name}
            required={isRequired}
           
            value={value ?? ""}
            onChange={e => {
                const newValue = formatPhoneNumber(e.target.value.replace(/\D/g, "")); // Strip non-numeric characters
                onChange(newValue === "" ? null : newValue);
            }}
            onBlur={handleBlur}
            onKeyDown={handleInput}
            inputRef={mergeRef}
        />
    );
};

const FormPhoneNumber = forwardRef(_FormPhoneNumber);

export { FormPhoneNumber };
