import { EmployeeBasics } from '@imas/api/employee';
import { Alignment, Spacer } from '@imas/components/layout';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs, TypedGridFilterItem, TypedGridFilterModel } from '@imas/data-grid';
import { GridLogicOperator } from '@mui/x-data-grid-pro';
import { useDeviceInfo } from '@imas/styles';
import { XDialogTitle } from '@imas/components/mui';


export interface AddEmployeesDialogProps {
    open: boolean,
    employees: EmployeeBasics[] | undefined,
    onClose: () => void,
    onConfirm: (selected: int[]) => void,
}

export interface EmployeeBasicsRM extends EmployeeBasics {
    fullName: string,
}

//define the column order
export type EMPLOYEE_SELECTOR_GRID_COLUMNS = ["fullName"];

//define the grid api ref type
export type EMPLOYEE_SELECTOR_GRID_API_REF = TypedGridApiRef<EmployeeBasics, EmployeeBasicsRM, never, "row">;

//define grid props type
export type EMPLOYEE_SELECTOR_GRID_PROPS = TypedEditDataGridProps<EmployeeBasics, EmployeeBasicsRM, never, EMPLOYEE_SELECTOR_GRID_COLUMNS, "row">

//define the filter model type
export type EMPLOYEE_SELECTOR_GRID_FILTER_MODEL = TypedGridFilterModel<EmployeeBasics, EmployeeBasicsRM>;

//default filter model
export const DEFAULT_EMPLOYEE_SELECTOR_FILTER: EMPLOYEE_SELECTOR_GRID_FILTER_MODEL = { logicOperator: GridLogicOperator.And, items: [] };

export const EmployeeSelectorDialog = ({open, employees, onClose, onConfirm} : AddEmployeesDialogProps) => {

    //use deviceInfo
    const deviceInfo = useDeviceInfo();
    
    //grid apiRef
	const apiRef: EMPLOYEE_SELECTOR_GRID_API_REF = useTGridApiRef();

    //state variables
    const [selectionModel, setSelectionModel] = useState<int[]>([]);

    //filter and sort the employees
    const sortedEmployees = useMemo(() => {
        if (!employees) return;
        const filteredEmployees = employees.filter(x => x.active && x.needsTime);
        const sorted = filteredEmployees.sort((a,b) => a.first.localeCompare(b.first));
        return sorted;
    }, employees);

    //filter model
    const [filterModel, setFilterModel] = useState<EMPLOYEE_SELECTOR_GRID_FILTER_MODEL>(DEFAULT_EMPLOYEE_SELECTOR_FILTER);

	//memoized grid column defs
	const columns = useMemo((): TypedGridColumnDefs<EmployeeBasics, EmployeeBasicsRM, never, EMPLOYEE_SELECTOR_GRID_COLUMNS, "row"> => {
		return [
			{ field: 'fullName', headerName: 'Name', flex: 1, 
                valueGetter: ({ row }) => `${row.first} ${row.last}`
            },
			//{ field: 'description', headerName: 'Description', width: 200},
		];
	}, []);

    //get filter value for client
    const fullNameFilter = useMemo(() => {
        return filterModel.items.find((x): x is TypedGridFilterItem<EmployeeBasics, EmployeeBasicsRM, 'fullName'> => x.field === 'fullName' && x.operator === 'contains');
    }, [filterModel]);

    //close the dialog
	const handleClose = () => {
        onClose();
        setSelectionModel([]);
		apiRef.current.setFilterModel(DEFAULT_EMPLOYEE_SELECTOR_FILTER);
	};

    //excecutes onConfirm then handle close
	const handleConfirm = () => {
        onConfirm(selectionModel);
		handleClose();
	};

    return (

		<Dialog fullWidth={true} open={open} fullScreen={!deviceInfo.isMd} onClose={handleClose}>
            <XDialogTitle fullScreen onClose={handleClose}>{'Select Employee(s)'}</XDialogTitle>
            {/* Full Name Filter */}
			<TextField
				label={"Name"}
				value={fullNameFilter?.value ?? ""}
				onChange={(e) => {
					const value = e.target.value === "" ? null : e.target.value;
					if (value === null) { if (fullNameFilter) apiRef.current.deleteFilterItem(fullNameFilter); }
					else apiRef.current.upsertFilterItem({
						id: 1,
						field: 'fullName',
						operator: 'contains',
						...fullNameFilter,
						value,
					});
				}}
				size={"x-small"}
                sx={{margin: '10px'}}
			/>

            <Alignment column flex sx={{minHeight: '50vh'}}>
                <TDataGrid<EmployeeBasics, EmployeeBasicsRM, never, EMPLOYEE_SELECTOR_GRID_COLUMNS, "row">
			        apiRef={apiRef}
			        editMode={"row"}
                    checkboxSelection
                    keepNonExistentRowsSelected
                    selectionModel={selectionModel}
                    onRowSelectionModelChange={(selected) => {
                        let selectedRows = typeof selected === "number" ? [selected] : selected;

                        setSelectionModel(selectedRows);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => setFilterModel(model)}
			        disableColumnMenu
			        loading={!sortedEmployees}
			        rows={sortedEmployees ?? []}
			        columns={columns}
                    sx={{border: 'none'}}
			    />
			</Alignment>
            <DialogActions>
              <Button onClick={handleClose}>{'Cancel'}</Button>
              <Button onClick={handleConfirm}>{'Confirm'}</Button>
            </DialogActions>
        </Dialog>
    );
};