import { SourceGetter } from '@imas/api';
import axios from 'axios';
import { ServiceOrderProcedure } from '../types/api/service-orders/types';

/**
 *  Get an array of service order procedures from a service order (uniqueid)
 */
export const GetServiceOrderProcedures = (getSource: SourceGetter) => {
    return (uniqueId: int): Promise<ServiceOrderProcedure[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderProcedure[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/procedures/' + uniqueId.toString(),
                cancelToken: source.token
            }).then(result => {
                //resolve promise
                resolve(result.data);
            }).catch(e => reject(e));
        });
    };
};