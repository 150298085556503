import axios from 'axios';

//get a google maps link given the address
export const getMapsLink = (address: string) => {
    //create the url
    return axios.getUri({
        url: "https://www.google.com/maps/search/",
        params: {
            api: 1,
            query: address
        }
    });
};


//get a google maps link given the address
export const getPoi = (from: string, to: string) => {
    //create the url
    return axios.getUri({
        url: "https://www.google.com/maps/dir/",
        params: {
            api: 1,
            origin: from,
            destination: to
        }
    });
};