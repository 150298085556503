import React from 'react';
import * as MuiGrid from "@mui/x-data-grid-pro";
import { TypedGridComponentProps, TGEM, TGDM, TGRM, TGRM_Key, TypedGridColumns, TypedGridColumnDefs, TypedGridApiRef, TypedGridFilterOperator, TypedGridApi } from './types';
import { PatchedDataGrid } from '@imas/data-grid' ;
import AutoSizer from 'react-virtualized-auto-sizer';
import { Alignment } from '@imas/components/layout';

//re-export typings
export * from './types';

export const useTDataGrid = <
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>,
    COLS extends TypedGridColumns<RM>,
    Mode extends TGEM = "cell",
    COL_DEFS extends TypedGridColumnDefs<DM, RM, E, COLS, Mode> = TypedGridColumnDefs<DM, RM, E, COLS, Mode>
>(props: TypedGridComponentProps<Mode, DM, RM, E, COLS, COL_DEFS>): MuiGrid.DataGridProProps => {
    //return the props
    return props as MuiGrid.DataGridProProps;
};

export const TDataGrid = <
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>,
    COLS extends TypedGridColumns<RM>,
    Mode extends TGEM = "cell",
    COL_DEFS extends TypedGridColumnDefs<DM, RM, E, COLS, Mode> = TypedGridColumnDefs<DM, RM, E, COLS, Mode>
>(props: TypedGridComponentProps<Mode, DM, RM, E, COLS, COL_DEFS>): JSX.Element => {

    //Autosizer not needed with autoheight
    if (props.autoHeight || props.disableAutosizer) return (
        <PatchedDataGrid 
            components={{ 
                ColumnHeaderFilterIconButton:(params) => <Alignment/>
            }}
            {...(props as MuiGrid.DataGridProProps)} 
        />
    );

    
    //render grid with props
    return (
        <AutoSizer>
			{({ width, height }) => (
                <div style={{height: height, width: width}}>
                    <PatchedDataGrid 
                        components={{ 
                            ColumnHeaderFilterIconButton:(params) => <Alignment/>
                        }}
                        {...(props as MuiGrid.DataGridProProps)} 
                    />
                </div>
                )}
		</AutoSizer>
    );
};

export const useTGridApiRef = <DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>,Mode extends TGEM = "cell">() => {
    return MuiGrid.useGridApiRef() as unknown as React.MutableRefObject<TypedGridApi<DM, RM, E, Mode>>;
};

/* export const getTGridNumericColumnOperators = <
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>,
    K extends TGRM_Key<RM>,
    Mode extends TGEM = "cell",
>(apiRef: TypedGridApiRef<DM, RM, E, Mode>): TypedGridFilterOperator<DM, RM, E, K, Mode>[] => {
    return MuiGrid.getGridNumericOperators().map(x => ({
        ...x,
        getApplyFilterFn: (filterItem: MuiGrid.GridFilterItem, column: MuiGrid.GridColDef) => {
            const applyFilter = x.getApplyFilterFn(filterItem, column);
            if (applyFilter === null) return null;

            //get data model
            const editing = Object.keys(apiRef.current.getEditRowsModel()).map(Number);

            console.log(editing);

            return (params: MuiGrid.GridCellParams) => {
                if (editing.includes(params.id as number)) return true;

                return applyFilter(params);
            };
        }
    })) as any;
}; */