import { axiosData, SourceGetter } from "@imas/api";
import { ExpenseItemCode } from './types';

//states base url
const EXPENSE_ITEM_CODES_URL = "api/system/expense-item-codes";

/**  */
export const GetExpenseItemCodes = (getSource: SourceGetter) => {
    return async (): Promise<ExpenseItemCode[]> => {
        return await axiosData({
            method: 'get',
            url: `${EXPENSE_ITEM_CODES_URL}`,
            cancelToken: getSource().token,
        });
    };
};

export const UpdateExpenseItemCodes = (getSource: SourceGetter) => {
    return async (ExpenseCode: ExpenseItemCode): Promise<ExpenseItemCode> => {
        return await axiosData({
            method: 'put',
            url: `${EXPENSE_ITEM_CODES_URL}`,
            data: ExpenseCode,
            cancelToken: getSource().token,
        });
    } ;
};

export const CreateExpenseItemCodes = (getSource: SourceGetter) => {
    return async (ExpenseCode: ExpenseItemCode): Promise<ExpenseItemCode> => {
        return await axiosData({
            method: 'post',
            url: `${EXPENSE_ITEM_CODES_URL}`,
            data: ExpenseCode,
            cancelToken: getSource().token,
        });
    } ;
};

export const DeleteExpenseItemCodes = (getSource: SourceGetter) => {
    return async (id: int): Promise<void> => {
        return await axiosData({
            method: 'delete',
            url: `${EXPENSE_ITEM_CODES_URL}/${id}`,
            cancelToken: getSource().token,
        });
    };
};