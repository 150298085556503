import { Alignment } from '@imas/components/layout';
import { Box } from '@mui/material';
import { memo } from 'react';

export const WorkPlaceReviewFooter = memo(() => {
	return (
		<Alignment column sx={{ marginTop: '20px' }}>
			{/* Divider */}
			<Box sx={{ width: '-webkit-fill-available', bgcolor: "text.primary", height: '3px', marginTop: '5px' }}/>
		</Alignment>
	);
});