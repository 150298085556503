import { useLoadApi } from '@imas/api';
import { useClientIdNames, useMinifiedProposals } from '@imas/api/data-hooks';
import { GetHourTypes, GetSiteWorkClasses } from '@imas/api/system';
import { TimeSheet } from '@imas/api/time-sheet';
import { TypedGridColumnDefs } from '@imas/data-grid';
import { useUser } from '@imas/utils/user';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { useEffect, useMemo } from 'react';
import { HourTotalCell, NotesCell, ServiceOrderDateRange, ServiceOrderDateRangeEditable, ShiftDiffCell, ShiftDiffHeader, TimeSheetHourHeadFooterOverlay } from './CellsAndHeaders';
import { TimeSheetDetailEditorPanelToggle } from './Components';
import { ClientEditCell, DescriptionEditCell, HourTypeEditCell, NotesEditCell, ProposalEditCell, ServiceOrderEditCell, ShiftDiffEditCell, WorkClassEditCell } from './EditCells';
import { TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TIME_SHEET_DETAILS_EDIT_GRID_COLUMNS, TIME_SHEET_DETAILS_EDIT_GRID_API_REF } from './types';
import { getDailyNotes, getWeekRecordTotalHours, TIME_SHEET_DETAILS_COL_WIDTHS } from './utils';
import moment from 'moment';
import { HOUR_COL_DEF } from './columnTypes';
import { makeStyles } from '@imas/styles';
import { GridActionsCellItem, GridLogicOperator } from '@mui/x-data-grid-pro';
import { OpenInNew as OpenInNewIcon} from '@mui/icons-material';
import axios from 'axios';

const useStyles = makeStyles()((theme) => ({
	visible: {
		'> .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer': {
			overflow: 'visible',
		},
	},
}));

interface UseTimeSheetDetailsFormColDefsProps {
	timeSheet?: TimeSheet;
	managementMode: boolean;
	api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF;
};

export const useTimeSheetDetailsFormColDefs = ({ timeSheet, managementMode }: UseTimeSheetDetailsFormColDefsProps) => {
	const { classes } = useStyles();
	
	const user = useUser();

	const [clients] = useClientIdNames();
	const { data: hourTypesList } = useLoadApi(GetHourTypes, [], []);
	const { data: workClasses } = useLoadApi(GetSiteWorkClasses, [user.sitenum], [user.sitenum], {  });
	const [proposals] = useMinifiedProposals();

	const hourTypes = useMemo(() => {
		if (!hourTypesList) return;
		if (!timeSheet) return hourTypesList;
		//console.log(hourTypesList);
		//console.log(timeSheet);
		return hourTypesList.filter(x => x.discontinuedDate === null || (moment(x.discontinuedDate) > moment(timeSheet.weekOf)));
	}, [hourTypesList, timeSheet]);

	const columns = useMemo((): TypedGridColumnDefs<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TIME_SHEET_DETAILS_EDIT_GRID_COLUMNS, "row"> => {
		return [
			{ 
				editable: true, 
				field: 'client', 
				headerName: 'Client', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.CLIENT,
				type: 'string',
				renderEditCell: (props) => <ClientEditCell {...props} clients={clients}/>,
			},
			{ 
				editable: true, 
				field: 'pronum', 
				headerName: 'Job No.', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.PRONUM,
				renderEditCell: (props) => <ProposalEditCell {...props} proposals={proposals}/>,
			},
			{ 
				editable: true, 
				field: 'description', 
				headerName: 'Description/Job Name', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.DESCRIPTION,
				renderEditCell: (props) => <DescriptionEditCell {...props}/>,
			},
			{ 
				editable: true, 
				field: 'hourType', 
				headerName: 'Type', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.HOUR_TYPE,
				type: 'singleSelect',
				valueOptions: (hourTypes ?? []).map(x => ({ label: x.name, value: x.id })),
				valueFormatter: ({ value }) => hourTypes?.find(x => x.id === value)?.name ?? '',
				renderEditCell: (props) => <HourTypeEditCell {...props} hourTypes={hourTypes}/>,
			},
			{
				field: 'actions',
				type: 'actions',
				getActions: ({ id, row }) => ([
					<GridActionsCellItem onClick={() => {
						let windowProxy = window.open( axios.getUri({ url: `/service-orders/details/${row.uniqueId}`}), undefined, "popup=0" );
                        if ( windowProxy !== null) {
                            windowProxy.isIMASPopup = false;
                            windowProxy.opener = windowProxy;
                        }
					}} icon={<OpenInNewIcon/>} label={"Add"} color={"inherit"} disabled={row.uniqueId === null}/>
				]),
				width: TIME_SHEET_DETAILS_COL_WIDTHS.ACTIONS,
			},
			{ 
				editable: true, 
				field: 'serviceOrderId', 
				headerName: 'S.O.', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.SERVICE_ORDER_ID,
				renderEditCell: (props) => <ServiceOrderEditCell {...props}/>,
			},
			{ 
				editable: true, 
				field: 'uniqueId',
				headerName: 'Unique Id', 
				width: 0, 
			},
			{ 
				editable: true, 
				field: 'shiftDiff',
				headerName: 'Shift Diff', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.SHIFT_DIFF,
				type: 'boolean',
				renderCell: (params) => <ShiftDiffCell {...params}/>,
				renderHeader: (params) => <ShiftDiffHeader/>,
				renderEditCell: (props) => <ShiftDiffEditCell {...props}/>,
			},
			{
				field: 'soWeekday', 
				headerName: 'Date Range',
				width: TIME_SHEET_DETAILS_COL_WIDTHS.SO_WEEKDAY,
				renderCell: ({ row }) => <ServiceOrderDateRange startDate={row.startDate} endDate={row.endDate}/>,
				renderEditCell: (props) => <ServiceOrderDateRangeEditable {...props}/>,
				valueGetter: ({ row: { startDate } }) => startDate ? moment(startDate).toDate() : null,
				sortComparator: (v1, v2) =>  (v1?.valueOf() ?? 0) - (v2?.valueOf() ?? 0),
			},
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'sat', renderHeader: () => <TimeSheetHourHeadFooterOverlay/>, headerClassName: classes.visible },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'sun' },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'mon' },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'tue' },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'wed' },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'thu' },
			{ ...(HOUR_COL_DEF as any), editable: true, field: 'fri' },
			{ 
				editable: false, 
				field: 'hourTotal', 
				headerName: 'Total:', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.HOUR_TOAL,
				valueGetter: ({ row }) => getWeekRecordTotalHours(row),
				valueFormatter: ({ value }) => value === undefined ? null : value.toFixed(2),
				renderCell: (props) => <HourTotalCell {...props}/>,
			},
			{ 
				...(GRID_DETAIL_PANEL_TOGGLE_COL_DEF as any), 
				renderCell: (params) => <TimeSheetDetailEditorPanelToggle {...params}/>,
			},
			{ 
				editable: true, 
				field: 'workClass', 
				headerName: 'Class', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.WORK_CLASS,
				renderEditCell: (props) => <WorkClassEditCell {...props} workClasses={workClasses}/>,
			},
			{ 
				editable: true, 
				field: 'notes', 
				headerName: 'Notes', 
				width: TIME_SHEET_DETAILS_COL_WIDTHS.NOTES,
				flex: 1,
				renderCell: (props) => <NotesCell {...props}/>,
				renderEditCell: (props) => <NotesEditCell {...props}/>,
			},
			{
				editable: true, 
				field: 'dailyNotes',
				//hide: true,
				valueGetter: ({ row }) => getDailyNotes(row),
			}
		];
	}, [managementMode, timeSheet, clients, hourTypes, workClasses, proposals]);

	return { columns };
};