import { useApi, useLoadApi } from '@imas/api';
import { EditDataGrid, TypedGridColumnDefs, useTGridApiRef } from '@imas/data-grid';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { CreateTimeSheetDetail, UpdateTimeSheetDetail, DeleteTimeSheetDetail, GetTimeSheetServiceOrderEmployeeHours, GetTimeSheetEmployeeWorkHours } from '@imas/api/time-sheet';
import { 
	useTimeSheetDetailsFormColDefs,
	TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_COLUMNS, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 
	TIME_SHEET_DETAILS_EDIT_GRID_API_REF, TIME_SHEET_DETAILS_EDIT_GRID_PROPS, 
	TimeSheetDetailEditorPanelContent,
	TIME_SHEET_DETAIL_EDITOR_PANEL_CONTENT_HEIGHT,
	ITimeSheetDetailsFormContext,
	TimeSheetDetailsFormContext,
} from './TimeSheetDetailsFormUtils';
import { useTimeSheet, useTimeSheetCertificationHours, useTimeSheetDetails, useTimeSheetDetailsView } from '@imas/api/data-hooks';
import { makeStyles } from '@imas/styles';
import { Alignment } from '@imas/components/layout';
import { roundFloat } from '@imas/utils/math';
import { FILLER_CHR_VALUES as FILLER_VALUES, useAutoFillHourType, weekdayList } from './TimeSheetDetailsFormUtils/utils';
import Enumerable from 'linq';
import useSize from '@react-hook/size';
import moment from 'moment';
import { HOUR_TYPES, TIME_SHEET_CHANGEOVER_CUTOFF_DATE } from '@imas/constants';
import { GetUserWorkClass } from '@imas/api/user';

const useStyles = makeStyles()((theme) => ({
	visible: {
		overflow: 'visible',
	},
	absolute: {
		position: 'absolute',
	},
	visibleImportant: {
		overflow: 'visible!important',
	},
	container: {
		overflow: 'hidden',
	},
	gridContainer: {
		//maxHeight: '50vh',
	},
	gridBorder: {
		marginTop: '1px',
		borderBottom: `1px solid ${theme.palette.grid.border}`,
	},
	headerPadding: {
		height: '44px',
	},
	footerPadding: {
		height: '84px',
	},
	detailPanel: {
		overflow: 'hidden',
	},
}));

export interface TimeSheetDetailsFormProps {
	/** ID of the Time Sheet to show Details for.  */
	timeSheetId: int;
	/** If Grid column headers should be hidden. */
	hideHeaders?: boolean;
	/** If the grid should be shown in management mode. */
	managementMode?: boolean;
};

export const TimeSheetDetailsForm = memo(({ timeSheetId, hideHeaders, managementMode }: TimeSheetDetailsFormProps) => {
	const { classes } = useStyles();
	
	//propsRef
	const timeSheetIdRef = useRef(timeSheetId);
	timeSheetIdRef.current = timeSheetId;

	//open detail pane
	const [openDetailId, setOpenDetailId] = useState<int | null>(null);
	const openDetailIdRef = useRef(openDetailId);
	openDetailIdRef.current = openDetailId;

	//get user hour eligibility
	const getAutoFillHourType = useAutoFillHourType();

	//currently focused SO
	const [soUniqueId, setSoUniqueId] = useState<int | null>(null);

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

	//get TimeSheet record
	const [timeSheet] = useTimeSheet(timeSheetId);

	const { data: workClass } = useLoadApi(GetUserWorkClass, [], []);

	//get TimeSheetDetails for specified TimeSheet
	const [details, { call: refreshRows }] = useTimeSheetDetailsView(timeSheetId, { 
		mutate: (result) => {
			return Enumerable.from(result)
				.orderByDescending(x => x.pronum)
				.thenBy(x => x.serviceOrderId)
				.toArray();
		},
	});

	//get TimeSheetCertificationHours for specified TimeSheet
	const [certificationHours] = useTimeSheetCertificationHours(timeSheetId);	
	const { data: soEmployeeHours } = useLoadApi(GetTimeSheetServiceOrderEmployeeHours, [timeSheetId], [timeSheetId]);

	//determine if the time sheet is editable
	const isEditable = useMemo(() => {
		if (!timeSheet) return false;

		if (managementMode) return !timeSheet.locked && !timeSheet.apprDate;
		
		return !timeSheet.locked && !timeSheet.apprDate && !timeSheet.submitDate;
	}, [timeSheet, managementMode]);

	//determine if time sheet is legacy
	const isLegacy = useMemo(() => {
		console.log(timeSheet ? moment(timeSheet.weekOf) : null);
		return !!timeSheet && moment(timeSheet.weekOf).isBefore(TIME_SHEET_CHANGEOVER_CUTOFF_DATE, 'day');
	}, [timeSheet]);

	//add certification hours to details
	const detailsWithCertHours = useMemo(() => {
		if (!details) return;
		if (!certificationHours) return;

		return details.map<TimeSheetDetailDM>(detail => ({
			...detail,
			certificationHours: (certificationHours ?? []).filter(certHour => certHour.timeSheetDetailId === detail.id),
		}));
	}, [details, certificationHours]);

	//APIs
	const createDetail = useApi(CreateTimeSheetDetail);
	const updateDetail = useApi(UpdateTimeSheetDetail);
	const deleteDetail = useApi(DeleteTimeSheetDetail);
	const getUserWorkClass = useApi(GetUserWorkClass);

	//grid apiRef
	const apiRef: TIME_SHEET_DETAILS_EDIT_GRID_API_REF = useTGridApiRef();

	//grid column defs
    const { columns } = useTimeSheetDetailsFormColDefs({ 
		timeSheet,
		managementMode: managementMode ?? false,
		api: apiRef,
	});

	/* const columns = useMemo((): TypedGridColumnDefs<TimeSheetDetailDM, TimeSheetDetailRM, "client", ["client"], "row"> => {
		return [
			{
				field: 'client',
				editable: true,
			}
		];
	}, []); */

	//grid filter model
	// const [filterModel, setFilterModel] = useState<TIME_SHEET_DETAILS_EDIT_GRID_SORT_MODEL>({
	// 	items: [
	// 		{ id: 0, field: 'soWeekday', operator: ''  }
	// 	],
	// 	logicOperator: GridLogicOperator.And,
	// });
	


	//validator
	const validator = useCallback<TIME_SHEET_DETAILS_EDIT_GRID_PROPS["rowValidator"]>(async (row, x) => {
		if (row.hourType === undefined) throw new Error("Hour Type is required.");
		if (row.workClass === undefined || row.workClass === null) throw new Error("Work Class is required.");

		console.log(row);
		console.log(row.uniqueId);
		return {
			client: row.client,
			pronum: row.pronum ?? null,
			description: row.description,
			hourType: row.hourType,
			uniqueId: row.uniqueId ?? null,
			serviceOrderId: row.serviceOrderId ?? null,
			shiftDiff: row.shiftDiff ?? false,
			mon: typeof row.mon === 'number' ? roundFloat(row.mon, 0.25) : null,
			tue: typeof row.tue === 'number' ? roundFloat(row.tue, 0.25) : null,
			wed: typeof row.wed === 'number' ? roundFloat(row.wed, 0.25) : null,
			thu: typeof row.thu === 'number' ? roundFloat(row.thu, 0.25) : null,
			fri: typeof row.fri === 'number' ? roundFloat(row.fri, 0.25) : null,
			sat: typeof row.sat === 'number' ? roundFloat(row.sat, 0.25) : null,
			sun: typeof row.sun === 'number' ? roundFloat(row.sun, 0.25) : null,
			monNotes: row.dailyNotes?.mon ?? '',
			tueNotes: row.dailyNotes?.tue ?? '',
			wedNotes: row.dailyNotes?.wed ?? '',
			thuNotes: row.dailyNotes?.thu ?? '',
			friNotes: row.dailyNotes?.fri ?? '',
			satNotes: row.dailyNotes?.sat ?? '',
			sunNotes: row.dailyNotes?.sun ?? '',
			workClass: row.workClass,
			notes: row.notes ?? '',
			certificationHours: [],
		};
	}, []);
	
	//row create handler
	const onCreate = useCallback<TIME_SHEET_DETAILS_EDIT_GRID_PROPS["onRowCreate"]>(async (row, existingDetail) => {
		//get current timeSheetId
		const timeSheetId = timeSheetIdRef.current;

		//create the record
		return { ...(await createDetail({ timeSheetId, ...row, billable: null })), certificationHours: existingDetail?.certificationHours ?? [] };
	}, [createDetail]);

	//row update handler
	const onUpdate = useCallback<TIME_SHEET_DETAILS_EDIT_GRID_PROPS["onRowUpdate"]>(async (id, row, { certificationHours }) => {
		//get current timeSheetId
		const timeSheetId = timeSheetIdRef.current;

		//if uniqueId is updating to be null close the detail editor if it is open for the current row
		if (row.uniqueId === null && openDetailIdRef.current === id) setOpenDetailId(null);

		//update the record
		return { ...(await updateDetail(id, { timeSheetId, ...row, billable: null })), certificationHours: certificationHours ?? [] };
	}, [updateDetail, setOpenDetailId]);
	
	//row delete handler
	const onDelete = useCallback<Exclude<TIME_SHEET_DETAILS_EDIT_GRID_PROPS["onRowDelete"], undefined>>(async (id, detail) => {
		//confirm the user wants to delete the phone number
		const deleteConfirmed  = await confirm({
			title: "Delete Confirmation",
			prompt: (
				<>
					<Typography variant={"body1"}>{`Are you sure you would like to delete the following row?`}</Typography><br/>
					<Alignment row>
						<Alignment column>
							<Typography variant={"body1"} sx={{ textAlign: 'left', fontWeight: 'bold' }}>Client</Typography>
							<Typography variant={"body1"} sx={{ textAlign: 'left' }}>{detail.client}</Typography>
						</Alignment>
						<Alignment column sx={{ width: '70px', paddingLeft: '20px '}}>
							<Typography variant={"body1"} sx={{ textAlign: 'left', fontWeight: 'bold' }}>Job No.</Typography>
							<Typography variant={"body1"} sx={{ textAlign: 'left' }}>{detail.pronum}</Typography>
						</Alignment>
						<Alignment column sx={{ paddingLeft: '20px '}}>
							<Typography variant={"body1"} sx={{ textAlign: 'left', fontWeight: 'bold' }}>Description/Job Name</Typography>
							<Typography variant={"body1"} sx={{ textAlign: 'left' }}>{detail.description}</Typography>
						</Alignment>
					</Alignment>
				</>
			),
			confirmText: "Delete",
			confirmIcon: <DeleteIcon/>
		});
		if (!deleteConfirmed) throw new Error();
		
		//delete the record
		await deleteDetail(id);
	}, [deleteDetail, confirm]);

	//handle onEditRowsModelChange event
	const handleEditRowsModelChange = useCallback<NonNullable<TIME_SHEET_DETAILS_EDIT_GRID_PROPS['onRowModesModelChange']>>((model) => {
		// TODO: FIX HERE
		// const uniqueId = model[(Object.keys(model)[0] ?? '') as any]?.uniqueId?.value ?? null;
		// setSoUniqueId(uniqueId);
	}, [setSoUniqueId]);

	//handle handleCellClick event for showing SO work hours when the grid is disabled
	const handleCellClick = useCallback<NonNullable<TIME_SHEET_DETAILS_EDIT_GRID_PROPS['onCellDoubleClick']>>(({ row }) => {
		if (isEditable) return;
		
		setSoUniqueId(row.uniqueId);
	}, [isEditable]);
	
	//handle onCellDoubleClick event for opening detail panel
	const handleCellDoubleClick = useCallback<NonNullable<TIME_SHEET_DETAILS_EDIT_GRID_PROPS['onCellDoubleClick']>>(({ row, field }) => {
		//check if row is an hour row
		if (!(weekdayList as string[]).includes(field)) return;
		
		//check if row is currently in edit mode
		if (apiRef.current.getRowMode(row.id) === 'edit') {
			//verify the uniqueId field is filled in
			if (row.uniqueId || apiRef.current.getRowWithUpdatedValues(row.id).uniqueId) {
				//save 
				apiRef.current.stopRowEditMode({id: row.id, ignoreModifications: false});
				
				//open detail
				setOpenDetailId(row.id);
			}
		}
	}, [setOpenDetailId]);

	const detailPanelExpandedRowIds = useMemo(() => openDetailId ? [openDetailId] : [], [openDetailId]);

	//handle onDetailPanelExpandedRowIdsChange event
	const handleDetailPanelExpandedRowIdsChange = useCallback<NonNullable<TIME_SHEET_DETAILS_EDIT_GRID_PROPS['onDetailPanelExpandedRowIdsChange']>>((openDetails) => {
		setOpenDetailId(openDetails.at(-1) ?? null);
	}, [setOpenDetailId]);

	//function for getting DetailPanelContent
	const getDetailPanelContent = useCallback<NonNullable<TIME_SHEET_DETAILS_EDIT_GRID_PROPS['getDetailPanelContent']>>((params) => {
		return (
			<TimeSheetDetailEditorPanelContent isEditable={isEditable} api={apiRef} {...params}/>
		);
	}, [isEditable, setOpenDetailId]);

	//function for getting DetailPanelContent height
	const getDetailPanelHeight = useCallback(() => TIME_SHEET_DETAIL_EDITOR_PANEL_CONTENT_HEIGHT, []);

	// grid size
	const gridContainerRef = useRef<HTMLDivElement>(null);
	const [_, gridHeight] = useSize(gridContainerRef);

	const createNewDetail = async () => {
		return await createDetail({ 
			timeSheetId: timeSheetId, 
			hourType: getAutoFillHourType('', '', null) ?? HOUR_TYPES['REGULAR'],
			workClass: (await getUserWorkClass()).workClass,
			client: '', 
			pronum: null, 
			description: '', 
			uniqueId: null, 
			shiftDiff: false, 
			serviceOrderId: null, 
			mon: null, tue: null, wed: null, thu: null, fri: null, 
			sat: null, sun: null, monNotes: null, tueNotes: null, wedNotes: null, thuNotes: null, friNotes: null, satNotes: null, sunNotes: null, 
			notes: '',
			billable: null,
		});
	};

	const context = useMemo<ITimeSheetDetailsFormContext>(() => ({
		timeSheetId,
		timeSheet,
		weekOf: timeSheet ? moment(timeSheet.weekOf) : null,
		isEditable,
		details,
		soEmployeeHours,
		gridHeight,
		soUniqueId,
		workClass,
		managementMode: managementMode ?? false,
		setSoUniqueId,
		getAutoFillHourType,
		addRow: createNewDetail,
		refreshRows,
		api: apiRef,
	}), [timeSheetId, timeSheet, isEditable, details, soEmployeeHours, gridHeight, soUniqueId, workClass, managementMode, getAutoFillHourType, createNewDetail, refreshRows]);

	return (
		
		<TimeSheetDetailsFormContext.Provider value={context}>
			<Alignment column flex>
				<Alignment column flex className={classes.container}>
					<Alignment className={classes.headerPadding}/>
					<Alignment column flex className={classes.gridContainer} ref={gridContainerRef}>
						{isLegacy ? (
							<Typography sx={{ margin: 'auto' }}>Historical Time Sheets Viewer is not currently available on IMAS web.</Typography> 
						) : (
							<EditDataGrid<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TIME_SHEET_DETAILS_EDIT_GRID_COLUMNS, "row">
								apiRef={apiRef}
								editMode={"row"}
								columnHeaderHeight={hideHeaders ? 0 : undefined}
								loading={!timeSheet || !detailsWithCertHours || !workClass}
								disabled={!isEditable}
								rows={detailsWithCertHours ??  []}
								columns={columns}
								columnVisibilityModel={{
									shiftDiff: managementMode ?? false,
									dailyNotes: false,
									uniqueId: false
								}}
								rowValidator={validator}
								//onRowModesModelChange={handleEditRowsModelChange}
								onRowCreate={onCreate}
								onRowUpdate={onUpdate}
								onRowDelete={onDelete}
								onCellClick={handleCellClick}
								onCellDoubleClick={handleCellDoubleClick}
								detailPanelExpandedRowIds={detailPanelExpandedRowIds}
								onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
								getDetailPanelContent={getDetailPanelContent}
								getDetailPanelHeight={getDetailPanelHeight}
								onRowEditStart={({ row }) => setSoUniqueId(row.uniqueId)}
								initialState={{
									sorting:{
										sortModel: [{ field: 'soWeekday', sort: 'asc'}]
									},
								}}
								sx={{ border: 'none' }}
								classes={{
									main: classes.visible,
									columnHeaders: classes.visible,
									detailPanel: classes.detailPanel,
									columnHeaderTitleContainer: classes.visibleImportant,
									virtualScrollerContent: classes.absolute,
								}} 
								localeText={{
									noRowsLabel: 'Time sheet empty.',
								}} 
								disablePersistentEditRow
								experimental_singleClickToEdit
								// experimental_disableCreateReorder
							/>
						)}
					</Alignment>
					<Alignment className={classes.gridBorder}/>
					<Alignment className={classes.footerPadding}/>
				</Alignment>
			</Alignment>
		</TimeSheetDetailsFormContext.Provider>
	);
});