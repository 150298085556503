import { Button } from '@mui/material';
import { snackbar } from '@imas/utils/snackbar';
import { ServiceWorkerMessageTypes } from '@imas/service-worker';

//update app function (uses service worker API with reload fallback)
export function updateIMAS(): void {
	//display a notification in the case that the service worker update functionality is not available
	const updateNotSupported = () => {
		snackbar.enqueue("Device does not support background updating, would you like to reload the page instead?", { 
			key: "reload-app", 
			variant: "info", 
			persist: true, 
			action: (key) => (
				<>
					<Button
						color={"inherit"}
						variant={"text"}
						onClick={async () => {
							try {
								//clear all caches
								const cacheNames = await caches.keys();
								for (let cacheName in cacheNames) await caches.delete(cacheName);

								//reload the page
								window.location.reload();
							} catch (e: any) { 
								console.log(`Error in Refresh: ${(e ?? "").toString()}`); 
							}
						}}
					>{"Reload"}</Button>
					<Button color={"inherit"} variant={"text"} onClick={() => snackbar.close(key)}>{"Later"}</Button>
				</>
			)}
		);
	};

	//check if the service worker is registered
	navigator.serviceWorker.getRegistration()
	.then(registration => {
		//call updateNotSupported if there is no service order registration
		if (registration === undefined) updateNotSupported();
		else {
			//show the updating progress notification & get key
			const key = snackbar.enqueue("Updating...", { variant: "info", persist: true });

			//update the current service worker
			registration.update()
			.then(() => {
				//post message to the active service worker
				registration.active?.postMessage({
					type: "install-update",
					data: null,
				} as ServiceWorkerMessageTypes);
			})
			.catch((e) => {
				//close progress notif
				snackbar.close(key);

				//show error
				snackbar.error(`Unable to update due to the following error: ${e.toString()}`);
			});
		}
	})
	.catch(() => updateNotSupported());
};