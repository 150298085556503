import { GetClient, UpdateClient, Client, ClientLocation, ClientLocationForm as TClientLocationForm } from '@imas/api/client';
import { GetCountries, GetStates } from '@imas/api/system';
import { useApi, useLoadApi } from '@imas/api';
import { Alignment } from '@imas/components/layout';
import { TextField, Typography, Divider, Button, Tooltip, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { FormTextField, FormAutocomplete, useMuiFormContext, useMuiWatch } from '@imas/mui-form';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { STATES_AUTOCOMPLETE_VIRTUALIZER, COUNTRY_AUTOCOMPLETE_VIRTUALIZER } from '@imas/utils/virtualization';
import { formatAddressHTML } from '@imas/utils/formatting';
import { LocationPreferences } from './LocationPreferences';
import { getMapsLink } from '@imas/utils/misc';
import { formatAddress } from '@imas/utils/formatting';
import MapIcon from '@mui/icons-material/Map';

import { memo, useMemo } from 'react';
import moment from 'moment';

interface LocationBusinessAndBillingProps {
	cLocation?: ClientLocation | null;
};

interface AddressFields { 
	address1: string | null;
	address2: string | null;
	address: string | null; 
	city: string | null; 
	state: string | null; 
	zip: string | null; 
	country: string | null; 
};

export const LocationBusinessAndBilling = memo(({ cLocation }: LocationBusinessAndBillingProps) => {
	//use form control
	const { isLoading, control, getValues, setValue } = useMuiFormContext<TClientLocationForm>();

	const [billingAddress1] = useMuiWatch({ control, name: ["billingAddress1"]});
	const [billingAddress2] = useMuiWatch({ control, name: ["billingAddress2"]});
	const [billingCity] = useMuiWatch({ control, name: ["billingCity"]});
	const [billingState] = useMuiWatch({ control, name: ["billingState"]});
	const [billingZip] = useMuiWatch({ control, name: ["billingZip"]});
	const [billingCountry] = useMuiWatch({ control, name: ["billingCountry"]});
	const [businessAddress1] = useMuiWatch({ control, name: ["businessAddress1"]});
	const [businessAddress2] = useMuiWatch({ control, name: ["businessAddress2"]});
	const [businessCity] = useMuiWatch({ control, name: ["businessCity"]});
	const [businessState] = useMuiWatch({ control, name: ["businessState"]});
	const [businessZip] = useMuiWatch({ control, name: ["businessZip"]});
	const [businessCountry] = useMuiWatch({ control, name: ["businessCountry"]});

	//automatic snackbart
	const showSnackbar = useAutomaticSnackbar();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

	//confirm if the user wants to export the address
	const confirmAddressExport = async (client: Client, newAddress: AddressFields): Promise<boolean> => {
		//confirm the user wants to export and overwrite the address (if the client already has one)
		if (client.address1 !== null || client.city !== null || client.state !== null || client.zip !== null || client.country !== null) {
			return confirm({ 
				title: "Confirm Corporate Address Change",
				prompt: (
					<>
						<Typography variant={"body1"}> 
							{"This client already has a corporate address:"}<br/><br/>
							{formatAddressHTML({...client})}<br/>
							{"Are you sure you want to overwrite it with the following address?"}<br/><br/>
							{formatAddressHTML({ ...newAddress })}
						</Typography>
					</>
				),
			});
		};

		//if there is no client address then return true as if the user confirmed the export
		return true;
	};

	//update billing/business address
	const updateAddress = (type: "billing" | "business", fields: AddressFields) => {
		//do update to fields
		setValue(`${type}Address`, fields.address1);
		setValue(`${type}Address1`, fields.address1);
		setValue(`${type}Address2`, fields.address2);
		setValue(`${type}City`, fields.city);
		setValue(`${type}State`, fields.state);
		setValue(`${type}Zip`, fields.zip);
		setValue(`${type}Country`, fields.country);
	};

	//get billing/business address
	const getAddress = (type: "billing" | "business"): AddressFields => {
		const values = getValues();

		return {
			address: values[`${type}Address`],
			address1: values[`${type}Address1`],
			address2: values[`${type}Address2`],
			city: values[`${type}City`],
			state: values[`${type}State`],
			zip: values[`${type}Zip`],
			country: values[`${type}Country`],
		};
	};

	//import client address to billing/business address
	const importClientAddressTo = async (type: "billing" | "business") => {
		if (clientId === null) return;

		//progress
		const close = showSnackbar("Importing address...", { variant: 'info', persist: true });

		try {
			//get client address info
			const { address, address1, address2, city, state, zip, country } = await getClient(clientId);

			//update billing/business address
			updateAddress(type, { address, address1, address2, city, state, zip, country });
		} finally {
			close();
		}
	};

	//update client record using billing/business address
	const exportClientAddressFrom = async (type: "billing" | "business") => {
		if (clientId === null) return;

		//progress
		const close = showSnackbar("Exporting address...", { variant: 'info', persist: true });

		try {
			//get client record
			const client = await getClient(clientId);

			//current billing/business address values
			const fields = getAddress(type);

			//check if the user has confirmed the address export, if they did not confirm then dont export
			const confirmed = await confirmAddressExport(client, fields);
			if (!confirmed) return close();

			//update client record with current values
			await updateClient(client.id, { ...client, revenueMMAsOfDate: client.revenueMMAsOfDate ? moment(client.revenueMMAsOfDate).toDate() : null, ...fields });
			
			//show success
			close();
			showSnackbar("Client updated.", { variant: 'success' });
		} catch(e) {
			throw close(e);
		}
	};

	//use APIs
	const getClient = useApi(GetClient);
	const updateClient = useApi(UpdateClient);

	//subscribe to updates
	const [clientId] = useMuiWatch({ control, name: ["clientId"] });

	//get states
	const { data: states } = useLoadApi(GetStates, [], []);

	//get countries
	const { data: countries } = useLoadApi(GetCountries, [], []);

	//sorts countries so USA is default first in list
	const sortedCountries = countries?.sort((a,b) => {
		if(a.countryAbbrev3 === 'USA')
		{
			return -1;} else if (b.countryAbbrev3 === 'USA')
		{
			return 1; } else {
			return a.countryAbbrev3.localeCompare(b.countryAbbrev3);
		}
	});

	const billAddTooLongHelperText: React.ReactNode = useMemo(() => {
		if(billingAddress1 !== null)
		{
			if(billingAddress1?.length > 30) return (
				"Length of Address is too long"
   
		   );

		}
		
		return undefined;

	}, [billingAddress1]);
	const busAddTooLongHelperText: React.ReactNode = useMemo(() => {
		if(businessAddress1 !== null)
		{
			if(businessAddress1?.length > 30) return (
				"Length of Address is too long"
   
		   );
		   return undefined;

		}


	}, [businessAddress1]);

	const bill2AddTooLongHelperText: React.ReactNode = useMemo(() => {
		if(billingAddress2 !== null)
		{
			if(billingAddress2?.length > 60) return (
				"Length of Suite/Other is too long"
   
		   );

		}
		
		return undefined;

	}, [billingAddress2]);

	const bus2AddTooLongHelperText: React.ReactNode = useMemo(() => {
		if(businessAddress2 !== null)
		{
			if(businessAddress2?.length > 60) return (
				"Length of Suite/Other is too long"
   
		   );

		}
		
		return undefined;

	}, [businessAddress2]);

	const billCityTooLongHelperText: React.ReactNode = useMemo(() => {
		if(billingCity !== null)
		{
			if(billingCity?.length > 30) return (
				"Length of City is too long"
   
		   );

		}
		
		return undefined;

	}, [billingCity]);

	const busCityTooLongHelperText: React.ReactNode = useMemo(() => {
		if(businessCity !== null)
		{
			if(businessCity?.length > 30) return (
				"Length of City is too long"
   
		   );

		}
		
		return undefined;

	}, [businessCity]);
	const billZipTooLongHelperText: React.ReactNode = useMemo(() => {
		if(billingZip !== null)
		{
			if(billingZip?.length > 10) return (
				"Length of Zip Code is too long"
   
		   );

		}
		
		return undefined;

	}, [billingZip]);
	const busZipTooLongHelperText: React.ReactNode = useMemo(() => {
		if(businessZip !== null)
		{
			if(businessZip?.length > 10) return (
				"Length of Zip Code is too long"
   
		   );

		}
		
		return undefined;

	}, [businessZip]);

	return (
		<Alignment row sx={{ color: 'text.primary', width: '-webkit-fill-available' }}>
			<Alignment column flex>
				{/* Billing Address/Info */}
				<Alignment row sx={{ marginBottom: '5px' }}>
					<Typography variant={"h6"} sx={{ fontWeight: 'bold' }}>{"Billing"}</Typography>

					{/* Import Corp Address (To Billing) */}
					<Tooltip title={"Replace Billing Address with Corporate Address"} arrow>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							startIcon={<ArrowDownward/>}
							disabled={clientId === null || isLoading}
							onClick={async () => await importClientAddressTo("billing")}
							sx={{ marginLeft: 'auto' }}
						>{"Corp"}</Button>
					</Tooltip>

					{/* Export Corp Address (From Billing) */}
					<Tooltip title={"Replace Corporate Address with Billing Address"} arrow>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							startIcon={<ArrowUpward/>}
							disabled={clientId === null || isLoading}
							onClick={async () => await exportClientAddressFrom("billing")}
							sx={{ marginLeft: '10px' }}
						>{"Corp"}</Button>
					</Tooltip>
				</Alignment>

				{/* Address */}
				<FormTextField
					control={control}
					name={"billingAddress1"}
					label={"Street Address"}
					rows={1}
					sx={{ marginBottom: '10px' }}
					FormHelperTextProps={{ sx: billAddTooLongHelperText ? { color: 'error.main' } : undefined}}
					helperText={billAddTooLongHelperText}
				/>
				<FormTextField
					control={control}
					name={"billingAddress2"}
					label={"Suite/Other"}
					rows={1}
					sx={{ marginBottom: '10px' }}
					FormHelperTextProps={{ sx: bill2AddTooLongHelperText ? { color: 'error.main' } : undefined}}
					helperText={bill2AddTooLongHelperText}
				/>


				<Alignment row sx={{ marginBottom: '10px' }}>
					{/* City */}
					<FormTextField
						control={control}
						name={"billingCity"}
						label={"City"}
						fullWidth
						sx={{ marginRight: '10px' }}
						FormHelperTextProps={{ sx: billCityTooLongHelperText ? { color: 'error.main' } : undefined}}
						helperText={billCityTooLongHelperText}
					/>

					{/* State */}
					<FormAutocomplete
						control={control}
						name={"billingState"}
						loading={!states}
						autoComplete
						autoHighlight
						options={states ?? []}
						getValue={(state, options) => options.find((x) => x.abbreviation === state)}
						onChange={(state, setValue) => {
							if (state) {
								//update country value
								setValue("billingCountry", state.countryAbbrev3);

								return state.abbreviation;
							}

							return null;
						}}
						getOptionLabel={(state) => state.abbreviation}
						renderInput={(params) => <TextField {...params} label={"State"}/>}
						sx={{ marginRight: '10px', minWidth: '100px' }}

						//props for consistent state autocomplete (adds virtualization)
						{...STATES_AUTOCOMPLETE_VIRTUALIZER}
					/>

					{/* Zip */}
					<FormTextField
						control={control}
						name={"billingZip"}
						label={"Zip"}
						sx={{ minWidth: '110px' }}
						FormHelperTextProps={{ sx: billZipTooLongHelperText ? { color: 'error.main' } : undefined}}
						helperText={billZipTooLongHelperText}
					/>
				</Alignment>
				<Alignment row>
				{/* Country */}
				<FormAutocomplete
						control={control}
						name={"billingCountry"}
						loading={!sortedCountries}
						autoComplete
						autoHighlight
						options={sortedCountries?? []}
						getValue={(country, options) => options.find((x) => x.countryAbbrev3 === country)}
						onChange={(country, setValue) => {
							if (country) {
								//update country value
								setValue("billingCountry", country?.countryAbbrev3);

								return country.countryAbbrev3;
							}

							return null;
						}}
						getOptionLabel={(country) => country.countryAbbrev3}
						renderInput={(params) => <TextField {...params} label={"Country"}/>}
                        sx={{ width: '435px', marginRight: '10px'}}

						{...COUNTRY_AUTOCOMPLETE_VIRTUALIZER}
					/>

				<Tooltip title={"Open Billing Address in new tab."} arrow>
						<IconButton
							disabled={billingAddress1 === null || billingCity === null || billingState === null || billingZip === null || billingCountry === null}
							size={"small"}
							onClick={() => window.open(getMapsLink(formatAddress({address1: billingAddress1, address2: billingAddress2,  city : billingCity, state: billingState, zip: billingZip, country: billingCountry})))}
							sx={{ width: '30px', height: '30px' }}
						>
						<MapIcon/>
						</IconButton>
					</Tooltip>
				</Alignment>
				
				<Divider sx={{ margin: 'auto 0' }}/>

				{/* Bill To */}
				<FormTextField
					control={control}
					name={"billingTo"}
					label={"Bill To"}
					sx={{ marginBottom: '10px' }}
				/>

				{/* Billing Attn */}
				<FormTextField
					control={control}
					name={"billingAttn"}
					label={"Billing Attn"}
					sx={{ marginBottom: '10px' }}
				/>

				{/* Billing Email */}
				<FormTextField
					control={control}
					name={"billingEmail"}
					label={"Billing Email"}
				/>
			</Alignment>

			{/* Copy Buttons */}
			<Alignment column>
				<Alignment column sx={{ margin: '40px 10px 0 10px'}}>
					{/* Copy Billing Address to Bussiness Address */}
					<Tooltip title={"Replace Business Address with Billing Address"} arrow>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							endIcon={<ArrowForward/>}
							disabled={isLoading}
							onClick={() => {
								//copy billing address -> business address
								updateAddress("business", getAddress("billing"));
							}}
							sx={{ marginBottom: '10px' }}
						>{"Copy"}</Button>
					</Tooltip>

					{/* Copy Bussiness Address to Billing Address */}
					<Tooltip title={"Replace Billing Address with Business Address"} arrow>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							startIcon={<ArrowBack/>}
							disabled={isLoading}
							onClick={() => {
								//copy business address -> billing address
								updateAddress("billing", getAddress("business"));
							}}
						>{"Copy"}</Button>
					</Tooltip>
				</Alignment>
			</Alignment>
			
			<Alignment column flex>
				{/*Business Address/Info */}
				<Alignment row sx={{ marginBottom: '5px' }}>
					<Typography variant={"h6"} sx={{ fontWeight: 'bold' }}>{"Business"}</Typography>

					{/* Import Corp Address (To Business) */}
					<Tooltip title={"Replace Business Address with Corporate Address"} arrow>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							startIcon={<ArrowDownward/>}
							disabled={clientId === null || isLoading}
							onClick={async () => await importClientAddressTo("business")}
							sx={{ marginLeft: 'auto' }}
						>{"Corp"}</Button>
					</Tooltip>

					{/* Export Corp Address (From Business) */}
					<Tooltip title={"Replace Corporate Address with Business Address"} arrow>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							startIcon={<ArrowUpward/>}
							disabled={clientId === null || isLoading}
							onClick={async () => await exportClientAddressFrom("business")}
							sx={{ marginLeft: '10px' }}
						>{"Corp"}</Button>
					</Tooltip>
				</Alignment>

				{/* Address */}
				<FormTextField
					control={control}
					name={"businessAddress1"}
					label={"Street Address"}
					rows={1}
					FormHelperTextProps={{ sx: busAddTooLongHelperText ? { color: 'error.main' } : undefined}}
					helperText={busAddTooLongHelperText}
					sx={{ marginBottom: '10px' }}
				/>
				<FormTextField
					control={control}
					name={"businessAddress2"}
					label={"Suite/Other"}
					rows={1}
					sx={{ marginBottom: '10px' }}
					FormHelperTextProps={{ sx: bus2AddTooLongHelperText ? { color: 'error.main' } : undefined}}
					helperText={bus2AddTooLongHelperText}
				/>

				<Alignment row sx={{ marginBottom: '10px' }}>
					{/* City */}
					<FormTextField
						control={control}
						name={"businessCity"}
						label={"City"}
						fullWidth
						sx={{ marginRight: '10px' }}
						FormHelperTextProps={{ sx: busCityTooLongHelperText ? { color: 'error.main' } : undefined}}
						helperText={busCityTooLongHelperText}
					/>

					{/* State */}
					<FormAutocomplete
						control={control}
						name={"businessState"}
						loading={!states}
						autoComplete
						autoHighlight
						options={states ?? []}
						getValue={(state, options) => options.find((x) => x.abbreviation === state)}
						onChange={(state, setValue) => {
							if (state) {
								//update country value
								setValue("businessCountry", state.countryAbbrev3);

								return state.abbreviation;
							}

							return null;
						}}
						getOptionLabel={(state) => state.abbreviation}
						renderInput={(params) => <TextField {...params} label={"State"}/>}
						sx={{ marginRight: '10px', minWidth: '100px' }}

						//addvirtualization
						{...STATES_AUTOCOMPLETE_VIRTUALIZER}
					/>

					{/* Zip */}
					<FormTextField
						control={control}
						name={"businessZip"}
						label={"Zip"}
						sx={{ minWidth: '110px' }}
						FormHelperTextProps={{ sx: busZipTooLongHelperText ? { color: 'error.main' } : undefined}}
						helperText={busZipTooLongHelperText}
					/>
				</Alignment>
				<Alignment row>
					<FormAutocomplete
						control={control}
						name={"businessCountry"}
						loading={!sortedCountries}
						autoComplete
						autoHighlight
						options={sortedCountries?? []}
						getValue={(country, options) => options.find((x) => x.countryAbbrev3 === country)}
						onChange={(country, setValue) => {
							if (country) {
								//update country value
								setValue("businessCountry", country?.countryAbbrev3);

								return country.countryAbbrev3;
							}

							return null;
						}}
						getOptionLabel={(country) => country.countryAbbrev3}
						renderInput={(params) => <TextField {...params} label={"Country"}/>}
                        sx={{ width: '435px', marginRight: '10px'}}

						{...COUNTRY_AUTOCOMPLETE_VIRTUALIZER}
					/>
					
					<Tooltip title={"Open Business Address in new tab."} arrow>
							<IconButton
								disabled={businessAddress1 === null || businessCity === null || businessState === null || businessZip === null || businessCountry === null}
								size={"small"}
								onClick={() => window.open(getMapsLink(formatAddress({address1: businessAddress1, address2: businessAddress2,  city : businessCity, state: businessState, zip: businessZip, country: businessCountry})))}
								sx={{ width: '30px', height: '30px' }}
							>
							<MapIcon/>
							</IconButton>
					</Tooltip>
				</Alignment>
				{/* Country */}
				
				

				{/* Location Preferences */}
				<LocationPreferences cLocation={cLocation}/>
			</Alignment>
		</Alignment>
	);
});
