import { makeStyles } from '@imas/styles';

const lightPoleMeasurementFormStyles = makeStyles()((theme) => ({
    imageContainer: {
        alignItems: 'flex-start',
    },
    centeredTextField: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    latLongLabel: {
        color: theme.palette.text.primary + '!important'
    }
}));

export default lightPoleMeasurementFormStyles;