import { SourceGetter, CacheResult } from '@imas/api';
import axios from 'axios';
import { Moment } from 'moment';
import { ScheduleEventEntry, OnCallScoreResult, EventDetailsResult, OnCallScheduleResult } from './types/api/schedule/schedule';
import { cacheDb, db } from '@imas/utils/storage';

/**
 * Get a list of daily schedule events given the date, site code, and schedule type.
 * @param {number | null} employeeId
 * @param {Moment} date 
 * @param {number | null} siteCode 
 * @param {number} scheduleType 
 */
export const GetDailyScheduledEvents = (getSource: SourceGetter) => {
    return (employeeId: number | null, date: Moment, siteCode: number | null, scheduleType: number | null): CacheResult<ScheduleEventEntry[]> => {
        //returns the correct schedule label based on employee id, and schedule type
        const entryType = employeeId === null ? scheduleType === null ? "all" : scheduleType === 0 ? "field" : "lab" : "me";

        //cache result promise
        const cache = cacheDb.getDailyScheduledEvents(date, entryType);

        //create api request source 
        const source = getSource();

        //api call result promise
        const api = new Promise<ScheduleEventEntry[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/schedule/events',
                params: {
                    employeeId: employeeId,
                    date: date.format('YYYY-MM-DD'),
                    siteCode: siteCode,
                    scheduleType: scheduleType
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);

                //cache the result
                cacheDb.addDailyScheduledEvents(date, entryType, response.data);
            }).catch(e => reject(e));
        });

        //return a function which can be used to cancel the request
        return { cache, api };
    };
};


/**
 * Get the details of a given daily schedule event given the event's ID and ItemTypeId.
 * @param {number} eventId 
 * @param {number} eventItemTypeId 
 * @param {Moment} date
 * @param {CallableFunction} resultCallback 
 */
export const GetDailyScheduledEventDetails = (getSource: SourceGetter) => {
    return (eventId: number, eventItemTypeId: number, date: Moment): Promise<EventDetailsResult> => {
        //result promise
        return new Promise<EventDetailsResult>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/schedule/event/details',
                params: {
                    "id": eventId,
                    "itemTypeId": eventItemTypeId,
                    "date": date.format('YYYY-MM-DD')
                },
                cancelToken: getSource().token,
            }).then((response) => {
                //resolve the promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};


/**
 * Get the on call schedule for a given date
 * @param {Moment} date
 * @param {CallableFunction} resultCallback 
 */
export const GetOnCallSchedule = (getSource: SourceGetter) => {
    return (date: Moment): CacheResult<OnCallScheduleResult[]> => {
        //cached result promise
        const cache = cacheDb.getOnCallSchedule(date);

        //api call result promise
        const api = new Promise<OnCallScheduleResult[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/schedule/on-call',
                params: {
                    "date": date.format()
                },
                cancelToken: getSource().token,
            }).then((response) => {
                //resolve promise
                resolve(response.data);

                //cache the result
                cacheDb.addOnCallSchedule(date, response.data);
            }).catch(e => reject(e));
        });

        //return a function which can be used to cancel the request
        return { cache, api };
    };
};

/**
 * Get the on call scores for a given weekend
 * @param {Moment} saturdayDate
 * @param {Moment} sundayDate
 * @param {number} employeeId
 * @param {CallableFunction} resultCallback 
 */
export const GetOnCallScores = (getSource: SourceGetter) => {
    return (saturdayDate: Moment, sundayDate: Moment, employeeId: number | null): CacheResult<OnCallScoreResult[] | null> => {
        //create request source 
        const source = getSource();

        //cache result promise
        const cache = db.get<OnCallScoreResult[]>("onCallScores", source.token);

        //api call result promise
        const api = new Promise<OnCallScoreResult[] | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/schedule/on-call/scores',
                params: {
                    "saturdayDate": saturdayDate.format('YYYY-MM-DD'),
                    "sundayDate": sundayDate.format('YYYY-MM-DD'),
                    "employeeId": employeeId
                },
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);

                //cache the result
                db.set("onCallScores", response.data);
            }).catch(e => reject(e));
        });

        //return a function which can be used to cancel the request
        return { cache, api };
    };
};