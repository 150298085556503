import { mapApi, ApiConfig } from '@imas/api';
import { 
	Certification
} from './types';

const CERTIFICATIONS_BASE_URL = "/api/certifications";

/*  */
export const GetCertifications = mapApi((): ApiConfig<Certification[]> => ({
	method: 'get',
	url: `${CERTIFICATIONS_BASE_URL}`,
}));
