import * as React$1 from 'react';
import * as MuiGrid from "@mui/x-data-grid-pro";
import { ClickAwayListenerProps, GrowProps, IconButtonProps, MenuItemProps, PopperProps, SxProps, Theme } from '@mui/material';
import { GridHeaderFilteringState } from '@mui/x-data-grid/models/gridHeaderFilteringModel';

/** TGDM stands for TypedGridDataModel */
export interface TGDM<T extends string | number = string | number> extends MuiGrid.GridRowModel {
    id: T;
};

export type TGDM_Key<DM extends TGDM> = Exclude<keyof DM, number | symbol>;
export type TGDM_Id<DM extends TGDM> = DM["id"];

/** TGRM stands for TypedGridRowModel */
export interface TGRM {
    [key: string]: any;
};

export type TGRM_Key<RM extends TGRM> = Exclude<keyof RM, number | symbol>;

export type GridUpdateAction = 'delete';
export type TypedGridRowModelUpdate<DM extends TGDM> = { 
    _action?: GridUpdateAction;
} & Partial<DM>;

export interface TypedGridRowTreeNodeConfig<DM extends TGDM> {
    id: TGDM_Key<DM>;
    children?: TGDM_Key<DM>[];
    parent: TGDM_Key<DM> | null;
    childrenExpanded?: boolean;
    depth: number;
    groupingKey: TGDM_Key<DM>;
    groupingField: TGDM_Key<DM> | null; 
    isAutoGenerated?: boolean;
}

export type TypedGridRowTreeConfig<DM extends TGDM> = Record<TGDM_Key<DM>, TypedGridRowTreeNodeConfig<DM>>;
export type TypedGridRowsLookup<DM extends TGDM> = Record<TGDM_Key<DM>, DM>;

export type TypedGridRowEntry<DM extends TGDM> = {
    id: TGDM_Id<DM>;
    model: DM;
};

export type TypedGridRowIdGetter<DM extends TGDM> = (row: DM) => TGDM_Id<DM>;

//declare function checkGridRowIdIsValid<R extends TGDM>(id: TGDM_Id<R>, row: R | Partial<R>, detailErrorMessage?: string): void;

export interface TypedGridEditCellProps<RM extends TGRM, K extends TGRM_Key<RM>> {
    value?: RM[K];
    [prop: string]: any;
}

export type TypedGridEditRowProps<RM extends TGRM, E extends TGRM_Key<RM>> = {
    [K in E]: TypedGridEditCellProps<RM, K>;
};

export type TypedGridEditRow<RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = Mode extends "cell" ? Partial<TypedGridEditRowProps<RM, E>> : TypedGridEditRowProps<RM, E> 

export type TypedGridEditRowsModel<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = Record<TGDM_Id<DM>, TypedGridEditRow<RM, E, Mode>>;
export type TypedGridRowModesModel<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = Record<TGDM_Id<DM>, TypedGridEditRow<RM, E, Mode>>;

export type TGEM = MuiGrid.GridEditMode;
export type TypedGridModes = MuiGrid.GridEditModes;

export interface TypedGridCellIndexCoordinates extends MuiGrid.GridCellIndexCoordinates {};

export interface TypedGridColumnHeaderIndexCoordinates extends MuiGrid.GridColumnHeaderIndexCoordinates {};

/** Types used to limit specified API methods to specified Editing Modes. */
export type TypedColumnModeOnly<Mode extends TGEM, T> = Mode extends "cell" ? T : never;
export type TypedRowModeOnly<Mode extends TGEM, T> = Mode extends "row" ? T : never;

export interface TypedGridColumnHeaderParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<MuiGrid.GridColumnHeaderParams, "field" | "colDef"> {
    field: K;
    colDef: TypedGridStateColDef<DM, RM, E, K, Mode>;
}

export type TypedGridCellClassFn<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = (params: TypedGridCellParams<DM, RM, E, K, Mode>) => string;

export type TypedGridCellClassNamePropType<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = string | TypedGridCellClassFn<DM, RM, E, K, Mode>;

export type TypedGridApiRef<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM
> = React$1.MutableRefObject<TypedGridApi<DM, RM, E, Mode>>;

export type TypedGridColumnHeaderClassFn<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = (params: TypedGridColumnHeaderParams<DM, RM, E, K, Mode>) => string;

export type TypedGridColumnHeaderClassNamePropType<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = string | TypedGridColumnHeaderClassFn<DM, RM, E, K, Mode>;

export interface TypedGridRowParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<MuiGrid.GridRowParams, "getValue" | "columns"> {
    id: TGDM_Id<DM>;
    row: DM;
    columns: TypedGridColumnsList<DM, RM, E, Mode>;
    getValue: <Field extends TGRM_Key<RM>>(id: TGDM_Id<DM>, field: Field) => RM[Field];
};

export interface TypedGridParamsApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> { // extends GridParamsApi {
    getCellValue: <K extends TGRM_Key<RM>>(id: TGDM_Id<DM>, field: K) => RM[K];
    getCellElement: <K extends TGRM_Key<RM>>(id: TGDM_Id<DM>, field: K) => HTMLDivElement | null;
    getCellParams: <K extends TGRM_Key<RM>>(id: TGDM_Id<DM>, field: K) => TypedGridCellParams<DM, RM, E, K, Mode>;
    getRowParams: (id: TGDM_Id<DM>) => TypedGridRowParams<DM, RM, E, Mode>;
    getRowElement: (id: TGDM_Id<DM>) => HTMLDivElement | null;
    getColumnHeaderElement: <K extends TGRM_Key<RM>>(field: K) => HTMLDivElement | null;
    getColumnHeaderParams: <K extends TGRM_Key<RM>>(field: K) => TypedGridColumnHeaderParams<DM, RM, E, K, Mode>;
};

export type TypedMuiBaseEvent = MuiGrid.MuiBaseEvent;
export type TypedMuiEvent<E extends MuiGrid.MuiBaseEvent = MuiGrid.MuiBaseEvent> = MuiGrid.MuiEvent<E>;

export interface TypedGridCallbackDetails<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    api?: TypedGridApi<DM, RM, E, Mode>;
};

//export type TypedGridEvents = MuiGrid.GridEvents;

export interface TypedGridColumnOrderChangeParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<MuiGrid.GridColumnOrderChangeParams, "field" | "colDef"> {
    field: K;
    colDef: TypedGridStateColDef<DM, RM, E, K, Mode>;
};

export interface TypedGridColumnResizeParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<MuiGrid.GridColumnResizeParams, "colDef"> {
    colDef: TypedGridStateColDef<DM, RM, E, K, Mode>;
};

export interface TypedGridEditCellPropsParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    id: TGDM_Id<DM>;
    field: K;
    props: TypedGridEditCellProps<RM, K>;
};

export interface TypedGridEditCellValueParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    field: K;
    value: RM[K] | null;
};

export interface TypedGridStopCellEditModeParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    field: K;
    ignoreModifications: boolean;
};

export interface TypedGridStartCellEditModeParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    field: K;
};

export interface TypedGridStopRowEditModeParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    ignoreModifications: boolean;
};

export interface TypedGridStartRowEditModeParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
};

/* export interface TypedGridCommitCellChangeParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    id: TGDM_Id<DM>;
    field: K;
};

export interface TypedGridCellEditCommitParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    id: TGDM_Id<DM>;
    field: K;
    value: RM[K];
}; */

export interface TypedGridRowScrollEndParams extends MuiGrid.GridRowScrollEndParams {
    //visibleColumns: TypedGridColumns;
};

export interface TypedGridRenderContext extends MuiGrid.GridRenderContext {};

export interface TypedGridScrollParams extends MuiGrid.GridScrollParams {
    renderContext?: TypedGridRenderContext;
};

export type TypedGridScrollFn = (v: TypedGridScrollParams) => void;

export interface TypedGridSortModelParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    sortModel: TypedGridSortModel<RM>;
    columns: TypedGridColumnsList<DM, RM, E, Mode>;
    api?: TypedGridApi<DM, RM, E, Mode>;
};

export type TypedGridColumnLookup<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = {
    [K in TGRM_Key<RM>]: K extends TGRM_Key<RM> ? TypedGridStateColDef<DM, RM, E, K, Mode> : never;
};

export interface TypedGridColumnsState<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    all: TGRM_Key<RM>[];
    lookup: TypedGridColumnLookup<DM, RM, E, Mode>;
    orderedFields: string[];
    columnVisibilityModel: TypedGridColumnVisibilityModel<DM>;
};

export type TypedGridColumnVisibilityModel<DM extends TGDM> = Record<TGDM_Id<DM>, boolean>;

export interface TypedGridColumnMenuState<RM extends TGRM> {
    open: boolean;
    field?: TGRM_Key<RM>;
};

export interface TypedGridColumnReorderState<RM extends TGRM>  {
    dragCol: TGRM_Key<RM>;
};

export interface TypedGridColumnResizeState<RM extends TGRM> {
    resizingColumnField: TGRM_Key<RM>;
};

export type TypedGridDensity = MuiGrid.GridDensity;

export interface TypedGridDensityState {
    value: TypedGridDensity;
    rowHeight: number;
    columnHeaderHeight: number;
};

export type TypedGridCellIdentifier<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> = {
    id: TGDM_Id<DM>;
    field: K;
};

export type TypedGridColumnIdentifier<RM extends TGRM, K extends TGRM_Key<RM>>  = {
    field: K;
};

export interface TypedGridFocusState<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    cell: TypedGridCellIdentifier<DM, RM, K> | null;
    columnHeader: TypedGridColumnIdentifier<RM, K> | null;
};

export interface TypedGridTabIndexState<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    cell: TypedGridCellIdentifier<DM, RM, K> | null;
    columnHeader: TypedGridColumnIdentifier<RM, K> | null;
};

export type TypedGridPreferencePanelsValue = MuiGrid.GridPreferencePanelsValue;

export interface TypedGridPreferencePanelState {
    open: boolean;
    openedPanelValue?: TypedGridPreferencePanelsValue;
};

export type TypedGridPreferencePanelInitialState = TypedGridPreferencePanelState;

export interface TypedGridRowGroupParams<DM extends TGDM> {
    ids: TGDM_Id<DM>[];
    idRowsLookup: TypedGridRowsLookup<DM>;
};

export interface TypedGridRowGroupingResult<DM extends TGDM>{
    groupingName: string;
    tree: TypedGridRowTreeConfig<DM>;
    treeDepth: number;
    ids: TGDM_Id<DM>[];
    idRowsLookup: TypedGridRowsLookup<DM>;
};

type TypedGridRowGroupingPreProcessing<DM extends TGDM> = (params: TypedGridRowGroupParams<DM>) => TypedGridRowGroupingResult<DM> | null;

export interface TypedGridRowGroupsPreProcessingApi<DM extends TGDM> {
    unstable_registerRowGroupsBuilder: (processingName: string, groupingFunction: TypedGridRowGroupingPreProcessing<DM> | null) => void;
    unstable_groupRows: (params: TypedGridRowGroupParams<DM>) => TypedGridRowGroupingResult<DM>;
};

export interface TypedGridRowsState extends MuiGrid.GridRowsState {};

//???
export type TypedGridVisibleRowsLookupState<DM extends TGDM> =  Record<TGDM_Id<DM>, boolean>;

export type TypedGridInputSelectionModel<DM extends TGDM> = TGDM_Id<DM>[] | TGDM_Id<DM>;

export type TypedGridSelectionModel<DM extends TGDM> = TGDM_Id<DM>[];

export interface TypedGridSortingState<DM extends TGDM, RM extends TGRM> {
    sortedRows: TGDM_Id<DM>[];
    sortModel: TypedGridSortModel<RM>;
};

export interface TypedGridSortingInitialState<RM extends TGRM> {
    sortModel?: TypedGridSortModel<RM>;
};

export interface TypedGridFilterItem<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM> = TGRM_Key<RM>> {
    id?: int | TGDM_Id<DM>;
    field: K;
    value?: RM[K];
    operator?: string;
};

export type TypedGridLogicOperator = MuiGrid.GridLogicOperator;

export interface TypedGridFilterInputValueProps<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> {
    item: TypedGridFilterItem<DM, RM, K>;
    applyValue: (value: TypedGridFilterItem<DM, RM, K>) => void;
    apiRef: TypedGridApiRef<DM, RM, E, Mode>;
    focusElementRef?: React$1.Ref<any>;
};

export interface TypedGridFilterModel<DM extends TGDM, RM extends TGRM> {
    items: TypedGridFilterItem<DM, RM, TGRM_Key<RM>>[];
    logicOperator?: TypedGridLogicOperator;
};

export interface TypedGridFilterState<DM extends TGDM, RM extends TGRM> {
    filterModel: TypedGridFilterModel<DM, RM>;
    visibleRowsLookup: Record<TGDM_Id<DM>, boolean>;
    filteredDescendantCountLookup: Record<TGDM_Id<DM>, number>;
};

export interface TypedGridFilterInitialState<DM extends TGDM, RM extends TGRM> {
    filterModel?: TypedGridFilterModel<DM, RM>;
};

export interface TypedElementSize extends MuiGrid.ElementSize {};

export interface TypedGridDimensions extends MuiGrid.GridDimensions {
    viewportOuterSize: TypedElementSize;
    viewportInnerSize: TypedElementSize;
};

export interface TypedGridDimensionsApi {
    resize: () => void;
    getRootDimensions: () => TypedGridDimensions | null;
    unstable_getViewportPageSize: () => number;
};

export interface TypedGridPaginationState extends MuiGrid.GridPaginationState {};

export interface TypedGridPageSizeApi {
    setPageSize: (pageSize: number) => void;
};

export interface TypedGridPageApi {
    setPage: (page: number) => void;
};

export interface TypedGridPaginationApi extends TypedGridPageApi, TypedGridPageSizeApi {};

export interface TypedGridPinnedColumns<RM extends TGRM> {
    left?: TGRM_Key<RM>[];
    right?: TGRM_Key<RM>[];
};

export type TypedGridPinnedPosition = MuiGrid.GridPinnedPosition;

export interface TypedGridColumnPinningApi<RM extends TGRM> {
    pinColumn: <K extends TGRM_Key<RM>>(field: K, side: TypedGridPinnedPosition) => void;
    unpinColumn: <K extends TGRM_Key<RM>>(field: K) => void;
    getPinnedColumns: () => TypedGridPinnedColumns<RM>;
    setPinnedColumns: (pinnedColumns: TypedGridPinnedColumns<RM>) => void;
    isColumnPinned: <K extends TGRM_Key<RM>>(field: K) => TypedGridPinnedPosition | false;
};

export type TypedGridColumnPinningState<RM extends TGRM> = TypedGridPinnedColumns<RM>;

export interface TypedGridState<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    rows: TypedGridRowsState;
    visibleRowsLookup: TypedGridVisibleRowsLookupState<DM>;
    rowsMeta: MuiGrid.GridRowsMetaState; //new
    //editRows: MuiGrid.GridEditingState; //new
    headerFiltering: GridHeaderFilteringState; //new
    rowSelection: MuiGrid.GridRowSelectionModel;
    editRows: TypedGridEditRowsModel<DM, RM, E, Mode>;
    pagination: MuiGrid.GridPaginationState;
    columns: TypedGridColumnsState<DM, RM, E, Mode>;
    columnGrouping: MuiGrid.GridColumnsGroupingState; //new
    columnReorder: TypedGridColumnReorderState<RM>;
    columnResize: TypedGridColumnResizeState<RM>;
    columnMenu: TypedGridColumnMenuState<RM>;
    sorting: TypedGridSortingState<DM, RM>;
    focus: TypedGridFocusState<DM, RM, TGRM_Key<RM>>;
    tabIndex: TypedGridTabIndexState<DM, RM, TGRM_Key<RM>>;
    selection: TypedGridSelectionModel<DM>;
    filter: TypedGridFilterState<DM, RM>;
    preferencePanel: TypedGridPreferencePanelState;
    density: TypedGridDensityState;
    error?: any;
    pinnedColumns: TypedGridColumnPinningState<RM>;
};

export interface TypedGridInitialState<DM extends TGDM, RM extends TGRM> {
    sorting?: TypedGridSortingInitialState<RM>;
    filter?: TypedGridFilterInitialState<DM, RM>;
    preferencePanel?: TypedGridPreferencePanelInitialState;
    pinnedColumns?: TypedGridColumnPinningState<RM>;
};

export interface TypedGridStateChangeParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    state: TypedGridState<DM, RM, E, Mode>;
    api: TypedGridApi<DM, RM, E, Mode>;
};

export interface TypedGridRowSelectionCheckboxParams<DM extends TGDM> extends MuiGrid.GridRowSelectionCheckboxParams {
    id: TGDM_Id<DM>;
};

export interface TypedGridHeaderSelectionCheckboxParams extends MuiGrid.GridHeaderSelectionCheckboxParams {};

export interface TypedGridValueOptionsParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    field: K;
    id?: TGDM_Key<DM>;
    row?: DM;
};

export type TypedPreProcessorCallback = (value: any, params?: any) => any;
enum TypedGridPreProcessingGroup {
    hydrateColumns = "hydrateColumns",
    scrollToIndexes = "scrollToIndexes",
    columnMenu = "columnMenu",
    canBeReordered = "canBeReordered",
    filteringMethod = "filteringMethod",
    sortingMethod = "sortingMethod"
};

export interface TypedGridPreProcessingApi {
    unstable_registerPreProcessor: (group: TypedGridPreProcessingGroup, id: string, callback: TypedPreProcessorCallback) => () => void;
    unstable_applyPreProcessors: (group: TypedGridPreProcessingGroup, value: any, params?: any) => any;
};


export interface ParamsEventTuple<T1, T2> { 
    params: T1;
    event: T2;
};

export interface TypedGridRowEventLookup<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    rowClick: ParamsEventTuple<TypedGridRowParams<DM, RM, E, Mode>, React$1.MouseEvent<HTMLElement>>;
    rowDoubleClick: ParamsEventTuple<TypedGridRowParams<DM, RM, E, Mode>, React$1.MouseEvent<HTMLElement>>;
    rowMouseEnter: ParamsEventTuple<TypedGridRowParams<DM, RM, E, Mode>, React$1.MouseEvent<HTMLElement>>;
    rowMouseLeave: ParamsEventTuple<TypedGridRowParams<DM, RM, E, Mode>, React$1.MouseEvent<HTMLElement>>;
};

export interface TypedGridColumnHeaderEventLookup<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    columnHeaderClick: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnHeaderDoubleClick: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnHeaderOver: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnHeaderOut: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnHeaderEnter: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnHeaderLeave: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnHeaderKeyDown: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.KeyboardEvent<HTMLElement>>;
    columnHeaderFocus: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.FocusEvent<HTMLElement>>;
    columnHeaderBlur: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.FocusEvent<HTMLElement>>;
    columnHeaderDragStart: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.DragEvent<HTMLElement>>;
    columnHeaderDragEnter: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.DragEvent<HTMLElement>>;
    columnHeaderDragOver: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.DragEvent<HTMLElement>>;
    columnHeaderDragEnd: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.DragEvent<HTMLElement>>;
    columnSeparatorMouseDown: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
};

export interface TypedGridCellEventLookup<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    cellClick: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    cellDoubleClick: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    cellMouseDown: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    cellMouseUp: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    cellKeyDown: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.KeyboardEvent<HTMLElement>>;
    cellDragEnter: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.DragEvent<HTMLElement>>;
    cellDragOver: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.DragEvent<HTMLElement>>;
};

export interface TypedGridControlledStateEventLookup<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    pageSizeChange: {
        params: number;
    };
    pageChange: {
        params: number;
    };
    filterModelChange: {
        params: TypedGridFilterModel<DM, RM>;
    };
    sortModelChange: {
        params: TypedGridSortModel<RM>;
    };
    /* editRowsModelChange: {
        params: TypedGridEditRowsModel<DM, RM, E, Mode>;
    }; */
    selectionChange: {
        params: TypedGridSelectionModel<DM>;
    };
    pinnedColumnsChange: {
        params: TypedGridPinnedColumns<RM>;
    };
	columnVisibilityModelChange: {
        params: TypedGridColumnVisibilityModel<DM>;
    };
    detailPanelsExpandedRowIdsChange: {
        params: TGDM_Id<DM>[];
    };
};

export interface TypedGridEventLookup<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> 
    extends TypedGridRowEventLookup<DM, RM, E, Mode>, TypedGridColumnHeaderEventLookup<DM, RM, E, Mode>,
        TypedGridCellEventLookup<DM, RM, E, Mode>, TypedGridControlledStateEventLookup<DM, RM, E, Mode> 
{
    unmount: {};
    componentError: {
        params: any;
    };
    stateChange: {
        params: TypedGridState<DM, RM, E, Mode>;
    };
    resize: {
        params: MuiGrid.ElementSize;
    };
    viewportInnerSizeChange: {
        params: MuiGrid.ElementSize;
    };
    debouncedResize: {
        params: MuiGrid.ElementSize;
    };
    rowGroupsPreProcessingChange: {};
    preProcessorRegister: {
        params: TypedGridPreProcessingGroup;
    };
    preProcessorUnregister: {
        params: TypedGridPreProcessingGroup;
    };
    columnsChange: {
        params: TGRM_Key<RM>[];
    };
    columnWidthChange: ParamsEventTuple<TypedGridColumnResizeParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnResizeStart: ParamsEventTuple<{ feild: TGRM_Key<RM> }, React$1.MouseEvent<HTMLElement>>;
    columnResizeStop: ParamsEventTuple<null, React$1.MouseEvent<HTMLElement>>;
    columnResize: ParamsEventTuple<TypedGridColumnResizeParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement>>;
    columnOrderChange: {
        params: TypedGridColumnOrderChangeParams<DM, RM, E, TGRM_Key<RM>, Mode>;
    };
    rowsSet: {};
    visibleRowsSet: {};
    rowExpansionChange: {
        params: TypedGridRowTreeNodeConfig<DM>;
    };
    cellModeChange: {
        params: TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>;
    };
    cellEditStart: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.MouseEvent<HTMLElement> | React$1.KeyboardEvent<HTMLElement>>;
    cellEditStop: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, MuiGrid.MuiBaseEvent>;
    /* cellEditCommit: {
        params: TypedGridCellEditCommitParams<DM, RM, TGRM_Key<RM>>;
        event: MuiGrid.MuiBaseEvent;
    }; */
    /* editCellPropsChange: {
        params: TypedGridEditCellPropsParams<DM, RM, TGRM_Key<RM>>;
        event: React$1.SyntheticEvent<HTMLElement> | {};
    }; */
    rowEditStart: ParamsEventTuple<TypedGridRowParams<DM, RM, E, Mode>, React$1.MouseEvent<HTMLElement> | React$1.KeyboardEvent<HTMLElement>>;
    rowEditStop: ParamsEventTuple<TypedGridRowParams<DM, RM, E, Mode>, MuiGrid.MuiBaseEvent>;
    rowEditCommit: ParamsEventTuple<TGDM_Id<DM>, MuiGrid.MuiBaseEvent>;
    cellFocusIn: {
        params: TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>;
    };
    cellFocusOut: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>, MuiGrid.MuiBaseEvent>;
    cellNavigationKeyDown: ParamsEventTuple<TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode> | TypedGridRowParams<DM, RM, E, Mode>, React$1.KeyboardEvent<HTMLElement>>;
    columnHeaderNavigationKeyDown: ParamsEventTuple<TypedGridColumnHeaderParams<DM, RM, E, TGRM_Key<RM>, Mode>, React$1.KeyboardEvent<HTMLElement>>;
    rowsScroll: {
        params: TypedGridScrollParams;
    };
    rowsScrollEnd: {
        params: TypedGridRowScrollEndParams;
    };
    virtualScrollerContentSizeChange: {};
    headerSelectionCheckboxChange: {
        params: TypedGridHeaderSelectionCheckboxParams;
    };
    rowSelectionCheckboxChange: {
        params: TypedGridRowSelectionCheckboxParams<DM>;
        event: React$1.ChangeEvent<HTMLElement>;
    };
};

export type TypedGridEvents<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = keyof TypedGridEventLookup<DM, RM, E, Mode>;

export type TypedGridEventListener<Event extends TypedGridEvents<DM, RM, E, Mode>, DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> 
    = (params: TypedGridEventLookup<DM, RM, E, Mode>[Event] extends {
        params: any;
    } ? TypedGridEventLookup<DM, RM, E, Mode>[Event]['params'] : undefined, event: TypedGridEventLookup<DM, RM, E, Mode>[Event] extends {
        event: MuiGrid.MuiBaseEvent;
    } ? MuiGrid.MuiEvent<TypedGridEventLookup<DM, RM, E, Mode>[Event]['event']> : MuiGrid.MuiEvent<{}>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;

export type TypedPublisherArgsNoEvent<E extends MuiGrid.GridEvents, T extends {
    params: any;
}> = [E, T['params']];

export type TypedPublisherArgsRequiredEvent<E extends MuiGrid.GridEvents, T extends {
    params: any;
    event: MuiGrid.MuiBaseEvent;
}> = [E, T['params'], T['event']];

export type TypedPublisherArgsOptionalEvent<E extends MuiGrid.GridEvents, T extends {
    params: any;
    event: MuiGrid.MuiBaseEvent;
}> = TypedPublisherArgsRequiredEvent<E, T> | TypedPublisherArgsNoEvent<E, T>;

export type TypedPublisherArgsEvent<E extends MuiGrid.GridEvents, T extends {
    params: any;
    event: MuiGrid.MuiBaseEvent;
}> = {} extends T['event'] ? TypedPublisherArgsOptionalEvent<E, T> : TypedPublisherArgsRequiredEvent<E, T>;

export type TypedPublisherArgsParams<E extends MuiGrid.GridEvents, T extends {
    params: any;
}> = [E, T['params']];

export type TypedPublisherArgsNoParams<E extends MuiGrid.GridEvents> = [E];

export type TypedGridEventPublisherArg<E extends MuiGrid.GridEvents, T> = T extends {
    params: any;
    event: MuiGrid.MuiBaseEvent;
} ? TypedPublisherArgsEvent<E, T> : T extends {
    params: any;
} ? TypedPublisherArgsParams<E, T> : TypedPublisherArgsNoParams<E>;
export type TypedGridEventPublisher = <E extends MuiGrid.GridEvents>(...params: TypedGridEventPublisherArg<E, MuiGrid.GridEventLookup[E]>) => void;

export type TypedEventListener = (...args: any[]) => void;

export interface TypedEventListenerOptions extends EventListenerOptions {};

export interface TypedGridCoreApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<MuiGrid.GridCoreApi, 'subscribeEvent'> {
    subscribeEvent: <Event extends TypedGridEvents<DM, RM, E, Mode>, DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM>(
        event: Event, handler: TypedGridEventListener<Event, DM, RM, E, Mode>, options?: TypedEventListenerOptions
    ) => () => void;
    publishEvent: TypedGridEventPublisher;
    showError: (props: any) => void;
};

export interface TypedGridDensityOption extends MuiGrid.GridDensityOption {};

export interface TypedGridDensityApi extends MuiGrid.GridDensityApi {
    setDensity: (density: TypedGridDensity, columnHeaderHeight?: number, rowHeight?: number) => void;
};

export interface TypedGridEditRowApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {

    getCellMode: TypedColumnModeOnly<Mode, (id: TGDM_Id<DM>, field: E) => MuiGrid.GridCellModes>;
    getRowMode: TypedRowModeOnly<Mode, (id: TGDM_Id<DM>) => MuiGrid.GridRowMode>;
    isCellEditable: <K extends TGRM_Key<RM>>(params: TypedGridCellParams<DM, RM, E, K, Mode>) => boolean;
    setEditCellValue: <K extends E>(params: TypedGridEditCellValueParams<DM, RM, K, Mode>, event?: MuiGrid.MuiBaseEvent) => void;

    /** New in v6 */
    getRowWithUpdatedValues: (id: TGDM_Id<DM>/* , field: E */) => TGRM;
    stopCellEditMode: <K extends E>(params: TypedGridStopCellEditModeParams<DM, RM, K, Mode>) => void;
    startCellEditMode: <K extends E>(params: TypedGridStartCellEditModeParams<DM, RM, K, Mode>) => void;
    stopRowEditMode: <K extends E>(params: TypedGridStopRowEditModeParams<DM, RM, K, Mode>) => void;
    startRowEditMode: <K extends E>(params: TypedGridStartRowEditModeParams<DM, RM, K, Mode>) => void;

    /** Removed or changed in the v6 migration */
    //setEditRowsModel: (model: TypedGridEditRowsModel<DM, RM, E, Mode>) => void;
    //getEditRowsModel: () => TypedGridEditRowsModel<DM, RM, E, Mode>;
    //setCellMode: TypedColumnModeOnly<Mode, (id: TGDM_Id<DM>, field: E, mode: MuiGrid.GridCellModes) => void>;
    //setRowMode: TypedRowModeOnly<Mode, (id: TGDM_Id<DM>, mode: MuiGrid.GridRowMode) => void>;
    //commitCellChange: TypedColumnModeOnly<Mode, <K extends E>(params: TypedGridCommitCellChangeParams<DM, RM, K>, event?: MuiGrid.MuiBaseEvent) => boolean | Promise<boolean>>;
    //commitRowChange: TypedRowModeOnly<Mode, (id: TGDM_Id<DM>, event?: MuiGrid.MuiBaseEvent) => boolean | Promise<boolean>>;
};

export interface TypedGridRowApi<DM extends TGDM> {
    getRowModels: () => Map<TGDM_Id<DM>, DM>;
    getRowsCount: () => number;
    getAllRowIds: () => TGDM_Id<DM>[];
    setRows: (rows: DM[]) => void;
    updateRows: (updates: TypedGridRowModelUpdate<DM>[]) => void;
    getRow: (id: TGDM_Id<DM>) => DM | null;
    getRowNode: (id: TGDM_Id<DM>) => TypedGridRowTreeNodeConfig<DM> | null;
    setRowChildrenExpansion: (id: TGDM_Id<DM>, isExpanded: boolean) => void;
};

export interface TypedGridSelectionApi<DM extends TGDM> extends MuiGrid.GridRowSelectionApi {
    selectRow: (id: TGDM_Id<DM>, isSelected?: boolean, resetSelection?: boolean) => void;
    selectRows: (ids: TGDM_Id<DM>[], isSelected?: boolean, resetSelection?: boolean) => void;
    selectRowRange: (range: {
        startId: TGDM_Id<DM>;
        endId: TGDM_Id<DM>;
    }, isSelected?: boolean, resetSelection?: boolean) => void;
    isRowSelected: (id: TGDM_Id<DM>) => boolean;
    getSelectedRows: () => Map<TGDM_Id<DM>, DM>;
    setRowSelectionModel: (rowIds: TGDM_Id<DM>[]) => void;
};

export interface TypedGridSortApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<MuiGrid.GridSortApi, "getSortModel" | "sortColumn" | "setSortModel"> {
    getSortModel: () => TypedGridSortModel<RM>;
    applySorting: () => void;
    setSortModel: (model: TypedGridSortModel<RM>) => void;
    sortColumn: (column: TypedGridColDef<DM, RM, E, TGRM_Key<RM>, Mode>, direction?: TypedGridSortDirection, allowMultipleSorting?: boolean) => void;
    getSortedRows: () => DM[];
    getSortedRowIds: () => TGDM_Id<DM>[];
    getRowIdFromRowIndex: (index: number) => TGDM_Id<DM>;
    //getRowIndex: (id: TGDM_Id<DM>) => number; replaced with 
    getRowIndexRelativeToVisibleRows: (id: TGDM_Id<DM>) => number;
};

/** Original @type {MuiGrid.GridStateApi} */
export interface TypedGridStateApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    state: TypedGridState<DM, RM, E, Mode>;
    setState: (state: TypedGridState<DM, RM, E, Mode> | ((previousState: TypedGridState<DM, RM, E, Mode>) => TypedGridState<DM, RM, E, Mode>)) => void;
    forceUpdate: () => void;
    unstable_updateControlState: <Event extends keyof TypedGridControlledStateEventLookup<DM, RM, E, Mode>>(controlState: TypedGridControlStateItem<DM, RM, E, Mode, Event>) => void;
};

export interface TypedGridExportOptions<RM extends TGRM> extends MuiGrid.GridExportOptions {
    fields?: TGRM_Key<RM>[];
};

export interface TypedGridCsvExportOptions<RM extends TGRM> extends TypedGridExportOptions<RM>, Omit<MuiGrid.GridCsvExportOptions, 'fields'> {};

export interface TypedGridPrintExportOptions<RM extends TGRM> extends TypedGridExportOptions<RM>, Omit<MuiGrid.GridCsvExportOptions, 'fields'> {};

export type TypedGridExportFormat = 'csv' | 'print';


export interface TypedGridCsvExportApi<RM extends TGRM> extends Omit<MuiGrid.GridCsvExportApi, "getDataAsCsv" | "exportDataAsCsv"> {
    getDataAsCsv: (options?: TypedGridCsvExportOptions<RM>) => string;
    exportDataAsCsv: (options?: TypedGridCsvExportOptions<RM>) => void;
};

/** Original @type {MuiGrid.GridFocusApi} */
export interface TypedGridFocusApi<DM extends TGDM, RM extends TGRM> {
    setCellFocus: <K extends TGRM_Key<RM>>(id: TGDM_Id<DM>, field: K) => void;
    setColumnHeaderFocus: <K extends TGRM_Key<RM>>(field: K, event?: MuiGrid.MuiBaseEvent) => void;
};

/** Original @type {MuiGrid.GridFilterApi} */
export interface TypedGridFilterApi<DM extends TGDM, RM extends TGRM> {
    showFilterPanel: <K extends TGRM_Key<RM>>(targetColumnField?: K) => void;
    hideFilterPanel: () => void;
    upsertFilterItem: <K extends TGRM_Key<RM>>(item: TypedGridFilterItem<DM, RM, K>) => void;
    unstable_applyFilters: () => void;
    deleteFilterItem: <K extends TGRM_Key<RM>>(item: TypedGridFilterItem<DM, RM, K>) => void;
    setFilterlogicOperator: (operator: TypedGridLogicOperator) => void;
    setFilterModel: (model: TypedGridFilterModel<DM, RM>) => void;
    //getVisibleRowModels: () => Map<TGDM_Id<DM>, DM>;
};

/** Original @type {MuiGrid.GridColumnMenuApi} */
export interface TypedGridColumnMenuApi<RM extends TGRM> {
    showColumnMenu: <K extends TGRM_Key<RM>>(field: K) => void;
    hideColumnMenu: () => void;
    toggleColumnMenu: <K extends TGRM_Key<RM>>(field: K) => void;
};

export interface TypedGridPreferencesPanelApi extends MuiGrid.GridPreferencesPanelApi {
    showPreferences: (newValue: TypedGridPreferencePanelsValue) => void;
    hidePreferences: () => void;
};

/** Original @type {MuiGrid.GridPrintExportApi} */
export interface TypedGridPrintExportApi<RM extends TGRM> {
    exportDataAsPrint: (options?: TypedGridPrintExportOptions<RM>) => void;
};

export interface TypedGridDisableVirtualizationApi extends MuiGrid.GridDisableVirtualizationApi {};

export interface TypedGridControlStateItem<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM, Event extends keyof TypedGridControlledStateEventLookup<DM, RM, E, Mode>> {
    stateId: string;
    propModel?: TypedGridEventLookup<DM, RM, E, Mode>[Event]['params'];
    stateSelector: (state: TypedGridState<DM, RM, E, Mode>) => TypedGridControlledStateEventLookup<DM, RM, E, Mode>[Event]['params'];
    propOnChange?: (model: TypedGridControlledStateEventLookup<DM, RM, E, Mode>[Event]['params'], details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    changeEvent: Event;
};

/** Origina @type {MuiGrid.GridControlStateApi} */
export interface TypedGridControlStateApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    unstable_updateControlState: <Event extends keyof TypedGridControlledStateEventLookup<DM, RM, E, Mode>>(controlState: TypedGridControlStateItem<DM, RM, E, Mode, Event>) => void;
    unstable_applyControlStateConstraint: (state: TypedGridState<DM, RM, E, Mode>) => {
        ignoreSetState: boolean;
        postUpdate: () => void;
    };
};

/* export interface TypedGridClipboardApi extends MuiGrid.GridClipboardApi {}; */

export interface TypedGridScrollApi extends MuiGrid.GridScrollApi {
    scroll: (params: Partial<TypedGridScrollParams>) => void;
    getScrollPosition: () => TypedGridScrollParams;
    scrollToIndexes: (params: Partial<TypedGridCellIndexCoordinates>) => boolean;
};

export type TypedGridSortDirection = MuiGrid.GridSortDirection;
export interface TypedGridSortCellParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    field: K;
    value: RM[K];
    api: TypedGridApi<DM, RM, E, Mode>;
};

export type TypedGridComparatorFn<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> = (
    v1: RM[K], 
    v2: RM[K], 
    cellParams1: TypedGridSortCellParams<DM, RM, E, K, Mode>,
    cellParams2: TypedGridSortCellParams<DM, RM, E, K, Mode>,
) => number;

/** Original @type {MuiGrid.GridSortItem} */
export interface TypedGridSortItem<RM extends TGRM, K extends TGRM_Key<RM>> {
    field: K;
    sort: TypedGridSortDirection;
};

export type TypedGridSortModel<RM extends TGRM> = TypedGridSortItem<RM, TGRM_Key<RM>>[];

export type TypedGridActionsCellItemProps = {
    label: string;
    icon?: React$1.ReactElement;
} & (({
    showInMenu?: false;
    icon: React$1.ReactElement;
} & IconButtonProps) | ({
    showInMenu: true;
} & MenuItemProps));

export type TypedGridAlignment = MuiGrid.GridAlignment;
export type ValueOptions = string | number | {
    value: any;
    label: string;
};

export type TypedGridColTypes = "string" | "number" | "date" | "required-date" | "dateTime" | "boolean" | "singleSelect" | "actions";

export type TypedGridColDef<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> = {
    field: K;
    type?: TypedGridColTypes | string;
    headerName?: string;
    description?: string;
    width?: number;
    flex?: number;
    minWidth?: number;
    maxWidth?: number
    //hide?: boolean; replaced with columnVisibilityModel in v6 migration
    sortable?: boolean;
    resizable?: boolean;
    sortComparator?: TypedGridComparatorFn<DM, RM, E, K, Mode>;
    valueOptions?: Array<ValueOptions> | ((params: TypedGridValueOptionsParams<DM, RM, K>) => Array<ValueOptions>);
    align?: MuiGrid.GridAlignment;
    valueGetter?: (params: TypedGridValueGetterParams<DM, RM, E, K, Mode>) => RM[K] | Date; //or Date added in v6 for date columns to work correctly. Not sure how older versions allowed Dates to be returned
    valueSetter?: (params: TypedGridValueSetterParams<DM, RM, K>) => DM;
    valueFormatter?: (params: TypedGridValueFormatterParams<DM, RM, E, K, Mode>) => any;
    valueParser?: (value: RM[K], params?: TypedGridCellParams<DM, RM, E, K, Mode>) => RM[K];
    cellClassName?: TypedGridCellClassNamePropType<DM, RM, E, K, Mode>;
    renderCell?: (params: TypedGridRenderCellParams<DM, RM, E, K, Mode>) => React$1.ReactNode;
    renderEditCell?: (params: TypedGridRenderEditCellParams<DM, RM, E, K, Mode>) => React$1.ReactNode;
    preProcessEditCellProps?: (params: TypedGridPreProcessEditCellProps<DM, RM, K>) => TypedGridEditCellProps<RM, K> | Promise<TypedGridEditCellProps<RM, K>>;
    headerClassName?: TypedGridColumnHeaderClassNamePropType<DM, RM, E, K, Mode>;
    renderHeader?: (params: TypedGridColumnHeaderParams<DM, RM, E, K, Mode>) => React$1.ReactNode;
    headerAlign?: MuiGrid.GridAlignment;
    hideSortIcons?: boolean;
    disableColumnMenu?: boolean;
    filterable?: boolean;
    filterOperators?: TypedGridFilterOperator<DM, RM, E, K, Mode>[];
    disableReorder?: boolean;
    disableExport?: boolean;
} & (
    K extends E ? { editable: true } : { editable?: false }  
);;

export type TypedGridActionsColDef<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = Omit<TypedGridColDef<DM, RM, E, any, Mode>, "editable" | "type"> & {
    type: 'actions';
    getActions: (params: TypedGridRowParams<DM, RM, E, Mode>) => React$1.ReactElement<TypedGridActionsCellItemProps>[];
};

export type GRID_DETAIL_PANEL_TOGGLE_FIELD = '__detail_panel_toggle__';

export type TypedGridDetailPanelColDef<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> = Omit<TypedGridColDef<DM, RM, E, any, Mode>, 'field' | 'editable' | 'type'> & {
	field: GRID_DETAIL_PANEL_TOGGLE_FIELD;
};

export type TypedGridEnrichedColDef<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = TypedGridColDef<DM, RM, E, K, Mode> | TypedGridActionsColDef<DM, RM, E, Mode>;

export type TypedGridColumnsList<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM
> = TypedGridEnrichedColDef<DM, RM, E, TGRM_Key<RM>, Mode>[];

export type TypedGridColumns<RM extends TGRM> = (TGRM_Key<RM> | `${string}actions` | GRID_DETAIL_PANEL_TOGGLE_FIELD)[];

export type TypedGridColumnDefs<
    DM extends TGDM,
    RM extends TGRM,
    E extends TGRM_Key<RM>,
    COLS extends TypedGridColumns<RM>,
    Mode extends TGEM
> = {
    [I in keyof COLS]: 
        COLS[I] extends TGRM_Key<RM> ?
			TypedGridColDef<DM, RM, E, COLS[I], Mode> :
		COLS[I] extends 'actions' ? 
			TypedGridActionsColDef<DM, RM, E, Mode> :
		COLS[I] extends GRID_DETAIL_PANEL_TOGGLE_FIELD ?
			TypedGridDetailPanelColDef<DM, RM, E, Mode> :
        never;
};

export type TypedGridStateColDef<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = TypedGridEnrichedColDef<DM, RM, E, K, Mode> & {
    computedWidth: number;
};

export interface TypedGridColumnApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> {
    getColumn: <K extends TGRM_Key<RM>>(field: K) => TypedGridStateColDef<DM, RM, E, K, Mode>;
    getAllColumns: () => TypedGridColumnsList<DM, RM, E, Mode>;
    getVisibleColumns: () => TypedGridColumnsList<DM, RM, E, Mode>;
    getColumnsMeta: () => TypedGridColumnsMeta;
    getColumnIndex: <K extends TGRM_Key<RM>>(field: K, useVisibleColumns?: boolean) => number;
    getColumnPosition: <K extends TGRM_Key<RM>>(field: K) => number;
    updateColumns: (cols: TypedGridColumnsList<DM, RM, E, Mode>) => void;
    setColumnVisibility: <K extends TGRM_Key<RM>>(field: K, isVisible: boolean) => void;
    setColumnIndex: <K extends TGRM_Key<RM>>(field: K, targetIndexPosition: number) => void;
    setColumnWidth: <K extends TGRM_Key<RM>>(field: K, width: number) => void;
};

export interface TypedGridLoggerApi {
    getLogger: (name: string) => MuiGrid.Logger;
};

export interface TypedGridApi<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, Mode extends TGEM> extends 
    TypedGridCoreApi<DM, RM, E, Mode>, 
    TypedGridStateApi<DM, RM, E, Mode>, 
    TypedGridLoggerApi, 
    TypedGridPreProcessingApi, 
    TypedGridRowGroupsPreProcessingApi<DM>, 
    TypedGridDensityApi, 
    TypedGridDimensionsApi, 
    TypedGridRowApi<DM>, 
    TypedGridEditRowApi<DM, RM, E, Mode>, 
    TypedGridParamsApi<DM, RM, E, Mode>, 
    TypedGridColumnApi<DM, RM, E, Mode>, 
    TypedGridSelectionApi<DM>, 
    TypedGridSortApi<DM, RM, E, Mode>, 
    TypedGridPaginationApi, 
    TypedGridCsvExportApi<RM>, 
    TypedGridFocusApi<DM, RM>, 
    TypedGridFilterApi<DM, RM>, 
    TypedGridColumnMenuApi<RM>, 
    TypedGridPreferencesPanelApi, 
    TypedGridPrintExportApi<RM>, 
    TypedGridDisableVirtualizationApi, 
    TypedGridControlStateApi<DM, RM, E, Mode>, 
    //TypedGridClipboardApi, removed in migration from v5 to v6
    TypedGridScrollApi, 
    TypedGridColumnPinningApi<RM>
{};

/** Original @type {MuiGrid.GridCellParams} */
export interface TypedGridCellParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    field: K;
    value: RM[K];
    formattedValue: RM[K];
    row: DM;
    colDef: TypedGridStateColDef<DM, RM, E, K, Mode>,
    isEditable?: boolean;
    cellMode: MuiGrid.GridRowModes;
    hasFocus: boolean;
    tabIndex: 0 | -1;
    getValue: <Field extends TGRM_Key<RM>>(id: TGDM_Id<DM>, field: Field) => RM[Field];
};

export interface TypedGridRenderCellParams<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> extends TypedGridCellParams<DM, RM, E, K, Mode> {
    api: TypedGridApi<DM, RM, E, Mode>;
};

export interface TypedGridMenuProps extends MuiGrid.GridMenuProps {};

export type TypedGridActionsCellProps<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> = Pick<TypedGridRenderCellParams<DM, RM, E, never, Mode>, 'colDef' | 'id' | 'api'> & Pick<TypedGridMenuProps, 'position'>;

export interface TypedGridRenderEditCellParams<
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM
> extends TypedGridCellParams<DM, RM, E, K, Mode> {
    api: TypedGridApi<DM, RM, E, Mode>;
};

export interface TypedGridValueGetterFullParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> extends Omit<TypedGridCellParams<DM, RM, E, K, Mode>, 'formattedValue' | 'isEditable' | 'getValue'> {
    getValue: <Field extends Exclude<TGRM_Key<RM>, K>>(id: TGDM_Id<DM>, field: Field) => RM[Field];
    api: TypedGridApi<DM, RM, E, Mode>;
};

export interface TypedGridValueGetterSimpleParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id: TGDM_Id<DM>;
    field: K;
    row: DM;
    colDef: TypedGridColDef<DM, RM, E, K, Mode> | TypedGridStateColDef<DM, RM, E, K, Mode>;
    rowNode: Pick<TypedGridRowTreeNodeConfig<DM>, 'id' | 'isAutoGenerated'>;
};

export type TypedGridValueGetterParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> 
	= TypedGridValueGetterFullParams<DM, RM, E, K, Mode> | TypedGridValueGetterSimpleParams<DM, RM, E, K, Mode>;

export interface TypedGridValueSetterParams<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    value: RM[K];
    row: DM;
};

export interface TypedGridValueFormatterParams<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> {
    id?: TGDM_Id<DM>;
    field: K;
    value: RM[K];
    api: TypedGridApi<DM, RM, E, Mode>;
};

export interface TypedGridPreProcessEditCellProps<DM extends TGDM, RM extends TGRM, K extends TGRM_Key<RM>> {
    id: TGDM_Id<DM>;
    row: DM;
    props: TypedGridEditCellProps<RM, K>;
};

export interface TypedGridFilterOperator<DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, K extends TGRM_Key<RM>, Mode extends TGEM> {
    label?: string;
    value: RM[K];
    getApplyFilterFn: (filterItem: TypedGridFilterItem<DM, RM, K>, column: TypedGridStateColDef<DM, RM, E, K, Mode>) => null | ((params: TypedGridCellParams<DM, RM, E, K, Mode>) => boolean);
    InputComponent?: React$1.JSXElementConstructor<TypedGridFilterInputValueProps<DM, RM, E, K, Mode>>;
    InputComponentProps?: Record<string, any>;
};

export interface TypedGridColumnsMeta {
    totalWidth: number;
    positions: number[];
};

export type TypedGridColTypeDef<T extends any> =
    Omit<TypedGridColDef<{ id: string | number, value: T }, { value: T }, never, "value", "cell">, "field"> 
& {
    extendType?: MuiGrid.GridNativeColTypes;
}; 


export type TypedGridColumnTypesRecord = {
    [key: string]: TypedGridColTypeDef<any>;
};


export type TypedColumnEntryNotEditable<R extends TGDM, K extends TGDM_Key<R>> = K | 'actions' | GRID_DETAIL_PANEL_TOGGLE_FIELD;
export type TypedColumnEntryEditable<R extends TGDM, K extends TGDM_Key<R>> = { editable: K } | [K, true];
export type TypedColumnEntry<R extends TGDM> = TypedColumnEntryNotEditable<R, TGDM_Key<R>> | TypedColumnEntryEditable<R, TGDM_Key<R>>;
export type TypedColumnOrderList<R extends TGDM> = TypedColumnEntry<R>[];

export type  TypedEditableColumnKeys<R extends TGDM, C extends TypedColumnOrderList<R>> = { 
    [CK in keyof C]: 
        C extends Array<infer U> ? 
            U extends TypedColumnEntryEditable<R, infer P>
                ? P extends [TGDM_Key<R>, true] 
                    ? P[0] : P : never : never;
            //     ? U extends { editable: TGRM_Key<R> }
            //         ? U extends Array<infer P>
            //             ? P extends TGRM_Key<R> & true
            //             ? Exclude<P, true>
            //         : never
            //     : U["editable"]
            // : never : never : never;
}[number];

export interface TypedGridComponentProps<
    Mode extends TGEM,
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, 
    COLS extends TypedGridColumns<RM>, 
    COL_DEFS extends TypedGridColumnDefs<DM, RM, E, COLS, Mode> = TypedGridColumnDefs<DM, RM, E, COLS, Mode>
>
    extends TypedGridComponentOtherProps<Mode, DM, RM, E, COLS, COL_DEFS>, 
        Omit<MuiGrid.DataGridProProps, "apiRef" | "columns" | "editMode" | "filterModel" | "getCellClassName"
			| "initialState" | "isCellEditable" | "isRowSelectable" | "getRowClassName" | "getRowId" | "getTreeDataPath" | "onCellClick"
			| "onCellDoubleClick" | "onCellEditCommit" | "onCellEditStart" | "onCellEditStop" | "onCellFocusOut" | "onCellKeyDown" | "onColumnHeaderClick"
			| "onColumnHeaderDoubleClick" | "onColumnHeaderEnter" | "onColumnHeaderLeave" | "onColumnHeaderOut" | "onColumnHeaderOver"
			| "onColumnOrderChange" | "onColumnResize" | "onColumnWidthChange"
			| "onRowModesModelChange" | "onError" | "onFilterModelChange" | "onPinnedColumnsChange"
			| "onResize" | "onRowClick" | "onRowDoubleClick" | "onRowEditCommit" | "processRowUpdate" | "onRowEditStart" | "onRowEditStop" | "onRowsScrollEnd" 
			| "onRowSelectionModelChange" | "onSortModelChange" | "onStateChange" | "pinnedColumns" | "rows" | "selectionModel" | "sortModel" | "sx" 
			| "getDetailPanelContent" | "onDetailPanelExpandedRowIdsChange" | "detailPanelExpandedRowIds"

            //new props
            
		> 
{disableAutosizer?: boolean};

export interface TypedGridComponentOtherProps<
    Mode extends TGEM,
    DM extends TGDM, RM extends TGRM, E extends TGRM_Key<RM>, 
    COLS extends TypedGridColumns<RM>, 
    COL_DEFS extends TypedGridColumnDefs<DM, RM, E, COLS, Mode> = TypedGridColumnDefs<DM, RM, E, COLS, Mode>
> {
    /** Field wich are used to infer types. @type {MuiGrid} */
    
    /** Original @type {MuiGrid.GridComponentProps["editMode"]} */
    editMode: [Mode] extends [never] ? undefined : Mode;

    /** Original @type {MuiGrid.GridComponentProps["rows"]} */
    rows: readonly DM[];

    /** Original @type {MuiGrid.GridComponentProps["columns"]} */
    columns: COL_DEFS;

    /** Original @type {MuiGrid.GridComponentProps["columnTypes"]} */
    columnTypes?: TypedGridColumnTypesRecord;

    /** Original @type {MuiGrid.GridComponentProps["apiRef"]} */
    apiRef?: TypedGridApiRef<DM, RM, E, Mode>;

    /** Original @type {MuiGrid.GridComponentProps["getCellClassName"]} */
    getCellClassName?: (params: TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>) => string;
    
    /** Original @type {MuiGrid.GridComponentProps["getRowClassName"]} */
    getRowClassName?: (params: TypedGridRowParams<DM, RM, E, Mode>) => string;
    
    /** Original @type {MuiGrid.GridComponentProps["isCellEditable"]} */
    isCellEditable?: (params: TypedGridCellParams<DM, RM, E, TGRM_Key<RM>, Mode>) => boolean;
    
    /** Original @type {MuiGrid.GridComponentProps["isRowSelectable"]} */
    isRowSelectable?: (params: TypedGridRowParams<DM, RM, E, Mode>) => boolean;
    
    /** Original @type {MuiGrid.GridComponentProps["getTreeDataPath"]} */
    getTreeDataPath?: (row: DM) => string[];
    
    ///** Original @type {MuiGrid.GridComponentProps["onEditCellPropsChange"]} */
    //onEditCellPropsChange?: TypedGridEventListener<"editCellPropsChange", DM, RM, E, Mode>;
    
    ///** Original @type {MuiGrid.GridComponentProps["onCellEditCommit"]} */
    //onCellEditCommit?: TypedGridEventListener<"cellEditCommit", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onCellEditStart"]} */
    onCellEditStart?: TypedGridEventListener<"cellEditStart", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onCellEditStop"]} */
    onCellEditStop?: TypedGridEventListener<"cellEditStop", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowEditCommit"]} */
    onRowEditCommit?: TypedGridEventListener<"rowEditCommit", DM, RM, E, Mode>;

    /** Original @type {MuiGrid.GridComponentProps["processRowUpdate"]} */
    processRowUpdate?: (row: TypedGridEditRow<RM, E, Mode>, record: DM | null) => Promise<DM>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowEditStart"]} */
    onRowEditStart?: TypedGridEventListener<"rowEditStart", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowEditStop"]} */
    onRowEditStop?: TypedGridEventListener<"rowEditStop", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onError"]} */
    onError?: TypedGridEventListener<"componentError", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onCellClick"]} */
    onCellClick?: TypedGridEventListener<"cellClick", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onCellDoubleClick"]} */
    onCellDoubleClick?: TypedGridEventListener<"cellDoubleClick", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onCellFocusOut"]} */
    onCellFocusOut?: TypedGridEventListener<"cellFocusOut", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onCellKeyDown"]} */
    onCellKeyDown?: TypedGridEventListener<"cellKeyDown", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnHeaderClick"]} */
    onColumnHeaderClick?: TypedGridEventListener<"columnHeaderClick", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnHeaderDoubleClick"]} */
    onColumnHeaderDoubleClick?: TypedGridEventListener<"columnHeaderDoubleClick", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnHeaderOver"]} */
    onColumnHeaderOver?: TypedGridEventListener<"columnHeaderOver", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnHeaderOut"]} */
    onColumnHeaderOut?: TypedGridEventListener<"columnHeaderOut", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnHeaderEnter"]} */
    onColumnHeaderEnter?: TypedGridEventListener<"columnHeaderEnter", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnHeaderLeave"]} */
    onColumnHeaderLeave?: TypedGridEventListener<"columnHeaderLeave", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnOrderChange"]} */
    onColumnOrderChange?: TypedGridEventListener<"columnOrderChange", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnResize"]} */
    onColumnResize?: TypedGridEventListener<"columnResize", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onColumnWidthChange"]} */
    onColumnWidthChange?: TypedGridEventListener<"columnWidthChange", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowClick"]} */
    onRowClick?: TypedGridEventListener<"rowClick", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowsScrollEnd"]} */
    onRowsScrollEnd?: TypedGridEventListener<"rowsScrollEnd", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowDoubleClick"]} */
    onRowDoubleClick?: TypedGridEventListener<"rowDoubleClick", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onResize"]} */
    onResize?: TypedGridEventListener<"resize", DM, RM, E, Mode>;
    
    /** Original @type {MuiGrid.GridComponentProps["onStateChange"]} */
    onStateChange?: TypedGridEventListener<"stateChange", DM, RM, E, Mode>;
    
    //@type {MuiGrid.GridComponentProps["onPageChange"]}
    //onPageChange?: (page: number, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    ///@type {MuiGrid.GridComponentProps["onPageSizeChange"]}
    //onPageSizeChange?: (pageSize: number, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    ///** Original @type {MuiGrid.GridComponentProps["editRowsModel"]} */
    //editRowsModel?: TypedGridEditRowsModel<DM, RM, E, Mode>;
    
    ///** Original @type {MuiGrid.GridComponentProps["onEditRowsModelChange"]} */
    //onEditRowsModelChange?: (editRowsModel: TypedGridEditRowsModel<DM, RM, E, Mode>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;

    /** Original @type {MuiGrid.GridComponentProps["onRowModesModelChange"]} */
    onRowModesModelChange?: (rowModesModel: TypedGridRowModesModel<DM, RM, E, Mode>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    /** Original @type {MuiGrid.GridComponentProps["filterModel"]} */
    filterModel?: TypedGridFilterModel<DM, RM>;
    
    /** Original @type {MuiGrid.GridComponentProps["onFilterModelChange"]} */
    onFilterModelChange?: (model: TypedGridFilterModel<DM, RM>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    /** Original @type {MuiGrid.GridComponentProps["selectionModel"]} */
    selectionModel?: TypedGridInputSelectionModel<DM>;
    
    /** Original @type {MuiGrid.GridComponentProps["onRowSelectionModelChange"]} */
    onRowSelectionModelChange?: (selectionModel: TypedGridInputSelectionModel<DM>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    /** Original @type {MuiGrid.GridComponentProps["sortModel"]} */
    sortModel?: TypedGridSortModel<RM>;
    
    /** Original @type {MuiGrid.GridComponentProps["onSortModelChange"]} */
    onSortModelChange?: (model: TypedGridSortModel<RM>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    /** Original @type {MuiGrid.GridComponentProps["pinnedColumns"]} */
    pinnedColumns?: TypedGridPinnedColumns<RM>;
    
    /** Original @type {MuiGrid.GridComponentProps["onPinnedColumnsChange"]} */
    onPinnedColumnsChange?: (pinnedColumns: TypedGridPinnedColumns<RM>, details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;
    
    /** Original @type {MuiGrid.GridComponentProps["getRowId"]} */
    getRowId?: TypedGridRowIdGetter<DM>;
    
    /** Original @type {MuiGrid.GridComponentProps["nonce"]} */
    nonce?: string;
    
    /** Original @type {MuiGrid.GridComponentProps["initialState"]} */
    initialState?: TypedGridInitialState<DM, RM>;
    
    /** Original @type {MuiGrid.GridComponentProps["groupingColDef"]} */
    //groupingColDef?: GridGroupingColDefOverride | ((params: GridGroupingColDefOverrideParams) => GridGroupingColDefOverride | undefined | null);
    
    /** Original @type {MuiGrid.GridComponentProps["sx"]} */ 
    sx?: SxProps<Theme>;

	/** Original @type {MuiGrid.GridComponentProps["detailPanelExpandedRowIds"]} */
    detailPanelExpandedRowIds?: TGDM_Id<DM>[];

	/** Original @type {MuiGrid.GridComponentProps["onDetailPanelExpandedRowIdsChange"]} */
	onDetailPanelExpandedRowIdsChange?: (ids: TGDM_Id<DM>[], details: TypedGridCallbackDetails<DM, RM, E, Mode>) => void;

	/** Original @type {MuiGrid.GridComponentProps["getDetailPanelContent"]} */
	 getDetailPanelContent?: (params: TypedGridRowParams<DM, RM, E, Mode>) => React.ReactNode;
};
