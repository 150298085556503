import { Data } from '@react-google-maps/api';
import { mapApi, ApiConfig } from '@imas/api';
import { Client, ClientLocation, ClientLocationContact, ClientLocationContactForm, vContact, vContactPrimaryPhoneNumber } from './types';
import { PhoneNumber } from '../phone-numbers';
import { Proposal } from '../proposal';

//client location contacts base url
const CLIENT_LOCATION_CONTACTS_URL = "/api/clients/locations/contacts";

/*  */
export const GetAllClientLocationContacts = mapApi((email?: string): ApiConfig<ClientLocationContact[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}`,
	params: { email },
}));

/*  */
export const GetAllClientLocationVContacts = mapApi((): ApiConfig<vContact[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/view`,
}));

/*  */
export const GetClientLocationContact = mapApi((contactId: int): ApiConfig<ClientLocationContact> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}`,
}));

/*  */
export const GetClientsLocationVContactPrimaryPhoneNumbers = mapApi((clientid: int): ApiConfig<vContactPrimaryPhoneNumber[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/client-v-contact-primary-phone-number/${clientid.toString()}`,
}));

/*  */
export const GetClientLocationVContactPrimaryPhoneNumber = mapApi((contactId: int): ApiConfig<vContactPrimaryPhoneNumber> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}/v-contact-primary-phone-number`,
}));

/*  */
export const GetClientLocationContactClient = mapApi((contactId: int): ApiConfig<Client> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}/client`,
}));

/*  */
export const GetClientLocationContactLocation = mapApi((contactId: int): ApiConfig<ClientLocation> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}/location`,
}));

/*  */
export const GetClientLocationContactPhoneNumbers = mapApi((contactId: int): ApiConfig<PhoneNumber[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}/phone-numbers`,
}));

/*  */
export const GetClientLocationContactProposals = mapApi((contactId?: int | null): ApiConfig<Proposal[]> => ({
	method: 'get',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId ?? -1}/proposals`,
}));

/*  */
export const CreateClientLocationContact = mapApi((data: ClientLocationContactForm): ApiConfig<ClientLocationContact> => ({
	method: 'post',
	url: `${CLIENT_LOCATION_CONTACTS_URL}`,
	data,
}));

/*  */
export const UpdateClientLocationContact = mapApi((contactId: int, data: ClientLocationContactForm): ApiConfig<ClientLocationContact> => ({
	method: 'put',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}`,
	data,
}));

/*  */
export const DeleteClientLocationContact = mapApi((contactId: int): ApiConfig<ClientLocationContact> =>({
	method: 'delete',
	url: `${CLIENT_LOCATION_CONTACTS_URL}/${contactId}`,
}));
