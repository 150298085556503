import { EmployeeWorkHours, ServiceOrderEmployeeHour, TimeSheet, TimeSheetDetail, vTimeSheetDetail } from "@imas/api/time-sheet";
import React, { createContext } from "react";
import { GetAutoFillHourType, TIME_SHEET_DETAILS_EDIT_GRID_API_REF } from "./types";
import { Moment } from "moment";
import { vWorkClass } from "@imas/api/system";

export interface ITimeSheetDetailsFormContext {
    timeSheetId: number;
    timeSheet?: TimeSheet;
    weekOf: Moment | null;
    isEditable: boolean;
    details?: vTimeSheetDetail[];
    soEmployeeHours?: ServiceOrderEmployeeHour[];
    gridHeight: number;
    soUniqueId: int | null;
    workClass?: vWorkClass;
    managementMode: boolean;
    setSoUniqueId: React.Dispatch<React.SetStateAction<int | null>>;
    getAutoFillHourType: GetAutoFillHourType;
    addRow: () => Promise<vTimeSheetDetail | null>;
    refreshRows: () => Promise<void>;
    api: TIME_SHEET_DETAILS_EDIT_GRID_API_REF;
}

export const TimeSheetDetailsFormContext = createContext<ITimeSheetDetailsFormContext>({
    timeSheetId: -1,
    timeSheet: undefined,
    isEditable: false,
    weekOf: null,
    details: undefined,
    soEmployeeHours: undefined,
    gridHeight: -1,
    soUniqueId: null,
    workClass: undefined,
    managementMode: false,
    setSoUniqueId: () => undefined,
    getAutoFillHourType: () => null,
    addRow: async () => null,
    refreshRows: async () => {},
    api: {} as never,
});
