import { FolderInfoItem } from '@imas/api/files/types';
import React, { useState, useMemo, useRef } from 'react';
import { FileExplorerProps } from './FileExplorer';
import { FileExplorerContext } from './FileExplorerContext';
import { getModeFilter } from './FileExplorerUtils';
import { useFileExplorer } from './useFileExplorerHandlers';
import { FileExplorerContextMenu, FileExplorerContextMenuRef } from './FileExplorerContextMenu/FileExplorerContextMenu';
import moment from 'moment';

export interface FileExplorerContextProviderProps extends Omit<FileExplorerProps, "actions" | "sx"> {
    children: React.ReactNode;
};

const FileExplorerContextProvider = React.memo((props: FileExplorerContextProviderProps) => {
    //separate internal props from file explorer hook props
    const { children, mode: modeProp, allowUpload, table, contextMenu: contextMenuBuilder, ...hookProps } = props;
    const mode = modeProp ?? 'explorer';

    // ref for FileExplorerContextMenu
	const contextMenuRef = useRef<FileExplorerContextMenuRef>({ open: () => {}, close: () => {} });

	//sorting method
	const [sorting, setSorting] = useState<int>(0);

	//list of selected items
	const [selected, setSelected] = useState<FolderInfoItem[]>([]);

    //useFileExplorer hook
    const { 
        currentDirectory, items: unfilteredItems, breadcrumbs, navHistory, goBackHistory, 
        canNavigateTo, navigate, goBack, undoGoBack, refresh, upload, rename, move, delete: deleteFiles 
    } = useFileExplorer({ table, ...hookProps});

    //filter items by mode
    const items = useMemo(() => {
        return unfilteredItems ? unfilteredItems.filter(getModeFilter(mode)) : undefined;
    }, [unfilteredItems, mode]);

	//sort items
	const sortedItems = useMemo(() => {
		//the second sort on these methods groups folders and makes them alphabetical
		switch (sorting) {
			case 0:
				//by name a => z
				return items?.sort((x,y) => x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1 ).sort((x,y) => x.type === null ? y.type === null ? (x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1) : -1 : 1);
			case 1:
				//by name z => a
				return items?.sort((x,y) => x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1 ).sort((x,y) => x.type === null ? y.type === null ? (x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1) : -1 : 1).reverse();
			case 2:
				//by date modified, newest on top
				return items?.sort((x,y) => moment(x.lastWrite).isBefore(moment(y.lastWrite)) ? -1 : 1 ).sort((x,y) => x.type === null ? y.type === null ? (moment(x.lastWrite).isBefore(moment(y.lastWrite)) ? 1 : -1) : 1 : -1);
			case 3:
				//by date modified, oldest on top 
				return items?.sort((x,y) => moment(x.lastWrite).isBefore(moment(y.lastWrite)) ? -1 : 1 ).sort((x,y) => x.type === null ? y.type === null ? (moment(x.lastWrite).isBefore(moment(y.lastWrite)) ? 1 : -1) : 1 : -1).reverse();
			case 4:
				//by type a => z
				return items?.sort((x,y) => (x.type ?? 'z') > (y.type ?? 'z') ? 1 : -1 ).sort((x,y) => x.type === null ? y.type === null ? (x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1) : -1 : 1);
			case 5:
				//by type z => a
				return items?.sort((x,y) => (x.type ?? 'z') > (y.type ?? 'z') ? -1 : 1 ).sort((x,y) => x.type === null ? y.type === null ? (x.name.toLowerCase() > y.name.toLowerCase() ? -1 : 1) : -1 : 1);
			case 6:
				//by size big => small
				return items?.sort((x,y) => y.size - x.size );
			case 7:
				//by size small => big
				return items?.sort((x,y) => x.size - y.size );
			default:
				return items;
		}
	}, [items, sorting]);

    return (
        <FileExplorerContext.Provider value={{
            preferences: {
                selectCheckboxes: true,
                includeExtInFilename: true,
            },
            currentDir: currentDirectory,
            mode: mode,
            table,
            items: sortedItems,
            breadcrumbs,
            selected,
			canUpload: allowUpload ?? (mode === "explorer" || mode === "upload-file" || mode === "upload-files"),
            navHistory,
            goBackHistory,
            contextMenu: contextMenuRef,
			sorting,
            setSelected,
			setSorting,
            canNavigateTo,
            navigate,
            goBack, 
            undoGoBack,
            refresh,
            upload,
            rename,
            move,
            delete: deleteFiles,
        }}>
            {children}

            {/* FileExplorerContextMenu */}
            <FileExplorerContextMenu builder={contextMenuBuilder} ref={contextMenuRef}/>
        </FileExplorerContext.Provider>
    );
});

export default FileExplorerContextProvider;