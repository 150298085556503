import { JsonFile } from './../../../api/types/api/JsonFile';
import { RenameFieldsForm } from './types/reports/RenameFieldsForm';
import { CreateReportTemplateVersionRequest } from './types/reports/CreateReportTemplateVersionRequest';
import { ReportTemplateForm } from './types/reports/ReportTemplateForm';
import { ReportTemplate, ReportTemplateVersion, vReportTemplate } from '@imas/api/service-order';
import { ApiConfig, axiosData, mapApi, SourceGetter } from '@imas/api';
import axios from 'axios';
import { Moment } from 'moment';

//service order reports base url
const REPORT_TEMPLATES_URL = "/api/service-orders/report-templates";

//get all report templates
export const GetReportTemplates = (getSource: SourceGetter) => {
    return (): Promise<ReportTemplate[]> => {
		const source = getSource();

        return new Promise<ReportTemplate[]>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: REPORT_TEMPLATES_URL,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//Get a report template given its template id
export const GetReportTemplate = (getSource: SourceGetter) => {
    return (templateid: int): Promise<ReportTemplate> => {
		const source = getSource();

        return new Promise<ReportTemplate>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: `${REPORT_TEMPLATES_URL}/${templateid.toString()}`,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//Create a new report template
export const CreateReportTemplate = (getSource: SourceGetter) => {
    return (data: ReportTemplateForm): Promise<ReportTemplate> => {
		const source = getSource();

        return new Promise<ReportTemplate>((resolve, reject) => {      
			axios({
	            method: 'post',
				url: `${REPORT_TEMPLATES_URL}/create`,
				data: data,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//Update a report template
export const UpdateReportTemplate = (getSource: SourceGetter) => {
    return (templateid: int, data: ReportTemplateForm): Promise<ReportTemplate> => {
		const source = getSource();

        return new Promise<ReportTemplate>((resolve, reject) => {      
			axios({
	            method: 'put',
				url: `${REPORT_TEMPLATES_URL}/update/${templateid.toString()}`,
				data: data,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};


//get all vReportTemplates
export const GetvReportTemplates = (getSource: SourceGetter) => {
    return (): Promise<vReportTemplate[]> => {
		const source = getSource();

        return new Promise<vReportTemplate[]>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: REPORT_TEMPLATES_URL + '/v-report-templates',
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//gets a vReportTemplate given its templateid
export const GetvReportTemplate = (getSource: SourceGetter) => {
    return (templateid: int): Promise<vReportTemplate> => {
		const source = getSource();

        return new Promise<vReportTemplate>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: REPORT_TEMPLATES_URL + '/v-report-templates/' + templateid.toString(),
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};


export const GetvReportTemplateFilename = (getSource: SourceGetter) => {
    return (filename: string): Promise<vReportTemplate> => {
		const source = getSource();

        return new Promise<vReportTemplate>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: REPORT_TEMPLATES_URL + '/v-report-templates-filename/' + filename,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//Gets all report template versions for a report template
export const GetReportTemplateVersions = (getSource: SourceGetter) => {
    return (templateid: int): Promise<ReportTemplateVersion[]> => {
		const source = getSource();

        return new Promise<ReportTemplateVersion[]>((resolve, reject) => {      
			axios({
	            method: 'get',
				url: `${REPORT_TEMPLATES_URL}/template-versions/${templateid.toString()}`,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//Import a report template into a service order, making a report
export const ImportReportTemplate = (getSource: SourceGetter) => {
    return (uniqueid: int, templateid: int): Promise<string> => {
		const source = getSource();

        return new Promise<string>((resolve, reject) => {      
			axios({
	            method: 'post',
				url: `${REPORT_TEMPLATES_URL}/import/${uniqueid.toString()}/${templateid.toString()}`,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//Adds a template to a proposal
export const AddTemplateToProposal = (getSource: SourceGetter) => {
    return (uniqueid: int, templateid: int): Promise<boolean> => {
		const source = getSource();

        return new Promise<boolean>((resolve, reject) => {      
			axios({
	            method: 'post',
				url: `${REPORT_TEMPLATES_URL}/add-to-proposal/${uniqueid.toString()}/${templateid.toString()}`,
	            cancelToken: source.token,
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
};

//
/* export const CreateReportTemplateVersion = (getSource: SourceGetter) => {
    return (data: CreateReportTemplateVersionRequest): Promise<ReportTemplateVersion> => {
		const source = getSource();

        return new Promise<ReportTemplateVersion>((resolve, reject) => {      
			axios({
	            method: 'post',
				url: `${REPORT_TEMPLATES_URL}/template-versions`,
				data: data,
	            cancelToken: source.token,
				encodeAs: "FormData",
	        }).then(response => {
				//resolve promise
				resolve(response.data);
			}).catch(e => {
				reject(e);
			});
		});
	};
}; */

export const CreateReportTemplateVersion = mapApi((data: CreateReportTemplateVersionRequest): ApiConfig<ReportTemplateVersion> => ({
	method: 'post',
	url: `${REPORT_TEMPLATES_URL}/template-versions`,
	data: data,
	encodeAs: "FormData",
}));

export const RenameTemplateFields = mapApi((data: RenameFieldsForm): ApiConfig<JsonFile> => ({
	method: 'post',
	url: `${REPORT_TEMPLATES_URL}/rename-fields`,
	data: data,
	encodeAs: "FormData",
}));

export const AddFeedbackButton = mapApi((): ApiConfig<void> => ({
	method: 'put',
	url: `${REPORT_TEMPLATES_URL}/add-feedback-button`,
}));