import { vContact } from '@imas/api/client';
import { ListSubheader, Box, Typography, createFilterOptions, AutocompleteProps } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { forwardRef } from 'react';

const LISTBOX_PADDING = 8; //px

const VContactListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	return (
		<>
			{/* List Header */}
			<ListSubheader sx={{ marginTop: '5px', lineHeight: '32px', padding: '0px', display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ marginLeft: '15px', width: '235px' }}>{"Contact"}</Box>
				<Box sx={{ marginLeft: '15px', width: '335px' }}>{"Client"}</Box>
			</ListSubheader>

			{/* List */}
			<VirtualizedListboxAdpater 
				{...props} 
				ref={ref} 
				renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<vContact>) => {
					const row = data[index];
				
					return (
						<li {...row.props}  style={{ ...style, top: (style.top as number) + LISTBOX_PADDING }}>
							<Typography noWrap sx={{ width: '250px' }}>{row.option.name}</Typography>
							<Typography noWrap sx={{ width: '300px' }}>{row.option.clientName}</Typography>
						</li>
					);
				}}
			/>
		</>
	);
});

const clientsFilter = createFilterOptions<vContact>({ stringify: (contact) => `${contact.name} ${contact.clientName}` });

export const VCONTACTS_AUTOCOMPLETE_VIRTUALIZER: NonNullable<Pick<AutocompleteProps<vContact, false, false, false, "div">, "filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup">> = {
	filterOptions: clientsFilter,

	//virtualization props
	disableListWrap: true,
	PopperComponent: VirtualizedListboxPopper,
	ListboxComponent: VContactListboxAdapter,
	renderOption: (props, option) => ({ props, option }),
	renderGroup: (params) => params,
};