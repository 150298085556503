import React, { useCallback, useState } from 'react';
import { HubContext } from './HubContext';
import { v4 as uuid4 } from 'uuid';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { HubRegistration } from './types';

export const HubProvider: React.FC = React.memo(({ children }) => {
	//list of active hub registrations
	const [hubRegistrations, setHubRegistrations] = useState<HubRegistration<any>[]>([]);

	//method for registering a hub
	const register = useCallback((endpoint: string) => {
		//create registration
		const registration: HubRegistration<any> = new HubRegistration(new HubConnectionBuilder().withUrl(endpoint).withAutomaticReconnect().build()); 
		
		//add it to the list of registrations
		setHubRegistrations(x => [...x, registration]);

		//return the new registration
		return registration;
	}, []);

	//un-register a hub
	const unregister = useCallback((registration: HubRegistration<any>) => {
		try {
			//ensure the connection is closed
			registration.connection.stop().finally(() => {
				//remove it from list of registrations
				setHubRegistrations(registrations => registrations.filter(x => x.id !== registration.id));
			});
		} catch (e) {
			console.error(e);
		}
	}, []);
	
	return (
		<HubContext.Provider value={{
			register,
			unregister
		}}>
			{children}
		</HubContext.Provider>
	);
}); 