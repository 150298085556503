import { axiosData, SourceGetter } from '@imas/api';
import { EmployeeManagerSession, EmployeeManagerSessionForm } from './types';

//manager sessions base url
const MANAGER_SESSIONS_URL = "/api/employees/manager-sessions";

/*  */
export const GetEmployeeManagerSession = (getSource: SourceGetter) => {
    return async (managerSessionId: int): Promise<EmployeeManagerSession> => {
        return axiosData({
			method: 'get',
            url: `${MANAGER_SESSIONS_URL}/${managerSessionId}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const UpdateEmployeeManagerSession = (getSource: SourceGetter) => {
    return async (managerSessionId: int, data: EmployeeManagerSessionForm): Promise<EmployeeManagerSession> => {
        return axiosData({
			method: 'put',
            url: `${MANAGER_SESSIONS_URL}/${managerSessionId}`,
			data,
            cancelToken: getSource().token,
		});
    };
};