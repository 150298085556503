import { getGeolocation } from './getGeolocation';
import { AuthenticationLocation } from '@imas/api/auth/types';

export async function getAuthLocation(): Promise<AuthenticationLocation> {
    const location = await getGeolocation({ enableHighAccuracy: true, maximumAge: 0 });

    return {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        accuracy: location.coords.accuracy,
    };
};