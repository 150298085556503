import { vEmployee } from "@imas/api/employee";
import { XDialogTitle } from "@imas/components/mui";
import { SlideUp } from "@imas/components/transitions";
import { useDeviceInfo } from "@imas/styles";
import { EmployeeToVCard } from "@imas/utils/contacts";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { memo } from "react";
import QRCode from "react-qr-code";
import { EmployeeDirectoryRecord } from "src/pages/Home/EmployeeDirectory/EmployeeDirectory";

export interface BusinessCardDialogProps {
    employee: vEmployee | EmployeeDirectoryRecord;
    open: boolean;
    onClose: () => void;
};

export const BusinessCardDialog = memo(({employee, open, onClose} : BusinessCardDialogProps) => {

    if (!open) return <></>;

    const deviceInfo = useDeviceInfo();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={!deviceInfo.isSm}
            TransitionComponent={!deviceInfo.isSm ? SlideUp : undefined}
        >
            {!deviceInfo.isSm ? (
                <XDialogTitle
                    onClose={onClose}
                    fullScreen
                >
					<Typography variant={'h6'}>{"Electronic Business Card"}</Typography>
				</XDialogTitle>
            ) : null}

            <DialogContent sx={{alignSelf: 'center', alignText: 'center'}}>
                <Typography color={'textPrimary'} variant={'h6'} marginBottom={'20px'} fontWeight={'bold'}>{"Scan to Import Contact Info"}</Typography>
                <QRCode
                    level={'M'}
                    value={EmployeeToVCard(employee)}
                ></QRCode>
            </DialogContent>

        </Dialog>
    );
});