import React from 'react';
import { ResourceCollaborator } from '.';
import { AvatarGroup, Avatar, SxProps, Theme } from '@mui/material';
import { makeStyles } from '@imas/styles';

const stringToColor = (str: string) => {
	var hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	var colour = '#';
	for (let i = 0; i < 3; i++) {
		var value = (hash >> (i * 8)) & 0xFF;
		colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
};

const useStyles = makeStyles()(theme => ({
	avatar: {
		height: '32px',
		width: '32px',
	},
	f2: { fontSize: '0.9rem' },
	f3: { fontSize: '0.75rem' },
	group: {
		padding: '5px',
	}
}));

export interface CollaboratorGroupProps {
	collaborators: ResourceCollaborator[];
	max?: int;
	noPadding?: boolean;
	sx?: SxProps<Theme>;
};

export const CollaboratorGroup = (props: CollaboratorGroupProps) => {
	const { collaborators, max, noPadding, sx } = props;

	//styling
	const { classes, cx } = useStyles();

	return (
		<AvatarGroup 
			max={max ?? 5} 
			total={collaborators.length}
			className={noPadding ? undefined : classes.group}
			classes={{
				avatar: classes.avatar,
			}}
			sx={sx}
		>
			{collaborators.map(collaborator => (
				<Avatar
					key={collaborator.id}
					title={collaborator.name}
					className={cx({
						[classes.f2]: collaborator.initials.length <= 2,
						[classes.f3]: collaborator.initials.length > 2
					})}
					sx={{ bgcolor: stringToColor(collaborator.name) }}
				>{collaborator.initials}</Avatar>
			))}
		</AvatarGroup>
	);
};