import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AccountCircle, MinorCrash } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { EventAssignment } from 'src/api/types/api/schedule/schedule';
import { Alignment } from '@imas/components/layout';
import { openVehicleInspectionEditor } from 'src/pages/Home/Vehicles/VehicleInspection/VehicleInspectionEditor';

export interface AssignmentListProps {
    assignments: EventAssignment[]
};

/** Displays a list of employee's assigned to the project. */
export const AssignmentList = ({ assignments }: AssignmentListProps) => {
    return (
        (<List>
            {/* for each assignment add a new item */}
            {assignments.map((data: EventAssignment, index: number) => (
                // individual assingment item, on click redirects to a link which display's that employee's profile
				(<Alignment flex row>
                    <ListItem key={index} button component={Link} to={"/home/employee-directory/" + data.employeeId} style={{ width: "-webkit-fill-available", paddingTop: "2px", paddingBottom: "2px" }}>
                	    <ListItemIcon><AccountCircle/></ListItemIcon>

                	    {/* show the employee name & certs */}
                	    <ListItemText primary={data.employeeNameCert} secondary={"Veh: " + data.calItemVehicle}/>
                	</ListItem>
                    <Tooltip title={'New Vehicle Inspection'}>
						<ListItem key={index + 'vehicle'} 
							disabled={data.calItemId === null || data.calItemId === 999}
							button
							onClick={() => {
								if (data.calItemId === null) return;
								if (data.calItemId === 999) return;
								openVehicleInspectionEditor({calItemId: data.calItemId});
							}}
							style={{ width: 'auto', paddingTop: "2px", paddingBottom: "2px" }}
						>
                		    <MinorCrash fontSize={'large'}/>
                		</ListItem>
					</Tooltip>
                </Alignment>)
            ))}
        </List>)
    );
};