import useEventListener from '@use-it/event-listener';
import { useState } from 'react';

export function useActiveElement(): Element | null {
	//currently active element
	const [active, setActive] = useState<Element | null>(null);

	//update active element when focus changes
	useEventListener("focusin", () => setActive(x => x !== document.activeElement ? document.activeElement : x));
	useEventListener("focusout", () => setActive(null));

	return active;
};